const Tuss = () => {

        const procedimentos = [
                {
                        codigo: "10101012",
                        original: "Consulta em consultório (no horário normal ou preestabelecido)",
                        mod: "Consulta em consultório (no horário normal ou preestabelecido)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10101020",
                        original: "Consulta em domicílio",
                        mod: "Consulta em domicílio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10101039",
                        original: "Consulta em pronto socorro",
                        mod: "Consulta em pronto socorro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10102019",
                        original: "Visita hospitalar (paciente internado)",
                        mod: "Visita hospitalar (paciente internado)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10103015",
                        original: "Atendimento ao recém-nascido em berçário",
                        mod: "Atendimento ao recém-nascido em berçário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10103023",
                        original: "Atendimento ao recém-nascido em sala de parto (parto normal ou operatório de baixo risco)",
                        mod: "Atendimento ao recém-nascido em sala de parto (parto normal ou operatório de baixo risco)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10103031",
                        original: "Atendimento ao recém-nascido em sala de parto (parto normal ou operatório de alto risco)",
                        mod: "Atendimento ao recém-nascido em sala de parto (parto normal ou operatório de alto risco)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10104011",
                        original: "Atendimento do intensivista diarista (por dia e por paciente)",
                        mod: "Atendimento do intensivista diarista (por dia e por paciente)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10104020",
                        original: "Atendimento médico do intensivista em UTI geral ou pediátrica (plantão de 12 horas - por paciente)",
                        mod: "Atendimento médico do intensivista em UTI geral ou pediátrica (plantão de 12 horas - por paciente)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10105034",
                        original: "Transporte extra-hospitalar terrestre de pacientes graves, 1ª hora - a partir do deslocamento do médico - acompanhamento médico",
                        mod: "Transporte extra-hospitalar terrestre de pacientes graves, 1ª hora - a partir do deslocamento do médico - acompanhamento médico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10105042",
                        original: "Transporte extra-hospitalar terrestre de pacientes graves, por hora adicional - até o retorno do médico à base - acompanhamento médico",
                        mod: "Transporte extra-hospitalar terrestre de pacientes graves, por hora adicional - até o retorno do médico à base - acompanhamento médico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10105050",
                        original: "Transporte extra-hospitalar aéreo ou aquático de pacientes graves, 1ª hora - a partir do deslocamento do médico - acompanhamento médico",
                        mod: "Transporte extra-hospitalar aéreo ou aquático de pacientes graves, 1ª hora - a partir do deslocamento do médico - acompanhamento médico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10105069",
                        original: "Transporte extra-hospitalar aéreo ou aquático de pacientes graves, por hora adicional - acompanhamento médico",
                        mod: "Transporte extra-hospitalar aéreo ou aquático de pacientes graves, por hora adicional - acompanhamento médico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10105077",
                        original: "Acompanhamento médico para transporte intra-hospitalar de pacientes graves, com ventilação assistida, da UTI para o centro de diagnósitco",
                        mod: "Acompanhamento médico para transporte intra-hospitalar de pacientes graves, com ventilação assistida, da UTI para o centro de diagnósitco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10106014",
                        original: "Aconselhamento genético",
                        mod: "Aconselhamento genético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10106030",
                        original: "Atendimento ao familiar do adolescente",
                        mod: "Atendimento ao familiar do adolescente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10106049",
                        original: "Atendimento pediátrico a gestantes (3º trimestre)",
                        mod: "Atendimento pediátrico a gestantes (3º trimestre)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10106065",
                        original: "Exame de aptidão física e mental, ou em portadores de mobilidade reduzida, para fins de inscrição ou renovação da CNH (Carteira Nacional de Habilitação)",
                        mod: "Exame de aptidão física e mental, ou em portadores de mobilidade reduzida, para fins de inscrição ou renovação da CNH (Carteira Nacional de Habilitação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10106073",
                        original: "Junta Médica (três ou mais profissionais) - destina-se ao esclarecimento diagnóstico ou decisão de conduta em caso de difícil solução - por profissional",
                        mod: "Junta Médica (três ou mais profissionais) - destina-se ao esclarecimento diagnóstico ou decisão de conduta em caso de difícil solução - por profissional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10106090",
                        original: "Junta Médica - pagamento de honorários médicos referente a 3ª opinião, conforme resolução Consu nº 8",
                        mod: "Junta Médica - pagamento de honorários médicos referente a 3ª opinião, conforme resolução Consu nº 8",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10106103",
                        original: "Perícia médica",
                        mod: "Perícia médica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10106111",
                        original: "Exame de aptidão física e mental para concessão de benefícios fiscais conferidos pelas Secretaria da Receita Federal e da Fazenda Estadual, a quem fazem jus portadores de mobilidade reduzida, com necessidade de adaptação veicular",
                        mod: "Exame de aptidão física e mental para concessão de benefícios fiscais conferidos pelas Secretaria da Receita Federal e da Fazenda Estadual, a quem fazem jus portadores de mobilidade reduzida, com necessidade de adaptação veicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10106120",
                        original: "Exame de aptidão física e mental para ratificação, quando a condição física e mental assim o requerer, dos exames realizados pelo órgão previdenciário, incluindo restrição ou liberação para a condução de veículo automotor",
                        mod: "Exame de aptidão física e mental para ratificação, quando a condição física e mental assim o requerer, dos exames realizados pelo órgão previdenciário, incluindo restrição ou liberação para a condução de veículo automotor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10106138",
                        original: "Prova de direção veicular em banca especial - Avaliação Clínica durante a prova de direção veicular procedida por dois médicos simultaneamente",
                        mod: "Prova de direção veicular em banca especial - Avaliação Clínica durante a prova de direção veicular procedida por dois médicos simultaneamente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "10106146",
                        original: "Atendimento ambulatorial em puericultura",
                        mod: "Atendimento ambulatorial em puericultura",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101015",
                        original: "Acompanhamento clínico ambulatorial pós-transplante renal - por avaliação",
                        mod: "Acompanhamento clínico ambulatorial pós-transplante renal - por avaliação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101023",
                        original: "Análise da proporcionalidade cineantropométrica",
                        mod: "Análise da proporcionalidade cineantropométrica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101074",
                        original: "Avaliação nutrológica (inclui consulta)",
                        mod: "Avaliação nutrológica (inclui consulta)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101082",
                        original: "Avaliação nutrológica pré e pós-cirurgia bariátrica (inclui consulta)",
                        mod: "Avaliação nutrológica pré e pós-cirurgia bariátrica (inclui consulta)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101090",
                        original: "Avaliação da composição corporal por antropometria (inclui consulta)",
                        mod: "Avaliação da composição corporal por antropometria (inclui consulta)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101104",
                        original: "Avaliação da composição corporal por bioimpedanciometria",
                        mod: "Avaliação da composição corporal por bioimpedanciometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101112",
                        original: "Avaliação da composição corporal por pesagem hidrostática",
                        mod: "Avaliação da composição corporal por pesagem hidrostática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101120",
                        original: "Controle anti-doping (por período de 2 horas) - durante competições",
                        mod: "Controle anti-doping (por período de 2 horas) - durante competições",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101139",
                        original: "Controle anti-doping (por período de 2 horas) - fora de competições",
                        mod: "Controle anti-doping (por período de 2 horas) - fora de competições",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101155",
                        original: "Prestação de serviços em delegações ou competições esportivas",
                        mod: "Prestação de serviços em delegações ou competições esportivas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101171",
                        original: "Rejeição de enxerto renal - tratamento ambulatorial - avaliação clínica diária",
                        mod: "Rejeição de enxerto renal - tratamento ambulatorial - avaliação clínica diária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101198",
                        original: "Teste e adaptação de lente de contato (sessão) - binocular",
                        mod: "Teste e adaptação de lente de contato (sessão) - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101201",
                        original: "Avaliação clínica e eletrônica de paciente portador de marca-passo ou sincronizador ou desfibrilador",
                        mod: "Avaliação clínica e eletrônica de paciente portador de marca-passo ou sincronizador ou desfibrilador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101210",
                        original: "Acompanhamento clínico ambulatorial pós-transplante de córnea -por avaliação do 11º ao 30º dia até 3 avaliações",
                        mod: "Acompanhamento clínico ambulatorial pós-transplante de córnea -por avaliação do 11º ao 30º dia até 3 avaliações",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101228",
                        original: "Acompanhamento clínico ambulatorial pós-transplante de medula óssea",
                        mod: "Acompanhamento clínico ambulatorial pós-transplante de medula óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20101236",
                        original: "Avaliação geriátrica ampla - AGA",
                        mod: "Avaliação geriátrica ampla - AGA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102011",
                        original: "Holter de 24 horas - 2 ou mais canais - analógico",
                        mod: "Holter de 24 horas - 2 ou mais canais - analógico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102020",
                        original: "Holter de 24 horas - 3 canais - digital",
                        mod: "Holter de 24 horas - 3 canais - digital",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102038",
                        original: "Monitorização ambulatorial da pressão arterial - MAPA (24 horas)",
                        mod: "Monitorização ambulatorial da pressão arterial - MAPA (24 horas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102062",
                        original: "Monitor de eventos sintomáticos por 15 a 30 dias (LOOPER)",
                        mod: "Monitor de eventos sintomáticos por 15 a 30 dias (LOOPER)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102070",
                        original: "Tilt teste",
                        mod: "Tilt teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102089",
                        original: "Sistema Holter - 12 horas - 1 canal",
                        mod: "Sistema Holter - 12 horas - 1 canal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102097",
                        original: "Sistema Holter - 12 horas - 2 ou mais canais",
                        mod: "Sistema Holter - 12 horas - 2 ou mais canais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102100",
                        original: "Sistema Holter - 24 horas - 1 canal",
                        mod: "Sistema Holter - 24 horas - 1 canal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102111",
                        original: "Monitorização eletrocardiográfica programada com transcrição - não contínua",
                        mod: "Monitorização eletrocardiográfica programada com transcrição - não contínua",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102119",
                        original: "Monitorização eletrocardiográfica programada com transcrição - não contínua",
                        mod: "Monitorização eletrocardiográfica programada com transcrição - não contínua",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102127",
                        original: "Holter 7 dias",
                        mod: "Holter 7 dias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102135",
                        original: "Holter cerebral",
                        mod: "Holter cerebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20102143",
                        original: "Tilt teste com provas farmacológicas",
                        mod: "Tilt teste com provas farmacológicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103018",
                        original: "Adaptação e treinamento de recursos ópticos para visão subnormal (por sessão) - binocular",
                        mod: "Adaptação e treinamento de recursos ópticos para visão subnormal (por sessão) - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103026",
                        original: "Amputação bilateral (preparação do coto)",
                        mod: "Amputação bilateral (preparação do coto)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103034",
                        original: "Amputação bilateral (treinamento protético)",
                        mod: "Amputação bilateral (treinamento protético)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103042",
                        original: "Amputação unilateral (preparação do coto)",
                        mod: "Amputação unilateral (preparação do coto)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103050",
                        original: "Amputação unilateral (treinamento protético)",
                        mod: "Amputação unilateral (treinamento protético)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103069",
                        original: "Assistência fisiátrica respiratória em pré e pós-operatório de condições cirúrgicas",
                        mod: "Assistência fisiátrica respiratória em pré e pós-operatório de condições cirúrgicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103077",
                        original: "Ataxias",
                        mod: "Ataxias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103093",
                        original: "Atendimento fisiátrico no pré e pós-operatório de pacientes para prevenção de sequelas",
                        mod: "Atendimento fisiátrico no pré e pós-operatório de pacientes para prevenção de sequelas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103107",
                        original: "Atendimento fisiátrico no pré e pós-parto",
                        mod: "Atendimento fisiátrico no pré e pós-parto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103115",
                        original: "Atividade reflexa ou aplicação de técnica cinesioterápica específica",
                        mod: "Atividade reflexa ou aplicação de técnica cinesioterápica específica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103123",
                        original: "Atividades em escola de postura (máximo de 10 pessoas) - por sessão",
                        mod: "Atividades em escola de postura (máximo de 10 pessoas) - por sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103131",
                        original: "Biofeedback com EMG",
                        mod: "Biofeedback com EMG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103140",
                        original: "Bloqueio fenólico, alcoólico ou com toxina botulínica por segmento corporal",
                        mod: "Bloqueio fenólico, alcoólico ou com toxina botulínica por segmento corporal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103158",
                        original: "Confecção de órteses em material termo-sensível (por unidade)",
                        mod: "Confecção de órteses em material termo-sensível (por unidade)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103166",
                        original: "Confecção de prótese imediata",
                        mod: "Confecção de prótese imediata",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103174",
                        original: "Confecção de prótese provisória",
                        mod: "Confecção de prótese provisória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103182",
                        original: "Desvios posturais da coluna vertebral",
                        mod: "Desvios posturais da coluna vertebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103190",
                        original: "Disfunção vésico-uretral",
                        mod: "Disfunção vésico-uretral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103204",
                        original: "Distrofia simpático-reflexa",
                        mod: "Distrofia simpático-reflexa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103212",
                        original: "Distúrbios circulatórios artério-venosos e linfáticos",
                        mod: "Distúrbios circulatórios artério-venosos e linfáticos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103220",
                        original: "Doenças pulmonares atendidas em ambulatório",
                        mod: "Doenças pulmonares atendidas em ambulatório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103239",
                        original: "Exercícios de ortóptica (por sessão)",
                        mod: "Exercícios de ortóptica (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103247",
                        original: "Exercícios para reabilitação do asmático (ERAC) - por sessão coletiva",
                        mod: "Exercícios para reabilitação do asmático (ERAC) - por sessão coletiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103255",
                        original: "Exercícios para reabilitação do asmático (ERAI) - por sessão individual",
                        mod: "Exercícios para reabilitação do asmático (ERAI) - por sessão individual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103263",
                        original: "Hemiparesia",
                        mod: "Hemiparesia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103271",
                        original: "Hemiplegia",
                        mod: "Hemiplegia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103280",
                        original: "Hemiplegia e hemiparesia com afasia",
                        mod: "Hemiplegia e hemiparesia com afasia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103298",
                        original: "Hipo ou agenesia de membros",
                        mod: "Hipo ou agenesia de membros",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103301",
                        original: "Infiltração de ponto gatilho (por músculo) ou agulhamento seco (por músculo)",
                        mod: "Infiltração de ponto gatilho (por músculo) ou agulhamento seco (por músculo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103310",
                        original: "Lesão nervosa periférica afetando mais de um nervo com alterações sensitivas e/ou motoras",
                        mod: "Lesão nervosa periférica afetando mais de um nervo com alterações sensitivas e/ou motoras",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103328",
                        original: "Lesão nervosa periférica afetando um nervo com alterações sensitivas e/ou motoras",
                        mod: "Lesão nervosa periférica afetando um nervo com alterações sensitivas e/ou motoras",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103336",
                        original: "Manipulação vertebral",
                        mod: "Manipulação vertebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103344",
                        original: "Miopatias",
                        mod: "Miopatias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103360",
                        original: "Paciente com D.P.O.C. em atendimento ambulatorial necessitando reeducação e reabilitação respiratória",
                        mod: "Paciente com D.P.O.C. em atendimento ambulatorial necessitando reeducação e reabilitação respiratória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103379",
                        original: "Paciente em pós-operatório de cirurgia cardíaca, atendido em ambulatório, duas a três vezes por semana",
                        mod: "Paciente em pós-operatório de cirurgia cardíaca, atendido em ambulatório, duas a três vezes por semana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103387",
                        original: "Pacientes com doença isquêmica do coração, atendido em ambulatório de 8 a 24 semanas",
                        mod: "Pacientes com doença isquêmica do coração, atendido em ambulatório de 8 a 24 semanas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103395",
                        original: "Pacientes com doença isquêmica do coração, atendido em ambulatório, até 8 semanas de programa",
                        mod: "Pacientes com doença isquêmica do coração, atendido em ambulatório, até 8 semanas de programa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103409",
                        original: "Pacientes com doenças neuro-músculo-esqueléticas com envolvimento tegumentar",
                        mod: "Pacientes com doenças neuro-músculo-esqueléticas com envolvimento tegumentar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103417",
                        original: "Pacientes sem doença coronariana clinicamente manifesta, mas considerada de alto  risco,  atendido  em ambulatório, duas a três vezes por semana",
                        mod: "Pacientes sem doença coronariana clinicamente manifesta, mas considerada de alto  risco,  atendido  em ambulatório, duas a três vezes por semana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103425",
                        original: "Paralisia cerebral",
                        mod: "Paralisia cerebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103433",
                        original: "Paralisia cerebral com distúrbio de comunicação",
                        mod: "Paralisia cerebral com distúrbio de comunicação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103441",
                        original: "Paraparesia/tetraparesia",
                        mod: "Paraparesia/tetraparesia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103450",
                        original: "Paraplegia e tetraplegia",
                        mod: "Paraplegia e tetraplegia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103468",
                        original: "Parkinson",
                        mod: "Parkinson",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103476",
                        original: "Patologia neurológica com dependência de atividades da vida diária",
                        mod: "Patologia neurológica com dependência de atividades da vida diária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103484",
                        original: "Patologia osteomioarticular em um membro",
                        mod: "Patologia osteomioarticular em um membro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103492",
                        original: "Patologia osteomioarticular em dois ou mais membros",
                        mod: "Patologia osteomioarticular em dois ou mais membros",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103506",
                        original: "Patologia osteomioarticular em um segmento da coluna",
                        mod: "Patologia osteomioarticular em um segmento da coluna",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103514",
                        original: "Patologia osteomioarticular em diferentes segmentos da coluna",
                        mod: "Patologia osteomioarticular em diferentes segmentos da coluna",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103522",
                        original: "Patologias osteomioarticulares com dependência de atividades da vida diária",
                        mod: "Patologias osteomioarticulares com dependência de atividades da vida diária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103530",
                        original: "Recuperação funcional pós-operatória ou por imobilização da patologia vertebral",
                        mod: "Recuperação funcional pós-operatória ou por imobilização da patologia vertebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103549",
                        original: "Procedimentos mesoterápicos (por região anatômica)",
                        mod: "Procedimentos mesoterápicos (por região anatômica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103557",
                        original: "Procedimentos mesoterápicos com calcitonina (qualquer segmento)",
                        mod: "Procedimentos mesoterápicos com calcitonina (qualquer segmento)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103565",
                        original: "Processos inflamatórios pélvicos",
                        mod: "Processos inflamatórios pélvicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103573",
                        original: "Programa de exercício supervisionado com obtenção de eletrocardiograma e/ou saturação de O2 - sessão individual",
                        mod: "Programa de exercício supervisionado com obtenção de eletrocardiograma e/ou saturação de O2 - sessão individual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103581",
                        original: "Programa de exercício supervisionado com obtenção de eletrocardiograma e/ou saturação de O2 - sessão coletiva",
                        mod: "Programa de exercício supervisionado com obtenção de eletrocardiograma e/ou saturação de O2 - sessão coletiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103590",
                        original: "Programa de exercício supervisionado sem obtenção de eletrocardiograma e/ou saturação de O2 - sessão individual",
                        mod: "Programa de exercício supervisionado sem obtenção de eletrocardiograma e/ou saturação de O2 - sessão individual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103603",
                        original: "Programa de exercício supervisionado sem obtenção de eletrocardiograma e/ou saturação de O2 - sessão coletiva",
                        mod: "Programa de exercício supervisionado sem obtenção de eletrocardiograma e/ou saturação de O2 - sessão coletiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103611",
                        original: "Queimados - seguimento ambulatorial para prevenção de sequelas (por segmento)",
                        mod: "Queimados - seguimento ambulatorial para prevenção de sequelas (por segmento)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103620",
                        original: "Reabilitação de paciente com endoprótese",
                        mod: "Reabilitação de paciente com endoprótese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103638",
                        original: "Reabilitação labiríntica (por sessão)",
                        mod: "Reabilitação labiríntica (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103646",
                        original: "Reabilitação perineal com biofeedback",
                        mod: "Reabilitação perineal com biofeedback",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103654",
                        original: "Recuperação funcional de distúrbios crânio-faciais",
                        mod: "Recuperação funcional de distúrbios crânio-faciais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103662",
                        original: "Recuperação funcional pós-operatória ou pós-imobilização gessada de  patologia  osteomioarticular  com complicações neurovasculares afetando um membro",
                        mod: "Recuperação funcional pós-operatória ou pós-imobilização gessada de  patologia  osteomioarticular  com complicações neurovasculares afetando um membro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103670",
                        original: "Recuperação funcional pós-operatória ou pós-imobilização gessada de  patologia  osteomioarticular  com complicações neurovasculares afetando mais de um membro",
                        mod: "Recuperação funcional pós-operatória ou pós-imobilização gessada de  patologia  osteomioarticular  com complicações neurovasculares afetando mais de um membro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103689",
                        original: "Retardo do desenvolvimento psicomotor",
                        mod: "Retardo do desenvolvimento psicomotor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103697",
                        original: "Sequelas de traumatismos torácicos e abdominais",
                        mod: "Sequelas de traumatismos torácicos e abdominais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103700",
                        original: "Sequelas em politraumatizados (em diferentes segmentos)",
                        mod: "Sequelas em politraumatizados (em diferentes segmentos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103719",
                        original: "Sinusites",
                        mod: "Sinusites",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103727",
                        original: "Reabilitação  cardíaca supervisionada. Programa de 12 semanas. Duas a três sessões por semana (por sessão)",
                        mod: "Reabilitação  cardíaca supervisionada. Programa de 12 semanas. Duas a três sessões por semana (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103743",
                        original: "Exercícios de pleóptica",
                        mod: "Exercícios de pleóptica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20103751",
                        original: "Leme, análise de marcha",
                        mod: "Leme, análise de marcha",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104014",
                        original: "Actinoterapia (por sessão)",
                        mod: "Actinoterapia (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104022",
                        original: "Aplicação de hipossensibilizante - em consultório (AHC) exclusive o alérgeno - planejamento técnico para",
                        mod: "Aplicação de hipossensibilizante - em consultório (AHC) exclusive o alérgeno - planejamento técnico para",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104049",
                        original: "Cateterismo vesical em retenção urinária",
                        mod: "Cateterismo vesical em retenção urinária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104057",
                        original: "Cauterização química vesical",
                        mod: "Cauterização química vesical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104065",
                        original: "Cerumen - remoção (bilateral)",
                        mod: "Cerumen - remoção (bilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104073",
                        original: "Crioterapia (grupo de até 5 lesões)",
                        mod: "Crioterapia (grupo de até 5 lesões)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104081",
                        original: "Curativos em geral com anestesia, exceto queimados",
                        mod: "Curativos em geral com anestesia, exceto queimados",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104090",
                        original: "Curativo de extremidades de origem vascular",
                        mod: "Curativo de extremidades de origem vascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104103",
                        original: "Curativos em geral sem anestesia, exceto queimados",
                        mod: "Curativos em geral sem anestesia, exceto queimados",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104111",
                        original: "Dilatação uretral (sessão)",
                        mod: "Dilatação uretral (sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104120",
                        original: "Fototerapia com UVA (PUVA) (por sessão)",
                        mod: "Fototerapia com UVA (PUVA) (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104138",
                        original: "Imunoterapia específica - 30 dias - planejamento técnico",
                        mod: "Imunoterapia específica - 30 dias - planejamento técnico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104146",
                        original: "Imunoterapia inespecífica - 30 dias - planejamento técnico",
                        mod: "Imunoterapia inespecífica - 30 dias - planejamento técnico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104154",
                        original: "Instilação vesical ou uretral",
                        mod: "Instilação vesical ou uretral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104170",
                        original: "Sessão de eletroconvulsoterapia (em sala com oxímetro de pulso, monitor de ECG, EEG), sob anestesia",
                        mod: "Sessão de eletroconvulsoterapia (em sala com oxímetro de pulso, monitor de ECG, EEG), sob anestesia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104189",
                        original: "Sessão de oxigenoterapia hiperbárica (por sessão de 2 horas)",
                        mod: "Sessão de oxigenoterapia hiperbárica (por sessão de 2 horas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104197",
                        original: "Sessão de psicoterapia de casal",
                        mod: "Sessão de psicoterapia de casal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104200",
                        original: "Sessão de psicoterapia de grupo (por paciente)",
                        mod: "Sessão de psicoterapia de grupo (por paciente)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104219",
                        original: "Sessão de psicoterapia individual",
                        mod: "Sessão de psicoterapia individual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104227",
                        original: "Sessão de psicoterapia infantil",
                        mod: "Sessão de psicoterapia infantil",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104235",
                        original: "Terapia inalatória - por nebulização",
                        mod: "Terapia inalatória - por nebulização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104243",
                        original: "Terapia oncológica com altas doses - planejamento e 1º dia de tratamento",
                        mod: "Terapia oncológica com altas doses - planejamento e 1º dia de tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104251",
                        original: "Terapia oncológica com altas doses - por dia subsequente de tratamento",
                        mod: "Terapia oncológica com altas doses - por dia subsequente de tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104260",
                        original: "Terapia oncológica com aplicação de medicamentos por via intracavitária ou intratecal  - por procedimento",
                        mod: "Terapia oncológica com aplicação de medicamentos por via intracavitária ou intratecal  - por procedimento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104278",
                        original: "Terapia oncológica com aplicação intra-arterial ou intravenosa de medicamentos em infusão de duração mínima de 6 horas - planejamento e 1º dia de tratamento",
                        mod: "Terapia oncológica com aplicação intra-arterial ou intravenosa de medicamentos em infusão de duração mínima de 6 horas - planejamento e 1º dia de tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104286",
                        original: "Terapia oncológica com aplicação intra-arterial ou intravenosa de medicamentos em infusão de duração mínima de 6 horas - por dia subsequente de tratamento",
                        mod: "Terapia oncológica com aplicação intra-arterial ou intravenosa de medicamentos em infusão de duração mínima de 6 horas - por dia subsequente de tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104294",
                        original: "Terapia oncológica - planejamento e 1º dia de tratamento",
                        mod: "Terapia oncológica - planejamento e 1º dia de tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104308",
                        original: "Terapia oncológica - por dia subsequente de tratamento",
                        mod: "Terapia oncológica - por dia subsequente de tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104316",
                        original: "Curativo de ouvido (cada)",
                        mod: "Curativo de ouvido (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104324",
                        original: "Curativo oftalmológico",
                        mod: "Curativo oftalmológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104332",
                        original: "Bota de Unna - confecção",
                        mod: "Bota de Unna - confecção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104340",
                        original: "Cateterismo de canais ejaculadores",
                        mod: "Cateterismo de canais ejaculadores",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104359",
                        original: "Massagem prostática",
                        mod: "Massagem prostática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104367",
                        original: "Pneumoperitônio (por sessão)",
                        mod: "Pneumoperitônio (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104375",
                        original: "Pneumotórax artificial",
                        mod: "Pneumotórax artificial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104383",
                        original: "Pulsoterapia intravenosa (por sessão) - ambulatorial",
                        mod: "Pulsoterapia intravenosa (por sessão) - ambulatorial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104391",
                        original: "Terapia imunobiológica intravenosa (por sessão) - ambulatorial",
                        mod: "Terapia imunobiológica intravenosa (por sessão) - ambulatorial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104405",
                        original: "Terapia fotodinâmica",
                        mod: "Terapia fotodinâmica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104413",
                        original: "Estimulação magnética transcraniana superficial (repetida) - EMT",
                        mod: "Estimulação magnética transcraniana superficial (repetida) - EMT",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104421",
                        original: "Terapia imunobiológica subcutânea (por sessão) ambulatorial",
                        mod: "Terapia imunobiológica subcutânea (por sessão) ambulatorial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104430",
                        original: "Terapia antineoplásica oral para tratamento do câncer",
                        mod: "Terapia antineoplásica oral para tratamento do câncer",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20104464",
                        original: "Terapia imunoprofilática com palivizumabe para o vírus sincicial respiratório (por sessão) – ambulatorial",
                        mod: "Terapia imunoprofilática com palivizumabe para o vírus sincicial respiratório (por sessão) – ambulatorial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20105010",
                        original: "Perícia forense, por psiquiatra forense",
                        mod: "Perícia forense, por psiquiatra forense",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20105029",
                        original: "Perícia psiquiátrica administrativa",
                        mod: "Perícia psiquiátrica administrativa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20105037",
                        original: "Fornecimento de equipamentos coletores e adjuvantes para colostomia, ileostomia e urostomia, sonda vesical de demora e coletor de urina",
                        mod: "Fornecimento de equipamentos coletores e adjuvantes para colostomia, ileostomia e urostomia, sonda vesical de demora e coletor de urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201010",
                        original: "Acompanhamento clínico de transplante renal no período de internação do receptor e do doador (pós-operatório até 15 dias)",
                        mod: "Acompanhamento clínico de transplante renal no período de internação do receptor e do doador (pós-operatório até 15 dias)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201028",
                        original: "Acompanhamento peroperatório",
                        mod: "Acompanhamento peroperatório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201036",
                        original: "Assistência cardiológica peroperatória em cirurgia geral e em parto (primeira hora)",
                        mod: "Assistência cardiológica peroperatória em cirurgia geral e em parto (primeira hora)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201044",
                        original: "Assistência cardiológica peroperatória em cirurgia geral e em parto (horas suplementares) - máximo de 4 horas",
                        mod: "Assistência cardiológica peroperatória em cirurgia geral e em parto (horas suplementares) - máximo de 4 horas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201052",
                        original: "Cardioversão elétrica eletiva (avaliação clínica, eletrocardiográfica, indispensável à desfibrilação)",
                        mod: "Cardioversão elétrica eletiva (avaliação clínica, eletrocardiográfica, indispensável à desfibrilação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201060",
                        original: "Rejeição de enxerto renal - tratamento internado - avaliação clínica diária - por visita",
                        mod: "Rejeição de enxerto renal - tratamento internado - avaliação clínica diária - por visita",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201079",
                        original: "Transplante duplo rim-pâncreas - acompanhamento clínico (pós-operatório até 15 dias)",
                        mod: "Transplante duplo rim-pâncreas - acompanhamento clínico (pós-operatório até 15 dias)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201087",
                        original: "Tratamento  conservador  de  traumatismo  cranioencefálico, hipertensão  intracraniana  e  hemorragia (por dia)",
                        mod: "Tratamento  conservador  de  traumatismo  cranioencefálico, hipertensão  intracraniana  e  hemorragia (por dia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201095",
                        original: "Assistência cardiológica no pós-operatório de cirurgia cardíaca (após a alta da UTI)",
                        mod: "Assistência cardiológica no pós-operatório de cirurgia cardíaca (após a alta da UTI)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201109",
                        original: "Avaliação clínica diária enteral",
                        mod: "Avaliação clínica diária enteral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201117",
                        original: "Avaliação clínica diária parenteral",
                        mod: "Avaliação clínica diária parenteral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201125",
                        original: "Avaliação clínica diária parenteral e enteral",
                        mod: "Avaliação clínica diária parenteral e enteral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20201133",
                        original: "Acompanhamento médico na litotripsia extracorpórea",
                        mod: "Acompanhamento médico na litotripsia extracorpórea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20202016",
                        original: "Cardiotocografia anteparto",
                        mod: "Cardiotocografia anteparto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20202024",
                        original: "Cardiotocografia intraparto (por hora) até 6 horas externa",
                        mod: "Cardiotocografia intraparto (por hora) até 6 horas externa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20202032",
                        original: "Monitorização hemodinâmica invasiva (por 12 horas)",
                        mod: "Monitorização hemodinâmica invasiva (por 12 horas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20202040",
                        original: "Monitorização neurofisiológica intra-operatória",
                        mod: "Monitorização neurofisiológica intra-operatória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20202059",
                        original: "Potencial evocado intra-operatório - monitorização cirúrgica (PE/IO)",
                        mod: "Potencial evocado intra-operatório - monitorização cirúrgica (PE/IO)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20202067",
                        original: "Monitorização da pressão intracraniana (por dia)",
                        mod: "Monitorização da pressão intracraniana (por dia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20203012",
                        original: "Assistência fisiátrica respiratória em paciente internado com ventilação mecânica",
                        mod: "Assistência fisiátrica respiratória em paciente internado com ventilação mecânica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20203020",
                        original: "Eletroestimulação do assoalho pélvico e/ou outra técnica de exercícios perineais",
                        mod: "Eletroestimulação do assoalho pélvico e/ou outra técnica de exercícios perineais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20203047",
                        original: "Assistência fisiátrica respiratória em doente clínico internado",
                        mod: "Assistência fisiátrica respiratória em doente clínico internado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20203063",
                        original: "Pacientes com doença isquêmica do coração, hospitalizado, até 8 semanas de programa",
                        mod: "Pacientes com doença isquêmica do coração, hospitalizado, até 8 semanas de programa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20203071",
                        original: "Pacientes em pós-operatório de cirurgia cardíaca, hospitalizado, até 8 semanas de programa",
                        mod: "Pacientes em pós-operatório de cirurgia cardíaca, hospitalizado, até 8 semanas de programa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20204027",
                        original: "Cardioversão elétrica de emergência",
                        mod: "Cardioversão elétrica de emergência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20204035",
                        original: "Cardioversão química de arritmia paroxísta em emergência",
                        mod: "Cardioversão química de arritmia paroxísta em emergência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20204043",
                        original: "Priapismo - tratamento não cirúrgico",
                        mod: "Priapismo - tratamento não cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20204086",
                        original: "Terapia oncológica com aplicação intra-arterial de medicamentos, em regime de aplicação peroperatória, por meio de cronoinfusor ou perfusor extra-corpórea",
                        mod: "Terapia oncológica com aplicação intra-arterial de medicamentos, em regime de aplicação peroperatória, por meio de cronoinfusor ou perfusor extra-corpórea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20204159",
                        original: "Pulsoterapia intravenosa (por sessão) - hospitalar",
                        mod: "Pulsoterapia intravenosa (por sessão) - hospitalar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20204167",
                        original: "Terapia imunobiológica intravenosa (por sessão) - hospitalar",
                        mod: "Terapia imunobiológica intravenosa (por sessão) - hospitalar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20204175",
                        original: "Terapia imunobiológica subcutânea (por sessão) - hospitalar",
                        mod: "Terapia imunobiológica subcutânea (por sessão) - hospitalar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "20204183",
                        original: "Terapia imunoprofilática com palivizumabe para o vírus sincicial respiratório (por sessão) – hospitalar",
                        mod: "Terapia imunoprofilática com palivizumabe para o vírus sincicial respiratório (por sessão) – hospitalar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101018",
                        original: "Abrasão cirúrgica (por sessão)",
                        mod: "Abrasão cirúrgica (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101026",
                        original: "Alopecia parcial - exérese e sutura",
                        mod: "Alopecia parcial - exérese e sutura",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101034",
                        original: "Alopecia parcial - rotação de retalho",
                        mod: "Alopecia parcial - rotação de retalho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101042",
                        original: "Alopecia parcial - rotação múltipla de retalhos",
                        mod: "Alopecia parcial - rotação múltipla de retalhos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101050",
                        original: "Apêndice pré-auricular - ressecção",
                        mod: "Apêndice pré-auricular - ressecção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101069",
                        original: "Autonomização de retalho - por estágio",
                        mod: "Autonomização de retalho - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101077",
                        original: "Biópsia de pele, tumores superficiais, tecido celular subcutâneo, linfonodo superficial, etc",
                        mod: "Biópsia de pele, tumores superficiais, tecido celular subcutâneo, linfonodo superficial, etc",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101085",
                        original: "Biópsia de unha",
                        mod: "Biópsia de unha",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101093",
                        original: "Calosidade e/ou mal perfurante - desbastamento (por lesão)",
                        mod: "Calosidade e/ou mal perfurante - desbastamento (por lesão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101107",
                        original: "Cauterização química (por grupo de até 5 lesões)",
                        mod: "Cauterização química (por grupo de até 5 lesões)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101115",
                        original: "Cirurgia da hidrosadenite (por região)",
                        mod: "Cirurgia da hidrosadenite (por região)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101123",
                        original: "Cirurgia micrográfica de Mohs",
                        mod: "Cirurgia micrográfica de Mohs",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101140",
                        original: "Correção cirúrgica de linfedema (por estágio)",
                        mod: "Correção cirúrgica de linfedema (por estágio)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101158",
                        original: "Correção cirúrgica de sequelas de alopecia traumática com microenxertos pilosos (por região)",
                        mod: "Correção cirúrgica de sequelas de alopecia traumática com microenxertos pilosos (por região)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101166",
                        original: "Correção de deformidades nos membros com utilização de implantes",
                        mod: "Correção de deformidades nos membros com utilização de implantes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101174",
                        original: "Correção de deformidades por exérese de tumores, cicatrizes ou ferimentos com o emprego de expansores  em retalhos  musculares ou miocutâneos (por estágio)",
                        mod: "Correção de deformidades por exérese de tumores, cicatrizes ou ferimentos com o emprego de expansores  em retalhos  musculares ou miocutâneos (por estágio)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101182",
                        original: "Correção de deformidades por exérese de tumores, cicatrizes ou ferimentos, com o emprego de expansores de tecido, em retalhos cutâneos (por estágio)",
                        mod: "Correção de deformidades por exérese de tumores, cicatrizes ou ferimentos, com o emprego de expansores de tecido, em retalhos cutâneos (por estágio)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101190",
                        original: "Correção de lipodistrofia braquial, crural ou trocanteriana de membros superiores e inferiores",
                        mod: "Correção de lipodistrofia braquial, crural ou trocanteriana de membros superiores e inferiores",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101204",
                        original: "Criocirurgia (nitrogênio líquido) de neoplasias cutâneas",
                        mod: "Criocirurgia (nitrogênio líquido) de neoplasias cutâneas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101212",
                        original: "Curativo de queimaduras - por unidade topográfica (UT) ambulatorial",
                        mod: "Curativo de queimaduras - por unidade topográfica (UT) ambulatorial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101220",
                        original: "Curativo de queimaduras - por unidade topográfica (UT) hospitalar",
                        mod: "Curativo de queimaduras - por unidade topográfica (UT) hospitalar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101239",
                        original: "Curativo especial sob anestesia - por unidade topográfica (UT)",
                        mod: "Curativo especial sob anestesia - por unidade topográfica (UT)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101247",
                        original: "Curetagem e eletrocoagulação de CA de pele (por lesão)",
                        mod: "Curetagem e eletrocoagulação de CA de pele (por lesão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101255",
                        original: "Curetagem simples de lesões de pele (por grupo de até 5 lesões)",
                        mod: "Curetagem simples de lesões de pele (por grupo de até 5 lesões)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101263",
                        original: "Dermoabrasão de lesões cutâneas",
                        mod: "Dermoabrasão de lesões cutâneas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101271",
                        original: "Dermolipectomia para correção de abdome em avental",
                        mod: "Dermolipectomia para correção de abdome em avental",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101280",
                        original: "Desbridamento cirúrgico - por unidade topográfica (UT)",
                        mod: "Desbridamento cirúrgico - por unidade topográfica (UT)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101298",
                        original: "Eletrocoagulação de lesões de pele e mucosas - com ou sem curetagem (por grupo de até 5 lesões)",
                        mod: "Eletrocoagulação de lesões de pele e mucosas - com ou sem curetagem (por grupo de até 5 lesões)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101301",
                        original: "Enxerto cartilaginoso",
                        mod: "Enxerto cartilaginoso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101310",
                        original: "Enxerto composto",
                        mod: "Enxerto composto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101328",
                        original: "Enxerto de mucosa",
                        mod: "Enxerto de mucosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101336",
                        original: "Enxerto de pele (homoenxerto inclusive)",
                        mod: "Enxerto de pele (homoenxerto inclusive)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101344",
                        original: "Enxerto de pele múltiplo - por unidade topográfica (UT)",
                        mod: "Enxerto de pele múltiplo - por unidade topográfica (UT)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101352",
                        original: "Epilação por eletrólise (por sessão)",
                        mod: "Epilação por eletrólise (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101360",
                        original: "Escalpo  parcial  -  tratamento cirúrgico",
                        mod: "Escalpo  parcial  -  tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101379",
                        original: "Escalpo total - tratamento cirúrgico",
                        mod: "Escalpo total - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101387",
                        original: "Escarectomia descompressiva (pele e estruturas profundas) - por unidade topográfica (UT)",
                        mod: "Escarectomia descompressiva (pele e estruturas profundas) - por unidade topográfica (UT)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101395",
                        original: "Esfoliação química média (por sessão)",
                        mod: "Esfoliação química média (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101409",
                        original: "Esfoliação química profunda (por sessão)",
                        mod: "Esfoliação química profunda (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101417",
                        original: "Esfoliação química superficial (por sessão)",
                        mod: "Esfoliação química superficial (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101425",
                        original: "Exérese de higroma cístico",
                        mod: "Exérese de higroma cístico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101433",
                        original: "Exérese de higroma cístico no RN e lactente",
                        mod: "Exérese de higroma cístico no RN e lactente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101441",
                        original: "Exérese de lesão com auto-enxertia",
                        mod: "Exérese de lesão com auto-enxertia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101450",
                        original: "Exérese e sutura de lesões (circulares ou não) com rotação de retalhos cutâneos",
                        mod: "Exérese e sutura de lesões (circulares ou não) com rotação de retalhos cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101468",
                        original: "Exérese de lesão / tumor de pele e mucosas",
                        mod: "Exérese de lesão / tumor de pele e mucosas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101476",
                        original: "Exérese de tumor e rotação de retalho músculo-cutâneo",
                        mod: "Exérese de tumor e rotação de retalho músculo-cutâneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101484",
                        original: "Exérese de unha",
                        mod: "Exérese de unha",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101492",
                        original: "Exérese e sutura simples de pequenas lesões (por grupo de até 5 lesões)",
                        mod: "Exérese e sutura simples de pequenas lesões (por grupo de até 5 lesões)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101506",
                        original: "Exérese tangencial (shaving) - (por grupo de até 5 lesões)",
                        mod: "Exérese tangencial (shaving) - (por grupo de até 5 lesões)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101514",
                        original: "Expansão tissular (por sessão)",
                        mod: "Expansão tissular (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101522",
                        original: "Extensos ferimentos, cicatrizes ou tumores - excisão e retalhos cutâneos da região",
                        mod: "Extensos ferimentos, cicatrizes ou tumores - excisão e retalhos cutâneos da região",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101530",
                        original: "Extensos ferimentos, cicatrizes ou tumores - exérese e emprego de retalhos cutâneos ou musculares  cruzados (por estágio)",
                        mod: "Extensos ferimentos, cicatrizes ou tumores - exérese e emprego de retalhos cutâneos ou musculares  cruzados (por estágio)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101549",
                        original: "Extensos ferimentos, cicatrizes ou tumores - exérese e retalhos cutâneos à distância",
                        mod: "Extensos ferimentos, cicatrizes ou tumores - exérese e retalhos cutâneos à distância",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101557",
                        original: "Extensos ferimentos, cicatrizes ou tumores - exérese e rotação de retalho fasciocutâneo ou axial",
                        mod: "Extensos ferimentos, cicatrizes ou tumores - exérese e rotação de retalho fasciocutâneo ou axial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101565",
                        original: "Extensos ferimentos, cicatrizes ou tumores - exérese e rotação de retalhos miocutâneos",
                        mod: "Extensos ferimentos, cicatrizes ou tumores - exérese e rotação de retalhos miocutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101573",
                        original: "Extensos ferimentos, cicatrizes ou tumores - exérese e rotação de retalhos musculares",
                        mod: "Extensos ferimentos, cicatrizes ou tumores - exérese e rotação de retalhos musculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101581",
                        original: "Extensos ferimentos, cicatrizes, ou tumores - exérese e enxerto cutâneo",
                        mod: "Extensos ferimentos, cicatrizes, ou tumores - exérese e enxerto cutâneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101590",
                        original: "Face - biópsia",
                        mod: "Face - biópsia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101603",
                        original: "Ferimentos infectados e mordidas de animais (desbridamento)",
                        mod: "Ferimentos infectados e mordidas de animais (desbridamento)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101611",
                        original: "Incisão e drenagem de tenossinovites purulentas",
                        mod: "Incisão e drenagem de tenossinovites purulentas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101620",
                        original: "Incisão e drenagem de abscesso, hematoma ou panarício",
                        mod: "Incisão e drenagem de abscesso, hematoma ou panarício",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101638",
                        original: "Incisão e drenagem de flegmão",
                        mod: "Incisão e drenagem de flegmão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101646",
                        original: "Infiltração  intralesional, cicatricial / hemangiomas - por sessão",
                        mod: "Infiltração  intralesional, cicatricial / hemangiomas - por sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101654",
                        original: "Lasercirurgia (por sessão)",
                        mod: "Lasercirurgia (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101662",
                        original: "Matricectomia por dobra ungueal",
                        mod: "Matricectomia por dobra ungueal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101670",
                        original: "Plástica em Z ou W",
                        mod: "Plástica em Z ou W",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101689",
                        original: "Reconstrução com retalhos de gálea aponeurótica",
                        mod: "Reconstrução com retalhos de gálea aponeurótica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101697",
                        original: "Retalho composto (incluindo cartilagem ou osso)",
                        mod: "Retalho composto (incluindo cartilagem ou osso)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101700",
                        original: "Retalho local ou regional",
                        mod: "Retalho local ou regional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101719",
                        original: "Retalho muscular ou miocutâneo",
                        mod: "Retalho muscular ou miocutâneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101735",
                        original: "Retirada de corpo estranho subcutâneo",
                        mod: "Retirada de corpo estranho subcutâneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101743",
                        original: "Retração cicatricial de axila - tratamento cirúrgico",
                        mod: "Retração cicatricial de axila - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101751",
                        original: "Retração cicatricial de zona de flexão e extensão de membros superiores e inferiores - tratamento cirúrgico",
                        mod: "Retração cicatricial de zona de flexão e extensão de membros superiores e inferiores - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101760",
                        original: "Retração cicatricial do cotovelo - tratamento cirúrgico",
                        mod: "Retração cicatricial do cotovelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101778",
                        original: "Retração de aponevrose palmar (Dupuytren) - tratamento cirúrgico",
                        mod: "Retração de aponevrose palmar (Dupuytren) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101786",
                        original: "Sutura de extensos ferimentos com ou sem desbridamento",
                        mod: "Sutura de extensos ferimentos com ou sem desbridamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101794",
                        original: "Sutura de pequenos ferimentos com ou sem desbridamento",
                        mod: "Sutura de pequenos ferimentos com ou sem desbridamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101808",
                        original: "Transecção de retalho",
                        mod: "Transecção de retalho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101816",
                        original: "Transferência intermediária de retalho",
                        mod: "Transferência intermediária de retalho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101824",
                        original: "Tratamento cirúrgico de bridas constrictivas",
                        mod: "Tratamento cirúrgico de bridas constrictivas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101832",
                        original: "Tratamento cirúrgico de grandes hemangiomas",
                        mod: "Tratamento cirúrgico de grandes hemangiomas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101840",
                        original: "Tratamento da miiase furunculóide (por lesão)",
                        mod: "Tratamento da miiase furunculóide (por lesão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101859",
                        original: "Tratamento de anomalias pilosas a laser/photoderm - por sessão",
                        mod: "Tratamento de anomalias pilosas a laser/photoderm - por sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101867",
                        original: "Tratamento de escaras ou ulcerações com enxerto de pele",
                        mod: "Tratamento de escaras ou ulcerações com enxerto de pele",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101875",
                        original: "Tratamento de escaras ou ulcerações com retalhos cutâneos locais",
                        mod: "Tratamento de escaras ou ulcerações com retalhos cutâneos locais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101883",
                        original: "Tratamento de escaras ou ulcerações com retalhos miocutâneos ou musculares",
                        mod: "Tratamento de escaras ou ulcerações com retalhos miocutâneos ou musculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101891",
                        original: "Tratamento de fístula cutânea",
                        mod: "Tratamento de fístula cutânea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101905",
                        original: "Tratamento de lesões cutâneas e vasculares a laser/photoderm - por sessão",
                        mod: "Tratamento de lesões cutâneas e vasculares a laser/photoderm - por sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101913",
                        original: "TU partes moles - exérese",
                        mod: "TU partes moles - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101921",
                        original: "Exérese e sutura de hemangioma, linfangioma ou nevus (por grupo de até 5 lesões)",
                        mod: "Exérese e sutura de hemangioma, linfangioma ou nevus (por grupo de até 5 lesões)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101930",
                        original: "Abscesso de unha (drenagem) - tratamento cirúrgico",
                        mod: "Abscesso de unha (drenagem) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101948",
                        original: "Cantoplastia ungueal",
                        mod: "Cantoplastia ungueal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101956",
                        original: "Unha (enxerto) - tratamento cirúrgico",
                        mod: "Unha (enxerto) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30101964",
                        original: "Retalho expandido",
                        mod: "Retalho expandido",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30201012",
                        original: "Biópsia de lábio",
                        mod: "Biópsia de lábio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30201020",
                        original: "Excisão com plástica de vermelhão",
                        mod: "Excisão com plástica de vermelhão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30201039",
                        original: "Excisão com reconstrução à custa de retalhos",
                        mod: "Excisão com reconstrução à custa de retalhos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30201047",
                        original: "Excisão com reconstrução total",
                        mod: "Excisão com reconstrução total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30201055",
                        original: "Excisão em cunha",
                        mod: "Excisão em cunha",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30201063",
                        original: "Frenotomia labial",
                        mod: "Frenotomia labial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30201071",
                        original: "Queiloplastia para fissura labial unilateral - por estágio",
                        mod: "Queiloplastia para fissura labial unilateral - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30201080",
                        original: "Reconstrução de sulco gengivo-labial",
                        mod: "Reconstrução de sulco gengivo-labial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30201098",
                        original: "Reconstrução total do lábio",
                        mod: "Reconstrução total do lábio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30201101",
                        original: "Tratamento cirúrgico da macrostomia",
                        mod: "Tratamento cirúrgico da macrostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30201110",
                        original: "Tratamento cirúrgico da microstomia",
                        mod: "Tratamento cirúrgico da microstomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30201128",
                        original: "Reconstrução parcial do lábio",
                        mod: "Reconstrução parcial do lábio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202019",
                        original: "Alongamento cirúrgico do palato mole",
                        mod: "Alongamento cirúrgico do palato mole",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202027",
                        original: "Biópsia de boca",
                        mod: "Biópsia de boca",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202035",
                        original: "Excisão de lesão maligna com reconstrução à custa de retalhos locais",
                        mod: "Excisão de lesão maligna com reconstrução à custa de retalhos locais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202043",
                        original: "Excisão de tumor de boca com mandibulectomia",
                        mod: "Excisão de tumor de boca com mandibulectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202051",
                        original: "Exérese de tumor e enxerto cutâneo ou mucoso",
                        mod: "Exérese de tumor e enxerto cutâneo ou mucoso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202060",
                        original: "Fístula orofacial - tratamento cirúrgico",
                        mod: "Fístula orofacial - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202078",
                        original: "Glossectomia subtotal ou total, com ou sem mandibulectomia",
                        mod: "Glossectomia subtotal ou total, com ou sem mandibulectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202086",
                        original: "Palato-queiloplastia unilateral",
                        mod: "Palato-queiloplastia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202094",
                        original: "Palatoplastia com enxerto ósseo",
                        mod: "Palatoplastia com enxerto ósseo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202108",
                        original: "Palatoplastia com retalho faríngeo",
                        mod: "Palatoplastia com retalho faríngeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202116",
                        original: "Palatoplastia com retalho miomucoso",
                        mod: "Palatoplastia com retalho miomucoso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202124",
                        original: "Palatoplastia parcial",
                        mod: "Palatoplastia parcial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202132",
                        original: "Palatoplastia total",
                        mod: "Palatoplastia total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202140",
                        original: "Plástica do ducto parotídeo",
                        mod: "Plástica do ducto parotídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30202159",
                        original: "Laserterapia para o tratamento da mucosite oral / orofaringe, por sessão",
                        mod: "Laserterapia para o tratamento da mucosite oral / orofaringe, por sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30203015",
                        original: "Frenotomia lingual",
                        mod: "Frenotomia lingual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30203023",
                        original: "Tumor de língua - tratamento cirúrgico",
                        mod: "Tumor de língua - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30203031",
                        original: "Biópsia de língua",
                        mod: "Biópsia de língua",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30204011",
                        original: "Biópsia de glândula salivar",
                        mod: "Biópsia de glândula salivar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30204020",
                        original: "Excisão de glândula submandibular",
                        mod: "Excisão de glândula submandibular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30204038",
                        original: "Exérese de rânula ou mucocele",
                        mod: "Exérese de rânula ou mucocele",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30204046",
                        original: "Parotidectomia parcial com conservação do nervo facial",
                        mod: "Parotidectomia parcial com conservação do nervo facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30204054",
                        original: "Parotidectomia total ampliada com ou sem reconstrução com retalhos locais",
                        mod: "Parotidectomia total ampliada com ou sem reconstrução com retalhos locais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30204062",
                        original: "Parotidectomia total com conservação do nervo facial",
                        mod: "Parotidectomia total com conservação do nervo facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30204070",
                        original: "Parotidectomia total com reconstrução do nervo facial",
                        mod: "Parotidectomia total com reconstrução do nervo facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30204089",
                        original: "Parotidectomia total com sacrificio do nervo facial, sem reconstrução",
                        mod: "Parotidectomia total com sacrificio do nervo facial, sem reconstrução",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30204097",
                        original: "Plastia de ducto salivar ou exérese de cálculo ou de rânula salivar",
                        mod: "Plastia de ducto salivar ou exérese de cálculo ou de rânula salivar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30204100",
                        original: "Ressecção de tumor de glândula sublingual",
                        mod: "Ressecção de tumor de glândula sublingual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205018",
                        original: "Abscesso faríngeo - qualquer área",
                        mod: "Abscesso faríngeo - qualquer área",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205026",
                        original: "Adeno tonsilectomia - revisão cirúrgica",
                        mod: "Adeno tonsilectomia - revisão cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205034",
                        original: "Adeno-amigdalectomia",
                        mod: "Adeno-amigdalectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205042",
                        original: "Adenoidectomia",
                        mod: "Adenoidectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205050",
                        original: "Amigdalectomia das palatinas",
                        mod: "Amigdalectomia das palatinas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205069",
                        original: "Amigdalectomia lingual",
                        mod: "Amigdalectomia lingual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205077",
                        original: "Biópsia do cavum, orofaringe ou hipofaringe",
                        mod: "Biópsia do cavum, orofaringe ou hipofaringe",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205085",
                        original: "Cauterização (qualquer técnica) por sessão",
                        mod: "Cauterização (qualquer técnica) por sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205093",
                        original: "Corpo estranho de faringe - retirada em consultório",
                        mod: "Corpo estranho de faringe - retirada em consultório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205107",
                        original: "Corpo estranho de faringe - retirada sob anestesia geral",
                        mod: "Corpo estranho de faringe - retirada sob anestesia geral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205115",
                        original: "Criptólise amigdaliana",
                        mod: "Criptólise amigdaliana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205140",
                        original: "Faringolaringectomia",
                        mod: "Faringolaringectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205158",
                        original: "Faringolaringoesofagectomia total",
                        mod: "Faringolaringoesofagectomia total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205166",
                        original: "Ressecção de nasoangiofibroma",
                        mod: "Ressecção de nasoangiofibroma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205174",
                        original: "Ressecção de tumor de faringe (via bucal ou nasal)",
                        mod: "Ressecção de tumor de faringe (via bucal ou nasal)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205182",
                        original: "Ressecção de tumor de faringe com acesso por faringotomia ou por retalho jugal",
                        mod: "Ressecção de tumor de faringe com acesso por faringotomia ou por retalho jugal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205190",
                        original: "Ressecção de tumor de faringe com mandibulectomia",
                        mod: "Ressecção de tumor de faringe com mandibulectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205204",
                        original: "Ressecção de tumor de faringe por mandibulotomia",
                        mod: "Ressecção de tumor de faringe por mandibulotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205212",
                        original: "Ressecção de tumor de nasofaringe via endoscópica",
                        mod: "Ressecção de tumor de nasofaringe via endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205220",
                        original: "Tonsilectomia a laser",
                        mod: "Tonsilectomia a laser",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205239",
                        original: "Tumor de boca ou faringe - ressecção",
                        mod: "Tumor de boca ou faringe - ressecção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205247",
                        original: "Uvulopalatofaringoplastia (qualquer técnica)",
                        mod: "Uvulopalatofaringoplastia (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205263",
                        original: "Uvulopalatofaringoplastia por radiofrequência",
                        mod: "Uvulopalatofaringoplastia por radiofrequência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205271",
                        original: "Adenoidectomia por videoendoscopia",
                        mod: "Adenoidectomia por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30205280",
                        original: "Ressecção de nasoangiofibroma por videoendoscopia",
                        mod: "Ressecção de nasoangiofibroma por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206014",
                        original: "Alargamento de traqueostomia",
                        mod: "Alargamento de traqueostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206022",
                        original: "Aritenoidectomia microcirúrgica",
                        mod: "Aritenoidectomia microcirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206030",
                        original: "Aritenoidectomia ou aritenopexia via externa",
                        mod: "Aritenoidectomia ou aritenopexia via externa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206049",
                        original: "Confecção de fístula tráqueo-esofágica para prótese fonatória com miotomia faríngea",
                        mod: "Confecção de fístula tráqueo-esofágica para prótese fonatória com miotomia faríngea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206065",
                        original: "Exérese de tumor por via endoscópica",
                        mod: "Exérese de tumor por via endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206103",
                        original: "Injeção intralaríngea de toxina botulínica",
                        mod: "Injeção intralaríngea de toxina botulínica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206120",
                        original: "Laringectomia parcial",
                        mod: "Laringectomia parcial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206138",
                        original: "Laringectomia total",
                        mod: "Laringectomia total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206170",
                        original: "Laringofissura (inclusive com cordectomia)",
                        mod: "Laringofissura (inclusive com cordectomia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206200",
                        original: "Laringotraqueoplastia",
                        mod: "Laringotraqueoplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206219",
                        original: "Microcirurgia com laser para remoção de lesões malignas",
                        mod: "Microcirurgia com laser para remoção de lesões malignas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206227",
                        original: "Microcirurgia com uso de laser para ressecção de lesões benignas",
                        mod: "Microcirurgia com uso de laser para ressecção de lesões benignas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206235",
                        original: "Microcirurgia para decorticação ou tratamento de edema de Reinke",
                        mod: "Microcirurgia para decorticação ou tratamento de edema de Reinke",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206243",
                        original: "Microcirurgia para remoção de cisto ou lesão intracordal",
                        mod: "Microcirurgia para remoção de cisto ou lesão intracordal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206251",
                        original: "Microcirurgia para ressecção de papiloma",
                        mod: "Microcirurgia para ressecção de papiloma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206260",
                        original: "Microcirurgia para ressecção de pólipo, nódulo ou granuloma",
                        mod: "Microcirurgia para ressecção de pólipo, nódulo ou granuloma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206278",
                        original: "Microcirurgia para tratamento de paralisia de prega vocal (inclui injeção de materiais)",
                        mod: "Microcirurgia para tratamento de paralisia de prega vocal (inclui injeção de materiais)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206294",
                        original: "Reconstrução para fonação após laringectomia",
                        mod: "Reconstrução para fonação após laringectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206308",
                        original: "Tiroplastia tipo 1 com rotação de aritenóide",
                        mod: "Tiroplastia tipo 1 com rotação de aritenóide",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206316",
                        original: "Tiroplastia tipo 1 simples",
                        mod: "Tiroplastia tipo 1 simples",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206324",
                        original: "Tiroplastia tipo 2 ou 3",
                        mod: "Tiroplastia tipo 2 ou 3",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206359",
                        original: "Tratamento cirúrgico da estenose laringo-traqueal",
                        mod: "Tratamento cirúrgico da estenose laringo-traqueal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30206367",
                        original: "Tratamento cirúrgico de trauma laríngeo (agudo)",
                        mod: "Tratamento cirúrgico de trauma laríngeo (agudo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207010",
                        original: "Redução de fratura do malar (sem fixação)",
                        mod: "Redução de fratura do malar (sem fixação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207029",
                        original: "Redução de fratura do malar (com fixação)",
                        mod: "Redução de fratura do malar (com fixação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207037",
                        original: "Redução de fratura de seio frontal (acesso frontal)",
                        mod: "Redução de fratura de seio frontal (acesso frontal)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207045",
                        original: "Redução de fratura de seio frontal (acesso coronal)",
                        mod: "Redução de fratura de seio frontal (acesso coronal)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207061",
                        original: "Fratura do arco zigomático - redução instrumental sem fixação",
                        mod: "Fratura do arco zigomático - redução instrumental sem fixação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207070",
                        original: "Fratura do arco zigomático - redução cirúrgica com fixação",
                        mod: "Fratura do arco zigomático - redução cirúrgica com fixação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207088",
                        original: "Fratura  simples de mandíbula com contenção e bloqueio intermaxilar eventual",
                        mod: "Fratura  simples de mandíbula com contenção e bloqueio intermaxilar eventual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207096",
                        original: "Fratura simples de mandíbula - redução cirúrgica com fixação óssea e bloqueio intermaxilar eventual",
                        mod: "Fratura simples de mandíbula - redução cirúrgica com fixação óssea e bloqueio intermaxilar eventual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207100",
                        original: "Fratura naso etmóido órbito-etmoidal",
                        mod: "Fratura naso etmóido órbito-etmoidal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207118",
                        original: "Fratura cominutiva de mandíbula - redução cirúrgica com fixação óssea e bloqueio intermaxilar eventual",
                        mod: "Fratura cominutiva de mandíbula - redução cirúrgica com fixação óssea e bloqueio intermaxilar eventual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207126",
                        original: "Fraturas complexas de mandíbula - redução cirúrgica com fixação óssea e eventual bloqueio intermaxilar",
                        mod: "Fraturas complexas de mandíbula - redução cirúrgica com fixação óssea e eventual bloqueio intermaxilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207134",
                        original: "Fraturas alveolares - fixação com aparelho e contenção",
                        mod: "Fraturas alveolares - fixação com aparelho e contenção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207142",
                        original: "Fratura de maxila, tipo Lefort I e II - redução e aplicação de levantamento zigomático-maxilar com bloqueio intermaxilar eventual",
                        mod: "Fratura de maxila, tipo Lefort I e II - redução e aplicação de levantamento zigomático-maxilar com bloqueio intermaxilar eventual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207150",
                        original: "Fratura de maxila, tipo Lefort III - redução e aplicação de levantamento crânio-maxilar com bloqueio intermaxilar eventual",
                        mod: "Fratura de maxila, tipo Lefort III - redução e aplicação de levantamento crânio-maxilar com bloqueio intermaxilar eventual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207169",
                        original: "Fratura Lefort I - fixação cirúrgica com síntese óssea, levantamento e bloqueio intermaxilar eventual",
                        mod: "Fratura Lefort I - fixação cirúrgica com síntese óssea, levantamento e bloqueio intermaxilar eventual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207177",
                        original: "Fratura Lefort II - fixação cirúrgica com síntese óssea, levantamento e bloqueio intermaxilar eventual",
                        mod: "Fratura Lefort II - fixação cirúrgica com síntese óssea, levantamento e bloqueio intermaxilar eventual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207185",
                        original: "Fratura Lefort III - fixação cirúrgica com síntese óssea, levantamento crânio-maxilar e bloqueio intermaxilar eventual",
                        mod: "Fratura Lefort III - fixação cirúrgica com síntese óssea, levantamento crânio-maxilar e bloqueio intermaxilar eventual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207193",
                        original: "Fraturas múltiplas de terço médio da face:fixação cirúrgica com síntese óssea, levantamento crânio maxilar e bloqueio intermaxilar",
                        mod: "Fraturas múltiplas de terço médio da face:fixação cirúrgica com síntese óssea, levantamento crânio maxilar e bloqueio intermaxilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207207",
                        original: "Fraturas complexas do terço médio da face, fixação cirúrgica com síntese, levantamento crânio-maxilar, enxerto ósseo, halo craniano eventual",
                        mod: "Fraturas complexas do terço médio da face, fixação cirúrgica com síntese, levantamento crânio-maxilar, enxerto ósseo, halo craniano eventual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207215",
                        original: "Retirada dos meios de fixação (na face)",
                        mod: "Retirada dos meios de fixação (na face)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207223",
                        original: "Tratamento conservador de fratura de ossos",
                        mod: "Tratamento conservador de fratura de ossos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30207231",
                        original: "Redução de luxação do ATM",
                        mod: "Redução de luxação do ATM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208017",
                        original: "Artroplastia para luxação recidivante da articulação têmporo-mandibular",
                        mod: "Artroplastia para luxação recidivante da articulação têmporo-mandibular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208025",
                        original: "Osteoplastia para prognatismo, micrognatismo ou laterognatismo",
                        mod: "Osteoplastia para prognatismo, micrognatismo ou laterognatismo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208033",
                        original: "Osteotomias alvéolo palatinas",
                        mod: "Osteotomias alvéolo palatinas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208041",
                        original: "Osteotomias segmentares da maxila ou malar",
                        mod: "Osteotomias segmentares da maxila ou malar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208050",
                        original: "Osteotomia tipo Lefort I",
                        mod: "Osteotomia tipo Lefort I",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208068",
                        original: "Osteotomia tipo Lefort II",
                        mod: "Osteotomia tipo Lefort II",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208076",
                        original: "Osteotomia tipo Lefort III - extracraniana",
                        mod: "Osteotomia tipo Lefort III - extracraniana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208084",
                        original: "Osteotomia crânio-maxilares complexas",
                        mod: "Osteotomia crânio-maxilares complexas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208092",
                        original: "Redução simples da luxação da articulação têmporo-mandibular com fixação intermaxilar",
                        mod: "Redução simples da luxação da articulação têmporo-mandibular com fixação intermaxilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208106",
                        original: "Reconstrução parcial da mandíbula com enxerto ósseo",
                        mod: "Reconstrução parcial da mandíbula com enxerto ósseo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208114",
                        original: "Reconstrução total de mandíbula com prótese e ou enxerto ósseo",
                        mod: "Reconstrução total de mandíbula com prótese e ou enxerto ósseo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208122",
                        original: "Tratamento cirúrgico de anquilose da articulação têmporo-mandibular",
                        mod: "Tratamento cirúrgico de anquilose da articulação têmporo-mandibular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208130",
                        original: "Translocação etmóido orbital para tratamento do hipertelorismo    miocutâneo associado a expansor de tecido - por lado",
                        mod: "Translocação etmóido orbital para tratamento do hipertelorismo    miocutâneo associado a expansor de tecido - por lado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30208149",
                        original: "Tratamento cirúrgico ou artroplastia para luxação da articulação têmporo mandibular por artroscopia",
                        mod: "Tratamento cirúrgico ou artroplastia para luxação da articulação têmporo mandibular por artroscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30209013",
                        original: "Osteoplastias etmóido orbitais",
                        mod: "Osteoplastias etmóido orbitais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30209021",
                        original: "Osteoplastias de mandíbula",
                        mod: "Osteoplastias de mandíbula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30209030",
                        original: "Osteoplastias do arco zigomático",
                        mod: "Osteoplastias do arco zigomático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30209048",
                        original: "Osteoplastias da órbita",
                        mod: "Osteoplastias da órbita",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30209056",
                        original: "Correção cirúrgica de depressão (afundamento) da região frontal",
                        mod: "Correção cirúrgica de depressão (afundamento) da região frontal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30210011",
                        original: "Hemiatrofia facial, correção com enxerto de gordura ou implante",
                        mod: "Hemiatrofia facial, correção com enxerto de gordura ou implante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30210020",
                        original: "Correção de tumores, cicatrizes ou ferimentos com o auxílio de expansores de tecidos - por estágio",
                        mod: "Correção de tumores, cicatrizes ou ferimentos com o auxílio de expansores de tecidos - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30210038",
                        original: "Paralisia facial - reanimação com o músculo temporal (região oral), sem neurotização",
                        mod: "Paralisia facial - reanimação com o músculo temporal (região oral), sem neurotização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30210046",
                        original: "Paralisia facial - reanimação com o músculo temporal (região orbital), sem neurotização",
                        mod: "Paralisia facial - reanimação com o músculo temporal (região orbital), sem neurotização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30210054",
                        original: "Paralisia facial - reanimação com o músculo temporal (região oral) com neurotização",
                        mod: "Paralisia facial - reanimação com o músculo temporal (região oral) com neurotização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30210062",
                        original: "Paralisia facial - reanimação com o músculo temporal (região orbital e oral) com neurotização",
                        mod: "Paralisia facial - reanimação com o músculo temporal (região orbital e oral) com neurotização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30210070",
                        original: "Reconstrução com retalhos axiais supra-orbitais e supratrocleares",
                        mod: "Reconstrução com retalhos axiais supra-orbitais e supratrocleares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30210089",
                        original: "Reconstrução com retalho axial da artéria temporal superficial",
                        mod: "Reconstrução com retalho axial da artéria temporal superficial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30210097",
                        original: "Reconstrução com retalhos em VY de pedículo subarterial",
                        mod: "Reconstrução com retalhos em VY de pedículo subarterial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30210100",
                        original: "Reconstrução com rotação do músculo temporal",
                        mod: "Reconstrução com rotação do músculo temporal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30210119",
                        original: "Exérese de tumor maligno de pele",
                        mod: "Exérese de tumor maligno de pele",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30210127",
                        original: "Exérese de tumor benigno, cisto ou fístula",
                        mod: "Exérese de tumor benigno, cisto ou fístula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30211018",
                        original: "Biópsia de mandíbula",
                        mod: "Biópsia de mandíbula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30211034",
                        original: "Ressecção de tumor de mandíbula com desarticulação de ATM",
                        mod: "Ressecção de tumor de mandíbula com desarticulação de ATM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30211042",
                        original: "Hemimandibulectomia ou ressecção segmentar ou seccional da mandíbula",
                        mod: "Hemimandibulectomia ou ressecção segmentar ou seccional da mandíbula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30211050",
                        original: "Mandibulectomia total",
                        mod: "Mandibulectomia total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30211069",
                        original: "Mandibulectomia com ou sem esvaziamento orbitário e rinotomia lateral",
                        mod: "Mandibulectomia com ou sem esvaziamento orbitário e rinotomia lateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212014",
                        original: "Cervicotomia exploradora",
                        mod: "Cervicotomia exploradora",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212022",
                        original: "Drenagem de abscesso cervical profundo",
                        mod: "Drenagem de abscesso cervical profundo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212030",
                        original: "Esvaziamento cervical radical (especificar o lado)",
                        mod: "Esvaziamento cervical radical (especificar o lado)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212049",
                        original: "Esvaziamento cervical radical ampliado",
                        mod: "Esvaziamento cervical radical ampliado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212057",
                        original: "Esvaziamento cervical seletivo (especificar o lado)",
                        mod: "Esvaziamento cervical seletivo (especificar o lado)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212065",
                        original: "Exérese de cisto branquial",
                        mod: "Exérese de cisto branquial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212073",
                        original: "Exérese de cisto tireoglosso",
                        mod: "Exérese de cisto tireoglosso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212081",
                        original: "Exérese de tumor benigno, cisto ou fístula cervical",
                        mod: "Exérese de tumor benigno, cisto ou fístula cervical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212090",
                        original: "Linfadenectomia profunda",
                        mod: "Linfadenectomia profunda",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212103",
                        original: "Linfadenectomia superficial",
                        mod: "Linfadenectomia superficial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212111",
                        original: "Neuroblastoma cervical - exérese",
                        mod: "Neuroblastoma cervical - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212120",
                        original: "Punção-biópsia de pescoço",
                        mod: "Punção-biópsia de pescoço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212138",
                        original: "Reconstrução de esôfago cervical",
                        mod: "Reconstrução de esôfago cervical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212146",
                        original: "Ressecção de tumor de corpo carotídeo",
                        mod: "Ressecção de tumor de corpo carotídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212154",
                        original: "Retração cicatricial cervical - por estágio",
                        mod: "Retração cicatricial cervical - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212162",
                        original: "Retração cicatricial cervical com emprego de expansores de tecido - por estágio",
                        mod: "Retração cicatricial cervical com emprego de expansores de tecido - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212170",
                        original: "Torcicolo congênito - tratamento cirúrgico",
                        mod: "Torcicolo congênito - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212189",
                        original: "Tratamento cirúrgico da lipomatose cervical",
                        mod: "Tratamento cirúrgico da lipomatose cervical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30212197",
                        original: "Tratamento cirúrgico de fístula com retalho cutâneo",
                        mod: "Tratamento cirúrgico de fístula com retalho cutâneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30213010",
                        original: "Biópsia de tireóide",
                        mod: "Biópsia de tireóide",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30213029",
                        original: "Bócio mergulhante: extirpação por acesso cérvico-torácico",
                        mod: "Bócio mergulhante: extirpação por acesso cérvico-torácico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30213037",
                        original: "Istmectomia ou nodulectomia",
                        mod: "Istmectomia ou nodulectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30213045",
                        original: "Tireoidectomia parcial",
                        mod: "Tireoidectomia parcial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30213053",
                        original: "Tireoidectomia total",
                        mod: "Tireoidectomia total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30214017",
                        original: "Biópsia de paratireóide",
                        mod: "Biópsia de paratireóide",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30214025",
                        original: "Paratireoidectomia com toracotomia",
                        mod: "Paratireoidectomia com toracotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30214033",
                        original: "Reimplante de paratireóide previamente preservada",
                        mod: "Reimplante de paratireóide previamente preservada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30214041",
                        original: "Tratamento cirúrgico do hiperparatireoidismo primário",
                        mod: "Tratamento cirúrgico do hiperparatireoidismo primário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30214050",
                        original: "Tratamento cirúrgico do hiperparatireoidismo secundário",
                        mod: "Tratamento cirúrgico do hiperparatireoidismo secundário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30215013",
                        original: "Cranioplastia",
                        mod: "Cranioplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30215021",
                        original: "Craniotomia descompressiva",
                        mod: "Craniotomia descompressiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30215030",
                        original: "Craniotomia para tumores ósseos",
                        mod: "Craniotomia para tumores ósseos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30215048",
                        original: "Reconstrução craniana ou craniofacial",
                        mod: "Reconstrução craniana ou craniofacial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30215056",
                        original: "Retirada de cranioplastia",
                        mod: "Retirada de cranioplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30215072",
                        original: "Tratamento cirúrgico da craniossinostose",
                        mod: "Tratamento cirúrgico da craniossinostose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30215080",
                        original: "Tratamento cirúrgico da fratura do crânio - afundamento",
                        mod: "Tratamento cirúrgico da fratura do crânio - afundamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30215099",
                        original: "Tratamento cirúrgico da osteomielite de crânio",
                        mod: "Tratamento cirúrgico da osteomielite de crânio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301017",
                        original: "Abscesso de pálpebra - drenagem",
                        mod: "Abscesso de pálpebra - drenagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301025",
                        original: "Biópsia de pálpebra",
                        mod: "Biópsia de pálpebra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301033",
                        original: "Blefarorrafia",
                        mod: "Blefarorrafia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301041",
                        original: "Calázio - exérese",
                        mod: "Calázio - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301050",
                        original: "Cantoplastia lateral",
                        mod: "Cantoplastia lateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301068",
                        original: "Cantoplastia medial",
                        mod: "Cantoplastia medial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301076",
                        original: "Coloboma - com plástica",
                        mod: "Coloboma - com plástica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301084",
                        original: "Correção cirúrgica de ectrópio ou entrópio",
                        mod: "Correção cirúrgica de ectrópio ou entrópio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301092",
                        original: "Correção de bolsas palpebrais - unilateral",
                        mod: "Correção de bolsas palpebrais - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301106",
                        original: "Dermatocalaze ou blefarocalaze exérese - unilateral",
                        mod: "Dermatocalaze ou blefarocalaze exérese - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301114",
                        original: "Epicanto - correção cirúrgica - unilateral",
                        mod: "Epicanto - correção cirúrgica - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301122",
                        original: "Epilação",
                        mod: "Epilação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301130",
                        original: "Epilação de cílios (diatermo-coagulação)",
                        mod: "Epilação de cílios (diatermo-coagulação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301149",
                        original: "Fissura palpebral - correção cirúrgica",
                        mod: "Fissura palpebral - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301157",
                        original: "Lagoftalmo - correção cirúrgica",
                        mod: "Lagoftalmo - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301165",
                        original: "Pálpebra - reconstrução parcial (com ou sem ressecção de tumor)",
                        mod: "Pálpebra - reconstrução parcial (com ou sem ressecção de tumor)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301173",
                        original: "Pálpebra - reconstrução total (com ou sem ressecção de tumor) - por estágio",
                        mod: "Pálpebra - reconstrução total (com ou sem ressecção de tumor) - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301181",
                        original: "Ptose palpebral - correção cirúrgica - unilateral",
                        mod: "Ptose palpebral - correção cirúrgica - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301190",
                        original: "Ressecção de tumores palpebrais",
                        mod: "Ressecção de tumores palpebrais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301203",
                        original: "Retração palpebral - correção cirúrgica",
                        mod: "Retração palpebral - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301211",
                        original: "Simbléfaro com ou sem enxerto - correção cirúrgica",
                        mod: "Simbléfaro com ou sem enxerto - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301220",
                        original: "Supercílio - reconstrução total",
                        mod: "Supercílio - reconstrução total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301238",
                        original: "Sutura de pálpebra",
                        mod: "Sutura de pálpebra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301246",
                        original: "Tarsorrafia",
                        mod: "Tarsorrafia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301254",
                        original: "Telecanto - correção cirúrgica - unilateral",
                        mod: "Telecanto - correção cirúrgica - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301262",
                        original: "Triquíase com ou sem enxerto",
                        mod: "Triquíase com ou sem enxerto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30301270",
                        original: "Xantelasma palpebral - exérese - unilateral",
                        mod: "Xantelasma palpebral - exérese - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302013",
                        original: "Correção da enoftalmia",
                        mod: "Correção da enoftalmia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302021",
                        original: "Descompressão de órbita ou nervo ótico",
                        mod: "Descompressão de órbita ou nervo ótico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302030",
                        original: "Exenteração com osteotomia",
                        mod: "Exenteração com osteotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302048",
                        original: "Exenteração de órbita",
                        mod: "Exenteração de órbita",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302056",
                        original: "Exérese de tumor com abordagem craniofacial oncológica (tempo facial) pálpebra, cavidade orbitária e olhos",
                        mod: "Exérese de tumor com abordagem craniofacial oncológica (tempo facial) pálpebra, cavidade orbitária e olhos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302064",
                        original: "Fratura de órbita - redução cirúrgica",
                        mod: "Fratura de órbita - redução cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302072",
                        original: "Fratura de órbita - redução cirúrgica e enxerto ósseo",
                        mod: "Fratura de órbita - redução cirúrgica e enxerto ósseo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302080",
                        original: "Implante secundário de órbita",
                        mod: "Implante secundário de órbita",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302099",
                        original: "Microcirurgia para tumores orbitários",
                        mod: "Microcirurgia para tumores orbitários",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302102",
                        original: "Reconstituição de paredes orbitárias",
                        mod: "Reconstituição de paredes orbitárias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302110",
                        original: "Reconstrução parcial da cavidade orbital - por estágio",
                        mod: "Reconstrução parcial da cavidade orbital - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302129",
                        original: "Reconstrução total da cavidade orbital - por estágio",
                        mod: "Reconstrução total da cavidade orbital - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30302137",
                        original: "Tumor de órbita - exérese",
                        mod: "Tumor de órbita - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30303010",
                        original: "Autotransplante conjuntival",
                        mod: "Autotransplante conjuntival",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30303028",
                        original: "Biópsia de conjuntiva",
                        mod: "Biópsia de conjuntiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30303036",
                        original: "Enxerto de membrana amniótica",
                        mod: "Enxerto de membrana amniótica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30303044",
                        original: "Infiltração subconjuntival",
                        mod: "Infiltração subconjuntival",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30303052",
                        original: "Plástica de conjuntiva",
                        mod: "Plástica de conjuntiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30303060",
                        original: "Pterígio - exérese",
                        mod: "Pterígio - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30303079",
                        original: "Reconstituição de fundo de saco",
                        mod: "Reconstituição de fundo de saco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30303087",
                        original: "Sutura de conjuntiva",
                        mod: "Sutura de conjuntiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30303095",
                        original: "Transplante de limbo",
                        mod: "Transplante de limbo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30303109",
                        original: "Tumor de conjuntiva - exérese",
                        mod: "Tumor de conjuntiva - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304016",
                        original: "Cauterização de córnea",
                        mod: "Cauterização de córnea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304024",
                        original: "Ceratectomia superficial - monocular",
                        mod: "Ceratectomia superficial - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304032",
                        original: "Corpo estranho da córnea - retirada",
                        mod: "Corpo estranho da córnea - retirada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304040",
                        original: "PTK ceratectomia fototerapêutica - monocular",
                        mod: "PTK ceratectomia fototerapêutica - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304059",
                        original: "Recobrimento conjuntival",
                        mod: "Recobrimento conjuntival",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304067",
                        original: "Sutura de córnea (com ou sem hérnia de íris)",
                        mod: "Sutura de córnea (com ou sem hérnia de íris)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304075",
                        original: "Tarsoconjuntivoceratoplastia",
                        mod: "Tarsoconjuntivoceratoplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304083",
                        original: "Implante de anel intra-estromal",
                        mod: "Implante de anel intra-estromal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304091",
                        original: "Fotoablação de superfície convencional - PRK",
                        mod: "Fotoablação de superfície convencional - PRK",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304105",
                        original: "Delaminação corneana com fotoablação estromal - LASIK",
                        mod: "Delaminação corneana com fotoablação estromal - LASIK",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304113",
                        original: "Transplante lamelar anterior de córnea",
                        mod: "Transplante lamelar anterior de córnea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304121",
                        original: "Transplante lamelar posterior de córnea",
                        mod: "Transplante lamelar posterior de córnea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304156",
                        original: "Cross-linking (CXL) de colágeno corneano (nomenclatura proposta pelo CBO seguindo descrição/sigla internacional)",
                        mod: "Cross-linking (CXL) de colágeno corneano (nomenclatura proposta pelo CBO seguindo descrição/sigla internacional)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30304156",
                        original: "Cross-linking (CXL) de colágeno corneano",
                        mod: "Cross-linking (CXL) de colágeno corneano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30305012",
                        original: "Paracentese da câmara anterior",
                        mod: "Paracentese da câmara anterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30305020",
                        original: "Reconstrução da câmara anterior",
                        mod: "Reconstrução da câmara anterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30305039",
                        original: "Remoção de hifema",
                        mod: "Remoção de hifema",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30305047",
                        original: "Retirada de corpo estranho da câmara anterior",
                        mod: "Retirada de corpo estranho da câmara anterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30306019",
                        original: "Capsulotomia YAG ou cirúrgica",
                        mod: "Capsulotomia YAG ou cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30306027",
                        original: "Facectomia com lente intra-ocular com facoemulsificação",
                        mod: "Facectomia com lente intra-ocular com facoemulsificação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30306035",
                        original: "Facectomia com lente intra-ocular sem facoemulsificação",
                        mod: "Facectomia com lente intra-ocular sem facoemulsificação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30306043",
                        original: "Facectomia sem implante",
                        mod: "Facectomia sem implante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30306051",
                        original: "Fixação iriana de lente intra-ocular",
                        mod: "Fixação iriana de lente intra-ocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30306060",
                        original: "Implante secundário / explante / fixação escleral ou iriana",
                        mod: "Implante secundário / explante / fixação escleral ou iriana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30306078",
                        original: "Remoção de pigmentos da lente intra-ocular com yag-laser",
                        mod: "Remoção de pigmentos da lente intra-ocular com yag-laser",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307015",
                        original: "Biópsia de tumor via pars plana",
                        mod: "Biópsia de tumor via pars plana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307023",
                        original: "Biópsia de vítreo via pars plana",
                        mod: "Biópsia de vítreo via pars plana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307031",
                        original: "Endolaser/Endodiatermia",
                        mod: "Endolaser/Endodiatermia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307040",
                        original: "Implante de silicone intravítreo",
                        mod: "Implante de silicone intravítreo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307058",
                        original: "Infusão de perfluocarbono",
                        mod: "Infusão de perfluocarbono",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307066",
                        original: "Membranectomia EPI ou sub-retiniana",
                        mod: "Membranectomia EPI ou sub-retiniana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307074",
                        original: "Retirada de corpo estranho do corpo vítreo",
                        mod: "Retirada de corpo estranho do corpo vítreo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307082",
                        original: "Retirada de óleo de silicone via pars plana",
                        mod: "Retirada de óleo de silicone via pars plana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307090",
                        original: "Troca fluido gasosa",
                        mod: "Troca fluido gasosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307104",
                        original: "Vitrectomia a céu aberto - ceratoprótese",
                        mod: "Vitrectomia a céu aberto - ceratoprótese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307112",
                        original: "Vitrectomia anterior",
                        mod: "Vitrectomia anterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307120",
                        original: "Vitrectomia vias pars plana",
                        mod: "Vitrectomia vias pars plana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307139",
                        original: "Infusão intravítrea de medicação anti-inflamatória",
                        mod: "Infusão intravítrea de medicação anti-inflamatória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30307147",
                        original: "Tratamento ocular quimioterápico com anti-angiogênico. Programa de 24 meses. Uma sessão por mês (por sessão)",
                        mod: "Tratamento ocular quimioterápico com anti-angiogênico. Programa de 24 meses. Uma sessão por mês (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30308011",
                        original: "Biópsia de esclera",
                        mod: "Biópsia de esclera",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30308020",
                        original: "Enxerto de esclera (qualquer técnica)",
                        mod: "Enxerto de esclera (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30308038",
                        original: "Sutura de esclera",
                        mod: "Sutura de esclera",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30308046",
                        original: "Exérese de tumor de esclera",
                        mod: "Exérese de tumor de esclera",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30309018",
                        original: "Enucleação ou evisceração com ou sem implante",
                        mod: "Enucleação ou evisceração com ou sem implante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30309026",
                        original: "Injeção retrobulbar",
                        mod: "Injeção retrobulbar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30309034",
                        original: "Reconstituição de globo ocular com lesão de estruturas intra-oculares",
                        mod: "Reconstituição de globo ocular com lesão de estruturas intra-oculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30310016",
                        original: "Biópsia de íris e corpo ciliar",
                        mod: "Biópsia de íris e corpo ciliar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30310024",
                        original: "Cicloterapia - qualquer técnica",
                        mod: "Cicloterapia - qualquer técnica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30310032",
                        original: "Cirurgias fistulizantes antiglaucomatosas",
                        mod: "Cirurgias fistulizantes antiglaucomatosas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30310040",
                        original: "Cirurgias fistulizantes com implantes valvulares",
                        mod: "Cirurgias fistulizantes com implantes valvulares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30310059",
                        original: "Drenagem de descolamento de coróide",
                        mod: "Drenagem de descolamento de coróide",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30310067",
                        original: "Fototrabeculoplastia (laser)",
                        mod: "Fototrabeculoplastia (laser)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30310075",
                        original: "Goniotomia ou trabeculotomia",
                        mod: "Goniotomia ou trabeculotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30310083",
                        original: "Iridectomia (laser ou cirúrgica)",
                        mod: "Iridectomia (laser ou cirúrgica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30310091",
                        original: "Iridociclectomia",
                        mod: "Iridociclectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30310105",
                        original: "Sinequiotomia (cirúrgica)",
                        mod: "Sinequiotomia (cirúrgica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30310113",
                        original: "Sinequiotomia (laser)",
                        mod: "Sinequiotomia (laser)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30311012",
                        original: "Biópsia de músculos",
                        mod: "Biópsia de músculos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30311020",
                        original: "Cirurgia com sutura ajustável",
                        mod: "Cirurgia com sutura ajustável",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30311039",
                        original: "Estrabismo ciclo vertical/transposição - monocular - tratamento cirúrgico",
                        mod: "Estrabismo ciclo vertical/transposição - monocular - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30311047",
                        original: "Estrabismo horizontal - monocular - tratamento ciúrgico",
                        mod: "Estrabismo horizontal - monocular - tratamento ciúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30311055",
                        original: "Injeção de toxina botulínica - monocular",
                        mod: "Injeção de toxina botulínica - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312019",
                        original: "Aplicação de placa radiativa episcleral",
                        mod: "Aplicação de placa radiativa episcleral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312027",
                        original: "Biópsia de retina",
                        mod: "Biópsia de retina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312035",
                        original: "Exérese de tumor de coróide e/ou corpo ciliar",
                        mod: "Exérese de tumor de coróide e/ou corpo ciliar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312043",
                        original: "Fotocoagulação (laser) - por sessão  - monocular",
                        mod: "Fotocoagulação (laser) - por sessão  - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312051",
                        original: "Infusão de gás expansor",
                        mod: "Infusão de gás expansor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312060",
                        original: "Pancrioterapia periférica",
                        mod: "Pancrioterapia periférica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312078",
                        original: "Remoção de implante episcleral",
                        mod: "Remoção de implante episcleral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312086",
                        original: "Retinopexia com introflexão escleral",
                        mod: "Retinopexia com introflexão escleral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312094",
                        original: "Retinopexia pneumática",
                        mod: "Retinopexia pneumática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312108",
                        original: "Retinopexia profilática (criopexia)",
                        mod: "Retinopexia profilática (criopexia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312116",
                        original: "Retinotomia relaxante",
                        mod: "Retinotomia relaxante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312124",
                        original: "Panfotocoagulação na retinopatia da prematuridade - binocular",
                        mod: "Panfotocoagulação na retinopatia da prematuridade - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312132",
                        original: "Implante intravítreo de polímero farmacológico de liberação controlada",
                        mod: "Implante intravítreo de polímero farmacológico de liberação controlada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30312159",
                        original: "Termoterapia transpupilar",
                        mod: "Termoterapia transpupilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30313015",
                        original: "Cirurgia da glândula lacrimal",
                        mod: "Cirurgia da glândula lacrimal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30313023",
                        original: "Dacriocistectomia - unilateral",
                        mod: "Dacriocistectomia - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30313031",
                        original: "Dacriocistorrinostomia com ou sem intubação - unilateral",
                        mod: "Dacriocistorrinostomia com ou sem intubação - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30313040",
                        original: "Fechamento dos pontos lacrimais",
                        mod: "Fechamento dos pontos lacrimais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30313058",
                        original: "Reconstituição de vias lacrimais com silicone ou outro material",
                        mod: "Reconstituição de vias lacrimais com silicone ou outro material",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30313066",
                        original: "Sondagem das vias lacrimais - com ou sem lavagem",
                        mod: "Sondagem das vias lacrimais - com ou sem lavagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30313074",
                        original: "Reconstituição de pontos lacrimais",
                        mod: "Reconstituição de pontos lacrimais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30401011",
                        original: "Biópsia de pavilhão auricular",
                        mod: "Biópsia de pavilhão auricular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30401020",
                        original: "Exérese de tumor com abordagem craniofacial oncológica pavilhão auricular (tempo facial)",
                        mod: "Exérese de tumor com abordagem craniofacial oncológica pavilhão auricular (tempo facial)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30401038",
                        original: "Exérese de tumor com fechamento primário",
                        mod: "Exérese de tumor com fechamento primário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30401046",
                        original: "Outros defeitos congênitos que não a microtia",
                        mod: "Outros defeitos congênitos que não a microtia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30401054",
                        original: "Reconstrução  de orelha - retoques",
                        mod: "Reconstrução  de orelha - retoques",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30401062",
                        original: "Reconstrução de unidade anatômica do pavilhão auricular - por estágio",
                        mod: "Reconstrução de unidade anatômica do pavilhão auricular - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30401070",
                        original: "Reconstrução total de orelha - único estágio",
                        mod: "Reconstrução total de orelha - único estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30401089",
                        original: "Ressecção de tumor de pavilhão auricular, incluindo parte do osso temporal",
                        mod: "Ressecção de tumor de pavilhão auricular, incluindo parte do osso temporal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30401097",
                        original: "Ressecção subtotal ou total de orelha",
                        mod: "Ressecção subtotal ou total de orelha",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30401100",
                        original: "Tratamento cirúrgico de sinus pré-auricular",
                        mod: "Tratamento cirúrgico de sinus pré-auricular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30402018",
                        original: "Aspiração auricular",
                        mod: "Aspiração auricular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30402026",
                        original: "Biópsia (orelha externa)",
                        mod: "Biópsia (orelha externa)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30402034",
                        original: "Cisto pré-auricular (coloboma auris) - exérese-unilateral",
                        mod: "Cisto pré-auricular (coloboma auris) - exérese-unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30402042",
                        original: "Corpos estranhos, pólipos ou biópsia - em consultório",
                        mod: "Corpos estranhos, pólipos ou biópsia - em consultório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30402050",
                        original: "Corpos estranhos, pólipos ou biópsia - em hospital sob anestesia geral",
                        mod: "Corpos estranhos, pólipos ou biópsia - em hospital sob anestesia geral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30402069",
                        original: "Estenose de conduto auditivo externo - correção",
                        mod: "Estenose de conduto auditivo externo - correção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30402077",
                        original: "Furúnculo - drenagem (ouvido)",
                        mod: "Furúnculo - drenagem (ouvido)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30402085",
                        original: "Pericondrite de pavilhão - tratamento cirúrgico com desbridamento",
                        mod: "Pericondrite de pavilhão - tratamento cirúrgico com desbridamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30402093",
                        original: "Tumor benigno de conduto auditivo externo - exérese",
                        mod: "Tumor benigno de conduto auditivo externo - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403014",
                        original: "Cauterização de membrana timpânica",
                        mod: "Cauterização de membrana timpânica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403030",
                        original: "Estapedectomia ou estapedotomia",
                        mod: "Estapedectomia ou estapedotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403049",
                        original: "Exploração e descompressão parcial do nervo facial intratemporal",
                        mod: "Exploração e descompressão parcial do nervo facial intratemporal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403057",
                        original: "Fístula perilinfática - fechamento cirúrgico",
                        mod: "Fístula perilinfática - fechamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403065",
                        original: "Glomus jugular - ressecção",
                        mod: "Glomus jugular - ressecção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403073",
                        original: "Glomus timpânicus - ressecção",
                        mod: "Glomus timpânicus - ressecção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403081",
                        original: "Mastoidectomia simples ou radical modificada",
                        mod: "Mastoidectomia simples ou radical modificada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403090",
                        original: "Ouvido congênito - tratamento cirúrgico",
                        mod: "Ouvido congênito - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403103",
                        original: "Paracentese do tímpano - miringotomia, unilateral - em consultório",
                        mod: "Paracentese do tímpano - miringotomia, unilateral - em consultório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403111",
                        original: "Tímpano-mastoidectomia",
                        mod: "Tímpano-mastoidectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403120",
                        original: "Timpanoplastia com reconstrução da cadeia ossicular",
                        mod: "Timpanoplastia com reconstrução da cadeia ossicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403138",
                        original: "Timpanoplastia tipo I - miringoplastia - unilateral",
                        mod: "Timpanoplastia tipo I - miringoplastia - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403146",
                        original: "Timpanotomia exploradora - unilateral",
                        mod: "Timpanotomia exploradora - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403154",
                        original: "Timpanotomia para tubo de ventilação - unilateral",
                        mod: "Timpanotomia para tubo de ventilação - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30403162",
                        original: "Paracentese do tímpano, unilateral, em hospital - anestesia geral",
                        mod: "Paracentese do tímpano, unilateral, em hospital - anestesia geral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404010",
                        original: "Doença de Meniere - tratamento cirúrgico - descompressão do saco endolinfático ou 'shunt'",
                        mod: "Doença de Meniere - tratamento cirúrgico - descompressão do saco endolinfático ou 'shunt'",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404029",
                        original: "Enxerto parcial intratemporal do nervo facial - do foramem estilo-mastóideo ao gânglio geniculado",
                        mod: "Enxerto parcial intratemporal do nervo facial - do foramem estilo-mastóideo ao gânglio geniculado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404037",
                        original: "Enxerto parcial intratemporal do nervo facial - do gânglio geniculado ao meato acústico interno",
                        mod: "Enxerto parcial intratemporal do nervo facial - do gânglio geniculado ao meato acústico interno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404045",
                        original: "Enxerto total do nervo facial intratemporal",
                        mod: "Enxerto total do nervo facial intratemporal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404053",
                        original: "Exploração e descompressão total do nervo facial (transmastóideo, translabiríntico, fossa média)",
                        mod: "Exploração e descompressão total do nervo facial (transmastóideo, translabiríntico, fossa média)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404061",
                        original: "Implante coclear (exceto a prótese)",
                        mod: "Implante coclear (exceto a prótese)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404070",
                        original: "Injeção de drogas intratimpânicas",
                        mod: "Injeção de drogas intratimpânicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404088",
                        original: "Labirintectomia (membranosa ou óssea) - sem audição",
                        mod: "Labirintectomia (membranosa ou óssea) - sem audição",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404096",
                        original: "Neurectomia vestibular para fossa média ou posterior",
                        mod: "Neurectomia vestibular para fossa média ou posterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404100",
                        original: "Neurectomia vestibular translabiríntica - sem audição",
                        mod: "Neurectomia vestibular translabiríntica - sem audição",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404126",
                        original: "Ressecção do osso temporal",
                        mod: "Ressecção do osso temporal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404134",
                        original: "Tumor do nervo acústico - ressecção via translabiríntica ou fossa média",
                        mod: "Tumor do nervo acústico - ressecção via translabiríntica ou fossa média",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404142",
                        original: "Doença de Meniere - tratamento cirúrgico com ultrassom",
                        mod: "Doença de Meniere - tratamento cirúrgico com ultrassom",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30404177",
                        original: "Cirurgia para prótese auditiva percutânea ancorada no osso unilateral (primeira implantação ou substituição)",
                        mod: "Cirurgia para prótese auditiva percutânea ancorada no osso unilateral (primeira implantação ou substituição)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501016",
                        original: "Abscesso ou hematoma de septo nasal - drenagem",
                        mod: "Abscesso ou hematoma de septo nasal - drenagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501024",
                        original: "Abscesso ou hematoma de septo nasal - drenagem sob anestesia geral",
                        mod: "Abscesso ou hematoma de septo nasal - drenagem sob anestesia geral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501040",
                        original: "Alongamento de columela",
                        mod: "Alongamento de columela",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501059",
                        original: "Biópsia de nariz",
                        mod: "Biópsia de nariz",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501067",
                        original: "Corneto inferior - cauterização linear - unilateral",
                        mod: "Corneto inferior - cauterização linear - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501075",
                        original: "Corneto inferior - infiltração medicamentosa (unilateral)",
                        mod: "Corneto inferior - infiltração medicamentosa (unilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501083",
                        original: "Corpos estranhos - retirada em consultório (nariz)",
                        mod: "Corpos estranhos - retirada em consultório (nariz)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501091",
                        original: "Corpos estranhos - retirada sob anestesia geral / hospital (nariz)",
                        mod: "Corpos estranhos - retirada sob anestesia geral / hospital (nariz)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501113",
                        original: "Epistaxe - cauterização (qualquer técnica)",
                        mod: "Epistaxe - cauterização (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501121",
                        original: "Epistaxe - cauterização da artéria esfenopalatina com microscopia - unilateral",
                        mod: "Epistaxe - cauterização da artéria esfenopalatina com microscopia - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501130",
                        original: "Epistaxe - cauterização das artérias etmoidais com microscopia - unilateral",
                        mod: "Epistaxe - cauterização das artérias etmoidais com microscopia - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501148",
                        original: "Epistaxe - ligadura das artérias etmoidais - acesso transorbitário - unilateral",
                        mod: "Epistaxe - ligadura das artérias etmoidais - acesso transorbitário - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501156",
                        original: "Epistaxe - tamponamento  antero-posterior",
                        mod: "Epistaxe - tamponamento  antero-posterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501164",
                        original: "Epistaxe - tamponamento anterior",
                        mod: "Epistaxe - tamponamento anterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501172",
                        original: "Epistaxe - tamponamento antero-posterior sob anestesia geral",
                        mod: "Epistaxe - tamponamento antero-posterior sob anestesia geral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501180",
                        original: "Exérese de tumor com abordagem craniofacial oncológica (tempo facial) pirâmide nasal",
                        mod: "Exérese de tumor com abordagem craniofacial oncológica (tempo facial) pirâmide nasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501199",
                        original: "Exérese de tumor nasal por via endoscopica",
                        mod: "Exérese de tumor nasal por via endoscopica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501202",
                        original: "Fechamento de fístula liquórica transnasal",
                        mod: "Fechamento de fístula liquórica transnasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501210",
                        original: "Fístula liquórica - tratamento cirúrgico endoscópico intranasal",
                        mod: "Fístula liquórica - tratamento cirúrgico endoscópico intranasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501229",
                        original: "Fraturas dos ossos nasais - redução cirúrgica e gesso",
                        mod: "Fraturas dos ossos nasais - redução cirúrgica e gesso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501237",
                        original: "Fraturas dos ossos nasais - redução incruenta e gesso",
                        mod: "Fraturas dos ossos nasais - redução incruenta e gesso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501245",
                        original: "Imperfuração coanal - correção cirúrgica intranasal",
                        mod: "Imperfuração coanal - correção cirúrgica intranasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501253",
                        original: "Imperfuração coanal - correção cirúrgica transpalatina",
                        mod: "Imperfuração coanal - correção cirúrgica transpalatina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501261",
                        original: "Ozena - tratamento cirúrgico",
                        mod: "Ozena - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501270",
                        original: "Perfuração do septo nasal - correção cirúrgica",
                        mod: "Perfuração do septo nasal - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501288",
                        original: "Polipectomia - unilateral (nariz)",
                        mod: "Polipectomia - unilateral (nariz)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501296",
                        original: "Reconstrução de unidade anatômica do nariz - por estágio",
                        mod: "Reconstrução de unidade anatômica do nariz - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501300",
                        original: "Reconstrução total de nariz - por estágio",
                        mod: "Reconstrução total de nariz - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501318",
                        original: "Ressecção de tumores malignos transnasais",
                        mod: "Ressecção de tumores malignos transnasais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501326",
                        original: "Rinectomia parcial",
                        mod: "Rinectomia parcial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501334",
                        original: "Rinectomia total",
                        mod: "Rinectomia total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501342",
                        original: "Rinoplastia reparadora",
                        mod: "Rinoplastia reparadora",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501350",
                        original: "Rinosseptoplastia funcional",
                        mod: "Rinosseptoplastia funcional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501369",
                        original: "Septoplastia (qualquer técnica sem vídeo)",
                        mod: "Septoplastia (qualquer técnica sem vídeo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501377",
                        original: "Sinéquia nasal - ressecção unilateral - qualquer técnica",
                        mod: "Sinéquia nasal - ressecção unilateral - qualquer técnica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501385",
                        original: "Tratamento cirúrgico da atresia narinária",
                        mod: "Tratamento cirúrgico da atresia narinária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501393",
                        original: "Tratamento cirúrgico de deformidade nasal congênita",
                        mod: "Tratamento cirúrgico de deformidade nasal congênita",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501407",
                        original: "Tratamento cirúrgico do rinofima",
                        mod: "Tratamento cirúrgico do rinofima",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501415",
                        original: "Tratamento cirúrgico reparador do nariz em sela",
                        mod: "Tratamento cirúrgico reparador do nariz em sela",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501423",
                        original: "Tratamento de deformidade traumática nasal",
                        mod: "Tratamento de deformidade traumática nasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501431",
                        original: "Tumor intranasal - exérese por rinotomia lateral",
                        mod: "Tumor intranasal - exérese por rinotomia lateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501440",
                        original: "Tumor intranasal - exérese por via transnasal",
                        mod: "Tumor intranasal - exérese por via transnasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501458",
                        original: "Turbinectomia ou turbinoplastia - unilateral",
                        mod: "Turbinectomia ou turbinoplastia - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501466",
                        original: "Turbinoplastia por radiofrequência",
                        mod: "Turbinoplastia por radiofrequência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501474",
                        original: "Corpos estranhos - retirada sob anestesia geral / hospital (nariz) - por videoendoscopia",
                        mod: "Corpos estranhos - retirada sob anestesia geral / hospital (nariz) - por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501482",
                        original: "Epistaxe - cauterização da artéria esfenopalatina com microscopia - unilateral por videoendoscopia",
                        mod: "Epistaxe - cauterização da artéria esfenopalatina com microscopia - unilateral por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501490",
                        original: "Imperfuração coanal - correção cirúrgica intranasal por videoendoscopia",
                        mod: "Imperfuração coanal - correção cirúrgica intranasal por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501504",
                        original: "Ozena - tratamento cirúrgico por videoendoscopia",
                        mod: "Ozena - tratamento cirúrgico por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501512",
                        original: "Perfuração do septo nasal - correção cirúrgica por videoendoscopia",
                        mod: "Perfuração do septo nasal - correção cirúrgica por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501520",
                        original: "Rinosseptoplastia funcional por videoendoscopia",
                        mod: "Rinosseptoplastia funcional por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501539",
                        original: "Septoplastia por videoendoscopia",
                        mod: "Septoplastia por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30501547",
                        original: "Fratura de osso próprio de nariz - tratamento conservador",
                        mod: "Fratura de osso próprio de nariz - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502012",
                        original: "Angiofibroma - ressecção transmaxilar e/ou transpalatina",
                        mod: "Angiofibroma - ressecção transmaxilar e/ou transpalatina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502020",
                        original: "Antrostomia maxilar intranasal",
                        mod: "Antrostomia maxilar intranasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502039",
                        original: "Artéria maxilar interna - ligadura transmaxilar",
                        mod: "Artéria maxilar interna - ligadura transmaxilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502047",
                        original: "Cisto naso-alveolar e globular - exérese",
                        mod: "Cisto naso-alveolar e globular - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502063",
                        original: "Descompressão transetmoidal do canal óptico",
                        mod: "Descompressão transetmoidal do canal óptico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502071",
                        original: "Etmoidectomia externa",
                        mod: "Etmoidectomia externa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502080",
                        original: "Etmoidectomia intranasal",
                        mod: "Etmoidectomia intranasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502098",
                        original: "Exérese de tumor com abordagem craniofacial oncológica seios...(tempo facial)",
                        mod: "Exérese de tumor com abordagem craniofacial oncológica seios...(tempo facial)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502101",
                        original: "Exérese de tumor de seios paranasais por via endoscopica",
                        mod: "Exérese de tumor de seios paranasais por via endoscopica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502110",
                        original: "Fístula oro-antral - tratamento cirúrgico",
                        mod: "Fístula oro-antral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502128",
                        original: "Fístula oronasal - tratamento cirúrgico",
                        mod: "Fístula oronasal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502136",
                        original: "Maxilectomia incluindo exenteração de órbita",
                        mod: "Maxilectomia incluindo exenteração de órbita",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502144",
                        original: "Maxilectomia parcial",
                        mod: "Maxilectomia parcial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502152",
                        original: "Maxilectomia total",
                        mod: "Maxilectomia total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502160",
                        original: "Pólipo antro-coanal de Killiam - exérese",
                        mod: "Pólipo antro-coanal de Killiam - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502179",
                        original: "Punção maxilar transmeática ou via fossa canina",
                        mod: "Punção maxilar transmeática ou via fossa canina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502187",
                        original: "Ressecção de tumor benigno",
                        mod: "Ressecção de tumor benigno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502195",
                        original: "Biópsia de seios paranasais - qualquer via",
                        mod: "Biópsia de seios paranasais - qualquer via",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502209",
                        original: "Sinusectomia maxilar - via endonasal",
                        mod: "Sinusectomia maxilar - via endonasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502217",
                        original: "Sinusectomia frontal com retalho osteoplástico ou via coronal",
                        mod: "Sinusectomia frontal com retalho osteoplástico ou via coronal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502225",
                        original: "Sinusectomia fronto-etmoidal por via externa",
                        mod: "Sinusectomia fronto-etmoidal por via externa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502233",
                        original: "Sinusectomia maxilar - via oral (Caldwell-Luc)",
                        mod: "Sinusectomia maxilar - via oral (Caldwell-Luc)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502241",
                        original: "Sinusectomia transmaxilar (Ermiro de Lima)",
                        mod: "Sinusectomia transmaxilar (Ermiro de Lima)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502250",
                        original: "Sinusotomia esfenoidal",
                        mod: "Sinusotomia esfenoidal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502268",
                        original: "Sinusotomia frontal intranasal",
                        mod: "Sinusotomia frontal intranasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502276",
                        original: "Sinusotomia frontal via externa",
                        mod: "Sinusotomia frontal via externa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502284",
                        original: "Antrostomia maxilar, etmoidectomia etc a laser (abertura de todas as cavidades paranasais a laser)",
                        mod: "Antrostomia maxilar, etmoidectomia etc a laser (abertura de todas as cavidades paranasais a laser)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502292",
                        original: "Antrostomia maxilar intranasal por videoendoscopia",
                        mod: "Antrostomia maxilar intranasal por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502306",
                        original: "Artéria maxilar interna - ligadura transmaxilar por videoendoscopia",
                        mod: "Artéria maxilar interna - ligadura transmaxilar por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502314",
                        original: "Etmoidectomia intranasal por videoendoscopia",
                        mod: "Etmoidectomia intranasal por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502322",
                        original: "Sinusectomia maxilar - via endonasal por videoendoscopia",
                        mod: "Sinusectomia maxilar - via endonasal por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502349",
                        original: "Sinusotomia esfenoidal por videoendoscopia",
                        mod: "Sinusotomia esfenoidal por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502357",
                        original: "Sinusotomia frontal intranasal por videoendoscopia",
                        mod: "Sinusotomia frontal intranasal por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30502365",
                        original: "Sinusotomia frontal intranasal com balão por videoendoscopia",
                        mod: "Sinusotomia frontal intranasal com balão por videoendoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601010",
                        original: "Correção de deformidades da parede torácica",
                        mod: "Correção de deformidades da parede torácica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601029",
                        original: "Costectomia",
                        mod: "Costectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601037",
                        original: "Esternectomia subtotal",
                        mod: "Esternectomia subtotal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601045",
                        original: "Esternectomia total",
                        mod: "Esternectomia total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601053",
                        original: "Fechamento de pleurostomia",
                        mod: "Fechamento de pleurostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601070",
                        original: "Mobilização de retalhos musculares ou do omento",
                        mod: "Mobilização de retalhos musculares ou do omento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601088",
                        original: "Plumbagem extrafascial",
                        mod: "Plumbagem extrafascial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601096",
                        original: "Reconstrução da parede torácica (com ou sem prótese)",
                        mod: "Reconstrução da parede torácica (com ou sem prótese)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601100",
                        original: "Reconstrução da parede torácica com retalhos cutâneos",
                        mod: "Reconstrução da parede torácica com retalhos cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601118",
                        original: "Reconstrução da parede torácica com retalhos musculares ou miocutâneos",
                        mod: "Reconstrução da parede torácica com retalhos musculares ou miocutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601126",
                        original: "Reconstrução da região esternal com retalhos musculares bilaterais",
                        mod: "Reconstrução da região esternal com retalhos musculares bilaterais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601134",
                        original: "Ressecção de tumor do diafragma e reconstrução (qualquer técnica)",
                        mod: "Ressecção de tumor do diafragma e reconstrução (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601142",
                        original: "Retirada de corpo estranho da parede torácica",
                        mod: "Retirada de corpo estranho da parede torácica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601150",
                        original: "Toracectomia",
                        mod: "Toracectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601169",
                        original: "Toracoplastia (qualquer técnica)",
                        mod: "Toracoplastia (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601177",
                        original: "Toracotomia com biópsia",
                        mod: "Toracotomia com biópsia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601185",
                        original: "Toracotomia exploradora (excluídos os procedimentos intratorácicos)",
                        mod: "Toracotomia exploradora (excluídos os procedimentos intratorácicos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601193",
                        original: "Toracotomia para procedimentos ortopédicos sobre a coluna vertebral",
                        mod: "Toracotomia para procedimentos ortopédicos sobre a coluna vertebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601207",
                        original: "Tração esquelética do gradil costo-esternal (traumatismo)",
                        mod: "Tração esquelética do gradil costo-esternal (traumatismo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601215",
                        original: "Tratamento cirúrgico de fraturas do gradil costal",
                        mod: "Tratamento cirúrgico de fraturas do gradil costal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601223",
                        original: "Biópsia cirúrgica de costela ou esterno",
                        mod: "Biópsia cirúrgica de costela ou esterno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601231",
                        original: "Fratura luxação de esterno ou costela - redução incruenta",
                        mod: "Fratura luxação de esterno ou costela - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601240",
                        original: "Fratura luxação de esterno ou costela - tratamento cirúrgico",
                        mod: "Fratura luxação de esterno ou costela - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601258",
                        original: "Osteomielite de costela ou esterno - tratamento cirúrgico",
                        mod: "Osteomielite de costela ou esterno - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601266",
                        original: "Punção biópsia de costela ou esterno",
                        mod: "Punção biópsia de costela ou esterno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601274",
                        original: "Correção de deformidades da parede torácica por vídeo",
                        mod: "Correção de deformidades da parede torácica por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601282",
                        original: "Vídeo para procedimentos sobre a coluna vertebral",
                        mod: "Vídeo para procedimentos sobre a coluna vertebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601290",
                        original: "Ressutura de parede torácica",
                        mod: "Ressutura de parede torácica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601304",
                        original: "Fratura de costela ou esterno - tratamento conservador",
                        mod: "Fratura de costela ou esterno - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30601312",
                        original: "Osteomielite de costela ou esterno - tratamento conservador",
                        mod: "Osteomielite de costela ou esterno - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602017",
                        original: "Biópsia incisional de mama",
                        mod: "Biópsia incisional de mama",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602025",
                        original: "Coleta de fluxo papilar de mama",
                        mod: "Coleta de fluxo papilar de mama",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602033",
                        original: "Correção cirúrgica da assimetria mamária",
                        mod: "Correção cirúrgica da assimetria mamária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602041",
                        original: "Correção de inversão papilar - unilateral",
                        mod: "Correção de inversão papilar - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602050",
                        original: "Drenagem de abscesso de mama",
                        mod: "Drenagem de abscesso de mama",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602068",
                        original: "Drenagem e/ou aspiração de seroma",
                        mod: "Drenagem e/ou aspiração de seroma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602076",
                        original: "Exérese de lesão da mama por marcação estereotáxica ou roll",
                        mod: "Exérese de lesão da mama por marcação estereotáxica ou roll",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602084",
                        original: "Exérese de mama supra-numerária - unilateral",
                        mod: "Exérese de mama supra-numerária - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602092",
                        original: "Exérese de nódulo",
                        mod: "Exérese de nódulo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602106",
                        original: "Fistulectomia de mama",
                        mod: "Fistulectomia de mama",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602114",
                        original: "Ginecomastia - unilateral",
                        mod: "Ginecomastia - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602122",
                        original: "Correção da hipertrofia mamária - unilateral",
                        mod: "Correção da hipertrofia mamária - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602130",
                        original: "Linfadenectomia axilar",
                        mod: "Linfadenectomia axilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602149",
                        original: "Mastectomia radical ou radical modificada - qualquer técnica",
                        mod: "Mastectomia radical ou radical modificada - qualquer técnica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602157",
                        original: "Mastectomia simples",
                        mod: "Mastectomia simples",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602165",
                        original: "Mastectomia subcutânea e inclusão da prótese",
                        mod: "Mastectomia subcutânea e inclusão da prótese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602173",
                        original: "Mastoplastia em mama oposta após reconstrução da contralateral",
                        mod: "Mastoplastia em mama oposta após reconstrução da contralateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602181",
                        original: "Punção ou biópsia percutânea de agulha fina - por nódulo (máximo de 3 nódulos por mama)",
                        mod: "Punção ou biópsia percutânea de agulha fina - por nódulo (máximo de 3 nódulos por mama)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602190",
                        original: "Quadrantectomia e linfadenectomia axilar",
                        mod: "Quadrantectomia e linfadenectomia axilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602203",
                        original: "Quadrantectomia - ressecção segmentar",
                        mod: "Quadrantectomia - ressecção segmentar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602211",
                        original: "Reconstrução da placa aréolo mamilar - unilateral",
                        mod: "Reconstrução da placa aréolo mamilar - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602238",
                        original: "Reconstrução mamária com retalho muscular ou miocutâneo - unilateral",
                        mod: "Reconstrução mamária com retalho muscular ou miocutâneo - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602246",
                        original: "Reconstrução mamária com retalhos cutâneos regionais",
                        mod: "Reconstrução mamária com retalhos cutâneos regionais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602254",
                        original: "Reconstrução parcial da mama pós-quadrantectomia",
                        mod: "Reconstrução parcial da mama pós-quadrantectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602262",
                        original: "Reconstrução da mama com prótese e/ou expansor",
                        mod: "Reconstrução da mama com prótese e/ou expansor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602289",
                        original: "Ressecção do linfonodo sentinela / torácica lateral",
                        mod: "Ressecção do linfonodo sentinela / torácica lateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602297",
                        original: "Ressecção do linfonodo sentinela / torácica medial",
                        mod: "Ressecção do linfonodo sentinela / torácica medial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602300",
                        original: "Ressecção dos ductos principais da mama - unilateral",
                        mod: "Ressecção dos ductos principais da mama - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602319",
                        original: "Retirada da válvula após colocação de expansor permanente",
                        mod: "Retirada da válvula após colocação de expansor permanente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602327",
                        original: "Substituição de prótese",
                        mod: "Substituição de prótese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602335",
                        original: "Biópsia percutânea com agulha grossa, em consultório",
                        mod: "Biópsia percutânea com agulha grossa, em consultório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602343",
                        original: "Linfadenectomia por incisão extra-axilar",
                        mod: "Linfadenectomia por incisão extra-axilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30602351",
                        original: "Mamoplastia",
                        mod: "Mamoplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701015",
                        original: "Abdominal ou hipogástrico - transplantes cutâneos",
                        mod: "Abdominal ou hipogástrico - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701023",
                        original: "Antebraço  - transplantes cutâneos",
                        mod: "Antebraço  - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701031",
                        original: "Axilar - transplantes cutâneos",
                        mod: "Axilar - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701040",
                        original: "Couro cabeludo - transplantes cutâneos",
                        mod: "Couro cabeludo - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701058",
                        original: "Deltopeitoral - transplantes cutâneos",
                        mod: "Deltopeitoral - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701066",
                        original: "Digitais (da face volar e látero-cubital dos dedos médio e anular da mão) - transplantes cutâneos",
                        mod: "Digitais (da face volar e látero-cubital dos dedos médio e anular da mão) - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701074",
                        original: "Digital do hallux - transplantes cutâneos",
                        mod: "Digital do hallux - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701082",
                        original: "Dorsal do pé - transplantes cutâneos",
                        mod: "Dorsal do pé - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701090",
                        original: "Escapular - transplantes cutâneos",
                        mod: "Escapular - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701104",
                        original: "Femoral - transplantes cutâneos",
                        mod: "Femoral - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701112",
                        original: "Fossa poplítea - transplantes cutâneos",
                        mod: "Fossa poplítea - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701120",
                        original: "Inguino-cural - transplantes cutâneos",
                        mod: "Inguino-cural - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701139",
                        original: "Intercostal - transplantes cutâneos",
                        mod: "Intercostal - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701147",
                        original: "Interdigital da 1ª comissura dos dedos do pé - transplantes cutâneos",
                        mod: "Interdigital da 1ª comissura dos dedos do pé - transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701155",
                        original: "Outros transplantes cutâneos",
                        mod: "Outros transplantes cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701163",
                        original: "Paraescapular",
                        mod: "Paraescapular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701171",
                        original: "Retroauricular",
                        mod: "Retroauricular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701180",
                        original: "Temporal",
                        mod: "Temporal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701198",
                        original: "Transplante cutâneo com microanastomose",
                        mod: "Transplante cutâneo com microanastomose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701201",
                        original: "Transplante cutâneo sem microanastomose, ilha neurovascular",
                        mod: "Transplante cutâneo sem microanastomose, ilha neurovascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30701210",
                        original: "Transplante miocutâneo com microanastomose",
                        mod: "Transplante miocutâneo com microanastomose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30702011",
                        original: "Grande dorsal (latissimus dorsi) - transplantes músculo-cutâneos com microanastomoses vasculares",
                        mod: "Grande dorsal (latissimus dorsi) - transplantes músculo-cutâneos com microanastomoses vasculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30702020",
                        original: "Grande glúteo (gluteus maximus)",
                        mod: "Grande glúteo (gluteus maximus)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30702038",
                        original: "Outros transplantes músculo-cutâneos",
                        mod: "Outros transplantes músculo-cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30702046",
                        original: "Reto abdominal (rectus abdominis)",
                        mod: "Reto abdominal (rectus abdominis)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30702054",
                        original: "Reto interno (gracilis) - transplantes músculo-cutâneos com microanastomoses vasculares",
                        mod: "Reto interno (gracilis) - transplantes músculo-cutâneos com microanastomoses vasculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30702062",
                        original: "Serrato maior (serratus) - transplantes músculo-cutâneos com microanastomoses vasculares",
                        mod: "Serrato maior (serratus) - transplantes músculo-cutâneos com microanastomoses vasculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30702070",
                        original: "Tensor da fascia lata (tensor fascia lata) - transplantes músculo-cutâneos com microanastomoses vasculares",
                        mod: "Tensor da fascia lata (tensor fascia lata) - transplantes músculo-cutâneos com microanastomoses vasculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30702089",
                        original: "Trapézio (trapezius)",
                        mod: "Trapézio (trapezius)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703018",
                        original: "Bíceps femoral (biceps femoris)",
                        mod: "Bíceps femoral (biceps femoris)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703026",
                        original: "Extensor comum dos dedos (extensor digitorum longus)",
                        mod: "Extensor comum dos dedos (extensor digitorum longus)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703034",
                        original: "Extensor próprio do dedo gordo (extensor hallucis longus)",
                        mod: "Extensor próprio do dedo gordo (extensor hallucis longus)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703042",
                        original: "Flexor curto plantar (flexor digitorum brevis)",
                        mod: "Flexor curto plantar (flexor digitorum brevis)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703050",
                        original: "Grande dorsal (latissimus dorsi) - transplantes musculares com microanastomoses vasculares",
                        mod: "Grande dorsal (latissimus dorsi) - transplantes musculares com microanastomoses vasculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703069",
                        original: "Grande peitoral (pectoralis major)",
                        mod: "Grande peitoral (pectoralis major)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703077",
                        original: "Músculo pédio (extensor digitorum brevis)",
                        mod: "Músculo pédio (extensor digitorum brevis)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703085",
                        original: "Os músculos latissimus dorsi, gracilis, rectus femoris, tensor fascia lata, flexor digitorum brevis, quando transplantados com sua inervação e praticada a microneurorrafia com finalidade de restaurar função e sensibilidade, serão considerados retalhos neu",
                        mod: "Os músculos latissimus dorsi, gracilis, rectus femoris, tensor fascia lata, flexor digitorum brevis, quando transplantados com sua inervação e praticada a microneurorrafia com finalidade de restaurar função e sensibilidade, serão considerados retalhos neu",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703085",
                        original: "Os músculos latissimus dorsi, gracilis, rectus femoris, tensor fascia lata, flexor digitorum brevis, quando transplantados com sua inervação e praticada a microneurorrafia com finalidade de restaurar função e sensibilidade, serão considerados retalhos neurovasculares livres e terão acréscimo do porte.",
                        mod: "Os músculos latissimus dorsi, gracilis, rectus femoris, tensor fascia lata, flexor digitorum brevis, quando transplantados com sua inervação e praticada a microneurorrafia com finalidade de restaurar função e sensibilidade, serão considerados retalhos neurovasculares livres e terão acréscimo do porte.",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703093",
                        original: "Outros transplantes musculares",
                        mod: "Outros transplantes musculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703107",
                        original: "Primeiro radial externo (extensor carpi radialis longus)",
                        mod: "Primeiro radial externo (extensor carpi radialis longus)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703115",
                        original: "Reto anterior (rectus femoris)",
                        mod: "Reto anterior (rectus femoris)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703123",
                        original: "Reto interno (gracilis) - transplantes musculares com microanastomoses vasculares",
                        mod: "Reto interno (gracilis) - transplantes musculares com microanastomoses vasculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703131",
                        original: "Sartório (sartorius)",
                        mod: "Sartório (sartorius)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703140",
                        original: "Semimembranoso (semimembranosus)",
                        mod: "Semimembranoso (semimembranosus)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703158",
                        original: "Semitendinoso (semitendinosus)",
                        mod: "Semitendinoso (semitendinosus)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703166",
                        original: "Serrato maior (serratus) - transplantes musculares com microanastomoses vasculares",
                        mod: "Serrato maior (serratus) - transplantes musculares com microanastomoses vasculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703174",
                        original: "Supinador longo (brachioradialis)",
                        mod: "Supinador longo (brachioradialis)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30703182",
                        original: "Tensor da fascia lata (tensor fascia lata) - transplantes musculares com microanastomoses vasculares",
                        mod: "Tensor da fascia lata (tensor fascia lata) - transplantes musculares com microanastomoses vasculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30704014",
                        original: "Costela",
                        mod: "Costela",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30704022",
                        original: "Ilíaco",
                        mod: "Ilíaco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30704030",
                        original: "Osteocutâneo de ilíaco",
                        mod: "Osteocutâneo de ilíaco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30704049",
                        original: "Osteocutâneos de costela",
                        mod: "Osteocutâneos de costela",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30704057",
                        original: "Osteomusculocutâneo de costela",
                        mod: "Osteomusculocutâneo de costela",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30704065",
                        original: "Outros transplantes ósseos e osteomusculocutâneos",
                        mod: "Outros transplantes ósseos e osteomusculocutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30704073",
                        original: "Perônio ou fíbula",
                        mod: "Perônio ou fíbula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30704081",
                        original: "Transplante ósseo vascularizado (microanastomose)",
                        mod: "Transplante ósseo vascularizado (microanastomose)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30705010",
                        original: "Autotransplante de dois retalhos  musculares combinados, isolados e associados entre si, ligados por um único pedículo",
                        mod: "Autotransplante de dois retalhos  musculares combinados, isolados e associados entre si, ligados por um único pedículo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30705029",
                        original: "Autotransplante de dois retalhos cutâneos combinados, isolados e associados entre si, ligados por um único pedículo vascular",
                        mod: "Autotransplante de dois retalhos cutâneos combinados, isolados e associados entre si, ligados por um único pedículo vascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30705037",
                        original: "Autotransplante de dois retalhos,  um  cutâneo  combinado a um muscular,  isolados  e associados entre si, ligados por um único pedículo vascular",
                        mod: "Autotransplante de dois retalhos,  um  cutâneo  combinado a um muscular,  isolados  e associados entre si, ligados por um único pedículo vascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30705045",
                        original: "Autotransplante de dois retalhos, um cutâneo combinado a retalho osteomuscular, isolados e associados entre sí, ligados por um único pedículo vascular",
                        mod: "Autotransplante de dois retalhos, um cutâneo combinado a retalho osteomuscular, isolados e associados entre sí, ligados por um único pedículo vascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30705053",
                        original: "Autotransplante de epiplon",
                        mod: "Autotransplante de epiplon",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30705061",
                        original: "Autotransplante de outros retalhos,  isolados  entre  si, e associados mediante um único pedículo vascular comuns aos retalhos",
                        mod: "Autotransplante de outros retalhos,  isolados  entre  si, e associados mediante um único pedículo vascular comuns aos retalhos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30705070",
                        original: "Autotransplante de três retalhos, um cutâneo separado, combinado a outros dois retalhos musculares isolados e associados, ligados por um único pedículo vascular",
                        mod: "Autotransplante de três retalhos, um cutâneo separado, combinado a outros dois retalhos musculares isolados e associados, ligados por um único pedículo vascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30705100",
                        original: "Reimplante de segmentos distais do membro superior, com ressecção segmentar",
                        mod: "Reimplante de segmentos distais do membro superior, com ressecção segmentar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30706017",
                        original: "Reimplante do membro inferior do nível médio proximal da perna até a coxa",
                        mod: "Reimplante do membro inferior do nível médio proximal da perna até a coxa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30706025",
                        original: "Reimplante do membro inferior do pé até o terço médio da perna",
                        mod: "Reimplante do membro inferior do pé até o terço médio da perna",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30706033",
                        original: "Reimplante do membro superior, do nível médio do antebraço até o ombro",
                        mod: "Reimplante do membro superior, do nível médio do antebraço até o ombro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30707013",
                        original: "Transplante articular de metatarsofalângica para a mão",
                        mod: "Transplante articular de metatarsofalângica para a mão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30707021",
                        original: "Transplante de 2º pododáctilo para mão",
                        mod: "Transplante de 2º pododáctilo para mão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30707030",
                        original: "Transplante de dedos do pé para a mão",
                        mod: "Transplante de dedos do pé para a mão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30707048",
                        original: "Transplante do 2º pododáctilo para o polegar",
                        mod: "Transplante do 2º pododáctilo para o polegar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30707056",
                        original: "Transplante do hallux para polegar",
                        mod: "Transplante do hallux para polegar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30707064",
                        original: "Transplante de dois pododáctilos para a mão",
                        mod: "Transplante de dois pododáctilos para a mão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30709016",
                        original: "Instalação de halo craniano",
                        mod: "Instalação de halo craniano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30709024",
                        original: "Tração cutânea",
                        mod: "Tração cutânea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30709032",
                        original: "Tração transesquelética (por membro)",
                        mod: "Tração transesquelética (por membro)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30710014",
                        original: "Retirada de fios ou pinos metálicos transósseos",
                        mod: "Retirada de fios ou pinos metálicos transósseos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30710022",
                        original: "Retirada de fios, pinos, parafusos ou hastes metálicas intra-ósseas",
                        mod: "Retirada de fios, pinos, parafusos ou hastes metálicas intra-ósseas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30710030",
                        original: "Retirada de placas",
                        mod: "Retirada de placas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30710049",
                        original: "Retirada de próteses de substituição de pequenas articulações",
                        mod: "Retirada de próteses de substituição de pequenas articulações",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30710057",
                        original: "Retirada de fixadores externos",
                        mod: "Retirada de fixadores externos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30710065",
                        original: "Retirada de prótese de substituição (grandes e médias articulações)",
                        mod: "Retirada de prótese de substituição (grandes e médias articulações)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30711010",
                        original: "Imobilizações não-gessadas (qualquer segmento)",
                        mod: "Imobilizações não-gessadas (qualquer segmento)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30711029",
                        original: "Imobilização de membro inferior",
                        mod: "Imobilização de membro inferior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30711037",
                        original: "Imobilização de membro superior",
                        mod: "Imobilização de membro superior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712017",
                        original: "Áxilo-palmar ou pendente",
                        mod: "Áxilo-palmar ou pendente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712025",
                        original: "Bota com ou sem salto",
                        mod: "Bota com ou sem salto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712033",
                        original: "Colar",
                        mod: "Colar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712041",
                        original: "Colete",
                        mod: "Colete",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712050",
                        original: "Cruro-podálico",
                        mod: "Cruro-podálico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712068",
                        original: "Dupla abdução ou Ducroquet",
                        mod: "Dupla abdução ou Ducroquet",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712076",
                        original: "Halo-gesso",
                        mod: "Halo-gesso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712084",
                        original: "Inguino-maleolar",
                        mod: "Inguino-maleolar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712092",
                        original: "Luva",
                        mod: "Luva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712106",
                        original: "Minerva ou Risser para escoliose",
                        mod: "Minerva ou Risser para escoliose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712114",
                        original: "Pelvipodálico",
                        mod: "Pelvipodálico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712122",
                        original: "Spica-gessada",
                        mod: "Spica-gessada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712130",
                        original: "Tipo Velpeau",
                        mod: "Tipo Velpeau",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30712149",
                        original: "Tóraco-braquial",
                        mod: "Tóraco-braquial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30713021",
                        original: "Biópsia óssea",
                        mod: "Biópsia óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30713030",
                        original: "Biópsias percutânea sinovial ou de tecidos moles",
                        mod: "Biópsias percutânea sinovial ou de tecidos moles",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30713048",
                        original: "Enxertos em outras pseudartroses",
                        mod: "Enxertos em outras pseudartroses",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30713064",
                        original: "Manipulação articular sob anestesia geral",
                        mod: "Manipulação articular sob anestesia geral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30713072",
                        original: "Retirada de enxerto ósseo",
                        mod: "Retirada de enxerto ósseo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30713137",
                        original: "Punção articular diagnóstica ou terapêutica (infiltração) - orientada ou não por método de imagem",
                        mod: "Punção articular diagnóstica ou terapêutica (infiltração) - orientada ou não por método de imagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30713145",
                        original: "Punção extra-articular diagnóstica ou terapêutica (infiltração/agulhamento seco) - orientada ou não por método de imagem",
                        mod: "Punção extra-articular diagnóstica ou terapêutica (infiltração/agulhamento seco) - orientada ou não por método de imagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30713153",
                        original: "Artroscopia para diagnóstico com ou sem biópsia sinovial",
                        mod: "Artroscopia para diagnóstico com ou sem biópsia sinovial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30714010",
                        original: "Corpo estranho intra-articular - tratamento cirúrgico",
                        mod: "Corpo estranho intra-articular - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30714028",
                        original: "Corpo estranho intra-ósseo - tratamento cirúrgico",
                        mod: "Corpo estranho intra-ósseo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30714036",
                        original: "Corpo estranho intramuscular - tratamento cirúrgico",
                        mod: "Corpo estranho intramuscular - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715016",
                        original: "Artrodese da coluna com instrumentação por segmento",
                        mod: "Artrodese da coluna com instrumentação por segmento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715024",
                        original: "Artrodese de coluna via anterior ou póstero lateral - tratamento cirúrgico",
                        mod: "Artrodese de coluna via anterior ou póstero lateral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715032",
                        original: "Biópsia da coluna",
                        mod: "Biópsia da coluna",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715040",
                        original: "Biópsia de corpo vertebral com agulha",
                        mod: "Biópsia de corpo vertebral com agulha",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715059",
                        original: "Cirurgia de coluna por via endoscópica",
                        mod: "Cirurgia de coluna por via endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715067",
                        original: "Cordotomia - mielotomia",
                        mod: "Cordotomia - mielotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715075",
                        original: "Costela cervical - tratamento cirúrgico",
                        mod: "Costela cervical - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715083",
                        original: "Derivação lombar externa",
                        mod: "Derivação lombar externa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715091",
                        original: "Descompressão medular e/ou cauda equina",
                        mod: "Descompressão medular e/ou cauda equina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715105",
                        original: "Dorso curvo / escoliose / giba costal - tratamento cirúrgico",
                        mod: "Dorso curvo / escoliose / giba costal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715113",
                        original: "Espondilolistese - tratamento cirúrgico",
                        mod: "Espondilolistese - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715121",
                        original: "Fratura de coluna sem gesso - tratamento conservador",
                        mod: "Fratura de coluna sem gesso - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715130",
                        original: "Fratura do cóccix - redução incruenta",
                        mod: "Fratura do cóccix - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715148",
                        original: "Fratura do cóccix - tratamento cirúrgico",
                        mod: "Fratura do cóccix - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715156",
                        original: "Fratura e/ou luxação de coluna vertebral - redução incruenta",
                        mod: "Fratura e/ou luxação de coluna vertebral - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715164",
                        original: "Fraturas ou fratura-luxação de coluna - tratamento cirúrgico",
                        mod: "Fraturas ou fratura-luxação de coluna - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715172",
                        original: "Hemivértebra - ressecção via anterior ou posterior - tratamento cirúrgico",
                        mod: "Hemivértebra - ressecção via anterior ou posterior - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715180",
                        original: "Hérnia de disco tóraco-lombar - tratamento cirúrgico",
                        mod: "Hérnia de disco tóraco-lombar - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715199",
                        original: "Laminectomia ou laminotomia",
                        mod: "Laminectomia ou laminotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715202",
                        original: "Microcirurgia para tumores extra-intradurais",
                        mod: "Microcirurgia para tumores extra-intradurais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715210",
                        original: "Osteomielite de coluna - tratamento cirúrgico",
                        mod: "Osteomielite de coluna - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715229",
                        original: "Osteotomia de coluna vertebral - tratamento cirúrgico",
                        mod: "Osteotomia de coluna vertebral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715237",
                        original: "Outras afecções da coluna - tratamento incruento",
                        mod: "Outras afecções da coluna - tratamento incruento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715245",
                        original: "Pseudartrose de coluna - tratamento cirúrgico",
                        mod: "Pseudartrose de coluna - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715253",
                        original: "Punção liquórica",
                        mod: "Punção liquórica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715261",
                        original: "Retirada de corpo estranho - tratamento cirúrgico",
                        mod: "Retirada de corpo estranho - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715270",
                        original: "Retirada de material de síntese - tratamento cirúrgico",
                        mod: "Retirada de material de síntese - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715288",
                        original: "Substituição de corpo vertebral",
                        mod: "Substituição de corpo vertebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715296",
                        original: "Tração cervical transesquelética",
                        mod: "Tração cervical transesquelética",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715300",
                        original: "Tratamento cirúrgico da cifose infantil",
                        mod: "Tratamento cirúrgico da cifose infantil",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715318",
                        original: "Tratamento cirúrgico da lesão traumática raquimedular",
                        mod: "Tratamento cirúrgico da lesão traumática raquimedular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715326",
                        original: "Tratamento cirúrgico das malformações craniovertebrais",
                        mod: "Tratamento cirúrgico das malformações craniovertebrais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715334",
                        original: "Tratamento cirúrgico do disrafismo espinhal",
                        mod: "Tratamento cirúrgico do disrafismo espinhal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715342",
                        original: "Tratamento conservador do traumatismo raquimedular (por dia)",
                        mod: "Tratamento conservador do traumatismo raquimedular (por dia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715350",
                        original: "Tratamento microcirúrgico das lesões intramedulares (tumor, malformações arteriovenosas, siringomielia, parasitoses)",
                        mod: "Tratamento microcirúrgico das lesões intramedulares (tumor, malformações arteriovenosas, siringomielia, parasitoses)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715369",
                        original: "Tratamento microcirúrgico do canal vertebral estreito por segmento",
                        mod: "Tratamento microcirúrgico do canal vertebral estreito por segmento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715377",
                        original: "Tratamento pré-natal dos disrafismos espinhais",
                        mod: "Tratamento pré-natal dos disrafismos espinhais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715385",
                        original: "Tumor ósseo vertebral - ressecção com substituição com ou sem instrumentação - tratamento cirúrgico",
                        mod: "Tumor ósseo vertebral - ressecção com substituição com ou sem instrumentação - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715393",
                        original: "Hérnia de disco cervical - tratamento cirúrgico",
                        mod: "Hérnia de disco cervical - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715407",
                        original: "Fratura de coluna com gesso - tratamento conservador",
                        mod: "Fratura de coluna com gesso - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30715423",
                        original: "Radiculotomia",
                        mod: "Radiculotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717019",
                        original: "Artrodese ao nível do ombro - tratamento cirúrgico",
                        mod: "Artrodese ao nível do ombro - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717027",
                        original: "Artroplastia escápulo umeral com implante - tratamento cirúrgico",
                        mod: "Artroplastia escápulo umeral com implante - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717035",
                        original: "Artrotomia glenoumeral - tratamento cirúrgico",
                        mod: "Artrotomia glenoumeral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717043",
                        original: "Biópsia cirúrgica da cintura escapular",
                        mod: "Biópsia cirúrgica da cintura escapular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717051",
                        original: "Deformidade (doença) Sprengel - tratamento cirúrgico",
                        mod: "Deformidade (doença) Sprengel - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717060",
                        original: "Desarticulação ao nível do ombro - tratamento cirúrgico",
                        mod: "Desarticulação ao nível do ombro - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717078",
                        original: "Escápula em ressalto - tratamento cirúrgico",
                        mod: "Escápula em ressalto - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717086",
                        original: "Fratura de cintura escapular - tratamento conservador",
                        mod: "Fratura de cintura escapular - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717094",
                        original: "Fraturas e/ou luxações e/ou avulsões - redução incruenta - em articulação escápulo-umeral e cintura escapular",
                        mod: "Fraturas e/ou luxações e/ou avulsões - redução incruenta - em articulação escápulo-umeral e cintura escapular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717108",
                        original: "Fraturas e/ou luxações e/ou avulsões - tratamento cirúrgico - em articulação escápulo-umeral e cintura escapular",
                        mod: "Fraturas e/ou luxações e/ou avulsões - tratamento cirúrgico - em articulação escápulo-umeral e cintura escapular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717116",
                        original: "Luxações crônicas inveteradas e recidivantes - tratamento cirúrgico - em articulação escápulo-umeral e cintura escapular",
                        mod: "Luxações crônicas inveteradas e recidivantes - tratamento cirúrgico - em articulação escápulo-umeral e cintura escapular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717124",
                        original: "Osteomielite ao nível da cintura escapular - tratamento cirúrgico",
                        mod: "Osteomielite ao nível da cintura escapular - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717132",
                        original: "Pseudartroses e/ou osteotomias da cintura escapular - tratamento cirúrgico",
                        mod: "Pseudartroses e/ou osteotomias da cintura escapular - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717140",
                        original: "Ressecção parcial ou total de clavícula - tratamento cirúrgico",
                        mod: "Ressecção parcial ou total de clavícula - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717159",
                        original: "Revisão cirúrgica de prótese de ombro",
                        mod: "Revisão cirúrgica de prótese de ombro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717167",
                        original: "Transferências musculares ao nível do ombro - tratamento cirúrgico",
                        mod: "Transferências musculares ao nível do ombro - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30717175",
                        original: "Fratura de clavícula ou escápula - tratamento conservador",
                        mod: "Fratura de clavícula ou escápula - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30718015",
                        original: "Amputação ao nível do braço - tratamento cirúrgico",
                        mod: "Amputação ao nível do braço - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30718023",
                        original: "Biópsia cirúrgica do úmero",
                        mod: "Biópsia cirúrgica do úmero",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30718031",
                        original: "Fixador externo dinâmico com ou sem alongamento - tratamento cirúrgico",
                        mod: "Fixador externo dinâmico com ou sem alongamento - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30718040",
                        original: "Fratura (incluindo descolamento epifisário) - redução incruenta",
                        mod: "Fratura (incluindo descolamento epifisário) - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30718058",
                        original: "Fratura (incluindo descolamento epifisário) - tratamento cirúrgico",
                        mod: "Fratura (incluindo descolamento epifisário) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30718066",
                        original: "Fratura de úmero - tratamento conservador",
                        mod: "Fratura de úmero - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30718074",
                        original: "Fraturas e pseudartroses - fixador externo - tratamento cirúrgico",
                        mod: "Fraturas e pseudartroses - fixador externo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30718082",
                        original: "Osteomielite de úmero - tratamento cirúrgico",
                        mod: "Osteomielite de úmero - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30718090",
                        original: "Pseudartroses, osteotomias, alongamentos/encurtamentos - tratamento cirúrgico",
                        mod: "Pseudartroses, osteotomias, alongamentos/encurtamentos - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30718104",
                        original: "Osteomielite de úmero - tratamento incruento",
                        mod: "Osteomielite de úmero - tratamento incruento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719011",
                        original: "Artrodese - tratamento cirúrgico",
                        mod: "Artrodese - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719020",
                        original: "Artroplastia com implante - tratamento cirúrgico",
                        mod: "Artroplastia com implante - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719038",
                        original: "Artroplastias sem implante - tratamento cirúrgico",
                        mod: "Artroplastias sem implante - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719046",
                        original: "Artrotomia de cotovelo - tratamento cirúrgico",
                        mod: "Artrotomia de cotovelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719054",
                        original: "Biópsia cirúrgica de cotovelo",
                        mod: "Biópsia cirúrgica de cotovelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719062",
                        original: "Desarticulação ao nível do cotovelo - tratamento cirúrgico",
                        mod: "Desarticulação ao nível do cotovelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719070",
                        original: "Fratura de cotovelo - tratamento conservador",
                        mod: "Fratura de cotovelo - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719089",
                        original: "Fraturas / pseudartroses / artroses / com fixador externo dinâmico - tratamento cirúrgico",
                        mod: "Fraturas / pseudartroses / artroses / com fixador externo dinâmico - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719097",
                        original: "Fraturas e ou luxações - redução incruenta",
                        mod: "Fraturas e ou luxações - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719100",
                        original: "Fraturas e ou luxações - tratamento cirúrgico",
                        mod: "Fraturas e ou luxações - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719119",
                        original: "Lesões ligamentares - redução incruenta",
                        mod: "Lesões ligamentares - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719127",
                        original: "Tendinites, sinovites e artrites - tratamento cirúrgico",
                        mod: "Tendinites, sinovites e artrites - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30719135",
                        original: "Artrodiastase - tratamento cirúrgico com fixador externo",
                        mod: "Artrodiastase - tratamento cirúrgico com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720010",
                        original: "Abaixamento miotendinoso no antebraço",
                        mod: "Abaixamento miotendinoso no antebraço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720028",
                        original: "Alongamento dos ossos do antebraço com fixador externo dinâmico - tratamento cirúrgico",
                        mod: "Alongamento dos ossos do antebraço com fixador externo dinâmico - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720036",
                        original: "Amputação ao nível do antebraço - tratamento cirúrgico",
                        mod: "Amputação ao nível do antebraço - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720044",
                        original: "Biópsia cirúrgica do antebraço",
                        mod: "Biópsia cirúrgica do antebraço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720052",
                        original: "Contratura isquêmica de Volkmann - tratamento cirúrgico",
                        mod: "Contratura isquêmica de Volkmann - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720060",
                        original: "Correção de deformidade adquirida de antebraço com fixador externo",
                        mod: "Correção de deformidade adquirida de antebraço com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720079",
                        original: "Encurtamento segmentar dos ossos do antebraço com osteossíntese - tratamento cirúrgico",
                        mod: "Encurtamento segmentar dos ossos do antebraço com osteossíntese - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720087",
                        original: "Fratura do antebraço - tratamento conservador",
                        mod: "Fratura do antebraço - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720095",
                        original: "Fratura e/ou luxações (incluindo descolamento epifisário cotovelo-punho) - tratamento cirúrgico",
                        mod: "Fratura e/ou luxações (incluindo descolamento epifisário cotovelo-punho) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720109",
                        original: "Fratura e/ou luxações (incluindo descolamento epifisário) - redução incruenta",
                        mod: "Fratura e/ou luxações (incluindo descolamento epifisário) - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720117",
                        original: "Fratura viciosamente consolidada de antebraço - tratamento cirúrgico",
                        mod: "Fratura viciosamente consolidada de antebraço - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720125",
                        original: "Osteomielite dos ossos do antebraço - tratamento cirúrgico",
                        mod: "Osteomielite dos ossos do antebraço - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720133",
                        original: "Pseudartroses e ou osteotomias - tratamento cirúrgico",
                        mod: "Pseudartroses e ou osteotomias - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720141",
                        original: "Ressecção da cabeça do rádio e/ ou da extremidade distal ulna - tratamento cirúrgico",
                        mod: "Ressecção da cabeça do rádio e/ ou da extremidade distal ulna - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720150",
                        original: "Ressecção do processo estilóide do rádio - tratamento cirúrgico",
                        mod: "Ressecção do processo estilóide do rádio - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720168",
                        original: "Sinostose rádio-ulnar - tratamento cirúrgico",
                        mod: "Sinostose rádio-ulnar - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30720176",
                        original: "Tratamento cirúrgico de fraturas com fixador externo - antebraço",
                        mod: "Tratamento cirúrgico de fraturas com fixador externo - antebraço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721016",
                        original: "Agenesia de rádio (centralização da ulna no carpo)",
                        mod: "Agenesia de rádio (centralização da ulna no carpo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721024",
                        original: "Alongamento do rádio/ulna - tratamento cirúrgico",
                        mod: "Alongamento do rádio/ulna - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721032",
                        original: "Artrodese entre os ossos do carpo",
                        mod: "Artrodese entre os ossos do carpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721040",
                        original: "Artrodese - fixador externo",
                        mod: "Artrodese - fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721059",
                        original: "Artrodese rádio-cárpica ou do punho",
                        mod: "Artrodese rádio-cárpica ou do punho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721067",
                        original: "Artroplastia do punho (com implante) - tratamento cirúrgico",
                        mod: "Artroplastia do punho (com implante) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721075",
                        original: "Artroplastia para ossos do carpo (com implante) - tratamento cirúrgico",
                        mod: "Artroplastia para ossos do carpo (com implante) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721083",
                        original: "Artrotomia - tratamento cirúrgico - punho",
                        mod: "Artrotomia - tratamento cirúrgico - punho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721091",
                        original: "Biópsia cirúrgica de punho",
                        mod: "Biópsia cirúrgica de punho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721105",
                        original: "Coto de amputação punho e antebraço - revisão",
                        mod: "Coto de amputação punho e antebraço - revisão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721113",
                        original: "Desarticulação do punho - tratamento cirúrgico",
                        mod: "Desarticulação do punho - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721121",
                        original: "Encurtamento rádio/ulnar",
                        mod: "Encurtamento rádio/ulnar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721130",
                        original: "Fratura de punho - tratamento conservador",
                        mod: "Fratura de punho - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721148",
                        original: "Fratura de osso do carpo - redução cirúrgica",
                        mod: "Fratura de osso do carpo - redução cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721156",
                        original: "Fratura do carpo - redução incruenta",
                        mod: "Fratura do carpo - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721164",
                        original: "Fraturas - fixador externo",
                        mod: "Fraturas - fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721172",
                        original: "Fraturas do carpo - tratamento conservador",
                        mod: "Fraturas do carpo - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721180",
                        original: "Fraturas e/ou luxações do punho - redução incruenta",
                        mod: "Fraturas e/ou luxações do punho - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721199",
                        original: "Fraturas e/ou luxações do punho - tratamento cirúrgico",
                        mod: "Fraturas e/ou luxações do punho - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721202",
                        original: "Luxação do carpo - redução incruenta",
                        mod: "Luxação do carpo - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721210",
                        original: "Pseudartroses - tratamento cirúrgico",
                        mod: "Pseudartroses - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721229",
                        original: "Ressecção de osso do carpo - tratamento cirúrgico",
                        mod: "Ressecção de osso do carpo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721237",
                        original: "Reparação ligamentar do carpo",
                        mod: "Reparação ligamentar do carpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721245",
                        original: "Sinovectomia de punho - tratamento cirúrgico",
                        mod: "Sinovectomia de punho - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30721253",
                        original: "Transposição do rádio para ulna",
                        mod: "Transposição do rádio para ulna",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722012",
                        original: "Abscesso de mão e dedos - tenossinovites / espaços palmares / dorsais e comissurais - tratamento cirúrgico",
                        mod: "Abscesso de mão e dedos - tenossinovites / espaços palmares / dorsais e comissurais - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722039",
                        original: "Abscessos de dedo (drenagem) - tratamento cirúrgico",
                        mod: "Abscessos de dedo (drenagem) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722047",
                        original: "Alongamento/transporte ósseo com fixador externo",
                        mod: "Alongamento/transporte ósseo com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722055",
                        original: "Alongamentos tendinosos de mão",
                        mod: "Alongamentos tendinosos de mão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722063",
                        original: "Amputação ao nível dos metacarpianos - tratamento cirúrgico",
                        mod: "Amputação ao nível dos metacarpianos - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722071",
                        original: "Amputação de dedo (cada) - tratamento cirúrgico",
                        mod: "Amputação de dedo (cada) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722080",
                        original: "Amputação transmetacarpiana",
                        mod: "Amputação transmetacarpiana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722098",
                        original: "Amputação transmetacarpiana com transposição de dedo",
                        mod: "Amputação transmetacarpiana com transposição de dedo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722101",
                        original: "Aponevrose palmar (ressecção) - tratamento cirúrgico",
                        mod: "Aponevrose palmar (ressecção) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722110",
                        original: "Artrodese interfalangeana / metacarpofalangeana -  tratamento cirúrgico",
                        mod: "Artrodese interfalangeana / metacarpofalangeana -  tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722128",
                        original: "Artroplastia com implante na mão (MF e IF) múltipla",
                        mod: "Artroplastia com implante na mão (MF e IF) múltipla",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722136",
                        original: "Artroplastia com implante na mão (MF ou IF)",
                        mod: "Artroplastia com implante na mão (MF ou IF)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722144",
                        original: "Artroplastia interfalangeana / metacarpofalangeana - tratamento cirúrgico",
                        mod: "Artroplastia interfalangeana / metacarpofalangeana - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722152",
                        original: "Artrotomia ao nível da mão -  tratamento cirúrgico",
                        mod: "Artrotomia ao nível da mão -  tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722160",
                        original: "Biópsia cirúrgica dos ossos da mão",
                        mod: "Biópsia cirúrgica dos ossos da mão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722179",
                        original: "Bridas congênitas - tratamento cirúrgico",
                        mod: "Bridas congênitas - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722209",
                        original: "Capsulectomias múltiplas MF ou IF",
                        mod: "Capsulectomias múltiplas MF ou IF",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722217",
                        original: "Capsulectomias única MF e IF",
                        mod: "Capsulectomias única MF e IF",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722225",
                        original: "Centralização da ulna (tratamento da mão torta radial)",
                        mod: "Centralização da ulna (tratamento da mão torta radial)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722233",
                        original: "Contratura isquêmica de mão - tratamento cirúrgico",
                        mod: "Contratura isquêmica de mão - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722241",
                        original: "Coto de amputação digital - revisão",
                        mod: "Coto de amputação digital - revisão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722250",
                        original: "Dedo colo de cisne - tratamento cirúrgico",
                        mod: "Dedo colo de cisne - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722268",
                        original: "Dedo em botoeira - tratamento cirúrgico",
                        mod: "Dedo em botoeira - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722276",
                        original: "Dedo em gatilho, capsulotomia / fasciotomia - tratamento cirúrgico",
                        mod: "Dedo em gatilho, capsulotomia / fasciotomia - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722284",
                        original: "Dedo em martelo - tratamento cirúrgico",
                        mod: "Dedo em martelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722292",
                        original: "Dedo em martelo - tratamento conservador",
                        mod: "Dedo em martelo - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722306",
                        original: "Enxerto ósseo (perda de substância) - tratamento cirúrgico",
                        mod: "Enxerto ósseo (perda de substância) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722314",
                        original: "Exploração cirúrgica de tendão de mão",
                        mod: "Exploração cirúrgica de tendão de mão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722322",
                        original: "Falangização",
                        mod: "Falangização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722330",
                        original: "Fixador externo em cirurgia da mão",
                        mod: "Fixador externo em cirurgia da mão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722349",
                        original: "Fratura de falanges - tratamento conservador",
                        mod: "Fratura de falanges - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722357",
                        original: "Fratura de Bennett - redução incruenta",
                        mod: "Fratura de Bennett - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722365",
                        original: "Fratura de Bennett - tratamento cirúrgico",
                        mod: "Fratura de Bennett - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722373",
                        original: "Fratura de osso da mão - tratamento conservador",
                        mod: "Fratura de osso da mão - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722381",
                        original: "Fratura de metacarpiano - tratamento conservador",
                        mod: "Fratura de metacarpiano - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722390",
                        original: "Fratura/artrodese com fixador externo",
                        mod: "Fratura/artrodese com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722403",
                        original: "Fraturas de falanges ou metacarpianos - redução incruenta",
                        mod: "Fraturas de falanges ou metacarpianos - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722411",
                        original: "Fraturas de falanges ou metacarpianos - tratamento cirúrgico com fixação",
                        mod: "Fraturas de falanges ou metacarpianos - tratamento cirúrgico com fixação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722420",
                        original: "Fraturas e/ou luxações de falanges (interfalangeanas) - redução incruenta",
                        mod: "Fraturas e/ou luxações de falanges (interfalangeanas) - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722438",
                        original: "Fraturas e/ou luxações de falanges (interfalangeanas) - tratamento cirúrgico",
                        mod: "Fraturas e/ou luxações de falanges (interfalangeanas) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722446",
                        original: "Fraturas e/ou luxações de metacarpianos - redução incruenta",
                        mod: "Fraturas e/ou luxações de metacarpianos - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722454",
                        original: "Gigantismo ao nível da mão - tratamento cirúrgico",
                        mod: "Gigantismo ao nível da mão - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722462",
                        original: "Lesões ligamentares agudas da mão - reparação cirúrgica",
                        mod: "Lesões ligamentares agudas da mão - reparação cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722470",
                        original: "Lesões ligamentares crônicas da mão - reparação cirúrgica",
                        mod: "Lesões ligamentares crônicas da mão - reparação cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722489",
                        original: "Ligamentoplastia com âncora",
                        mod: "Ligamentoplastia com âncora",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722497",
                        original: "Luxação metacarpofalangeana - redução incruenta",
                        mod: "Luxação metacarpofalangeana - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722500",
                        original: "Luxação metacarpofalangeana - tratamento cirúrgico",
                        mod: "Luxação metacarpofalangeana - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722519",
                        original: "Osteomielite ao nível da mão - tratamento cirúrgico",
                        mod: "Osteomielite ao nível da mão - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722527",
                        original: "Osteossíntese de fratura de falange e metacarpeana com fixação externa",
                        mod: "Osteossíntese de fratura de falange e metacarpeana com fixação externa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722535",
                        original: "Osteossíntese de fratura de falange e metacarpeana com uso de miniparafuso",
                        mod: "Osteossíntese de fratura de falange e metacarpeana com uso de miniparafuso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722543",
                        original: "Perda de substância da mão (reparação) - tratamento cirúrgico",
                        mod: "Perda de substância da mão (reparação) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722551",
                        original: "Plástica ungueal",
                        mod: "Plástica ungueal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722560",
                        original: "Policização ou transferência digital",
                        mod: "Policização ou transferência digital",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722578",
                        original: "Polidactilia articulada - tratamento cirúrgico",
                        mod: "Polidactilia articulada - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722586",
                        original: "Polidactilia não articulada - tratamento cirúrgico",
                        mod: "Polidactilia não articulada - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722594",
                        original: "Prótese (implante) para ossos do carpo",
                        mod: "Prótese (implante) para ossos do carpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722608",
                        original: "Pseudartrose com perda de substâncias de metacarpiano e falanges",
                        mod: "Pseudartrose com perda de substâncias de metacarpiano e falanges",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722616",
                        original: "Pseudartrose do escafóide - tratamento cirúrgico",
                        mod: "Pseudartrose do escafóide - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722624",
                        original: "Pseudartrose dos ossos da mão - tratamento cirúrgico",
                        mod: "Pseudartrose dos ossos da mão - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722632",
                        original: "Reconstrução da falange com retalho homodigital",
                        mod: "Reconstrução da falange com retalho homodigital",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722640",
                        original: "Reconstrução de leito ungueal",
                        mod: "Reconstrução de leito ungueal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722659",
                        original: "Reconstrução do polegar com retalho ilhado osteocutâneo antebraquial",
                        mod: "Reconstrução do polegar com retalho ilhado osteocutâneo antebraquial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722667",
                        original: "Reimplante de dois dedos da mão",
                        mod: "Reimplante de dois dedos da mão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722675",
                        original: "Reimplante do membro superior nível transmetacarpiano até o terço distal do antebraço",
                        mod: "Reimplante do membro superior nível transmetacarpiano até o terço distal do antebraço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722683",
                        original: "Reimplante do polegar",
                        mod: "Reimplante do polegar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722691",
                        original: "Reparações cutâneas com retalho ilhado antebraquial invertido",
                        mod: "Reparações cutâneas com retalho ilhado antebraquial invertido",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722705",
                        original: "Ressecção 1ª fileira dos ossos do carpo",
                        mod: "Ressecção 1ª fileira dos ossos do carpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722713",
                        original: "Ressecção de cisto sinovial",
                        mod: "Ressecção de cisto sinovial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722721",
                        original: "Retração cicatricial de mais de um dedo, sem comprometimento tendinoso - tratamento cirúrgico",
                        mod: "Retração cicatricial de mais de um dedo, sem comprometimento tendinoso - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722730",
                        original: "Retração cicatricial de um dedo sem comprometimento tendinoso - tratamento cirúrgico",
                        mod: "Retração cicatricial de um dedo sem comprometimento tendinoso - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722748",
                        original: "Retração cicatricial dos dedos com lesão tendínea - tratamento cirúrgico",
                        mod: "Retração cicatricial dos dedos com lesão tendínea - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722756",
                        original: "Revascularização  do  polegar  ou  outro  dedo",
                        mod: "Revascularização  do  polegar  ou  outro  dedo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722764",
                        original: "Roturas do aparelho extensor de dedo - redução incruenta",
                        mod: "Roturas do aparelho extensor de dedo - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722772",
                        original: "Roturas tendino-ligamentares da mão (mais que 1) - tratamento cirúrgico",
                        mod: "Roturas tendino-ligamentares da mão (mais que 1) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722780",
                        original: "Sequestrectomias",
                        mod: "Sequestrectomias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722799",
                        original: "Sindactilia de 2 dígitos - tratamento cirúrgico",
                        mod: "Sindactilia de 2 dígitos - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722802",
                        original: "Sindactilia múltipla - tratamento cirúrgico",
                        mod: "Sindactilia múltipla - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722810",
                        original: "Sinovectomia da mão (1 articulação)",
                        mod: "Sinovectomia da mão (1 articulação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722829",
                        original: "Sinovectomia da mão (múltiplas)",
                        mod: "Sinovectomia da mão (múltiplas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722845",
                        original: "Transposição de dedo - tratamento cirúrgico",
                        mod: "Transposição de dedo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722853",
                        original: "Tratamento cirúrgico da polidactilia múltipla e/ou complexa",
                        mod: "Tratamento cirúrgico da polidactilia múltipla e/ou complexa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722861",
                        original: "Tratamento cirúrgico da sindactilia múltipla com emprego de expansor - por estágio",
                        mod: "Tratamento cirúrgico da sindactilia múltipla com emprego de expansor - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722870",
                        original: "Tratamento da doença de Kiembuck com transplante vascularizado",
                        mod: "Tratamento da doença de Kiembuck com transplante vascularizado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722888",
                        original: "Tratamento da pseudoartrose do escafóide com transplante ósseo vascularizado e fixação com micro parafuso",
                        mod: "Tratamento da pseudoartrose do escafóide com transplante ósseo vascularizado e fixação com micro parafuso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722900",
                        original: "Roturas do aparelho extensor de dedo - tratamento conservador",
                        mod: "Roturas do aparelho extensor de dedo - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30722918",
                        original: "Rotura do aparelho extensor do dedo - tratamento cirúrgico",
                        mod: "Rotura do aparelho extensor do dedo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30723019",
                        original: "Biópsia cirúrgica de cintura pélvica",
                        mod: "Biópsia cirúrgica de cintura pélvica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30723027",
                        original: "Desarticulação interílio abdominal - tratamento cirúrgico",
                        mod: "Desarticulação interílio abdominal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30723035",
                        original: "Fratura da cintura pélvica - tratamento conservador",
                        mod: "Fratura da cintura pélvica - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30723043",
                        original: "Fratura/luxação com fixador externo - tratamento cirúrgico",
                        mod: "Fratura/luxação com fixador externo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30723051",
                        original: "Fraturas e/ou luxações do anel pélvico (com uma ou mais abordagens) - tratamento cirúrgico",
                        mod: "Fraturas e/ou luxações do anel pélvico (com uma ou mais abordagens) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30723060",
                        original: "Fraturas e/ou luxações do anel pélvico - redução incruenta",
                        mod: "Fraturas e/ou luxações do anel pélvico - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30723078",
                        original: "Osteomielite  ao nível da pelve - tratamento cirúrgico",
                        mod: "Osteomielite  ao nível da pelve - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30723086",
                        original: "Osteotomias / artrodeses - tratamento cirúrgico",
                        mod: "Osteotomias / artrodeses - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30723094",
                        original: "Fratura de pelve sem aparelho pelve-podálico - tratamento conservador",
                        mod: "Fratura de pelve sem aparelho pelve-podálico - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30723108",
                        original: "Fratura e/ou luxação de anel pélvico - tratamento conservador",
                        mod: "Fratura e/ou luxação de anel pélvico - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30723116",
                        original: "Fratura ou disjunção ao nível da pelve - tratamento conservador com gesso",
                        mod: "Fratura ou disjunção ao nível da pelve - tratamento conservador com gesso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30723124",
                        original: "Fratura ou disjunção ao nível da pelve - tratamento conservador sem gesso",
                        mod: "Fratura ou disjunção ao nível da pelve - tratamento conservador sem gesso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724015",
                        original: "Artrite séptica  - tratamento cirúrgico - articulação coxo-femoral",
                        mod: "Artrite séptica  - tratamento cirúrgico - articulação coxo-femoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724023",
                        original: "Artrodese / fratura de acetábulo (ligamentotaxia) com fixador externo",
                        mod: "Artrodese / fratura de acetábulo (ligamentotaxia) com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724031",
                        original: "Artrodese coxo-femoral em geral - tratamento cirúrgico",
                        mod: "Artrodese coxo-femoral em geral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724040",
                        original: "Artrodiastase de quadril",
                        mod: "Artrodiastase de quadril",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724058",
                        original: "Artroplastia (qualquer técnica ou versão de quadril) - tratamento cirúrgico",
                        mod: "Artroplastia (qualquer técnica ou versão de quadril) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724066",
                        original: "Artroplastia de quadril infectada (retirada dos componentes) - tratamento cirúrgico",
                        mod: "Artroplastia de quadril infectada (retirada dos componentes) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724074",
                        original: "Artroplastia de ressecção do quadril (Girdlestone) - tratamento cirúrgico",
                        mod: "Artroplastia de ressecção do quadril (Girdlestone) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724082",
                        original: "Artroplastia parcial do quadril (tipo Thompson ou qualquer técnica) - tratamento cirúrgico",
                        mod: "Artroplastia parcial do quadril (tipo Thompson ou qualquer técnica) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724090",
                        original: "Artrotomia de quadril infectada (incisão e drenagem de artrite séptica) sem retirada de componente - tratamento cirúrgico",
                        mod: "Artrotomia de quadril infectada (incisão e drenagem de artrite séptica) sem retirada de componente - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724104",
                        original: "Artrotomia coxo-femoral - tratamento cirúrgico",
                        mod: "Artrotomia coxo-femoral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724112",
                        original: "Biópsia cirúrgica coxo-femoral",
                        mod: "Biópsia cirúrgica coxo-femoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724120",
                        original: "Desarticulação coxo-femoral - tratamento cirúrgico",
                        mod: "Desarticulação coxo-femoral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724139",
                        original: "Epifisiodese com abaixamento do grande trocanter - tratamento cirúrgico",
                        mod: "Epifisiodese com abaixamento do grande trocanter - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724147",
                        original: "Epifisiolistese proximal de fêmur (fixação 'in situ') - tratamento cirúrgico",
                        mod: "Epifisiolistese proximal de fêmur (fixação 'in situ') - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724155",
                        original: "Fratura de acetábulo (com uma ou mais abordagens) - tratamento cirúrgico",
                        mod: "Fratura de acetábulo (com uma ou mais abordagens) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724163",
                        original: "Fratura de acetábulo - redução incruenta",
                        mod: "Fratura de acetábulo - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724171",
                        original: "Fratura e/ou luxação e/ou avulsão coxo-femoral - redução incruenta",
                        mod: "Fratura e/ou luxação e/ou avulsão coxo-femoral - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724180",
                        original: "Fratura e/ou luxação e/ou avulsão coxo-femoral - tratamento cirúrgico",
                        mod: "Fratura e/ou luxação e/ou avulsão coxo-femoral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724198",
                        original: "Luxação congênita de quadril (redução cirúrgica e osteotomia) - tratamento cirúrgico",
                        mod: "Luxação congênita de quadril (redução cirúrgica e osteotomia) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724201",
                        original: "Luxação congênita de quadril (redução cirúrgica simples) - tratamento cirúrgico",
                        mod: "Luxação congênita de quadril (redução cirúrgica simples) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724210",
                        original: "Luxação congênita de quadril (redução incruenta com ou sem tenotomia de adutores)",
                        mod: "Luxação congênita de quadril (redução incruenta com ou sem tenotomia de adutores)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724228",
                        original: "Osteotomia - fixador externo",
                        mod: "Osteotomia - fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724236",
                        original: "Osteotomias  ao  nível  do  colo ou  região trocanteriana (Sugioka, Martin, Bombelli etc) - tratamento cirúrgico",
                        mod: "Osteotomias  ao  nível  do  colo ou  região trocanteriana (Sugioka, Martin, Bombelli etc) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724244",
                        original: "Osteotomias supra-acetabulares (Chiari, Pemberton, 'dial', etc) - tratamento cirúrgico",
                        mod: "Osteotomias supra-acetabulares (Chiari, Pemberton, 'dial', etc) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724252",
                        original: "Punção-biópsia coxo-femoral-artrocentese",
                        mod: "Punção-biópsia coxo-femoral-artrocentese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724260",
                        original: "Reconstrução de quadril com fixador externo",
                        mod: "Reconstrução de quadril com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724279",
                        original: "Revisão de artroplastias de quadril com retirada de componentes e implante de prótese",
                        mod: "Revisão de artroplastias de quadril com retirada de componentes e implante de prótese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30724287",
                        original: "Tratamento  de necrose  avascular  por foragem de estaqueamento associada à necrose microcirúrgica  da cabeça femoral - tratamento cirúrgico",
                        mod: "Tratamento  de necrose  avascular  por foragem de estaqueamento associada à necrose microcirúrgica  da cabeça femoral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725011",
                        original: "Alongamento / transporte ósseo / pseudoartrose com fixador externo - coxa/fêmur",
                        mod: "Alongamento / transporte ósseo / pseudoartrose com fixador externo - coxa/fêmur",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725020",
                        original: "Alongamento de fêmur - tratamento cirúrgico",
                        mod: "Alongamento de fêmur - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725038",
                        original: "Amputação ao nível da coxa - tratamento cirúrgico",
                        mod: "Amputação ao nível da coxa - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725046",
                        original: "Biópsia cirúrgica de fêmur",
                        mod: "Biópsia cirúrgica de fêmur",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725054",
                        original: "Correção de deformidade adquirida de fêmur com fixador externo",
                        mod: "Correção de deformidade adquirida de fêmur com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725062",
                        original: "Descolamento epifisário (traumático ou não) - redução incruenta",
                        mod: "Descolamento epifisário (traumático ou não) - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725070",
                        original: "Descolamento epifisário (traumático ou não) - tratamento cirúrgico",
                        mod: "Descolamento epifisário (traumático ou não) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725089",
                        original: "Encurtamento de fêmur - tratamento cirúrgico",
                        mod: "Encurtamento de fêmur - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725097",
                        original: "Epifisiodese (por segmento) - tratamento cirúrgico",
                        mod: "Epifisiodese (por segmento) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725100",
                        original: "Fratura de fêmur - tratamento conservador com gesso",
                        mod: "Fratura de fêmur - tratamento conservador com gesso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725119",
                        original: "Fraturas de fêmur - redução incruenta",
                        mod: "Fraturas de fêmur - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725127",
                        original: "Fraturas de fêmur - tratamento cirúrgico",
                        mod: "Fraturas de fêmur - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725135",
                        original: "Fraturas,  pseudartroses,  correção  de  deformidades e  alongamentos com fixador externo dinâmico - tratamento cirúrgico",
                        mod: "Fraturas,  pseudartroses,  correção  de  deformidades e  alongamentos com fixador externo dinâmico - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725143",
                        original: "Osteomielite de fêmur - tratamento cirúrgico",
                        mod: "Osteomielite de fêmur - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725151",
                        original: "Pseudartroses e/ou osteotomias - tratamento cirúrgico",
                        mod: "Pseudartroses e/ou osteotomias - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725160",
                        original: "Tratamento cirúrgico de fraturas com fixador externo - coxa/fêmur",
                        mod: "Tratamento cirúrgico de fraturas com fixador externo - coxa/fêmur",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725178",
                        original: "Fêmur - descolamento epifisário de extremidades superior - tratamento conservador com gesso",
                        mod: "Fêmur - descolamento epifisário de extremidades superior - tratamento conservador com gesso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725186",
                        original: "Fêmur - descolamento epifisário de extremidades superior - tratamento conservador sem gesso",
                        mod: "Fêmur - descolamento epifisário de extremidades superior - tratamento conservador sem gesso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725194",
                        original: "Fratura de extremidade superior do fêmur ou cavidade cotilóide - tratamento conservador com gesso",
                        mod: "Fratura de extremidade superior do fêmur ou cavidade cotilóide - tratamento conservador com gesso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30725208",
                        original: "Necrose asséptica da cabeça femoral - tratamento conservador",
                        mod: "Necrose asséptica da cabeça femoral - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726018",
                        original: "Artrite séptica - tratamento cirúrgico - joelho",
                        mod: "Artrite séptica - tratamento cirúrgico - joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726026",
                        original: "Artrodese de joelho - tratamento cirúrgico",
                        mod: "Artrodese de joelho - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726034",
                        original: "Artroplastia total de joelho com implantes - tratamento cirúrgico",
                        mod: "Artroplastia total de joelho com implantes - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726042",
                        original: "Artrotomia - tratamento cirúrgico - joelho",
                        mod: "Artrotomia - tratamento cirúrgico - joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726050",
                        original: "Biópsia cirúrgica de joelho",
                        mod: "Biópsia cirúrgica de joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726069",
                        original: "Desarticulação de joelho - tratamento cirúrgico",
                        mod: "Desarticulação de joelho - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726077",
                        original: "Epifisites e tendinites - tratamento cirúrgico",
                        mod: "Epifisites e tendinites - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726085",
                        original: "Fratura de joelho - tratamento conservador",
                        mod: "Fratura de joelho - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726093",
                        original: "Fratura e/ou luxação de patela (inclusive osteocondral) - redução incruenta",
                        mod: "Fratura e/ou luxação de patela (inclusive osteocondral) - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726107",
                        original: "Fratura e/ou luxação de patela - tratamento cirúrgico",
                        mod: "Fratura e/ou luxação de patela - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726115",
                        original: "Fraturas e/ou luxações ao nível do joelho - redução incruenta",
                        mod: "Fraturas e/ou luxações ao nível do joelho - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726123",
                        original: "Fraturas e/ou luxações ao nível do joelho - tratamento cirúrgico",
                        mod: "Fraturas e/ou luxações ao nível do joelho - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726131",
                        original: "Lesão aguda de ligamento colateral, associada a ligamento cruzado e menisco - tratamento cirúrgico",
                        mod: "Lesão aguda de ligamento colateral, associada a ligamento cruzado e menisco - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726140",
                        original: "Lesões agudas e/ou luxações de meniscos (1 ou ambos) - tratamento cirúrgico",
                        mod: "Lesões agudas e/ou luxações de meniscos (1 ou ambos) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726158",
                        original: "Lesões complexas de joelho (fratura com lesão ligamentar e meniscal) - tratamento cirúrgico",
                        mod: "Lesões complexas de joelho (fratura com lesão ligamentar e meniscal) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726166",
                        original: "Lesões intrínsecas  de  joelho  (lesões  condrais,  osteocondrite dissecante, plica patológica, corpos livres, artrofitose) - tratamento cirúrgico",
                        mod: "Lesões intrínsecas  de  joelho  (lesões  condrais,  osteocondrite dissecante, plica patológica, corpos livres, artrofitose) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726174",
                        original: "Lesões ligamentares agudas - tratamento incruento",
                        mod: "Lesões ligamentares agudas - tratamento incruento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726182",
                        original: "Lesões ligamentares agudas - tratamento cirúrgico",
                        mod: "Lesões ligamentares agudas - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726190",
                        original: "Lesões ligamentares periféricas crônicas - tratamento cirúrgico",
                        mod: "Lesões ligamentares periféricas crônicas - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726204",
                        original: "Liberação lateral e facectomias - tratamento cirúrgico",
                        mod: "Liberação lateral e facectomias - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726212",
                        original: "Meniscorrafia - tratamento cirúrgico",
                        mod: "Meniscorrafia - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726220",
                        original: "Osteotomias ao nível do joelho - tratamento cirúrgico",
                        mod: "Osteotomias ao nível do joelho - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726239",
                        original: "Realinhamentos do aparelho extensor - tratamento cirúrgico",
                        mod: "Realinhamentos do aparelho extensor - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726247",
                        original: "Reconstruções ligamentares do pivot central - tratamento cirúrgico",
                        mod: "Reconstruções ligamentares do pivot central - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726255",
                        original: "Revisões de artroplastia total - tratamento cirúrgico",
                        mod: "Revisões de artroplastia total - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726263",
                        original: "Revisões de realinhamentos do aparelho extensor - tratamento cirúrgico",
                        mod: "Revisões de realinhamentos do aparelho extensor - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726271",
                        original: "Revisões de reconstruções intra-articulares - tratamento cirúrgico",
                        mod: "Revisões de reconstruções intra-articulares - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726280",
                        original: "Toalete cirúrgica - correção de joelho flexo - tratamento cirúrgico",
                        mod: "Toalete cirúrgica - correção de joelho flexo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726298",
                        original: "Transplantes homólogos ao nível do joelho - tratamento cirúrgico",
                        mod: "Transplantes homólogos ao nível do joelho - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726301",
                        original: "Tratamento cirúrgico de luxações / artrodese / contraturas com fixador externo",
                        mod: "Tratamento cirúrgico de luxações / artrodese / contraturas com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30726310",
                        original: "Bolsa pré-patelar - ressecção",
                        mod: "Bolsa pré-patelar - ressecção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727014",
                        original: "Alongamento / transporte ósseo / pseudoartrose com fixador externo - perna",
                        mod: "Alongamento / transporte ósseo / pseudoartrose com fixador externo - perna",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727022",
                        original: "Alongamento com fixador dinâmico - tratamento cirúrgico",
                        mod: "Alongamento com fixador dinâmico - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727030",
                        original: "Alongamento dos ossos da perna - tratamento cirúrgico",
                        mod: "Alongamento dos ossos da perna - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727049",
                        original: "Amputação de perna - tratamento cirúrgico",
                        mod: "Amputação de perna - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727057",
                        original: "Biópsia cirúrgica de tíbia ou fíbula",
                        mod: "Biópsia cirúrgica de tíbia ou fíbula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727065",
                        original: "Correção de deformidade adquirida de tíbia com fixador externo",
                        mod: "Correção de deformidade adquirida de tíbia com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727073",
                        original: "Correção de deformidades congênitas na perna com fixador externo",
                        mod: "Correção de deformidades congênitas na perna com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727081",
                        original: "Encurtamento dos ossos da perna - tratamento cirúrgico",
                        mod: "Encurtamento dos ossos da perna - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727090",
                        original: "Epifisiodese de tíbia/fíbula - tratamento cirúrgico",
                        mod: "Epifisiodese de tíbia/fíbula - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727103",
                        original: "Fratura de osso da perna - tratamento conservador",
                        mod: "Fratura de osso da perna - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727111",
                        original: "Fraturas de fíbula (inclui o descolamento epifisário) - tratamento cirúrgico",
                        mod: "Fraturas de fíbula (inclui o descolamento epifisário) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727120",
                        original: "Fraturas de fíbula (inclui descolamento epifisário) - redução incruenta",
                        mod: "Fraturas de fíbula (inclui descolamento epifisário) - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727138",
                        original: "Fraturas de tíbia associada ou não a fíbula (inclui descolamento epifisário) - tratamento cirúrgico",
                        mod: "Fraturas de tíbia associada ou não a fíbula (inclui descolamento epifisário) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727146",
                        original: "Fraturas de tíbia e fíbula (inclui descolamento epifisário) - redução incruenta",
                        mod: "Fraturas de tíbia e fíbula (inclui descolamento epifisário) - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727154",
                        original: "Osteomielite dos ossos da perna - tratamento cirúrgico",
                        mod: "Osteomielite dos ossos da perna - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727162",
                        original: "Osteotomias e/ou pseudartroses - tratamento cirúrgico",
                        mod: "Osteotomias e/ou pseudartroses - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727170",
                        original: "Transposição de fíbula/tíbia - tratamento cirúrgico",
                        mod: "Transposição de fíbula/tíbia - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727189",
                        original: "Tratamento cirúrgico de fraturas de tíbia com fixador externo",
                        mod: "Tratamento cirúrgico de fraturas de tíbia com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30727197",
                        original: "Fratura de dois ossos da perna - tratamento conservador",
                        mod: "Fratura de dois ossos da perna - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728010",
                        original: "Amputação ao nível do tornozelo - tratamento cirúrgico",
                        mod: "Amputação ao nível do tornozelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728029",
                        original: "Artrite ou osteoartrite - tratamento cirúrgico",
                        mod: "Artrite ou osteoartrite - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728037",
                        original: "Artrodese (com ou sem alongamento simultâneo) com fixador externo",
                        mod: "Artrodese (com ou sem alongamento simultâneo) com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728045",
                        original: "Artrodese ao nível do tornozelo - tratamento cirúrgico",
                        mod: "Artrodese ao nível do tornozelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728053",
                        original: "Artroplastia de tornozelo (com implante) - tratamento cirúrgico",
                        mod: "Artroplastia de tornozelo (com implante) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728061",
                        original: "Artrorrise do tornozelo - tratamento cirúrgico",
                        mod: "Artrorrise do tornozelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728070",
                        original: "Artrotomia de tornozelo - tratamento cirúrgico",
                        mod: "Artrotomia de tornozelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728088",
                        original: "Biópsia cirúrgica do tornozelo",
                        mod: "Biópsia cirúrgica do tornozelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728096",
                        original: "Fratura de tornozelo - tratamento conservador",
                        mod: "Fratura de tornozelo - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728100",
                        original: "Fraturas / pseudartroses / artroses ao nível do tornozelo com fixador externo dinâmico - tratamento cirúrgico",
                        mod: "Fraturas / pseudartroses / artroses ao nível do tornozelo com fixador externo dinâmico - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728118",
                        original: "Fraturas e/ou luxações ao nível do tornozelo - redução incruenta",
                        mod: "Fraturas e/ou luxações ao nível do tornozelo - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728126",
                        original: "Fraturas e/ou luxações ao nível do tornozelo - tratamento cirúrgico",
                        mod: "Fraturas e/ou luxações ao nível do tornozelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728134",
                        original: "Lesões ligamentares agudas ao nível do tornozelo - tratamento incruento",
                        mod: "Lesões ligamentares agudas ao nível do tornozelo - tratamento incruento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728142",
                        original: "Lesões ligamentares agudas ao nível do tornozelo - tratamento cirúrgico",
                        mod: "Lesões ligamentares agudas ao nível do tornozelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728150",
                        original: "Lesões ligamentares crônicas ao nível do tornozelo - tratamento cirúrgico",
                        mod: "Lesões ligamentares crônicas ao nível do tornozelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728169",
                        original: "Osteocondrite de tornozelo - tratamento cirúrgico",
                        mod: "Osteocondrite de tornozelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30728177",
                        original: "Pseudartroses ou osteotomias ao nível do tornozelo - tratamento cirúrgico",
                        mod: "Pseudartroses ou osteotomias ao nível do tornozelo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729017",
                        original: "Amputação ao nível do pé - tratamento cirúrgico",
                        mod: "Amputação ao nível do pé - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729025",
                        original: "Amputação/desarticulação de pododáctilos (por segmento) - tratamento cirúrgico",
                        mod: "Amputação/desarticulação de pododáctilos (por segmento) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729033",
                        original: "Artrite ou osteoartrite dos ossos do pé (inclui osteomielite) - tratamento cirúrgico",
                        mod: "Artrite ou osteoartrite dos ossos do pé (inclui osteomielite) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729041",
                        original: "Artrodese de tarso e/ou médio pé - tratamento cirúrgico",
                        mod: "Artrodese de tarso e/ou médio pé - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729050",
                        original: "Artrodese metatarso - falângica ou interfalângica - tratamento cirúrgico",
                        mod: "Artrodese metatarso - falângica ou interfalângica - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729068",
                        original: "Biópsia cirúrgica dos ossos do pé",
                        mod: "Biópsia cirúrgica dos ossos do pé",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729084",
                        original: "Correção de deformidades do pé com fixador externo dinâmico - tratamento cirúrgico",
                        mod: "Correção de deformidades do pé com fixador externo dinâmico - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729092",
                        original: "Correção de pé torto congênito com fixador externo",
                        mod: "Correção de pé torto congênito com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729106",
                        original: "Deformidade dos dedos - tratamento cirúrgico",
                        mod: "Deformidade dos dedos - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729114",
                        original: "Exérese ungueal",
                        mod: "Exérese ungueal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729122",
                        original: "Fasciotomia ou ressecção de fascia plantar - tratamento cirúrgico",
                        mod: "Fasciotomia ou ressecção de fascia plantar - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729130",
                        original: "Fratura de osso do pé - tratamento conservador",
                        mod: "Fratura de osso do pé - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729149",
                        original: "Fratura e/ou luxações do pé (exceto antepé) - redução incruenta",
                        mod: "Fratura e/ou luxações do pé (exceto antepé) - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729157",
                        original: "Fratura e/ou luxações do pé (exceto antepé) - tratamento cirúrgico",
                        mod: "Fratura e/ou luxações do pé (exceto antepé) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729165",
                        original: "Fraturas e/ou luxações do antepé - redução incruenta",
                        mod: "Fraturas e/ou luxações do antepé - redução incruenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729173",
                        original: "Fraturas e/ou luxações do antepé - tratamento cirúrgico",
                        mod: "Fraturas e/ou luxações do antepé - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729181",
                        original: "Hallux valgus (um pé) - tratamento cirúrgico",
                        mod: "Hallux valgus (um pé) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729190",
                        original: "Osteotomia ou pseudartrose do tarso e médio pé - tratamento cirúrgico",
                        mod: "Osteotomia ou pseudartrose do tarso e médio pé - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729203",
                        original: "Osteotomia ou pseudartrose dos metatarsos/falanges - tratamento cirúrgico",
                        mod: "Osteotomia ou pseudartrose dos metatarsos/falanges - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729211",
                        original: "Osteotomias / fraturas com fixador externo",
                        mod: "Osteotomias / fraturas com fixador externo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729220",
                        original: "Pé plano/pé cavo/coalisão tarsal - tratamento cirúrgico",
                        mod: "Pé plano/pé cavo/coalisão tarsal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729238",
                        original: "Pé torto congênito (um pé) - tratamento cirúrgico",
                        mod: "Pé torto congênito (um pé) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729246",
                        original: "Ressecção de osso do pé - tratamento cirúrgico",
                        mod: "Ressecção de osso do pé - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729254",
                        original: "Retração cicatricial dos dedos",
                        mod: "Retração cicatricial dos dedos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729262",
                        original: "Rotura do tendão de Aquiles - tratamento incruento",
                        mod: "Rotura do tendão de Aquiles - tratamento incruento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729270",
                        original: "Rotura do tendão de Aquiles - tratamento cirúrgico",
                        mod: "Rotura do tendão de Aquiles - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729289",
                        original: "Tratamento cirúrgico da sindactilia complexa e /ou múltipla",
                        mod: "Tratamento cirúrgico da sindactilia complexa e /ou múltipla",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729297",
                        original: "Tratamento cirúrgico da sindactilia simples",
                        mod: "Tratamento cirúrgico da sindactilia simples",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729300",
                        original: "Tratamento cirúrgico de gigantismo",
                        mod: "Tratamento cirúrgico de gigantismo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729319",
                        original: "Tratamento cirúrgico de linfedema ao nível do pé",
                        mod: "Tratamento cirúrgico de linfedema ao nível do pé",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729327",
                        original: "Tratamento cirúrgico de polidactilia múltipla e/ou complexa",
                        mod: "Tratamento cirúrgico de polidactilia múltipla e/ou complexa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729335",
                        original: "Tratamento cirúrgico de polidactilia simples",
                        mod: "Tratamento cirúrgico de polidactilia simples",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30729343",
                        original: "Tratamento cirúrgico do mal perfurante plantar",
                        mod: "Tratamento cirúrgico do mal perfurante plantar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730015",
                        original: "Alongamento",
                        mod: "Alongamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730023",
                        original: "Biópsia de músculo",
                        mod: "Biópsia de músculo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730031",
                        original: "Desbridamento cirúrgico de feridas ou extremidades",
                        mod: "Desbridamento cirúrgico de feridas ou extremidades",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730040",
                        original: "Desinserção ou miotomia",
                        mod: "Desinserção ou miotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730058",
                        original: "Dissecção muscular",
                        mod: "Dissecção muscular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730066",
                        original: "Drenagem cirúrgica do psoas",
                        mod: "Drenagem cirúrgica do psoas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730074",
                        original: "Fasciotomia",
                        mod: "Fasciotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730082",
                        original: "Fasciotomia - por compartimento",
                        mod: "Fasciotomia - por compartimento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730090",
                        original: "Fasciotomias (descompressivas)",
                        mod: "Fasciotomias (descompressivas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730104",
                        original: "Fasciotomias acima do punho",
                        mod: "Fasciotomias acima do punho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730112",
                        original: "Miorrafias",
                        mod: "Miorrafias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730120",
                        original: "Terapia por ondas de choque extracorpórea em partes moles - acompanhamento 1ª aplicação",
                        mod: "Terapia por ondas de choque extracorpórea em partes moles - acompanhamento 1ª aplicação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730139",
                        original: "Terapia por ondas de choque extracorpórea em partes moles - acompanhamento reaplicações",
                        mod: "Terapia por ondas de choque extracorpórea em partes moles - acompanhamento reaplicações",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730155",
                        original: "Transposição muscular",
                        mod: "Transposição muscular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730163",
                        original: "Lesão ligamentar aguda - tratamento conservador",
                        mod: "Lesão ligamentar aguda - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30730171",
                        original: "Lesões músculo tendinosas - tratamento incruento",
                        mod: "Lesões músculo tendinosas - tratamento incruento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731011",
                        original: "Abertura de bainha tendinosa - tratamento cirúrgico",
                        mod: "Abertura de bainha tendinosa - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731020",
                        original: "Biópsias cirúrgicas de tendões, bursas e sinóvias",
                        mod: "Biópsias cirúrgicas de tendões, bursas e sinóvias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731038",
                        original: "Bursectomia - tratamento cirúrgico",
                        mod: "Bursectomia - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731046",
                        original: "Cisto sinovial - tratamento cirúrgico",
                        mod: "Cisto sinovial - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731054",
                        original: "Encurtamento de tendão - tratamento cirúrgico",
                        mod: "Encurtamento de tendão - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731062",
                        original: "Sinovectomia - tratamento cirúrgico",
                        mod: "Sinovectomia - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731070",
                        original: "Tenoartroplastia para ossos do carpo",
                        mod: "Tenoartroplastia para ossos do carpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731089",
                        original: "Tenodese",
                        mod: "Tenodese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731097",
                        original: "Tenólise no túnel osteofibroso",
                        mod: "Tenólise no túnel osteofibroso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731100",
                        original: "Tenólise/tendonese - tratamento cirúrgico",
                        mod: "Tenólise/tendonese - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731119",
                        original: "Tenoplastia / enxerto de tendão - tratamento cirúrgico",
                        mod: "Tenoplastia / enxerto de tendão - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731127",
                        original: "Tenoplastia de tendão em outras regiões",
                        mod: "Tenoplastia de tendão em outras regiões",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731135",
                        original: "Tenorrafia múltipla em outras regiões",
                        mod: "Tenorrafia múltipla em outras regiões",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731143",
                        original: "Tenorrafia no túnel osteofibroso - mais de 2 dígitos",
                        mod: "Tenorrafia no túnel osteofibroso - mais de 2 dígitos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731151",
                        original: "Tenorrafia no túnel osteofibroso até 2 dígitos",
                        mod: "Tenorrafia no túnel osteofibroso até 2 dígitos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731160",
                        original: "Tenorrafia única em outras regiões",
                        mod: "Tenorrafia única em outras regiões",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731178",
                        original: "Tenossinovectomia de mão ou punho",
                        mod: "Tenossinovectomia de mão ou punho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731186",
                        original: "Tenossinovites estenosantes - tratamento cirúrgico",
                        mod: "Tenossinovites estenosantes - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731194",
                        original: "Tenossinovites infecciosas - drenagem",
                        mod: "Tenossinovites infecciosas - drenagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731208",
                        original: "Tenotomia",
                        mod: "Tenotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731216",
                        original: "Transposição de mais de 1 tendão - tratamento cirúrgico",
                        mod: "Transposição de mais de 1 tendão - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731224",
                        original: "Transposição única de tendão",
                        mod: "Transposição única de tendão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731232",
                        original: "Tumores de tendão ou sinovial - tratamento cirúrgico",
                        mod: "Tumores de tendão ou sinovial - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731240",
                        original: "Alongamento de tendões - tratamento cruento",
                        mod: "Alongamento de tendões - tratamento cruento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30731259",
                        original: "Rotura de tendão de aquiles - tratamento conservador",
                        mod: "Rotura de tendão de aquiles - tratamento conservador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30732018",
                        original: "Curetagem ou ressecção em bloco de tumor com reconstrução e enxerto vascularizado",
                        mod: "Curetagem ou ressecção em bloco de tumor com reconstrução e enxerto vascularizado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30732026",
                        original: "Enxerto ósseo",
                        mod: "Enxerto ósseo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30732034",
                        original: "Ressecção da lesão com cimentação e osteosíntese",
                        mod: "Ressecção da lesão com cimentação e osteosíntese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30732042",
                        original: "Revisão de endoprótese",
                        mod: "Revisão de endoprótese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30732050",
                        original: "Terapia por ondas de choque extracorpórea em partes ósseas - acompanhamento 1ª aplicação",
                        mod: "Terapia por ondas de choque extracorpórea em partes ósseas - acompanhamento 1ª aplicação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30732069",
                        original: "Terapia por ondas de choque extracorpórea em partes ósseas - acompanhamento reaplicações",
                        mod: "Terapia por ondas de choque extracorpórea em partes ósseas - acompanhamento reaplicações",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30732085",
                        original: "Tumor ósseo (ressecção com substituição)",
                        mod: "Tumor ósseo (ressecção com substituição)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30732093",
                        original: "Tumor ósseo (ressecção e artrodese)",
                        mod: "Tumor ósseo (ressecção e artrodese)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30732107",
                        original: "Tumor ósseo (ressecção e cimento)",
                        mod: "Tumor ósseo (ressecção e cimento)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30732115",
                        original: "Tumor ósseo (ressecção e enxerto)",
                        mod: "Tumor ósseo (ressecção e enxerto)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30732123",
                        original: "Tumor ósseo (ressecção segmentar)",
                        mod: "Tumor ósseo (ressecção segmentar)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30732131",
                        original: "Tumor ósseo (ressecção simples)",
                        mod: "Tumor ósseo (ressecção simples)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30733014",
                        original: "Sinovectomia total  - procedimento videoartroscópico de joelho",
                        mod: "Sinovectomia total  - procedimento videoartroscópico de joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30733022",
                        original: "Sinovectomia parcial ou subtotal - procedimento videoartroscópico de joelho",
                        mod: "Sinovectomia parcial ou subtotal - procedimento videoartroscópico de joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30733030",
                        original: "Condroplastia (com remoção de corpos livres) - procedimento videoartroscópico de joelho",
                        mod: "Condroplastia (com remoção de corpos livres) - procedimento videoartroscópico de joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30733049",
                        original: "Osteocondroplastia - estabilização, ressecção e/ou plastia #  - procedimento videoartroscópico de joelho",
                        mod: "Osteocondroplastia - estabilização, ressecção e/ou plastia #  - procedimento videoartroscópico de joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30733057",
                        original: "Meniscectomia - um menisco - procedimento videoartroscópico de joelho",
                        mod: "Meniscectomia - um menisco - procedimento videoartroscópico de joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30733065",
                        original: "Reparo ou sutura de um menisco - procedimento videoartroscópico de joelho",
                        mod: "Reparo ou sutura de um menisco - procedimento videoartroscópico de joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30733073",
                        original: "Reconstrução, retencionamento ou reforço do ligamento cruzado anterior ou posterior #  - procedimento videoartroscópico de joelho",
                        mod: "Reconstrução, retencionamento ou reforço do ligamento cruzado anterior ou posterior #  - procedimento videoartroscópico de joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30733081",
                        original: "Fratura com redução e/ou estabilização da superfície articular - um compartimento #  - procedimento videoartroscópico de joelho",
                        mod: "Fratura com redução e/ou estabilização da superfície articular - um compartimento #  - procedimento videoartroscópico de joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30733090",
                        original: "Tratamento cirúrgico da artrofibrose #  - procedimento videoartroscópico de joelho",
                        mod: "Tratamento cirúrgico da artrofibrose #  - procedimento videoartroscópico de joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30733103",
                        original: "Instabilidade femoro-patelar, release lateral da patela, retencionamento, reforço ou reconstrução do ligamento patelo-femoral medial #  - procedimento videoartroscópico de joelho",
                        mod: "Instabilidade femoro-patelar, release lateral da patela, retencionamento, reforço ou reconstrução do ligamento patelo-femoral medial #  - procedimento videoartroscópico de joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30734010",
                        original: "Sinovectomia total - procedimento videoartroscópico de tornozelo",
                        mod: "Sinovectomia total - procedimento videoartroscópico de tornozelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30734029",
                        original: "Sinovectomia parcial ou subtotal - procedimento videoartroscópico de tornozelo",
                        mod: "Sinovectomia parcial ou subtotal - procedimento videoartroscópico de tornozelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30734037",
                        original: "Condroplastia (com remoção de corpos livres) - procedimento videoartroscópico de tornozelo",
                        mod: "Condroplastia (com remoção de corpos livres) - procedimento videoartroscópico de tornozelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30734045",
                        original: "Osteocondroplastia - estabilização, ressecção e ou plastia (enxertia) # - procedimento videoartroscópico de tornozelo",
                        mod: "Osteocondroplastia - estabilização, ressecção e ou plastia (enxertia) # - procedimento videoartroscópico de tornozelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30734053",
                        original: "Reconstrução, retencionamento ou reforço de ligamento - procedimento videoartroscópico de tornozelo",
                        mod: "Reconstrução, retencionamento ou reforço de ligamento - procedimento videoartroscópico de tornozelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30734061",
                        original: "Fraturas - redução e estabilização de cada superfície - procedimento videoartroscópico de tornozelo",
                        mod: "Fraturas - redução e estabilização de cada superfície - procedimento videoartroscópico de tornozelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30735017",
                        original: "Sinovectomia total - procedimento videoartroscópico de ombro",
                        mod: "Sinovectomia total - procedimento videoartroscópico de ombro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30735025",
                        original: "Sinovectomia parcial ou subtotal  - procedimento videoartroscópico de ombro",
                        mod: "Sinovectomia parcial ou subtotal  - procedimento videoartroscópico de ombro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30735033",
                        original: "Acromioplastia - procedimento videoartroscópico de ombro",
                        mod: "Acromioplastia - procedimento videoartroscópico de ombro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30735041",
                        original: "Lesão labral - procedimento videoartroscópico de ombro",
                        mod: "Lesão labral - procedimento videoartroscópico de ombro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30735050",
                        original: "Luxação gleno-umeral - procedimento videoartroscópico de ombro",
                        mod: "Luxação gleno-umeral - procedimento videoartroscópico de ombro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30735068",
                        original: "Ruptura do manguito rotador - procedimento videoartroscópico de ombro",
                        mod: "Ruptura do manguito rotador - procedimento videoartroscópico de ombro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30735076",
                        original: "Instabilidade multidirecional - procedimento videoartroscópico de ombro",
                        mod: "Instabilidade multidirecional - procedimento videoartroscópico de ombro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30735084",
                        original: "Ressecção lateral da clavícula - procedimento videoartroscópico de ombro",
                        mod: "Ressecção lateral da clavícula - procedimento videoartroscópico de ombro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30735092",
                        original: "Tenotomia da porção longa do bíceps - procedimento videoartroscópico de ombro",
                        mod: "Tenotomia da porção longa do bíceps - procedimento videoartroscópico de ombro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30736013",
                        original: "Sinovectomia  total - procedimento videoartroscópico de cotovelo",
                        mod: "Sinovectomia  total - procedimento videoartroscópico de cotovelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30736021",
                        original: "Sinovectomia parcial ou subtotal - procedimento videoartroscópico de cotovelo",
                        mod: "Sinovectomia parcial ou subtotal - procedimento videoartroscópico de cotovelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30736030",
                        original: "Condroplastia (com remoção de corpos livres) - procedimento videoartroscópico de cotovelo",
                        mod: "Condroplastia (com remoção de corpos livres) - procedimento videoartroscópico de cotovelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30736048",
                        original: "Osteocondroplastia - estabilização, ressecção e/ou plastia (enxertia) # - procedimento videoartroscópico de cotovelo",
                        mod: "Osteocondroplastia - estabilização, ressecção e/ou plastia (enxertia) # - procedimento videoartroscópico de cotovelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30736056",
                        original: "Reconstrução, retencionamento ou reforço de ligamento #  - procedimento videoartroscópico de cotovelo",
                        mod: "Reconstrução, retencionamento ou reforço de ligamento #  - procedimento videoartroscópico de cotovelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30736064",
                        original: "Fraturas: redução e estabilização para cada superfície - procedimento videoartroscópico de cotovelo",
                        mod: "Fraturas: redução e estabilização para cada superfície - procedimento videoartroscópico de cotovelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30737010",
                        original: "Sinovectomia total - procedimento videoartroscópico de punho e túnel do carpo",
                        mod: "Sinovectomia total - procedimento videoartroscópico de punho e túnel do carpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30737028",
                        original: "Sinovectomia parcial ou subtotal  - procedimento videoartroscópico de punho e túnel do carpo",
                        mod: "Sinovectomia parcial ou subtotal  - procedimento videoartroscópico de punho e túnel do carpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30737036",
                        original: "Condroplastia (com remoção de corpos livres) - procedimento videoartroscópico de punho e túnel do carpo",
                        mod: "Condroplastia (com remoção de corpos livres) - procedimento videoartroscópico de punho e túnel do carpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30737044",
                        original: "Osteocondroplastia - estabilização, ressecção e/ou plastia (enxertia) - procedimento videoartroscópico de punho e túnel do carpo",
                        mod: "Osteocondroplastia - estabilização, ressecção e/ou plastia (enxertia) - procedimento videoartroscópico de punho e túnel do carpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30737052",
                        original: "Reconstrução, retencionamento ou reforço de ligamento ou reparo de cartilagem triangular # - procedimento videoartroscópico de punho e túnel do carpo",
                        mod: "Reconstrução, retencionamento ou reforço de ligamento ou reparo de cartilagem triangular # - procedimento videoartroscópico de punho e túnel do carpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30737060",
                        original: "Fraturas - redução e estabilização de cada superfície - procedimento videoartroscópico de punho e túnel do carpo",
                        mod: "Fraturas - redução e estabilização de cada superfície - procedimento videoartroscópico de punho e túnel do carpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30737079",
                        original: "Túnel do carpo - descompressão - procedimento videoartroscópico de punho e túnel do carpo",
                        mod: "Túnel do carpo - descompressão - procedimento videoartroscópico de punho e túnel do carpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30738016",
                        original: "Sinovectomia total - procedimento videoartroscópico de coxofemoral",
                        mod: "Sinovectomia total - procedimento videoartroscópico de coxofemoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30738024",
                        original: "Sinovectomia parcial e/ou remoção de corpos livres - procedimento videoartroscópico de coxofemoral",
                        mod: "Sinovectomia parcial e/ou remoção de corpos livres - procedimento videoartroscópico de coxofemoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30738032",
                        original: "Desbridamento do labrum ou ligamento redondo com ou sem condroplastia - procedimento videoartroscópico de coxofemoral",
                        mod: "Desbridamento do labrum ou ligamento redondo com ou sem condroplastia - procedimento videoartroscópico de coxofemoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30738040",
                        original: "Tratamento do impacto femoro-acetabular - procedimento videoartroscópico de coxofemoral",
                        mod: "Tratamento do impacto femoro-acetabular - procedimento videoartroscópico de coxofemoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30738059",
                        original: "Condroplastia com sutura labral - procedimento videoartroscópico de coxofemoral",
                        mod: "Condroplastia com sutura labral - procedimento videoartroscópico de coxofemoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801010",
                        original: "Colocação de órtese traqueal, traqueobrônquica ou brônquica, por via endoscópica (tubo de silicone ou metálico)",
                        mod: "Colocação de órtese traqueal, traqueobrônquica ou brônquica, por via endoscópica (tubo de silicone ou metálico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801028",
                        original: "Colocação de prótese traqueal ou traqueobrônquica (qualquer via)",
                        mod: "Colocação de prótese traqueal ou traqueobrônquica (qualquer via)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801036",
                        original: "Fechamento de fístula tráqueo-cutânea",
                        mod: "Fechamento de fístula tráqueo-cutânea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801044",
                        original: "Punção traqueal",
                        mod: "Punção traqueal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801052",
                        original: "Ressecção carinal (traqueobrônquica)",
                        mod: "Ressecção carinal (traqueobrônquica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801060",
                        original: "Ressecção de tumor traqueal",
                        mod: "Ressecção de tumor traqueal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801079",
                        original: "Traqueoplastia (qualquer via)",
                        mod: "Traqueoplastia (qualquer via)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801087",
                        original: "Traqueorrafia (qualquer via)",
                        mod: "Traqueorrafia (qualquer via)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801095",
                        original: "Traqueostomia",
                        mod: "Traqueostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801109",
                        original: "Traqueostomia com colocação de órtese traqueal ou traqueobrônquica por via cervical",
                        mod: "Traqueostomia com colocação de órtese traqueal ou traqueobrônquica por via cervical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801117",
                        original: "Traqueostomia mediastinal",
                        mod: "Traqueostomia mediastinal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801133",
                        original: "Plastia de traqueostoma",
                        mod: "Plastia de traqueostoma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801141",
                        original: "Traqueotomia ou fechamento cirúrgico",
                        mod: "Traqueotomia ou fechamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801150",
                        original: "Troca de prótese tráqueo-esofágica",
                        mod: "Troca de prótese tráqueo-esofágica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801168",
                        original: "Ressecção de tumor traqueal por videotoracoscopia",
                        mod: "Ressecção de tumor traqueal por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801176",
                        original: "Traqueorrafia por videotoracoscopia",
                        mod: "Traqueorrafia por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30801184",
                        original: "Traqueostomia com retirada de corpo estranho",
                        mod: "Traqueostomia com retirada de corpo estranho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30802016",
                        original: "Broncoplastia e/ou arterioplastia",
                        mod: "Broncoplastia e/ou arterioplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30802024",
                        original: "Broncotomia e/ou broncorrafia",
                        mod: "Broncotomia e/ou broncorrafia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30802032",
                        original: "Colocação de molde brônquico por toracotomia",
                        mod: "Colocação de molde brônquico por toracotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30802040",
                        original: "Broncoplastia e/ou arterioplastia por videotoracoscopia",
                        mod: "Broncoplastia e/ou arterioplastia por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30802059",
                        original: "Broncotomia e/ou broncorrafia por videotoracoscopia",
                        mod: "Broncotomia e/ou broncorrafia por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803012",
                        original: "Bulectomia unilateral",
                        mod: "Bulectomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803020",
                        original: "Cirurgia redutora do volume pulmonar unilateral (qualquer técnica)",
                        mod: "Cirurgia redutora do volume pulmonar unilateral (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803039",
                        original: "Cisto pulmonar congênito - tratamento cirúrgico",
                        mod: "Cisto pulmonar congênito - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803047",
                        original: "Correção de fístula bronco-pleural (qualquer técnica)",
                        mod: "Correção de fístula bronco-pleural (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803055",
                        original: "Drenagem tubular aberta de cavidade pulmonar",
                        mod: "Drenagem tubular aberta de cavidade pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803063",
                        original: "Embolectomia pulmonar",
                        mod: "Embolectomia pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803071",
                        original: "Lobectomia por malformação pulmonar",
                        mod: "Lobectomia por malformação pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803080",
                        original: "Lobectomia pulmonar",
                        mod: "Lobectomia pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803098",
                        original: "Metastasectomia pulmonar unilateral (qualquer técnica)",
                        mod: "Metastasectomia pulmonar unilateral (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803101",
                        original: "Pneumonectomia",
                        mod: "Pneumonectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803110",
                        original: "Pneumonectomia de totalização",
                        mod: "Pneumonectomia de totalização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803128",
                        original: "Pneumorrafia",
                        mod: "Pneumorrafia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803136",
                        original: "Pneumostomia (cavernostomia) com costectomia e estoma cutâneo-cavitário",
                        mod: "Pneumostomia (cavernostomia) com costectomia e estoma cutâneo-cavitário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803144",
                        original: "Posicionamento de agulhas radiativas por toracotomia (braquiterapia)",
                        mod: "Posicionamento de agulhas radiativas por toracotomia (braquiterapia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803152",
                        original: "Segmentectomia (qualquer técnica)",
                        mod: "Segmentectomia (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803160",
                        original: "Tromboendarterectomia pulmonar",
                        mod: "Tromboendarterectomia pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803179",
                        original: "Bulectomia unilateral por videotoracoscopia",
                        mod: "Bulectomia unilateral por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803187",
                        original: "Cirurgia redutora do volume pulmonar unilateral  por videotoracoscopia",
                        mod: "Cirurgia redutora do volume pulmonar unilateral  por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803195",
                        original: "Correção de fístula bronco-pleural por videotoracoscopia",
                        mod: "Correção de fístula bronco-pleural por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803209",
                        original: "Drenagem tubular aberta de cavidade pulmonar por videotoracoscopia",
                        mod: "Drenagem tubular aberta de cavidade pulmonar por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803217",
                        original: "Lobectomia pulmonar por videotoracoscopia",
                        mod: "Lobectomia pulmonar por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803225",
                        original: "Metastasectomia pulmonar unilateral por videotoracoscopia",
                        mod: "Metastasectomia pulmonar unilateral por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803233",
                        original: "Segmentectomia por videotoracoscopia",
                        mod: "Segmentectomia por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30803241",
                        original: "Biópsia transcutânea de pulmão por agulha",
                        mod: "Biópsia transcutânea de pulmão por agulha",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804019",
                        original: "Biópsia percutânea de pleura por agulha",
                        mod: "Biópsia percutânea de pleura por agulha",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804027",
                        original: "Descorticação pulmonar",
                        mod: "Descorticação pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804035",
                        original: "Pleurectomia",
                        mod: "Pleurectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804043",
                        original: "Pleurodese (qualquer técnica)",
                        mod: "Pleurodese (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804051",
                        original: "Pleuroscopia",
                        mod: "Pleuroscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804060",
                        original: "Pleurostomia (aberta)",
                        mod: "Pleurostomia (aberta)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804086",
                        original: "Punção pleural",
                        mod: "Punção pleural",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804094",
                        original: "Repleção de cavidade pleural com solução de antibiótico para tratamento de empiema",
                        mod: "Repleção de cavidade pleural com solução de antibiótico para tratamento de empiema",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804108",
                        original: "Ressecção de tumor da pleura localizado",
                        mod: "Ressecção de tumor da pleura localizado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804116",
                        original: "Retirada de dreno tubular torácico (colocado em outro serviço)",
                        mod: "Retirada de dreno tubular torácico (colocado em outro serviço)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804124",
                        original: "Tenda pleural",
                        mod: "Tenda pleural",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804132",
                        original: "Toracostomia com drenagem pleural fechada",
                        mod: "Toracostomia com drenagem pleural fechada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804140",
                        original: "Tratamento operatório da hemorragia intrapleural",
                        mod: "Tratamento operatório da hemorragia intrapleural",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804159",
                        original: "Descorticação pulmonar por videotoracoscopia",
                        mod: "Descorticação pulmonar por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804167",
                        original: "Pleurectomia por videotoracoscopia",
                        mod: "Pleurectomia por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804175",
                        original: "Pleurodese por video",
                        mod: "Pleurodese por video",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804183",
                        original: "Pleuroscopia por vídeo",
                        mod: "Pleuroscopia por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804191",
                        original: "Ressecção de tumor da pleura localizado por vídeo",
                        mod: "Ressecção de tumor da pleura localizado por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804205",
                        original: "Tenda pleural por vídeo",
                        mod: "Tenda pleural por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30804213",
                        original: "Tratamento operatório da hemorragia intrapleural por  vídeo",
                        mod: "Tratamento operatório da hemorragia intrapleural por  vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805015",
                        original: "Ressecção de bócio intratorácico",
                        mod: "Ressecção de bócio intratorácico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805023",
                        original: "Biópsia de linfonodos pré-escalênicos ou do confluente venoso",
                        mod: "Biópsia de linfonodos pré-escalênicos ou do confluente venoso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805031",
                        original: "Biópsia de tumor do mediastino (qualquer via)",
                        mod: "Biópsia de tumor do mediastino (qualquer via)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805040",
                        original: "Cisto ou duplicação brônquica ou esôfagica - tratamento cirúrgico",
                        mod: "Cisto ou duplicação brônquica ou esôfagica - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805074",
                        original: "Ligadura de artérias brônquicas por toracotomia para controle de hemoptise",
                        mod: "Ligadura de artérias brônquicas por toracotomia para controle de hemoptise",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805082",
                        original: "Ligadura de ducto-torácico (qualquer via)",
                        mod: "Ligadura de ducto-torácico (qualquer via)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805090",
                        original: "Linfadenectomia mediastinal",
                        mod: "Linfadenectomia mediastinal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805104",
                        original: "Mediastinoscopia, via cervical",
                        mod: "Mediastinoscopia, via cervical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805112",
                        original: "Mediastinotomia (via paraesternal, transesternal, cervical)",
                        mod: "Mediastinotomia (via paraesternal, transesternal, cervical)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805120",
                        original: "Mediastinotomia extrapleural por via posterior",
                        mod: "Mediastinotomia extrapleural por via posterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805139",
                        original: "Pericardiotomia com abertura pleuro-pericárdica (qualquer técnica)",
                        mod: "Pericardiotomia com abertura pleuro-pericárdica (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805147",
                        original: "Ressecção de tumor de mediastino",
                        mod: "Ressecção de tumor de mediastino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805155",
                        original: "Timectomia (qualquer via)",
                        mod: "Timectomia (qualquer via)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805163",
                        original: "Tratamento da mediastinite (qualquer via)",
                        mod: "Tratamento da mediastinite (qualquer via)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805171",
                        original: "Vagotomia troncular terapêutica por toracotomia",
                        mod: "Vagotomia troncular terapêutica por toracotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805180",
                        original: "Biópsia de tumor do mediastino por vídeo",
                        mod: "Biópsia de tumor do mediastino por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805198",
                        original: "Cisto ou duplicação brônquica ou esofágica – tratamento cirúrgico por vídeo",
                        mod: "Cisto ou duplicação brônquica ou esofágica – tratamento cirúrgico por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805201",
                        original: "Ligadura de artérias brônquicas para controle de hemoptise por vídeo",
                        mod: "Ligadura de artérias brônquicas para controle de hemoptise por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805210",
                        original: "Ligadura de ducto-torácico por vídeo",
                        mod: "Ligadura de ducto-torácico por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805228",
                        original: "Linfadenectomia mediastinal por vídeo",
                        mod: "Linfadenectomia mediastinal por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805236",
                        original: "Mediastinoscopia, via cervical por vídeo",
                        mod: "Mediastinoscopia, via cervical por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805244",
                        original: "Mediastinotomia extrapleural por via posterior por vídeo",
                        mod: "Mediastinotomia extrapleural por via posterior por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805252",
                        original: "Pericardiotomia com abertura pleuro-pericárdica por vídeo",
                        mod: "Pericardiotomia com abertura pleuro-pericárdica por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805260",
                        original: "Ressecção de tumor de mediastino por vídeo",
                        mod: "Ressecção de tumor de mediastino por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805279",
                        original: "Timectomia por vídeo",
                        mod: "Timectomia por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805287",
                        original: "Tratamento da mediastinite por vídeo",
                        mod: "Tratamento da mediastinite por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30805295",
                        original: "Retirada de corpo estranho do mediastino",
                        mod: "Retirada de corpo estranho do mediastino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30806011",
                        original: "Abscesso subfrênico - tratamento cirúrgico",
                        mod: "Abscesso subfrênico - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30806020",
                        original: "Eventração diafragmática - tratamento cirúrgico",
                        mod: "Eventração diafragmática - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30806038",
                        original: "Hérnia diafragmática - tratamento cirúrgico (qualquer técnica)",
                        mod: "Hérnia diafragmática - tratamento cirúrgico (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30806046",
                        original: "Implante de marca-passo diafragmático definitivo",
                        mod: "Implante de marca-passo diafragmático definitivo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30806054",
                        original: "Hérnia diafragmática – tratamento cirúrgico por vídeo",
                        mod: "Hérnia diafragmática – tratamento cirúrgico por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30901014",
                        original: "Ampliação (anel valvar, grandes vasos, átrio, ventrículo)",
                        mod: "Ampliação (anel valvar, grandes vasos, átrio, ventrículo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30901022",
                        original: "Canal arterial persistente - correção cirúrgica",
                        mod: "Canal arterial persistente - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30901030",
                        original: "Coarctação da aorta - correção cirúrgica",
                        mod: "Coarctação da aorta - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30901049",
                        original: "Confecção de bandagem da artéria pulmonar",
                        mod: "Confecção de bandagem da artéria pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30901057",
                        original: "Correção cirúrgica da comunicação interatrial",
                        mod: "Correção cirúrgica da comunicação interatrial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30901065",
                        original: "Correção cirúrgica da comunicação interventricular",
                        mod: "Correção cirúrgica da comunicação interventricular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30901073",
                        original: "Correção de cardiopatia congênita + cirurgia valvar",
                        mod: "Correção de cardiopatia congênita + cirurgia valvar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30901081",
                        original: "Correção de cardiopatia congênita + revascularização do miocárdio",
                        mod: "Correção de cardiopatia congênita + revascularização do miocárdio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30901090",
                        original: "Redirecionamento do fluxo sanguíneo (com anastomose direta, retalho, tubo) - em defeitos cardíacos congenitos",
                        mod: "Redirecionamento do fluxo sanguíneo (com anastomose direta, retalho, tubo) - em defeitos cardíacos congenitos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30901103",
                        original: "Ressecção (infundíbulo, septo, membranas, bandas) - em defeitos cardíacos congenitos",
                        mod: "Ressecção (infundíbulo, septo, membranas, bandas) - em defeitos cardíacos congenitos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30901111",
                        original: "Transposições (vasos, câmaras) - em defeitos cardíacos congenitos",
                        mod: "Transposições (vasos, câmaras) - em defeitos cardíacos congenitos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30902010",
                        original: "Ampliação do anel valvar",
                        mod: "Ampliação do anel valvar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30902029",
                        original: "Cirurgia multivalvar",
                        mod: "Cirurgia multivalvar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30902037",
                        original: "Comissurotomia valvar",
                        mod: "Comissurotomia valvar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30902045",
                        original: "Plastia valvar",
                        mod: "Plastia valvar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30902053",
                        original: "Troca valvar",
                        mod: "Troca valvar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30903017",
                        original: "Aneurismectomia de VE",
                        mod: "Aneurismectomia de VE",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30903025",
                        original: "Revascularização do miocárdio",
                        mod: "Revascularização do miocárdio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30903033",
                        original: "Revascularização do miocárdio + cirurgia valvar",
                        mod: "Revascularização do miocárdio + cirurgia valvar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30903041",
                        original: "Ventriculectomia parcial - em coronariopatias",
                        mod: "Ventriculectomia parcial - em coronariopatias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904013",
                        original: "Cárdio-estimulação transesofágica (CETE), terapêutica ou diagnóstica",
                        mod: "Cárdio-estimulação transesofágica (CETE), terapêutica ou diagnóstica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904021",
                        original: "Implante de desfibrilador interno, placas e eletrodos",
                        mod: "Implante de desfibrilador interno, placas e eletrodos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904064",
                        original: "Implante de estimulador cardíaco artificial multissítio",
                        mod: "Implante de estimulador cardíaco artificial multissítio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904080",
                        original: "Instalação de marca-passo epimiocárdio temporário",
                        mod: "Instalação de marca-passo epimiocárdio temporário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904099",
                        original: "Implante de marca-passo temporário à beira do leito",
                        mod: "Implante de marca-passo temporário à beira do leito",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904102",
                        original: "Recolocação de eletrodo / gerador com ou sem troca de unidades",
                        mod: "Recolocação de eletrodo / gerador com ou sem troca de unidades",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904110",
                        original: "Retirada do sistema (não aplicável na troca do gerador)",
                        mod: "Retirada do sistema (não aplicável na troca do gerador)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904129",
                        original: "Troca de gerador",
                        mod: "Troca de gerador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904137",
                        original: "Implante de marca-passo monocameral (gerador + eletrodo atrial ou ventricular)",
                        mod: "Implante de marca-passo monocameral (gerador + eletrodo atrial ou ventricular)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904145",
                        original: "Implante de marca-passo bicameral (gerador + eletrodo atrial e ventricular)",
                        mod: "Implante de marca-passo bicameral (gerador + eletrodo atrial e ventricular)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904153",
                        original: "Remoção de cabo-eletrodo de marcapasso e/ou cárdio-desfibrilador implantável com auxílio de dilatador mecânico, laser ou radiofrequência",
                        mod: "Remoção de cabo-eletrodo de marcapasso e/ou cárdio-desfibrilador implantável com auxílio de dilatador mecânico, laser ou radiofrequência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904161",
                        original: "Implante de cardiodesfibrilador multissítio - TRC-D (gerador e eletrodos)",
                        mod: "Implante de cardiodesfibrilador multissítio - TRC-D (gerador e eletrodos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904170",
                        original: "Implante de gerador de eventos (Looper implantável)",
                        mod: "Implante de gerador de eventos (Looper implantável)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30904170",
                        original: "Implante de monitor de eventos (Looper implantável)",
                        mod: "Implante de monitor de eventos (Looper implantável)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30905010",
                        original: "Colocação de balão intra-aórtico",
                        mod: "Colocação de balão intra-aórtico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30905028",
                        original: "Colocação de stent na aorta sem CEC",
                        mod: "Colocação de stent na aorta sem CEC",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30905036",
                        original: "Instalação do circuíto de circulação extracorpórea convencional",
                        mod: "Instalação do circuíto de circulação extracorpórea convencional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30905044",
                        original: "Instalação do circuíto de circulação extracorpórea em crianças de baixo peso (10 kg)",
                        mod: "Instalação do circuíto de circulação extracorpórea em crianças de baixo peso (10 kg)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30905052",
                        original: "Derivação cavo-atrial",
                        mod: "Derivação cavo-atrial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30905060",
                        original: "Perfusionista - em procedimentos cardíacos",
                        mod: "Perfusionista - em procedimentos cardíacos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906016",
                        original: "Aneurisma de aorta abdominal infra-renal",
                        mod: "Aneurisma de aorta abdominal infra-renal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906024",
                        original: "Aneurisma de aorta abdominal supra-renal",
                        mod: "Aneurisma de aorta abdominal supra-renal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906032",
                        original: "Aneurisma de aorta-torácica",
                        mod: "Aneurisma de aorta-torácica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906040",
                        original: "Aneurisma de artérias viscerais",
                        mod: "Aneurisma de artérias viscerais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906059",
                        original: "Aneurisma de axilar, femoral, poplítea",
                        mod: "Aneurisma de axilar, femoral, poplítea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906067",
                        original: "Aneurisma de carótida, subclávia, ilíaca",
                        mod: "Aneurisma de carótida, subclávia, ilíaca",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906075",
                        original: "Aneurismas - outros",
                        mod: "Aneurismas - outros",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906083",
                        original: "Aneurismas torácicos ou tóraco-abdominais",
                        mod: "Aneurismas torácicos ou tóraco-abdominais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906113",
                        original: "Angioplastia transluminal transoperatória - por artéria",
                        mod: "Angioplastia transluminal transoperatória - por artéria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906121",
                        original: "Artéria hipogástrica - unilateral - qualquer técnica",
                        mod: "Artéria hipogástrica - unilateral - qualquer técnica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906130",
                        original: "Artéria mesentérica inferior - qualquer técnica",
                        mod: "Artéria mesentérica inferior - qualquer técnica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906148",
                        original: "Artéria mesentérica superior - qualquer técnica",
                        mod: "Artéria mesentérica superior - qualquer técnica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906156",
                        original: "Artéria renal bilateral revascularização",
                        mod: "Artéria renal bilateral revascularização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906164",
                        original: "Cateterismo da artéria radial - para PAM",
                        mod: "Cateterismo da artéria radial - para PAM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906172",
                        original: "Correção das dissecções da aorta",
                        mod: "Correção das dissecções da aorta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906180",
                        original: "Endarterectomia aorto-ilíaca",
                        mod: "Endarterectomia aorto-ilíaca",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906199",
                        original: "Endarterectomia carotídea - cada segmento arterial tratado",
                        mod: "Endarterectomia carotídea - cada segmento arterial tratado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906202",
                        original: "Endarterectomia ilíaco-femoral",
                        mod: "Endarterectomia ilíaco-femoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906210",
                        original: "Ligadura de carótida ou ramos",
                        mod: "Ligadura de carótida ou ramos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906229",
                        original: "Ponte aorto-bifemoral",
                        mod: "Ponte aorto-bifemoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906237",
                        original: "Ponte aorto-biilíaca",
                        mod: "Ponte aorto-biilíaca",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906245",
                        original: "Ponte aorto-femoral - unilateral",
                        mod: "Ponte aorto-femoral - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906253",
                        original: "Ponte aorto-ilíaca - unilateral",
                        mod: "Ponte aorto-ilíaca - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906261",
                        original: "Ponte axilo-bifemoral",
                        mod: "Ponte axilo-bifemoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906270",
                        original: "Ponte axilo-femoral",
                        mod: "Ponte axilo-femoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906288",
                        original: "Ponte distal",
                        mod: "Ponte distal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906296",
                        original: "Ponte fêmoro poplítea proximal",
                        mod: "Ponte fêmoro poplítea proximal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906300",
                        original: "Ponte fêmoro-femoral cruzada",
                        mod: "Ponte fêmoro-femoral cruzada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906318",
                        original: "Ponte fêmoro-femoral ipsilateral",
                        mod: "Ponte fêmoro-femoral ipsilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906326",
                        original: "Ponte subclávio bifemoral",
                        mod: "Ponte subclávio bifemoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906334",
                        original: "Ponte subclávio femoral",
                        mod: "Ponte subclávio femoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906342",
                        original: "Pontes aorto-cervicais ou endarterectomias dos troncos supra-aórticos",
                        mod: "Pontes aorto-cervicais ou endarterectomias dos troncos supra-aórticos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906350",
                        original: "Pontes transcervicais - qualquer tipo",
                        mod: "Pontes transcervicais - qualquer tipo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906377",
                        original: "Preparo de veia autóloga para remendos vasculares",
                        mod: "Preparo de veia autóloga para remendos vasculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906385",
                        original: "Arterioplastia da femoral profunda (profundoplastia)",
                        mod: "Arterioplastia da femoral profunda (profundoplastia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906393",
                        original: "Reoperação de aorta abdominal",
                        mod: "Reoperação de aorta abdominal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906407",
                        original: "Retirada de enxerto infectado em posição não aórtica",
                        mod: "Retirada de enxerto infectado em posição não aórtica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906415",
                        original: "Revascularização aorto-femoral - unilateral",
                        mod: "Revascularização aorto-femoral - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906423",
                        original: "Revascularização arterial de membro superior",
                        mod: "Revascularização arterial de membro superior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906431",
                        original: "Tratamento cirúrgico da isquemia cerebral",
                        mod: "Tratamento cirúrgico da isquemia cerebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906440",
                        original: "Tratamento cirúrgico de síndrome vértebro basilar",
                        mod: "Tratamento cirúrgico de síndrome vértebro basilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906458",
                        original: "Tratamento cirúrgico de tumor carotídeo",
                        mod: "Tratamento cirúrgico de tumor carotídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30906466",
                        original: "Tronco celíaco - qualquer técnica",
                        mod: "Tronco celíaco - qualquer técnica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907012",
                        original: "Cirurgia de restauração venosa com pontes em cavidades",
                        mod: "Cirurgia de restauração venosa com pontes em cavidades",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907020",
                        original: "Cirurgia de restauração venosa com pontes nos membros",
                        mod: "Cirurgia de restauração venosa com pontes nos membros",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907039",
                        original: "Cura cirúrgica da impotência coeundi venosa",
                        mod: "Cura cirúrgica da impotência coeundi venosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907047",
                        original: "Cura cirúrgica de hipertensão portal - qualquer tipo",
                        mod: "Cura cirúrgica de hipertensão portal - qualquer tipo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907063",
                        original: "Escleroterapia de veias - por sessão",
                        mod: "Escleroterapia de veias - por sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907071",
                        original: "Fulguração de telangiectasias (por grupo)",
                        mod: "Fulguração de telangiectasias (por grupo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907080",
                        original: "Implante de filtro de veia cava",
                        mod: "Implante de filtro de veia cava",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907098",
                        original: "Interrupção cirúrgica veia cava inferior",
                        mod: "Interrupção cirúrgica veia cava inferior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907101",
                        original: "Tratamento cirúrgico de varizes com lipodermatoesclerose ou úlcera (um membro)",
                        mod: "Tratamento cirúrgico de varizes com lipodermatoesclerose ou úlcera (um membro)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907110",
                        original: "Trombectomia venosa",
                        mod: "Trombectomia venosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907128",
                        original: "Valvuloplastia ou interposição de segmento valvulado venoso",
                        mod: "Valvuloplastia ou interposição de segmento valvulado venoso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907136",
                        original: "Varizes - tratamento cirúrgico de dois membros",
                        mod: "Varizes - tratamento cirúrgico de dois membros",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907144",
                        original: "Varizes - tratamento cirúrgico de um membro",
                        mod: "Varizes - tratamento cirúrgico de um membro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30907152",
                        original: "Varizes - ressecção de colaterais com anestesia local em consultório / ambulatório",
                        mod: "Varizes - ressecção de colaterais com anestesia local em consultório / ambulatório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30908019",
                        original: "Fístula aorto-cava, reno-cava ou ílio-ilíaca",
                        mod: "Fístula aorto-cava, reno-cava ou ílio-ilíaca",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30908027",
                        original: "Fístula arteriovenosa - com enxerto",
                        mod: "Fístula arteriovenosa - com enxerto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30908035",
                        original: "Fístula arteriovenosa cervical ou cefálica extracraniana",
                        mod: "Fístula arteriovenosa cervical ou cefálica extracraniana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30908043",
                        original: "Fístula arteriovenosa congênita - reintervenção",
                        mod: "Fístula arteriovenosa congênita - reintervenção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30908051",
                        original: "Fístula arteriovenosa congênita - correção cirúrgica radical",
                        mod: "Fístula arteriovenosa congênita - correção cirúrgica radical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30908060",
                        original: "Fístula arteriovenosa congênita para redução de fluxo",
                        mod: "Fístula arteriovenosa congênita para redução de fluxo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30908078",
                        original: "Fístula arteriovenosa direta",
                        mod: "Fístula arteriovenosa direta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30908086",
                        original: "Fístula arteriovenosa dos grandes vasos intratorácicos",
                        mod: "Fístula arteriovenosa dos grandes vasos intratorácicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30908094",
                        original: "Fístula arteriovenosa dos membros",
                        mod: "Fístula arteriovenosa dos membros",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30908108",
                        original: "Tromboembolectomia de fístula arteriovenosa",
                        mod: "Tromboembolectomia de fístula arteriovenosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30909023",
                        original: "Hemodiálise contínua (12h)",
                        mod: "Hemodiálise contínua (12h)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30909031",
                        original: "Hemodiálise crônica (por sessão)",
                        mod: "Hemodiálise crônica (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30909139",
                        original: "Hemodepuração de casos agudos (sessão hemodiálise, hemofiltração, hemodiafiltração isolada, plasmaferese ou hemoperfusão) - até 4 horas ou fração",
                        mod: "Hemodepuração de casos agudos (sessão hemodiálise, hemofiltração, hemodiafiltração isolada, plasmaferese ou hemoperfusão) - até 4 horas ou fração",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30909147",
                        original: "Hemodepuração de casos agudos (sessão hemodiálise, hemofiltração, hemodiafiltração isolada, plasmaferese ou hemoperfusão) - até 12 horas",
                        mod: "Hemodepuração de casos agudos (sessão hemodiálise, hemofiltração, hemodiafiltração isolada, plasmaferese ou hemoperfusão) - até 12 horas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910013",
                        original: "Aneurisma roto ou trombosado de aorta abdominal abaixo da artéria renal",
                        mod: "Aneurisma roto ou trombosado de aorta abdominal abaixo da artéria renal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910021",
                        original: "Aneurismas rotos ou trombosados - outros",
                        mod: "Aneurismas rotos ou trombosados - outros",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910030",
                        original: "Aneurismas rotos ou trombosados de aorta abdominal acima da artéria renal",
                        mod: "Aneurismas rotos ou trombosados de aorta abdominal acima da artéria renal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910048",
                        original: "Aneurismas rotos ou trombosados de artérias viscerais",
                        mod: "Aneurismas rotos ou trombosados de artérias viscerais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910056",
                        original: "Aneurismas rotos ou trombosados de axilar, femoral, poplítea",
                        mod: "Aneurismas rotos ou trombosados de axilar, femoral, poplítea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910064",
                        original: "Aneurismas rotos ou trombosados de carótida, subclávia, ilíaca",
                        mod: "Aneurismas rotos ou trombosados de carótida, subclávia, ilíaca",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910072",
                        original: "Aneurismas rotos ou trombosados torácicos ou tóraco-abdominais",
                        mod: "Aneurismas rotos ou trombosados torácicos ou tóraco-abdominais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910080",
                        original: "Embolectomia ou tromboembolectomia arterial",
                        mod: "Embolectomia ou tromboembolectomia arterial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910099",
                        original: "Exploração vascular em traumas de outros segmentos",
                        mod: "Exploração vascular em traumas de outros segmentos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910102",
                        original: "Exploração vascular em traumas torácicos e abdominais",
                        mod: "Exploração vascular em traumas torácicos e abdominais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910110",
                        original: "Lesões vasculares cervicais e cérvico-torácicas",
                        mod: "Lesões vasculares cervicais e cérvico-torácicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910129",
                        original: "Lesões vasculares de membro inferior ou superior - unilateral",
                        mod: "Lesões vasculares de membro inferior ou superior - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910137",
                        original: "Lesões vasculares intra-abdominais",
                        mod: "Lesões vasculares intra-abdominais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30910145",
                        original: "Lesões vasculares traumáticas intratorácicas",
                        mod: "Lesões vasculares traumáticas intratorácicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911010",
                        original: "Avaliação da viabilidade miocárdica por cateter",
                        mod: "Avaliação da viabilidade miocárdica por cateter",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911028",
                        original: "Avaliação fisiológica da gravidade de obstruções (cateter ou guia)",
                        mod: "Avaliação fisiológica da gravidade de obstruções (cateter ou guia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911036",
                        original: "Biópsia endomiocárdica",
                        mod: "Biópsia endomiocárdica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911044",
                        original: "Cateterismo cardíaco D e/ou E com  ou  sem  cinecoronariografia / cineangiografia  com  avaliação  de reatividade vascular pulmonar ou teste de sobrecarga hemodinânica",
                        mod: "Cateterismo cardíaco D e/ou E com  ou  sem  cinecoronariografia / cineangiografia  com  avaliação  de reatividade vascular pulmonar ou teste de sobrecarga hemodinânica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911052",
                        original: "Cateterismo cardíaco D e/ou E com estudo cineangiográfico e de revascularização cirúrgica do miocárdio",
                        mod: "Cateterismo cardíaco D e/ou E com estudo cineangiográfico e de revascularização cirúrgica do miocárdio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911060",
                        original: "Cateterismo cardíaco direito com estudo angiográfico da artéria pulmonar",
                        mod: "Cateterismo cardíaco direito com estudo angiográfico da artéria pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911079",
                        original: "Cateterismo cardíaco E e/ou D com cineangiocoronariografia e ventriculografia",
                        mod: "Cateterismo cardíaco E e/ou D com cineangiocoronariografia e ventriculografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911087",
                        original: "Cateterismo cardíaco E e/ou D com cineangiocoronariografia, ventriculografia e estudo angiográfico  da aorta e/ou ramos tóraco-abdominais e/ou membros",
                        mod: "Cateterismo cardíaco E e/ou D com cineangiocoronariografia, ventriculografia e estudo angiográfico  da aorta e/ou ramos tóraco-abdominais e/ou membros",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911095",
                        original: "Cateterismo E e estudo cineangiográfico da aorta e/ou seus ramos",
                        mod: "Cateterismo E e estudo cineangiográfico da aorta e/ou seus ramos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911109",
                        original: "Cateterização cardíaca E por via transeptal",
                        mod: "Cateterização cardíaca E por via transeptal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911117",
                        original: "Estudo eletrofisiológico - mapeamento eletro-eletrônico tridimensional - do sistema de condução com ou sem ação farmacológica",
                        mod: "Estudo eletrofisiológico - mapeamento eletro-eletrônico tridimensional - do sistema de condução com ou sem ação farmacológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911125",
                        original: "Estudo hemodinâmico das cardiopatias congênitas  estruturalmente  complexas (menos: CIA, CIV, PCA, Co, AO, estenose aórtica e pulmonar isoladas)",
                        mod: "Estudo hemodinâmico das cardiopatias congênitas  estruturalmente  complexas (menos: CIA, CIV, PCA, Co, AO, estenose aórtica e pulmonar isoladas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911133",
                        original: "Estudo hemodinâmico de cardiopatias congênitas e/ou valvopatias  com  ou  sem  cinecoronariografia  ou oximetria",
                        mod: "Estudo hemodinâmico de cardiopatias congênitas e/ou valvopatias  com  ou  sem  cinecoronariografia  ou oximetria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911141",
                        original: "Estudo ultrassonográfico intravascular",
                        mod: "Estudo ultrassonográfico intravascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911150",
                        original: "Mapeamento de feixes anômalos e focos ectópicos por eletrofisiologia intracavitária, com provas",
                        mod: "Mapeamento de feixes anômalos e focos ectópicos por eletrofisiologia intracavitária, com provas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911168",
                        original: "Teste de avaliação do limiar de fibrilação ventricular",
                        mod: "Teste de avaliação do limiar de fibrilação ventricular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30911176",
                        original: "Mapeamento Eletrofisiológico Cardíaco Convencional",
                        mod: "Mapeamento Eletrofisiológico Cardíaco Convencional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912016",
                        original: "Ablação de circuito arritmogênico por cateter de radiofrequência",
                        mod: "Ablação de circuito arritmogênico por cateter de radiofrequência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912024",
                        original: "Angioplastia transluminal da aorta ou ramos ou da artéria pulmonar e ramos (por vaso)",
                        mod: "Angioplastia transluminal da aorta ou ramos ou da artéria pulmonar e ramos (por vaso)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912032",
                        original: "Angioplastia transluminal percutânea de múltiplos vasos, com implante de stent",
                        mod: "Angioplastia transluminal percutânea de múltiplos vasos, com implante de stent",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912040",
                        original: "Angioplastia transluminal percutânea por balão (1 vaso)",
                        mod: "Angioplastia transluminal percutânea por balão (1 vaso)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912059",
                        original: "Atriosseptostomia por balão",
                        mod: "Atriosseptostomia por balão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912067",
                        original: "Atriosseptostomia por lâmina",
                        mod: "Atriosseptostomia por lâmina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912075",
                        original: "Emboloterapia",
                        mod: "Emboloterapia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912083",
                        original: "Colocação de cateter intracavitário para monitorização hemodinâmica",
                        mod: "Colocação de cateter intracavitário para monitorização hemodinâmica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912091",
                        original: "Implante de prótese intravascular na aorta/pulmonar ou ramos com ou sem angioplastia",
                        mod: "Implante de prótese intravascular na aorta/pulmonar ou ramos com ou sem angioplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912105",
                        original: "Implante de stent coronário com ou sem angioplastia por balão concomitante (1 vaso)",
                        mod: "Implante de stent coronário com ou sem angioplastia por balão concomitante (1 vaso)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912113",
                        original: "Infusão seletiva intravascular de enzimas trombolíticas",
                        mod: "Infusão seletiva intravascular de enzimas trombolíticas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912121",
                        original: "Oclusão percutânea de 'shunts' intracardíacos",
                        mod: "Oclusão percutânea de 'shunts' intracardíacos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912130",
                        original: "Oclusão percutânea de fístula e/ou conexões sistêmico pulmonares",
                        mod: "Oclusão percutânea de fístula e/ou conexões sistêmico pulmonares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912148",
                        original: "Oclusão percutânea do canal arterial",
                        mod: "Oclusão percutânea do canal arterial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912156",
                        original: "Punção saco pericárdico com introdução de cateter multipolar no espaço pericárdico",
                        mod: "Punção saco pericárdico com introdução de cateter multipolar no espaço pericárdico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912164",
                        original: "Punção transeptal com introdução de cateter multipolar nas camaras esquerdas e/ou veias pulmonares",
                        mod: "Punção transeptal com introdução de cateter multipolar nas camaras esquerdas e/ou veias pulmonares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912172",
                        original: "Radiação ou antiproliferação intracoronária",
                        mod: "Radiação ou antiproliferação intracoronária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912180",
                        original: "Recanalização arterial no IAM - angioplastia primária - com implante de stent com ou sem suporte circulatório (balão intra-órtico)",
                        mod: "Recanalização arterial no IAM - angioplastia primária - com implante de stent com ou sem suporte circulatório (balão intra-órtico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912199",
                        original: "Recanalização mecânica do IAM (angioplastia primária com balão)",
                        mod: "Recanalização mecânica do IAM (angioplastia primária com balão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912202",
                        original: "Redução miocárdica por infusão seletiva de drogas",
                        mod: "Redução miocárdica por infusão seletiva de drogas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912210",
                        original: "Retirada percutânea de corpos estranhos vasculares",
                        mod: "Retirada percutânea de corpos estranhos vasculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912229",
                        original: "Revascularização transmiocárdica percutânea",
                        mod: "Revascularização transmiocárdica percutânea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912237",
                        original: "Tratamento percutâneo do aneurisma/dissecção da aorta",
                        mod: "Tratamento percutâneo do aneurisma/dissecção da aorta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912245",
                        original: "Valvoplastia percutânea por via arterial ou venosa",
                        mod: "Valvoplastia percutânea por via arterial ou venosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912253",
                        original: "Valvoplastia percutânea por via transeptal",
                        mod: "Valvoplastia percutânea por via transeptal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912261",
                        original: "Angioplastia transluminal percutânea de bifurcação e de tronco com implante de stent",
                        mod: "Angioplastia transluminal percutânea de bifurcação e de tronco com implante de stent",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912270",
                        original: "Ateromectomia rotacional, direcional, extracional ou uso de laser coronariano com ou sem angioplastia por balão, com ou sem implante de stent",
                        mod: "Ateromectomia rotacional, direcional, extracional ou uso de laser coronariano com ou sem angioplastia por balão, com ou sem implante de stent",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30912288",
                        original: "Procedimento terapêutico nas cardiopatias congênitas, exceto atriosseptostomia",
                        mod: "Procedimento terapêutico nas cardiopatias congênitas, exceto atriosseptostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30913012",
                        original: "Implante de cateter venoso central por punção, para NPP, QT, Hemodepuração ou para infusão de soros/drogas",
                        mod: "Implante de cateter venoso central por punção, para NPP, QT, Hemodepuração ou para infusão de soros/drogas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30913020",
                        original: "Instalação de cateter para monitorização hemodinâmica à beira do leito (Swan-Ganz)",
                        mod: "Instalação de cateter para monitorização hemodinâmica à beira do leito (Swan-Ganz)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30913047",
                        original: "Instalação de circuito para assistência mecânica circulatória prolongada (toracotomia)",
                        mod: "Instalação de circuito para assistência mecânica circulatória prolongada (toracotomia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30913055",
                        original: "Manutenção de circuito para assistência mecânica circulatória prolongada - período de 6 horas",
                        mod: "Manutenção de circuito para assistência mecânica circulatória prolongada - período de 6 horas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30913071",
                        original: "Dissecção de vaso umbilical com colocação de cateter",
                        mod: "Dissecção de vaso umbilical com colocação de cateter",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30913080",
                        original: "Dissecção de veia em RN ou lactente",
                        mod: "Dissecção de veia em RN ou lactente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30913098",
                        original: "Dissecção de veia com colocação cateter venoso",
                        mod: "Dissecção de veia com colocação cateter venoso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30913101",
                        original: "Implante cirúrgico de cateter de longa permanência para NPP, QT ou para Hemodepuração",
                        mod: "Implante cirúrgico de cateter de longa permanência para NPP, QT ou para Hemodepuração",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30913128",
                        original: "Retirada cirúrgica de cateter de longa permanência para NPP, QT ou para Hemodepuração",
                        mod: "Retirada cirúrgica de cateter de longa permanência para NPP, QT ou para Hemodepuração",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30913144",
                        original: "Confecção de fístula AV para hemodiálise",
                        mod: "Confecção de fístula AV para hemodiálise",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30913152",
                        original: "Retirada/desativação  de fístula AV para hemodiálise",
                        mod: "Retirada/desativação  de fístula AV para hemodiálise",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914019",
                        original: "Anastomose linfovenosa",
                        mod: "Anastomose linfovenosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914027",
                        original: "Doenca de Hodgkin - estadiamento cirúrgico",
                        mod: "Doenca de Hodgkin - estadiamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914043",
                        original: "Linfadenectomia inguinal ou ilíaca",
                        mod: "Linfadenectomia inguinal ou ilíaca",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914051",
                        original: "Linfadenectomia cervical",
                        mod: "Linfadenectomia cervical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914060",
                        original: "Linfadenectomia pélvica",
                        mod: "Linfadenectomia pélvica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914078",
                        original: "Linfadenectomia retroperitoneal",
                        mod: "Linfadenectomia retroperitoneal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914086",
                        original: "Linfangioplastia",
                        mod: "Linfangioplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914094",
                        original: "Linfedema - ressecção total",
                        mod: "Linfedema - ressecção total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914108",
                        original: "Linfedema genital - ressecção",
                        mod: "Linfedema genital - ressecção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914116",
                        original: "Marsupialização de linfocele",
                        mod: "Marsupialização de linfocele",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914124",
                        original: "Punção biópsia ganglionar",
                        mod: "Punção biópsia ganglionar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914132",
                        original: "Linfedema - ressecção parcial",
                        mod: "Linfedema - ressecção parcial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914140",
                        original: "Linfadenectomia pélvica laparoscópica",
                        mod: "Linfadenectomia pélvica laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914159",
                        original: "Linfadenectomia retroperitoneal laparoscópica",
                        mod: "Linfadenectomia retroperitoneal laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30914167",
                        original: "Marsupialização laparoscópica de linfocele",
                        mod: "Marsupialização laparoscópica de linfocele",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30915015",
                        original: "Correção cirúrgica das arritmias",
                        mod: "Correção cirúrgica das arritmias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30915023",
                        original: "Drenagem do pericárdio",
                        mod: "Drenagem do pericárdio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30915031",
                        original: "Pericardiocentese",
                        mod: "Pericardiocentese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30915040",
                        original: "Pericardiotomia / Pericardiectomia",
                        mod: "Pericardiotomia / Pericardiectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30915058",
                        original: "Drenagem do pericárdio por vídeo",
                        mod: "Drenagem do pericárdio por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30915066",
                        original: "Pericardiotomia / Pericardiectomia por vídeo",
                        mod: "Pericardiotomia / Pericardiectomia por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30916011",
                        original: "Hipotermia profunda com ou sem parada circulatória total",
                        mod: "Hipotermia profunda com ou sem parada circulatória total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30917018",
                        original: "Biópsia do miocárdio",
                        mod: "Biópsia do miocárdio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30917026",
                        original: "Cardiomioplastia",
                        mod: "Cardiomioplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30917034",
                        original: "Cardiotomia (ferimento, corpo estranho, exploração)",
                        mod: "Cardiotomia (ferimento, corpo estranho, exploração)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30917042",
                        original: "Retirada de tumores intracardíacos",
                        mod: "Retirada de tumores intracardíacos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30918014",
                        original: "Estudo eletrofisiológico cardíaco com ou sem sensibilização farmacológica",
                        mod: "Estudo eletrofisiológico cardíaco com ou sem sensibilização farmacológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30918022",
                        original: "Mapeamento de gatilhos ou substratos arritmogênicos por técnica eletrofisiológica com ou sem provas farmacológicas",
                        mod: "Mapeamento de gatilhos ou substratos arritmogênicos por técnica eletrofisiológica com ou sem provas farmacológicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30918030",
                        original: "Mapeamento eletroanatômico tridimensional",
                        mod: "Mapeamento eletroanatômico tridimensional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "30918081",
                        original: "Ablação percutânea por cateter para tratamento de arritmias cardíacas complexas (Fibrilação atrial, Taquicardia Ventricular com modificação de cicatriz, Taquicardias Atriais Macrorreentrantes com modificaçõa de cicatriz) por energia de radiofrequência",
                        mod: "Ablação percutânea por cateter para tratamento de arritmias cardíacas complexas (Fibrilação atrial, Taquicardia Ventricular com modificação de cicatriz, Taquicardias Atriais Macrorreentrantes com modificaçõa de cicatriz) por energia de radiofrequência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001017",
                        original: "Atresia de esôfago com fístula traqueal - tratamento cirúrgico",
                        mod: "Atresia de esôfago com fístula traqueal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001025",
                        original: "Atresia de esôfago sem fístula (dupla estomia) - tratamento cirúrgico",
                        mod: "Atresia de esôfago sem fístula (dupla estomia) - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001033",
                        original: "Autotransplante com microcirurgia",
                        mod: "Autotransplante com microcirurgia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001041",
                        original: "Esofagectomia distal com toracotomia",
                        mod: "Esofagectomia distal com toracotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001050",
                        original: "Esofagectomia distal sem toracotomia",
                        mod: "Esofagectomia distal sem toracotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001068",
                        original: "Esofagoplastia (coloplastia)",
                        mod: "Esofagoplastia (coloplastia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001076",
                        original: "Esofagoplastia (gastroplastia)",
                        mod: "Esofagoplastia (gastroplastia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001084",
                        original: "Estenose de esôfago - tratamento cirúrgico via torácica",
                        mod: "Estenose de esôfago - tratamento cirúrgico via torácica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001092",
                        original: "Faringo-laringo-esofagectomia total com ou sem toracotomia",
                        mod: "Faringo-laringo-esofagectomia total com ou sem toracotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001106",
                        original: "Fístula tráqueo esofágica - tratamento cirúrgico via cervical",
                        mod: "Fístula tráqueo esofágica - tratamento cirúrgico via cervical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001114",
                        original: "Fístula tráqueo esofágica - tratamento cirúrgico via torácica",
                        mod: "Fístula tráqueo esofágica - tratamento cirúrgico via torácica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001149",
                        original: "Reintervenção sobre a transição esôfago gástrica",
                        mod: "Reintervenção sobre a transição esôfago gástrica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001157",
                        original: "Ressecção do esôfago cervical e/ou torácico e transplante com microcirurgia",
                        mod: "Ressecção do esôfago cervical e/ou torácico e transplante com microcirurgia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001165",
                        original: "Substituição esofágica - cólon ou tubo gástrico",
                        mod: "Substituição esofágica - cólon ou tubo gástrico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001173",
                        original: "Tratamento cirúrgico das varizes esofágicas",
                        mod: "Tratamento cirúrgico das varizes esofágicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001181",
                        original: "Tratamento cirúrgico conservador do megaesofago",
                        mod: "Tratamento cirúrgico conservador do megaesofago",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001190",
                        original: "Tunelização esofágica",
                        mod: "Tunelização esofágica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001203",
                        original: "Esofagorrafia cervical",
                        mod: "Esofagorrafia cervical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001211",
                        original: "Esofagorrafia torácica",
                        mod: "Esofagorrafia torácica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001220",
                        original: "Esofagostomia",
                        mod: "Esofagostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001238",
                        original: "Tratamento cirúrgico do divertículo esofágico",
                        mod: "Tratamento cirúrgico do divertículo esofágico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001246",
                        original: "Tratamento cirúrgico do divertículo faringoesofágico",
                        mod: "Tratamento cirúrgico do divertículo faringoesofágico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001254",
                        original: "Esofagectomia subtotal com linfadenectomia com ou sem toracotomia",
                        mod: "Esofagectomia subtotal com linfadenectomia com ou sem toracotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001262",
                        original: "Refluxo gastroesofágico - tratamento cirúrgico (Hérnia de hiato)",
                        mod: "Refluxo gastroesofágico - tratamento cirúrgico (Hérnia de hiato)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001270",
                        original: "Reconstrução do esôfago cervical e torácico com transplante segmentar de intestino",
                        mod: "Reconstrução do esôfago cervical e torácico com transplante segmentar de intestino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001289",
                        original: "Reconstrução do esôfago cervical ou torácico, com transplante de intestino",
                        mod: "Reconstrução do esôfago cervical ou torácico, com transplante de intestino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001297",
                        original: "Dissecção do esôfago torácico (qualquer técnica)",
                        mod: "Dissecção do esôfago torácico (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001300",
                        original: "Esofagectomia distal com ou sem toracotomia por videolaparoscopia",
                        mod: "Esofagectomia distal com ou sem toracotomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001319",
                        original: "Reintervenção sobre a transição esôfago gástrica por videolaparoscopia",
                        mod: "Reintervenção sobre a transição esôfago gástrica por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001327",
                        original: "Tratamento cirúrgico das varizes esofágicas por videolaparoscopia",
                        mod: "Tratamento cirúrgico das varizes esofágicas por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001335",
                        original: "Tratamento cirúrgico conservador do megaesofago por videolaparoscopia",
                        mod: "Tratamento cirúrgico conservador do megaesofago por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001343",
                        original: "Esofagorrafia torácica por videotoracoscopia",
                        mod: "Esofagorrafia torácica por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001351",
                        original: "Tratamento cirúrgico do divertículo esofágico por videotoracoscopia",
                        mod: "Tratamento cirúrgico do divertículo esofágico por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31001360",
                        original: "Refluxo gastroesofágico - tratamento cirúrgico (Hérnia de hiato) por videolaparoscopia",
                        mod: "Refluxo gastroesofágico - tratamento cirúrgico (Hérnia de hiato) por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002013",
                        original: "Colocação de banda gástrica",
                        mod: "Colocação de banda gástrica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002021",
                        original: "Conversão de anastomose gastrojejunal (qualquer técnica)",
                        mod: "Conversão de anastomose gastrojejunal (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002030",
                        original: "Degastrogastrectomia com vagotomia",
                        mod: "Degastrogastrectomia com vagotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002048",
                        original: "Degastrogastrectomia sem vagotomia",
                        mod: "Degastrogastrectomia sem vagotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002056",
                        original: "Gastrostomia confecção / fechamento",
                        mod: "Gastrostomia confecção / fechamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002064",
                        original: "Gastrectomia parcial com linfadenectomia",
                        mod: "Gastrectomia parcial com linfadenectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002072",
                        original: "Gastrectomia parcial com vagotomia",
                        mod: "Gastrectomia parcial com vagotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002080",
                        original: "Gastrectomia parcial sem vagotomia",
                        mod: "Gastrectomia parcial sem vagotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002099",
                        original: "Gastrectomia polar superior com reconstrução jejunal com toracotomia",
                        mod: "Gastrectomia polar superior com reconstrução jejunal com toracotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002102",
                        original: "Gastrectomia polar superior com reconstrução jejunal sem toracotomia",
                        mod: "Gastrectomia polar superior com reconstrução jejunal sem toracotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002110",
                        original: "Gastrectomia total com linfadenectomia",
                        mod: "Gastrectomia total com linfadenectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002129",
                        original: "Gastrectomia total via abdominal",
                        mod: "Gastrectomia total via abdominal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002137",
                        original: "Gastroenteroanastomose",
                        mod: "Gastroenteroanastomose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002145",
                        original: "Gastrorrafia",
                        mod: "Gastrorrafia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002153",
                        original: "Gastrotomia com sutura de varizes",
                        mod: "Gastrotomia com sutura de varizes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002161",
                        original: "Gastrotomia para retirada de CE ou lesão isolada",
                        mod: "Gastrotomia para retirada de CE ou lesão isolada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002170",
                        original: "Gastrotomia para qualquer finalidade",
                        mod: "Gastrotomia para qualquer finalidade",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002188",
                        original: "Membrana antral - tratamento cirúrgico",
                        mod: "Membrana antral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002196",
                        original: "Piloroplastia",
                        mod: "Piloroplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002218",
                        original: "Gastroplastia para obesidade mórbida - qualquer técnica",
                        mod: "Gastroplastia para obesidade mórbida - qualquer técnica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002242",
                        original: "Tratamento cirúrgico das varizes gástricas",
                        mod: "Tratamento cirúrgico das varizes gástricas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002250",
                        original: "Vagotomia com operação de drenagem",
                        mod: "Vagotomia com operação de drenagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002269",
                        original: "Vagotomia gástrica proximal ou superseletiva com duodenoplastia (operação de drenagem)",
                        mod: "Vagotomia gástrica proximal ou superseletiva com duodenoplastia (operação de drenagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002277",
                        original: "Vagotomia superseletiva ou vagotomia gástrica proximal",
                        mod: "Vagotomia superseletiva ou vagotomia gástrica proximal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002285",
                        original: "Colocação de banda gástrica por videolaparoscopia",
                        mod: "Colocação de banda gástrica por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002293",
                        original: "Conversão de anastomose gastrojejunal por videolaparoscopia",
                        mod: "Conversão de anastomose gastrojejunal por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002307",
                        original: "Gastrectomia parcial com linfadenectomia por videolaparoscopia",
                        mod: "Gastrectomia parcial com linfadenectomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002315",
                        original: "Gastrectomia parcial com vagotomia por videolaparoscopia",
                        mod: "Gastrectomia parcial com vagotomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002323",
                        original: "Gastrectomia parcial sem vagotomia por videolaparoscopia",
                        mod: "Gastrectomia parcial sem vagotomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002331",
                        original: "Gastrectomia total com linfadenectomia por videolaparoscopia",
                        mod: "Gastrectomia total com linfadenectomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002340",
                        original: "Gastrectomia total via abdominal por videolaparoscopia",
                        mod: "Gastrectomia total via abdominal por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002358",
                        original: "Gastroenteroanastomose por videolaparoscopia",
                        mod: "Gastroenteroanastomose por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002366",
                        original: "Gastrotomia para retirada de CE ou lesão isolada por videolaparoscopia",
                        mod: "Gastrotomia para retirada de CE ou lesão isolada por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002374",
                        original: "Piloroplastia por videolaparoscopia",
                        mod: "Piloroplastia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002390",
                        original: "Gastroplastia para obesidade mórbida por videolaparoscopia",
                        mod: "Gastroplastia para obesidade mórbida por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002404",
                        original: "Vagotomia gástrica proximal ou superseletiva com duodenoplastia (operação de drenagem) por videolaparoscopia",
                        mod: "Vagotomia gástrica proximal ou superseletiva com duodenoplastia (operação de drenagem) por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31002412",
                        original: "Vagotomia superseletiva ou vagotomia gástrica proximal por videolaparoscopia",
                        mod: "Vagotomia superseletiva ou vagotomia gástrica proximal por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003010",
                        original: "Amputação abdômino-perineal do reto (completa)",
                        mod: "Amputação abdômino-perineal do reto (completa)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003028",
                        original: "Amputação do reto por procidência",
                        mod: "Amputação do reto por procidência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003036",
                        original: "Anomalia anorretal - correção via sagital posterior",
                        mod: "Anomalia anorretal - correção via sagital posterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003044",
                        original: "Anomalia anorretal - tratamento cirúrgico via abdômino-perineal",
                        mod: "Anomalia anorretal - tratamento cirúrgico via abdômino-perineal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003052",
                        original: "Anomalia anorretal - tratamento cirúrgico via perineal",
                        mod: "Anomalia anorretal - tratamento cirúrgico via perineal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003060",
                        original: "Anorretomiomectomia",
                        mod: "Anorretomiomectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003079",
                        original: "Apendicectomia",
                        mod: "Apendicectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003087",
                        original: "Apple-Peel - tratamento cirúrgico",
                        mod: "Apple-Peel - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003095",
                        original: "Atresia de cólon - tratamento cirúrgico",
                        mod: "Atresia de cólon - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003109",
                        original: "Atresia de duodeno - tratamento cirúrgico",
                        mod: "Atresia de duodeno - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003117",
                        original: "Atresia jejunal distal ou ileal - tratamento cirúrgico",
                        mod: "Atresia jejunal distal ou ileal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003125",
                        original: "Atresia jejunal proximal - tratamento cirúrgico",
                        mod: "Atresia jejunal proximal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003133",
                        original: "Cirurgia de abaixamento (qualquer técnica)",
                        mod: "Cirurgia de abaixamento (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003141",
                        original: "Cirurgia de acesso posterior",
                        mod: "Cirurgia de acesso posterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003150",
                        original: "Cisto mesentérico - tratamento cirúrgico",
                        mod: "Cisto mesentérico - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003168",
                        original: "Colectomia parcial com colostomia",
                        mod: "Colectomia parcial com colostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003176",
                        original: "Colectomia parcial sem colostomia",
                        mod: "Colectomia parcial sem colostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003184",
                        original: "Colectomia total com íleo-reto-anastomose",
                        mod: "Colectomia total com íleo-reto-anastomose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003192",
                        original: "Colectomia total com ileostomia",
                        mod: "Colectomia total com ileostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003206",
                        original: "Colocação de sonda enteral",
                        mod: "Colocação de sonda enteral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003214",
                        original: "Colostomia ou enterostomia",
                        mod: "Colostomia ou enterostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003230",
                        original: "Colotomia e colorrafia",
                        mod: "Colotomia e colorrafia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003249",
                        original: "Distorção de volvo por laparotomia",
                        mod: "Distorção de volvo por laparotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003257",
                        original: "Distorção de volvo por via endoscópica",
                        mod: "Distorção de volvo por via endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003265",
                        original: "Divertículo de Meckel - exérese",
                        mod: "Divertículo de Meckel - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003273",
                        original: "Duplicação do tubo digestivo - tratamento cirúrgico",
                        mod: "Duplicação do tubo digestivo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003281",
                        original: "Enterectomia segmentar",
                        mod: "Enterectomia segmentar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003290",
                        original: "Entero-anastomose  (qualquer segmento)",
                        mod: "Entero-anastomose  (qualquer segmento)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003303",
                        original: "Enterocolite necrotizante - tratamento cirúrgico",
                        mod: "Enterocolite necrotizante - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003311",
                        original: "Enteropexia (qualquer segmento)",
                        mod: "Enteropexia (qualquer segmento)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003320",
                        original: "Enterotomia e/ou enterorrafia de qualquer segmento (por sutura ou ressecção)",
                        mod: "Enterotomia e/ou enterorrafia de qualquer segmento (por sutura ou ressecção)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003338",
                        original: "Esporão retal - ressecção",
                        mod: "Esporão retal - ressecção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003346",
                        original: "Esvaziamento pélvico anterior ou posterior - procedimento cirúrgico",
                        mod: "Esvaziamento pélvico anterior ou posterior - procedimento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003354",
                        original: "Esvaziamento pélvico total - procedimento cirurgico",
                        mod: "Esvaziamento pélvico total - procedimento cirurgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003362",
                        original: "Fecaloma - remoção manual",
                        mod: "Fecaloma - remoção manual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003370",
                        original: "Fechamento de colostomia ou enterostomia",
                        mod: "Fechamento de colostomia ou enterostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003389",
                        original: "Fixação do reto por via abdominal",
                        mod: "Fixação do reto por via abdominal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003397",
                        original: "Íleo meconial - tratamento cirúrgico",
                        mod: "Íleo meconial - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003427",
                        original: "Invaginação intestinal - ressecção",
                        mod: "Invaginação intestinal - ressecção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003435",
                        original: "Invaginação intestinal sem ressecção - tratamento cirúrgico",
                        mod: "Invaginação intestinal sem ressecção - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003451",
                        original: "Má-rotação intestinal - tratamento cirúrgico",
                        mod: "Má-rotação intestinal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003460",
                        original: "Megacólon congênito - tratamento cirúrgico",
                        mod: "Megacólon congênito - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003478",
                        original: "Membrana duodenal - tratamento cirúrgico",
                        mod: "Membrana duodenal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003486",
                        original: "Pâncreas anular - tratamento cirúrgico",
                        mod: "Pâncreas anular - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003494",
                        original: "Perfuração duodenal ou delgado - tratamento cirúrgico",
                        mod: "Perfuração duodenal ou delgado - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003508",
                        original: "Piloromiotomia",
                        mod: "Piloromiotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003516",
                        original: "Procidência do reto - redução manual",
                        mod: "Procidência do reto - redução manual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003524",
                        original: "Proctocolectomia total",
                        mod: "Proctocolectomia total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003532",
                        original: "Proctocolectomia total com reservatório ileal",
                        mod: "Proctocolectomia total com reservatório ileal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003540",
                        original: "Ressecção total de intestino delgado",
                        mod: "Ressecção total de intestino delgado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003559",
                        original: "Retossigmoidectomia abdominal",
                        mod: "Retossigmoidectomia abdominal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003567",
                        original: "Tumor anorretal - ressecção anorretal, tratamento cirúrgico",
                        mod: "Tumor anorretal - ressecção anorretal, tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003567",
                        original: "Tumor anorretal - ressecção endo-anal",
                        mod: "Tumor anorretal - ressecção endo-anal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003575",
                        original: "Amputação abdômino-perineal do reto (completa) por videolaparoscopia",
                        mod: "Amputação abdômino-perineal do reto (completa) por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003583",
                        original: "Apendicectomia por videolaparoscopia",
                        mod: "Apendicectomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003591",
                        original: "Cirurgia de abaixamento por videolaparoscopia",
                        mod: "Cirurgia de abaixamento por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003605",
                        original: "Cisto mesentérico - tratamento cirúrgico por videolaparoscopia",
                        mod: "Cisto mesentérico - tratamento cirúrgico por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003613",
                        original: "Colectomia parcial com colostomia por videolaparoscopia",
                        mod: "Colectomia parcial com colostomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003621",
                        original: "Colectomia parcial sem colostomia por videolaparoscopia",
                        mod: "Colectomia parcial sem colostomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003630",
                        original: "Colectomia total com íleo-reto-anastomose por videolaparoscopia",
                        mod: "Colectomia total com íleo-reto-anastomose por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003648",
                        original: "Colectomia total com ileostomia por videolaparoscopia",
                        mod: "Colectomia total com ileostomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003656",
                        original: "Distorção de volvo por videolaparoscopia",
                        mod: "Distorção de volvo por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003664",
                        original: "Divertículo de Meckel - exérese por videolaparoscopia",
                        mod: "Divertículo de Meckel - exérese por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003672",
                        original: "Enterectomia segmentar por videolaparoscopia",
                        mod: "Enterectomia segmentar por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003680",
                        original: "Entero-anastomose (qualque segmento) por videolaparoscopia",
                        mod: "Entero-anastomose (qualque segmento) por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003699",
                        original: "Enteropexia (qualquer segmento) por videolaparoscopia",
                        mod: "Enteropexia (qualquer segmento) por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003702",
                        original: "Esvaziamento pélvico anterior ou posterior por videolaparoscopia",
                        mod: "Esvaziamento pélvico anterior ou posterior por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003710",
                        original: "Esvaziamento pélvico total por videolaparoscopia",
                        mod: "Esvaziamento pélvico total por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003729",
                        original: "Fixação do reto por via abdominal por videolaparoscopia",
                        mod: "Fixação do reto por via abdominal por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003737",
                        original: "Megacólon congênito - tratamento cirúrgico por videolaparoscopia",
                        mod: "Megacólon congênito - tratamento cirúrgico por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003745",
                        original: "Pâncreas anular - tratamento cirúrgico por videolaparoscopia",
                        mod: "Pâncreas anular - tratamento cirúrgico por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003753",
                        original: "Perfuração duodenal ou delgado - tratamento cirúrgico por videolaparoscopia",
                        mod: "Perfuração duodenal ou delgado - tratamento cirúrgico por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003761",
                        original: "Piloromiotomia por videolaparoscopia",
                        mod: "Piloromiotomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003770",
                        original: "Proctocolectomia total com reservatório ileal por videolaparoscopia",
                        mod: "Proctocolectomia total com reservatório ileal por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003788",
                        original: "Proctocolectomia total por videolaparoscopia",
                        mod: "Proctocolectomia total por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31003796",
                        original: "Retossigmoidectomia abdominal por videolaparoscopia",
                        mod: "Retossigmoidectomia abdominal por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004016",
                        original: "Abscesso anorretal - drenagem",
                        mod: "Abscesso anorretal - drenagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004024",
                        original: "Abscesso isquio-retal - drenagem",
                        mod: "Abscesso isquio-retal - drenagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004032",
                        original: "Cerclagem anal",
                        mod: "Cerclagem anal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004040",
                        original: "Corpo estranho do reto - retirada",
                        mod: "Corpo estranho do reto - retirada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004059",
                        original: "Criptectomia (única ou múltipla)",
                        mod: "Criptectomia (única ou múltipla)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004067",
                        original: "Dilatação digital ou instrumental do ânus e/ou do reto",
                        mod: "Dilatação digital ou instrumental do ânus e/ou do reto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004075",
                        original: "Esfincteroplastia anal (qualquer técnica)",
                        mod: "Esfincteroplastia anal (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004083",
                        original: "Estenose anal - tratamento cirúrgico (qualquer técnica)",
                        mod: "Estenose anal - tratamento cirúrgico (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004091",
                        original: "Excisão de plicoma",
                        mod: "Excisão de plicoma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004105",
                        original: "Fissurectomia com ou sem esfincterotomia",
                        mod: "Fissurectomia com ou sem esfincterotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004113",
                        original: "Fístula reto-vaginal e fístula anal em ferradura - tratamento cirúrgico via perineal",
                        mod: "Fístula reto-vaginal e fístula anal em ferradura - tratamento cirúrgico via perineal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004121",
                        original: "Fistulectomia anal em dois tempos",
                        mod: "Fistulectomia anal em dois tempos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004130",
                        original: "Fistulectomia anal em ferradura",
                        mod: "Fistulectomia anal em ferradura",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004148",
                        original: "Fistulectomia anal em um tempo",
                        mod: "Fistulectomia anal em um tempo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004156",
                        original: "Fistulectomia anorretal com abaixamento mucoso",
                        mod: "Fistulectomia anorretal com abaixamento mucoso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004164",
                        original: "Fistulectomia perineal",
                        mod: "Fistulectomia perineal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004172",
                        original: "Hemorróidas - fotocoagulação com raio infravermelho (por sessão)",
                        mod: "Hemorróidas - fotocoagulação com raio infravermelho (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004180",
                        original: "Hemorróidas - ligadura elástica (por sessão)",
                        mod: "Hemorróidas - ligadura elástica (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004199",
                        original: "Hemorróidas - tratamento esclerosante (por sessão)",
                        mod: "Hemorróidas - tratamento esclerosante (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004202",
                        original: "Hemorroidectomia aberta ou fechada, com ou sem esfincterotomia, sem grampeador",
                        mod: "Hemorroidectomia aberta ou fechada, com ou sem esfincterotomia, sem grampeador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004202",
                        original: "Hemorroidectomia aberta ou fechada, com ou sem esfincterotomia",
                        mod: "Hemorroidectomia aberta ou fechada, com ou sem esfincterotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004210",
                        original: "Laceração anorretal - tratamento cirúrgico por via perineal",
                        mod: "Laceração anorretal - tratamento cirúrgico por via perineal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004229",
                        original: "Lesão anal - eletrocauterização",
                        mod: "Lesão anal - eletrocauterização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004237",
                        original: "Papilectomia (única ou múltipla)",
                        mod: "Papilectomia (única ou múltipla)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004245",
                        original: "Pólipo retal - ressecção endoanal",
                        mod: "Pólipo retal - ressecção endoanal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004253",
                        original: "Prolapso retal - esclerose (por sessão)",
                        mod: "Prolapso retal - esclerose (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004261",
                        original: "Prolapso retal - tratamento cirúrgico perineal",
                        mod: "Prolapso retal - tratamento cirúrgico perineal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004261",
                        original: "Prolapso retal - tratamento cirúrgico",
                        mod: "Prolapso retal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004270",
                        original: "Reconstituição de esfincter anal por plástica muscular (qualquer técnica)",
                        mod: "Reconstituição de esfincter anal por plástica muscular (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004288",
                        original: "Reconstrução total anoperineal",
                        mod: "Reconstrução total anoperineal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004300",
                        original: "Tratamento cirúrgico de retocele (colpoperineoplastia posterior)",
                        mod: "Tratamento cirúrgico de retocele (colpoperineoplastia posterior)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004318",
                        original: "Trombose hemorroidária - exérese",
                        mod: "Trombose hemorroidária - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004326",
                        original: "Prurido anal - tratamento cirúrgico",
                        mod: "Prurido anal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004334",
                        original: "Esfincterotomia  - ânus",
                        mod: "Esfincterotomia  - ânus",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31004342",
                        original: "Anopexia mecânica com grampeador",
                        mod: "Anopexia mecânica com grampeador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005012",
                        original: "Abscesso hepático - drenagem cirúrgica (até 3 fragmentos)",
                        mod: "Abscesso hepático - drenagem cirúrgica (até 3 fragmentos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005020",
                        original: "Alcoolização percutânea dirigida de tumor hepático",
                        mod: "Alcoolização percutânea dirigida de tumor hepático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005039",
                        original: "Anastomose biliodigestiva intra-hepática",
                        mod: "Anastomose biliodigestiva intra-hepática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005047",
                        original: "Atresia de vias biliares - tratamento cirúrgico",
                        mod: "Atresia de vias biliares - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005063",
                        original: "Biópsia hepática por laparotomia (até 3 fragmentos)",
                        mod: "Biópsia hepática por laparotomia (até 3 fragmentos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005071",
                        original: "Biópsia hepática transparietal (até 3 fragmentos)",
                        mod: "Biópsia hepática transparietal (até 3 fragmentos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005080",
                        original: "Laparotomia para implantação cirúrgica de cateter arterial visceral para quimioterapia",
                        mod: "Laparotomia para implantação cirúrgica de cateter arterial visceral para quimioterapia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005098",
                        original: "Cisto de colédoco - tratamento cirúrgico",
                        mod: "Cisto de colédoco - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005101",
                        original: "Colecistectomia com colangiografia",
                        mod: "Colecistectomia com colangiografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005110",
                        original: "Colecistectomia com fístula biliodigestiva",
                        mod: "Colecistectomia com fístula biliodigestiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005128",
                        original: "Colecistectomia sem colangiografia",
                        mod: "Colecistectomia sem colangiografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005136",
                        original: "Colecistojejunostomia",
                        mod: "Colecistojejunostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005144",
                        original: "Colecistostomia",
                        mod: "Colecistostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005152",
                        original: "Colédoco ou hepático-jejunostomia (qualquer técnica)",
                        mod: "Colédoco ou hepático-jejunostomia (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005160",
                        original: "Colédoco ou hepaticoplastia",
                        mod: "Colédoco ou hepaticoplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005179",
                        original: "Colédoco-duodenostomia",
                        mod: "Colédoco-duodenostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005187",
                        original: "Coledocotomia ou coledocostomia sem colecistectomia",
                        mod: "Coledocotomia ou coledocostomia sem colecistectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005195",
                        original: "Coledocoscopia intra-operatória",
                        mod: "Coledocoscopia intra-operatória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005209",
                        original: "Derivação porto sistêmica",
                        mod: "Derivação porto sistêmica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005217",
                        original: "Desconexão ázigos - portal com esplenectomia",
                        mod: "Desconexão ázigos - portal com esplenectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005225",
                        original: "Desconexão ázigos - portal sem esplenectomia",
                        mod: "Desconexão ázigos - portal sem esplenectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005233",
                        original: "Desvascularização hepática",
                        mod: "Desvascularização hepática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005241",
                        original: "Drenagem biliar trans-hepática",
                        mod: "Drenagem biliar trans-hepática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005250",
                        original: "Enucleação de metástases hepáticas",
                        mod: "Enucleação de metástases hepáticas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005268",
                        original: "Enucleação de metástases, por metástase",
                        mod: "Enucleação de metástases, por metástase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005276",
                        original: "Hepatorrafia",
                        mod: "Hepatorrafia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005284",
                        original: "Hepatorrafia complexa com lesão de estruturas vasculares biliares",
                        mod: "Hepatorrafia complexa com lesão de estruturas vasculares biliares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005292",
                        original: "Lobectomia hepática direita",
                        mod: "Lobectomia hepática direita",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005306",
                        original: "Lobectomia hepática esquerda",
                        mod: "Lobectomia hepática esquerda",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005314",
                        original: "Papilotomia transduodenal",
                        mod: "Papilotomia transduodenal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005322",
                        original: "Punção hepática para drenagem de abscessos",
                        mod: "Punção hepática para drenagem de abscessos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005330",
                        original: "Radioablação / termoablação de tumores hepáticos",
                        mod: "Radioablação / termoablação de tumores hepáticos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005357",
                        original: "Ressecção de cisto hepático com hepatectomia",
                        mod: "Ressecção de cisto hepático com hepatectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005365",
                        original: "Ressecção de cisto hepático sem hepatectomia",
                        mod: "Ressecção de cisto hepático sem hepatectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005373",
                        original: "Ressecção de tumor de vesícula ou da via biliar com hepatectomia",
                        mod: "Ressecção de tumor de vesícula ou da via biliar com hepatectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005381",
                        original: "Ressecção de tumor de vesícula ou da via biliar sem hepatectomia",
                        mod: "Ressecção de tumor de vesícula ou da via biliar sem hepatectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005390",
                        original: "Segmentectomia hepática",
                        mod: "Segmentectomia hepática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005403",
                        original: "Sequestrectomia hepática",
                        mod: "Sequestrectomia hepática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005420",
                        original: "Tratamento cirúrgico de estenose cicatricial das vias biliares",
                        mod: "Tratamento cirúrgico de estenose cicatricial das vias biliares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005438",
                        original: "Trissegmentectomias",
                        mod: "Trissegmentectomias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005446",
                        original: "Coledocotomia ou coledocostomia com colecistectomia",
                        mod: "Coledocotomia ou coledocostomia com colecistectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005454",
                        original: "Abscesso hepático - drenagem cirúrgica por videolaparoscopia",
                        mod: "Abscesso hepático - drenagem cirúrgica por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005462",
                        original: "Alcoolização percutânea dirigida de tumor hepático por videolaparoscopia",
                        mod: "Alcoolização percutânea dirigida de tumor hepático por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005470",
                        original: "Colecistectomia com colangiografia por videolaparoscopia",
                        mod: "Colecistectomia com colangiografia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005489",
                        original: "Colecistectomia com fístula biliodigestiva por videolaparoscopia",
                        mod: "Colecistectomia com fístula biliodigestiva por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005497",
                        original: "Colecistectomia sem colangiografia por videolaparoscopia",
                        mod: "Colecistectomia sem colangiografia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005500",
                        original: "Colecistojejunostomia por videolaparoscopia",
                        mod: "Colecistojejunostomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005519",
                        original: "Colecistostomia por videolaparoscopia",
                        mod: "Colecistostomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005527",
                        original: "Colédoco ou hepático-jejunostomia por videolaparoscopia",
                        mod: "Colédoco ou hepático-jejunostomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005535",
                        original: "Colédoco-duodenostomia por videolaparoscopia",
                        mod: "Colédoco-duodenostomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005543",
                        original: "Coledocotomia ou coledocostomia com colecistectomia por videolaparoscopia",
                        mod: "Coledocotomia ou coledocostomia com colecistectomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005551",
                        original: "Coledocotomia ou coledocostomia sem colecistectomia por videolaparoscopia",
                        mod: "Coledocotomia ou coledocostomia sem colecistectomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005560",
                        original: "Desconexão ázigos - portal com esplenectomia por videolaparoscopia",
                        mod: "Desconexão ázigos - portal com esplenectomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005578",
                        original: "Desconexão ázigos - portal sem esplenectomia por videolaparoscopia",
                        mod: "Desconexão ázigos - portal sem esplenectomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005586",
                        original: "Enucleação de metástase hepáticas por videolaparoscopia",
                        mod: "Enucleação de metástase hepáticas por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005594",
                        original: "Hepatorrafia complexa com lesão de estruturas vasculares biliares por videolaparoscopia",
                        mod: "Hepatorrafia complexa com lesão de estruturas vasculares biliares por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005608",
                        original: "Hepatorrafia por videolaparoscopia",
                        mod: "Hepatorrafia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005616",
                        original: "Lobectomia hepática direita por videolaparoscopia",
                        mod: "Lobectomia hepática direita por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005624",
                        original: "Lobectomia hepática esquerda por videolaparoscopia",
                        mod: "Lobectomia hepática esquerda por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005632",
                        original: "Punção hepática para drenagem de abcessos por videolaparoscopia",
                        mod: "Punção hepática para drenagem de abcessos por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005640",
                        original: "Radioablação / termoablação de tumores hepáticos por videolaparoscopia",
                        mod: "Radioablação / termoablação de tumores hepáticos por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005659",
                        original: "Ressecção de cisto hepático com hepatectomia por videolaparoscopia",
                        mod: "Ressecção de cisto hepático com hepatectomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005667",
                        original: "Ressecção de cisto hepático sem hepatectomia por videolaparoscopia",
                        mod: "Ressecção de cisto hepático sem hepatectomia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005675",
                        original: "Biópsia hepática por videolaparoscopia",
                        mod: "Biópsia hepática por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005683",
                        original: "Biópsia hepática por laparotomia (acima de 3 fragmentos)",
                        mod: "Biópsia hepática por laparotomia (acima de 3 fragmentos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31005691",
                        original: "Biópsia hepática transparietal (acima de 3 fragmentos)",
                        mod: "Biópsia hepática transparietal (acima de 3 fragmentos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006019",
                        original: "Biópsia de pâncreas por laparotomia",
                        mod: "Biópsia de pâncreas por laparotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006027",
                        original: "Biópsia de pâncreas por punção dirigida",
                        mod: "Biópsia de pâncreas por punção dirigida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006035",
                        original: "Enucleação de tumores pancreáticos",
                        mod: "Enucleação de tumores pancreáticos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006043",
                        original: "Hipoglicemia - tratamento cirúrgico (pancreatotomia parcial ou total)",
                        mod: "Hipoglicemia - tratamento cirúrgico (pancreatotomia parcial ou total)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006051",
                        original: "Pancreatectomia corpo caudal com preservação do baço",
                        mod: "Pancreatectomia corpo caudal com preservação do baço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006060",
                        original: "Pancreatectomia parcial ou sequestrectomia",
                        mod: "Pancreatectomia parcial ou sequestrectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006078",
                        original: "Pancreato-duodenectomia com linfadenectomia",
                        mod: "Pancreato-duodenectomia com linfadenectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006086",
                        original: "Pancreato-enterostomia",
                        mod: "Pancreato-enterostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006094",
                        original: "Pancreatorrafia",
                        mod: "Pancreatorrafia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006108",
                        original: "Pseudocisto pâncreas - drenagem externa (qualquer técnica)",
                        mod: "Pseudocisto pâncreas - drenagem externa (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006116",
                        original: "Pseudocisto pâncreas - drenagem interna (qualquer técnica)",
                        mod: "Pseudocisto pâncreas - drenagem interna (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006124",
                        original: "Cisto pancreático - cistojejunoanastomose - tratamento cirúrgico",
                        mod: "Cisto pancreático - cistojejunoanastomose - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006132",
                        original: "Cisto pancreático - gastroanastomose - tratamento cirúrgico",
                        mod: "Cisto pancreático - gastroanastomose - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006159",
                        original: "Biópsia de pâncreas por videolaparoscopia",
                        mod: "Biópsia de pâncreas por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006167",
                        original: "Enucleação de tumores pancreáticos por videolaparoscopia",
                        mod: "Enucleação de tumores pancreáticos por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006175",
                        original: "Pseudocisto pâncreas - drenagem externa por videolaparoscopia",
                        mod: "Pseudocisto pâncreas - drenagem externa por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31006183",
                        original: "Pseudocisto pâncreas - drenagem interna por videolaparoscopia",
                        mod: "Pseudocisto pâncreas - drenagem interna por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31007015",
                        original: "Biópsia esplênica",
                        mod: "Biópsia esplênica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31007023",
                        original: "Esplenectomia parcial",
                        mod: "Esplenectomia parcial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31007031",
                        original: "Esplenectomia total",
                        mod: "Esplenectomia total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31007040",
                        original: "Esplenorrafia",
                        mod: "Esplenorrafia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31007058",
                        original: "Esplenectomia parcial por videolaparoscopia",
                        mod: "Esplenectomia parcial por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31007066",
                        original: "Esplenectomia total por videolaparoscopia",
                        mod: "Esplenectomia total por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31007074",
                        original: "Esplenorrafia por videolaparoscopia",
                        mod: "Esplenorrafia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31008011",
                        original: "Diálise peritoneal intermitente - agudo ou crônico (por sessão)",
                        mod: "Diálise peritoneal intermitente - agudo ou crônico (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31008020",
                        original: "Diálise peritoneal ambulatorial contínua (CAPD) 9 dias - treinamento",
                        mod: "Diálise peritoneal ambulatorial contínua (CAPD) 9 dias - treinamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31008038",
                        original: "Diálise peritoneal ambulatorial contínua (CAPD) por mês/paciente",
                        mod: "Diálise peritoneal ambulatorial contínua (CAPD) por mês/paciente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31008046",
                        original: "Diálise peritoneal automática (APD) - tratamento (agudo ou crônico)",
                        mod: "Diálise peritoneal automática (APD) - tratamento (agudo ou crônico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31008054",
                        original: "Epiploplastia",
                        mod: "Epiploplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31008062",
                        original: "Implante de cateter peritoneal",
                        mod: "Implante de cateter peritoneal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31008070",
                        original: "Instalação de cateter Tenckhoff",
                        mod: "Instalação de cateter Tenckhoff",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31008097",
                        original: "Retirada de cateter Tenckhoff",
                        mod: "Retirada de cateter Tenckhoff",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31008100",
                        original: "Epiploplastia por videolaparoscopia",
                        mod: "Epiploplastia por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31008119",
                        original: "Diálise peritoneal automática por mês (agudo ou crônico)",
                        mod: "Diálise peritoneal automática por mês (agudo ou crônico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009018",
                        original: "Abscesso perineal - drenagem cirúrgica",
                        mod: "Abscesso perineal - drenagem cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009026",
                        original: "Biópsia de parede abdominal",
                        mod: "Biópsia de parede abdominal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009042",
                        original: "Cisto sacro-coccígeo - tratamento cirúrgico",
                        mod: "Cisto sacro-coccígeo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009050",
                        original: "Diástase dos retos-abdominais - tratamento cirúrgico",
                        mod: "Diástase dos retos-abdominais - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009069",
                        original: "Hérnia inguinal encarcerada em RN ou lactente - tratamento cirúrgico",
                        mod: "Hérnia inguinal encarcerada em RN ou lactente - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009077",
                        original: "Herniorrafia com ressecção intestinal - estrangulada",
                        mod: "Herniorrafia com ressecção intestinal - estrangulada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009085",
                        original: "Herniorrafia crural - unilateral",
                        mod: "Herniorrafia crural - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009093",
                        original: "Herniorrafia epigástrica",
                        mod: "Herniorrafia epigástrica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009107",
                        original: "Herniorrafia incisional",
                        mod: "Herniorrafia incisional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009115",
                        original: "Herniorrafia inguinal - unilateral",
                        mod: "Herniorrafia inguinal - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009123",
                        original: "Herniorrafia inguinal no RN ou lactente",
                        mod: "Herniorrafia inguinal no RN ou lactente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009131",
                        original: "Herniorrafia lombar",
                        mod: "Herniorrafia lombar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009140",
                        original: "Herniorrafia recidivante",
                        mod: "Herniorrafia recidivante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009158",
                        original: "Herniorrafia sem ressecção intestinal encarcerada",
                        mod: "Herniorrafia sem ressecção intestinal encarcerada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009166",
                        original: "Herniorrafia umbilical",
                        mod: "Herniorrafia umbilical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009174",
                        original: "Laparotomia exploradora, ou para biópsia, ou para drenagem de abscesso, ou para liberação de bridas em vigência de oclusão",
                        mod: "Laparotomia exploradora, ou para biópsia, ou para drenagem de abscesso, ou para liberação de bridas em vigência de oclusão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009204",
                        original: "Neuroblastoma abdominal - exérese",
                        mod: "Neuroblastoma abdominal - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009220",
                        original: "Onfalocele/gastrosquise em 1 tempo ou primeiro tempo ou prótese - tratamento cirúrgico",
                        mod: "Onfalocele/gastrosquise em 1 tempo ou primeiro tempo ou prótese - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009239",
                        original: "Onfalocele/gastrosquise - segundo tempo - tratamento cirúrgico",
                        mod: "Onfalocele/gastrosquise - segundo tempo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009247",
                        original: "Paracentese abdominal",
                        mod: "Paracentese abdominal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009255",
                        original: "Reconstrução da parede abdominal com retalho muscular ou miocutâneo",
                        mod: "Reconstrução da parede abdominal com retalho muscular ou miocutâneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009263",
                        original: "Reparação de outras hérnias (inclui herniorrafia muscular)",
                        mod: "Reparação de outras hérnias (inclui herniorrafia muscular)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009271",
                        original: "Ressecção de cisto ou fístula de úraco",
                        mod: "Ressecção de cisto ou fístula de úraco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009280",
                        original: "Ressecção de cisto ou fístula ou restos do ducto onfalomesentérico",
                        mod: "Ressecção de cisto ou fístula ou restos do ducto onfalomesentérico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009298",
                        original: "Ressutura da parede abdominal (por deiscência total ou evisceração)",
                        mod: "Ressutura da parede abdominal (por deiscência total ou evisceração)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009301",
                        original: "Teratoma sacro-coccígeo - exérese",
                        mod: "Teratoma sacro-coccígeo - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009310",
                        original: "Herniorrafia com ressecção intestinal - estrangulada - por videolaparoscopia",
                        mod: "Herniorrafia com ressecção intestinal - estrangulada - por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009328",
                        original: "Herniorrafia crural - unilateral por videolaparoscopia",
                        mod: "Herniorrafia crural - unilateral por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009336",
                        original: "Herniorrafia inguinal - unilateral por videolaparoscopia",
                        mod: "Herniorrafia inguinal - unilateral por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009344",
                        original: "Herniorrafia recidivante por videolaparoscopia",
                        mod: "Herniorrafia recidivante por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009352",
                        original: "Laparotomia exploradora, ou para biópsia, ou para drenagem de abscesso, ou para liberação de bridas em vigência de oclusão por videolaparoscopia",
                        mod: "Laparotomia exploradora, ou para biópsia, ou para drenagem de abscesso, ou para liberação de bridas em vigência de oclusão por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31009360",
                        original: "Herniorrafia inguinal em criança - unilateral",
                        mod: "Herniorrafia inguinal em criança - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101011",
                        original: "Abscesso renal ou peri-renal - drenagem cirúrgica",
                        mod: "Abscesso renal ou peri-renal - drenagem cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101020",
                        original: "Abscesso renal ou peri-renal - drenagem percutânea",
                        mod: "Abscesso renal ou peri-renal - drenagem percutânea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101038",
                        original: "Adrenalectomia unilateral",
                        mod: "Adrenalectomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101046",
                        original: "Angioplastia renal unilateral a céu aberto",
                        mod: "Angioplastia renal unilateral a céu aberto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101054",
                        original: "Angioplastia renal unilateral transluminal",
                        mod: "Angioplastia renal unilateral transluminal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101062",
                        original: "Autotransplante renal unilateral",
                        mod: "Autotransplante renal unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101070",
                        original: "Biópsia renal cirúrgica unilateral",
                        mod: "Biópsia renal cirúrgica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101089",
                        original: "Cisto renal - escleroterapia percutânea - por cisto",
                        mod: "Cisto renal - escleroterapia percutânea - por cisto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101097",
                        original: "Endopielotomia percutânea unilateral",
                        mod: "Endopielotomia percutânea unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101100",
                        original: "Estenose de junção pieloureteral - tratamento cirúrgico",
                        mod: "Estenose de junção pieloureteral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101119",
                        original: "Fístula pielo-cutânea - tratamento cirúrgico",
                        mod: "Fístula pielo-cutânea - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101127",
                        original: "Lombotomia exploradora",
                        mod: "Lombotomia exploradora",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101135",
                        original: "Marsupialização de cistos renais unilateral",
                        mod: "Marsupialização de cistos renais unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101151",
                        original: "Nefrectomia parcial com ureterectomia",
                        mod: "Nefrectomia parcial com ureterectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101160",
                        original: "Nefrectomia parcial unilateral",
                        mod: "Nefrectomia parcial unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101178",
                        original: "Nefrectomia parcial unilateral extracorpórea",
                        mod: "Nefrectomia parcial unilateral extracorpórea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101186",
                        original: "Nefrectomia radical unilateral",
                        mod: "Nefrectomia radical unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101194",
                        original: "Nefrectomia total unilateral",
                        mod: "Nefrectomia total unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101208",
                        original: "Nefro ou pieloenterocistostomia unilateral",
                        mod: "Nefro ou pieloenterocistostomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101216",
                        original: "Nefrolitotomia anatrófica unilateral",
                        mod: "Nefrolitotomia anatrófica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101224",
                        original: "Nefrolitotomia percutânea unilateral",
                        mod: "Nefrolitotomia percutânea unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101232",
                        original: "Nefrolitotomia simples unilateral",
                        mod: "Nefrolitotomia simples unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101240",
                        original: "Nefrolitotripsia extracorpórea - 1ª sessão",
                        mod: "Nefrolitotripsia extracorpórea - 1ª sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101259",
                        original: "Nefrolitotripsia extracorpórea - reaplicações (até 3 meses)",
                        mod: "Nefrolitotripsia extracorpórea - reaplicações (até 3 meses)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101275",
                        original: "Nefrolitotripsia percutânea unilateral (MEC., E.H., ou US)",
                        mod: "Nefrolitotripsia percutânea unilateral (MEC., E.H., ou US)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101283",
                        original: "Nefropexia unilateral",
                        mod: "Nefropexia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101291",
                        original: "Nefrorrafia (trauma) unilateral",
                        mod: "Nefrorrafia (trauma) unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101305",
                        original: "Nefrostomia a céu aberto unilateral",
                        mod: "Nefrostomia a céu aberto unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101313",
                        original: "Nefrostomia percutânea unilateral",
                        mod: "Nefrostomia percutânea unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101321",
                        original: "Nefroureterectomia com ressecção vesical unilateral",
                        mod: "Nefroureterectomia com ressecção vesical unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101330",
                        original: "Pielolitotomia com nefrolitotomia anatrófica unilateral",
                        mod: "Pielolitotomia com nefrolitotomia anatrófica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101348",
                        original: "Pielolitotomia com nefrolitotomia simples unilateral",
                        mod: "Pielolitotomia com nefrolitotomia simples unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101356",
                        original: "Pielolitotomia unilateral",
                        mod: "Pielolitotomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101364",
                        original: "Pieloplastia",
                        mod: "Pieloplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101372",
                        original: "Pielostomia unilateral",
                        mod: "Pielostomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101380",
                        original: "Pielotomia exploradora unilateral",
                        mod: "Pielotomia exploradora unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101399",
                        original: "Punção aspirativa renal para diagnóstico de rejeição (ato médico)",
                        mod: "Punção aspirativa renal para diagnóstico de rejeição (ato médico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101402",
                        original: "Punção biópsia renal percutânea",
                        mod: "Punção biópsia renal percutânea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101410",
                        original: "Revascularização renal - qualquer técnica",
                        mod: "Revascularização renal - qualquer técnica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101429",
                        original: "Sinfisiotomia (rim em ferradura)",
                        mod: "Sinfisiotomia (rim em ferradura)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101437",
                        original: "Transuretero anastomose",
                        mod: "Transuretero anastomose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101445",
                        original: "Tratamento cirúrgico da fístula pielo-intestinal",
                        mod: "Tratamento cirúrgico da fístula pielo-intestinal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101453",
                        original: "Tumor renal - enucleação unilateral",
                        mod: "Tumor renal - enucleação unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101461",
                        original: "Tumor Wilms - tratamento cirúrgico",
                        mod: "Tumor Wilms - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101470",
                        original: "Tumores retro-peritoneais  malignos unilaterais - exérese",
                        mod: "Tumores retro-peritoneais  malignos unilaterais - exérese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101488",
                        original: "Adrenalectomia laparoscópica unilateral",
                        mod: "Adrenalectomia laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101496",
                        original: "Marsupialização laparoscópica de cisto renal unilateral",
                        mod: "Marsupialização laparoscópica de cisto renal unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101500",
                        original: "Biópsia renal laparoscópica unilateral",
                        mod: "Biópsia renal laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101518",
                        original: "Nefropexia laparoscópica unilateral",
                        mod: "Nefropexia laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101526",
                        original: "Pieloplastia laparoscópica unilateral",
                        mod: "Pieloplastia laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101534",
                        original: "Pielolitotomia laparoscópica unilateral",
                        mod: "Pielolitotomia laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101542",
                        original: "Nefroureterectomia com ressecção vesical laparoscópica unilateral",
                        mod: "Nefroureterectomia com ressecção vesical laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101550",
                        original: "Nefrectomia radical laparoscópica unilateral",
                        mod: "Nefrectomia radical laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101569",
                        original: "Nefrectomia parcial laparoscópica unilateral",
                        mod: "Nefrectomia parcial laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101577",
                        original: "Nefrolitotripsia percutânea unilateral a laser",
                        mod: "Nefrolitotripsia percutânea unilateral a laser",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101585",
                        original: "Nefrectomia total unilateral por videolaparoscopia",
                        mod: "Nefrectomia total unilateral por videolaparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31101593",
                        original: "Cisto de supra-renal - tratamento cirúrgico",
                        mod: "Cisto de supra-renal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102018",
                        original: "Biópsia cirúrgica de ureter unilateral",
                        mod: "Biópsia cirúrgica de ureter unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102026",
                        original: "Biópsia endoscópica de ureter unilateral",
                        mod: "Biópsia endoscópica de ureter unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102034",
                        original: "Cateterismo ureteral unilateral",
                        mod: "Cateterismo ureteral unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102042",
                        original: "Colocação cirúrgica de duplo J unilateral",
                        mod: "Colocação cirúrgica de duplo J unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102050",
                        original: "Colocação cistoscópica de duplo J unilateral",
                        mod: "Colocação cistoscópica de duplo J unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102069",
                        original: "Colocação nefroscópica de duplo J unilateral",
                        mod: "Colocação nefroscópica de duplo J unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102077",
                        original: "Colocação ureteroscópica de duplo J unilateral",
                        mod: "Colocação ureteroscópica de duplo J unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102085",
                        original: "Dilatação endoscópica unilateral",
                        mod: "Dilatação endoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102093",
                        original: "Duplicação pieloureteral - tratamento cirúrgico",
                        mod: "Duplicação pieloureteral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102093",
                        original: "Duplicação pieloureteral - tratamento cirúrgico - unilateral",
                        mod: "Duplicação pieloureteral - tratamento cirúrgico - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102107",
                        original: "Fístula uretero-cutânea unilateral (tratamento cirúrgico)",
                        mod: "Fístula uretero-cutânea unilateral (tratamento cirúrgico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102115",
                        original: "Fístula uretero-intestinal unilateral (tratamento cirúrgico)",
                        mod: "Fístula uretero-intestinal unilateral (tratamento cirúrgico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102123",
                        original: "Fístula uretero-vaginal unilateral (tratamento cirúrgico)",
                        mod: "Fístula uretero-vaginal unilateral (tratamento cirúrgico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102131",
                        original: "Meatotomia endoscópica unilateral",
                        mod: "Meatotomia endoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102174",
                        original: "Reimplante ureterointestinal uni ou bilateral",
                        mod: "Reimplante ureterointestinal uni ou bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102182",
                        original: "Reimplante ureteral por via extra ou intravesical unilateral",
                        mod: "Reimplante ureteral por via extra ou intravesical unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102204",
                        original: "Reimplante uretero-vesical unilateral - via combinada",
                        mod: "Reimplante uretero-vesical unilateral - via combinada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102220",
                        original: "Retirada endoscópica de cálculo de ureter unilateral",
                        mod: "Retirada endoscópica de cálculo de ureter unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102239",
                        original: "Transureterostomia",
                        mod: "Transureterostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102247",
                        original: "Ureterectomia unilateral",
                        mod: "Ureterectomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102255",
                        original: "Ureterocele unilateral - ressecção a céu aberto",
                        mod: "Ureterocele unilateral - ressecção a céu aberto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102263",
                        original: "Ureteroceles - tratamento endoscópico",
                        mod: "Ureteroceles - tratamento endoscópico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102263",
                        original: "Ureteroceles - tratamento endoscópico - unilateral",
                        mod: "Ureteroceles - tratamento endoscópico - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102271",
                        original: "Ureteroileocistostomia unilateral",
                        mod: "Ureteroileocistostomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102280",
                        original: "Ureteroileostomia cutânea unilateral",
                        mod: "Ureteroileostomia cutânea unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102298",
                        original: "Ureterólise unilateral",
                        mod: "Ureterólise unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102301",
                        original: "Ureterolitotomia unilateral",
                        mod: "Ureterolitotomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102310",
                        original: "Ureterolitotripsia extracorpórea - 1ª sessão",
                        mod: "Ureterolitotripsia extracorpórea - 1ª sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102328",
                        original: "Ureterolitotripsia extracorpórea - reaplicações (até 3 meses)",
                        mod: "Ureterolitotripsia extracorpórea - reaplicações (até 3 meses)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102344",
                        original: "Ureteroplastia unilateral",
                        mod: "Ureteroplastia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102352",
                        original: "Ureterorrenolitotomia unilateral",
                        mod: "Ureterorrenolitotomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102360",
                        original: "Ureterorrenolitotripsia flexível a laser unilateral",
                        mod: "Ureterorrenolitotripsia flexível a laser unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102379",
                        original: "Ureterorrenolitotripsia rígida unilateral",
                        mod: "Ureterorrenolitotripsia rígida unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102409",
                        original: "Ureterossigmoidoplastia unilateral",
                        mod: "Ureterossigmoidoplastia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102417",
                        original: "Ureterossigmoidostomia unilateral",
                        mod: "Ureterossigmoidostomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102425",
                        original: "Ureterostomia cutânea unilateral",
                        mod: "Ureterostomia cutânea unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102433",
                        original: "Ureterotomia interna percutânea unilateral",
                        mod: "Ureterotomia interna percutânea unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102441",
                        original: "Ureterotomia interna ureteroscópica flexível unilateral",
                        mod: "Ureterotomia interna ureteroscópica flexível unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102450",
                        original: "Ureterotomia interna ureteroscópica rígida unilateral",
                        mod: "Ureterotomia interna ureteroscópica rígida unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102468",
                        original: "Ureteroureterocistoneostomia",
                        mod: "Ureteroureterocistoneostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102476",
                        original: "Ureteroureterostomia unilateral",
                        mod: "Ureteroureterostomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102492",
                        original: "Ureterolitotomia laparoscópica unilateral",
                        mod: "Ureterolitotomia laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102506",
                        original: "Ureterólise laparoscópica unilateral",
                        mod: "Ureterólise laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102514",
                        original: "Ureteroureterostomia laparoscópica unilateral",
                        mod: "Ureteroureterostomia laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102522",
                        original: "Ureteroplastia laparoscópica unilateral",
                        mod: "Ureteroplastia laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102530",
                        original: "Correção laparoscópica de refluxo vesico-ureteral unilateral",
                        mod: "Correção laparoscópica de refluxo vesico-ureteral unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102549",
                        original: "Reimplante uretero-vesical laparoscópico unilateral",
                        mod: "Reimplante uretero-vesical laparoscópico unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102557",
                        original: "Reimplante ureterointestinal laparoscópico unilateral",
                        mod: "Reimplante ureterointestinal laparoscópico unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102565",
                        original: "Ureterorrenolitotripsia rígida unilateral a laser",
                        mod: "Ureterorrenolitotripsia rígida unilateral a laser",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102573",
                        original: "Ureteroenterostomia cutânea - unilateral",
                        mod: "Ureteroenterostomia cutânea - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102581",
                        original: "Ureterolitotripsia transureteroscópica",
                        mod: "Ureterolitotripsia transureteroscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102590",
                        original: "Refluxo vésico-ureteral - tratamento endoscópico",
                        mod: "Refluxo vésico-ureteral - tratamento endoscópico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31102590",
                        original: "Refluxo vésico-ureteral - tratamento endoscópico - unilateral",
                        mod: "Refluxo vésico-ureteral - tratamento endoscópico - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103014",
                        original: "Ampliação vesical",
                        mod: "Ampliação vesical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103022",
                        original: "Bexiga psóica - tratamento cirúrgico",
                        mod: "Bexiga psóica - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103030",
                        original: "Biópsia endoscópica de bexiga (inclui cistoscopia)",
                        mod: "Biópsia endoscópica de bexiga (inclui cistoscopia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103049",
                        original: "Biópsia vesical a céu aberto",
                        mod: "Biópsia vesical a céu aberto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103057",
                        original: "Cálculo vesical - extração endoscópica",
                        mod: "Cálculo vesical - extração endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103065",
                        original: "Cistectomia parcial",
                        mod: "Cistectomia parcial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103073",
                        original: "Cistectomia radical (inclui próstata ou útero)",
                        mod: "Cistectomia radical (inclui próstata ou útero)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103081",
                        original: "Cistectomia total",
                        mod: "Cistectomia total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103090",
                        original: "Cistolitotomia",
                        mod: "Cistolitotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103103",
                        original: "Cistolitotripsia extracorpórea - 1ª sessão",
                        mod: "Cistolitotripsia extracorpórea - 1ª sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103111",
                        original: "Cistolitotripsia extracorpórea - reaplicações (até 3 meses)",
                        mod: "Cistolitotripsia extracorpórea - reaplicações (até 3 meses)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103138",
                        original: "Cistolitotripsia percutânea (U.S., E.H., E.C.)",
                        mod: "Cistolitotripsia percutânea (U.S., E.H., E.C.)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103146",
                        original: "Cistolitotripsia transuretral (U.S., E.H., E.C.)",
                        mod: "Cistolitotripsia transuretral (U.S., E.H., E.C.)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103154",
                        original: "Cistoplastia redutora",
                        mod: "Cistoplastia redutora",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103162",
                        original: "Cistorrafia (trauma)",
                        mod: "Cistorrafia (trauma)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103170",
                        original: "Cistostomia cirúrgica",
                        mod: "Cistostomia cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103189",
                        original: "Cistostomia com procedimento endoscópico",
                        mod: "Cistostomia com procedimento endoscópico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103197",
                        original: "Cistostomia por punção com trocater",
                        mod: "Cistostomia por punção com trocater",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103200",
                        original: "Colo de divertículo - ressecção endoscópica",
                        mod: "Colo de divertículo - ressecção endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103219",
                        original: "Colo vesical - ressecção endoscópica",
                        mod: "Colo vesical - ressecção endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103227",
                        original: "Corpo estranho - extração cirúrgica",
                        mod: "Corpo estranho - extração cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103235",
                        original: "Corpo estranho - extração endoscópica",
                        mod: "Corpo estranho - extração endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103243",
                        original: "Diverticulectomia vesical",
                        mod: "Diverticulectomia vesical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103251",
                        original: "Enterocistoplastia (ampliação vesical)",
                        mod: "Enterocistoplastia (ampliação vesical)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103260",
                        original: "Extrofia em cloaca - tratamento cirúrgico",
                        mod: "Extrofia em cloaca - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103278",
                        original: "Extrofia vesical - tratamento cirúrgico",
                        mod: "Extrofia vesical - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103286",
                        original: "Fístula vésico-cutânea - tratamento cirúrgico",
                        mod: "Fístula vésico-cutânea - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103294",
                        original: "Fístula vésico-entérica - tratamento cirúrgico",
                        mod: "Fístula vésico-entérica - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103308",
                        original: "Fístula vésico-retal - tratamento cirúrgico",
                        mod: "Fístula vésico-retal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103316",
                        original: "Fístula vésico-uterina - tratamento cirúrgico",
                        mod: "Fístula vésico-uterina - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103324",
                        original: "Fístula vésico-vaginal - tratamento cirúrgico",
                        mod: "Fístula vésico-vaginal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103332",
                        original: "Incontinência urinária - 'sling' vaginal ou abdominal",
                        mod: "Incontinência urinária - 'sling' vaginal ou abdominal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103340",
                        original: "Incontinência urinária - suspensão endoscópica de colo",
                        mod: "Incontinência urinária - suspensão endoscópica de colo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103359",
                        original: "Incontinência urinária - tratamento cirúrgico supra-púbico",
                        mod: "Incontinência urinária - tratamento cirúrgico supra-púbico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103367",
                        original: "Incontinência urinária - tratamento endoscópico (injeção)",
                        mod: "Incontinência urinária - tratamento endoscópico (injeção)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103375",
                        original: "Incontinência urinária com colpoplastia anterior - tratamento cirúrgico (com ou sem uso de prótese)",
                        mod: "Incontinência urinária com colpoplastia anterior - tratamento cirúrgico (com ou sem uso de prótese)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103383",
                        original: "Pólipos vesicais - ressecção cirúrgica",
                        mod: "Pólipos vesicais - ressecção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103391",
                        original: "Pólipos vesicais - ressecção endoscópica",
                        mod: "Pólipos vesicais - ressecção endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103405",
                        original: "Punção e aspiração vesical",
                        mod: "Punção e aspiração vesical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103413",
                        original: "Reimplante uretero-vesical à Boari",
                        mod: "Reimplante uretero-vesical à Boari",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103430",
                        original: "Retenção por coágulo - aspiração vesical",
                        mod: "Retenção por coágulo - aspiração vesical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103448",
                        original: "Tumor vesical - fotocoagulação a laser",
                        mod: "Tumor vesical - fotocoagulação a laser",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103456",
                        original: "Tumor vesical - ressecção endoscópica",
                        mod: "Tumor vesical - ressecção endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103464",
                        original: "Vesicostomia cutânea",
                        mod: "Vesicostomia cutânea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103472",
                        original: "Retirada endoscópica de duplo J",
                        mod: "Retirada endoscópica de duplo J",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103480",
                        original: "Neobexiga cutânea continente",
                        mod: "Neobexiga cutânea continente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103499",
                        original: "Neobexiga retal continente",
                        mod: "Neobexiga retal continente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103502",
                        original: "Neobexiga uretral continente",
                        mod: "Neobexiga uretral continente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103510",
                        original: "Correção laparoscópica de incontinência urinária",
                        mod: "Correção laparoscópica de incontinência urinária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103529",
                        original: "Cistectomia parcial laparoscópica",
                        mod: "Cistectomia parcial laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103537",
                        original: "Cistectomia radical laparoscópica (inclui próstata ou útero)",
                        mod: "Cistectomia radical laparoscópica (inclui próstata ou útero)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103545",
                        original: "Neobexiga laparoscópica",
                        mod: "Neobexiga laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103553",
                        original: "Diverticulectomia vesical laparoscópica",
                        mod: "Diverticulectomia vesical laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103561",
                        original: "Cistolitotripsia a laser",
                        mod: "Cistolitotripsia a laser",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103570",
                        original: "Colo vesical - ressecção cirúrgica",
                        mod: "Colo vesical - ressecção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103588",
                        original: "Tumor vesical, ressecção a céu aberto",
                        mod: "Tumor vesical, ressecção a céu aberto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31103596",
                        original: "Tratamento da hiperatividade vesical: injeção intravesical de toxina botulínica",
                        mod: "Tratamento da hiperatividade vesical: injeção intravesical de toxina botulínica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104010",
                        original: "Abscesso periuretral - tratamento cirúrgico",
                        mod: "Abscesso periuretral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104029",
                        original: "Biópsia endoscópica de uretra",
                        mod: "Biópsia endoscópica de uretra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104037",
                        original: "Corpo estranho ou cálculo - extração cirúrgica",
                        mod: "Corpo estranho ou cálculo - extração cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104045",
                        original: "Corpo estranho ou cálculo - extração endoscópica",
                        mod: "Corpo estranho ou cálculo - extração endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104053",
                        original: "Divertículo uretral - tratamento cirúrgico",
                        mod: "Divertículo uretral - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104061",
                        original: "Eletrocoagulação endoscópica",
                        mod: "Eletrocoagulação endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104070",
                        original: "Esfincterotomia - uretra",
                        mod: "Esfincterotomia - uretra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104088",
                        original: "Fístula uretro-cutânea - correção cirúrgica",
                        mod: "Fístula uretro-cutânea - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104096",
                        original: "Fístula uretro-retal - correção cirúrgica",
                        mod: "Fístula uretro-retal - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104100",
                        original: "Fístula uretro-vaginal - correção cirúrgica",
                        mod: "Fístula uretro-vaginal - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104118",
                        original: "Incontinência urinária masculina - tratamento cirúrgico (exclui implante de esfincter artificial)",
                        mod: "Incontinência urinária masculina - tratamento cirúrgico (exclui implante de esfincter artificial)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104126",
                        original: "Injeções periuretrais (incluindo uretrocistocopia) por tratamento",
                        mod: "Injeções periuretrais (incluindo uretrocistocopia) por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104134",
                        original: "Meatoplastia (retalho cutâneo)",
                        mod: "Meatoplastia (retalho cutâneo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104142",
                        original: "Meatotomia uretral",
                        mod: "Meatotomia uretral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104150",
                        original: "Neouretra proximal (cistouretroplastia)",
                        mod: "Neouretra proximal (cistouretroplastia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104169",
                        original: "Ressecção de carúncula",
                        mod: "Ressecção de carúncula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104177",
                        original: "Ressecção de válvula uretral posterior",
                        mod: "Ressecção de válvula uretral posterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104185",
                        original: "Tumor uretral - excisão",
                        mod: "Tumor uretral - excisão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104193",
                        original: "Uretroplastia anterior",
                        mod: "Uretroplastia anterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104207",
                        original: "Uretroplastia posterior",
                        mod: "Uretroplastia posterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104215",
                        original: "Uretrostomia",
                        mod: "Uretrostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104223",
                        original: "Uretrotomia interna",
                        mod: "Uretrotomia interna",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104231",
                        original: "Uretrotomia interna com prótese endouretral",
                        mod: "Uretrotomia interna com prótese endouretral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104240",
                        original: "Uretrectomia total",
                        mod: "Uretrectomia total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104258",
                        original: "Ressecção de corda da uretra",
                        mod: "Ressecção de corda da uretra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104266",
                        original: "Uretrotomia externa para retirada de cálculo ou corpo estranho",
                        mod: "Uretrotomia externa para retirada de cálculo ou corpo estranho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104274",
                        original: "Incontinência urinária masculina - 'sling'",
                        mod: "Incontinência urinária masculina - 'sling'",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104274",
                        original: "Incontinência urinária masculina - 'sling' ou esfincter artificial",
                        mod: "Incontinência urinária masculina - 'sling' ou esfincter artificial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104282",
                        original: "Incontinência urinária masculina - esfincter artificial",
                        mod: "Incontinência urinária masculina - esfincter artificial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31104290",
                        original: "Retirada e/ou substituição de implante no tratamento da incontinência urinária masculina - esfincter artificial",
                        mod: "Retirada e/ou substituição de implante no tratamento da incontinência urinária masculina - esfincter artificial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201016",
                        original: "Ablação prostática a laser",
                        mod: "Ablação prostática a laser",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201024",
                        original: "Abscesso de próstata - drenagem",
                        mod: "Abscesso de próstata - drenagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201032",
                        original: "Biópsia prostática - até 8 fragmentos",
                        mod: "Biópsia prostática - até 8 fragmentos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201040",
                        original: "Biópsia prostática - mais de 8 fragmentos",
                        mod: "Biópsia prostática - mais de 8 fragmentos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201059",
                        original: "Eletrovaporização de próstata",
                        mod: "Eletrovaporização de próstata",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201067",
                        original: "Hemorragia da loja prostática - evacuação e irrigação",
                        mod: "Hemorragia da loja prostática - evacuação e irrigação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201075",
                        original: "Hemorragia da loja prostática - revisão endoscópica",
                        mod: "Hemorragia da loja prostática - revisão endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201083",
                        original: "Hipertrofia prostática - hipertermia ou termoterapia",
                        mod: "Hipertrofia prostática - hipertermia ou termoterapia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201091",
                        original: "Hipertrofia prostática - implante de prótese",
                        mod: "Hipertrofia prostática - implante de prótese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201105",
                        original: "Hipertrofia prostática - tratamento por dilatação",
                        mod: "Hipertrofia prostática - tratamento por dilatação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201113",
                        original: "Prostatavesiculectomia radical",
                        mod: "Prostatavesiculectomia radical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201121",
                        original: "Prostatectomia a céu aberto",
                        mod: "Prostatectomia a céu aberto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201130",
                        original: "Ressecção endoscópica da próstata",
                        mod: "Ressecção endoscópica da próstata",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201148",
                        original: "Prostatavesiculectomia radical laparoscópica",
                        mod: "Prostatavesiculectomia radical laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201156",
                        original: "Exérese laparoscópica de cisto de vesícula seminal unilateral",
                        mod: "Exérese laparoscópica de cisto de vesícula seminal unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31201164",
                        original: "Hipertrofia prostática - tratamento por diatemia",
                        mod: "Hipertrofia prostática - tratamento por diatemia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31202012",
                        original: "Biópsia escrotal",
                        mod: "Biópsia escrotal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31202020",
                        original: "Drenagem de abscesso - escroto",
                        mod: "Drenagem de abscesso - escroto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31202039",
                        original: "Elefantíase peno-escrotal - tratamento cirúrgico",
                        mod: "Elefantíase peno-escrotal - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31202047",
                        original: "Exérese de cisto escrotal",
                        mod: "Exérese de cisto escrotal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31202055",
                        original: "Plástica escrotal",
                        mod: "Plástica escrotal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31202063",
                        original: "Reconstrução da bolsa escrotal com retalho inguinal pediculado - por estágio",
                        mod: "Reconstrução da bolsa escrotal com retalho inguinal pediculado - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31202071",
                        original: "Ressecção parcial da bolsa escrotal",
                        mod: "Ressecção parcial da bolsa escrotal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203019",
                        original: "Autotransplante de um testículo",
                        mod: "Autotransplante de um testículo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203027",
                        original: "Biópsia unilateral de testículo",
                        mod: "Biópsia unilateral de testículo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203035",
                        original: "Escroto agudo - exploração cirúrgica",
                        mod: "Escroto agudo - exploração cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203043",
                        original: "Hidrocele unilateral - correção cirúrgica",
                        mod: "Hidrocele unilateral - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203051",
                        original: "Implante de prótese testicular unilateral",
                        mod: "Implante de prótese testicular unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203060",
                        original: "Orquidopexia unilateral",
                        mod: "Orquidopexia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203078",
                        original: "Orquiectomia unilateral",
                        mod: "Orquiectomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203086",
                        original: "Punção da vaginal",
                        mod: "Punção da vaginal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203094",
                        original: "Reparação plástica (trauma)",
                        mod: "Reparação plástica (trauma)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203108",
                        original: "Torção de testículo - cura cirúrgica",
                        mod: "Torção de testículo - cura cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203116",
                        original: "Tumor de testículo - ressecção",
                        mod: "Tumor de testículo - ressecção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203124",
                        original: "Varicocele unilateral - correção cirúrgica",
                        mod: "Varicocele unilateral - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203132",
                        original: "Orquidopexia laparoscópica unilateral",
                        mod: "Orquidopexia laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203140",
                        original: "Orquiectomia intra-abdominal laparoscópica unilateral",
                        mod: "Orquiectomia intra-abdominal laparoscópica unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31203159",
                        original: "Correção laparoscópica de varicocele unilateral",
                        mod: "Correção laparoscópica de varicocele unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31204015",
                        original: "Biópsia de epidídimo",
                        mod: "Biópsia de epidídimo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31204023",
                        original: "Drenagem de abscesso - epidídimo",
                        mod: "Drenagem de abscesso - epidídimo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31204031",
                        original: "Epididimectomia unilateral",
                        mod: "Epididimectomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31204040",
                        original: "Epididimovasoplastia unilateral",
                        mod: "Epididimovasoplastia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31204058",
                        original: "Epididimovasoplastia unilateral microcirúrgica",
                        mod: "Epididimovasoplastia unilateral microcirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31204066",
                        original: "Exérese de cisto unilateral",
                        mod: "Exérese de cisto unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31205011",
                        original: "Espermatocelectomia unilateral",
                        mod: "Espermatocelectomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31205020",
                        original: "Exploração cirúrgica do deferente unilateral",
                        mod: "Exploração cirúrgica do deferente unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31205038",
                        original: "Recanalização dos ductus deferentes",
                        mod: "Recanalização dos ductus deferentes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31205038",
                        original: "Recanalização dos ductus deferentes (microcirurgia)",
                        mod: "Recanalização dos ductus deferentes (microcirurgia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31205046",
                        original: "Vasectomia unilateral",
                        mod: "Vasectomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31205054",
                        original: "Vaso-vasostomia microcirúrgica unilateral (recanalização dos ductos deferentes)",
                        mod: "Vaso-vasostomia microcirúrgica unilateral (recanalização dos ductos deferentes)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31205070",
                        original: "Cirurgia esterilizadora masculina",
                        mod: "Cirurgia esterilizadora masculina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31205089",
                        original: "Vasostomia",
                        mod: "Vasostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206018",
                        original: "Amputação parcial",
                        mod: "Amputação parcial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206026",
                        original: "Amputação total",
                        mod: "Amputação total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206034",
                        original: "Biópsia peniana",
                        mod: "Biópsia peniana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206042",
                        original: "Doença de Peyronie - tratamento cirúrgico",
                        mod: "Doença de Peyronie - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206050",
                        original: "Eletrocoagulação de lesões cutâneas",
                        mod: "Eletrocoagulação de lesões cutâneas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206069",
                        original: "Emasculação",
                        mod: "Emasculação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206077",
                        original: "Epispadia - reconstrução por etapa",
                        mod: "Epispadia - reconstrução por etapa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206085",
                        original: "Epispadia com incontinência - tratamento cirúrgico",
                        mod: "Epispadia com incontinência - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206093",
                        original: "Fratura de pênis - tratamento cirúrgico",
                        mod: "Fratura de pênis - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206107",
                        original: "Hipospadia - por estágio - tratamento cirúrgico",
                        mod: "Hipospadia - por estágio - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206115",
                        original: "Hipospadia distal - tratamento em 1 tempo - tratamento cirúrgico",
                        mod: "Hipospadia distal - tratamento em 1 tempo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206123",
                        original: "Hipospadia proximal - tratamento em 1 tempo - tratamento cirúrgico",
                        mod: "Hipospadia proximal - tratamento em 1 tempo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206131",
                        original: "Implante de prótese inflável",
                        mod: "Implante de prótese inflável",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206140",
                        original: "Implante de prótese semi-rígida (exclui próteses infláveis)",
                        mod: "Implante de prótese semi-rígida (exclui próteses infláveis)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206158",
                        original: "Neofaloplastia - por estágio",
                        mod: "Neofaloplastia - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206166",
                        original: "Neofaloplastia com retalho inguinal pediculado com reconstrução uretral - por estágio",
                        mod: "Neofaloplastia com retalho inguinal pediculado com reconstrução uretral - por estágio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206174",
                        original: "Parafimose - redução manual ou cirúrgica",
                        mod: "Parafimose - redução manual ou cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206182",
                        original: "Pênis curvo congênito - tratamento cirúrgico",
                        mod: "Pênis curvo congênito - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206190",
                        original: "Plástica - retalho cutâneo à distância",
                        mod: "Plástica - retalho cutâneo à distância",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206204",
                        original: "Plástica de corpo cavernoso",
                        mod: "Plástica de corpo cavernoso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206212",
                        original: "Plástica do freio bálano-prepucial",
                        mod: "Plástica do freio bálano-prepucial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206220",
                        original: "Postectomia",
                        mod: "Postectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206239",
                        original: "Priapismo - tratamento cirúrgico",
                        mod: "Priapismo - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206247",
                        original: "Reconstrução de pênis com enxerto - plástica total",
                        mod: "Reconstrução de pênis com enxerto - plástica total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206255",
                        original: "Reimplante do pênis",
                        mod: "Reimplante do pênis",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31206263",
                        original: "Revascularização peniana",
                        mod: "Revascularização peniana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301010",
                        original: "Bartolinectomia unilateral",
                        mod: "Bartolinectomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301029",
                        original: "Biópsia de vulva",
                        mod: "Biópsia de vulva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301037",
                        original: "Cauterização química, ou eletrocauterização, ou criocauterização de lesões da vulva (por grupo de até 5 lesões)",
                        mod: "Cauterização química, ou eletrocauterização, ou criocauterização de lesões da vulva (por grupo de até 5 lesões)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301045",
                        original: "Clitorectomia (parcial ou total)",
                        mod: "Clitorectomia (parcial ou total)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301053",
                        original: "Clitoroplastia",
                        mod: "Clitoroplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301061",
                        original: "Excisão radical local da vulva (não inclui a linfadenectomia)",
                        mod: "Excisão radical local da vulva (não inclui a linfadenectomia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301070",
                        original: "Exérese de glândula de Skene",
                        mod: "Exérese de glândula de Skene",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301088",
                        original: "Exérese de lesão da vulva e/ou do períneo (por grupo de até 5 lesões)",
                        mod: "Exérese de lesão da vulva e/ou do períneo (por grupo de até 5 lesões)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301096",
                        original: "Hipertrofia dos pequenos lábios - correção cirúrgica",
                        mod: "Hipertrofia dos pequenos lábios - correção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301100",
                        original: "Incisão e drenagem da glândula de Bartholin ou Skene",
                        mod: "Incisão e drenagem da glândula de Bartholin ou Skene",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301118",
                        original: "Marsupialização da glândula de Bartholin",
                        mod: "Marsupialização da glândula de Bartholin",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301126",
                        original: "Vulvectomia ampliada (não inclui a linfadenectomia)",
                        mod: "Vulvectomia ampliada (não inclui a linfadenectomia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301134",
                        original: "Vulvectomia simples",
                        mod: "Vulvectomia simples",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31301142",
                        original: "Laserterapia, trato gential inferior feminino",
                        mod: "Laserterapia, trato gential inferior feminino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302017",
                        original: "Biópsia de vagina",
                        mod: "Biópsia de vagina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302025",
                        original: "Colpectomia",
                        mod: "Colpectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302033",
                        original: "Colpocleise (Lefort)",
                        mod: "Colpocleise (Lefort)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302041",
                        original: "Colpoplastia anterior",
                        mod: "Colpoplastia anterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302050",
                        original: "Colpoplastia posterior com perineorrafia",
                        mod: "Colpoplastia posterior com perineorrafia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302068",
                        original: "Colporrafia ou colpoperineoplastia incluindo ressecção de septo ou ressutura de parede vaginal",
                        mod: "Colporrafia ou colpoperineoplastia incluindo ressecção de septo ou ressutura de parede vaginal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302076",
                        original: "Colpotomia ou culdocentese",
                        mod: "Colpotomia ou culdocentese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302084",
                        original: "Exérese de cisto vaginal",
                        mod: "Exérese de cisto vaginal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302092",
                        original: "Extração de corpo estranho com anestesia geral ou bloqueio",
                        mod: "Extração de corpo estranho com anestesia geral ou bloqueio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302106",
                        original: "Fístula ginecológica - tratamento cirúrgico",
                        mod: "Fístula ginecológica - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302114",
                        original: "Himenotomia",
                        mod: "Himenotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302122",
                        original: "Neovagina (cólon, delgado, tubo de pele)",
                        mod: "Neovagina (cólon, delgado, tubo de pele)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31302130",
                        original: "Cauterização química, ou eletrocauterização, ou criocauterização de lesões da vagina (por grupo de até 5 lesões)",
                        mod: "Cauterização química, ou eletrocauterização, ou criocauterização de lesões da vagina (por grupo de até 5 lesões)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303013",
                        original: "Aspiração manual intra-uterina (AMIU)",
                        mod: "Aspiração manual intra-uterina (AMIU)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303021",
                        original: "Biópsia do colo uterino",
                        mod: "Biópsia do colo uterino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303030",
                        original: "Biópsia do endométrio",
                        mod: "Biópsia do endométrio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303056",
                        original: "Curetagem ginecológica semiótica e/ou terapêutica com ou sem dilatação de colo uterino",
                        mod: "Curetagem ginecológica semiótica e/ou terapêutica com ou sem dilatação de colo uterino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303064",
                        original: "Dilatação do colo uterino",
                        mod: "Dilatação do colo uterino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303072",
                        original: "Excisão de pólipo cervical",
                        mod: "Excisão de pólipo cervical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303080",
                        original: "Histerectomia subtotal com ou sem anexectomia, uni ou bilateral - qualquer via",
                        mod: "Histerectomia subtotal com ou sem anexectomia, uni ou bilateral - qualquer via",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303102",
                        original: "Histerectomia total - qualquer via",
                        mod: "Histerectomia total - qualquer via",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303110",
                        original: "Histerectomia total ampliada - qualquer via - (não inclui a linfadenectomia pélvica)",
                        mod: "Histerectomia total ampliada - qualquer via - (não inclui a linfadenectomia pélvica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303129",
                        original: "Histerectomia total com anexectomia uni ou bilateral - qualquer via",
                        mod: "Histerectomia total com anexectomia uni ou bilateral - qualquer via",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303137",
                        original: "Metroplastia (Strassmann ou outra técnica)",
                        mod: "Metroplastia (Strassmann ou outra técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303145",
                        original: "Miomectomia uterina",
                        mod: "Miomectomia uterina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303153",
                        original: "Traquelectomia - amputação, conização - (com ou sem cirurgia de alta frequência / CAF)",
                        mod: "Traquelectomia - amputação, conização - (com ou sem cirurgia de alta frequência / CAF)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303161",
                        original: "Traquelectomia radical (não inclui a linfadenectomia)",
                        mod: "Traquelectomia radical (não inclui a linfadenectomia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303170",
                        original: "Histeroscopia cirúrgica com biópsia e/ou curetagem uterina, lise de sinéquias, retirada de corpo estranho",
                        mod: "Histeroscopia cirúrgica com biópsia e/ou curetagem uterina, lise de sinéquias, retirada de corpo estranho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303188",
                        original: "Histeroscopia com ressectoscópio para miomectomia, polipectomia, metroplastia, endometrectomia e ressecção de sinéquias",
                        mod: "Histeroscopia com ressectoscópio para miomectomia, polipectomia, metroplastia, endometrectomia e ressecção de sinéquias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303196",
                        original: "Cauterização química, ou eletrocauterização, ou criocauterização de lesões de colo uterino (por sessão)",
                        mod: "Cauterização química, ou eletrocauterização, ou criocauterização de lesões de colo uterino (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303200",
                        original: "Histerectomia subtotal laparoscópica com ou sem anexectomia, uni ou bilateral - via alta",
                        mod: "Histerectomia subtotal laparoscópica com ou sem anexectomia, uni ou bilateral - via alta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303218",
                        original: "Histerectomia total laparoscópica",
                        mod: "Histerectomia total laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303226",
                        original: "Histerectomia total laparoscópica ampliada",
                        mod: "Histerectomia total laparoscópica ampliada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303234",
                        original: "Histerectomia total laparoscópica com anexectomia uni ou bilateral",
                        mod: "Histerectomia total laparoscópica com anexectomia uni ou bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303242",
                        original: "Metroplastia laparoscópica",
                        mod: "Metroplastia laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303250",
                        original: "Miomectomia uterina laparoscópica",
                        mod: "Miomectomia uterina laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303269",
                        original: "Implante de dispositivo intra-uterino (DIU) não hormonal",
                        mod: "Implante de dispositivo intra-uterino (DIU) não hormonal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303285",
                        original: "Histerectomia puerperal",
                        mod: "Histerectomia puerperal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303293",
                        original: "Implante de dispositivo intra-uterino (DIU) hormonal",
                        mod: "Implante de dispositivo intra-uterino (DIU) hormonal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303307",
                        original: "Retirada de DIU por histeroscopia",
                        mod: "Retirada de DIU por histeroscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303315",
                        original: "Curetagem uterina pós-parto",
                        mod: "Curetagem uterina pós-parto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31303323",
                        original: "Histerectomia pós-parto",
                        mod: "Histerectomia pós-parto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31304010",
                        original: "Esterilização tubária",
                        mod: "Esterilização tubária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31304010",
                        original: "Cirurgia de esterilização feminina (laqueadura tubárica convencional)",
                        mod: "Cirurgia de esterilização feminina (laqueadura tubárica convencional)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31304028",
                        original: "Neossalpingostomia distal",
                        mod: "Neossalpingostomia distal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31304036",
                        original: "Recanalização tubária (qualquer técnica), uni ou bilateral (com microscópio ou lupa)",
                        mod: "Recanalização tubária (qualquer técnica), uni ou bilateral (com microscópio ou lupa)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31304044",
                        original: "Salpingectomia uni ou bilateral",
                        mod: "Salpingectomia uni ou bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31304052",
                        original: "Laqueadura tubária laparoscópica",
                        mod: "Laqueadura tubária laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31304052",
                        original: "Cirurgia de esterilização feminina (laqueadura tubárica laparoscópica)",
                        mod: "Cirurgia de esterilização feminina (laqueadura tubárica laparoscópica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31304060",
                        original: "Neossalpingostomia distal laparoscópica",
                        mod: "Neossalpingostomia distal laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31304079",
                        original: "Recanalização tubária laparoscópica uni ou bilateral",
                        mod: "Recanalização tubária laparoscópica uni ou bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31304087",
                        original: "Salpingectomia uni ou bilateral laparoscópica",
                        mod: "Salpingectomia uni ou bilateral laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31304095",
                        original: "Implante de dispositivo intratubário não hormonal",
                        mod: "Implante de dispositivo intratubário não hormonal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31305016",
                        original: "Ooforectomia uni ou bilateral ou ooforoplastia uni ou bilateral",
                        mod: "Ooforectomia uni ou bilateral ou ooforoplastia uni ou bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31305024",
                        original: "Translocação de ovários",
                        mod: "Translocação de ovários",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31305032",
                        original: "Ooforectomia laparoscópica uni ou bilateral ou ooforoplastia uni ou bilateral",
                        mod: "Ooforectomia laparoscópica uni ou bilateral ou ooforoplastia uni ou bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31306012",
                        original: "Correção de defeito lateral",
                        mod: "Correção de defeito lateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31306020",
                        original: "Correção de enterocele",
                        mod: "Correção de enterocele",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31306039",
                        original: "Correção de rotura perineal de III  grau  (com lesão  do  esfincter)  e  reconstituição  por  plástica - qualquer técnica",
                        mod: "Correção de rotura perineal de III  grau  (com lesão  do  esfincter)  e  reconstituição  por  plástica - qualquer técnica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31306047",
                        original: "Perineorrafia (não obstétrica) e/ou episiotomia e/ou episiorrafia",
                        mod: "Perineorrafia (não obstétrica) e/ou episiotomia e/ou episiorrafia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31306055",
                        original: "Reconstrução perineal com retalhos miocutâneos",
                        mod: "Reconstrução perineal com retalhos miocutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31306063",
                        original: "Ressecção de tumor do septo reto-vaginal",
                        mod: "Ressecção de tumor do septo reto-vaginal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31306071",
                        original: "Seio urogenital - plástica",
                        mod: "Seio urogenital - plástica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31306080",
                        original: "Retração cicraticial perineal",
                        mod: "Retração cicraticial perineal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307019",
                        original: "Câncer de ovário (Debulking)",
                        mod: "Câncer de ovário (Debulking)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307027",
                        original: "Cirurgia (via alta  ou  baixa)  do  prolápso  de  cúpula  vaginal (fixação  sacral  ou  no  ligamento sacro-espinhoso) qualquer técnica",
                        mod: "Cirurgia (via alta  ou  baixa)  do  prolápso  de  cúpula  vaginal (fixação  sacral  ou  no  ligamento sacro-espinhoso) qualquer técnica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307035",
                        original: "Culdoplastia (Mac Call, Moschowicz, etc.)",
                        mod: "Culdoplastia (Mac Call, Moschowicz, etc.)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307043",
                        original: "Endometriose peritonial - tratamento cirúrgico",
                        mod: "Endometriose peritonial - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307051",
                        original: "Epiploplastia ou aplicação de membranas antiaderentes",
                        mod: "Epiploplastia ou aplicação de membranas antiaderentes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307060",
                        original: "Laparoscopia ginecológica com ou sem biópsia (inclui a cromotubagem)",
                        mod: "Laparoscopia ginecológica com ou sem biópsia (inclui a cromotubagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307078",
                        original: "Liberação de aderências pélvicas com ou sem ressecção de cistos peritoniais ou salpingólise",
                        mod: "Liberação de aderências pélvicas com ou sem ressecção de cistos peritoniais ou salpingólise",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307086",
                        original: "Ligadura de veia ovariana",
                        mod: "Ligadura de veia ovariana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307094",
                        original: "Ligamentopexia pélvica",
                        mod: "Ligamentopexia pélvica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307108",
                        original: "Neurectomia pré-sacral ou do nervo gênito-femoral",
                        mod: "Neurectomia pré-sacral ou do nervo gênito-femoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307116",
                        original: "Omentectomia",
                        mod: "Omentectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307124",
                        original: "Ressecção de tumor de parede abdominal pélvica",
                        mod: "Ressecção de tumor de parede abdominal pélvica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307132",
                        original: "Ressecção ou ligadura de varizes pélvicas",
                        mod: "Ressecção ou ligadura de varizes pélvicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307140",
                        original: "Secção de ligamentos útero-sacros",
                        mod: "Secção de ligamentos útero-sacros",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307159",
                        original: "Câncer de ovário (Debulking) laparoscópica",
                        mod: "Câncer de ovário (Debulking) laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307167",
                        original: "Cirurgia laparoscópica do prolapso de cúpula vaginal (fixação sacral ou no ligamento sacro-espinhoso)",
                        mod: "Cirurgia laparoscópica do prolapso de cúpula vaginal (fixação sacral ou no ligamento sacro-espinhoso)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307175",
                        original: "Culdoplastia laparoscópica (Mac Call, Moschowicz, etc)",
                        mod: "Culdoplastia laparoscópica (Mac Call, Moschowicz, etc)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307183",
                        original: "Endometriose peritoneal - tratamento cirúrgico via laparoscópica",
                        mod: "Endometriose peritoneal - tratamento cirúrgico via laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307191",
                        original: "Epiploplastia ou aplicação de membranas antiaderentes via laparoscópica",
                        mod: "Epiploplastia ou aplicação de membranas antiaderentes via laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307205",
                        original: "Liberação laparoscópica de aderências pélvicas com ou sem ressecção de cistos peritoneais ou salpingólise",
                        mod: "Liberação laparoscópica de aderências pélvicas com ou sem ressecção de cistos peritoneais ou salpingólise",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307213",
                        original: "Ligadura de veia ovariana laparoscópica",
                        mod: "Ligadura de veia ovariana laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307221",
                        original: "Ligamentopexia pélvica laparoscópica",
                        mod: "Ligamentopexia pélvica laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307230",
                        original: "Neurectomia laparoscópica pré-sacral ou do nervo gênito-femoral",
                        mod: "Neurectomia laparoscópica pré-sacral ou do nervo gênito-femoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307248",
                        original: "Omentectomia laparoscópica",
                        mod: "Omentectomia laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307256",
                        original: "Ressecção laparoscópica de tumor de parede abdominal",
                        mod: "Ressecção laparoscópica de tumor de parede abdominal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307264",
                        original: "Ressecção ou ligadura laparoscópica de varizes pélvicas",
                        mod: "Ressecção ou ligadura laparoscópica de varizes pélvicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307272",
                        original: "Secção laparoscópica de ligamentos útero-sacros",
                        mod: "Secção laparoscópica de ligamentos útero-sacros",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31307280",
                        original: "Endometriose  - tratamento cirúrgico via laparoscópica",
                        mod: "Endometriose  - tratamento cirúrgico via laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31308015",
                        original: "Aspiração de folículos para fertilização",
                        mod: "Aspiração de folículos para fertilização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31308023",
                        original: "GIFT (transferência de gametas para as trompas)",
                        mod: "GIFT (transferência de gametas para as trompas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31308031",
                        original: "Inseminação artificial",
                        mod: "Inseminação artificial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31308040",
                        original: "Transferência de embrião para o útero",
                        mod: "Transferência de embrião para o útero",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309011",
                        original: "Amniorredução ou amnioinfusão",
                        mod: "Amniorredução ou amnioinfusão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309020",
                        original: "Aspiração manual intra-uterina (AMIU) pós-abortamento",
                        mod: "Aspiração manual intra-uterina (AMIU) pós-abortamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309038",
                        original: "Assistência ao trabalho de parto, por hora (até o limite de 6 horas). Não deverá ser considerado se o parto ocorrer na primeira hora após o início da assistência. Após a primeira hora, além da assistência, remunera-se o parto (via baixa ou cesariana).",
                        mod: "Assistência ao trabalho de parto, por hora (até o limite de 6 horas). Não deverá ser considerado se o parto ocorrer na primeira hora após o início da assistência. Após a primeira hora, além da assistência, remunera-se o parto (via baixa ou cesariana).",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309046",
                        original: "Cerclagem do colo uterino (qualquer técnica)",
                        mod: "Cerclagem do colo uterino (qualquer técnica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309054",
                        original: "Cesariana",
                        mod: "Cesariana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309062",
                        original: "Curetagem pós-abortamento",
                        mod: "Curetagem pós-abortamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309070",
                        original: "Derivações em cirurgia fetal",
                        mod: "Derivações em cirurgia fetal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309089",
                        original: "Gravidez  ectópica - cirurgia",
                        mod: "Gravidez  ectópica - cirurgia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309097",
                        original: "Maturação cervical para indução de abortamento ou de trabalho de parto",
                        mod: "Maturação cervical para indução de abortamento ou de trabalho de parto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309100",
                        original: "Inversão uterina aguda - redução manual",
                        mod: "Inversão uterina aguda - redução manual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309119",
                        original: "Inversão uterina - tratamento cirúrgico",
                        mod: "Inversão uterina - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309127",
                        original: "Parto (via vaginal)",
                        mod: "Parto (via vaginal)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309135",
                        original: "Parto múltiplo (cada um subsequente ao inicial)",
                        mod: "Parto múltiplo (cada um subsequente ao inicial)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309143",
                        original: "Punção escalpofetal para avaliação PH fetal",
                        mod: "Punção escalpofetal para avaliação PH fetal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309151",
                        original: "Revisão obstétrica de parto ocorrido fora do hospital (inclui exame, dequitação e sutura de lacerações até de 2º grau)",
                        mod: "Revisão obstétrica de parto ocorrido fora do hospital (inclui exame, dequitação e sutura de lacerações até de 2º grau)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309178",
                        original: "Versão cefálica externa",
                        mod: "Versão cefálica externa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309186",
                        original: "Gravidez ectópica - cirurgia laparoscópica",
                        mod: "Gravidez ectópica - cirurgia laparoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309194",
                        original: "Inversão uterina - tratamento cirúrgico laparoscópico",
                        mod: "Inversão uterina - tratamento cirúrgico laparoscópico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309208",
                        original: "Cesariana com histerectomia",
                        mod: "Cesariana com histerectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309216",
                        original: "Cirurgia fetal guiada por ultrassonografia",
                        mod: "Cirurgia fetal guiada por ultrassonografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309224",
                        original: "Cirurgia fetal endoscópica (guiada por ultrassonografia e fetoscópio)",
                        mod: "Cirurgia fetal endoscópica (guiada por ultrassonografia e fetoscópio)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309232",
                        original: "Intervenção do obstetra na cirurgia fetal a céu aberto",
                        mod: "Intervenção do obstetra na cirurgia fetal a céu aberto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31309259",
                        original: "Amniodrenagem ou amnioinfusão guiadas por ultrassonografia",
                        mod: "Amniodrenagem ou amnioinfusão guiadas por ultrassonografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401015",
                        original: "Biópsia estereotáxica de encéfalo",
                        mod: "Biópsia estereotáxica de encéfalo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401023",
                        original: "Cingulotomia ou capsulotomia unilateral",
                        mod: "Cingulotomia ou capsulotomia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401031",
                        original: "Cirurgia intracraniana por via endoscópica",
                        mod: "Cirurgia intracraniana por via endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401040",
                        original: "Craniotomia para remoção de corpo estranho",
                        mod: "Craniotomia para remoção de corpo estranho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401058",
                        original: "Derivação ventricular externa",
                        mod: "Derivação ventricular externa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401066",
                        original: "Drenagem estereotáxica - cistos, hematomas ou abscessos",
                        mod: "Drenagem estereotáxica - cistos, hematomas ou abscessos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401074",
                        original: "Hipofisectomia por qualquer método",
                        mod: "Hipofisectomia por qualquer método",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401082",
                        original: "Implante de cateter intracraniano",
                        mod: "Implante de cateter intracraniano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401090",
                        original: "Implante de eletrodo cerebral profundo",
                        mod: "Implante de eletrodo cerebral profundo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401104",
                        original: "Implante de eletrodos cerebral ou medular",
                        mod: "Implante de eletrodos cerebral ou medular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401112",
                        original: "Implante estereotáxico de cateter para braquiterapia",
                        mod: "Implante estereotáxico de cateter para braquiterapia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401120",
                        original: "Implante intratecal de bombas para infusão de fármacos",
                        mod: "Implante intratecal de bombas para infusão de fármacos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401139",
                        original: "Localização estereotáxica de corpo estranho intracraniano com remoção",
                        mod: "Localização estereotáxica de corpo estranho intracraniano com remoção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401147",
                        original: "Localização estereotáxica de lesões intracranianas com remoção",
                        mod: "Localização estereotáxica de lesões intracranianas com remoção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401155",
                        original: "Microcirurgia para tumores intracranianos",
                        mod: "Microcirurgia para tumores intracranianos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401163",
                        original: "Microcirurgia por via transesfenoidal",
                        mod: "Microcirurgia por via transesfenoidal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401171",
                        original: "Microcirurgia vascular intracraniana",
                        mod: "Microcirurgia vascular intracraniana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401198",
                        original: "Punção subdural ou ventricular transfontanela",
                        mod: "Punção subdural ou ventricular transfontanela",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401201",
                        original: "Ressecção de mucocele frontal",
                        mod: "Ressecção de mucocele frontal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401228",
                        original: "Revisão de sistema de neuroestimulação",
                        mod: "Revisão de sistema de neuroestimulação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401236",
                        original: "Sistema de derivação ventricular interna com válvulas ou revisões",
                        mod: "Sistema de derivação ventricular interna com válvulas ou revisões",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401244",
                        original: "Terceiro ventriculostomia",
                        mod: "Terceiro ventriculostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401252",
                        original: "Tratamento cirúrgico da epilepsia",
                        mod: "Tratamento cirúrgico da epilepsia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401260",
                        original: "Tratamento cirúrgico da fístula liquórica",
                        mod: "Tratamento cirúrgico da fístula liquórica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401279",
                        original: "Tratamento cirúrgico da meningoencefalocele",
                        mod: "Tratamento cirúrgico da meningoencefalocele",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401287",
                        original: "Tratamento cirúrgico de tumores cerebrais sem microscopia",
                        mod: "Tratamento cirúrgico de tumores cerebrais sem microscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401295",
                        original: "Tratamento cirúrgico do abscesso encefálico",
                        mod: "Tratamento cirúrgico do abscesso encefálico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401309",
                        original: "Tratamento cirúrgico do hematoma intracraniano",
                        mod: "Tratamento cirúrgico do hematoma intracraniano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401333",
                        original: "Tratamento pré-natal das hidrocefalias e cistos cerebrais",
                        mod: "Tratamento pré-natal das hidrocefalias e cistos cerebrais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401341",
                        original: "Acesso endoscópico ao tratamento cirúrgico dos tumores da região selar",
                        mod: "Acesso endoscópico ao tratamento cirúrgico dos tumores da região selar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401350",
                        original: "Implantação de halo para radiocirurgia",
                        mod: "Implantação de halo para radiocirurgia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401368",
                        original: "Craniectomia para tumores cerebelares",
                        mod: "Craniectomia para tumores cerebelares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401376",
                        original: "Craniotomia exploradora com ou sem biópsia",
                        mod: "Craniotomia exploradora com ou sem biópsia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401384",
                        original: "Traumatismo cranioencefálico - tratamento cirúrgico",
                        mod: "Traumatismo cranioencefálico - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401392",
                        original: "Trepanação para propedêutica neurocirúrgica",
                        mod: "Trepanação para propedêutica neurocirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31401406",
                        original: "Tumores extracranianos - tratamento cirúrgico",
                        mod: "Tumores extracranianos - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31402011",
                        original: "Cordotomia-mielotomias por radiofrequência",
                        mod: "Cordotomia-mielotomias por radiofrequência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31402020",
                        original: "Lesão de substância gelatinosa medular (DREZ) por radiofrequência",
                        mod: "Lesão de substância gelatinosa medular (DREZ) por radiofrequência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31402038",
                        original: "Tampão sanguíneo peridural para tratamento de cefaléia após punção (não indicada na profilaxia da cefaléia)",
                        mod: "Tampão sanguíneo peridural para tratamento de cefaléia após punção (não indicada na profilaxia da cefaléia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403018",
                        original: "Biópsia de nervo",
                        mod: "Biópsia de nervo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403026",
                        original: "Bloqueio de nervo periférico - nervos periféricos",
                        mod: "Bloqueio de nervo periférico - nervos periféricos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403034",
                        original: "Denervação percutânea de faceta articular - por segmento",
                        mod: "Denervação percutânea de faceta articular - por segmento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403042",
                        original: "Enxerto de nervo",
                        mod: "Enxerto de nervo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403050",
                        original: "Enxerto de nervo interfascicular, pediculado (1º estágio)",
                        mod: "Enxerto de nervo interfascicular, pediculado (1º estágio)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403069",
                        original: "Enxerto de nervo interfascicular, pediculado (2º estágio)",
                        mod: "Enxerto de nervo interfascicular, pediculado (2º estágio)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403077",
                        original: "Enxerto interfascicular de nervo vascularizado",
                        mod: "Enxerto interfascicular de nervo vascularizado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403085",
                        original: "Enxerto interfascicular",
                        mod: "Enxerto interfascicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403093",
                        original: "Enxerto para reparo de 2 ou mais nervos",
                        mod: "Enxerto para reparo de 2 ou mais nervos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403107",
                        original: "Excisão de tumores de nervos periféricos com enxerto interfascicular",
                        mod: "Excisão de tumores de nervos periféricos com enxerto interfascicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403115",
                        original: "Excisão de tumores dos nervos periféricos",
                        mod: "Excisão de tumores dos nervos periféricos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403123",
                        original: "Exploração cirúrgica de nervo (neurólise externa)",
                        mod: "Exploração cirúrgica de nervo (neurólise externa)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403131",
                        original: "Extirpação de neuroma",
                        mod: "Extirpação de neuroma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403140",
                        original: "Implante de gerador para neuroestimulação",
                        mod: "Implante de gerador para neuroestimulação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403158",
                        original: "Lesão de nervos associada à lesão óssea - tratamento cirúrgico",
                        mod: "Lesão de nervos associada à lesão óssea - tratamento cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403166",
                        original: "Lesão estereotáxica de estruturas profundas para tratamento da dor ou movimento anormal",
                        mod: "Lesão estereotáxica de estruturas profundas para tratamento da dor ou movimento anormal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403174",
                        original: "Microcirurgia do plexo braquial com a exploração, neurólise e enxertos interfasciculares para reparo das lesões",
                        mod: "Microcirurgia do plexo braquial com a exploração, neurólise e enxertos interfasciculares para reparo das lesões",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403182",
                        original: "Microcirurgia do plexo braquial com exploração e neurólise",
                        mod: "Microcirurgia do plexo braquial com exploração e neurólise",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403204",
                        original: "Microneurólise intraneural ou intrafascicular de um nervo",
                        mod: "Microneurólise intraneural ou intrafascicular de um nervo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403212",
                        original: "Microneurólise intraneural ou intrafascicular de dois ou mais nervos",
                        mod: "Microneurólise intraneural ou intrafascicular de dois ou mais nervos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403220",
                        original: "Microneurólise múltiplas",
                        mod: "Microneurólise múltiplas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403239",
                        original: "Microneurólise única",
                        mod: "Microneurólise única",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403255",
                        original: "Microneurorrafia de dedos da mão",
                        mod: "Microneurorrafia de dedos da mão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403263",
                        original: "Microneurorrafia múltipla (plexo nervoso)",
                        mod: "Microneurorrafia múltipla (plexo nervoso)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403271",
                        original: "Microneurorrafia única",
                        mod: "Microneurorrafia única",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403280",
                        original: "Neurólise das síndromes compressivas",
                        mod: "Neurólise das síndromes compressivas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403298",
                        original: "Neurotripsia (cada extremidade)",
                        mod: "Neurotripsia (cada extremidade)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403301",
                        original: "Reposição de fármaco(s) em bombas implantadas",
                        mod: "Reposição de fármaco(s) em bombas implantadas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403310",
                        original: "Ressecção de neuroma",
                        mod: "Ressecção de neuroma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403328",
                        original: "Revisão de sistema implantados para infusão de fármacos",
                        mod: "Revisão de sistema implantados para infusão de fármacos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403336",
                        original: "Rizotomia percutânea por segmento - qualquer método",
                        mod: "Rizotomia percutânea por segmento - qualquer método",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403344",
                        original: "Simpatectomia",
                        mod: "Simpatectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403352",
                        original: "Transposição de nervo",
                        mod: "Transposição de nervo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403360",
                        original: "Tratamento microcirúrgico das neuropatias compressivas (tumoral, inflamatório, etc)",
                        mod: "Tratamento microcirúrgico das neuropatias compressivas (tumoral, inflamatório, etc)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403379",
                        original: "Simpatectomia por videotoracoscopia",
                        mod: "Simpatectomia por videotoracoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31403387",
                        original: "Neurotomia",
                        mod: "Neurotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31404014",
                        original: "Descompressão vascular de nervos cranianos",
                        mod: "Descompressão vascular de nervos cranianos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31404022",
                        original: "Neurotomia seletiva do trigêmio",
                        mod: "Neurotomia seletiva do trigêmio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31404030",
                        original: "Tratamento de nevralgia do trigêmio por técnica cirúrgica percutânea - qualquer método (quando orientado por imagem, cobrar código correspondente)",
                        mod: "Tratamento de nevralgia do trigêmio por técnica cirúrgica percutânea - qualquer método (quando orientado por imagem, cobrar código correspondente)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31405010",
                        original: "Bloqueio do sistema nervoso autônomo",
                        mod: "Bloqueio do sistema nervoso autônomo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31405029",
                        original: "Tratamento cirúrgico de lesão do sistema nervoso autônomo - qualquer método",
                        mod: "Tratamento cirúrgico de lesão do sistema nervoso autônomo - qualquer método",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31405037",
                        original: "Tratamento cirúrgico da síndrome do desfiladeiro cérvico torácico",
                        mod: "Tratamento cirúrgico da síndrome do desfiladeiro cérvico torácico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31501010",
                        original: "Transplante de córnea",
                        mod: "Transplante de córnea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31501010",
                        original: "Transplante penetrante da córnea",
                        mod: "Transplante penetrante da córnea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31501028",
                        original: "Retirada para transplante - córnea",
                        mod: "Retirada para transplante - córnea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31502016",
                        original: "Transplante cardíaco (doador)",
                        mod: "Transplante cardíaco (doador)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31502024",
                        original: "Transplante cardíaco (receptor)",
                        mod: "Transplante cardíaco (receptor)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31503012",
                        original: "Transplante cardiopulmonar (doador)",
                        mod: "Transplante cardiopulmonar (doador)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31503020",
                        original: "Transplante cardiopulmonar (receptor)",
                        mod: "Transplante cardiopulmonar (receptor)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31504019",
                        original: "Transplante pulmonar (doador)",
                        mod: "Transplante pulmonar (doador)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31504027",
                        original: "Transplante pulmonar unilateral (receptor)",
                        mod: "Transplante pulmonar unilateral (receptor)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31505015",
                        original: "Transplante hepático (receptor)",
                        mod: "Transplante hepático (receptor)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31505023",
                        original: "Transplante hepático (doador)",
                        mod: "Transplante hepático (doador)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31506011",
                        original: "Transplante renal (receptor)",
                        mod: "Transplante renal (receptor)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31506038",
                        original: "Nefrectomia em doador vivo - para transplante",
                        mod: "Nefrectomia em doador vivo - para transplante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31506046",
                        original: "Nefrectomia laparoscópica em doador vivo - para transplante",
                        mod: "Nefrectomia laparoscópica em doador vivo - para transplante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31507018",
                        original: "Transplante pancreático (receptor)",
                        mod: "Transplante pancreático (receptor)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31507026",
                        original: "Transplante pancreático (doador)",
                        mod: "Transplante pancreático (doador)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31601014",
                        original: "Acupuntura por sessão",
                        mod: "Acupuntura por sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602010",
                        original: "Analgesia controlada pelo paciente - por dia subsequente",
                        mod: "Analgesia controlada pelo paciente - por dia subsequente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602029",
                        original: "Analgesia por dia subsequente. Acompanhamento de analgesia por cateter peridural",
                        mod: "Analgesia por dia subsequente. Acompanhamento de analgesia por cateter peridural",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602037",
                        original: "Anestesia geral ou condutiva para realização de bloqueio neurolítico",
                        mod: "Anestesia geral ou condutiva para realização de bloqueio neurolítico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602045",
                        original: "Bloqueio anestésico de nervos cranianos",
                        mod: "Bloqueio anestésico de nervos cranianos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602053",
                        original: "Bloqueio anestésico de plexo celíaco",
                        mod: "Bloqueio anestésico de plexo celíaco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602061",
                        original: "Bloqueio anestésico de simpático lombar",
                        mod: "Bloqueio anestésico de simpático lombar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602070",
                        original: "Bloqueio anestésico simpático",
                        mod: "Bloqueio anestésico simpático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602088",
                        original: "Bloqueio de articulação têmporo-mandibular",
                        mod: "Bloqueio de articulação têmporo-mandibular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602096",
                        original: "Bloqueio de gânglio estrelado com anestésico local",
                        mod: "Bloqueio de gânglio estrelado com anestésico local",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602100",
                        original: "Bloqueio de gânglio estrelado com neurolítico",
                        mod: "Bloqueio de gânglio estrelado com neurolítico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602118",
                        original: "Bloqueio de nervo periférico - bloqueios anestésicos de nervos e estímulos neurovasculares",
                        mod: "Bloqueio de nervo periférico - bloqueios anestésicos de nervos e estímulos neurovasculares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602126",
                        original: "Bloqueio facetário para-espinhoso",
                        mod: "Bloqueio facetário para-espinhoso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602134",
                        original: "Bloqueio neurolítico de nervos cranianos ou cérvico-torácico",
                        mod: "Bloqueio neurolítico de nervos cranianos ou cérvico-torácico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602142",
                        original: "Bloqueio neurolítico do plexo celíaco, simpático lombar ou torácico",
                        mod: "Bloqueio neurolítico do plexo celíaco, simpático lombar ou torácico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602150",
                        original: "Bloqueio neurolítico peridural ou subaracnóideo",
                        mod: "Bloqueio neurolítico peridural ou subaracnóideo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602169",
                        original: "Bloqueio peridural ou subaracnóideo com corticóide",
                        mod: "Bloqueio peridural ou subaracnóideo com corticóide",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602177",
                        original: "Bloqueio simpático por via venosa",
                        mod: "Bloqueio simpático por via venosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602185",
                        original: "Estimulação elétrica transcutânea",
                        mod: "Estimulação elétrica transcutânea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602207",
                        original: "Instalação de bomba de infusão para analgesia em dor aguda ou crônica, por qualquer via",
                        mod: "Instalação de bomba de infusão para analgesia em dor aguda ou crônica, por qualquer via",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602215",
                        original: "Laser - por sessão",
                        mod: "Laser - por sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602223",
                        original: "Passagem de catéter peridural ou subaracnóideo com bloqueio de prova",
                        mod: "Passagem de catéter peridural ou subaracnóideo com bloqueio de prova",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602231",
                        original: "Anestesia para endoscopia diagnóstica",
                        mod: "Anestesia para endoscopia diagnóstica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602240",
                        original: "Anestesia para endoscopia intervencionista",
                        mod: "Anestesia para endoscopia intervencionista",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602258",
                        original: "Anestesia para exames radiológicos de angiorradiologia",
                        mod: "Anestesia para exames radiológicos de angiorradiologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602266",
                        original: "Anestesia para exames de ultrassonografia",
                        mod: "Anestesia para exames de ultrassonografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602274",
                        original: "Anestesia para exames de tomografia computadorizada ",
                        mod: "Anestesia para exames de tomografia computadorizada ",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602282",
                        original: "Anestesia para exames de ressonância magnética ",
                        mod: "Anestesia para exames de ressonância magnética ",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602290",
                        original: "Anestesia para procedimentos de radioterapia",
                        mod: "Anestesia para procedimentos de radioterapia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602304",
                        original: "Anestesia para exames específicos, teste para diagnóstico e outros procedimentos diagnósticos",
                        mod: "Anestesia para exames específicos, teste para diagnóstico e outros procedimentos diagnósticos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602312",
                        original: "Anestesia para procedimentos clínicos ambulatoriais e hospitalares",
                        mod: "Anestesia para procedimentos clínicos ambulatoriais e hospitalares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602320",
                        original: "Anestesia para procedimentos de medicina nuclear",
                        mod: "Anestesia para procedimentos de medicina nuclear",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "31602339",
                        original: "Bloqueio anestésico de plexos nervosos (lombossacro, braquial, cervical) para tratamento de dor",
                        mod: "Bloqueio anestésico de plexos nervosos (lombossacro, braquial, cervical) para tratamento de dor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40101010",
                        original: "ECG convencional de até 12 derivações",
                        mod: "ECG convencional de até 12 derivações",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40101029",
                        original: "ECG de alta resolução",
                        mod: "ECG de alta resolução",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40101037",
                        original: "Teste ergométrico computadorizado (inclui ECG basal convencional)",
                        mod: "Teste ergométrico computadorizado (inclui ECG basal convencional)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40101045",
                        original: "Teste ergométrico convencional - 3 ou mais derivações simultâneas (inclui ECG basal convencional)",
                        mod: "Teste ergométrico convencional - 3 ou mais derivações simultâneas (inclui ECG basal convencional)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40101053",
                        original: "Variabilidade da frequência cardíaca",
                        mod: "Variabilidade da frequência cardíaca",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40101061",
                        original: "Ergoespirometria ou teste cardiopulmonar de exercício completo (espirometria forçada, consumo de O2, produção de CO2 e derivados, ECG, oximetria)",
                        mod: "Ergoespirometria ou teste cardiopulmonar de exercício completo (espirometria forçada, consumo de O2, produção de CO2 e derivados, ECG, oximetria)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102017",
                        original: "Bilimetria gástrica ou esofágica de 24 horas",
                        mod: "Bilimetria gástrica ou esofágica de 24 horas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102025",
                        original: "Manometria computadorizada anorretal",
                        mod: "Manometria computadorizada anorretal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102033",
                        original: "Manometria computadorizada anorretal para biofeedback - 1ª sessão",
                        mod: "Manometria computadorizada anorretal para biofeedback - 1ª sessão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102041",
                        original: "Manometria computadorizada anorretal para biofeedback - demais sessões",
                        mod: "Manometria computadorizada anorretal para biofeedback - demais sessões",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102050",
                        original: "Manometria esofágica computadorizada com teste provocativo",
                        mod: "Manometria esofágica computadorizada com teste provocativo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102068",
                        original: "Manometria esofágica computadorizada sem teste provocativo",
                        mod: "Manometria esofágica computadorizada sem teste provocativo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102076",
                        original: "Manometria esofágica para localização dos esfíncteres pré-pH-metria",
                        mod: "Manometria esofágica para localização dos esfíncteres pré-pH-metria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102084",
                        original: "pH-metria esofágica computadorizada com um canal",
                        mod: "pH-metria esofágica computadorizada com um canal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102092",
                        original: "pH-metria esofágica computadorizada com dois canais",
                        mod: "pH-metria esofágica computadorizada com dois canais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102106",
                        original: "pH-metria esofágica computadorizada com três canais",
                        mod: "pH-metria esofágica computadorizada com três canais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102114",
                        original: "Impedâncio pH-metria esofágica",
                        mod: "Impedâncio pH-metria esofágica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102122",
                        original: "pH-metria gástrica de 24 horas com quatro canais",
                        mod: "pH-metria gástrica de 24 horas com quatro canais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102130",
                        original: "pH-metria esofágica de 24 horas com quatro canais",
                        mod: "pH-metria esofágica de 24 horas com quatro canais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40102149",
                        original: "Manometria Biliar",
                        mod: "Manometria Biliar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103013",
                        original: "Análise computadorizada da voz",
                        mod: "Análise computadorizada da voz",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103021",
                        original: "Análise computadorizada de papila e/ou fibras nervosas - monocular (GDX)",
                        mod: "Análise computadorizada de papila e/ou fibras nervosas - monocular (GDX)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103030",
                        original: "Análise computadorizada do segmento anterior - monocular",
                        mod: "Análise computadorizada do segmento anterior - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103048",
                        original: "Audiometria (tipo Von Bekesy)",
                        mod: "Audiometria (tipo Von Bekesy)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103056",
                        original: "Potencial evocado estacionário (Steady State)",
                        mod: "Potencial evocado estacionário (Steady State)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103064",
                        original: "Audiometria de tronco cerebral (PEA) BERA",
                        mod: "Audiometria de tronco cerebral (PEA) BERA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103072",
                        original: "Audiometria tonal limiar com testes de discriminação",
                        mod: "Audiometria tonal limiar com testes de discriminação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103080",
                        original: "Audiometria tonal limiar infantil condicionada (qualquer técnica) - Peep-show",
                        mod: "Audiometria tonal limiar infantil condicionada (qualquer técnica) - Peep-show",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103099",
                        original: "Audiometria vocal - pesquisa de limiar de discriminação",
                        mod: "Audiometria vocal - pesquisa de limiar de discriminação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103102",
                        original: "Audiometria vocal - pesquisa de limiar de inteligibilidade",
                        mod: "Audiometria vocal - pesquisa de limiar de inteligibilidade",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103110",
                        original: "Audiometria vocal com mensagem competitiva (SSI, SSW)",
                        mod: "Audiometria vocal com mensagem competitiva (SSI, SSW)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103129",
                        original: "Avaliação neurofisiológica  da função  sexual (inclui eletroneuromiografia de MMII, RBC, NCDP, PEGC)",
                        mod: "Avaliação neurofisiológica  da função  sexual (inclui eletroneuromiografia de MMII, RBC, NCDP, PEGC)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103137",
                        original: "Campimetria computadorizada - monocular",
                        mod: "Campimetria computadorizada - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103145",
                        original: "Variação de contingente negativo (PE/Tardio)",
                        mod: "Variação de contingente negativo (PE/Tardio)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103153",
                        original: "Craniocorporografia",
                        mod: "Craniocorporografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103161",
                        original: "Decay do reflexo estapédico",
                        mod: "Decay do reflexo estapédico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103170",
                        original: "EEG de rotina",
                        mod: "EEG de rotina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103188",
                        original: "EEG intra-operatório para monitorização cirúrgica (EEG/IO) - por hora de monitorização",
                        mod: "EEG intra-operatório para monitorização cirúrgica (EEG/IO) - por hora de monitorização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103196",
                        original: "EEGQ quantitativo (mapeamento cerebral)",
                        mod: "EEGQ quantitativo (mapeamento cerebral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103200",
                        original: "Eletrencefalograma especial: terapia intensiva, morte encefálica, EEG prolongado (até 2 horas)",
                        mod: "Eletrencefalograma especial: terapia intensiva, morte encefálica, EEG prolongado (até 2 horas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103234",
                        original: "Eletrencefalograma em vigília, e sono espontâneo ou induzido",
                        mod: "Eletrencefalograma em vigília, e sono espontâneo ou induzido",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103242",
                        original: "Eletro-oculografia - monocular",
                        mod: "Eletro-oculografia - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103250",
                        original: "Eletro-retinografia - monocular",
                        mod: "Eletro-retinografia - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103269",
                        original: "Eletrococleografia (Ecochg)",
                        mod: "Eletrococleografia (Ecochg)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103277",
                        original: "Eletrocorticografia intra-operatória (ECOG) - por hora de monitorização",
                        mod: "Eletrocorticografia intra-operatória (ECOG) - por hora de monitorização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103285",
                        original: "Eletroglotografia",
                        mod: "Eletroglotografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103307",
                        original: "Eletroneuromiografia (velocidade de condução) testes de estímulos para paralisia facial",
                        mod: "Eletroneuromiografia (velocidade de condução) testes de estímulos para paralisia facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103315",
                        original: "Eletroneuromiografia de MMII",
                        mod: "Eletroneuromiografia de MMII",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103323",
                        original: "Eletroneuromiografia de MMSS",
                        mod: "Eletroneuromiografia de MMSS",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103331",
                        original: "Eletroneuromiografia de MMSS e MMII",
                        mod: "Eletroneuromiografia de MMSS e MMII",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103340",
                        original: "Eletroneuromiografia de segmento complementar",
                        mod: "Eletroneuromiografia de segmento complementar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103358",
                        original: "Eletroneuromiografia de segmento especial",
                        mod: "Eletroneuromiografia de segmento especial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103366",
                        original: "Eletroneuromiografia genitoperineal",
                        mod: "Eletroneuromiografia genitoperineal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103374",
                        original: "EMG com registro de movimento involuntário (teste dinâmico de escrita; estudo funcional de tremores)",
                        mod: "EMG com registro de movimento involuntário (teste dinâmico de escrita; estudo funcional de tremores)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103382",
                        original: "EMG para monitoração de quimodenervação (por sessão)",
                        mod: "EMG para monitoração de quimodenervação (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103390",
                        original: "EMG quantitativa ou EMG de fibra única",
                        mod: "EMG quantitativa ou EMG de fibra única",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103404",
                        original: "Espectrografia vocal",
                        mod: "Espectrografia vocal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103412",
                        original: "Gustometria",
                        mod: "Gustometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103420",
                        original: "Imitanciometria de alta frequência",
                        mod: "Imitanciometria de alta frequência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103439",
                        original: "Impedanciometria",
                        mod: "Impedanciometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103439",
                        original: "Impedanciometria - timpanometria",
                        mod: "Impedanciometria - timpanometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103447",
                        original: "Método de Proetz (por sessão)",
                        mod: "Método de Proetz (por sessão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103455",
                        original: "Otoemissões acústicas produto de distorção",
                        mod: "Otoemissões acústicas produto de distorção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103463",
                        original: "Otoemissões evocadas transientes",
                        mod: "Otoemissões evocadas transientes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103471",
                        original: "Perimetria com scanning laser ophthalmoscope - monocular",
                        mod: "Perimetria com scanning laser ophthalmoscope - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103480",
                        original: "Pesquisa de pares cranianos relacionados com o VIII PAR",
                        mod: "Pesquisa de pares cranianos relacionados com o VIII PAR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103498",
                        original: "Potencial evocado auditivo de tronco cerebral (PEA-TC)",
                        mod: "Potencial evocado auditivo de tronco cerebral (PEA-TC)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103501",
                        original: "Pesquisa do fenômeno de Tullio",
                        mod: "Pesquisa do fenômeno de Tullio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103510",
                        original: "Poligrafia de recém-nascido (maior ou igual 2 horas) (PG/RN)",
                        mod: "Poligrafia de recém-nascido (maior ou igual 2 horas) (PG/RN)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103528",
                        original: "Polissonografia de noite inteira (PSG) (inclui polissonogramas)",
                        mod: "Polissonografia de noite inteira (PSG) (inclui polissonogramas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103536",
                        original: "Polissonograma com EEG de noite inteira",
                        mod: "Polissonograma com EEG de noite inteira",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103544",
                        original: "Polissonograma com teste de CPAP nasal",
                        mod: "Polissonograma com teste de CPAP nasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103544",
                        original: "Polissonograma com teste de CPAP/BIPAP nasal",
                        mod: "Polissonograma com teste de CPAP/BIPAP nasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103552",
                        original: "Posturografia",
                        mod: "Posturografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103560",
                        original: "Potencial evocado - P300",
                        mod: "Potencial evocado - P300",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103579",
                        original: "Potencial evocado auditivo de média latência (PEA-ML) bilateral",
                        mod: "Potencial evocado auditivo de média latência (PEA-ML) bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103587",
                        original: "Potencial somato-sensitivo para localização funcional da área central (monitorização por hora) até 3 horas",
                        mod: "Potencial somato-sensitivo para localização funcional da área central (monitorização por hora) até 3 horas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103595",
                        original: "Potencial evocado gênito-cortical (PEGC)",
                        mod: "Potencial evocado gênito-cortical (PEGC)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103609",
                        original: "Potencial evocado motor - PEM (bilateral)",
                        mod: "Potencial evocado motor - PEM (bilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103617",
                        original: "Potencial evocado somato-sensitivo - membros inferiores (PESS)",
                        mod: "Potencial evocado somato-sensitivo - membros inferiores (PESS)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103625",
                        original: "Potencial evocado somato-sensitivo - membros superiores (PESS)",
                        mod: "Potencial evocado somato-sensitivo - membros superiores (PESS)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103633",
                        original: "Potencial evocado visual (PEV)",
                        mod: "Potencial evocado visual (PEV)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103641",
                        original: "Provas de função tubária",
                        mod: "Provas de função tubária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103650",
                        original: "Registro do nistagmo pendular",
                        mod: "Registro do nistagmo pendular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103668",
                        original: "Rinomanometria computadorizada",
                        mod: "Rinomanometria computadorizada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103676",
                        original: "Rinometria acústica",
                        mod: "Rinometria acústica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103684",
                        original: "Reflexo cutâneo-simpático",
                        mod: "Reflexo cutâneo-simpático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103714",
                        original: "Teste de estimulação repetitiva (um ou mais músculos)",
                        mod: "Teste de estimulação repetitiva (um ou mais músculos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103722",
                        original: "Teste de fístula perilinfática com eletronistagmografia",
                        mod: "Teste de fístula perilinfática com eletronistagmografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103730",
                        original: "Teste de latências múltiplas de sono (TLMS) diurno pós PSG",
                        mod: "Teste de latências múltiplas de sono (TLMS) diurno pós PSG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103749",
                        original: "Vectoeletronistagmografia - computadorizada",
                        mod: "Vectoeletronistagmografia - computadorizada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103757",
                        original: "Vídeo-eletrencefalografia contínua não invasiva - 12 horas (vídeo EEG/NT)",
                        mod: "Vídeo-eletrencefalografia contínua não invasiva - 12 horas (vídeo EEG/NT)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103765",
                        original: "Videonistagmografia infravermelha",
                        mod: "Videonistagmografia infravermelha",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103781",
                        original: "Audiometria ocupacional ou de seleção",
                        mod: "Audiometria ocupacional ou de seleção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103790",
                        original: "Audiometrias de altas frequências",
                        mod: "Audiometrias de altas frequências",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103803",
                        original: "Avaliação da função auditiva central",
                        mod: "Avaliação da função auditiva central",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103811",
                        original: "Eletrodiagnóstico",
                        mod: "Eletrodiagnóstico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103820",
                        original: "Pesquisa do nistagmo optocinético",
                        mod: "Pesquisa do nistagmo optocinético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103838",
                        original: "Prova de Doerfler-Stewart",
                        mod: "Prova de Doerfler-Stewart",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103846",
                        original: "Prova de Stenger",
                        mod: "Prova de Stenger",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103854",
                        original: "Teste de Fowler",
                        mod: "Teste de Fowler",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103862",
                        original: "Eletroencefalograma com eletrodos especiais",
                        mod: "Eletroencefalograma com eletrodos especiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103870",
                        original: "Potencial evocado do nervo trigêmeo",
                        mod: "Potencial evocado do nervo trigêmeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103889",
                        original: "Processamento auditivo central infantil (de 3 a 7 anos)",
                        mod: "Processamento auditivo central infantil (de 3 a 7 anos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40103897",
                        original: "Processamento auditivo central (a partir dos 7 anos e adulto)",
                        mod: "Processamento auditivo central (a partir dos 7 anos e adulto)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40104010",
                        original: "Avaliação muscular por dinamometria computadorizada (isocinética) - por articulação",
                        mod: "Avaliação muscular por dinamometria computadorizada (isocinética) - por articulação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40104028",
                        original: "Cronaximetria",
                        mod: "Cronaximetria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40104036",
                        original: "Curva  I/T - medida de latência de nervo periférico",
                        mod: "Curva  I/T - medida de latência de nervo periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40104044",
                        original: "Ergotonometria músculo-esquelético (tetra, paraparesia e hemiparesia)",
                        mod: "Ergotonometria músculo-esquelético (tetra, paraparesia e hemiparesia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40104125",
                        original: "Sistema tridimensional de avaliação do movimento que inclui vídeo acoplado à plataforma da força e eletromiografia",
                        mod: "Sistema tridimensional de avaliação do movimento que inclui vídeo acoplado à plataforma da força e eletromiografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105016",
                        original: "Determinação das pressões respiratórias máximas",
                        mod: "Determinação das pressões respiratórias máximas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105024",
                        original: "Determinação dos volumes pulmonares por diluição de gases",
                        mod: "Determinação dos volumes pulmonares por diluição de gases",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105032",
                        original: "Determinação dos volumes pulmonares por pletismografia",
                        mod: "Determinação dos volumes pulmonares por pletismografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105040",
                        original: "Medida da difusão do monóxido de carbono",
                        mod: "Medida da difusão do monóxido de carbono",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105059",
                        original: "Medida de pico de fluxo expiratório",
                        mod: "Medida de pico de fluxo expiratório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105067",
                        original: "Medida seriada por 3 semanas do pico de fluxo expiratório",
                        mod: "Medida seriada por 3 semanas do pico de fluxo expiratório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105075",
                        original: "Prova de função pulmonar completa (ou espirometria)",
                        mod: "Prova de função pulmonar completa (ou espirometria)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105083",
                        original: "Resistência das vias aéreas por oscilometria",
                        mod: "Resistência das vias aéreas por oscilometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105091",
                        original: "Resistência das vias aéreas por pletismografia",
                        mod: "Resistência das vias aéreas por pletismografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105105",
                        original: "Espirografia Simples - capacidade vital lenta",
                        mod: "Espirografia Simples - capacidade vital lenta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105113",
                        original: "Regulação ventilatória - 1) medida de ventilação e do padrão ventilatório",
                        mod: "Regulação ventilatória - 1) medida de ventilação e do padrão ventilatório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105121",
                        original: "Regulação ventilatória - 2) determinação da pressão de oclusão",
                        mod: "Regulação ventilatória - 2) determinação da pressão de oclusão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105130",
                        original: "Regulação ventilatória - 3) resposta a hipoxia e hipercapnia",
                        mod: "Regulação ventilatória - 3) resposta a hipoxia e hipercapnia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40105148",
                        original: "Espirometria",
                        mod: "Espirometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201015",
                        original: "Amnioscopia",
                        mod: "Amnioscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201023",
                        original: "Anuscopia (interna e externa)",
                        mod: "Anuscopia (interna e externa)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201031",
                        original: "Broncoscopia com biópsia transbrônquica",
                        mod: "Broncoscopia com biópsia transbrônquica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201058",
                        original: "Broncoscopia com ou sem aspirado ou lavado brônquico bilateral",
                        mod: "Broncoscopia com ou sem aspirado ou lavado brônquico bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201066",
                        original: "Cistoscopia e/ou uretroscopia",
                        mod: "Cistoscopia e/ou uretroscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201074",
                        original: "Colangiopancreatografia retrógrada endoscópica",
                        mod: "Colangiopancreatografia retrógrada endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201082",
                        original: "Colonoscopia (inclui a retossigmoidoscopia)",
                        mod: "Colonoscopia (inclui a retossigmoidoscopia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201090",
                        original: "Colonoscopia com magnificação",
                        mod: "Colonoscopia com magnificação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201104",
                        original: "Ecoendoscopia alta",
                        mod: "Ecoendoscopia alta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201112",
                        original: "Ecoendoscopia baixa",
                        mod: "Ecoendoscopia baixa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201120",
                        original: "Endoscopia digestiva alta",
                        mod: "Endoscopia digestiva alta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201139",
                        original: "Endoscopia digestiva alta com magnificação",
                        mod: "Endoscopia digestiva alta com magnificação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201147",
                        original: "Enteroscopia",
                        mod: "Enteroscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201155",
                        original: "Histeroscopia diagnóstica com biópsia",
                        mod: "Histeroscopia diagnóstica com biópsia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201163",
                        original: "Laparoscopia",
                        mod: "Laparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201171",
                        original: "Retossigmoidoscopia flexível",
                        mod: "Retossigmoidoscopia flexível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201180",
                        original: "Retossigmoidoscopia rígida",
                        mod: "Retossigmoidoscopia rígida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201198",
                        original: "Vídeo-endoscopia do esfíncter velo-palatino com ótica flexível",
                        mod: "Vídeo-endoscopia do esfíncter velo-palatino com ótica flexível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201201",
                        original: "Vídeo-endoscopia do esfíncter velo-palatino com ótica rígida",
                        mod: "Vídeo-endoscopia do esfíncter velo-palatino com ótica rígida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201210",
                        original: "Vídeo-endoscopia naso-sinusal com ótica flexível",
                        mod: "Vídeo-endoscopia naso-sinusal com ótica flexível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201228",
                        original: "Vídeo-endoscopia naso-sinusal com ótica rígida",
                        mod: "Vídeo-endoscopia naso-sinusal com ótica rígida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201236",
                        original: "Vídeo-laringo-estroboscopia com endoscópio flexível",
                        mod: "Vídeo-laringo-estroboscopia com endoscópio flexível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201244",
                        original: "Vídeo-laringo-estroboscopia com endoscópio rígido",
                        mod: "Vídeo-laringo-estroboscopia com endoscópio rígido",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201252",
                        original: "Vídeo-faringo-laringoscopia com endoscópio flexível",
                        mod: "Vídeo-faringo-laringoscopia com endoscópio flexível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201260",
                        original: "Vídeo-faringo-laringoscopia com endoscópio rígido",
                        mod: "Vídeo-faringo-laringoscopia com endoscópio rígido",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201279",
                        original: "Ureteroscopia flexível unilateral",
                        mod: "Ureteroscopia flexível unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201287",
                        original: "Ureteroscopia rígida unilateral",
                        mod: "Ureteroscopia rígida unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201309",
                        original: "Avaliação endoscópica da deglutição (FEES)",
                        mod: "Avaliação endoscópica da deglutição (FEES)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201317",
                        original: "Medida de pressão de varizes de esôfago endoscópica",
                        mod: "Medida de pressão de varizes de esôfago endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201325",
                        original: "Videoquimografia laríngea",
                        mod: "Videoquimografia laríngea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201333",
                        original: "Endoscopia digestiva alta com cromoscopia",
                        mod: "Endoscopia digestiva alta com cromoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201341",
                        original: "Enteroscopia do intestino delgado com cápsula endoscópica",
                        mod: "Enteroscopia do intestino delgado com cápsula endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201350",
                        original: "Colonoscopia com cromoscopia",
                        mod: "Colonoscopia com cromoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201368",
                        original: "Broncoscopia com cromoscopia",
                        mod: "Broncoscopia com cromoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201376",
                        original: "Aplicação de plasma de argônio por endoscopia digestiva alta",
                        mod: "Aplicação de plasma de argônio por endoscopia digestiva alta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201384",
                        original: "Aplicação de plasma de argônio por broncoscopia",
                        mod: "Aplicação de plasma de argônio por broncoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201392",
                        original: "Aplicação de plasma de argônio por colonoscopia",
                        mod: "Aplicação de plasma de argônio por colonoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40201406",
                        original: "Aplicação de plasma de argônio por sigmoidoscopia",
                        mod: "Aplicação de plasma de argônio por sigmoidoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202011",
                        original: "Aritenoidectomia microcirúrgica endoscópica",
                        mod: "Aritenoidectomia microcirúrgica endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202038",
                        original: "Endoscopia digestiva alta com biópsia e/ou citologia",
                        mod: "Endoscopia digestiva alta com biópsia e/ou citologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202046",
                        original: "Biópsias por laparoscopia",
                        mod: "Biópsias por laparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202054",
                        original: "Broncoscopia com biópsia transbrônquica com acompanhamento radioscópico",
                        mod: "Broncoscopia com biópsia transbrônquica com acompanhamento radioscópico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202062",
                        original: "Cecostomia",
                        mod: "Cecostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202070",
                        original: "Cistoenterostomia com colocação de prótese ou dreno",
                        mod: "Cistoenterostomia com colocação de prótese ou dreno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202089",
                        original: "Colagem de fístula por via endoscópica",
                        mod: "Colagem de fístula por via endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202097",
                        original: "Colocação de cânula sob orientação endoscópica",
                        mod: "Colocação de cânula sob orientação endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202100",
                        original: "Colocação de cateter para braquiterapia endobrônquica",
                        mod: "Colocação de cateter para braquiterapia endobrônquica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202119",
                        original: "Colocação de prótese coledociana por via endoscópica",
                        mod: "Colocação de prótese coledociana por via endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202127",
                        original: "Colocação de prótese traqueal ou brônquica",
                        mod: "Colocação de prótese traqueal ou brônquica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202135",
                        original: "Colonoscopia com magnificação e tatuagem",
                        mod: "Colonoscopia com magnificação e tatuagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202143",
                        original: "Descompressão colônica por colonoscopia",
                        mod: "Descompressão colônica por colonoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202151",
                        original: "Desobstrução brônquica com laser ou eletrocautério",
                        mod: "Desobstrução brônquica com laser ou eletrocautério",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202160",
                        original: "Desobstrução brônquica por broncoaspiração",
                        mod: "Desobstrução brônquica por broncoaspiração",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202178",
                        original: "Dilatação de estenose laringo-traqueo-brônquica",
                        mod: "Dilatação de estenose laringo-traqueo-brônquica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202186",
                        original: "Dilatação instrumental do esôfago, estômago ou duodeno",
                        mod: "Dilatação instrumental do esôfago, estômago ou duodeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202194",
                        original: "Dilatação instrumental e injeção de substância medicamentosa por endoscopia",
                        mod: "Dilatação instrumental e injeção de substância medicamentosa por endoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202208",
                        original: "Diverticulotomia - aparelho digestivo",
                        mod: "Diverticulotomia - aparelho digestivo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202216",
                        original: "Drenagem cavitária por laparoscopia",
                        mod: "Drenagem cavitária por laparoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202224",
                        original: "Ecoendoscopia com cistoenterostomia",
                        mod: "Ecoendoscopia com cistoenterostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202232",
                        original: "Ecoendoscopia com neurólise de plexo celíaco",
                        mod: "Ecoendoscopia com neurólise de plexo celíaco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202240",
                        original: "Ecoendoscopia com punção por agulha",
                        mod: "Ecoendoscopia com punção por agulha",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202259",
                        original: "Esclerose de varizes de esôfago, estômago ou duodeno",
                        mod: "Esclerose de varizes de esôfago, estômago ou duodeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202267",
                        original: "Estenostomia endoscópica",
                        mod: "Estenostomia endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202283",
                        original: "Gastrostomia endoscópica",
                        mod: "Gastrostomia endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202291",
                        original: "Hemostasia mecânica do esôfago, estômago ou duodeno",
                        mod: "Hemostasia mecânica do esôfago, estômago ou duodeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202305",
                        original: "Hemostasia térmica por endoscopia",
                        mod: "Hemostasia térmica por endoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202313",
                        original: "Hemostasias de cólon",
                        mod: "Hemostasias de cólon",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202330",
                        original: "Injeção de substância medicamentosa por endoscopia",
                        mod: "Injeção de substância medicamentosa por endoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202348",
                        original: "Introdução de prótese no esôfago",
                        mod: "Introdução de prótese no esôfago",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202356",
                        original: "Jejunostomia endoscópica",
                        mod: "Jejunostomia endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202364",
                        original: "Laringoscopia com microscopia para exérese de pólipo/nódulo/papiloma",
                        mod: "Laringoscopia com microscopia para exérese de pólipo/nódulo/papiloma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202372",
                        original: "Laringoscopia com retirada de corpo estranho de laringe/faringe (tubo flexível)",
                        mod: "Laringoscopia com retirada de corpo estranho de laringe/faringe (tubo flexível)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202399",
                        original: "Laringoscopia/traqueoscopia com exérese de pólipo/nódulo/papiloma",
                        mod: "Laringoscopia/traqueoscopia com exérese de pólipo/nódulo/papiloma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202410",
                        original: "Laringoscopia/traqueoscopia com retirada de corpo estranho (tubo rígido)",
                        mod: "Laringoscopia/traqueoscopia com retirada de corpo estranho (tubo rígido)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202429",
                        original: "Laringoscopia/traqueoscopia para diagnóstico e biópsia (tubo rígido)",
                        mod: "Laringoscopia/traqueoscopia para diagnóstico e biópsia (tubo rígido)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202437",
                        original: "Laringoscopia/traqueoscopia para diagnóstico e biópsia com aparelho flexível",
                        mod: "Laringoscopia/traqueoscopia para diagnóstico e biópsia com aparelho flexível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202445",
                        original: "Laringoscopia/traqueoscopia para intubação oro ou nasotraqueal",
                        mod: "Laringoscopia/traqueoscopia para intubação oro ou nasotraqueal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202453",
                        original: "Ligadura elástica do esôfago, estômago ou duodeno",
                        mod: "Ligadura elástica do esôfago, estômago ou duodeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202470",
                        original: "Mucosectomia",
                        mod: "Mucosectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202488",
                        original: "Nasofibrolaringoscopia para dignóstico e/ou biópsia",
                        mod: "Nasofibrolaringoscopia para dignóstico e/ou biópsia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202496",
                        original: "Papilotomia biópsia e/ou citologia biliar e pancreática",
                        mod: "Papilotomia biópsia e/ou citologia biliar e pancreática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202500",
                        original: "Papilotomia e dilatação biliar ou pancreática",
                        mod: "Papilotomia e dilatação biliar ou pancreática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202518",
                        original: "Papilotomia endoscópica (para retirada de cálculos coledocianos ou drenagem biliar)",
                        mod: "Papilotomia endoscópica (para retirada de cálculos coledocianos ou drenagem biliar)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202526",
                        original: "Papilotomia, dilatação e colocação de prótese ou dreno biliar ou pancreático",
                        mod: "Papilotomia, dilatação e colocação de prótese ou dreno biliar ou pancreático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202534",
                        original: "Passagem de sonda naso-enteral",
                        mod: "Passagem de sonda naso-enteral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202542",
                        original: "Polipectomia de cólon (independente do número de pólipos)",
                        mod: "Polipectomia de cólon (independente do número de pólipos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202550",
                        original: "Polipectomia do esôfago, estômago ou duodeno (independente do número de pólipos)",
                        mod: "Polipectomia do esôfago, estômago ou duodeno (independente do número de pólipos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202569",
                        original: "Retirada de corpo estranho do cólon",
                        mod: "Retirada de corpo estranho do cólon",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202577",
                        original: "Retirada de corpo estranho do esôfago, estômago ou duodeno",
                        mod: "Retirada de corpo estranho do esôfago, estômago ou duodeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202585",
                        original: "Retirada de corpo estranho no brônquio ou brônquico",
                        mod: "Retirada de corpo estranho no brônquio ou brônquico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202593",
                        original: "Retirada de tumor ou papiloma por broncoscopia",
                        mod: "Retirada de tumor ou papiloma por broncoscopia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202607",
                        original: "Tamponamento de varizes do esôfago e estômago",
                        mod: "Tamponamento de varizes do esôfago e estômago",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202615",
                        original: "Endoscopia digestiva alta com biópsia e teste de urease (pesquisa Helicobacter pylori)",
                        mod: "Endoscopia digestiva alta com biópsia e teste de urease (pesquisa Helicobacter pylori)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202623",
                        original: "Traqueostomia por punção percutânea",
                        mod: "Traqueostomia por punção percutânea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202631",
                        original: "Tratamento endoscópico de hemoptise",
                        mod: "Tratamento endoscópico de hemoptise",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202640",
                        original: "Uretrotomia endoscópica",
                        mod: "Uretrotomia endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202658",
                        original: "Colocação de balão intragástrico por via endoscópica para obesidade mórbida",
                        mod: "Colocação de balão intragástrico por via endoscópica para obesidade mórbida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202666",
                        original: "Colonoscopia com biópsia e/ou citologia",
                        mod: "Colonoscopia com biópsia e/ou citologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202674",
                        original: "Colonoscopia com dilatação segmentar",
                        mod: "Colonoscopia com dilatação segmentar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202682",
                        original: "Retossigmoidoscopia flexível com polipectomia",
                        mod: "Retossigmoidoscopia flexível com polipectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202690",
                        original: "Retossigmoidoscopia flexível com biópsia e/ou citologia",
                        mod: "Retossigmoidoscopia flexível com biópsia e/ou citologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202704",
                        original: "Colonoscopia com estenostomia",
                        mod: "Colonoscopia com estenostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202712",
                        original: "Colonoscopia com mucosectomia",
                        mod: "Colonoscopia com mucosectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202720",
                        original: "Retossigmoidoscopia rígida com biópsia e/ou citologia",
                        mod: "Retossigmoidoscopia rígida com biópsia e/ou citologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202739",
                        original: "Retossigmoidoscopia rígida com polipectomia",
                        mod: "Retossigmoidoscopia rígida com polipectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202747",
                        original: "Endoscopia digestiva alta com cromoscopia e biópsia e/ou citologia",
                        mod: "Endoscopia digestiva alta com cromoscopia e biópsia e/ou citologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202755",
                        original: "Colonoscopia com tratamento de fístula",
                        mod: "Colonoscopia com tratamento de fístula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202763",
                        original: "Laringoscopia/traqueoscopia com laser para exérese de papiloma/tumor",
                        mod: "Laringoscopia/traqueoscopia com laser para exérese de papiloma/tumor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40202771",
                        original: "Retirada de balão intragástrico por via endoscópica",
                        mod: "Retirada de balão intragástrico por via endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301010",
                        original: "3-metil histidina, pesquisa e/ou dosagem no soro",
                        mod: "3-metil histidina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301028",
                        original: "5-nucleotidase - pesquisa e/ou dosagem",
                        mod: "5-nucleotidase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301036",
                        original: "Acetaminofen - pesquisa e/ou dosagem",
                        mod: "Acetaminofen",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301044",
                        original: "Acetilcolinesterase, em eritrócitos - pesquisa e/ou dosagem",
                        mod: "Acetilcolinesterase, em eritrócitos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301052",
                        original: "Acetona, pesquisa e/ou dosagem no soro",
                        mod: "Acetona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301060",
                        original: "Ácido ascórbico (vitamina C) - pesquisa e/ou dosagem",
                        mod: "Ácido ascórbico (vitamina C)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301079",
                        original: "Ácido beta hidroxi butírico - pesquisa e/ou dosagem",
                        mod: "Ácido beta hidroxi butírico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301087",
                        original: "Ácido fólico, pesquisa e/ou dosagem nos eritrócitos",
                        mod: "Ácido fólico nos eritrócitos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301095",
                        original: "Ácido glioxílico - pesquisa e/ou dosagem",
                        mod: "Ácido glioxílico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301109",
                        original: "Ácido láctico (lactato) - pesquisa e/ou dosagem",
                        mod: "Ácido láctico (lactato)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301117",
                        original: "Ácido orótico - pesquisa e/ou dosagem",
                        mod: "Ácido orótico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301125",
                        original: "Ácido oxálico - pesquisa e/ou dosagem",
                        mod: "Ácido oxálico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301133",
                        original: "Ácido pirúvico - pesquisa e/ou dosagem",
                        mod: "Ácido pirúvico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301141",
                        original: "Ácido siálico - pesquisa e/ou dosagem",
                        mod: "Ácido siálico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301150",
                        original: "Ácido úrico - pesquisa e/ou dosagem",
                        mod: "Ácido úrico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301168",
                        original: "Ácido valpróico - pesquisa e/ou dosagem",
                        mod: "Ácido valpróico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301176",
                        original: "Ácidos biliares - pesquisa e/ou dosagem",
                        mod: "Ácidos biliares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301184",
                        original: "Ácidos graxos livres - pesquisa e/ou dosagem",
                        mod: "Ácidos graxos livres",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301192",
                        original: "Ácidos orgânicos (perfil quantitativo)",
                        mod: "Ácidos orgânicos (perfil quantitativo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301206",
                        original: "Acilcarnitinas (perfil qualitativo)",
                        mod: "Acilcarnitinas (perfil qualitativo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301214",
                        original: "Acilcarnitinas (perfil quantitativo)",
                        mod: "Acilcarnitinas (perfil quantitativo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301222",
                        original: "Albumina - pesquisa e/ou dosagem",
                        mod: "Albumina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301230",
                        original: "Aldolase - pesquisa e/ou dosagem",
                        mod: "Aldolase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301249",
                        original: "Alfa-1-antitripsina, pesquisa e/ou dosagem no soro",
                        mod: "Alfa-1-antitripsina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301257",
                        original: "Alfa-1-glicoproteína ácida - pesquisa e/ou dosagem",
                        mod: "Alfa-1-glicoproteína ácida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301265",
                        original: "Alfa-2-macroglobulina - pesquisa e/ou dosagem",
                        mod: "Alfa-2-macroglobulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301273",
                        original: "Alumínio, pesquisa e/ou dosagem no soro",
                        mod: "Alumínio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301281",
                        original: "Amilase - pesquisa e/ou dosagem",
                        mod: "Amilase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301290",
                        original: "Aminoácidos, fracionamento e quantificação",
                        mod: "Aminoácidos, fracionamento e quantificação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301303",
                        original: "Amiodarona - pesquisa e/ou dosagem",
                        mod: "Amiodarona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301311",
                        original: "Amitriptilina, nortriptilina (cada) - pesquisa e/ou dosagem",
                        mod: "Amitriptilina, nortriptilina (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301320",
                        original: "Amônia - pesquisa e/ou dosagem",
                        mod: "Amônia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301338",
                        original: "Anfetaminas, pesquisa e/ou dosagem",
                        mod: "Anfetaminas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301346",
                        original: "Antibióticos, pesquisa e/ou dosagem no soro, cada",
                        mod: "Antibióticos, cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301354",
                        original: "Apolipoproteína A (Apo A) - pesquisa e/ou dosagem",
                        mod: "Apolipoproteína A (Apo A)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301362",
                        original: "Apolipoproteína B (Apo B) - pesquisa e/ou dosagem",
                        mod: "Apolipoproteína B (Apo B)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301370",
                        original: "Barbitúricos, antidepressivos tricíclicos (cada) - pesquisa e/ou dosagem",
                        mod: "Barbitúricos, antidepressivos tricíclicos (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301389",
                        original: "Beta-glicuronidase - pesquisa e/ou dosagem",
                        mod: "Beta-glicuronidase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301397",
                        original: "Bilirrubinas (direta, indireta e total) - pesquisa e/ou dosagem",
                        mod: "Bilirrubinas (direta, indireta e total)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301400",
                        original: "Cálcio - pesquisa e/ou dosagem",
                        mod: "Cálcio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301419",
                        original: "Cálcio iônico - pesquisa e/ou dosagem",
                        mod: "Cálcio iônico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301427",
                        original: "Capacidade de fixação de ferro - pesquisa e/ou dosagem",
                        mod: "Capacidade de fixação de ferro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301435",
                        original: "Carbamazepina - pesquisa e/ou dosagem",
                        mod: "Carbamazepina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301443",
                        original: "Carnitina livre - pesquisa e/ou dosagem",
                        mod: "Carnitina livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301451",
                        original: "Carnitina total e frações - pesquisa e/ou dosagem",
                        mod: "Carnitina total e frações",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301460",
                        original: "Caroteno - pesquisa e/ou dosagem",
                        mod: "Caroteno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301478",
                        original: "Ceruloplasmina - pesquisa e/ou dosagem",
                        mod: "Ceruloplasmina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301486",
                        original: "Ciclosporina, methotrexate - cada - pesquisa e/ou dosagem",
                        mod: "Ciclosporina, methotrexate - cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301494",
                        original: "Clearance de ácido úrico",
                        mod: "Clearance de ácido úrico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301508",
                        original: "Clearance de creatinina",
                        mod: "Clearance de creatinina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301516",
                        original: "Clearance de fosfato",
                        mod: "Clearance de fosfato",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301524",
                        original: "Clearance de uréia",
                        mod: "Clearance de uréia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301532",
                        original: "Clearance osmolar",
                        mod: "Clearance osmolar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301540",
                        original: "Clomipramina - pesquisa e/ou dosagem",
                        mod: "Clomipramina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301559",
                        original: "Cloro - pesquisa e/ou dosagem",
                        mod: "Cloro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301567",
                        original: "Cobre - pesquisa e/ou dosagem",
                        mod: "Cobre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301575",
                        original: "Cocaína, pesquisa e/ou dosagem",
                        mod: "Cocaína",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301583",
                        original: "Colesterol (HDL) - pesquisa e/ou dosagem",
                        mod: "Colesterol (HDL)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301591",
                        original: "Colesterol (LDL) - pesquisa e/ou dosagem",
                        mod: "Colesterol (LDL)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301605",
                        original: "Colesterol total - pesquisa e/ou dosagem",
                        mod: "Colesterol total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301613",
                        original: "Cotinina - pesquisa e/ou dosagem",
                        mod: "Cotinina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301621",
                        original: "Creatina - pesquisa e/ou dosagem",
                        mod: "Creatina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301630",
                        original: "Creatinina - pesquisa e/ou dosagem",
                        mod: "Creatinina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301648",
                        original: "Creatino fosfoquinase total (CK) - pesquisa e/ou dosagem",
                        mod: "Creatino fosfoquinase total (CK)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301656",
                        original: "Creatino fosfoquinase - fração MB - massa - pesquisa e/ou dosagem",
                        mod: "Creatino fosfoquinase - fração MB - massa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301664",
                        original: "Creatino fosfoquinase - fração MB - atividade - pesquisa e/ou dosagem",
                        mod: "Creatino fosfoquinase - fração MB - atividade",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301672",
                        original: "Cromatografia de aminoácidos (perfil qualitatitivo) - pesquisa e/ou dosagem",
                        mod: "Cromatografia de aminoácidos (perfil qualitatitivo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301680",
                        original: "Curva glicêmica (4 dosagens) via oral ou endovenosa",
                        mod: "Curva glicêmica (4 dosagens) via oral ou endovenosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301699",
                        original: "Desidrogenase alfa-hidroxibutírica - pesquisa e/ou dosagem",
                        mod: "Desidrogenase alfa-hidroxibutírica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301702",
                        original: "Desidrogenase glutâmica - pesquisa e/ou dosagem",
                        mod: "Desidrogenase glutâmica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301710",
                        original: "Desidrogenase isocítrica - pesquisa e/ou dosagem",
                        mod: "Desidrogenase isocítrica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301729",
                        original: "Desidrogenase láctica (LDH) - pesquisa e/ou dosagem",
                        mod: "Desidrogenase láctica (LDH)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301737",
                        original: "Desidrogenase láctica - isoenzimas fracionadas - pesquisa e/ou dosagem",
                        mod: "Desidrogenase láctica - isoenzimas fracionadas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301745",
                        original: "Benzodiazepínicos e similares (cada) - pesquisa e/ou dosagem",
                        mod: "Benzodiazepínicos e similares (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301753",
                        original: "Digitoxina ou digoxina - pesquisa e/ou dosagem",
                        mod: "Digitoxina ou digoxina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301761",
                        original: "Eletroferese de proteínas",
                        mod: "Eletroferese de proteínas (com gráfico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301770",
                        original: "Eletroforese de glicoproteínas",
                        mod: "Eletroforese de glicoproteínas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301788",
                        original: "Eletroforese de lipoproteínas",
                        mod: "Eletroforese de lipoproteínas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301796",
                        original: "Enolase - pesquisa e/ou dosagem",
                        mod: "Enolase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301800",
                        original: "Etossuximida - pesquisa e/ou dosagem",
                        mod: "Etossuximida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301818",
                        original: "Fenilalanina, pesquisa e/ou dosagem",
                        mod: "Fenilalanina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301826",
                        original: "Fenitoína - pesquisa e/ou dosagem",
                        mod: "Fenitoína",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301834",
                        original: "Fenobarbital - pesquisa e/ou dosagem",
                        mod: "Fenobarbital",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301842",
                        original: "Ferro sérico - pesquisa e/ou dosagem",
                        mod: "Ferro sérico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301850",
                        original: "Formaldeído - pesquisa e/ou dosagem",
                        mod: "Formaldeído",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301869",
                        original: "Fosfatase ácida fração prostática - pesquisa e/ou dosagem",
                        mod: "Fosfatase ácida fração prostática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301877",
                        original: "Fosfatase ácida total - pesquisa e/ou dosagem",
                        mod: "Fosfatase ácida total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301885",
                        original: "Fosfatase alcalina - pesquisa e/ou dosagem",
                        mod: "Fosfatase alcalina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301893",
                        original: "Fosfatase alcalina com fracionamento de isoenzimas - pesquisa e/ou dosagem",
                        mod: "Fosfatase alcalina com fracionamento de isoenzimas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301907",
                        original: "Fosfatase alcalina fração óssea - pesquisa e/ou dosagem",
                        mod: "Fosfatase alcalina fração óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301915",
                        original: "Fosfatase alcalina termo-estável - pesquisa e/ou dosagem",
                        mod: "Fosfatase alcalina termo-estável",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301923",
                        original: "Fosfolipídios - pesquisa e/ou dosagem",
                        mod: "Fosfolipídios",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301931",
                        original: "Fósforo - pesquisa e/ou dosagem",
                        mod: "Fósforo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301940",
                        original: "Fósforo, prova de reabsorção tubular",
                        mod: "Fósforo, prova de reabsorção tubular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301958",
                        original: "Frutosaminas (proteínas glicosiladas) - pesquisa e/ou dosagem",
                        mod: "Frutosaminas (proteínas glicosiladas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301966",
                        original: "Frutose - pesquisa e/ou dosagem",
                        mod: "Frutose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301974",
                        original: "Galactose - pesquisa e/ou dosagem",
                        mod: "Galactose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301982",
                        original: "Galactose 1-fosfatouridil transferase, pesquisa e/ou dosagem",
                        mod: "Galactose 1-fosfatouridil transferase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40301990",
                        original: "Gama-glutamil transferase - pesquisa e/ou dosagem",
                        mod: "Gama-GT",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302016",
                        original: "Gasometria (pH, pCO2, SA, O2, excesso base) - pesquisa e/ou dosagem",
                        mod: "Gasometria (pH, pCO2, SA, O2, excesso base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302024",
                        original: "Gasometria + Hb + Ht + Na +  K + Cl + Ca + glicose + lactato (quando efetuado no gasômetro) - pesquisa e/ou dosagem",
                        mod: "Gasometria + Hb + Ht + Na +  K + Cl + Ca + glicose + lactato (quando efetuado no gasômetro)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302032",
                        original: "Glicemia após sobrecarga com dextrosol ou glicose - pesquisa e/ou dosagem",
                        mod: "Glicemia após sobrecarga com dextrosol ou glicose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302040",
                        original: "Glicose - pesquisa e/ou dosagem",
                        mod: "Glicose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302059",
                        original: "Glicose-6-fosfato deidrogenase (G6FD) - pesquisa e/ou dosagem",
                        mod: "Glicose-6-fosfato deidrogenase (G6FD)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302067",
                        original: "Haptoglobina - pesquisa e/ou dosagem",
                        mod: "Haptoglobina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302075",
                        original: "Hemoglobina glicada (A1 total) - pesquisa e/ou dosagem",
                        mod: "Hemoglobina glicada (A1 total)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302083",
                        original: "Hemoglobina plasmática livre - pesquisa e/ou dosagem",
                        mod: "Hemoglobina plasmática livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302091",
                        original: "Hexosaminidase A - pesquisa e/ou dosagem",
                        mod: "Hexosaminidase A",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302105",
                        original: "Hidroxiprolina - pesquisa e/ou dosagem",
                        mod: "Hidroxiprolina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302113",
                        original: "Homocisteína - pesquisa e/ou dosagem",
                        mod: "Homocisteína",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302121",
                        original: "Imipramina - desipramina - pesquisa e/ou dosagem",
                        mod: "Imipramina - desipramina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302130",
                        original: "Amilase ou alfa-amilase, isoenzimas - pesquisa e/ou dosagem",
                        mod: "Amilase ou alfa-amilase, isoenzimas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302148",
                        original: "Isomerase fosfohexose - pesquisa e/ou dosagem",
                        mod: "Isomerase fosfohexose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302156",
                        original: "Isoniazida - pesquisa e/ou dosagem",
                        mod: "Isoniazida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302164",
                        original: "Lactose, teste de tolerância",
                        mod: "Lactose, teste de tolerância",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302172",
                        original: "Leucino aminopeptidase - pesquisa e/ou dosagem",
                        mod: "Leucino aminopeptidase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302180",
                        original: "Lidocaina - pesquisa e/ou dosagem",
                        mod: "Lidocaina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302199",
                        original: "Lipase - pesquisa e/ou dosagem",
                        mod: "Lipase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302202",
                        original: "Lipase lipoprotéica - pesquisa e/ou dosagem",
                        mod: "Lipase lipoprotéica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302210",
                        original: "Lipoproteína (a) - Lp (a) - pesquisa e/ou dosagem",
                        mod: "Lipoproteína (a) - Lp (a)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302229",
                        original: "Lítio - pesquisa e/ou dosagem",
                        mod: "Lítio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302237",
                        original: "Magnésio - pesquisa e/ou dosagem",
                        mod: "Magnésio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302245",
                        original: "Mioglobina, pesquisa e/ou dosagem",
                        mod: "Mioglobina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302253",
                        original: "Nitrogênio amoniacal - pesquisa e/ou dosagem",
                        mod: "Nitrogênio amoniacal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302261",
                        original: "Nitrogênio total - pesquisa e/ou dosagem",
                        mod: "Nitrogênio total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302270",
                        original: "Osmolalidade - pesquisa e/ou dosagem",
                        mod: "Osmolalidade",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302288",
                        original: "Oxcarbazepina, pesquisa e/ou dosagem",
                        mod: "Oxcarbazepina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302296",
                        original: "Piruvato quinase - pesquisa e/ou dosagem",
                        mod: "Piruvato quinase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302300",
                        original: "Porfirinas quantitativas (cada) - pesquisa e/ou dosagem",
                        mod: "Porfirinas quantitativas (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302318",
                        original: "Potássio - pesquisa e/ou dosagem",
                        mod: "Potássio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302326",
                        original: "Pré-albumina - pesquisa e/ou dosagem",
                        mod: "Pré-albumina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302334",
                        original: "Primidona - pesquisa e/ou dosagem",
                        mod: "Primidona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302342",
                        original: "Procainamida - pesquisa e/ou dosagem",
                        mod: "Procainamida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302350",
                        original: "Propanolol - pesquisa e/ou dosagem",
                        mod: "Propanolol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302369",
                        original: "Proteína ligadora do retinol - pesquisa e/ou dosagem",
                        mod: "Proteína ligadora do retinol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302377",
                        original: "Proteínas totais - pesquisa e/ou dosagem",
                        mod: "Proteínas totais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302385",
                        original: "Proteínas totais albumina e globulina - pesquisa e/ou dosagem",
                        mod: "Proteínas totais albumina e globulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302393",
                        original: "Quinidina - pesquisa e/ou dosagem",
                        mod: "Quinidina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302407",
                        original: "Reserva alcalina (bicarbonato) - pesquisa e/ou dosagem",
                        mod: "Reserva alcalina (bicarbonato)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302415",
                        original: "Sacarose, teste de tolerância",
                        mod: "Sacarose, teste de tolerância",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302423",
                        original: "Sódio - pesquisa e/ou dosagem",
                        mod: "Sódio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302431",
                        original: "Succinil acetona - pesquisa e/ou dosagem",
                        mod: "Succinil acetona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302440",
                        original: "Sulfonamidas livre e acetilada (% de acetilação) - pesquisa e/ou dosagem",
                        mod: "Sulfonamidas livre e acetilada (% de acetilação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302458",
                        original: "Tacrolimus - pesquisa e/ou dosagem",
                        mod: "Tacrolimus",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302466",
                        original: "Tálio, pesquisa e/ou dosagem",
                        mod: "Tálio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302474",
                        original: "Teofilina - pesquisa e/ou dosagem",
                        mod: "Teofilina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302482",
                        original: "Teste de tolerância a insulina ou hipoglicemiantes orais (até 6 dosagens)",
                        mod: "Teste de tolerância a insulina ou hipoglicemiantes orais (até 6 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302490",
                        original: "Tirosina - pesquisa e/ou dosagem",
                        mod: "Tirosina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302504",
                        original: "Transaminase oxalacética (amino transferase aspartato) - pesquisa e/ou dosagem",
                        mod: "TGO",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302512",
                        original: "Transaminase pirúvica (amino transferase de alanina) - pesquisa e/ou dosagem",
                        mod: "TGP",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302520",
                        original: "Transferrina - pesquisa e/ou dosagem",
                        mod: "Transferrina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302539",
                        original: "Triazolam - pesquisa e/ou dosagem",
                        mod: "Triazolam",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302547",
                        original: "Triglicerídeos - pesquisa e/ou dosagem",
                        mod: "Triglicerídeos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302555",
                        original: "Trimipramina - pesquisa e/ou dosagem",
                        mod: "Trimipramina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302563",
                        original: "Tripsina imuno reativa (IRT) - pesquisa e/ou dosagem",
                        mod: "Tripsina imuno reativa (IRT)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302571",
                        original: "Troponina - pesquisa e/ou dosagem",
                        mod: "Troponina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302580",
                        original: "Uréia - pesquisa e/ou dosagem",
                        mod: "Uréia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302598",
                        original: "Urobilinogênio - pesquisa e/ou dosagem",
                        mod: "Urobilinogênio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302601",
                        original: "Vitamina A, pesquisa e/ou dosagem",
                        mod: "Vitamina A",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302610",
                        original: "Vitamina E - pesquisa e/ou dosagem",
                        mod: "Vitamina E",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302628",
                        original: "Xilose, teste de absorção à",
                        mod: "Xilose, teste de absorção à",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302636",
                        original: "Lipídios totais - pesquisa e/ou dosagem",
                        mod: "Lipídios totais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302644",
                        original: "Maltose, teste de tolerância",
                        mod: "Maltose, teste de tolerância",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302652",
                        original: "Mucopolissacaridose, pesquisa e/ou dosagem",
                        mod: "Mucopolissacaridose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302660",
                        original: "Mucoproteínas - pesquisa e/ou dosagem",
                        mod: "Mucoproteínas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302679",
                        original: "Ocitocinase, pesquisa e/ou dosagem",
                        mod: "Ocitocinase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302687",
                        original: "Procalcitonina - pesquisa e/ou dosagem",
                        mod: "Procalcitonina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302695",
                        original: "Colesterol (VLDL) - pesquisa e/ou dosagem",
                        mod: "Colesterol (VLDL)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302709",
                        original: "Teste oral de tolerância à glicose - 2 dosagens",
                        mod: "Teste oral de tolerância à glicose - 2 dosagens",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302717",
                        original: "Eletroforese de proteínas de alta resolução",
                        mod: "Eletroforese de proteínas de alta resolução",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302725",
                        original: "Imunofixação - cada fração",
                        mod: "Imunofixação - cada fração",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302733",
                        original: "Hemoglobina glicada (Fração A1c) - pesquisa e/ou dosagem",
                        mod: "Hemoglobina glicada (Fração A1c)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302741",
                        original: "Lamotrigina - pesquisa e/ou dosagem",
                        mod: "Lamotrigina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302750",
                        original: "Perfil lipídico / lipidograma (lípidios totais, colesterol, triglicerídios e eletroforese lipoproteínas) - pesquisa e/ou dosagem",
                        mod: "Lipidograma (colesterol total, triglicerídios e HDL)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302768",
                        original: "PAPP-A - pesquisa e/ou dosagem",
                        mod: "PAPP-A",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302776",
                        original: "Peptídeo natriurético BNP/PROBNP - pesquisa e/ou dosagem",
                        mod: "Peptídeo natriurético BNP/PROBNP",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302784",
                        original: "Vitamina B1, pesquisa e/ou dosagem",
                        mod: "Vitamina B1",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302792",
                        original: "Vitamina B2, pesquisa e/ou dosagem",
                        mod: "Vitamina B2",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302806",
                        original: "Vitamina B3, pesquisa e/ou dosagem",
                        mod: "Vitamina B3",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302814",
                        original: "Vitamina B6, pesquisa e/ou dosagem",
                        mod: "Vitamina B6",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302822",
                        original: "Vitamina D2, pesquisa e/ou dosagem",
                        mod: "Vitamina D2",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302830",
                        original: "Vitamina 'D' 25 HIDROXI, pesquisa e/ou dosagem (Vitamina D3)",
                        mod: "Vitamina 'D' 25 HIDROXI (Vitamina D3)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302849",
                        original: "Vitamina K,- pesquisa e/ou dosagem",
                        mod: "Vitamina K,- pesquisa e/ou dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302857",
                        original: "6-Monoacetilmorfina urinária",
                        mod: "6-Monoacetilmorfina urinária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302865",
                        original: "7 Dihidrocolesterol, dosagem",
                        mod: "7 Dihidrocolesterol, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302873",
                        original: "Ácido Gama-hidroxibutirico, dosagem soro",
                        mod: "Ácido Gama-hidroxibutirico, dosagem soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302881",
                        original: "Ácido Micofenólico, dosagem soro",
                        mod: "Ácido Micofenólico, dosagem soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302890",
                        original: "Ácidos graxos cadeia longa",
                        mod: "Ácidos graxos cadeia longa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302903",
                        original: "Ácidos graxos cadeia muito longa",
                        mod: "Ácidos graxos cadeia muito longa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302911",
                        original: "AP 50, dosagem",
                        mod: "AP 50, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302920",
                        original: "Atividade de precalicreina",
                        mod: "Atividade de precalicreina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302938",
                        original: "Beta 2 transferrina",
                        mod: "Beta 2 transferrina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302946",
                        original: "Bilirrubina transcutânea [labo]",
                        mod: "Bilirrubina transcutânea [labo]",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302954",
                        original: "Cartilagem oligomérica proteína (COMP)",
                        mod: "Cartilagem oligomérica proteína (COMP)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302962",
                        original: "Cistatina C",
                        mod: "Cistatina C",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302970",
                        original: "Clearance de água livre",
                        mod: "Clearance de água livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302989",
                        original: "Clearance de amilase",
                        mod: "Clearance de amilase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40302997",
                        original: "Colágeno ensaio de ligação",
                        mod: "Colágeno ensaio de ligação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303012",
                        original: "Alfa -1-antitripsina, (fezes) - pesquisa e/ou dosagem",
                        mod: "Alfa -1-antitripsina, (fezes)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303020",
                        original: "Anal Swab, pesquisa de oxiúrus",
                        mod: "Anal Swab, pesquisa de oxiúrus",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303039",
                        original: "Coprológico funcional (caracteres, pH, digestibilidade, amônia, ácidos orgânicos e interpretação)",
                        mod: "Coprológico funcional (caracteres, pH, digestibilidade, amônia, ácidos orgânicos e interpretação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303047",
                        original: "Eosinófilos, pesquisa nas fezes",
                        mod: "Eosinófilos, pesquisa nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303055",
                        original: "Gordura fecal, dosagem",
                        mod: "Gordura fecal, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303063",
                        original: "Hematoxilina férrica, pesquisa de protozoários nas fezes",
                        mod: "Hematoxilina férrica, pesquisa de protozoários nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303071",
                        original: "Identificação de helmintos,  exame de fragmentos - nas fezes",
                        mod: "Identificação de helmintos,  exame de fragmentos - nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303080",
                        original: "Larvas (fezes), pesquisa",
                        mod: "Larvas (fezes), pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303098",
                        original: "Leucócitos e hemácias, pesquisa nas fezes",
                        mod: "Leucócitos e hemácias, pesquisa nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303101",
                        original: "Leveduras, pesquisa nas fezes",
                        mod: "Leveduras, pesquisa nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303110",
                        original: "Parasitológico - nas fezes",
                        mod: "Parasitológico - nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303128",
                        original: "Parasitológico, colheita múltipla com fornecimento do líquido conservante nas fezes",
                        mod: "Parasitológico, colheita múltipla com fornecimento do líquido conservante nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303136",
                        original: "Sangue oculto, pesquisa nas fezes",
                        mod: "Sangue oculto, pesquisa nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303144",
                        original: "Shistossoma, pesquisa ovos em fragmentos mucosa após biópsia retal",
                        mod: "Shistossoma, pesquisa ovos em fragmentos mucosa após biópsia retal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303152",
                        original: "Substâncias redutoras nas fezes - pesquisa",
                        mod: "Substâncias redutoras nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303160",
                        original: "Tripsina, prova de (digestão da gelatina)",
                        mod: "Tripsina, prova de (digestão da gelatina)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303179",
                        original: "Esteatócrito, triagem para gordura fecal",
                        mod: "Esteatócrito, triagem para gordura fecal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303187",
                        original: "Estercobilinogênio fecal, dosagem",
                        mod: "Estercobilinogênio fecal, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303195",
                        original: "Gordura fecal, pesquisa de",
                        mod: "Gordura fecal, pesquisa de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303209",
                        original: "Helicobacter pylori nas fezes",
                        mod: "Helicobacter pylori nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303217",
                        original: "Lactoferrina fecal",
                        mod: "Lactoferrina fecal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303225",
                        original: "Nitrôgenio fecal, dosagem",
                        mod: "Nitrôgenio fecal, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303233",
                        original: "Pesquisa de VRE",
                        mod: "Pesquisa de VRE",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303241",
                        original: "Toxina botuliníca, pesquisa (fezes)",
                        mod: "Toxina botuliníca, pesquisa (fezes)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303250",
                        original: "Sangue oculto nas fezes, pesquisa imunológica",
                        mod: "Sangue oculto nas fezes, pesquisa imunológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303268",
                        original: "Oograma nas fezes",
                        mod: "Oograma nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303276",
                        original: "Resíduos alimentares, identificação, fezes",
                        mod: "Resíduos alimentares, identificação, fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303284",
                        original: "Elastase pancreática fecal",
                        mod: "Elastase pancreática fecal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303292",
                        original: "Fibrinopeptídeo A, antígeno",
                        mod: "Fibrinopeptídeo A, antígeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303306",
                        original: "Hemoglobina A2, cromatografia",
                        mod: "Hemoglobina A2, cromatografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303314",
                        original: "Hemoglobina cromatografia",
                        mod: "Hemoglobina cromatografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40303322",
                        original: "TAT - complexo Trombina/Antitrombiona",
                        mod: "TAT - complexo Trombina/Antitrombiona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304019",
                        original: "Anticoagulante lúpico, pesquisa",
                        mod: "Anticoagulante lúpico, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304027",
                        original: "Anticorpo anti A e B, pesquisa e/ou dosagem",
                        mod: "Anticorpo anti A e B",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304035",
                        original: "Anticorpos antiplaquetários, citometria de fluxo",
                        mod: "Anticorpos antiplaquetários, citometria de fluxo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304043",
                        original: "Anticorpos irregulares - pesquisa e/ou dosagem",
                        mod: "Anticorpos irregulares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304051",
                        original: "Anticorpos irregulares, pesquisa (meio salino a temperatura ambiente e 37º e teste indireto de coombs)",
                        mod: "Anticorpos irregulares, pesquisa (meio salino a temperatura ambiente e 37º e teste indireto de coombs)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304060",
                        original: "Antitrombina III, dosagem",
                        mod: "Antitrombina III, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304078",
                        original: "Ativador tissular de plasminogênio (TPA) - pesquisa e/ou dosagem",
                        mod: "Ativador tissular de plasminogênio (TPA)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304086",
                        original: "CD... (antígeno de dif. Celular, cada determinação) - pesquisa e/ou dosagem",
                        mod: "CD... (antígeno de dif. Celular, cada determinação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304094",
                        original: "Citoquímica para classificar leucemia: esterase, fosfatase leucocitária, PAS, peroxidase ou SB,  etc - cada",
                        mod: "Citoquímica para classificar leucemia: esterase, fosfatase leucocitária, PAS, peroxidase ou SB,  etc - cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304108",
                        original: "Coombs direto",
                        mod: "Coombs direto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304116",
                        original: "Enzimas  eritrocitárias,  (adenilatoquinase,  desidrogenase láctica,  fosfofructoquinase,  fosfoglicerato quinase, gliceraldeído, 3  - fosfato   desidrogenase, glicose  fosfato isomerase,  glicose 6 - fosfato desidrogenase, glutation peroxidase, glutation",
                        mod: "Enzimas  eritrocitárias,  (adenilatoquinase,  desidrogenase láctica,  fosfofructoquinase,  fosfoglicerato quinase, gliceraldeído, 3  - fosfato   desidrogenase, glicose  fosfato isomerase,  glicose 6 - fosfato desidrogenase, glutation peroxidase, glutation",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304132",
                        original: "Falcização, teste de",
                        mod: "Falcização, teste de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304140",
                        original: "Fator 4 plaquetário, dosagens",
                        mod: "Fator 4 plaquetário, dosagens",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304159",
                        original: "Fator II, dosagem",
                        mod: "Fator II, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304167",
                        original: "Fator IX, dosagem",
                        mod: "Fator IX, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304175",
                        original: "Fator V, dosagem",
                        mod: "Fator V, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304183",
                        original: "Fator VIII, dosagem",
                        mod: "Fator VIII, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304191",
                        original: "Fator VIII, dosagem do antígeno (Von Willebrand)",
                        mod: "Fator VIII, dosagem do antígeno (Von Willebrand)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304205",
                        original: "Fator VIII, dosagem do inibidor",
                        mod: "Fator VIII, dosagem do inibidor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304213",
                        original: "Fator X, dosagem",
                        mod: "Fator X, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304221",
                        original: "Fator XI, dosagem",
                        mod: "Fator XI, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304230",
                        original: "Fator XII, dosagem",
                        mod: "Fator XII, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304248",
                        original: "Fator XIII, pesquisa",
                        mod: "Fator XIII, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304256",
                        original: "Fenotipagem do sistema Rh-Hr (anti Rho(D) + anti Rh(C) + anti Rh(E)",
                        mod: "Fenotipagem do sistema Rh-Hr (anti Rho(D) + anti Rh(C) + anti Rh(E)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304264",
                        original: "Fibrinogênio, teste funcional, dosagem",
                        mod: "Fibrinogênio, teste funcional, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304272",
                        original: "Filária, pesquisa",
                        mod: "Filária, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304280",
                        original: "Grupo ABO, classificação reversa - determinação",
                        mod: "Grupo ABO, classificação reversa - determinação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304299",
                        original: "Grupo sanguíneo ABO, e fator Rho (inclui Du) - determinação",
                        mod: "Grupo sanguíneo ABO, e fator Rho (inclui Du) - determinação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304302",
                        original: "Ham, teste de (hemólise ácida)",
                        mod: "Ham, teste de (hemólise ácida)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304310",
                        original: "Heinz, corpúsculos, pesquisa",
                        mod: "Heinz, corpúsculos, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304329",
                        original: "Hemácias fetais, pesquisa",
                        mod: "Hemácias fetais, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304337",
                        original: "Hematócrito, determinação do",
                        mod: "Hematócrito, determinação do",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304345",
                        original: "Hemoglobina, dosagem",
                        mod: "Hemoglobina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304353",
                        original: "Hemoglobina (eletroforese) - pesquisa e/ou dosagem",
                        mod: "Hemoglobina (eletroforese)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304361",
                        original: "Hemograma com contagem de plaquetas ou frações (eritrograma, leucograma, plaquetas)",
                        mod: "Hemograma",
                        favorito: true,
                        aspecto: "",
                },
                {
                        codigo: "40304370",
                        original: "Hemossedimentação, (VHS) - pesquisa e/ou dosagem",
                        mod: "VHS",
                        favorito: true,
                        aspecto: "",
                },
                {
                        codigo: "40304388",
                        original: "Hemossiderina (siderócitos), sangue ou urina - pesquisa e/ou dosagem",
                        mod: "Hemossiderina (siderócitos), sangue ou urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304396",
                        original: "Heparina, dosagem",
                        mod: "Heparina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304400",
                        original: "Inibidor do TPA (PAI) - pesquisa e/ou dosagem",
                        mod: "Inibidor do TPA (PAI)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304418",
                        original: "Leucócitos, contagem",
                        mod: "Leucócitos, contagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304434",
                        original: "Meta-hemoglobina, determinação da",
                        mod: "Meta-hemoglobina, determinação da",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304450",
                        original: "Plaquetas, teste de agregação (por agente agregante), cada",
                        mod: "Plaquetas, teste de agregação (por agente agregante), cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304469",
                        original: "Plasminogênio, dosagem",
                        mod: "Plasminogênio, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304477",
                        original: "Plasmódio, pesquisa",
                        mod: "Plasmódio, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304485",
                        original: "Medula óssea, aspiração para mielograma ou microbiológico",
                        mod: "Medula óssea, aspiração para mielograma ou microbiológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304493",
                        original: "Produtos de degradação da fibrina, qualitativo - pesquisa e/ou dosagem",
                        mod: "Produtos de degradação da fibrina, qualitativo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304507",
                        original: "Proteína C - pesquisa e/ou dosagem",
                        mod: "Proteína C",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304515",
                        original: "Proteína S, teste funcional",
                        mod: "Proteína S, teste funcional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304523",
                        original: "Protoporfirina eritrocitária livre - zinco - pesquisa e/ou dosagem",
                        mod: "Protoporfirina eritrocitária livre - zinco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304531",
                        original: "Prova do laço",
                        mod: "Prova do laço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304540",
                        original: "Resistência globular, curva de",
                        mod: "Resistência globular, curva de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304558",
                        original: "Reticulócitos, contagem",
                        mod: "Reticulócitos, contagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304566",
                        original: "Retração do coágulo - pesquisa",
                        mod: "Retração do coágulo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304574",
                        original: "Ristocetina, co-fator, teste funcional, dosagem",
                        mod: "Ristocetina, co-fator, teste funcional, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304582",
                        original: "Tempo de coagulação - determinação",
                        mod: "Tempo de coagulação - determinação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304590",
                        original: "Tempo de protrombina - determinação",
                        mod: "Tempo de protrombina - determinação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304604",
                        original: "Tempo de reptilase - determinação",
                        mod: "Tempo de reptilase - determinação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304612",
                        original: "Tempo de sangramento de IVY - deteminação",
                        mod: "Tempo de sangramento de IVY - deteminação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304620",
                        original: "Tempo de trombina - determinação",
                        mod: "Tempo de trombina - determinação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304639",
                        original: "Tempo de tromboplastina parcial ativada - determinação",
                        mod: "Tempo de tromboplastina parcial ativada - determinação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304647",
                        original: "Tripanossoma, pesquisa",
                        mod: "Tripanossoma, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304655",
                        original: "Tromboelastograma  - pesquisa e/ou dosagem",
                        mod: "Tromboelastograma ",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304663",
                        original: "Alfa-2antiplasmina, teste funcional",
                        mod: "Alfa-2antiplasmina, teste funcional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304671",
                        original: "Anticorpo antimieloperoxidase, MPO - pesquisa e/ou dosagem",
                        mod: "Anticorpo antimieloperoxidase, MPO",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304680",
                        original: "Fator VII - pesquisa e/ou dosagem",
                        mod: "Fator VII",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304698",
                        original: "Fator XIII, dosagem, teste funcional",
                        mod: "Fator XIII, dosagem, teste funcional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304701",
                        original: "Imunofenotipagem para doença residual mínima (*)",
                        mod: "Imunofenotipagem para doença residual mínima (*)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304710",
                        original: "Imunofenotipagem para hemoglobinúria paroxistica noturna (*)",
                        mod: "Imunofenotipagem para hemoglobinúria paroxistica noturna (*)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304728",
                        original: "Imunofenotipagem para leucemias agudas ou sindrome mielodisplásica (*)",
                        mod: "Imunofenotipagem para leucemias agudas ou sindrome mielodisplásica (*)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304736",
                        original: "Imunofenotipagem para linfoma não hodgkin / sindrome linfoproliferativa crônica (*)",
                        mod: "Imunofenotipagem para linfoma não hodgkin / sindrome linfoproliferativa crônica (*)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304744",
                        original: "Imunofenotipagem para perfil imune (*)",
                        mod: "Imunofenotipagem para perfil imune (*)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304752",
                        original: "Fator IX, dosagem do inibidor",
                        mod: "Fator IX, dosagem do inibidor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304760",
                        original: "Inibidor dos fatores da hemostasia, triagem",
                        mod: "Inibidor dos fatores da hemostasia, triagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304779",
                        original: "Produtos de degradação da fibrina, quantitativo - pesquisa e/ou dosagem",
                        mod: "Produtos de degradação da fibrina, quantitativo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304787",
                        original: "Proteína S livre, dosagem",
                        mod: "Proteína S livre, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304795",
                        original: "Células LE - pesquisa e/ou dosagem",
                        mod: "Células LE",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304809",
                        original: "Consumo de protrombina - pesquisa e/ou dosagem",
                        mod: "Consumo de protrombina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304817",
                        original: "Enzimas eritrocitárias, rastreio para deficiência",
                        mod: "Enzimas eritrocitárias, rastreio para deficiência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304825",
                        original: "Esplenograma (citologia)",
                        mod: "Esplenograma (citologia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304833",
                        original: "Hemoglobina instabilidade a 37 graus C - pesquisa e/ou dosagem",
                        mod: "Hemoglobina instabilidade a 37 graus C",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304841",
                        original: "Hemoglobina, solubilidade (HbS e HbD) - pesquisa e/ou dosagem",
                        mod: "Hemoglobina, solubilidade (HbS e HbD)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304850",
                        original: "Hemoglobinopatia - triagem (El.HB., hemoglob. fetal. reticulócitos, corpos de H, T. falcização hemácias, resist. osmótica, termo estabilidade)",
                        mod: "Hemoglobinopatia - triagem (El.HB., hemoglob. fetal. reticulócitos, corpos de H, T. falcização hemácias, resist. osmótica, termo estabilidade)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304868",
                        original: "Estreptozima - pesquisa e/ou dosagem",
                        mod: "Estreptozima",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304876",
                        original: "Sulfo-hemoglobina, determinação da",
                        mod: "Sulfo-hemoglobina, determinação da",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304884",
                        original: "Coombs indireto",
                        mod: "Coombs indireto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304892",
                        original: "Mielograma",
                        mod: "Mielograma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304906",
                        original: "Dímero D - pesquisa e/ou dosagem",
                        mod: "Dímero D",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304914",
                        original: "Tempo de sangramento (Duke) - determinação",
                        mod: "Tempo de sangramento (Duke) - determinação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304922",
                        original: "Coagulograma (TS, TC, prova do laço, retração do coágulo, contagem de plaquetas, tempo de protombina, tempo de tromboplastina, parcial ativado) - pesquisa e/ou dosagem",
                        mod: "Coagulograma (TS, TC, prova do laço, retração do coágulo, contagem de plaquetas, tempo de protombina, tempo de tromboplastina, parcial ativado)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304930",
                        original: "Baço, exame de esfregaço de aspirado",
                        mod: "Baço, exame de esfregaço de aspirado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304949",
                        original: "Linfonodo, exame de esfregaço de aspirado",
                        mod: "Linfonodo, exame de esfregaço de aspirado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304957",
                        original: "Adenograma (inclui hemograma)",
                        mod: "Adenograma (inclui hemograma)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304965",
                        original: "Alfa 1 anti tripsina, fenotipagem",
                        mod: "Alfa 1 anti tripsina, fenotipagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304973",
                        original: "Alfa talassemia anal molecular sangue",
                        mod: "Alfa talassemia anal molecular sangue",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40304981",
                        original: "Analisador da função plaquetaria PFA-100",
                        mod: "Analisador da função plaquetaria PFA-100",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305015",
                        original: "1,25-dihidroxi vitamina D - pesquisa e/ou dosagem",
                        mod: "1,25-dihidroxi vitamina D",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305040",
                        original: "17-cetogênicos (17-CGS) - pesquisa e/ou dosagem",
                        mod: "17-cetogênicos (17-CGS)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305058",
                        original: "17-cetogênicos cromatografia - pesquisa e/ou dosagem",
                        mod: "17-cetogênicos cromatografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305066",
                        original: "17-cetosteróides (17-CTS) - cromatografia - pesquisa e/ou dosagem",
                        mod: "17-cetosteróides (17-CTS) - cromatografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305074",
                        original: "17-cetosteróides relação alfa/beta - pesquisa e/ou dosagem",
                        mod: "17-cetosteróides relação alfa/beta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305082",
                        original: "17-cetosteróides totais (17-CTS) - pesquisa e/ou dosagem",
                        mod: "17-cetosteróides totais (17-CTS)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305090",
                        original: "17-hidroxipregnenolona - pesquisa e/ou dosagem",
                        mod: "17-hidroxipregnenolona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305112",
                        original: "Ácido 5 hidróxi indol acético, dosagem na urina",
                        mod: "Ácido 5 hidróxi indol acético, dosagem na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305120",
                        original: "Ácido homo vanílico - pesquisa e/ou dosagem",
                        mod: "Ácido homo vanílico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305163",
                        original: "AMP cíclico - pesquisa e/ou dosagem",
                        mod: "AMP cíclico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305210",
                        original: "Cortisol livre - pesquisa e/ou dosagem",
                        mod: "Cortisol livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305228",
                        original: "Curva glicêmica (6 dosagens) - pesquisa e/ou dosagem",
                        mod: "Curva glicêmica (6 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305236",
                        original: "Curva insulínica  (6 dosagens) - pesquisa e/ou dosagem",
                        mod: "Curva insulínica  (6 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305279",
                        original: "Dosagem de receptor de progesterona ou de estrogênio",
                        mod: "Dosagem de receptor de progesterona ou de estrogênio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305287",
                        original: "Enzima conversora da angiotensina (ECA) - pesquisa e/ou dosagem",
                        mod: "Enzima conversora da angiotensina (ECA)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305295",
                        original: "Eritropoietina - pesquisa e/ou dosagem",
                        mod: "Eritropoietina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305341",
                        original: "Gad-Ab-antidescarboxilase do ácido - pesquisa e/ou dosagem",
                        mod: "Gad-Ab-antidescarboxilase do ácido",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305368",
                        original: "Glucagon, dosagem",
                        mod: "Glucagon, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305384",
                        original: "Hormônio antidiurético (vasopressina) - pesquisa e/ou dosagem",
                        mod: "Hormônio antidiurético (vasopressina)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305406",
                        original: "IGF BP3 (proteína ligadora dos fatores de crescimento 'insulin-like') - pesquisa e/ou dosagem",
                        mod: "IGF BP3 (proteína ligadora dos fatores de crescimento 'insulin-like')",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305422",
                        original: "Leptina - pesquisa e/ou dosagem",
                        mod: "Leptina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305449",
                        original: "N-telopeptídeo - pesquisa e/ou dosagem",
                        mod: "N-telopeptídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305465",
                        original: "Paratormônio - PTH ou fração (cada) - pesquisa e/ou dosagem",
                        mod: "Paratormônio - PTH ou fração (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305490",
                        original: "Piridinolina - pesquisa e/ou dosagem",
                        mod: "Piridinolina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305503",
                        original: "Pregnandiol - pesquisa e/ou dosagem",
                        mod: "Pregnandiol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305511",
                        original: "Pregnantriol - pesquisa e/ou dosagem",
                        mod: "Pregnantriol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305546",
                        original: "Prova do LH-Rh, dosagem do FSH sem fornecimento de medicamento (cada)",
                        mod: "Prova do LH-Rh, dosagem do FSH sem fornecimento de medicamento (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305554",
                        original: "Prova do LH-Rh, dosagem do LH sem fornecimento de medicamento (cada)",
                        mod: "Prova do LH-Rh, dosagem do LH sem fornecimento de medicamento (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305562",
                        original: "Prova do TRH-HPR, dosagem do HPR sem fornecimento do material (cada)",
                        mod: "Prova do TRH-HPR, dosagem do HPR sem fornecimento do material (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305570",
                        original: "Prova do TRH-TSH, dosagem do TSH sem fornecimento do material (cada)",
                        mod: "Prova do TRH-TSH, dosagem do TSH sem fornecimento do material (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305589",
                        original: "Prova para diabete insípido (restrição hídrica  NaCL 3% vasopressina)",
                        mod: "Prova para diabete insípido (restrição hídrica  NaCL 3% vasopressina)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305597",
                        original: "Estrogênios totais (fenolesteróides) - pesquisa e/ou dosagem",
                        mod: "Estrogênios totais (fenolesteróides)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305600",
                        original: "Iodo protéico (PBI) - pesquisa e/ou dosagem",
                        mod: "Iodo protéico (PBI)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305619",
                        original: "Lactogênico placentário hormônio - pesquisa e/ou dosagem",
                        mod: "Lactogênico placentário hormônio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305627",
                        original: "Provas de função tireoideana (T3, T4, índices e TSH)",
                        mod: "Provas de função tireoideana (T3, T4, índices e TSH)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305635",
                        original: "Somatotrófico coriônico (HCS ou PHL) - pesquisa e/ou dosagem",
                        mod: "Somatotrófico coriônico (HCS ou PHL)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305740",
                        original: "11-desoxicorticosterona - pesquisa e/ou dosagem",
                        mod: "11-desoxicorticosterona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305759",
                        original: "Hormônio gonodotrofico corionico qualitativo (HCG-Beta-HCG) - pesquisa",
                        mod: "Hormônio gonodotrofico corionico qualitativo (HCG-Beta-HCG)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305767",
                        original: "Hormônio gonodotrofico corionico quantitativo (HCG-Beta-HCG) - dosagem",
                        mod: "Hormônio gonodotrofico corionico quantitativo (HCG-Beta-HCG) - dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305775",
                        original: "Macroprolactina - pesquisa e/ou dosagem",
                        mod: "Macroprolactina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40305783",
                        original: "17-hidroxicorticosteróides (17-OHS) - pesquisa e/ou dosagem",
                        mod: "17-hidroxicorticosteróides (17-OHS)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306011",
                        original: "Adenovírus, IgG - pesquisa e/ou dosagem",
                        mod: "Adenovírus, IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306020",
                        original: "Adenovírus, IgM - pesquisa e/ou dosagem",
                        mod: "Adenovírus, IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306046",
                        original: "Anticandida - IgG e IgM (cada) - pesquisa e/ou dosagem",
                        mod: "Anticandida - IgG e IgM (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306054",
                        original: "Anti-actina - pesquisa e/ou dosagem",
                        mod: "Anti-actina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306062",
                        original: "Anti-DNA - pesquisa e/ou dosagem",
                        mod: "Anti-DNA (dupla-hélice)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306070",
                        original: "Anti-JO1 - pesquisa e/ou dosagem",
                        mod: "Anti-JO1",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306089",
                        original: "Anti-LA/SSB - pesquisa e/ou dosagem",
                        mod: "Anti-LA/SSB",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306097",
                        original: "Anti-LKM-1 - pesquisa e/ou dosagem",
                        mod: "Anti-LKM-1",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306100",
                        original: "Anti-RNP - pesquisa e/ou dosagem",
                        mod: "Anti-RNP",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306119",
                        original: "Anti-Ro/SSA - pesquisa e/ou dosagem",
                        mod: "Anti-Ro/SSA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306127",
                        original: "Anti-Sm - pesquisa e/ou dosagem",
                        mod: "Anti-Sm",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306135",
                        original: "Anticardiolipina - IgA - pesquisa e/ou dosagem",
                        mod: "Anticardiolipina - IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306143",
                        original: "Anticardiolipina - IgG - pesquisa e/ou dosagem",
                        mod: "Anticardiolipina - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306151",
                        original: "Anticardiolipina - IgM - pesquisa e/ou dosagem",
                        mod: "Anticardiolipina - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306160",
                        original: "Anticentrômero - pesquisa e/ou dosagem",
                        mod: "Anticentrômero",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306178",
                        original: "Anticorpo anti-DNAse B - pesquisa e/ou dosagem",
                        mod: "Anticorpo anti-DNAse B",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306186",
                        original: "Anticorpo anti-hormônio do crescimento - pesquisa e/ou dosagem",
                        mod: "Anticorpo anti-hormônio do crescimento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306194",
                        original: "Anticorpo antivírus da hepatite E (total) - pesquisa e/ou dosagem",
                        mod: "Anticorpo antivírus da hepatite E (total)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306208",
                        original: "Anticorpos anti-ilhota de langherans - pesquisa e/ou dosagem",
                        mod: "Anticorpos anti-ilhota de langherans",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306216",
                        original: "Anticorpos anti-influenza A,  IgG - pesquisa e/ou dosagem",
                        mod: "Anticorpos anti-influenza A,  IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306224",
                        original: "Anticorpos anti-influenza A,  IgM - pesquisa e/ou dosagem",
                        mod: "Anticorpos anti-influenza A,  IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306232",
                        original: "Anticorpos anti-influenza B, IgG - pesquisa e/ou dosagem",
                        mod: "Anticorpos anti-influenza B, IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306240",
                        original: "Anticorpos anti-influenza B, IgM - pesquisa e/ou dosagem",
                        mod: "Anticorpos anti-influenza B, IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306259",
                        original: "Anticorpos antiendomisio - IgG, IgM, IgA (cada) - pesquisa e/ou dosagem",
                        mod: "Anticorpos antiendomisio - IgG, IgM, IgA (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306267",
                        original: "Anticorpos naturais - isoaglutininas, pesquisas",
                        mod: "Anticorpos naturais - isoaglutininas, pesquisas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306275",
                        original: "Anticorpos naturais - isoaglutininas, titulagem",
                        mod: "Anticorpos naturais - isoaglutininas, titulagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306283",
                        original: "Anticortex supra-renal - pesquisa e/ou dosagem",
                        mod: "Anticortex supra-renal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306291",
                        original: "Antiescleroderma (SCL 70) - pesquisa e/ou dosagem",
                        mod: "Antiescleroderma (SCL 70)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306305",
                        original: "Antigliadina (glúten) - IgA - pesquisa e/ou dosagem",
                        mod: "Antigliadina (glúten) - IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306313",
                        original: "Antigliadina (glúten) - IgG - pesquisa e/ou dosagem",
                        mod: "Antigliadina (glúten) - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306321",
                        original: "Antigliadina (glúten) - IgM - pesquisa e/ou dosagem",
                        mod: "Antigliadina (glúten) - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306330",
                        original: "Antimembrana basal - pesquisa e/ou dosagem",
                        mod: "Antimembrana basal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306348",
                        original: "Antimicrossomal - pesquisa e/ou dosagem",
                        mod: "Antimicrossomal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306356",
                        original: "Antimitocondria - pesquisa e/ou dosagem",
                        mod: "Antimitocondria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306364",
                        original: "Antimitocondria, M2 - pesquisa e/ou dosagem",
                        mod: "Antimitocondria, M2",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306372",
                        original: "Antimúsculo cardíaco - pesquisa e/ou dosagem",
                        mod: "Antimúsculo cardíaco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306380",
                        original: "Antimúsculo estriado - pesquisa e/ou dosagem",
                        mod: "Antimúsculo estriado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306399",
                        original: "Antimúsculo liso - pesquisa e/ou dosagem",
                        mod: "Antimúsculo liso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306402",
                        original: "Antineutrófilos (anca)  C - pesquisa e/ou dosagem",
                        mod: "Antineutrófilos (anca)  C",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306410",
                        original: "Antineutrófilos (anca)  P - pesquisa e/ou dosagem",
                        mod: "Antineutrófilos (anca)  P",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306429",
                        original: "Antiparietal - pesquisa e/ou dosagem",
                        mod: "Antiparietal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306437",
                        original: "Antiperoxidase tireoideana - pesquisa e/ou dosagem",
                        mod: "Antiperoxidase tireoideana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306445",
                        original: "Aslo - pesquisa e/ou dosagem",
                        mod: "Aslo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306453",
                        original: "Aspergilus, reação sorológica",
                        mod: "Aspergilus, reação sorológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306461",
                        original: "Avidez de IgG para toxoplasmose, citomegalia, rubéloa, EB e outros, cada - pesquisa e/ou dosagem",
                        mod: "Avidez de IgG para toxoplasmose, citomegalia, rubéloa, EB e outros, cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306470",
                        original: "Beta-2-microglobulina - pesquisa e/ou dosagem",
                        mod: "Beta-2-microglobulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306488",
                        original: "Biotinidase atividade da, qualitativo - pesquisa e/ou dosagem",
                        mod: "Biotinidase atividade da, qualitativo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306496",
                        original: "Blastomicose, reação sorológica",
                        mod: "Blastomicose, reação sorológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306500",
                        original: "Brucela - IgG - pesquisa e/ou dosagem",
                        mod: "Brucela - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306518",
                        original: "Brucela - IgM - pesquisa e/ou dosagem",
                        mod: "Brucela - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306526",
                        original: "Brucela, prova rápida",
                        mod: "Brucela, prova rápida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306534",
                        original: "C1q - pesquisa e/ou dosagem",
                        mod: "C1q",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306542",
                        original: "C3 proativador - pesquisa e/ou dosagem",
                        mod: "C3 proativador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306550",
                        original: "C3A (fator B) - pesquisa e/ou dosagem",
                        mod: "C3A (fator B)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306569",
                        original: "CA 50 - pesquisa e/ou dosagem",
                        mod: "CA 50",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306577",
                        original: "CA-242 - pesquisa e/ou dosagem",
                        mod: "CA-242",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306585",
                        original: "CA-27-29 - pesquisa e/ou dosagem",
                        mod: "CA-27-29",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306593",
                        original: "Caxumba, IgG - pesquisa e/ou dosagem",
                        mod: "Caxumba, IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306607",
                        original: "Caxumba, IgM - pesquisa e/ou dosagem",
                        mod: "Caxumba, IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306615",
                        original: "Chagas IgG - pesquisa e/ou dosagem",
                        mod: "Chagas IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306623",
                        original: "Chagas IgM - pesquisa e/ou dosagem",
                        mod: "Chagas IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306631",
                        original: "Chlamydia - IgG - pesquisa e/ou dosagem",
                        mod: "Chlamydia - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306640",
                        original: "Chlamydia - IgM - pesquisa e/ou dosagem",
                        mod: "Chlamydia - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306658",
                        original: "Cisticercose, AC - pesquisa e/ou dosagem",
                        mod: "Cisticercose, AC",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306666",
                        original: "Citomegalovírus IgG - pesquisa e/ou dosagem",
                        mod: "Citomegalovírus IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306674",
                        original: "Citomegalovírus IgM - pesquisa e/ou dosagem",
                        mod: "Citomegalovírus IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306682",
                        original: "Clostridium difficile, toxina A - pesquisa e/ou dosagem",
                        mod: "Clostridium difficile, toxina A",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306690",
                        original: "Complemento C2 - pesquisa e/ou dosagem",
                        mod: "Complemento C2",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306704",
                        original: "Complemento C3 - pesquisa e/ou dosagem",
                        mod: "Complemento C3",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306712",
                        original: "Complemento C4 - pesquisa e/ou dosagem",
                        mod: "Complemento C4",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306720",
                        original: "Complemento C5 - pesquisa e/ou dosagem",
                        mod: "Complemento C5",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306739",
                        original: "Complemento CH-100 - pesquisa e/ou dosagem",
                        mod: "Complemento CH-100",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306747",
                        original: "Complemento CH-50 - pesquisa e/ou dosagem",
                        mod: "Complemento CH-50",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306755",
                        original: "Crio-aglutinina, globulina, dosagem, cada",
                        mod: "Crio-aglutinina, globulina, dosagem, cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306763",
                        original: "Crio-aglutinina, globulina, pesquisa, cada",
                        mod: "Crio-aglutinina, globulina, pesquisa, cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306771",
                        original: "Cross match (prova cruzada de histocompatibilidade para transplante renal)",
                        mod: "Cross match (prova cruzada de histocompatibilidade para transplante renal)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306780",
                        original: "Cultura ou estimulação dos linfócitos 'in vitro' por concanavalina, PHA ou pokweed",
                        mod: "Cultura ou estimulação dos linfócitos 'in vitro' por concanavalina, PHA ou pokweed",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306798",
                        original: "Dengue - IgG e IgM (cada) - pesquisa e/ou dosagem",
                        mod: "Dengue - IgG e IgM (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306801",
                        original: "Echovírus (painel) sorologia para",
                        mod: "Echovírus (painel) sorologia para",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306810",
                        original: "Equinococose (Hidatidose), reação sorológica",
                        mod: "Equinococose (Hidatidose), reação sorológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306828",
                        original: "Equinococose, IDR - pesquisa e/ou dosagem",
                        mod: "Equinococose, IDR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306836",
                        original: "Esporotricose, reação sorológica",
                        mod: "Esporotricose, reação sorológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306844",
                        original: "Esporotriquina, IDR - pesquisa e/ou dosagem",
                        mod: "Esporotriquina, IDR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306852",
                        original: "Fator antinúcleo, (FAN) - pesquisa e/ou dosagem",
                        mod: "Fator Antinuclear (FAN)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306860",
                        original: "Fator reumatóide, quantitativo - pesquisa e/ou dosagem",
                        mod: "Fator reumatóide, quantitativo (por nefelometria ou turbidimetria)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306879",
                        original: "Filaria sorologia - pesquisa e/ou dosagem",
                        mod: "Filaria sorologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306887",
                        original: "Genotipagem do sistema HLA",
                        mod: "Genotipagem do sistema HLA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306895",
                        original: "Giardia, reação sorológica",
                        mod: "Giardia, reação sorológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306909",
                        original: "Helicobacter pylori - IgA - pesquisa e/ou dosagem",
                        mod: "Helicobacter pylori - IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306917",
                        original: "Helicobacter pylori - IgG - pesquisa e/ou dosagem",
                        mod: "Helicobacter pylori - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306925",
                        original: "Helicobacter pylori - IgM - pesquisa e/ou dosagem",
                        mod: "Helicobacter pylori - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306933",
                        original: "Hepatite A - HAV - IgG - pesquisa e/ou dosagem",
                        mod: "Hepatite A - HAV - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306941",
                        original: "Hepatite A - HAV - IgM - pesquisa e/ou dosagem",
                        mod: "Hepatite A - HAV - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306950",
                        original: "Hepatite B - HBCAC - IgG (anti-core IgG ou Acoreg) - pesquisa e/ou dosagem",
                        mod: "Anti-HBcAg IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306968",
                        original: "Hepatite B - HBCAC - IgM (anti-core IgM ou Acorem) - pesquisa e/ou dosagem",
                        mod: "Anti-HBcAg IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306976",
                        original: "Hepatite B - HBeAC (anti HBE) - pesquisa e/ou dosagem",
                        mod: "Hepatite B - HBeAC (anti HBE)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306984",
                        original: "Hepatite B - HBeAG (antígeno 'E') - pesquisa e/ou dosagem",
                        mod: "Hepatite B - HBeAG (antígeno 'E')",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40306992",
                        original: "Hepatite B - HBSAC (anti-antígeno de superfície) - pesquisa e/ou dosagem",
                        mod: "Anti-HBsAg",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307018",
                        original: "Hepatite B - HBSAG (AU, antígeno austrália) - pesquisa e/ou dosagem",
                        mod: "HBsAg",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307026",
                        original: "Hepatite C - anti-HCV - pesquisa e/ou dosagem",
                        mod: "Anti-HCV",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307034",
                        original: "Hepatite C - anti-HCV - IgM - pesquisa e/ou dosagem",
                        mod: "Hepatite C - anti-HCV - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307042",
                        original: "Hepatite C - imunoblot - pesquisa e/ou dosagem",
                        mod: "Hepatite C - imunoblot",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307050",
                        original: "Hepatite delta, anticorpo IgG - pesquisa e/ou dosagem",
                        mod: "Hepatite delta, anticorpo IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307069",
                        original: "Hepatite delta, anticorpo IgM - pesquisa e/ou dosagem",
                        mod: "Hepatite delta, anticorpo IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307077",
                        original: "Hepatite delta, antígeno - pesquisa e/ou dosagem",
                        mod: "Hepatite delta, antígeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307085",
                        original: "Herpes simples - IgG - pesquisa e/ou dosagem",
                        mod: "Herpes simples - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307093",
                        original: "Herpes simples - IgM - pesquisa e/ou dosagem",
                        mod: "Herpes simples - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307107",
                        original: "Herpes zoster - IgG - pesquisa e/ou dosagem",
                        mod: "Herpes zoster - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307115",
                        original: "Herpes zoster - IgM - pesquisa e/ou dosagem",
                        mod: "Herpes zoster - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307123",
                        original: "Hipersensibilidade retardada (intradermo reação IDeR ) candidina, caxumba, estreptoquinase-dornase, PPD, tricofitina, vírus vacinal, outro(s), cada",
                        mod: "Hipersensibilidade retardada (intradermo reação IDeR ) candidina, caxumba, estreptoquinase-dornase, PPD, tricofitina, vírus vacinal, outro(s), cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307131",
                        original: "Histamina, dosagem",
                        mod: "Histamina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307140",
                        original: "Histona - pesquisa e/ou dosagem",
                        mod: "Histona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307158",
                        original: "Histoplasmose, reação sorológica",
                        mod: "Histoplasmose, reação sorológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307166",
                        original: "HIV - antígeno P24 - pesquisa e/ou dosagem",
                        mod: "HIV - antígeno P24",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307174",
                        original: "HIV1 ou HIV2, pesquisa de anticorpos",
                        mod: "HIV1 ou HIV2, pesquisa de anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307182",
                        original: "HIV1+ HIV2, (determinação conjunta), pesquisa de anticorpos",
                        mod: "HIV1 + HIV2, (determinação conjunta), pesquisa de anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307190",
                        original: "HLA-DR - pesquisa e/ou dosagem",
                        mod: "HLA-DR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307204",
                        original: "HLA-DR+DQ - pesquisa e/ou dosagem",
                        mod: "HLA-DR+DQ",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307212",
                        original: "HTLV1 ou HTLV2 pesquisa de anticorpo (cada)",
                        mod: "HTLV1 ou HTLV2 pesquisa de anticorpo (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307220",
                        original: "IgA - pesquisa e/ou dosagem",
                        mod: "IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307239",
                        original: "IgA na saliva - pesquisa e/ou dosagem",
                        mod: "IgA na saliva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307247",
                        original: "IgD - pesquisa e/ou dosagem",
                        mod: "IgD",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307255",
                        original: "IgE, grupo específico, cada - pesquisa e/ou dosagem",
                        mod: "IgE, grupo específico, cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307263",
                        original: "IgE, por alérgeno (cada) - pesquisa e/ou dosagem",
                        mod: "IgE, por alérgeno (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307271",
                        original: "IgE, total - pesquisa e/ou dosagem",
                        mod: "IgE, total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307280",
                        original: "IgG - pesquisa e/ou dosagem",
                        mod: "IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307298",
                        original: "IgG, subclasses 1,2,3,4 (cada) - pesquisa e/ou dosagem",
                        mod: "IgG, subclasses 1,2,3,4 (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307301",
                        original: "IgM - pesquisa e/ou dosagem",
                        mod: "IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307310",
                        original: "Imunocomplexos circulantes - pesquisa e/ou dosagem",
                        mod: "Imunocomplexos circulantes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307328",
                        original: "Imunocomplexos circulantes, com células Raji - pesquisa e/ou dosagem",
                        mod: "Imunocomplexos circulantes, com células Raji",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307336",
                        original: "Imunoeletroforese (estudo da gamopatia) - pesquisa e/ou dosagem",
                        mod: "Imunoeletroforese (estudo da gamopatia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307344",
                        original: "Inibidor de C1 esterase - pesquisa e/ou dosagem",
                        mod: "Inibidor de C1 esterase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307352",
                        original: "Isospora, pesquisa de antígeno - pesquisa e/ou dosagem",
                        mod: "Isospora, pesquisa de antígeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307360",
                        original: "Ito (cancro mole), IDeR - pesquisa e/ou dosagem",
                        mod: "Ito (cancro mole), IDeR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307379",
                        original: "Kveim (sarcoidose), IDeR - pesquisa e/ou dosagem",
                        mod: "Kveim (sarcoidose), IDeR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307387",
                        original: "Legionella - IgG e IgM (cada) - pesquisa e/ou dosagem",
                        mod: "Legionella - IgG e IgM (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307395",
                        original: "Leishmaniose - IgG e IgM (cada) - pesquisa e/ou dosagem",
                        mod: "Leishmaniose - IgG e IgM (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307409",
                        original: "Leptospirose - IgG - pesquisa e/ou dosagem",
                        mod: "Leptospirose - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307417",
                        original: "Leptospirose - IgM - pesquisa e/ou dosagem",
                        mod: "Leptospirose - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307425",
                        original: "Leptospirose, aglutinação - pesquisa",
                        mod: "Leptospirose, aglutinação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307433",
                        original: "Linfócitos T 'helper' contagem de (IF com OKT-4) (CD-4+) citometria de fluxo",
                        mod: "Linfócitos T 'helper' contagem de (IF com OKT-4) (CD-4+) citometria de fluxo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307441",
                        original: "Linfócitos T supressores contagem de (IF com OKT-8) (D-8) citometria de fluxo",
                        mod: "Linfócitos T supressores contagem de (IF com OKT-8) (D-8) citometria de fluxo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307450",
                        original: "Listeriose, reação sorológica",
                        mod: "Listeriose, reação sorológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307468",
                        original: "Lyme - IgG - pesquisa e/ou dosagem",
                        mod: "Lyme - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307476",
                        original: "Lyme - IgM - pesquisa e/ou dosagem",
                        mod: "Lyme - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307484",
                        original: "Malária - IgG - pesquisa e/ou dosagem",
                        mod: "Malária - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307492",
                        original: "Malária - IgM - pesquisa e/ou dosagem",
                        mod: "Malária - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307506",
                        original: "Mantoux, IDeR",
                        mod: "Mantoux, IDeR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307514",
                        original: "MCA (antígeno cárcino-mamário) - pesquisa e/ou dosagem",
                        mod: "MCA (antígeno cárcino-mamário)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307522",
                        original: "Micoplasma pneumoniae - IgG - pesquisa e/ou dosagem",
                        mod: "Micoplasma pneumoniae - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307530",
                        original: "Micoplasma pneumoniae - IgM - pesquisa e/ou dosagem",
                        mod: "Micoplasma pneumoniae - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307565",
                        original: "Mononucleose - Epstein BARR - IgG - pesquisa e/ou dosagem",
                        mod: "Mononucleose - Epstein BARR - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307573",
                        original: "Mononucleose, anti-VCA (EBV) IgG - pesquisa e/ou dosagem",
                        mod: "Mononucleose, anti-VCA (EBV) IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307581",
                        original: "Mononucleose, anti-VCA (EBV) IgM - pesquisa e/ou dosagem",
                        mod: "Mononucleose, anti-VCA (EBV) IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307590",
                        original: "Montenegro, IDeR",
                        mod: "Montenegro, IDeR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307603",
                        original: "Outros testes bioquímicos para determinação do risco fetal (cada)",
                        mod: "Outros testes bioquímicos para determinação do risco fetal (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307611",
                        original: "Parvovírus - IgG, IgM (cada) - pesquisa e/ou dosagem",
                        mod: "Parvovírus - IgG, IgM (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307620",
                        original: "Peptídio intestinal vasoativo, dosagem",
                        mod: "Peptídio intestinal vasoativo, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307638",
                        original: "PPD (tuberculina), IDeR",
                        mod: "PPD (teste tuberculínico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307654",
                        original: "Proteína C, teste imunológico",
                        mod: "Proteína C, teste imunológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307662",
                        original: "Proteína eosinofílica catiônica (ECP) - pesquisa e/ou dosagem",
                        mod: "Proteína eosinofílica catiônica (ECP)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307689",
                        original: "Reação sorológica para coxsackie, neutralização IgG",
                        mod: "Reação sorológica para coxsackie, neutralização IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307697",
                        original: "Rubéola - IgG - pesquisa e/ou dosagem",
                        mod: "Rubéola - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307700",
                        original: "Rubéola - IgM - pesquisa e/ou dosagem",
                        mod: "Rubéola - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307719",
                        original: "Schistosomose - IgG - pesquisa e/ou dosagem",
                        mod: "Schistosomose - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307727",
                        original: "Schistosomose - IgM - pesquisa e/ou dosagem",
                        mod: "Schistosomose - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307735",
                        original: "Sífilis - FTA-ABS-IgG - pesquisa",
                        mod: "FTA-ABS-IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307743",
                        original: "Sífilis - FTA-ABS-IgM - pesquisa",
                        mod: "FTA-ABS-IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307751",
                        original: "Sífilis - TPHA - pesquisa",
                        mod: "Sífilis - TPHA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307760",
                        original: "Sífilis - VDRL",
                        mod: "VDRL",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307778",
                        original: "Teste de inibição da migração dos linfócitos (para cada antígeno)",
                        mod: "Teste de inibição da migração dos linfócitos (para cada antígeno)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307786",
                        original: "Teste respiratório para H. Pylori",
                        mod: "Teste respiratório para H. Pylori",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307794",
                        original: "Toxocara cannis - IgG - pesquisa e/ou dosagem",
                        mod: "Toxocara cannis - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307808",
                        original: "Toxocara cannis - IgM - pesquisa e/ou dosagem",
                        mod: "Toxocara cannis - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307816",
                        original: "Toxoplasmina, IDeR",
                        mod: "Toxoplasmina, IDeR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307824",
                        original: "Toxoplasmose IgG - pesquisa e/ou dosagem",
                        mod: "Toxoplasmose IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307832",
                        original: "Toxoplasmose IgM - pesquisa e/ou dosagem",
                        mod: "Toxoplasmose IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307840",
                        original: "Urease, teste rápido para helicobacter pylori",
                        mod: "Urease, teste rápido para helicobacter pylori",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307859",
                        original: "Vírus sincicial respiratório - Elisa - IgG - pesquisa e/ou dosagem",
                        mod: "Vírus sincicial respiratório - Elisa - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307867",
                        original: "Waaler-Rose (fator reumatóide) - pesquisa e/ou dosagem",
                        mod: "Waaler-Rose (fator reumatóide)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307875",
                        original: "Western Blot (anticorpos anti-HIV) - pesquisa e/ou dosagem",
                        mod: "Western Blot (anticorpos anti-HIV)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307883",
                        original: "Western Blot (anticorpos anti-HTVI ou HTLVII) (cada) - pesquisa e/ou dosagem",
                        mod: "Western Blot (anticorpos anti-HTVI ou HTLVII) (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307891",
                        original: "Widal, reação de",
                        mod: "Widal, reação de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307905",
                        original: "Alérgenos - perfil antigênico (painel C/36 antígenos) - pesquisa e/ou dosagem",
                        mod: "Alérgenos - perfil antigênico (painel C/36 antígenos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307913",
                        original: "Anti-DMP - pesquisa e/ou dosagem",
                        mod: "Anti-DMP",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307921",
                        original: "Anti-hialuronidase, determinação da",
                        mod: "Anti-hialuronidase, determinação da",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307930",
                        original: "Antidesoxiribonuclease B, neutralização quantitativa - pesquisa e/ou dosagem",
                        mod: "Antidesoxiribonuclease B, neutralização quantitativa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307948",
                        original: "Antifígado (glomérulo, tub. Renal corte rim de rato), IFI - pesquisa e/ou dosagem",
                        mod: "Antifígado (glomérulo, tub. Renal corte rim de rato), IFI",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307956",
                        original: "Antígenos metílicos solúveis do BCG (1 aplicação)",
                        mod: "Antígenos metílicos solúveis do BCG (1 aplicação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307964",
                        original: "Chagas, hemoaglutinação",
                        mod: "Chagas, hemoaglutinação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307972",
                        original: "Chagas (Machado Guerreiro)",
                        mod: "Chagas (Machado Guerreiro)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40307999",
                        original: "Complemento C3, C4 - turbid. ou nefolométrico C3A - pesquisa e/ou dosagem",
                        mod: "Complemento C3, C4 - turbid. ou nefolométrico C3A",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308014",
                        original: "Crioglobulinas, caracterização - imunoeletroforese",
                        mod: "Crioglobulinas, caracterização - imunoeletroforese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308022",
                        original: "DNCB - teste de contato",
                        mod: "DNCB - teste de contato",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308030",
                        original: "Fator reumatóide, teste do látex (qualitativo) - pesquisa",
                        mod: "Fator reumatóide, teste do látex (qualitativo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308049",
                        original: "Frei (linfogranuloma venéreo), IDeR - pesquisa e/ou dosagem",
                        mod: "Frei (linfogranuloma venéreo), IDeR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308065",
                        original: "Gonococo - hemaglutinação (HA)",
                        mod: "Gonococo - hemaglutinação (HA)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308081",
                        original: "Hidatidose (equinococose) IDi dupla - pesquisa e/ou dosagem",
                        mod: "Hidatidose (equinococose) IDi dupla",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308090",
                        original: "NBT estimulado",
                        mod: "NBT estimulado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308120",
                        original: "Sarampo - anticorpos IgG - pesquisa e/ou dosagem",
                        mod: "Sarampo - anticorpos IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308138",
                        original: "Sarampo - anticorpos IgM - pesquisa e/ou dosagem",
                        mod: "Sarampo - anticorpos IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308154",
                        original: "Toxoplasmose - IgA - pesquisa e/ou dosagem",
                        mod: "Toxoplasmose - IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308162",
                        original: "Varicela, IgG - pesquisa e/ou dosagem",
                        mod: "Varicela, IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308170",
                        original: "Varicela, IgM - pesquisa e/ou dosagem",
                        mod: "Varicela, IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308197",
                        original: "Vírus sincicial respiratório - pesquisa direta",
                        mod: "Vírus sincicial respiratório - pesquisa direta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308200",
                        original: "Weil Felix (Ricketsiose), reação de aglutinação",
                        mod: "Weil Felix (Ricketsiose), reação de aglutinação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308219",
                        original: "Anticorpo anti Saccharamyces - ASCA - pesquisa e/ou dosagem",
                        mod: "Anticorpo anti Saccharamyces - ASCA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308235",
                        original: "HER-2 - dosagem do receptor",
                        mod: "HER-2 - dosagem do receptor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308235",
                        original: "HER2 dosagem do receptor",
                        mod: "HER2 dosagem do receptor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308243",
                        original: "Poliomelite sorologia",
                        mod: "Poliomelite sorologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308251",
                        original: "Proteína Amiloide A - pesquisa e/ou dosagem",
                        mod: "Proteína Amiloide A",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308278",
                        original: "Schistosomose, pesquisa",
                        mod: "Schistosomose, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308286",
                        original: "Sífilis anticorpo total - pesquisa e/ou dosagem",
                        mod: "Sífilis anticorpo total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308294",
                        original: "Sífilis IgM - pesquisa e/ou dosagem",
                        mod: "Sífilis IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308308",
                        original: "Amebíase, IgG - pesquisa e/ou dosagem",
                        mod: "Amebíase, IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308316",
                        original: "Amebíase, IgM - pesquisa e/ou dosagem",
                        mod: "Amebíase, IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308324",
                        original: "Gonococo - IgG - pesquisa e/ou dosagem",
                        mod: "Gonococo - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308332",
                        original: "Gonococo - IgM - pesquisa e/ou dosagem",
                        mod: "Gonococo - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308340",
                        original: "Mononucleose, sorologia para (Monoteste ou Paul-Bunnel), cada",
                        mod: "Mononucleose, sorologia para (Monoteste ou Paul-Bunnel), cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308359",
                        original: "Psitacose - IgG - pesquisa e/ou dosagem",
                        mod: "Psitacose - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308367",
                        original: "Psitacose - IgM - pesquisa e/ou dosagem",
                        mod: "Psitacose - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308375",
                        original: "Psitacose - IgA - pesquisa e/ou dosagem",
                        mod: "Psitacose - IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308383",
                        original: "Proteína C reativa, qualitativa - pesquisa",
                        mod: "Proteína C reativa, qualitativa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308391",
                        original: "Proteína C reativa, quantitativa - pesquisa e/ou dosagem",
                        mod: "Proteína C reativa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308405",
                        original: "Aslo, quantitativo - pesquisa e/ou dosagem",
                        mod: "ASLO",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308413",
                        original: "Paracoccidioidomicose, anticorpos totais / IgG - pesquisa e/ou dosagem",
                        mod: "Paracoccidioidomicose, anticorpos totais / IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308421",
                        original: "Ameba, pesquisa",
                        mod: "Ameba, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308430",
                        original: "Ameba, pesquisa antígeno nas fezes, Elisa",
                        mod: "Ameba, pesquisa antígeno nas fezes, Elisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308448",
                        original: "Anti 68 KD (Cóclea)",
                        mod: "Anti 68 KD (Cóclea)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308456",
                        original: "Anti citosol hepático",
                        mod: "Anti citosol hepático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308464",
                        original: "Anti cromatina - IgG",
                        mod: "Anti cromatina - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308472",
                        original: "Anti GM1 - IgG/IgM",
                        mod: "Anti GM1 - IgG/IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308480",
                        original: "Anti HU (Western Blot)",
                        mod: "Anti HU (Western Blot)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308499",
                        original: "Anti KU",
                        mod: "Anti KU",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308502",
                        original: "Anti parainfluenza tipo 1,2,3",
                        mod: "Anti parainfluenza tipo 1,2,3",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308510",
                        original: "Anti PCNA",
                        mod: "Anti PCNA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308529",
                        original: "Anticorpos antipneumococos",
                        mod: "Anticorpos antipneumococos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308537",
                        original: "Anti RI (Western Blot)",
                        mod: "Anti RI (Western Blot)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308545",
                        original: "Anti ribossomal P",
                        mod: "Anti ribossomal P",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308553",
                        original: "Anti transglutaminase tecidual - IgA",
                        mod: "Anti transglutaminase tecidual - IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308561",
                        original: "Anti transglutaminase tecidual - IgG",
                        mod: "Anti transglutaminase tecidual - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308570",
                        original: "Anti YO",
                        mod: "Anti YO",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308588",
                        original: "Anti YO líquor",
                        mod: "Anti YO líquor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308596",
                        original: "Anticorpos anti 21 hidroxilase",
                        mod: "Anticorpos anti 21 hidroxilase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308600",
                        original: "Anticorpos anti adrenal",
                        mod: "Anticorpos anti adrenal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308618",
                        original: "Anticorpos anti anexina",
                        mod: "Anticorpos anti anexina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308626",
                        original: "Anticorpos anti antígeno hepático solúvel",
                        mod: "Anticorpos anti antígeno hepático solúvel",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308634",
                        original: "Anticorpos anti coccidióides, soro",
                        mod: "Anticorpos anti coccidióides, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308642",
                        original: "Anticorpos anti colágeno tipo 2",
                        mod: "Anticorpos anti colágeno tipo 2",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308650",
                        original: "Anticorpos anti fator intrinseco",
                        mod: "Anticorpos anti fator intrinseco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308669",
                        original: "Anticorpos anti febre, qualquer soro",
                        mod: "Anticorpos anti febre, qualquer soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308677",
                        original: "Anticorpos anti Fibrilarina",
                        mod: "Anticorpos anti Fibrilarina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308685",
                        original: "Anticorpos anti fosfatidilserina - IgA/IgG/IgM",
                        mod: "Anticorpos anti fosfatidilserina - IgA/IgG/IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308693",
                        original: "Anticorpos anti granulócitos",
                        mod: "Anticorpos anti granulócitos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308707",
                        original: "Anticorpos anti Hantavirus",
                        mod: "Anticorpos anti Hantavirus",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308715",
                        original: "Anticorpos anti líquor arbovirus, painel",
                        mod: "Anticorpos anti líquor arbovirus, painel",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308723",
                        original: "Anticorpos anti líquor bartonella",
                        mod: "Anticorpos anti líquor bartonella",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308731",
                        original: "Anticorpos anti líquor gangliosídeo, painel",
                        mod: "Anticorpos anti líquor gangliosídeo, painel",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308740",
                        original: "Anticorpos anti líquor GM1",
                        mod: "Anticorpos anti líquor GM1",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308758",
                        original: "Anticorpos anti MAG",
                        mod: "Anticorpos anti MAG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308766",
                        original: "Anticorpos anti MI-2 auto",
                        mod: "Anticorpos anti MI-2 auto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308774",
                        original: "Anticorpos anti Mielina",
                        mod: "Anticorpos anti Mielina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308782",
                        original: "Anticorpos anti neuronal nuclear 1 e 2 LCR",
                        mod: "Anticorpos anti neuronal nuclear 1 e 2 LCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308790",
                        original: "Anticorpos anti pele",
                        mod: "Anticorpos anti pele",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308804",
                        original: "Anticorpos anti peptídeo cíclico citrulinado - IgG",
                        mod: "Anticorpos anti peptídeo cíclico citrulinado (Anti-CCP)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308812",
                        original: "Anticorpos anti PM1",
                        mod: "Anticorpos anti PM1",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308820",
                        original: "Anticorpos anti reticulina",
                        mod: "Anticorpos anti reticulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308839",
                        original: "Anticorpos anti strongilóides",
                        mod: "Anticorpos anti strongilóides",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308847",
                        original: "Anticorpos anti sulfatídeo",
                        mod: "Anticorpos anti sulfatídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308855",
                        original: "Anticorpos anti vírus herpes  6 líquor - IgG/IgM",
                        mod: "Anticorpos anti vírus herpes  6 líquor - IgG/IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308863",
                        original: "Anticorpos anti vírus herpes 6",
                        mod: "Anticorpos anti vírus herpes 6",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308871",
                        original: "Anticorpos anti vírus rábico",
                        mod: "Anticorpos anti vírus rábico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308880",
                        original: "Anticorpos arbovírus, painel",
                        mod: "Anticorpos arbovírus, painel",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308898",
                        original: "Anticorpos Beta 2 glicoproteina I  - IgG/IgM/IgA",
                        mod: "Anticorpos Beta 2 glicoproteina I  - IgG/IgM/IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308901",
                        original: "Acetilcolina, anticorpos bloqueador receptor",
                        mod: "Acetilcolina, anticorpos bloqueador receptor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308910",
                        original: "Anticorpos contra canal de cálcio regulado por voltagem",
                        mod: "Anticorpos contra canal de cálcio regulado por voltagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308928",
                        original: "Anticorpos Coxsackie A, outros materiais",
                        mod: "Anticorpos Coxsackie A, outros materiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308936",
                        original: "Anticorpos CV2 auto, soro",
                        mod: "Anticorpos CV2 auto, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308944",
                        original: "Anticorpos Desmogleina tipo 1 e 3",
                        mod: "Anticorpos Desmogleina tipo 1 e 3",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308952",
                        original: "Anticorpos Fosfatidilcolina - IgG/IgM/IgA",
                        mod: "Anticorpos Fosfatidilcolina - IgG/IgM/IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308960",
                        original: "Anticorpos Gangliosídeos, soro",
                        mod: "Anticorpos Gangliosídeos, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308979",
                        original: "Anticorpos Gd1A - IgG",
                        mod: "Anticorpos Gd1A - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308987",
                        original: "Anticorpos Gd1A - IgM",
                        mod: "Anticorpos Gd1A - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40308995",
                        original: "Anticorpos Gd1B - IgG",
                        mod: "Anticorpos Gd1B - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309010",
                        original: "Adenosina de aminase (ADA) - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Adenosina de aminase (ADA) em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309029",
                        original: "Bioquímica ICR (proteínas + pandy + glicose + cloro) - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Bioquímica ICR (proteínas + pandy + glicose + cloro) em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309037",
                        original: "Células, contagem total e específica - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Células, contagem total e específica em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309045",
                        original: "Células, pesquisa de células neoplásicas (citologia oncótica) - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Células, pesquisa de células neoplásicas (citologia oncótica) em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309053",
                        original: "Criptococose, cândida, aspérgilus (látex) - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Criptococose, cândida, aspérgilus (látex) em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309061",
                        original: "Eletroforese de proteínas no líquor, com concentração - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Eletroforese de proteínas no líquor, com concentração em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309070",
                        original: "H. Influenzae, S. Pneumonieae, N. Meningitidis A, B e C W135 (cada) - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "H. Influenzae, S. Pneumonieae, N. Meningitidis A, B e C W135 (cada) em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309088",
                        original: "Haemophilus influenzae - pesquisa de anticorpos (cada)- pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Haemophilus influenzae - pesquisa de anticorpos (cada)- pesquisa e/ou dosagem em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309096",
                        original: "Índice de imunoprodução (eletrof. e IgG em soro e líquor) - pesquisa e/ou dosagem",
                        mod: "Índice de imunoprodução (eletrof. e IgG em soro e líquor)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309100",
                        original: "LCR ambulatorial rotina (aspectos cor + índice de cor + contagem global e  específica  de leucócitos e  hemácias + citologia  oncótica + proteína + glicose + cloro + eletroforese  com  concentração + IgG + reações para neurocisticercose (2) + reações para",
                        mod: "LCR ambulatorial rotina (aspectos cor + índice de cor + contagem global e  específica  de leucócitos e  hemácias + citologia  oncótica + proteína + glicose + cloro + eletroforese  com  concentração + IgG + reações para neurocisticercose (2) + reações para",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309100",
                        original: "LCR ambulatorial rotina (aspectos cor + índice de cor + contagem global e  específica  de leucócitos e  hemácias + citologia  oncótica + proteína + glicose + cloro + eletroforese  com concentração + IgG + reações para neurocisticercose (2) + reações para neuroles (2)).",
                        mod: "LCR ambulatorial rotina (aspectos cor + índice de cor + contagem global e  específica  de leucócitos e  hemácias + citologia  oncótica + proteína + glicose + cloro + eletroforese  com concentração + IgG + reações para neurocisticercose (2) + reações para neuroles (2)).",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309118",
                        original: "LCR hospitalar neurologia (aspectos cor + índices de cor + contagem global e específica de  leucócitos e hemácias + proteína + glicose + cloro + reações para neurocisticercose (2) + reações para  neurolues (2) + bacterioscopia + cultura + látex para bacté",
                        mod: "LCR hospitalar neurologia (aspectos cor + índices de cor + contagem global e específica de  leucócitos e hemácias + proteína + glicose + cloro + reações para neurocisticercose (2) + reações para  neurolues (2) + bacterioscopia + cultura + látex para bacté",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309118",
                        original: "LCR hospitalar neurologia (aspectos cor + índices de cor + contagem global e específica de  leucócitos e hemácias + proteína + glicose + cloro + reações para  neurocisticercose (2) + reações para  neurolues (2) + bacterioscopia + cultura + látex para bactérias).",
                        mod: "LCR hospitalar neurologia (aspectos cor + índices de cor + contagem global e específica de  leucócitos e hemácias + proteína + glicose + cloro + reações para  neurocisticercose (2) + reações para  neurolues (2) + bacterioscopia + cultura + látex para bactérias).",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309126",
                        original: "LCR pronto socorro (aspectos cor + índice  de cor + contagem  global  e  específica  de  leucócitos  e hemácias + proteína + glicose + cloro + lactato + bacterioscopia + cultura + látex para bactérias)",
                        mod: "LCR pronto socorro (aspectos cor + índice  de cor + contagem  global  e  específica  de  leucócitos  e hemácias + proteína + glicose + cloro + lactato + bacterioscopia + cultura + látex para bactérias)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309134",
                        original: "Pesquisa de bandas oligoclonais por isofocalização - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Pesquisa de bandas oligoclonais por isofocalização em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309142",
                        original: "Proteína mielina básica, anticorpo anti - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Proteína mielina básica, anticorpo anti em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309150",
                        original: "Punção cisternal subocciptal com manometria para coleta de líquido cefalorraqueano",
                        mod: "Punção cisternal subocciptal com manometria para coleta de líquido cefalorraqueano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309169",
                        original: "Punção lombar com manometria para coleta de líquido cefalorraqueano",
                        mod: "Punção lombar com manometria para coleta de líquido cefalorraqueano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309177",
                        original: "Nonne-Apple; reação",
                        mod: "Nonne-Apple; reação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309185",
                        original: "Takata-Ara, reação",
                        mod: "Takata-Ara, reação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309193",
                        original: "Babesia microti em líquor",
                        mod: "Babesia microti em líquor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309207",
                        original: "Coccidióides em líquor",
                        mod: "Coccidióides em líquor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309215",
                        original: "Líquor cisticercose Western Blot",
                        mod: "Líquor cisticercose Western Blot",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309223",
                        original: "Líquor encefalite, painel",
                        mod: "Líquor encefalite, painel",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309231",
                        original: "Líquor imunofenotipagem T e B",
                        mod: "Líquor imunofenotipagem T e B",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309240",
                        original: "Líquor índice IgG para citomegalovirus",
                        mod: "Líquor índice IgG para citomegalovirus",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309258",
                        original: "Líquor látex para meningite",
                        mod: "Líquor látex para meningite",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309266",
                        original: "Aminoácidos no líquido cefalorraquidiano",
                        mod: "Aminoácidos no líquido cefalorraquidiano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309304",
                        original: "Anticorpo antiespermatozóide - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Anticorpo antiespermatozóide em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309312",
                        original: "Espermograma (caracteres físicos, pH, fludificação, motilidade, vitalidade, contagem e morfologia)",
                        mod: "Espermograma (caracteres físicos, pH, fludificação, motilidade, vitalidade, contagem e morfologia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309320",
                        original: "Espermograma e teste de penetração 'in vitro', velocidade penetração vertical, colocação  vital, teste de revitalização",
                        mod: "Espermograma e teste de penetração 'in vitro', velocidade penetração vertical, colocação  vital, teste de revitalização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309401",
                        original: "Clements, teste",
                        mod: "Clements, teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309410",
                        original: "Espectrofotometria de líquido amniótico",
                        mod: "Espectrofotometria de líquido amniótico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309428",
                        original: "Fosfolipídios (relação lecitina/esfingomielina) - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Fosfolipídios (relação lecitina/esfingomielina) em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309436",
                        original: "Maturidade pulmonar fetal - - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Maturidade pulmonar fetal - em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309444",
                        original: "Rotina do líquido amniótico-amniograma (citológico espectrofotometria, creatinina e teste de clements)",
                        mod: "Rotina do líquido amniótico-amniograma (citológico espectrofotometria, creatinina e teste de clements)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309452",
                        original: "Azul de nilo no líquido amniótico",
                        mod: "Azul de nilo no líquido amniótico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309509",
                        original: "Cristais com luz polarizada - pesquisa e/ou dosagem em líquidos orgânicos",
                        mod: "Cristais com luz polarizada - pesquisa e/ou dosagem em líquidos orgânicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309517",
                        original: "Ragócitos, pesquisa",
                        mod: "Ragócitos, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40309525",
                        original: "Rotina líquido sinovial - caracteres físicos, citologia, proteínas, ácido úrico, látex p/ F.R., BACT.",
                        mod: "Rotina líquido sinovial - caracteres físicos, citologia, proteínas, ácido úrico, látex p/ F.R., BACT.",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310019",
                        original: "A fresco, exame",
                        mod: "A fresco, exame",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310035",
                        original: "Antibiograma p/ bacilos álcool-resistentes - drogas de 2 linhas",
                        mod: "Antibiograma p/ bacilos álcool-resistentes - drogas de 2 linhas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310043",
                        original: "Antígenos fúngicos, pesquisa",
                        mod: "Antígenos fúngicos, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310051",
                        original: "B.A.A.R. (Ziehl ou fluorescência, pesquisa direta e após homogeneização) - pesquisa",
                        mod: "B.A.A.R. (Ziehl ou fluorescência, pesquisa direta e após homogeneização)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310060",
                        original: "Bacterioscopia (Gram, Ziehl, Albert  etc), por lâmina",
                        mod: "Bacterioscopia (Gram, Ziehl, Albert  etc), por lâmina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310078",
                        original: "Chlamydia, cultura",
                        mod: "Chlamydia, cultura",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310086",
                        original: "Cólera - identificação (sorotipagem incluída)",
                        mod: "Cólera - identificação (sorotipagem incluída)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310094",
                        original: "Corpúsculos de Donovani, pesquisa direta de",
                        mod: "Corpúsculos de Donovani, pesquisa direta de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310108",
                        original: "Criptococo (tinta da China), pesquisa de",
                        mod: "Criptococo (tinta da China), pesquisa de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310116",
                        original: "Criptosporidium, pesquisa",
                        mod: "Criptosporidium, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310124",
                        original: "Cultura bacteriana (em diversos materiais biológicos)",
                        mod: "Cultura bacteriana (em diversos materiais biológicos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310132",
                        original: "Cultura para bactérias anaeróbicas",
                        mod: "Cultura para bactérias anaeróbicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310140",
                        original: "Cultura para fungos",
                        mod: "Cultura para fungos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310159",
                        original: "Cultura para mycobacterium",
                        mod: "Cultura para mycobacterium",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310167",
                        original: "Cultura quantitativa de secreções pulmonares, quando necessitar tratamento prévio c/ N.C.A.",
                        mod: "Cultura quantitativa de secreções pulmonares, quando necessitar tratamento prévio c/ N.C.A.",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310175",
                        original: "Cultura, fezes: salmonela, shigellae e esc. Coli enteropatogênicas, enteroinvasora (sorol. Incluída) + campylobacter SP. + E. Coli entero-hemorrágica",
                        mod: "Cultura, fezes: salmonela, shigellae e esc. Coli enteropatogênicas, enteroinvasora (sorol. Incluída) + campylobacter SP. + E. Coli entero-hemorrágica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310183",
                        original: "Cultura, fezes: salmonella, shigella e escherichia coli enteropatogênicas (sorologia incluída)",
                        mod: "Cultura, fezes: salmonella, shigella e escherichia coli enteropatogênicas (sorologia incluída)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310191",
                        original: "Cultura, herpesvírus ou outro",
                        mod: "Cultura, herpesvírus ou outro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310205",
                        original: "Cultura, micoplasma ou ureaplasma",
                        mod: "Cultura, micoplasma ou ureaplasma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310213",
                        original: "Cultura, urina com contagem de colônias",
                        mod: "Cultura, urina com contagem de colônias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310221",
                        original: "Estreptococos - A, teste rápido",
                        mod: "Estreptococos - A, teste rápido",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324648",
                        original: "Ensaio para dosagem da liberação de interferon gama",
                        mod: "Ensaio para dosagem da liberação de interferon gama",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310230",
                        original: "Fungos, pesquisa de (a fresco lactofenol, tinta da China)",
                        mod: "Fungos, pesquisa de (a fresco lactofenol, tinta da China)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310248",
                        original: "Hemocultura (por amostra)",
                        mod: "Hemocultura (por amostra)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310256",
                        original: "Hemocultura automatizada (por amostra)",
                        mod: "Hemocultura automatizada (por amostra)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310264",
                        original: "Hemocultura para bactérias anaeróbias (por amostra)",
                        mod: "Hemocultura para bactérias anaeróbias (por amostra)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310272",
                        original: "Hemophilus (bordetella) pertussis - pesquisa",
                        mod: "Hemophilus (bordetella) pertussis",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310280",
                        original: "Hansen, pesquisa de (por material)",
                        mod: "Hansen, pesquisa de (por material)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310299",
                        original: "Leptospira (campo escuro após concentração) pesquisa",
                        mod: "Leptospira (campo escuro após concentração) pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310302",
                        original: "Microorganismos - teste de sensibilidade a drogas MIC, por droga testada",
                        mod: "Microorganismos - teste de sensibilidade a drogas MIC, por droga testada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310310",
                        original: "Paracoccidioides, pesquisa de",
                        mod: "Paracoccidioides, pesquisa de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310329",
                        original: "Pneumocysti carinii, pesquisa por coloração especial",
                        mod: "Pneumocysti carinii, pesquisa por coloração especial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310337",
                        original: "Rotavírus, pesquisa, Elisa",
                        mod: "Rotavírus, pesquisa, Elisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310345",
                        original: "Treponema (campo escuro) - pesquisa",
                        mod: "Treponema (campo escuro)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310353",
                        original: "Vacina autógena",
                        mod: "Vacina autógena",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310361",
                        original: "Citomegalovírus - shell vial - pesquisa",
                        mod: "Citomegalovírus - shell vial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310370",
                        original: "Microsporídia, pesquisa nas fezes",
                        mod: "Microsporídia, pesquisa nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310388",
                        original: "Sarcoptes scabei, pesquisa",
                        mod: "Sarcoptes scabei, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310396",
                        original: "Chlamydia - PCR, amplificação de DNA",
                        mod: "Chlamydia - PCR, amplificação de DNA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310400",
                        original: "Cultura automatizada - MICROBIOLOGIA",
                        mod: "Cultura automatizada - MICROBIOLOGIA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310418",
                        original: "Antibiograma (teste de sensibilidade e antibióticos e quimioterápicos), por bactéria - não automatizado",
                        mod: "Antibiograma (teste de sensibilidade e antibióticos e quimioterápicos), por bactéria - não automatizado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310426",
                        original: "Antibiograma automatizado",
                        mod: "Antibiograma automatizado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310434",
                        original: "Leishmania, pesquisa",
                        mod: "Leishmania, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310434",
                        original: "Leishmania, pesquisa - pesquisa",
                        mod: "Leishmania, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310442",
                        original: "Chlamydia pneumoniae, painel",
                        mod: "Chlamydia pneumoniae, painel",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310450",
                        original: "CMV para imunofluorescência direta",
                        mod: "CMV para imunofluorescência direta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310469",
                        original: "Cultura para listeria",
                        mod: "Cultura para listeria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310477",
                        original: "Gardnerella, cultura",
                        mod: "Gardnerella, cultura",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310485",
                        original: "Helicobacter pylori, cultura de biópsia",
                        mod: "Helicobacter pylori, cultura de biópsia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310493",
                        original: "Herpes para imunofluorescência direta",
                        mod: "Herpes para imunofluorescência direta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310507",
                        original: "Mycobactéria amplificação de DNA (PCR)",
                        mod: "Mycobactéria amplificação de DNA (PCR)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310515",
                        original: "Pesquisa de antígenos bacterianos",
                        mod: "Pesquisa de antígenos bacterianos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310523",
                        original: "Pesquisa de antígenos entamoeba histolytica",
                        mod: "Pesquisa de antígenos entamoeba histolytica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310531",
                        original: "Poder bactericida (pós administração de antibiótico)",
                        mod: "Poder bactericida (pós administração de antibiótico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310540",
                        original: "Protozoários, cultura para",
                        mod: "Protozoários, cultura para",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310558",
                        original: "Streptococcus B hemol cultura qualquer material",
                        mod: "Streptococcus B hemol cultura qualquer material",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310566",
                        original: "Teste de sensibilidade mycobacterium cepas de bactérias",
                        mod: "Teste de sensibilidade mycobacterium cepas de bactérias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310574",
                        original: "Trichomonas, cultura para",
                        mod: "Trichomonas, cultura para",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310582",
                        original: "Yersinia enterocolitica",
                        mod: "Yersinia enterocolitica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310590",
                        original: "Antígenos bacterianos / vários materiais",
                        mod: "Antígenos bacterianos / vários materiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310604",
                        original: "Antifungigrama",
                        mod: "Antifungigrama",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310612",
                        original: "Chlamydia trachomatis, exame direto para vários materiais",
                        mod: "Chlamydia trachomatis, exame direto para vários materiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310620",
                        original: "Cultura, para agentes multirresistentes, vários materiais",
                        mod: "Cultura, para agentes multirresistentes, vários materiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310639",
                        original: "Demodex folliculorum, pesquisa",
                        mod: "Demodex folliculorum, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310647",
                        original: "Cultura quantitativa queimados (pele)",
                        mod: "Cultura quantitativa queimados (pele)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310655",
                        original: "Influenza vírus, isolamento",
                        mod: "Influenza vírus, isolamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310663",
                        original: "Lactamase, teste da cepa",
                        mod: "Lactamase, teste da cepa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310671",
                        original: "Cultura em leite materno",
                        mod: "Cultura em leite materno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310680",
                        original: "Acanthamoeba, pesquisa, vários materiais",
                        mod: "Acanthamoeba, pesquisa, vários materiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310698",
                        original: "Acanthamoeba, cultura",
                        mod: "Acanthamoeba, cultura",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310701",
                        original: "Corynebacterium diphiteriae, pesquisa",
                        mod: "Corynebacterium diphiteriae, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310710",
                        original: "Pesquisa de VRE",
                        mod: "Pesquisa de VRE",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310728",
                        original: "Fungos morfologia/bioquímica",
                        mod: "Fungos morfologia/bioquímica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40310736",
                        original: "Identificação de bactérias por método sorológico/bioquímico",
                        mod: "Identificação de bactérias por método sorológico/bioquímico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311015",
                        original: "Ácido cítrico na urina",
                        mod: "Ácido cítrico - pesquisa e/ou dosagem na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311023",
                        original: "Ácido homogentísico na urina",
                        mod: "Ácido homogentísico - pesquisa e/ou dosagem na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311031",
                        original: "Alcaptonúria  na urina",
                        mod: "Alcaptonúria  - pesquisa e/ou dosagem na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311040",
                        original: "Cálculos urinários - análise",
                        mod: "Cálculos urinários - análise",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311058",
                        original: "Catecolaminas fracionadas - dopamina, epinefrina, norepinefrina (cada) na urina",
                        mod: "Catecolaminas fracionadas - dopamina, epinefrina, norepinefrina (cada) - pesquisa e/ou dosagem na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311066",
                        original: "Cistinúria, pesquisa",
                        mod: "Cistinúria, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311074",
                        original: "Coproporfirina III na urina",
                        mod: "Coproporfirina III - pesquisa e/ou dosagem na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311082",
                        original: "Corpos cetônicos, pesquisa - na urina",
                        mod: "Corpos cetônicos, pesquisa - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311090",
                        original: "Cromatografia de açúcares - na urina",
                        mod: "Cromatografia de açúcares - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311104",
                        original: "Dismorfismo eritrocitário, pesquisa (contraste de fase) - na urina",
                        mod: "Dismorfismo eritrocitário, pesquisa (contraste de fase) - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311112",
                        original: "Erros inatos do metabolismo baterias de testes químicos de triagem em urina (mínimo de 6 testes)",
                        mod: "Erros inatos do metabolismo baterias de testes químicos de triagem em urina (mínimo de 6 testes)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311120",
                        original: "Frutosúria, pesquisa",
                        mod: "Frutosúria, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311139",
                        original: "Galactosúria, pesquisa",
                        mod: "Galactosúria, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311147",
                        original: "Lipóides, pesquisa - na urina",
                        mod: "Lipóides, pesquisa - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311155",
                        original: "Melanina, pesquisa - na urina",
                        mod: "Melanina, pesquisa - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311163",
                        original: "Metanefrinas urinárias, dosagem",
                        mod: "Metanefrinas urinárias, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311171",
                        original: "Microalbuminúria",
                        mod: "Microalbuminúria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311180",
                        original: "Pesquisa ou dosagem de um componente urinário",
                        mod: "Dosagem de proteína na urina de 24 horas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311198",
                        original: "Porfobilinogênio, pesquisa - na urina",
                        mod: "Porfobilinogênio, pesquisa - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311201",
                        original: "Proteínas de Bence Jones, pesquisa - na urina",
                        mod: "Proteínas de Bence Jones, pesquisa - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311210",
                        original: "Rotina de urina (caracteres físicos, elementos anormais e sedimentoscopia)",
                        mod: "Rotina de urina (com sedimento urinário)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311228",
                        original: "Uroporfirinas, dosagem",
                        mod: "Uroporfirinas, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311236",
                        original: "2,5-hexanodiona, dosagem na urina",
                        mod: "2,5-hexanodiona, dosagem na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311244",
                        original: "Cistina na urina",
                        mod: "Cistina - pesquisa e/ou dosagem na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311252",
                        original: "Porfobilinogênio - na urina",
                        mod: "Porfobilinogênio - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311260",
                        original: "Acidez titulável na urina",
                        mod: "Acidez titulável - pesquisa e/ou dosagem na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311279",
                        original: "Bartituratos na urina",
                        mod: "Bartituratos - pesquisa e/ou dosagem na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311287",
                        original: "Beta mercapto-lactato-disulfidúria,pesquisa - na urina",
                        mod: "Beta mercapto-lactato-disulfidúria,pesquisa - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311295",
                        original: "Contagem sedimentar de Addis",
                        mod: "Contagem sedimentar de Addis",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311309",
                        original: "Eletroforese de proteínas urinárias, com concentração",
                        mod: "Eletroforese de proteínas urinárias, com concentração",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311317",
                        original: "Fenilcetonúria, pesquisa",
                        mod: "Fenilcetonúria, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311325",
                        original: "Histidina, pesquisa - na urina",
                        mod: "Histidina, pesquisa - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311333",
                        original: "Inclusão citomegálica, pesquisa de células com - na urina",
                        mod: "Inclusão citomegálica, pesquisa de células com - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311341",
                        original: "Mioglobina, pesquisa - na urina",
                        mod: "Mioglobina, pesquisa - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311350",
                        original: "Osmolalidade, determinação - na urina",
                        mod: "Osmolalidade, determinação - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311368",
                        original: "Prova de concentração (Fishberg ou Volhard) - na urina",
                        mod: "Prova de concentração (Fishberg ou Volhard) - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311376",
                        original: "Prova de diluição - na urina",
                        mod: "Prova de diluição - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311384",
                        original: "Sobrecarga de água, prova - na urina",
                        mod: "Sobrecarga de água, prova - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311392",
                        original: "Tirosinose, pesquisa - na urina",
                        mod: "Tirosinose, pesquisa - na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311406",
                        original: "Fenciclidina na urina (amostra isolada)",
                        mod: "Fenciclidina na urina (amostra isolada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311414",
                        original: "Ferro urinário (urina 24h)",
                        mod: "Ferro urinário (urina 24h)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311422",
                        original: "Gordura na urina, pesquisa de",
                        mod: "Gordura na urina, pesquisa de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311430",
                        original: "Hemoglobina livre na urina (amostra isolada)",
                        mod: "Hemoglobina livre na urina (amostra isolada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311449",
                        original: "MDMA (Êxtase), triagem (urina)",
                        mod: "MDMA (Êxtase), triagem (urina)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311457",
                        original: "Pesquisa de espermatozóide na urina",
                        mod: "Pesquisa de espermatozóide na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311465",
                        original: "Substâncias redutoras, pesquisa (urina)",
                        mod: "Substâncias redutoras, pesquisa (urina)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311473",
                        original: "Teste de concentração urinária após DDAVP",
                        mod: "Teste de concentração urinária após DDAVP",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311481",
                        original: "Urina, pesquisa antígeno para Legionella",
                        mod: "Urina, pesquisa antígeno para Legionella",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311490",
                        original: "Urocitograma",
                        mod: "Urocitograma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40311503",
                        original: "Pesquisa de sulfatídeos e material metacromático na urina",
                        mod: "Pesquisa de sulfatídeos e material metacromático na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312011",
                        original: "Cristalização do muco cervical, pequisa",
                        mod: "Cristalização do muco cervical, pequisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312020",
                        original: "Cromatina sexual, pesquisa",
                        mod: "Cromatina sexual, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312046",
                        original: "Iontoforese para a coleta de suor, com dosagem de cloro",
                        mod: "Iontoforese para a coleta de suor, com dosagem de cloro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312054",
                        original: "Muco-nasal, pesquisa de eosinófilos e mastócitos",
                        mod: "Muco-nasal, pesquisa de eosinófilos e mastócitos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312062",
                        original: "Perfil  metabólico  para  litíase  renal: sangue (Ca, P, AU, Cr) urina: (Ca, AU, P, citr, pesq. Cistina) AMP-cíclico",
                        mod: "Perfil  metabólico  para  litíase  renal: sangue (Ca, P, AU, Cr) urina: (Ca, AU, P, citr, pesq. Cistina) AMP-cíclico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312070",
                        original: "Gastroacidograma - secreção basal para 60' e 4 amostras após o estímulo (fornecimento de material inclusive tubagem), teste",
                        mod: "Gastroacidograma - secreção basal para 60' e 4 amostras após o estímulo (fornecimento de material inclusive tubagem), teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312089",
                        original: "Hollander (inclusive tubagem), teste",
                        mod: "Hollander (inclusive tubagem), teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312097",
                        original: "Pancreozima - secretina no suco duodenal, teste",
                        mod: "Pancreozima - secretina no suco duodenal, teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312100",
                        original: "Rotina da biles A, B, C e do suco duodenal (caracteres físicos e microscópicos inclusive tubagem)",
                        mod: "Rotina da biles A, B, C e do suco duodenal (caracteres físicos e microscópicos inclusive tubagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312119",
                        original: "Tubagem duodenal",
                        mod: "Tubagem duodenal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312127",
                        original: "Perfil reumatológico (ácido úrico, eletroforese de proteínas, FAN, VHS, prova do látex P/F. R, W. Rose)",
                        mod: "Perfil reumatológico (ácido úrico, eletroforese de proteínas, FAN, VHS, prova do látex P/F. R, W. Rose)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312135",
                        original: "pH - tornassol - pesquisa",
                        mod: "pH - tornassol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312143",
                        original: "Prova atividade de febre reumática (aslo, eletroforese de proteínas, muco-proteínas e proteína 'C' reativa)",
                        mod: "Prova atividade de febre reumática (aslo, eletroforese de proteínas, muco-proteínas e proteína 'C' reativa)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312151",
                        original: "Provas de função hepática (bilirrubinas, eletroforese de proteínas, FA, TGO, TGP e Gama-PGT)",
                        mod: "Provas de função hepática (bilirrubinas, eletroforese de proteínas, FA, TGO, TGP e Gama-PGT)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312160",
                        original: "Teste do pezinho básico (TSH neonatal + fenilalanina + eletroforese de Hb para triagem de hemopatias)",
                        mod: "Teste do pezinho básico (TSH neonatal + fenilalanina + eletroforese de Hb para triagem de hemopatias)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312178",
                        original: "Teste do pezinho ampliado (TSH neonatal + 17 OH progesterona + fenilalanina + Tripsina imuno-reativa + eletroforese de Hb para triagem de hemopatias)",
                        mod: "Teste do pezinho ampliado (TSH neonatal + 17 OH progesterona + fenilalanina + Tripsina imuno-reativa + eletroforese de Hb para triagem de hemopatias)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312186",
                        original: "Cálculo biliar, análise química",
                        mod: "Cálculo biliar, análise química",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312194",
                        original: "Coleta de escarro induzida",
                        mod: "Coleta de escarro induzida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312208",
                        original: "Cristalografia análise de cálculo",
                        mod: "Cristalografia análise de cálculo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312216",
                        original: "Esclerose múltipla, painel",
                        mod: "Esclerose múltipla, painel",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312224",
                        original: "Espectrometria de massa em tandem",
                        mod: "Espectrometria de massa em tandem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312232",
                        original: "Identificação de verme",
                        mod: "Identificação de verme",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312240",
                        original: "Isolamento de microorganismos especiais",
                        mod: "Isolamento de microorganismos especiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312259",
                        original: "Lavado gástrico, colheita por",
                        mod: "Lavado gástrico, colheita por",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312267",
                        original: "Líquido pleural citológico",
                        mod: "Líquido pleural citológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312275",
                        original: "Pepsinogenio 2",
                        mod: "Pepsinogenio 2",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312283",
                        original: "Perfil para líquidos biológicos",
                        mod: "Perfil para líquidos biológicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312291",
                        original: "Proteína 14-3-3",
                        mod: "Proteína 14-3-3",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312305",
                        original: "Prova de restrição calórica",
                        mod: "Prova de restrição calórica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312313",
                        original: "Prova fármaco-dinâmica",
                        mod: "Prova fármaco-dinâmica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312321",
                        original: "Semiologia para impotência",
                        mod: "Semiologia para impotência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312330",
                        original: "Sexagem fetal",
                        mod: "Sexagem fetal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312348",
                        original: "Sorologia doença arranhadura de gato",
                        mod: "Sorologia doença arranhadura de gato",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312364",
                        original: "Vírus respiratório, triagem aspirado nasofaringeo",
                        mod: "Vírus respiratório, triagem aspirado nasofaringeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312372",
                        original: "Vírus respiratório, triagem lavado nasofaringeo",
                        mod: "Vírus respiratório, triagem lavado nasofaringeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312380",
                        original: "Vírus respiratório, triagem secreção orofaringe",
                        mod: "Vírus respiratório, triagem secreção orofaringe",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312399",
                        original: "Vírus respiratório, triagem secreção traqueal",
                        mod: "Vírus respiratório, triagem secreção traqueal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312402",
                        original: "Vírus respiratório, triagem swab nasal",
                        mod: "Vírus respiratório, triagem swab nasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312410",
                        original: "Teste respiratório da xylose",
                        mod: "Teste respiratório da xylose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312429",
                        original: "Teste respiratório de intolerância a frutose",
                        mod: "Teste respiratório de intolerância a frutose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312437",
                        original: "Teste respiratório de intolerância a glicose",
                        mod: "Teste respiratório de intolerância a glicose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312445",
                        original: "Teste respiratório de intolerânica a lactose",
                        mod: "Teste respiratório de intolerânica a lactose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312453",
                        original: "Teste respiratório de intolerância a sacarose",
                        mod: "Teste respiratório de intolerância a sacarose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312461",
                        original: "Teste respiratório de intolerância a sorbitol",
                        mod: "Teste respiratório de intolerância a sorbitol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40312470",
                        original: "Teste respiratório da lactulose (8 amostras)",
                        mod: "Teste respiratório da lactulose (8 amostras)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313018",
                        original: "Ácido delta aminolevulínico (para chumbo inorgânico)",
                        mod: "Ácido delta aminolevulínico (para chumbo inorgânico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313026",
                        original: "Ácido delta aminolevulínico desidratase (para chumbo inorgânico) - pesquisa e/ou dosagem",
                        mod: "Ácido delta aminolevulínico desidratase (para chumbo inorgânico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313034",
                        original: "Ácido fenilglioxílico (para estireno) - pesquisa e/ou dosagem",
                        mod: "Ácido fenilglioxílico (para estireno)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313042",
                        original: "Ácido hipúrico (para tolueno) - pesquisa e/ou dosagem",
                        mod: "Ácido hipúrico (para tolueno)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313050",
                        original: "Ácido mandélico (para estireno) - pesquisa e/ou dosagem",
                        mod: "Ácido mandélico (para estireno)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313069",
                        original: "Ácido metilhipúrico (para xilenos) - pesquisa e/ou dosagem",
                        mod: "Ácido metilhipúrico (para xilenos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313077",
                        original: "Ácido salicílico - pesquisa e/ou dosagem",
                        mod: "Ácido salicílico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313085",
                        original: "Azida sódica, teste da (para deissulfeto de carbono)",
                        mod: "Azida sódica, teste da (para deissulfeto de carbono)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313093",
                        original: "Carboxihemoglobina (para monóxido de carbono  diclorometano) - pesquisa e/ou dosagem",
                        mod: "Carboxihemoglobina (para monóxido de carbono  diclorometano)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313107",
                        original: "Chumbo - pesquisa e/ou dosagem",
                        mod: "Chumbo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313115",
                        original: "Colinesterase (para carbamatos  organofosforados) - pesquisa e/ou dosagem",
                        mod: "Colinesterase (para carbamatos  organofosforados)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313123",
                        original: "Coproporfirinas (para chumbo inorgânico) - pesquisa e/ou dosagem",
                        mod: "Coproporfirinas (para chumbo inorgânico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313131",
                        original: "Dialdeído malônico - pesquisa e/ou dosagem",
                        mod: "Dialdeído malônico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313140",
                        original: "Etanol - pesquisa e/ou dosagem",
                        mod: "Etanol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313158",
                        original: "Fenol (para benzeno, fenol) - pesquisa e/ou dosagem",
                        mod: "Fenol (para benzeno, fenol)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313166",
                        original: "Flúor (para fluoretos) - pesquisa e/ou dosagem",
                        mod: "Flúor (para fluoretos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313174",
                        original: "Formaldeído - pesquisa e/ou dosagem",
                        mod: "Formaldeído",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313182",
                        original: "Meta-hemoglobina (para anilina nitrobenzeno) - pesquisa e/ou dosagem",
                        mod: "Meta-hemoglobina (para anilina nitrobenzeno)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313190",
                        original: "Metais Al, As, Cd, Cr, Mn, Hg, Ni, Zn, Co, outro (s) absorção atômica (cada) - pesquisa e/ou dosagem",
                        mod: "Metais Al, As, Cd, Cr, Mn, Hg, Ni, Zn, Co, outro (s) absorção atômica (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313204",
                        original: "Metanol - pesquisa e/ou dosagem",
                        mod: "Metanol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313212",
                        original: "P-aminofenol (para anilina) - pesquisa e/ou dosagem",
                        mod: "P-aminofenol (para anilina)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313220",
                        original: "P-nitrofenol (para nitrobenzeno) - pesquisa e/ou dosagem",
                        mod: "P-nitrofenol (para nitrobenzeno)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313239",
                        original: "Protoporfirinas livres (para chumbo inorgânico) - pesquisa e/ou dosagem",
                        mod: "Protoporfirinas livres (para chumbo inorgânico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313247",
                        original: "Protoporfirinas Zn (para chumbo inorgânico) - pesquisa e/ou dosagem",
                        mod: "Protoporfirinas Zn (para chumbo inorgânico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313255",
                        original: "Selênio, dosagem",
                        mod: "Selênio, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313263",
                        original: "Sulfatos orgânicos ou inorgânicos, pesquisa (cada)",
                        mod: "Sulfatos orgânicos ou inorgânicos, pesquisa (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313271",
                        original: "Tiocianato (para cianetos  nitrilas alifáticas) - pesquisa e/ou dosagem",
                        mod: "Tiocianato (para cianetos  nitrilas alifáticas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313280",
                        original: "Triclorocompostos totais (para tetracloroetileno, tricloroetano, tricloroetileno) - pesquisa e/ou dosagem",
                        mod: "Triclorocompostos totais (para tetracloroetileno, tricloroetano, tricloroetileno)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313298",
                        original: "Ácido acético - pesquisa e/ou dosagem",
                        mod: "Ácido acético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313301",
                        original: "Ácido metil malônico - pesquisa e/ou dosagem",
                        mod: "Ácido metil malônico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313310",
                        original: "Cromo - pesquisa e/ou dosagem",
                        mod: "Cromo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313328",
                        original: "Zinco - pesquisa e/ou dosagem",
                        mod: "Zinco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313336",
                        original: "Salicilatos, pesquisa",
                        mod: "Salicilatos, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313344",
                        original: "Metil Etil Cetona - pesquisa e/ou dosagem",
                        mod: "Metil Etil Cetona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313352",
                        original: "Berílio, soro",
                        mod: "Berílio, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40313360",
                        original: "Brometo, sangue total",
                        mod: "Brometo, sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314014",
                        original: "Apolipoproteína E, genotipagem",
                        mod: "Apolipoproteína E, genotipagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314022",
                        original: "Citomegalovírus - qualitativo, por PCR - pesquisa",
                        mod: "Citomegalovírus - qualitativo, por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314030",
                        original: "Citomegalovírus - quantitativo, por PCR - pesquisa",
                        mod: "Citomegalovírus - quantitativo, por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314049",
                        original: "Cromossomo philadelfia - pesquisa",
                        mod: "Cromossomo philadelfia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314057",
                        original: "Fator V de Leiden por PCR - pesquisa",
                        mod: "Fator V de Leiden por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314065",
                        original: "Fibrose cística, pesquisa de uma mutação",
                        mod: "Fibrose cística, pesquisa de uma mutação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314065",
                        original: "Doenças do gene CFTL (fibrose cística) pesquisa de uma mutação (DF508)",
                        mod: "Doenças do gene CFTL (fibrose cística) pesquisa de uma mutação (DF508)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314073",
                        original: "Hepatite B (qualitativo) PCR - pesquisa",
                        mod: "Hepatite B (qualitativo) PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314081",
                        original: "Hepatite B (quantitativo) PCR - pesquisa",
                        mod: "Hepatite B (quantitativo) PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314090",
                        original: "Hepatite C (qualitativo) por PCR - pesquisa",
                        mod: "Hepatite C (qualitativo) por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314103",
                        original: "Hepatite C (quantitativo) por PCR - pesquisa",
                        mod: "Hepatite C (quantitativo) por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314111",
                        original: "Hepatite C - genotipagem - pesquisa",
                        mod: "Hepatite C - genotipagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314120",
                        original: "HIV - carga viral PCR - pesquisa",
                        mod: "HIV - carga viral PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314138",
                        original: "HIV - qualitativo por PCR - pesquisa",
                        mod: "HIV - qualitativo por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314146",
                        original: "HIV, genotipagem - pesquisa",
                        mod: "HIV, genotipagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314154",
                        original: "HPV (vírus do papiloma humano) + subtipagem quando necessário PCR - pesquisa",
                        mod: "HPV (vírus do papiloma humano) + subtipagem quando necessário PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314162",
                        original: "HTLV I / II por PCR (cada) - pesquisa",
                        mod: "HTLV I / II por PCR (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314170",
                        original: "Mycobactéria PCR - pesquisa",
                        mod: "Mycobactéria PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314189",
                        original: "Parvovírus por PCR - pesquisa",
                        mod: "Parvovírus por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314197",
                        original: "Proteína S total + livre, dosagem",
                        mod: "Proteína S total + livre, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314200",
                        original: "Rubéola por PCR - pesquisa",
                        mod: "Rubéola por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314219",
                        original: "Sífilis por PCR - pesquisa",
                        mod: "Sífilis por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314227",
                        original: "Toxoplasmose por PCR - pesquisa",
                        mod: "Toxoplasmose por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314235",
                        original: "X frágil por PCR - pesquisa",
                        mod: "X frágil por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314243",
                        original: "Chlamydia por biologia molecular - pesquisa",
                        mod: "Chlamydia por biologia molecular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314251",
                        original: "Citogenética de medula óssea",
                        mod: "Citogenética de medula óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314251",
                        original: "Citogenética de medula óssea",
                        mod: "Citogenética de medula óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314260",
                        original: "Amplificação de material por biologia molecular (outros agentes)",
                        mod: "Amplificação de material por biologia molecular (outros agentes)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314278",
                        original: "Pesquisa de outros agentes por PCR",
                        mod: "Pesquisa de outros agentes por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314286",
                        original: "Pesquisa de mutação de alelo específico por PCR",
                        mod: "Pesquisa de mutação de alelo específico por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314294",
                        original: "Resistência a agentes antivirais por biologia molecular (cada droga) - pesquisa",
                        mod: "Resistência a agentes antivirais por biologia molecular (cada droga)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314308",
                        original: "Quantificação de outros agentes por PCR",
                        mod: "Quantificação de outros agentes por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314316",
                        original: "SCIH tipagem molecular de bactérias",
                        mod: "SCIH tipagem molecular de bactérias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314324",
                        original: "Clonalidade para células B, detecção por PCR, vários materiais",
                        mod: "Clonalidade para células B, detecção por PCR, vários materiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314332",
                        original: "Clonalidade para células T, detecção por PCR, sangue total",
                        mod: "Clonalidade para células T, detecção por PCR, sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314340",
                        original: "Coronavirus, detecção por PCR",
                        mod: "Coronavirus, detecção por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314359",
                        original: "Epstein BARR vírus por PCR",
                        mod: "Epstein BARR vírus por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314367",
                        original: "Hepatite B, genotipagem",
                        mod: "Hepatite B, genotipagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314375",
                        original: "Herpes vírus humano 1,2 por PCR",
                        mod: "Herpes vírus humano 1,2 por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314383",
                        original: "Herpes vírus humano 6,7 por PCR",
                        mod: "Herpes vírus humano 6,7 por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314391",
                        original: "Herpes vírus humano 8 por PCR",
                        mod: "Herpes vírus humano 8 por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314405",
                        original: "Herpes vírus zoster, (Varicella) por PCR",
                        mod: "Herpes vírus zoster, (Varicella) por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314413",
                        original: "Hepatite C quantitativo por TMA",
                        mod: "Hepatite C quantitativo por TMA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314421",
                        original: "Papiloma virus humano, genotipagem",
                        mod: "Papiloma virus humano, genotipagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314430",
                        original: "HLA-B27, fenotipagem",
                        mod: "HLA-B27",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314448",
                        original: "HPV oncoproteínas virais E6/E7, pesquisa",
                        mod: "HPV oncoproteínas virais E6/E7, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314456",
                        original: "Clostridium Difficile Toxigênico, pesquisa DNA, fezes",
                        mod: "Clostridium Difficile Toxigênico, pesquisa DNA, fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314472",
                        original: "Detecção de bactérias e fungos em sangue periférico (Septifast), por PCR",
                        mod: "Detecção de bactérias e fungos em sangue periférico (Septifast), por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314480",
                        original: "Análise de quimerismo pós transplante (STR), cada",
                        mod: "Análise de quimerismo pós transplante (STR), cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314499",
                        original: "Teste de biologia molecular para hiperplasia adrenal congênita",
                        mod: "Teste de biologia molecular para hiperplasia adrenal congênita",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314502",
                        original: "HIV amplificação do DNA (PCR)",
                        mod: "HIV amplificação do DNA (PCR)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314510",
                        original: "Pesquisa de mutação pré-core vírus hepatite B",
                        mod: "Pesquisa de mutação pré-core vírus hepatite B",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314529",
                        original: "TMA qualitativo para hepatite C",
                        mod: "TMA qualitativo para hepatite C",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314537",
                        original: "Chlamydia - PCR, amplificação de DNA",
                        mod: "Chlamydia - PCR, amplificação de DNA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314545",
                        original: "Mycobactéria amplificação de DNA (PCR)",
                        mod: "Mycobactéria amplificação de DNA (PCR)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314561",
                        original: "Vírus Zika - por PCR",
                        mod: "Vírus Zika - por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314618",
                        original: "Coronavírus Covid-19, pesquisa por método molecular",
                        mod: "Coronavírus Covid-19, pesquisa por método molecular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40314618",
                        original: "SARS-CoV-2 (CORONAVÍRUS COVID-19) - PESQUISA POR RT - PCR",
                        mod: "SARS-CoV-2 (CORONAVÍRUS COVID-19) - PESQUISA POR RT - PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316017",
                        original: "17-alfa-hidroxiprogesterona - pesquisa e/ou dosagem",
                        mod: "17-alfa-hidroxiprogesterona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316025",
                        original: "3 alfa androstonediol glucoronídeo (3ALFDADIOL) - pesquisa e/ou dosagem",
                        mod: "3 alfa androstonediol glucoronídeo (3ALFDADIOL)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316033",
                        original: "Ácido vanilmandélico (VMA) - pesquisa e/ou dosagem",
                        mod: "Ácido vanilmandélico (VMA)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316041",
                        original: "Adrenocorticotrófico, hormônio (ACTH) - pesquisa e/ou dosagem",
                        mod: "Adrenocorticotrófico, hormônio (ACTH)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316050",
                        original: "Aldosterona - pesquisa e/ou dosagem",
                        mod: "Aldosterona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316068",
                        original: "Alfa-fetoproteína - pesquisa e/ou dosagem",
                        mod: "Alfa-fetoproteína",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316076",
                        original: "Androstenediona - pesquisa e/ou dosagem",
                        mod: "Androstenediona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316084",
                        original: "Anticorpo anti-receptor de TSH (TRAB) - pesquisa e/ou dosagem",
                        mod: "Anticorpo anti-receptor de TSH (TRAB)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316092",
                        original: "Anticorpos antiinsulina - pesquisa e/ou dosagem",
                        mod: "Anticorpos antiinsulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316106",
                        original: "Anticorpos antitireóide (tireoglobulina) - pesquisa e/ou dosagem",
                        mod: "Anticorpos tireoglobulina (Anti-TGB)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316114",
                        original: "Antígeno Austrália (HBSAG) - pesquisa e/ou dosagem",
                        mod: "Antígeno Austrália (HBSAG)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316122",
                        original: "Antígeno carcinoembriogênico (CEA) - pesquisa e/ou dosagem",
                        mod: "Antígeno carcinoembriogênico (CEA)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316130",
                        original: "Antígeno específico prostático livre (PSA livre) - pesquisa e/ou dosagem",
                        mod: "Antígeno específico prostático livre (PSA livre)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316149",
                        original: "Antígeno específico prostático total (PSA) - pesquisa e/ou dosagem",
                        mod: "Antígeno específico prostático total (PSA)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316157",
                        original: "Anti-TPO - pesquisa e/ou dosagem",
                        mod: "Anti-Tireoperoxidase (Anti-TPO)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316165",
                        original: "Calcitonina - pesquisa e/ou dosagem",
                        mod: "Calcitonina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316173",
                        original: "Catecolaminas - pesquisa e/ou dosagem",
                        mod: "Catecolaminas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316181",
                        original: "Composto S (11-desoxicortisol) - pesquisa e/ou dosagem",
                        mod: "Composto S (11-desoxicortisol)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316190",
                        original: "Cortisol - pesquisa e/ou dosagem",
                        mod: "Cortisol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316203",
                        original: "Crescimento, hormônio do (HGH) - pesquisa e/ou dosagem",
                        mod: "Crescimento, hormônio do (HGH)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316211",
                        original: "Dehidroepiandrosterona (DHEA) - pesquisa e/ou dosagem",
                        mod: "Dehidroepiandrosterona (DHEA)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316220",
                        original: "Dehidrotestosterona (DHT) - pesquisa e/ou dosagem",
                        mod: "Dehidrotestosterona (DHT)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316238",
                        original: "Drogas (imunossupressora, anticonvulsivante, digitálico, etc.) cada - pesquisa e/ou dosagem",
                        mod: "Drogas (imunossupressora, anticonvulsivante, digitálico, etc.) cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316246",
                        original: "Estradiol - pesquisa e/ou dosagem",
                        mod: "Estradiol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316254",
                        original: "Estriol - pesquisa e/ou dosagem",
                        mod: "Estriol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316262",
                        original: "Estrona - pesquisa e/ou dosagem",
                        mod: "Estrona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316270",
                        original: "Ferritina - pesquisa e/ou dosagem",
                        mod: "Ferritina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316289",
                        original: "Folículo estimulante, hormônio (FSH) - pesquisa e/ou dosagem",
                        mod: "Folículo estimulante, hormônio (FSH)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316297",
                        original: "Gastrina - pesquisa e/ou dosagem",
                        mod: "Gastrina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316300",
                        original: "Globulina de ligação de hormônios sexuais (SHBG) - pesquisa e/ou dosagem",
                        mod: "Globulina de ligação de hormônios sexuais (SHBG)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316319",
                        original: "Globulina transportadora da tiroxina (TBG) - pesquisa e/ou dosagem",
                        mod: "Globulina transportadora da tiroxina (TBG)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316327",
                        original: "Gonadotrófico coriônico, hormônio (HCG) - pesquisa e/ou dosagem",
                        mod: "Gonadotrófico coriônico, hormônio (HCG)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316335",
                        original: "Hormônio luteinizante (LH) - pesquisa e/ou dosagem",
                        mod: "Hormônio luteinizante (LH)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316343",
                        original: "Imunoglobulina (IGE) - pesquisa e/ou dosagem",
                        mod: "Imunoglobulina (IGE)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316351",
                        original: "Índice de tiroxina livre (ITL) - pesquisa e/ou dosagem",
                        mod: "Índice de tiroxina livre (ITL)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316360",
                        original: "Insulina - pesquisa e/ou dosagem",
                        mod: "Insulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316378",
                        original: "Marcadores tumorais (CA 19.9, CA 125, CA 72-4, CA 15-3, etc.) cada - pesquisa e/ou dosagem",
                        mod: "Marcadores tumorais (CA 19.9, CA 125, CA 72-4, CA 15-3, etc.) cada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316386",
                        original: "Osteocalcina - pesquisa e/ou dosagem",
                        mod: "Osteocalcina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316394",
                        original: "Peptídeo C - pesquisa e/ou dosagem",
                        mod: "Peptídeo C",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316408",
                        original: "Progesterona - pesquisa e/ou dosagem",
                        mod: "Progesterona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316416",
                        original: "Prolactina - pesquisa e/ou dosagem",
                        mod: "Prolactina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316424",
                        original: "PTH - pesquisa e/ou dosagem",
                        mod: "PTH",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316432",
                        original: "Renina - pesquisa e/ou dosagem",
                        mod: "Renina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316440",
                        original: "Somatomedina C (IGF1) - pesquisa e/ou dosagem",
                        mod: "Somatomedina C (IGF1)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316459",
                        original: "Sulfato de dehidroepiandrosterona (S-DHEA) - pesquisa e/ou dosagem",
                        mod: "Sulfato de dehidroepiandrosterona (S-DHEA)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316467",
                        original: "T3 livre - pesquisa e/ou dosagem",
                        mod: "T3 livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316475",
                        original: "T3 retenção - pesquisa e/ou dosagem",
                        mod: "T3 retenção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316483",
                        original: "T3 reverso - pesquisa e/ou dosagem",
                        mod: "T3 reverso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316491",
                        original: "T4 livre - pesquisa e/ou dosagem",
                        mod: "T4 livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316505",
                        original: "Testosterona livre - pesquisa e/ou dosagem",
                        mod: "Testosterona livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316513",
                        original: "Testosterona total - pesquisa e/ou dosagem",
                        mod: "Testosterona total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316521",
                        original: "Tireoestimulante, hormônio (TSH) - pesquisa e/ou dosagem",
                        mod: "TSH",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316530",
                        original: "Tireoglobulina - pesquisa e/ou dosagem",
                        mod: "Tireoglobulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316548",
                        original: "Tiroxina (T4) - pesquisa e/ou dosagem",
                        mod: "Tiroxina (T4)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316556",
                        original: "Triiodotironina (T3) - pesquisa e/ou dosagem",
                        mod: "Triiodotironina (T3)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316564",
                        original: "Vasopressina (ADH) - pesquisa e/ou dosagem",
                        mod: "Vasopressina (ADH)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316572",
                        original: "Vitamina B12 - pesquisa e/ou dosagem",
                        mod: "Vitamina B12",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316580",
                        original: "Adiponectina",
                        mod: "Adiponectina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316599",
                        original: "AMP cíclico nefrogênico na urina (24h)",
                        mod: "AMP cíclico nefrogênico na urina (24h)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316602",
                        original: "AMP cíclico nefrogênico na urina (amostra isolada)",
                        mod: "AMP cíclico nefrogênico na urina (amostra isolada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316610",
                        original: "Anabolizantes esteróides na urina (amostra isolada)",
                        mod: "Anabolizantes esteróides na urina (amostra isolada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316629",
                        original: "Androsterona",
                        mod: "Androsterona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316637",
                        original: "Angiotensina II",
                        mod: "Angiotensina II",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316645",
                        original: "Anticorpos antinucleossomo, soro",
                        mod: "Anticorpos antinucleossomo, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316653",
                        original: "Antígenos prostático específico complexado, soro",
                        mod: "Antígenos prostático específico complexado, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316661",
                        original: "Aquaporina 4 (AQP4)",
                        mod: "Aquaporina 4 (AQP4)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316670",
                        original: "Capacidade de ligação da vitamina B12",
                        mod: "Capacidade de ligação da vitamina B12",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316688",
                        original: "Corticosterona",
                        mod: "Corticosterona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316696",
                        original: "CRH",
                        mod: "CRH",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316700",
                        original: "Cromogranina A",
                        mod: "Cromogranina A",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316718",
                        original: "Cromogranina A, soro",
                        mod: "Cromogranina A, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316726",
                        original: "Curva de peptídeo C e insulina",
                        mod: "Curva de peptídeo C e insulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316734",
                        original: "Curva glicêmica (7 dosagens) via oral ou endovenosa ou potencializada",
                        mod: "Curva glicêmica (7 dosagens) via oral ou endovenosa ou potencializada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316742",
                        original: "DCB",
                        mod: "DCB",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316750",
                        original: "Dehidro rodamina teste de oxidação",
                        mod: "Dehidro rodamina teste de oxidação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316769",
                        original: "Deoxicorticosterona, dosagem",
                        mod: "Deoxicorticosterona, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316777",
                        original: "Dosagem de 18 hidroxicortisterona",
                        mod: "Dosagem de 18 hidroxicortisterona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316785",
                        original: "Dosagem de ácido hipúrico em urina",
                        mod: "Dosagem de ácido hipúrico em urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316793",
                        original: "Dosagem de ácido trans-mucônico em urina",
                        mod: "Dosagem de ácido trans-mucônico em urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316807",
                        original: "Fator de crescimento vascular endotelial (VEGF)",
                        mod: "Fator de crescimento vascular endotelial (VEGF)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316815",
                        original: "Fator II de crescimento, símile a insulina",
                        mod: "Fator II de crescimento, símile a insulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316823",
                        original: "Fator liberador hormônio do crescimento GH",
                        mod: "Fator liberador hormônio do crescimento GH",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316831",
                        original: "Glicose após estímulo/glucagon",
                        mod: "Glicose após estímulo/glucagon",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316840",
                        original: "Glicosuria fracionada (4 dosagens)",
                        mod: "Glicosuria fracionada (4 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316858",
                        original: "Globulina ligadora do cortisol",
                        mod: "Globulina ligadora do cortisol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316866",
                        original: "Gonadotrofina coriônica - hemaglutinação ou látex",
                        mod: "Gonadotrofina coriônica - hemaglutinação ou látex",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316874",
                        original: "HGH estímulo com exercício e clonidina, HGH",
                        mod: "HGH estímulo com exercício e clonidina, HGH",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316882",
                        original: "Hipoglicemiantes (sulfonilurea painel)",
                        mod: "Hipoglicemiantes (sulfonilurea painel)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316890",
                        original: "Hormônio anti muleriano",
                        mod: "Hormônio anti muleriano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316904",
                        original: "Hormônio estimulador do alfa melanócito",
                        mod: "Hormônio estimulador do alfa melanócito",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316912",
                        original: "ICA 512",
                        mod: "ICA 512",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316920",
                        original: "IGFBP-1",
                        mod: "IGFBP-1",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316939",
                        original: "IGFBP-2",
                        mod: "IGFBP-2",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316947",
                        original: "Índice de recuperação da tireoglobulina",
                        mod: "Índice de recuperação da tireoglobulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316955",
                        original: "Insulina livre",
                        mod: "Insulina livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316963",
                        original: "Insulina total e livre",
                        mod: "Insulina total e livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316971",
                        original: "Megateste",
                        mod: "Megateste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316980",
                        original: "Melanócito estimulante hormônio (MSH)",
                        mod: "Melanócito estimulante hormônio (MSH)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40316998",
                        original: "Melatonina (sangue)",
                        mod: "Melatonina (sangue)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317013",
                        original: "Monitorização de glicose 1 dia",
                        mod: "Monitorização de glicose 1 dia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317021",
                        original: "Monitorização de glicose 2 dias",
                        mod: "Monitorização de glicose 2 dias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317030",
                        original: "Monitorização de glicose 3 dias",
                        mod: "Monitorização de glicose 3 dias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317048",
                        original: "PCI e TG após TSH recombinante",
                        mod: "PCI e TG após TSH recombinante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317056",
                        original: "Pregnenolona, dosagem",
                        mod: "Pregnenolona, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317064",
                        original: "Pró-insulina",
                        mod: "Pró-insulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317072",
                        original: "Proteína ligadora hormônio de crescimento GH",
                        mod: "Proteína ligadora hormônio de crescimento GH",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317080",
                        original: "Prova de sobrecarga de glicose para insulina",
                        mod: "Prova de sobrecarga de glicose para insulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317099",
                        original: "Receptor fator de crescimento epidermal",
                        mod: "Receptor fator de crescimento epidermal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317102",
                        original: "Sub-unidade Alfa hormônios glicoproteicos",
                        mod: "Sub-unidade Alfa hormônios glicoproteicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317110",
                        original: "Sub-unidade Beta da gonadotrofina coriônica livre",
                        mod: "Sub-unidade Beta da gonadotrofina coriônica livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317129",
                        original: "Teste com ACTH para dosagem de DHEA",
                        mod: "Teste com ACTH para dosagem de DHEA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317137",
                        original: "Teste com cálcio para dosar calcitonina",
                        mod: "Teste com cálcio para dosar calcitonina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317145",
                        original: "Teste com cortrosina para 17 alfa hidroxiprogesterona",
                        mod: "Teste com cortrosina para 17 alfa hidroxiprogesterona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317153",
                        original: "Teste com estímulo para renina após captopril",
                        mod: "Teste com estímulo para renina após captopril",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317161",
                        original: "Teste de estímulo com cortrosina para11 desoxicortisol",
                        mod: "Teste de estímulo com cortrosina para11 desoxicortisol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317170",
                        original: "Teste de estímulo com TRH para dosagem de GH",
                        mod: "Teste de estímulo com TRH para dosagem de GH",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317188",
                        original: "Teste de estímulo do GH pela insulina (4 dosagens de GH)",
                        mod: "Teste de estímulo do GH pela insulina (4 dosagens de GH)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317196",
                        original: "Teste de estímulo do GH pelo exercício (cada dosagem de GH)",
                        mod: "Teste de estímulo do GH pelo exercício (cada dosagem de GH)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317200",
                        original: "Teste de estímulo do GH pelo glucagon (4 dosagens de GH)",
                        mod: "Teste de estímulo do GH pelo glucagon (4 dosagens de GH)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317218",
                        original: "Teste de reserva funcional com l-arginina",
                        mod: "Teste de reserva funcional com l-arginina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317226",
                        original: "Teste de supressão do GH pela sobrecarga de glicose (cada dosagem de GH)",
                        mod: "Teste de supressão do GH pela sobrecarga de glicose (cada dosagem de GH)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317234",
                        original: "Tetrahidroaldosterona",
                        mod: "Tetrahidroaldosterona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317242",
                        original: "TRH, dosagem do",
                        mod: "TRH, dosagem do",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317250",
                        original: "Curva insulínica e glicêmica clássica",
                        mod: "Curva insulínica e glicêmica clássica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317269",
                        original: "Curva insulínica e glicêmica (2 dosagens)",
                        mod: "Curva insulínica e glicêmica (2 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317277",
                        original: "Curva insulínica e glicêmica (3 dosagens)",
                        mod: "Curva insulínica e glicêmica (3 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317285",
                        original: "Curva insulínica e glicêmica (4 dosagens)",
                        mod: "Curva insulínica e glicêmica (4 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317293",
                        original: "Curva insulínica e glicêmica (5 dosagens)",
                        mod: "Curva insulínica e glicêmica (5 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317307",
                        original: "Curva insulínica e glicêmica (7 dosagens)",
                        mod: "Curva insulínica e glicêmica (7 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317315",
                        original: "Curva insulínica e glicêmica (8 dosagens)",
                        mod: "Curva insulínica e glicêmica (8 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317323",
                        original: "Curva insulínica e glicêmica (9 dosagens)",
                        mod: "Curva insulínica e glicêmica (9 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317331",
                        original: "Curva glicêmica prolongada (7 dosagens)",
                        mod: "Curva glicêmica prolongada (7 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317340",
                        original: "C-Telopeptídeo",
                        mod: "C-Telopeptídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317366",
                        original: "Grelina, polipeptídeo liberador da gastrina, dosagem",
                        mod: "Grelina, polipeptídeo liberador da gastrina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317374",
                        original: "Cortisol ritmo (2 dosagens)",
                        mod: "Cortisol ritmo (2 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317382",
                        original: "Testosterona na saliva, dosagem",
                        mod: "Testosterona na saliva, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317390",
                        original: "Curva insulínica e glicêmica (6 dosagens)",
                        mod: "Curva insulínica e glicêmica (6 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317404",
                        original: "Metanefrinas urinária após clonidina",
                        mod: "Metanefrinas urinária após clonidina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317412",
                        original: "Paratomônio, proteína relacionada, dosagem",
                        mod: "Paratomônio, proteína relacionada, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317420",
                        original: "Proteína ligadora do hormônio de crescimento (HGH), dosagem",
                        mod: "Proteína ligadora do hormônio de crescimento (HGH), dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317439",
                        original: "Restrição hídrica, teste",
                        mod: "Restrição hídrica, teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317447",
                        original: "Teste de estímulo para cortisol com insulina (cada)",
                        mod: "Teste de estímulo para cortisol com insulina (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317455",
                        original: "Teste para CRH para dosar ACTH e cortisol",
                        mod: "Teste para CRH para dosar ACTH e cortisol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317463",
                        original: "Curva de HGH com clonidina (cada dosagem)",
                        mod: "Curva de HGH com clonidina (cada dosagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317471",
                        original: "Prova funcional de estímulo da prolactina após TRH sem fornecimento do medicamento (por dosagem)",
                        mod: "Prova funcional de estímulo da prolactina após TRH sem fornecimento do medicamento (por dosagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40317480",
                        original: "Prova de Testosterona, após HCG sem fornecimento do medicamento (por dosagem)",
                        mod: "Prova de Testosterona, após HCG sem fornecimento do medicamento (por dosagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319016",
                        original: "APT teste",
                        mod: "APT teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319024",
                        original: "Atividade de protease fator Von Willebrand",
                        mod: "Atividade de protease fator Von Willebrand",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319032",
                        original: "Cadeia Kappa leve livre",
                        mod: "Cadeia Kappa leve livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319040",
                        original: "Cadeia Kappa-Lambda leve livre",
                        mod: "Cadeia Kappa-Lambda leve livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319059",
                        original: "Células tumorais circulante mama Cellsearch",
                        mod: "Células tumorais circulante mama Cellsearch",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319067",
                        original: "Coenzima Q10",
                        mod: "Coenzima Q10",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319075",
                        original: "Fator alfa necrose tumoral",
                        mod: "Fator alfa necrose tumoral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319083",
                        original: "Fator B properdin, dosagem soro",
                        mod: "Fator B properdin, dosagem soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319091",
                        original: "Fator X ativado",
                        mod: "Fator X ativado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319105",
                        original: "Ferro medula óssea",
                        mod: "Ferro medula óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319113",
                        original: "Hemácias, contagem",
                        mod: "Hemácias, contagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319121",
                        original: "Hemácias, tempo de sobrevida das",
                        mod: "Hemácias, tempo de sobrevida das",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319130",
                        original: "Hemoglobina fetal, dosagem",
                        mod: "Hemoglobina fetal, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319148",
                        original: "Hemólise",
                        mod: "Hemólise",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319156",
                        original: "Índice de segmentação dos neutrófilos",
                        mod: "Índice de segmentação dos neutrófilos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319164",
                        original: "Linfócitos, cultura com candida albicans",
                        mod: "Linfócitos, cultura com candida albicans",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319172",
                        original: "Microesferócitos, pesquisa de",
                        mod: "Microesferócitos, pesquisa de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319180",
                        original: "Molibidenio (sangue)",
                        mod: "Molibidenio (sangue)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319199",
                        original: "Neutrófilos, pesquisa de",
                        mod: "Neutrófilos, pesquisa de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319202",
                        original: "Pesquisa de alfa talassemia",
                        mod: "Pesquisa de alfa talassemia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319210",
                        original: "Pesquisa de beta talassemia",
                        mod: "Pesquisa de beta talassemia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319229",
                        original: "Pesquisa hemoglobina H",
                        mod: "Pesquisa hemoglobina H",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319237",
                        original: "Protrombina fragmento 1 e 2",
                        mod: "Protrombina fragmento 1 e 2",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319245",
                        original: "Prova funcional DDAVP - hemofilia",
                        mod: "Prova funcional DDAVP - hemofilia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319253",
                        original: "Prova funcional DDAVP - Von Willebrand (1hora)",
                        mod: "Prova funcional DDAVP - Von Willebrand (1hora)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319261",
                        original: "Prova funcional DDAVP - Von Willebrand (4horas)",
                        mod: "Prova funcional DDAVP - Von Willebrand (4horas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319270",
                        original: "Tempo de Lise de Euglobulina",
                        mod: "Tempo de Lise de Euglobulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319288",
                        original: "Teste cruzado de grupos sanguíneos",
                        mod: "Teste cruzado de grupos sanguíneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319296",
                        original: "Teste de estímulo DDAQVP para dosagem de cortisol e ACTH",
                        mod: "Teste de estímulo DDAQVP para dosagem de cortisol e ACTH",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319300",
                        original: "Viscosidade plasmática ou sanguínea",
                        mod: "Viscosidade plasmática ou sanguínea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319318",
                        original: "Análise de multímeros para pacientes com doença de Von Willebrand",
                        mod: "Análise de multímeros para pacientes com doença de Von Willebrand",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319326",
                        original: "Protrombina, pesquisa de mutação",
                        mod: "Protrombina, pesquisa de mutação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319334",
                        original: "CD 52 marcador isolado",
                        mod: "CD 52 marcador isolado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319342",
                        original: "Disfibrinogenemia, pesquisa",
                        mod: "Disfibrinogenemia, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319350",
                        original: "HPA1 genotipagem",
                        mod: "HPA1 genotipagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319369",
                        original: "CD3, imunofenotipagem",
                        mod: "CD3, imunofenotipagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319377",
                        original: "CD34, imunofenotipagem",
                        mod: "CD34, imunofenotipagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319385",
                        original: "Ciclina D1, imunofenotipagem",
                        mod: "Ciclina D1, imunofenotipagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319393",
                        original: "Adesividade plaquetária",
                        mod: "Adesividade plaquetária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319407",
                        original: "Tempo de coagulação ativado (TCA)",
                        mod: "Tempo de coagulação ativado (TCA)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319415",
                        original: "Teste de viabilidade celular, citometria de fluxo, outros materiais",
                        mod: "Teste de viabilidade celular, citometria de fluxo, outros materiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319423",
                        original: "Anti-fator Xa, atividade",
                        mod: "Anti-fator Xa, atividade",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319431",
                        original: "Cross match plaquetário",
                        mod: "Cross match plaquetário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319440",
                        original: "Fator II, dosagem do inibidor",
                        mod: "Fator II, dosagem do inibidor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319458",
                        original: "Fator VII, dosagem do inibidor",
                        mod: "Fator VII, dosagem do inibidor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319466",
                        original: "Fibrinogênio quantitativo, nefelometria",
                        mod: "Fibrinogênio quantitativo, nefelometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319474",
                        original: "Hemoglobinopatias, neonatal, sangue periférico",
                        mod: "Hemoglobinopatias, neonatal, sangue periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40319482",
                        original: "Imunofenotipagem Imunod nível 2",
                        mod: "Imunofenotipagem Imunod nível 2",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321010",
                        original: "Colesterol esterificado",
                        mod: "Colesterol esterificado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321029",
                        original: "Deficiência da MCAD",
                        mod: "Deficiência da MCAD",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321037",
                        original: "Deficiente de carboidrato, transferrina",
                        mod: "Deficiente de carboidrato, transferrina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321045",
                        original: "Dexametasona",
                        mod: "Dexametasona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321053",
                        original: "Dióxido de carbono, dosagem",
                        mod: "Dióxido de carbono, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321061",
                        original: "Dosagem de cafeína",
                        mod: "Dosagem de cafeína",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321070",
                        original: "Dosagem de cortisona",
                        mod: "Dosagem de cortisona",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321088",
                        original: "Dosagem de eletrólitos (sol. diálise)",
                        mod: "Dosagem de eletrólitos (sol. diálise)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321096",
                        original: "Dosagem de ferro em tecido hepático",
                        mod: "Dosagem de ferro em tecido hepático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321100",
                        original: "Dosagem de metilfenidato",
                        mod: "Dosagem de metilfenidato",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321118",
                        original: "Dosagem de mitotane",
                        mod: "Dosagem de mitotane",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321126",
                        original: "Dosagem de pema, soro",
                        mod: "Dosagem de pema, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321134",
                        original: "Drogas de abuso, triagem",
                        mod: "Drogas de abuso, triagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321142",
                        original: "Efexor, dosagem",
                        mod: "Efexor, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321152",
                        original: "Ehrlichia chaffeensis, sorologia para",
                        mod: "Ehrlichia chaffeensis, sorologia para",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321169",
                        original: "Estrôncio, dosagem",
                        mod: "Estrôncio, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321177",
                        original: "Extase",
                        mod: "Extase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321185",
                        original: "Fungos morfologia/bioquímica",
                        mod: "Fungos morfologia/bioquímica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321193",
                        original: "Haloperidol, dosagem",
                        mod: "Haloperidol, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321207",
                        original: "Homocistina, pesquisa de",
                        mod: "Homocistina, pesquisa de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321215",
                        original: "Identificação de bactérias por método sorológico/bioquímico",
                        mod: "Identificação de bactérias por método sorológico/bioquímico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321223",
                        original: "Imipenem, dosagem",
                        mod: "Imipenem, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321231",
                        original: "Índice de saturação de ferro",
                        mod: "Índice de saturação de ferro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321240",
                        original: "Inibina A",
                        mod: "Inibina A",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321258",
                        original: "Inibina B, dosagem",
                        mod: "Inibina B, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321266",
                        original: "Interleucina 10",
                        mod: "Interleucina 10",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321274",
                        original: "Interleucina 1B",
                        mod: "Interleucina 1B",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321282",
                        original: "Interleucina 6",
                        mod: "Interleucina 6",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321290",
                        original: "Isoenzimas de CPK",
                        mod: "Isoenzimas de CPK",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321304",
                        original: "Isotretinoina, dosagem",
                        mod: "Isotretinoina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321312",
                        original: "Itraconazol",
                        mod: "Itraconazol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321320",
                        original: "Ketamina, dosagem soro",
                        mod: "Ketamina, dosagem soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321339",
                        original: "Kunkel (sulfato de zinco), reação de",
                        mod: "Kunkel (sulfato de zinco), reação de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321347",
                        original: "Levetiracetam, dosagem",
                        mod: "Levetiracetam, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321355",
                        original: "Lindane, dosagem",
                        mod: "Lindane, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321363",
                        original: "Maconha, dosagem soro",
                        mod: "Maconha, dosagem soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321371",
                        original: "Macroamilase",
                        mod: "Macroamilase",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321380",
                        original: "Marcadores cardíacos diagnósticos",
                        mod: "Marcadores cardíacos diagnósticos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321398",
                        original: "Metanefrinas plasmáticas",
                        mod: "Metanefrinas plasmáticas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321401",
                        original: "Morfina (sangue)",
                        mod: "Morfina (sangue)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321410",
                        original: "Neurontin",
                        mod: "Neurontin",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321428",
                        original: "Nicotina qualitativa urina (amostra isolada)",
                        mod: "Nicotina qualitativa urina (amostra isolada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321436",
                        original: "Nifedipina, dosagem",
                        mod: "Nifedipina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321444",
                        original: "Opiáceos quantitativo (urina)",
                        mod: "Opiáceos quantitativo (urina)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321452",
                        original: "Painel qualitativo para diurético (urina)",
                        mod: "Painel qualitativo para diurético (urina)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321460",
                        original: "Paroxetina, dosagem",
                        mod: "Paroxetina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321479",
                        original: "Penicilina, dosagem",
                        mod: "Penicilina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321487",
                        original: "Pesquisa de nitritos",
                        mod: "Pesquisa de nitritos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321495",
                        original: "Polipeptídeo pancreático",
                        mod: "Polipeptídeo pancreático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321509",
                        original: "Porfirinas fracionadas plasmáticas",
                        mod: "Porfirinas fracionadas plasmáticas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321517",
                        original: "Prozac, dosagem (sangue)",
                        mod: "Prozac, dosagem (sangue)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321525",
                        original: "Reserpina, dosagem",
                        mod: "Reserpina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321533",
                        original: "Resistência a proteína C ativada",
                        mod: "Resistência a proteína C ativada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321541",
                        original: "Respiridona, dosagem",
                        mod: "Respiridona, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321550",
                        original: "Serotonina (sangue)",
                        mod: "Serotonina (sangue)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321568",
                        original: "Sirolimus, dosagem",
                        mod: "Sirolimus, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321576",
                        original: "Somatostatina",
                        mod: "Somatostatina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321584",
                        original: "Substância P, dosagem",
                        mod: "Substância P, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321592",
                        original: "Tioridazina, dosagem",
                        mod: "Tioridazina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321606",
                        original: "Titânio, dosagem",
                        mod: "Titânio, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321614",
                        original: "Topiramato, dosagem (sangue)",
                        mod: "Topiramato, dosagem (sangue)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321622",
                        original: "Toxina botulinica, pesquisa (alimentos)",
                        mod: "Toxina botulinica, pesquisa (alimentos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321630",
                        original: "Toxina botulinica, pesquisa (sangue)",
                        mod: "Toxina botulinica, pesquisa (sangue)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321649",
                        original: "Toxina butoliníca A, anticorpo",
                        mod: "Toxina butoliníca A, anticorpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321657",
                        original: "Trifluoperazina, dosagem soro",
                        mod: "Trifluoperazina, dosagem soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321665",
                        original: "Triptase, dosagem",
                        mod: "Triptase, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321673",
                        original: "Vanádio, dosagem",
                        mod: "Vanádio, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321681",
                        original: "Vigabatrina, dosagem",
                        mod: "Vigabatrina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321690",
                        original: "Cefalexina dosagem",
                        mod: "Cefalexina dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321703",
                        original: "Ceftriaxona dosagem",
                        mod: "Ceftriaxona dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321711",
                        original: "Clindamicina, dosagem",
                        mod: "Clindamicina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321720",
                        original: "Clobazam dosagem",
                        mod: "Clobazam dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321738",
                        original: "Clonazepan, dosagem",
                        mod: "Clonazepan, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321746",
                        original: "Clorpromazina, dosagem",
                        mod: "Clorpromazina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321754",
                        original: "Clozapina, dosagem",
                        mod: "Clozapina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321762",
                        original: "Colinesterase com inibição de Dibucaina",
                        mod: "Colinesterase com inibição de Dibucaina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321770",
                        original: "Disopiramida, dosagem",
                        mod: "Disopiramida, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321789",
                        original: "Dissulfiram, dosagem",
                        mod: "Dissulfiram, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321797",
                        original: "Doxepina, dosagem",
                        mod: "Doxepina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321800",
                        original: "Flunitrazepam, dosagem",
                        mod: "Flunitrazepam, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321819",
                        original: "Fluoxetina, dosagem",
                        mod: "Fluoxetina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321827",
                        original: "Galactocerebrosidase, dosagem",
                        mod: "Galactocerebrosidase, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321835",
                        original: "Heroína, dosagem",
                        mod: "Heroína, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321843",
                        original: "Heroína, dosagem, cabelo",
                        mod: "Heroína, dosagem, cabelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321851",
                        original: "Interleucina 4, dosagem",
                        mod: "Interleucina 4, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321860",
                        original: "Interleucina 8, dosagem",
                        mod: "Interleucina 8, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321878",
                        original: "Iodo, dosagem na urina",
                        mod: "Iodo, dosagem na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321886",
                        original: "Isopropanol, sangue total",
                        mod: "Isopropanol, sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321894",
                        original: "Lioresal, dosagem",
                        mod: "Lioresal, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321908",
                        original: "Isopropanol, urina",
                        mod: "Isopropanol, urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321916",
                        original: "Lorazepam, dosagem",
                        mod: "Lorazepam, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321924",
                        original: "LSD, dosagem",
                        mod: "LSD, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321932",
                        original: "LSD, dosagem cabelo",
                        mod: "LSD, dosagem cabelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321940",
                        original: "Macrocreatinoquinase, pesquisa",
                        mod: "Macrocreatinoquinase, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321959",
                        original: "Manganes eritrocitário, dosagem",
                        mod: "Manganes eritrocitário, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321967",
                        original: "Manganes sérico, dosagem",
                        mod: "Manganes sérico, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321975",
                        original: "Maprotilina, dosagem",
                        mod: "Maprotilina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321983",
                        original: "Midazolam, dosagem",
                        mod: "Midazolam, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40321991",
                        original: "Opiaceos, detecção",
                        mod: "Opiaceos, detecção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322017",
                        original: "Opiaceos, detecção no cabelo",
                        mod: "Opiaceos, detecção no cabelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322025",
                        original: "Pirimetamina, dosagem",
                        mod: "Pirimetamina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322033",
                        original: "Serotonina, dosagem urinária",
                        mod: "Serotonina, dosagem urinária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322041",
                        original: "Sulfadiazina, dosagem",
                        mod: "Sulfadiazina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322050",
                        original: "Sulfametoxazol, dosagem",
                        mod: "Sulfametoxazol, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322068",
                        original: "Sulfapiridina, dosagem",
                        mod: "Sulfapiridina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322076",
                        original: "Sulfisoxazol, dosagem",
                        mod: "Sulfisoxazol, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322084",
                        original: "Swelling test",
                        mod: "Swelling test",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322092",
                        original: "Tetrahidrocanabinol (maconha), dosagem",
                        mod: "Tetrahidrocanabinol (maconha), dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322106",
                        original: "Tetrahidrocanabinol (maconha), dosagem, cabelo",
                        mod: "Tetrahidrocanabinol (maconha), dosagem, cabelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322114",
                        original: "Vancomicina, dosagem",
                        mod: "Vancomicina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322122",
                        original: "Vitamina H (Biotina), dosagem",
                        mod: "Vitamina H (Biotina), dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322130",
                        original: "Zolpidem, dosagem",
                        mod: "Zolpidem, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322149",
                        original: "Ácido 2 Tio-Tiazolidina 4 carboxílico",
                        mod: "Ácido 2 Tio-Tiazolidina 4 carboxílico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322157",
                        original: "Ácido fitânico",
                        mod: "Ácido fitânico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322165",
                        original: "Ácido hialuronico",
                        mod: "Ácido hialuronico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322173",
                        original: "Iduronato-2 sulfatase, dosagem",
                        mod: "Iduronato-2 sulfatase, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322181",
                        original: "N-Acetilgalactosaminidase, dosagem",
                        mod: "N-Acetilgalactosaminidase, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322190",
                        original: "N-Acetilglicosaminidase, dosagem",
                        mod: "N-Acetilglicosaminidase, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322203",
                        original: "Neopterina, dosagem",
                        mod: "Neopterina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322211",
                        original: "N-Metil formamida, dosagem",
                        mod: "N-Metil formamida, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322220",
                        original: "Pentaclorofenol, dosagem",
                        mod: "Pentaclorofenol, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322238",
                        original: "Piretróides inseticidas, dosagem",
                        mod: "Piretróides inseticidas, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322246",
                        original: "Receptor solúvel de transferrina",
                        mod: "Receptor solúvel de transferrina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322254",
                        original: "Superoxido dismutase, dosagem",
                        mod: "Superoxido dismutase, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322262",
                        original: "Vitamina B5 (Pantofenato), dosagem",
                        mod: "Vitamina B5 (Pantofenato), dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322270",
                        original: "Ácido cítrico (Citrato), dosagem sangue",
                        mod: "Ácido cítrico (Citrato), dosagem sangue",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322289",
                        original: "Ácido cítrico (Citrato), dosagem esperma",
                        mod: "Ácido cítrico (Citrato), dosagem esperma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322297",
                        original: "Creatina eritrocitária",
                        mod: "Creatina eritrocitária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322300",
                        original: "Curva glicêmica clássica (5 dosagens)",
                        mod: "Curva glicêmica clássica (5 dosagens)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322319",
                        original: "Everolimus, dosagem",
                        mod: "Everolimus, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322327",
                        original: "Radicais livre no soro",
                        mod: "Radicais livre no soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322335",
                        original: "Radicais livre na urina",
                        mod: "Radicais livre na urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322343",
                        original: "Teste de absorção de triglicérides (sobrecarga)",
                        mod: "Teste de absorção de triglicérides (sobrecarga)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322351",
                        original: "10,11 Epóxido carbamazepinam, soro",
                        mod: "10,11 Epóxido carbamazepinam, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322360",
                        original: "Alfa fetoproteína L3, líquor",
                        mod: "Alfa fetoproteína L3, líquor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322378",
                        original: "Albumina, líquor",
                        mod: "Albumina, líquor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322386",
                        original: "Alfa-galactosidade, dosagem plásmatica",
                        mod: "Alfa-galactosidade, dosagem plásmatica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322394",
                        original: "Alfa L-iduronase, plasma",
                        mod: "Alfa L-iduronase, plasma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322408",
                        original: "Bicarbonato na urina, amostra isolada",
                        mod: "Bicarbonato na urina, amostra isolada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322416",
                        original: "Carnitina, dosagem",
                        mod: "Carnitina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322424",
                        original: "Cistatina C, dosagem no sangue",
                        mod: "Cistatina C, dosagem no sangue",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322432",
                        original: "Cobre eritrocitário, dosagem",
                        mod: "Cobre eritrocitário, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322440",
                        original: "Cocaína, dosagem, sangue total",
                        mod: "Cocaína, dosagem, sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322459",
                        original: "Colesterol LDL peroxidada",
                        mod: "Colesterol LDL peroxidada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322467",
                        original: "Índice de ácido úrico/creatinina",
                        mod: "Índice de ácido úrico/creatinina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322475",
                        original: "Índice de cálcio/creatinina",
                        mod: "Índice de cálcio/creatinina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322483",
                        original: "Índice de proteína/creatinina",
                        mod: "Índice de proteína/creatinina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322491",
                        original: "Tripsina, dosagem",
                        mod: "Tripsina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322505",
                        original: "Zinco eritrocitário, dosagem",
                        mod: "Zinco eritrocitário, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322513",
                        original: "Transcobalamina, dosagem",
                        mod: "Transcobalamina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322521",
                        original: "Acetoacetato, dosagem",
                        mod: "Acetoacetato, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322530",
                        original: "Biperideno, dosagem",
                        mod: "Biperideno, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322548",
                        original: "Catalase eritrocitária",
                        mod: "Catalase eritrocitária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322556",
                        original: "1,3-Beta-D-Glucan, soro",
                        mod: "1,3-Beta-D-Glucan, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40322564",
                        original: "Amiloidose - TTR",
                        mod: "Amiloidose - TTR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323013",
                        original: "Anticorpos GQ1B - IgG",
                        mod: "Anticorpos GQ1B - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323021",
                        original: "Anticorpos Hama",
                        mod: "Anticorpos Hama",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323030",
                        original: "Acetilcolina, anticorpos ligador receptor",
                        mod: "Acetilcolina, anticorpos ligador receptor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323048",
                        original: "Acetilcolina, anticorpos modulador receptor",
                        mod: "Acetilcolina, anticorpos modulador receptor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323056",
                        original: "Anticorpos neutralizado do interferon",
                        mod: "Anticorpos neutralizado do interferon",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323064",
                        original: "Anticorpos PLA-2, soro",
                        mod: "Anticorpos PLA-2, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323080",
                        original: "Anticorpos protombina - IgG/IgM",
                        mod: "Anticorpos protombina - IgG/IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323099",
                        original: "Anticorpos vírus Herpes  8",
                        mod: "Anticorpos vírus Herpes  8",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323102",
                        original: "Antigenemia para citomegalovirus",
                        mod: "Antigenemia para citomegalovirus",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323110",
                        original: "Antígenos de aspergillus galactomannan",
                        mod: "Antígenos de aspergillus galactomannan",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323129",
                        original: "Antígenos inalatórios anticorpos",
                        mod: "Antígenos inalatórios anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323137",
                        original: "Antígenos proteinas S",
                        mod: "Antígenos proteinas S",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323145",
                        original: "BTA qualitatitvo na urina (amostra isolada)",
                        mod: "BTA qualitatitvo na urina (amostra isolada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323153",
                        original: "C4d fragmento",
                        mod: "C4d fragmento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323161",
                        original: "C6 complemento",
                        mod: "C6 complemento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323170",
                        original: "C7 complemento",
                        mod: "C7 complemento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323188",
                        original: "C8 complemento",
                        mod: "C8 complemento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323196",
                        original: "C9 complemento",
                        mod: "C9 complemento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323200",
                        original: "Campylobacter, sorologia",
                        mod: "Campylobacter, sorologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323218",
                        original: "Cisticercose Western Blot",
                        mod: "Cisticercose Western Blot",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323226",
                        original: "Coclea autoac(s), soro",
                        mod: "Coclea autoac(s), soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323234",
                        original: "Coxsackie A,",
                        mod: "Coxsackie A,",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323242",
                        original: "Detecção antígeno histoplasma (urina)",
                        mod: "Detecção antígeno histoplasma (urina)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323250",
                        original: "Detecção de CMV para RNA nasba",
                        mod: "Detecção de CMV para RNA nasba",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323269",
                        original: "Detecção de enterovirus no líquor",
                        mod: "Detecção de enterovirus no líquor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323277",
                        original: "Detecção de Niemann Pick tipo Cc",
                        mod: "Detecção de Niemann Pick tipo Cc",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323285",
                        original: "Detecção/tipagem herpes vírus 1/2 líquor",
                        mod: "Detecção/tipagem herpes vírus 1/2 líquor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323293",
                        original: "Difteria, sorologia",
                        mod: "Difteria, sorologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323307",
                        original: "Entamoeba histolytica, pesquisa (abscesso hepático)",
                        mod: "Entamoeba histolytica, pesquisa (abscesso hepático)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323315",
                        original: "Epstein BARR antígeno nuclear - IgM",
                        mod: "Epstein BARR antígeno nuclear - IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323323",
                        original: "Esquisocitos, pesquisa de",
                        mod: "Esquisocitos, pesquisa de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323331",
                        original: "Febre amarela, sorologia",
                        mod: "Febre amarela, sorologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323340",
                        original: "Febre do mediterrâneo",
                        mod: "Febre do mediterrâneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323358",
                        original: "Fenotiazinas qualitativas urina",
                        mod: "Fenotiazinas qualitativas urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323366",
                        original: "Fenotipagem virtual para HIV",
                        mod: "Fenotipagem virtual para HIV",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323374",
                        original: "Fosfatidil glicerol - IgG/IgM/IgA",
                        mod: "Fosfatidil glicerol - IgG/IgM/IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323382",
                        original: "Fosfatidiletanolamina - IgG/IgM/IgA",
                        mod: "Fosfatidiletanolamina - IgG/IgM/IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323390",
                        original: "Ghrelin",
                        mod: "Ghrelin",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323404",
                        original: "Hepatite E - IgM/IgG",
                        mod: "Hepatite E - IgM/IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323412",
                        original: "Heptimax, dosagem",
                        mod: "Heptimax, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323420",
                        original: "Herpes vírus 7 - IgG/IgM",
                        mod: "Herpes vírus 7 - IgG/IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323439",
                        original: "Herpes vírus, citologia (pesquisa de células herpet) ou IFD",
                        mod: "Herpes vírus, citologia (pesquisa de células herpet) ou IFD",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323447",
                        original: "Hipersensibilidade pneumonite triagem",
                        mod: "Hipersensibilidade pneumonite triagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323455",
                        original: "Histoplasma, pesquisa de",
                        mod: "Histoplasma, pesquisa de",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323463",
                        original: "HIV amplificação do DNA (PCR)",
                        mod: "HIV amplificação do DNA (PCR)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323471",
                        original: "HLA locus C",
                        mod: "HLA locus C",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323480",
                        original: "Imunofenotipagem T e B",
                        mod: "Imunofenotipagem T e B",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323498",
                        original: "Isoprostano",
                        mod: "Isoprostano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323501",
                        original: "Kleihauer",
                        mod: "Kleihauer",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323510",
                        original: "Lyme para Western Blot",
                        mod: "Lyme para Western Blot",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323528",
                        original: "Mata autoanticorpo",
                        mod: "Mata autoanticorpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323536",
                        original: "Neisseria meningitidis IgG resp vacinal",
                        mod: "Neisseria meningitidis IgG resp vacinal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323544",
                        original: "Neuromielite óptica (Devic)",
                        mod: "Neuromielite óptica (Devic)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323552",
                        original: "Neuropatia motora, painel",
                        mod: "Neuropatia motora, painel",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323560",
                        original: "Painel para meningoencefalite (líquor)",
                        mod: "Painel para meningoencefalite (líquor)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323579",
                        original: "Perfil para anticorpos anti gangliosídeo",
                        mod: "Perfil para anticorpos anti gangliosídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323587",
                        original: "Pesquisa antígeno da legionella",
                        mod: "Pesquisa antígeno da legionella",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323595",
                        original: "Pesquisa de adenovirus",
                        mod: "Pesquisa de adenovirus",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323609",
                        original: "Pesquisa de antígenos de giardia lamblia",
                        mod: "Pesquisa de antígenos de giardia lamblia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323617",
                        original: "Pesquisa de células Tzanck",
                        mod: "Pesquisa de células Tzanck",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323625",
                        original: "Pesquisa de influenza A e B por imunofluorescência",
                        mod: "Pesquisa de influenza A e B por imunofluorescência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323633",
                        original: "Pesquisa de mutação pré-core vírus hepatite B",
                        mod: "Pesquisa de mutação pré-core vírus hepatite B",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323641",
                        original: "Pesquisa direta anticorpos anti plaqueta - IgG",
                        mod: "Pesquisa direta anticorpos anti plaqueta - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323650",
                        original: "Pesquisa para Gardnerella",
                        mod: "Pesquisa para Gardnerella",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323668",
                        original: "Pesquisa para haemophilus ducreyi",
                        mod: "Pesquisa para haemophilus ducreyi",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323676",
                        original: "Pesquisa rápida para influenza A e B",
                        mod: "Pesquisa rápida para influenza A e B",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323684",
                        original: "Pesquisa rápida para vírus sincicial respiratório",
                        mod: "Pesquisa rápida para vírus sincicial respiratório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323692",
                        original: "Plaquetas induzidas por heparina anticorpo",
                        mod: "Plaquetas induzidas por heparina anticorpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323706",
                        original: "Proteína C antígeno",
                        mod: "Proteína C antígeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323714",
                        original: "Proteína ligadora fração C4 complemento",
                        mod: "Proteína ligadora fração C4 complemento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323722",
                        original: "Proteína para ribossomal anticorpo soro",
                        mod: "Proteína para ribossomal anticorpo soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323730",
                        original: "Reatividade contra painel de linfócitos",
                        mod: "Reatividade contra painel de linfócitos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323749",
                        original: "Receptor de interleucina 2",
                        mod: "Receptor de interleucina 2",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323757",
                        original: "Rubéola, IHA para",
                        mod: "Rubéola, IHA para",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323765",
                        original: "Screening para anticorpos anti HLA",
                        mod: "Screening para anticorpos anti HLA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323773",
                        original: "Sorologia para Babesia microti",
                        mod: "Sorologia para Babesia microti",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323781",
                        original: "Sorologia para febre da montanha rochosa",
                        mod: "Sorologia para febre da montanha rochosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323790",
                        original: "Sub-classes de IgA",
                        mod: "Sub-classes de IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323803",
                        original: "T3 autoanticorpo",
                        mod: "T3 autoanticorpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323811",
                        original: "T4 autoanticorpo",
                        mod: "T4 autoanticorpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323820",
                        original: "Tetano, sorologia para",
                        mod: "Tetano, sorologia para",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323838",
                        original: "TMA qualitativo para hepatite C",
                        mod: "TMA qualitativo para hepatite C",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323846",
                        original: "Tularemia, anti",
                        mod: "Tularemia, anti",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323854",
                        original: "Unidades avançadas - triagem HCG",
                        mod: "Unidades avançadas - triagem HCG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323862",
                        original: "Vírus respiratório, pesquisa rápida",
                        mod: "Vírus respiratório, pesquisa rápida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323870",
                        original: "West Nile sorologia líquor LCR",
                        mod: "West Nile sorologia líquor LCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323889",
                        original: "ZAP-70",
                        mod: "ZAP-70",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323897",
                        original: "Anticorpos antidifteria",
                        mod: "Anticorpos antidifteria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323900",
                        original: "Anticorpos antitétano",
                        mod: "Anticorpos antitétano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323919",
                        original: "Teste rápido para detecção de HIV em gestante",
                        mod: "Teste rápido para detecção de HIV em gestante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323927",
                        original: "Filagrina, anticorpos IgG",
                        mod: "Filagrina, anticorpos IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323935",
                        original: "Bartonella, anticorpos IgG",
                        mod: "Bartonella, anticorpos IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323943",
                        original: "Bartonella, anticorpos IgM",
                        mod: "Bartonella, anticorpos IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323951",
                        original: "Borrelia burgdorferi em líquor, anticorpos IgG",
                        mod: "Borrelia burgdorferi em líquor, anticorpos IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323960",
                        original: "Borrelia burgdorferi em líquor, anticorpos IgM",
                        mod: "Borrelia burgdorferi em líquor, anticorpos IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323978",
                        original: "Cadeias leves livres Kappa/Lambda em urina, dosagem",
                        mod: "Cadeias leves livres Kappa/Lambda em urina, dosagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323986",
                        original: "Calprotectina, detecção nas fezes",
                        mod: "Calprotectina, detecção nas fezes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40323994",
                        original: "Contraimunoeletroforese",
                        mod: "Contraimunoeletroforese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324010",
                        original: "Bordetella pertussis (Coqueluche) anticorpos IgG",
                        mod: "Bordetella pertussis (Coqueluche) anticorpos IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324028",
                        original: "Coxiella burnetti (febre Q), anticorpos IgG",
                        mod: "Coxiella burnetti (febre Q), anticorpos IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324036",
                        original: "Coxiella burnetti (febre Q), anticorpos IgM",
                        mod: "Coxiella burnetti (febre Q), anticorpos IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324044",
                        original: "Coxsackie A9, anticorpos IgM",
                        mod: "Coxsackie A9, anticorpos IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324052",
                        original: "Coxsackie B1-6, anticorpos IgM",
                        mod: "Coxsackie B1-6, anticorpos IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324060",
                        original: "Epstein BARR vírus antígeno precoce, anticorpos",
                        mod: "Epstein BARR vírus antígeno precoce, anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324079",
                        original: "HIV1/2, anticorpos (teste rápido)",
                        mod: "HIV1/2, anticorpos (teste rápido)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324087",
                        original: "Imunoglobulina M, dosagem no líquor",
                        mod: "Imunoglobulina M, dosagem no líquor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324095",
                        original: "LKM, pesquisa de anticorpos por Western Blot",
                        mod: "LKM, pesquisa de anticorpos por Western Blot",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324109",
                        original: "Norovirus, pesquisa",
                        mod: "Norovirus, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324117",
                        original: "Profilagrina, anticorpos IgG",
                        mod: "Profilagrina, anticorpos IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324125",
                        original: "Proteinase 3, anticorpo",
                        mod: "Proteinase 3, anticorpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324133",
                        original: "Quantiferon tb gold test",
                        mod: "Quantiferon tb gold test",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324141",
                        original: "SP 100/PML, anticorpo IgG",
                        mod: "SP 100/PML, anticorpo IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324150",
                        original: "Neuronal nuclear IgG, anticorpos, soro",
                        mod: "Neuronal nuclear IgG, anticorpos, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324168",
                        original: "Complemento C1 R, pesquisa e/ou dosagem",
                        mod: "Complemento C1 R",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324176",
                        original: "Chikungunya, anticorpos",
                        mod: "Chikungunya, anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324184",
                        original: "Gangliosídeo 4, anticorpos",
                        mod: "Gangliosídeo 4, anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324192",
                        original: "Antígeno NS1 do vírus da dengue, pesquisa",
                        mod: "Antígeno NS1 do vírus da dengue, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324206",
                        original: "Antígenos de histocompatibilidade (séries A ou B)",
                        mod: "Antígenos de histocompatibilidade (séries A ou B)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324214",
                        original: "Anticorpos contra canal de potássio regulado por voltagem",
                        mod: "Anticorpos contra canal de potássio regulado por voltagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324222",
                        original: "Fosfatidilserina, anticorpos IgG",
                        mod: "Fosfatidilserina, anticorpos IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324230",
                        original: "Fosfatidilserina, anticorpos IgM",
                        mod: "Fosfatidilserina, anticorpos IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324249",
                        original: "Fosfatidilserina, anticorpos IgA",
                        mod: "Fosfatidilserina, anticorpos IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324257",
                        original: "HU (anti neurônio), anticorpos",
                        mod: "HU (anti neurônio), anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324265",
                        original: "Cadeias leves livres Kappa/Lambda, dosagem, sangue",
                        mod: "Cadeias leves livres Kappa/Lambda, dosagem, sangue",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324273",
                        original: "Cadeias leves livres Kappa/Lambda, dosagem, líquor",
                        mod: "Cadeias leves livres Kappa/Lambda, dosagem, líquor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324281",
                        original: "Cadeias leves livres Kappa/Lambda, dosagem, urina",
                        mod: "Cadeias leves livres Kappa/Lambda, dosagem, urina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324290",
                        original: "Chlamydia pneumoniae, anticorpos, IgA",
                        mod: "Chlamydia pneumoniae, anticorpos, IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324303",
                        original: "CTX interligadores C terminais",
                        mod: "CTX interligadores C terminais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324311",
                        original: "Profilagrina, anticorpos, soro",
                        mod: "Profilagrina, anticorpos, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324320",
                        original: "Filagrina, anticorpos, soro",
                        mod: "Filagrina, anticorpos, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324338",
                        original: "Glicoproteína Beta 2, anticorpos, IgG",
                        mod: "Glicoproteína Beta 2, anticorpos, IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324346",
                        original: "Glicoproteína Beta 2, anticorpos, IgM",
                        mod: "Glicoproteína Beta 2, anticorpos, IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324354",
                        original: "Glicoproteína Beta 2, anticorpos, IgA",
                        mod: "Glicoproteína Beta 2, anticorpos, IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324362",
                        original: "Hepatite E - anticorpos IgG",
                        mod: "Hepatite E - anticorpos IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324370",
                        original: "Hepatite E - anticorpos, IgM",
                        mod: "Hepatite E - anticorpos, IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324389",
                        original: "HLA-DQ, teste de histocompatibilidade de alta resolução, sangue total",
                        mod: "HLA-DQ, teste de histocompatibilidade de alta resolução, sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324397",
                        original: "Imunoglobulina G, líquor",
                        mod: "Imunoglobulina G, líquor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324400",
                        original: "Mitocôndria, pesquisa de anticorpos por Western Blot, soro",
                        mod: "Mitocôndria, pesquisa de anticorpos por Western Blot, soro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324419",
                        original: "Saccharomyces cerevisiae (ASCA), anticorpos, IgA",
                        mod: "Saccharomyces cerevisiae (ASCA), anticorpos, IgA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324427",
                        original: "Saccharomyces cerevisiae (ASCA), anticorpos, IgG",
                        mod: "Saccharomyces cerevisiae (ASCA), anticorpos, IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324435",
                        original: "Receptor insulina, anticorpos",
                        mod: "Receptor insulina, anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324443",
                        original: "Rotavírus, anticorpos",
                        mod: "Rotavírus, anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324451",
                        original: "Antígeno HTLV III (HIV), EIE",
                        mod: "Antígeno HTLV III (HIV), EIE",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324460",
                        original: "Estreptococus A, sorologia",
                        mod: "Estreptococus A, sorologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324478",
                        original: "Imunoglobulina A, dosagem no líquor",
                        mod: "Imunoglobulina A, dosagem no líquor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324486",
                        original: "Trichinella Spiralis, anticorpos",
                        mod: "Trichinella Spiralis, anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324494",
                        original: "RNA Polimerase III, anticorpos",
                        mod: "RNA Polimerase III, anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324508",
                        original: "Mycobacterium Tuberculosis, IgM, anticorpos",
                        mod: "Mycobacterium Tuberculosis, IgM, anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324516",
                        original: "Células Sesary, pesquisa",
                        mod: "Células Sesary, pesquisa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324524",
                        original: "Determinação de Apoptose",
                        mod: "Determinação de Apoptose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324532",
                        original: "Tiroxina Fostase (IA2), anticorpos",
                        mod: "Tiroxina Fostase (IA2), anticorpos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324559",
                        original: "Dengue, anticorpos IgG, soro (teste rápido)",
                        mod: "Dengue, anticorpos IgG, soro (teste rápido)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324567",
                        original: "Dengue, anticorpos IgM, soro (teste rápido)",
                        mod: "Dengue, anticorpos IgM, soro (teste rápido)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324575",
                        original: "Ehrlichia chaffeensis, sorologia para",
                        mod: "Ehrlichia chaffeensis, sorologia para",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324583",
                        original: "Toxina butoliníca A, anticorpo",
                        mod: "Toxina butoliníca A, anticorpo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324591",
                        original: "Vírus Zika - IgG",
                        mod: "Vírus Zika - IgG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324605",
                        original: "Vírus Zika – IgM",
                        mod: "Vírus Zika – IgM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40324770",
                        original: "SARS-CoV-2 (Coronavírus COVID-19), pesquisa de anticorpos IgA, IgG ou IgM (cada par)",
                        mod: "SARS-CoV-2 (Coronavírus COVID-19), pesquisa de anticorpos IgA, IgG ou IgM (cada par)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40401014",
                        original: "Transfusão (ato médico ambulatorial ou hospitalar)",
                        mod: "Transfusão (ato médico ambulatorial ou hospitalar)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40401022",
                        original: "Transfusão (ato médico de acompanhamento)",
                        mod: "Transfusão (ato médico de acompanhamento)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402010",
                        original: "Material descartável (kit) e soluções para utilização de processadora automática de sangue / auto transfusão intra-operatória",
                        mod: "Material descartável (kit) e soluções para utilização de processadora automática de sangue / auto transfusão intra-operatória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402029",
                        original: "Material descartável (kit) e soluções para utilização de processadora automática de sangue/aférese",
                        mod: "Material descartável (kit) e soluções para utilização de processadora automática de sangue/aférese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402037",
                        original: "Sangria terapêutica",
                        mod: "Sangria terapêutica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402045",
                        original: "Unidade de concentrado de hemácias",
                        mod: "Unidade de concentrado de hemácias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402053",
                        original: "Unidade de concentrado de hemácias lavadas",
                        mod: "Unidade de concentrado de hemácias lavadas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402061",
                        original: "Unidade de concentrado de plaquetas por aférese",
                        mod: "Unidade de concentrado de plaquetas por aférese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402070",
                        original: "Unidade de concentrado de plaquetas randômicas",
                        mod: "Unidade de concentrado de plaquetas randômicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402088",
                        original: "Unidade de crioprecipitado de fator anti-hemofílico",
                        mod: "Unidade de crioprecipitado de fator anti-hemofílico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402096",
                        original: "Unidade de plasma",
                        mod: "Unidade de plasma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402100",
                        original: "Unidade de sangue total",
                        mod: "Unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402118",
                        original: "Deleucotização de unidade de concentrado de hemácias - por unidade",
                        mod: "Deleucotização de unidade de concentrado de hemácias - por unidade",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402126",
                        original: "Deleucotização de unidade de concentrado de plaquetas - até 6 unidades",
                        mod: "Deleucotização de unidade de concentrado de plaquetas - até 6 unidades",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402134",
                        original: "Irradiação de componentes hemoterápicos",
                        mod: "Irradiação de componentes hemoterápicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402142",
                        original: "Deleucotização de unidade de concentrado de plaquetas - entre 7 e 12 unidades",
                        mod: "Deleucotização de unidade de concentrado de plaquetas - entre 7 e 12 unidades",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402150",
                        original: "Unidade de concentrado de granulócitos",
                        mod: "Unidade de concentrado de granulócitos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402169",
                        original: "Unidade de concentrado de plaquetas (dupla centrifugação)",
                        mod: "Unidade de concentrado de plaquetas (dupla centrifugação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40402177",
                        original: "Concentrado de hemácias CMV negativos",
                        mod: "Concentrado de hemácias CMV negativos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403017",
                        original: "Acompanhamento  hospitalar/dia  do  transplante   de  medula   óssea  por médico  hematologista  e/ou hemoterapeuta",
                        mod: "Acompanhamento  hospitalar/dia  do  transplante   de  medula   óssea  por médico  hematologista  e/ou hemoterapeuta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403025",
                        original: "Anticorpos eritrocitários naturais e imunes - titulagem",
                        mod: "Anticorpos eritrocitários naturais e imunes - titulagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403033",
                        original: "Aplicação de medula óssea ou células tronco",
                        mod: "Aplicação de medula óssea ou células tronco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403041",
                        original: "Coleta de células tronco de sangue de cordão umbilical para transplante de medula óssea",
                        mod: "Coleta de células tronco de sangue de cordão umbilical para transplante de medula óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403050",
                        original: "Coleta de células tronco por processadora automática para transplante de medula óssea",
                        mod: "Coleta de células tronco por processadora automática para transplante de medula óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403068",
                        original: "Coleta de biópsia de medula óssea por agulha",
                        mod: "Coleta de biópsia de medula óssea por agulha",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403076",
                        original: "Coleta de medula óssea para transplante",
                        mod: "Coleta de medula óssea para transplante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403084",
                        original: "Determinação de células CD34, CD45 positivas - Citômetro de Fluxo",
                        mod: "Determinação de células CD34, CD45 positivas - Citômetro de Fluxo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403092",
                        original: "Determinação de conteúdo de DNA - Citômetro de Fluxo",
                        mod: "Determinação de conteúdo de DNA - Citômetro de Fluxo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403106",
                        original: "Eletroforese de hemoglobina por componente hemoterápico",
                        mod: "Eletroforese de hemoglobina por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403114",
                        original: "Eletroforese de hemoglobina por unidade de sangue total",
                        mod: "Eletroforese de hemoglobina por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403122",
                        original: "Exsanguíneo  transfusão",
                        mod: "Exsanguíneo  transfusão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403130",
                        original: "Fenotipagem de outros sistemas eritrocitários - por fenótipo",
                        mod: "Fenotipagem de outros sistemas eritrocitários - por fenótipo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403149",
                        original: "Fenotipagem de outros sistemas eritrocitários - por fenótipo - gel teste",
                        mod: "Fenotipagem de outros sistemas eritrocitários - por fenótipo - gel teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403157",
                        original: "Fenotipagem do sistema RH-HR (D, C, E, C E C) gel teste",
                        mod: "Fenotipagem do sistema RH-HR (D, C, E, C E C) gel teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403165",
                        original: "Fenotipagem do sistema RH-HR (D, C, E, C, E)",
                        mod: "Fenotipagem do sistema RH-HR (D, C, E, C, E)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403173",
                        original: "Grupo sanguíneo ABO e RH - pesquisa",
                        mod: "Grupo sanguíneo ABO e RH",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403181",
                        original: "Grupo sanguíneo ABO e RH - gel teste - pesquisa",
                        mod: "Grupo sanguíneo ABO e RH - gel teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403190",
                        original: "Identificação de anticorpos séricos irregulares antieritrocitários - método de eluição",
                        mod: "Identificação de anticorpos séricos irregulares antieritrocitários - método de eluição",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403203",
                        original: "Identificação de anticorpos séricos irregulares antieritrocitários - painel de hemácias enzimático",
                        mod: "Identificação de anticorpos séricos irregulares antieritrocitários - painel de hemácias enzimático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403211",
                        original: "Identificação de anticorpos séricos irregulares antieritrocitários com painel de hemácias",
                        mod: "Identificação de anticorpos séricos irregulares antieritrocitários com painel de hemácias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403220",
                        original: "Identificação de anticorpos séricos irregulares antieritrocitários com painel de hemácias tratadas por enzimas",
                        mod: "Identificação de anticorpos séricos irregulares antieritrocitários com painel de hemácias tratadas por enzimas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403238",
                        original: "Identificação de anticorpos séricos irregulares antieritrocitários com painel de hemácias - gel liss",
                        mod: "Identificação de anticorpos séricos irregulares antieritrocitários com painel de hemácias - gel liss",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403246",
                        original: "Imunofenotipagem de subpopulações linfocitárias - Citômetro de Fluxo",
                        mod: "Imunofenotipagem de subpopulações linfocitárias - Citômetro de Fluxo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403254",
                        original: "Imunofenotipagem para classificação de leucemias - Citômetro de Fluxo",
                        mod: "Imunofenotipagem para classificação de leucemias - Citômetro de Fluxo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403262",
                        original: "NAT/HCV por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "NAT/HCV por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403270",
                        original: "NAT/HCV por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "NAT/HCV por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403289",
                        original: "NAT/HIV por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "NAT/HIV por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403297",
                        original: "NAT/HIV por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "NAT/HIV por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403300",
                        original: "Operação de processadora automática de sangue em aférese",
                        mod: "Operação de processadora automática de sangue em aférese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403319",
                        original: "Operação de processadora automática de sangue em autotransfusão intra-operatória",
                        mod: "Operação de processadora automática de sangue em autotransfusão intra-operatória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403327",
                        original: "Pesquisa de anticorpos séricos antieritrocitários, anti-A e/ou anti-B - gel teste",
                        mod: "Pesquisa de anticorpos séricos antieritrocitários, anti-A e/ou anti-B - gel teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403335",
                        original: "Pesquisa de anticorpos séricos antieritrocitários, anti-A e/ou anti-B",
                        mod: "Pesquisa de anticorpos séricos antieritrocitários, anti-A e/ou anti-B",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403343",
                        original: "Pesquisa de anticorpos séricos irregulares antieritrocitários",
                        mod: "Pesquisa de anticorpos séricos irregulares antieritrocitários",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403351",
                        original: "Pesquisa de anticorpos séricos irregulares antieritrocitários - gel teste",
                        mod: "Pesquisa de anticorpos séricos irregulares antieritrocitários - gel teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403360",
                        original: "Pesquisa de anticorpos séricos irregulares antieritrocitários - método de eluição",
                        mod: "Pesquisa de anticorpos séricos irregulares antieritrocitários - método de eluição",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403378",
                        original: "Pesquisa de anticorpos séricos irregulares antieritrocitários a frio",
                        mod: "Pesquisa de anticorpos séricos irregulares antieritrocitários a frio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403386",
                        original: "Pesquisa de hemoglobina S por componente hemoterápico - gel teste",
                        mod: "Pesquisa de hemoglobina S por componente hemoterápico - gel teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403394",
                        original: "Pesquisa de hemoglobina S por unidade de sangue total - gel teste",
                        mod: "Pesquisa de hemoglobina S por unidade de sangue total - gel teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403408",
                        original: "Prova de compatibilidade pré-transfusional completa",
                        mod: "Prova de compatibilidade pré-transfusional completa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403416",
                        original: "Prova de compatibilidade pré-transfusional completa - gel teste",
                        mod: "Prova de compatibilidade pré-transfusional completa - gel teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403424",
                        original: "S. Anti-HTLV-I + HTLV-II (determinação conjunta) por componente hemoterápico",
                        mod: "S. Anti-HTLV-I + HTLV-II (determinação conjunta) por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403432",
                        original: "S. Anti-HTLV-I + HTLV-II (determinação conjunta) por unidade de sangue total",
                        mod: "S. Anti-HTLV-I + HTLV-II (determinação conjunta) por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403440",
                        original: "S. Chagas EIE por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "S. Chagas EIE por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403459",
                        original: "S. Chagas EIE por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "S. Chagas EIE por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403467",
                        original: "S. Hepatite B anti-HBC por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "S. Hepatite B anti-HBC por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403475",
                        original: "S. Hepatite B anti-HBC por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "S. Hepatite B anti-HBC por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403483",
                        original: "S. Hepatite C anti-HCV por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "S. Hepatite C anti-HCV por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403491",
                        original: "S. Hepatite C anti-HCV por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "S. Hepatite C anti-HCV por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403505",
                        original: "S. HIV EIE por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "S. HIV EIE por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403513",
                        original: "S. HIV EIE por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "S. HIV EIE por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403521",
                        original: "S. Malária IFI por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "S. Malária IFI por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403530",
                        original: "S. Malária IFI por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "S. Malária IFI por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403548",
                        original: "S. Sífilis EIE por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "S. Sífilis EIE por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403556",
                        original: "S. Sífilis EIE por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "S. Sífilis EIE por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403564",
                        original: "S. Sífilis FTA - ABS por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "S. Sífilis FTA - ABS por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403572",
                        original: "S. Sífilis FTA - ABS por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "S. Sífilis FTA - ABS por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403580",
                        original: "S. Sífilis HA por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "S. Sífilis HA por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403599",
                        original: "S. Sífilis HA por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "S. Sífilis HA por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403602",
                        original: "S. Sífilis VDRL por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "S. Sífilis VDRL por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403610",
                        original: "S. Sífilis VDRL por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "S. Sífilis VDRL por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403629",
                        original: "S. Chagas HA por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "S. Chagas HA por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403637",
                        original: "S. Chagas HA por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "S. Chagas HA por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403645",
                        original: "S. Chagas IFI por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "S. Chagas IFI por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403653",
                        original: "S. Chagas IFI por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "S. Chagas IFI por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403661",
                        original: "S. Hepatite B (HBsAg) RIE ou EIE por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "S. Hepatite B (HBsAg) RIE ou EIE por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403670",
                        original: "S. Hepatite B (HBsAg) RIE ou EIE por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "S. Hepatite B (HBsAg) RIE ou EIE por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403688",
                        original: "Teste de Coombs direto",
                        mod: "Teste de Coombs direto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403696",
                        original: "Teste de Coombs direto - gel teste",
                        mod: "Teste de Coombs direto - gel teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403700",
                        original: "Teste de Coombs direto - mono específico (IgG, IgA, C3, C3D, Poliv. - AGH) - gel teste",
                        mod: "Teste de Coombs direto - mono específico (IgG, IgA, C3, C3D, Poliv. - AGH) - gel teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403718",
                        original: "Teste de Coombs indireto - mono específico (IgG, IgA, C3, C3D, Poliv. - AGH) - gel teste",
                        mod: "Teste de Coombs indireto - mono específico (IgG, IgA, C3, C3D, Poliv. - AGH) - gel teste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403726",
                        original: "TMO - congelamento de medula óssea ou células tronco periféricas",
                        mod: "TMO - congelamento de medula óssea ou células tronco periféricas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403734",
                        original: "TMO - cultura de linfócitos doador e receptor",
                        mod: "TMO - cultura de linfócitos doador e receptor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403742",
                        original: "TMO - descongelamento de medula óssea ou células tronco",
                        mod: "TMO - descongelamento de medula óssea ou células tronco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403750",
                        original: "TMO - determinação de HLA   transplantes de medula óssea - loci DR e DQ (alta resolução)",
                        mod: "TMO - determinação de HLA   transplantes de medula óssea - loci DR e DQ (alta resolução)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403769",
                        original: "TMO - determinação de HLA para transplantes de medula óssea - loci A e B",
                        mod: "TMO - determinação de HLA para transplantes de medula óssea - loci A e B",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403777",
                        original: "TMO - determinação de HLA para transplantes de medula óssea - loci DR e DQ (baixa resolução)",
                        mod: "TMO - determinação de HLA para transplantes de medula óssea - loci DR e DQ (baixa resolução)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403785",
                        original: "TMO - determinação de unidades formadoras de colônias",
                        mod: "TMO - determinação de unidades formadoras de colônias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403793",
                        original: "TMO - determinação de viabilidade de medula óssea",
                        mod: "TMO - determinação de viabilidade de medula óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403807",
                        original: "TMO - manutenção de congelamento de medula óssea ou células tronco (até 2 anos)",
                        mod: "TMO - manutenção de congelamento de medula óssea ou células tronco (até 2 anos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403815",
                        original: "TMO - preparo de medula óssea ou células tronco periféricas para congelamento",
                        mod: "TMO - preparo de medula óssea ou células tronco periféricas para congelamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403823",
                        original: "TMO - preparo e filtração de medula óssea ou células tronco na coleta",
                        mod: "TMO - preparo e filtração de medula óssea ou células tronco na coleta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403831",
                        original: "TMO - tratamento 'in vitro' de medula óssea ou células tronco por anticorpos monoclonais (purging)(4)",
                        mod: "TMO - tratamento 'in vitro' de medula óssea ou células tronco por anticorpos monoclonais (purging)(4)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403840",
                        original: "Transaminase pirúvica - TGP ou ALT por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "Transaminase pirúvica - TGP ou ALT por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403858",
                        original: "Transaminase pirúvica - TGP ou ALT por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "Transaminase pirúvica - TGP ou ALT por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403866",
                        original: "Transfusão fetal intra-uterina",
                        mod: "Transfusão fetal intra-uterina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403874",
                        original: "Detecção de consumo de oxigênio  (O2) por unidade de concentrado de plaquetas (por unidade de concentrado de plaquetas de doador múltiplo)",
                        mod: "Detecção de consumo de oxigênio  (O2) por unidade de concentrado de plaquetas (por unidade de concentrado de plaquetas de doador múltiplo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403882",
                        original: "Detecção de consumo de oxigênio (O2) por unidade de concentrado de plaquetas (por unidade de concentrado de plaquetas por aférese)",
                        mod: "Detecção de consumo de oxigênio (O2) por unidade de concentrado de plaquetas (por unidade de concentrado de plaquetas por aférese)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403890",
                        original: "NAT/HBV - por componente hemoterápico - pesquisa e/ou dosagem",
                        mod: "NAT/HBV - por componente hemoterápico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403904",
                        original: "NAT/HBV - por unidade de sangue total - pesquisa e/ou dosagem",
                        mod: "NAT/HBV - por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403912",
                        original: "Estimulação e mobilização de células CD34 positivas",
                        mod: "Estimulação e mobilização de células CD34 positivas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403920",
                        original: "Determinação do fator RH (D), incluindo prova para D-fraco no sangue do receptor",
                        mod: "Determinação do fator RH (D), incluindo prova para D-fraco no sangue do receptor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403939",
                        original: "Doação autóloga com recuperação intra-operatória",
                        mod: "Doação autóloga com recuperação intra-operatória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403947",
                        original: "Doação autóloga peri-operatória por hemodiluição normovolêmica",
                        mod: "Doação autóloga peri-operatória por hemodiluição normovolêmica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403955",
                        original: "Doação autóloga pré-operatória",
                        mod: "Doação autóloga pré-operatória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403963",
                        original: "Exames imunohematológicos em recém-nascidos: tipificação ABO e RH, pesquisa de D fraco RH(D) e prova da antiglobulina direta",
                        mod: "Exames imunohematológicos em recém-nascidos: tipificação ABO e RH, pesquisa de D fraco RH(D) e prova da antiglobulina direta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403971",
                        original: "Imuno-hematológicos: tipificação ABO, incluindo tipagem reversa e determinação do fator RH (D), incluindo prova para D-fraco e pesquisa e identificação de anticorpos séricos irregulares antieritrocitários",
                        mod: "Imuno-hematológicos: tipificação ABO, incluindo tipagem reversa e determinação do fator RH (D), incluindo prova para D-fraco e pesquisa e identificação de anticorpos séricos irregulares antieritrocitários",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403980",
                        original: "Investigação da presença de anti-A ou anti-B, em soro ou plasma de neonato, com métodos que incluam uma fase antiglobulínica",
                        mod: "Investigação da presença de anti-A ou anti-B, em soro ou plasma de neonato, com métodos que incluam uma fase antiglobulínica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40403998",
                        original: "Tipificação ABO, incluindo tipagem reversa no sangue do receptor (sem tipagem reversa até 4 meses de idade)",
                        mod: "Tipificação ABO, incluindo tipagem reversa no sangue do receptor (sem tipagem reversa até 4 meses de idade)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404013",
                        original: "TMO - prova cruzada para histocompatibilidade de transplante de medula óssea",
                        mod: "TMO - prova cruzada para histocompatibilidade de transplante de medula óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404021",
                        original: "Aférese para paciente ABO incompatível",
                        mod: "Aférese para paciente ABO incompatível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404030",
                        original: "Antigenemia para diagnóstico de CMV pós transplante",
                        mod: "Antigenemia para diagnóstico de CMV pós transplante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404048",
                        original: "Avaliação quimerismo - VNTR - doador - pré transplante",
                        mod: "Avaliação quimerismo - VNTR - doador - pré transplante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404056",
                        original: "Avaliação quimerismo - VNTR - paciente - pré transplante",
                        mod: "Avaliação quimerismo - VNTR - paciente - pré transplante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404064",
                        original: "Avaliação quimerismo por STR - paciente - pós transplante",
                        mod: "Avaliação quimerismo por STR - paciente - pós transplante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404072",
                        original: "Coleta de linfócitos de sangue periférico por aférese para tratamento de recidivas pós TCTH alogênico",
                        mod: "Coleta de linfócitos de sangue periférico por aférese para tratamento de recidivas pós TCTH alogênico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404080",
                        original: "Controle microbiológico da medula óssea no TCTH alogênico",
                        mod: "Controle microbiológico da medula óssea no TCTH alogênico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404099",
                        original: "Controle microbiológico das células tronco periféricas no TCTH alogênico",
                        mod: "Controle microbiológico das células tronco periféricas no TCTH alogênico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404102",
                        original: "Depleção de plasma em TCTH alogênicos com incompatibilidade ABO menor",
                        mod: "Depleção de plasma em TCTH alogênicos com incompatibilidade ABO menor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404110",
                        original: "PCR em tempo real para diagnóstico de adenovírus",
                        mod: "PCR em tempo real para diagnóstico de adenovírus",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404129",
                        original: "PCR em tempo real para diagnóstico de EBV - pós transplante",
                        mod: "PCR em tempo real para diagnóstico de EBV - pós transplante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404137",
                        original: "PCR em tempo real para diagnóstico de Herpes virus 6 - pos transplante",
                        mod: "PCR em tempo real para diagnóstico de Herpes virus 6 - pos transplante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404145",
                        original: "PCR em tempo real para diagnóstico de Herpes virus 8 - pos transplante",
                        mod: "PCR em tempo real para diagnóstico de Herpes virus 8 - pos transplante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404153",
                        original: "PCR em tempo real para os vírus para influenza e influenza",
                        mod: "PCR em tempo real para os vírus para influenza e influenza",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404153",
                        original: "PCR em tempo real para os vírus parainfluenza e influenza",
                        mod: "PCR em tempo real para os vírus parainfluenza e influenza",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404161",
                        original: "PCR em tempo real para vírus respiratório sincicial",
                        mod: "PCR em tempo real para vírus respiratório sincicial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404170",
                        original: "Quantificação de CD14 da coleta de células tronco periféricas para TCTH alogênico",
                        mod: "Quantificação de CD14 da coleta de células tronco periféricas para TCTH alogênico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404188",
                        original: "Quantificação de CD19 da coleta de células tronco periféricas para TCTH alogênico",
                        mod: "Quantificação de CD19 da coleta de células tronco periféricas para TCTH alogênico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404196",
                        original: "Quantificação de CD3  da coleta de células tronco periféricas para TCTH alogênico",
                        mod: "Quantificação de CD3  da coleta de células tronco periféricas para TCTH alogênico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404200",
                        original: "Quantificação de CD3  da coleta de linfócitos para tratamento de recidivas pós TCTH alogênico",
                        mod: "Quantificação de CD3  da coleta de linfócitos para tratamento de recidivas pós TCTH alogênico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404218",
                        original: "Quantificação de CD4 da coleta de células tronco periféricas para TCTH alogênico",
                        mod: "Quantificação de CD4 da coleta de células tronco periféricas para TCTH alogênico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404226",
                        original: "Quantificação de CD8 da coleta de células tronco periféricas para TCTH alogênico",
                        mod: "Quantificação de CD8 da coleta de células tronco periféricas para TCTH alogênico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404234",
                        original: "Quantificação de leucócitos totais da coleta de células tronco periféricas para TCTH alogênico",
                        mod: "Quantificação de leucócitos totais da coleta de células tronco periféricas para TCTH alogênico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404242",
                        original: "Quantificação de leucócitos totais da Medula Óssea no TCTH alogênico",
                        mod: "Quantificação de leucócitos totais da Medula Óssea no TCTH alogênico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404250",
                        original: "Sedimentação de hemácias em TCTH alogênicos com incompatibilidade ABO maior",
                        mod: "Sedimentação de hemácias em TCTH alogênicos com incompatibilidade ABO maior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404269",
                        original: "Viabilidade celular dos linfócitos periféricos por citometria de fluxo para tratamento das recidivas pós TCTH alogênico",
                        mod: "Viabilidade celular dos linfócitos periféricos por citometria de fluxo para tratamento das recidivas pós TCTH alogênico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404277",
                        original: "Viabilidade celular da medula óssea por citometria de fluxo após o descongelamento",
                        mod: "Viabilidade celular da medula óssea por citometria de fluxo após o descongelamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404285",
                        original: "Viabilidade celular das células tronco periféricas por citometria de fluxo após o descongelamento",
                        mod: "Viabilidade celular das células tronco periféricas por citometria de fluxo após o descongelamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404293",
                        original: "Genotipagem Duffy_Gata",
                        mod: "Genotipagem Duffy_Gata",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404307",
                        original: "Genotipagem Kell",
                        mod: "Genotipagem Kell",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404315",
                        original: "Genotipagem Kidd",
                        mod: "Genotipagem Kidd",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404323",
                        original: "Genotipagem para zigosidade D",
                        mod: "Genotipagem para zigosidade D",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404331",
                        original: "Genotipagem RhD parcial",
                        mod: "Genotipagem RhD parcial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404340",
                        original: "Imunofluorescência direta de pele",
                        mod: "Imunofluorescência direta de pele",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404358",
                        original: "Descalcificação de tecido, anatomia patológica",
                        mod: "Descalcificação de tecido, anatomia patológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404366",
                        original: "NAT teste de ácido nucleico HIV/HCV/HBV",
                        mod: "NAT teste de ácido nucleico HIV/HCV/HBV",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404374",
                        original: "S. Anticorpo anti-CMV (IgG), por unidade de sangue total",
                        mod: "S. Anticorpo anti-CMV (IgG), por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404382",
                        original: "S. Anticorpo anti-CMV (IgM), por unidade de sangue total",
                        mod: "S. Anticorpo anti-CMV (IgM), por unidade de sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404390",
                        original: "Concentrado de hemácias raras congeladas",
                        mod: "Concentrado de hemácias raras congeladas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404404",
                        original: "Fenotipagem do sistema RH-HR (D, C, E, C, E) e Kell",
                        mod: "Fenotipagem do sistema RH-HR (D, C, E, C, E) e Kell",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404412",
                        original: "Fotoférese",
                        mod: "Fotoférese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404420",
                        original: "Genotipagem completa plaquetária (HPA 1, 6 e 15)",
                        mod: "Genotipagem completa plaquetária (HPA 1, 6 e 15)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404439",
                        original: "Genotipagem HPA por sistema",
                        mod: "Genotipagem HPA por sistema",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404447",
                        original: "Genotipagem RHD, Cc, Ec, Pse, K, Kid, Df, Gata",
                        mod: "Genotipagem RHD, Cc, Ec, Pse, K, Kid, Df, Gata",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404455",
                        original: "Genotipagem RHD, Cc, Ee, Pseudogene",
                        mod: "Genotipagem RHD, Cc, Ee, Pseudogene",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404463",
                        original: "Genotipagem RHD, Cc, Ee, Pseudogene, Kell",
                        mod: "Genotipagem RHD, Cc, Ee, Pseudogene, Kell",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404471",
                        original: "Genotipagem Ss",
                        mod: "Genotipagem Ss",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404480",
                        original: "Granulócitos CMV negativos",
                        mod: "Granulócitos CMV negativos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404498",
                        original: "Pesquisa de hemolisina",
                        mod: "Pesquisa de hemolisina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404501",
                        original: "Pesquisa e identificação de anticorpos plaquetários (MAIPA)",
                        mod: "Pesquisa e identificação de anticorpos plaquetários (MAIPA)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404510",
                        original: "Plaquetaférese CMV negativos",
                        mod: "Plaquetaférese CMV negativos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404528",
                        original: "Plaquetaférese HLA compatível",
                        mod: "Plaquetaférese HLA compatível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404536",
                        original: "Prova de compatibilidade para transfusão de plaquetas (MAIPA)",
                        mod: "Prova de compatibilidade para transfusão de plaquetas (MAIPA)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40404544",
                        original: "Técnicas de adsorção",
                        mod: "Técnicas de adsorção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501019",
                        original: "Cariótipo com bandas de pele, tumor e demais tecidos",
                        mod: "Cariótipo com bandas de pele, tumor e demais tecidos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501027",
                        original: "Cariótipo com pesquisa de troca de cromátides irmãs",
                        mod: "Cariótipo com pesquisa de troca de cromátides irmãs",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501035",
                        original: "Cariótipo com técnicas de alta resolução",
                        mod: "Cariótipo com técnicas de alta resolução",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501043",
                        original: "Cariótipo de medula (técnicas com bandas)",
                        mod: "Cariótipo de medula (técnicas com bandas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501051",
                        original: "Cariótipo de sangue (técnicas com bandas)",
                        mod: "Cariótipo de sangue (técnicas com bandas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501060",
                        original: "Cariótipo de sangue obtido por cordocentese pré-natal",
                        mod: "Cariótipo de sangue obtido por cordocentese pré-natal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501078",
                        original: "Cariótipo de sangue-pesquisa de marcadores tumorais",
                        mod: "Cariótipo de sangue-pesquisa de marcadores tumorais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501086",
                        original: "Cariótipo de sangue-pesquisa de sítio frágil X",
                        mod: "Cariótipo de sangue-pesquisa de sítio frágil X",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501094",
                        original: "Cariótipo em vilosidades coriônicas (cultivo de trofoblastos)",
                        mod: "Cariótipo em vilosidades coriônicas (cultivo de trofoblastos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501108",
                        original: "Cariótipo para pesquisa de instabilidade cromossômica",
                        mod: "Cariótipo para pesquisa de instabilidade cromossômica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501116",
                        original: "Cromatina X ou Y",
                        mod: "Cromatina X ou Y",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501124",
                        original: "Cultura de material de aborto e obtenção de cariótipo",
                        mod: "Cultura de material de aborto e obtenção de cariótipo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501132",
                        original: "Cultura de tecido para ensaio enzimático e/ou extração de DNA",
                        mod: "Cultura de tecido para ensaio enzimático e/ou extração de DNA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501140",
                        original: "Diagnóstico genético pré-implantação por fish, por sonda",
                        mod: "Diagnóstico genético pré-implantação por fish, por sonda",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501159",
                        original: "Fish em metáfase ou núcleo interfásico, por sonda",
                        mod: "Fish em metáfase ou núcleo interfásico, por sonda",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501167",
                        original: "Fish pré-natal, por sonda",
                        mod: "Fish pré-natal, por sonda",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501175",
                        original: "Líquido amniótico, cariótipo com bandas",
                        mod: "Líquido amniótico, cariótipo com bandas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501183",
                        original: "Líquido amniótico, vilosidades coriônicas, subcultura para dosagens bioquímicas e/ou moleculares (adicional)",
                        mod: "Líquido amniótico, vilosidades coriônicas, subcultura para dosagens bioquímicas e/ou moleculares (adicional)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501191",
                        original: "Subcultura de pele para dosagens bioquímicas e/ou moleculares (adicional)",
                        mod: "Subcultura de pele para dosagens bioquímicas e/ou moleculares (adicional)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501205",
                        original: "Estudo de alterações cromossômicas em leucemias por FISH (Fluorescence In Situ Hybridization)",
                        mod: "Estudo de alterações cromossômicas em leucemias por FISH (Fluorescence In Situ Hybridization)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501213",
                        original: "Pesquisa de translocação PML/RAR-a",
                        mod: "Pesquisa de translocação PML/RAR-a",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501221",
                        original: "Cariótipo de sangue (técnicas com bandas) - Análise de 50 células para detecção de mosaicismo",
                        mod: "Cariótipo de sangue (técnicas com bandas) - Análise de 50 células para detecção de mosaicismo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501230",
                        original: "Cultura de fibroblastos (pele)",
                        mod: "Cultura de fibroblastos (pele)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501248",
                        original: "CITOGENÉTICA DE MEDULA ÓSSEA",
                        mod: "CITOGENÉTICA DE MEDULA ÓSSEA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501248",
                        original: "HER2 FISH para amplificação gênica em tumor de mama",
                        mod: "HER2 FISH para amplificação gênica em tumor de mama",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501256",
                        original: "HER2 CISH para amplificação gênica em tumor de mama",
                        mod: "HER2 CISH para amplificação gênica em tumor de mama",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501264",
                        original: "Translocação PML/RARA  t(15;17) FISH em medula óssea",
                        mod: "Translocação PML/RARA  t(15;17) FISH em medula óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501272",
                        original: "Translocação PML/RARA  t(15;17) FISH em sangue periférico",
                        mod: "Translocação PML/RARA  t(15;17) FISH em sangue periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40501280",
                        original: "Hibridização in situ pela prata ou cromogênica  (CISH) - Histoquímica",
                        mod: "Hibridização in situ pela prata ou cromogênica  (CISH) - Histoquímica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502015",
                        original: "Marcadores bioquímicos extras, além de BHCG, AFP e PAPP-A, para avaliação do risco fetal, por marcador, por amostra",
                        mod: "Marcadores bioquímicos extras, além de BHCG, AFP e PAPP-A, para avaliação do risco fetal, por marcador, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502040",
                        original: "Baterias de testes químicos de triagem em urina para erros inatos do metabolismo (mínimo de seis testes)",
                        mod: "Baterias de testes químicos de triagem em urina para erros inatos do metabolismo (mínimo de seis testes)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502058",
                        original: "Determinação do risco fetal, com elaboração de laudo",
                        mod: "Determinação do risco fetal, com elaboração de laudo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502066",
                        original: "Dosagem quantitativa de ácidos orgânicos, carnitina, perfil de acilcarnitina, ácidos graxos de cadeia muito longa, para o diagnóstico de erros inatos do metabolismo (perfil em uma amostra)",
                        mod: "Dosagem quantitativa de ácidos orgânicos, carnitina, perfil de acilcarnitina, ácidos graxos de cadeia muito longa, para o diagnóstico de erros inatos do metabolismo (perfil em uma amostra)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502074",
                        original: "Dosagem quantitativa de aminoácidos para o diagnóstico de erros inatos do metabolismo (perfil de aminoácidos numa amostra)",
                        mod: "Dosagem quantitativa de aminoácidos para o diagnóstico de erros inatos do metabolismo (perfil de aminoácidos numa amostra)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502082",
                        original: "Dosagem quantitativa de metabólitos na urina e/ou sangue para o diagnóstico de erros inatos do metabolismo (cada)",
                        mod: "Dosagem quantitativa de metabólitos na urina e/ou sangue para o diagnóstico de erros inatos do metabolismo (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502090",
                        original: "Eletroforese ou cromatografia (papel ou camada delgada) para identificação de aminoácidos ou glicídios ou oligossacarídios ou sialoligossacarídios glicosaminoglicanos ou outros compostos para detecção de erros inatos do metabolismo (cada)",
                        mod: "Eletroforese ou cromatografia (papel ou camada delgada) para identificação de aminoácidos ou glicídios ou oligossacarídios ou sialoligossacarídios glicosaminoglicanos ou outros compostos para detecção de erros inatos do metabolismo (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502104",
                        original: "Ensaios enzimáticos em células cultivadas para diagnóstico de EIM, incluindo preparo do material, dosagem de proteína e enzima de referência (cada)",
                        mod: "Ensaios enzimáticos em células cultivadas para diagnóstico de EIM, incluindo preparo do material, dosagem de proteína e enzima de referência (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502112",
                        original: "Ensaios enzimáticos em leucócitos, eritrócitos ou tecidos para diagnóstico de EIM, incluindo preparo do material, dosagem de proteína e enzima de referência (cada)",
                        mod: "Ensaios enzimáticos em leucócitos, eritrócitos ou tecidos para diagnóstico de EIM, incluindo preparo do material, dosagem de proteína e enzima de referência (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502120",
                        original: "Ensaios enzimáticos no plasma para diagnóstico de EIM, incluindo enzima de referência (cada)",
                        mod: "Ensaios enzimáticos no plasma para diagnóstico de EIM, incluindo enzima de referência (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502139",
                        original: "Teste duplo - 1 trimestre (PAPP-A+Beta-HCG) ou outros 2 em soro ou líquido aminiótico com elaboração de laudo contendo cálculo de risco para anomalias fetais",
                        mod: "Teste duplo - 1 trimestre (PAPP-A+Beta-HCG) ou outros 2 em soro ou líquido aminiótico com elaboração de laudo contendo cálculo de risco para anomalias fetais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502147",
                        original: "Teste duplo - 2 trimestre (AFP+Beta-HCG) ou outros 2 em soro ou líquido aminiótico com elaboração de laudo contendo cálculo de risco para anomalias fetais",
                        mod: "Teste duplo - 2 trimestre (AFP+Beta-HCG) ou outros 2 em soro ou líquido aminiótico com elaboração de laudo contendo cálculo de risco para anomalias fetais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502155",
                        original: "Teste triplo (AFP+Beta-HCG+Estriol) ou outros 3 em soro ou líquido aminiótico com elaboração de laudo contendo cálculo de risco para anomalias fetais",
                        mod: "Teste triplo (AFP+Beta-HCG+Estriol) ou outros 3 em soro ou líquido aminiótico com elaboração de laudo contendo cálculo de risco para anomalias fetais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502163",
                        original: "Testes químicos de triagem em urina para erros inatos do metabolismo (cada)",
                        mod: "Testes químicos de triagem em urina para erros inatos do metabolismo (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502171",
                        original: "Dosagem quantitativa de carnitina e perfil de acilcarnitina, para o diagnóstico de erros inatos do metabolismo",
                        mod: "Dosagem quantitativa de carnitina e perfil de acilcarnitina, para o diagnóstico de erros inatos do metabolismo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502180",
                        original: "Dosagem quantitativa de ácidos graxos de cadeia muito longa  para o diagnóstico de EIM",
                        mod: "Dosagem quantitativa de ácidos graxos de cadeia muito longa  para o diagnóstico de EIM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502198",
                        original: "Dosagem quantitativa de metabólitos por cromatografia / espectrometria de massa  (CG/MS ou HPLC/MS ) para o diagnóstico de EIM",
                        mod: "Dosagem quantitativa de metabólitos por cromatografia / espectrometria de massa  (CG/MS ou HPLC/MS ) para o diagnóstico de EIM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502201",
                        original: "Dosagem quantitativa de metabólitos por espectrometria de massa ou espectrometria de massa em TANDEM (MS OU MS/MS) para o diagnóstico de EIM",
                        mod: "Dosagem quantitativa de metabólitos por espectrometria de massa ou espectrometria de massa em TANDEM (MS OU MS/MS) para o diagnóstico de EIM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502210",
                        original: "Terapia de reposição enzimática por infusão endovenosa, por procedimento",
                        mod: "Terapia de reposição enzimática por infusão endovenosa, por procedimento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502228",
                        original: "Rastreamento neonatal para o diagnósitco de EIM e outras doenças",
                        mod: "Rastreamento neonatal para o diagnósitco de EIM e outras doenças",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502236",
                        original: "Dosagem quantitativa de ácidos orgânicos para o diagnóstico de erros inatos do metabolismo (perfil de ácidos orgânicos numa amostra)",
                        mod: "Dosagem quantitativa de ácidos orgânicos para o diagnóstico de erros inatos do metabolismo (perfil de ácidos orgânicos numa amostra)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40502244",
                        original: "Defeitos congênitos da glicolização - Focalização isoelétrica da transferrina",
                        mod: "Defeitos congênitos da glicolização - Focalização isoelétrica da transferrina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503011",
                        original: "Análise de DNA com enzimas de restrição por enzima utilizada, por amostra",
                        mod: "Análise de DNA com enzimas de restrição por enzima utilizada, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503020",
                        original: "Análise de DNA fetal por enzima de restrição, por enzima utilizada, por amostra (adicional nos exames em que já foi feito o PCR 4.05.03.06-2 e depende da enzima para estabelecer o diagnóstico)",
                        mod: "Análise de DNA fetal por enzima de restrição, por enzima utilizada, por amostra (adicional nos exames em que já foi feito o PCR 4.05.03.06-2 e depende da enzima para estabelecer o diagnóstico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503038",
                        original: "Análise de DNA fetal por sonda ou PCR por locus, por amostra",
                        mod: "Análise de DNA fetal por sonda ou PCR por locus, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503046",
                        original: "Análise de DNA pela técnica multiplex por locus extra, por amostra",
                        mod: "Análise de DNA pela técnica multiplex por locus extra, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503054",
                        original: "Análise de DNA pela técnica multiplex por locus, por amostra",
                        mod: "Análise de DNA pela técnica multiplex por locus, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503062",
                        original: "Análise de DNA por sonda, ou PCR por locus, por amostra",
                        mod: "Análise de DNA por sonda, ou PCR por locus, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503070",
                        original: "Diagnóstico genético pré-implantação por DNA, por sonda de FISH ou por primer de PCR, por amostra",
                        mod: "Diagnóstico genético pré-implantação por DNA, por sonda de FISH ou por primer de PCR, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503089",
                        original: "Extração de DNA (osso), por amostra",
                        mod: "Extração de DNA (osso), por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503097",
                        original: "Extração de DNA (sangue, urina, líquido aminiótico, vilo trofoblástico etc.), por amostra",
                        mod: "Extração de DNA (sangue, urina, líquido aminiótico, vilo trofoblástico etc.), por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503100",
                        original: "Identificação de mutação por sequenciamento do DNA, por 100 pares de base sequenciadas, por amostra",
                        mod: "Identificação de mutação por sequenciamento do DNA, por 100 pares de base sequenciadas, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503119",
                        original: "Processamento de qualquer tipo de amostra biológica para estabilização do ácido nucléico, por amostra",
                        mod: "Processamento de qualquer tipo de amostra biológica para estabilização do ácido nucléico, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503127",
                        original: "Extração, purificação e quantificação de ácido nucléico de qualquer tipo de amostra biológica, por amostra",
                        mod: "Extração, purificação e quantificação de ácido nucléico de qualquer tipo de amostra biológica, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503135",
                        original: "Transcrição reversa de RNA, por amostra",
                        mod: "Transcrição reversa de RNA, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503143",
                        original: "Amplificação do material genético (por PCR, PCR em tempo Real, LCR, RT-PCR ou outras técnicas), por primer utilizado, por amostra",
                        mod: "Amplificação do material genético (por PCR, PCR em tempo Real, LCR, RT-PCR ou outras técnicas), por primer utilizado, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503151",
                        original: "Análise de DNA por MLPA, por sonda de DNA utilizada, por amostra",
                        mod: "Análise de DNA por MLPA, por sonda de DNA utilizada, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503160",
                        original: "Análise de DNA pela técnica de Southern Blot, por sonda utilizada, por amostra",
                        mod: "Análise de DNA pela técnica de Southern Blot, por sonda utilizada, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503178",
                        original: "Produção de DOT/SLOT-BLOT, por BLOT, por amostra",
                        mod: "Produção de DOT/SLOT-BLOT, por BLOT, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503186",
                        original: "Separação do material genético por eletroforese capilar ou em gel (agarose, acrilamida), por gel utilizado, por amostra",
                        mod: "Separação do material genético por eletroforese capilar ou em gel (agarose, acrilamida), por gel utilizado, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503194",
                        original: "Rastreamento de exon mutado (por gradiente de desnaturação ou conformação de polimorfismo de fita simples ou RNAse ou Clivagem Química ou outras técnicas) para identificação de fragmento mutado, por fragmento analisado, por amostra",
                        mod: "Rastreamento de exon mutado (por gradiente de desnaturação ou conformação de polimorfismo de fita simples ou RNAse ou Clivagem Química ou outras técnicas) para identificação de fragmento mutado, por fragmento analisado, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503208",
                        original: "Coloração de gel e Fotodocumentação da análise molecular, por amostra",
                        mod: "Coloração de gel e Fotodocumentação da análise molecular, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503216",
                        original: "Interpretação e elaboração do laudo da análise genética, por amostra",
                        mod: "Interpretação e elaboração do laudo da análise genética, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503224",
                        original: "Análise de expressão gênica por locus, por amostra, por CGH array, SNP array ou outras técnicas",
                        mod: "Análise de expressão gênica por locus, por amostra, por CGH array, SNP array ou outras técnicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503232",
                        original: "Detecção pré-natal ou pós-natal de alterações cromossômicas submicroscópicas reconhecidamente causadoras de síndrome de genes contíguos, por FISH, qPCR ou outra técnica, por locus, por amostra",
                        mod: "Detecção pré-natal ou pós-natal de alterações cromossômicas submicroscópicas reconhecidamente causadoras de síndrome de genes contíguos, por FISH, qPCR ou outra técnica, por locus, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503240",
                        original: "Rastreamento pré-natal ou pós-natal de todo o genoma para  identificar alterações cromossômicas submicroscópicas por CGH-array ou SNP-array ou outras técnicas, por clone ou oligo utilizado, por amostra",
                        mod: "Rastreamento pré-natal ou pós-natal de todo o genoma para  identificar alterações cromossômicas submicroscópicas por CGH-array ou SNP-array ou outras técnicas, por clone ou oligo utilizado, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503259",
                        original: "Validação pré-natal ou pós-natal de alteração cromossômica submicroscópica detectada no rastreamento genômico, por FISH ou qPCR ou outra técnica, por locus, por amostra",
                        mod: "Validação pré-natal ou pós-natal de alteração cromossômica submicroscópica detectada no rastreamento genômico, por FISH ou qPCR ou outra técnica, por locus, por amostra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503267",
                        original: "Translocação AML1-ETO t(8,21) por PCR",
                        mod: "Translocação AML1-ETO t(8,21) por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503275",
                        original: "Análise da mutação IgVH-cadeia pesada da imunoglobulina",
                        mod: "Análise da mutação IgVH-cadeia pesada da imunoglobulina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503283",
                        original: "CCR-5, pesquisa de mutação por PCR",
                        mod: "CCR-5, pesquisa de mutação por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503291",
                        original: "Citocromo P450 2C19 genotipagem",
                        mod: "Citocromo P450 2C19 genotipagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503305",
                        original: "Colinesterase, estudo genético",
                        mod: "Colinesterase, estudo genético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503310",
                        original: "Cromossomo Y, microdeleções por PCR",
                        mod: "Cromossomo Y, microdeleções por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503313",
                        original: "Cromossomo Y, microdeleções por PCR",
                        mod: "Cromossomo Y, microdeleções por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503321",
                        original: "Diabetes tipo MODY 2, teste genético",
                        mod: "Diabetes tipo MODY 2, teste genético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503330",
                        original: "Diabetes tipo MODY 3, teste genético",
                        mod: "Diabetes tipo MODY 3, teste genético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503348",
                        original: "Distrofia miotonica, análise por DNA",
                        mod: "Distrofia miotonica, análise por DNA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503356",
                        original: "Disautonomia familiar, análise por DNA",
                        mod: "Disautonomia familiar, análise por DNA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503364",
                        original: "Neurofibromatose tipo 1, estudo molecular",
                        mod: "Neurofibromatose tipo 1, estudo molecular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503372",
                        original: "JAK2 (gene), detecção das mutações por PCR",
                        mod: "JAK2 (gene), detecção das mutações por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503380",
                        original: "CCND1 e IGH (genes), hibridização in situ por fluore",
                        mod: "CCND1 e IGH (genes), hibridização in situ por fluore",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503399",
                        original: "Hemofilia A, análise do DNA",
                        mod: "Hemofilia A, análise do DNA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503402",
                        original: "Hemofilia B, análise do DNA",
                        mod: "Hemofilia B, análise do DNA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503410",
                        original: "Hipolactasia, análise molecular",
                        mod: "Hipolactasia, análise molecular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503429",
                        original: "Hormônio de crescimento, estudo do gene receptor",
                        mod: "Hormônio de crescimento, estudo do gene receptor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503437",
                        original: "Hormônio de crescimento, estudo molecular do gene",
                        mod: "Hormônio de crescimento, estudo molecular do gene",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503445",
                        original: "Neoplasia endócrina múltipla, tipo 1, sangue total",
                        mod: "Neoplasia endócrina múltipla, tipo 1, sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503453",
                        original: "Hemocromatose, análise por PCR",
                        mod: "Hemocromatose, análise por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503461",
                        original: "Prader-Willi/Angelman, síndrome, diagnóstico",
                        mod: "Prader-Willi/Angelman, síndrome, diagnóstico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503470",
                        original: "PROP1, estudo molecular do gene, sangue total",
                        mod: "PROP1, estudo molecular do gene, sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503488",
                        original: "PTPN11, estudo molecular do gene, sangue total",
                        mod: "PTPN11, estudo molecular do gene, sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503496",
                        original: "Rearranjo 8q24 fish (medula óssea)",
                        mod: "Rearranjo 8q24 fish (medula óssea)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503500",
                        original: "Rearranjo 8q24 fish (sangue)",
                        mod: "Rearranjo 8q24 fish (sangue)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503518",
                        original: "Rearranjo BCL6 3q27 (NHL) fish",
                        mod: "Rearranjo BCL6 3q27 (NHL) fish",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503526",
                        original: "Rearranjo gênico células B por PCR",
                        mod: "Rearranjo gênico células B por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503534",
                        original: "Rearranjo gênico células T por PCR",
                        mod: "Rearranjo gênico células T por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503542",
                        original: "Rearranjo gênico quantitativo BCR/ABL por PCR",
                        mod: "Rearranjo gênico quantitativo BCR/ABL por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503550",
                        original: "Receptor 4 do melanocorticotropina, estudo molecular do gene",
                        mod: "Receptor 4 do melanocorticotropina, estudo molecular do gene",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503569",
                        original: "Sensibilidade genética a Warfarina padrão",
                        mod: "Sensibilidade genética a Warfarina padrão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503577",
                        original: "SHOX, estudo molecular do gene, sangue total",
                        mod: "SHOX, estudo molecular do gene, sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503585",
                        original: "Translocação 4;14 fish (IgH/FGFR3)",
                        mod: "Translocação 4;14 fish (IgH/FGFR3)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503593",
                        original: "C kit análise mutacional",
                        mod: "C kit análise mutacional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503607",
                        original: "CYP21, estudo molecular do gene, sangue",
                        mod: "CYP21, estudo molecular do gene, sangue",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503615",
                        original: "Detecção de Del/Dupl no gene MLH1 MSH2",
                        mod: "Detecção de Del/Dupl no gene MLH1 MSH2",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503623",
                        original: "Detecção de mutações no gene MLH1 MSH2",
                        mod: "Detecção de mutações no gene MLH1 MSH2",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503631",
                        original: "Detecção de mutações no gene MSH6",
                        mod: "Detecção de mutações no gene MSH6",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503640",
                        original: "FLT3 pesquisa de mutações por PCR (cada)",
                        mod: "FLT3 pesquisa de mutações por PCR (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503658",
                        original: "Atrofia muscular e bulbar (Kennedy), por PCR",
                        mod: "Atrofia muscular e bulbar (Kennedy), por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503666",
                        original: "MPL W515 e MPL S505 análise mutacional",
                        mod: "MPL W515 e MPL S505 análise mutacional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503674",
                        original: "Mucolipidosis tipo 4 análise da mutação",
                        mod: "Mucolipidosis tipo 4 análise da mutação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503682",
                        original: "FLT3 pesquisa de mutações por eletroforese capilar (cada)",
                        mod: "FLT3 pesquisa de mutações por eletroforese capilar (cada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503690",
                        original: "Distrofia muscular (Duchenne), por PCR",
                        mod: "Distrofia muscular (Duchenne), por PCR",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503704",
                        original: "Células B painel de linfoma, Burkitt's -T(8;14); Folicular - T(14;14) e células de manto - T(11;14), FISH",
                        mod: "Células B painel de linfoma, Burkitt's -T(8;14); Folicular - T(14;14) e células de manto - T(11;14), FISH",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503712",
                        original: "Pesquisa dea mutação 35delg da conexina",
                        mod: "Pesquisa dea mutação 35delg da conexina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503720",
                        original: "Identificação genética - exame de paternidade (cada pessoa)",
                        mod: "Identificação genética - exame de paternidade (cada pessoa)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503739",
                        original: "Atrofia dentato-rubro-palido-luysiana, DRPLA, sangue total",
                        mod: "Atrofia dentato-rubro-palido-luysiana, DRPLA, sangue total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503747",
                        original: "Detecção de Niemann Pick tipo Cc",
                        mod: "Detecção de Niemann Pick tipo Cc",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503755",
                        original: "Detecção/tipagem herpes vírus 1/2 líquor",
                        mod: "Detecção/tipagem herpes vírus 1/2 líquor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503763",
                        original: "EGFR, pesquisa de mutação",
                        mod: "EGFR, pesquisa de mutação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503771",
                        original: "K-RAS, pesquisa de mutação",
                        mod: "K-RAS, pesquisa de mutação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503780",
                        original: "BRAF, pesquisa de mutação",
                        mod: "BRAF, pesquisa de mutação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503798",
                        original: "NRAS  PCR ou sequenciamento de Sanger  para mutações nos éxons 2,  3 e 4 do gene, no tumor",
                        mod: "NRAS  PCR ou sequenciamento de Sanger  para mutações nos éxons 2,  3 e 4 do gene, no tumor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503801",
                        original: "Sequenciamento de Nova Geração (NGS) - genes isolados, painéis e grandes regiões genômicas (inclui Captura, Amplificação e Sequenciamento)",
                        mod: "Sequenciamento de Nova Geração (NGS) - genes isolados, painéis e grandes regiões genômicas (inclui Captura, Amplificação e Sequenciamento)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503810",
                        original: "Sequenciamento de Nova geração de todas as regiões codificadoras (éxons) de todo os genes do genoma  - Sequenciamento do Exoma (inclui Captura, Amplificação e Sequenciamento)",
                        mod: "Sequenciamento de Nova geração de todas as regiões codificadoras (éxons) de todo os genes do genoma  - Sequenciamento do Exoma (inclui Captura, Amplificação e Sequenciamento)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503825",
                        original: "Rearranjo PML/RARA  t(15;17) RQ-PCR (Quantitativo em tempo real)",
                        mod: "Rearranjo PML/RARA  t(15;17) RQ-PCR (Quantitativo em tempo real)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503828",
                        original: "Rearranjo PML/RARA  t(15;17) RQ-PCR (Quantitativo em tempo real)",
                        mod: "Rearranjo PML/RARA  t(15;17) RQ-PCR (Quantitativo em tempo real)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503833",
                        original: "Mutação familial específica - PCR do loccus identificado",
                        mod: "Mutação familial específica - PCR do loccus identificado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503836",
                        original: "Mutação familial específica - PCR do loccus identificado",
                        mod: "Mutação familial específica - PCR do loccus identificado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503841",
                        original: "Mutação familial específica - Sequenciamento de Sanger do loccus identificado",
                        mod: "Mutação familial específica - Sequenciamento de Sanger do loccus identificado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503844",
                        original: "Mutação familial específica - Sequenciamento de Sanger do loccus identificado",
                        mod: "Mutação familial específica - Sequenciamento de Sanger do loccus identificado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40503852",
                        original: "ALK - pesquisa de mutação",
                        mod: "ALK - pesquisa de mutação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601013",
                        original: "Procedimento diagnóstico peroperatório sem deslocamento do patologista",
                        mod: "Procedimento diagnóstico peroperatório sem deslocamento do patologista",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601021",
                        original: "Procedimento diagnóstico peroperatório - peça adicional ou margem cirúrgica",
                        mod: "Procedimento diagnóstico peroperatório - peça adicional ou margem cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601030",
                        original: "Procedimento diagnóstico peroperatório com deslocamento do patologista",
                        mod: "Procedimento diagnóstico peroperatório com deslocamento do patologista",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601048",
                        original: "Necrópsia de adulto/criança e natimorto com suspeita de anomalia genética",
                        mod: "Necrópsia de adulto/criança e natimorto com suspeita de anomalia genética",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601056",
                        original: "Necrópsia de embrião/feto até 500 gramas",
                        mod: "Necrópsia de embrião/feto até 500 gramas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601064",
                        original: "Microscopia eletrônica",
                        mod: "Microscopia eletrônica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601072",
                        original: "Ato de coleta de PAAF de órgãos ou estruturas superficiais sem deslocamento do patologista",
                        mod: "Ato de coleta de PAAF de órgãos ou estruturas superficiais sem deslocamento do patologista",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601080",
                        original: "Ato de coleta de PAAF de órgãos ou estruturas profundas sem deslocamento do patologista",
                        mod: "Ato de coleta de PAAF de órgãos ou estruturas profundas sem deslocamento do patologista",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601099",
                        original: "Ato de coleta de PAAF de órgãos ou estruturas superficiais com deslocamento do patologista",
                        mod: "Ato de coleta de PAAF de órgãos ou estruturas superficiais com deslocamento do patologista",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601102",
                        original: "Ato de coleta de PAAF de órgãos ou estruturas profundas com deslocamento do patologista",
                        mod: "Ato de coleta de PAAF de órgãos ou estruturas profundas com deslocamento do patologista",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601110",
                        original: "Procedimento diagnóstico em biópsia simples 'imprint' e 'cell block'",
                        mod: "Procedimento diagnóstico em biópsia simples 'imprint' e 'cell block'",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601129",
                        original: "Procedimento diagnóstico citopatológico oncótico de líquidos e raspados cutâneos",
                        mod: "Procedimento diagnóstico citopatológico oncótico de líquidos e raspados cutâneos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601137",
                        original: "Procedimento diagnóstico em citopatologia cérvico-vaginal oncótica",
                        mod: "Procedimento diagnóstico em citopatologia cérvico-vaginal oncótica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601145",
                        original: "Procedimento diagnóstico em citologia hormonal seriado",
                        mod: "Procedimento diagnóstico em citologia hormonal seriado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601153",
                        original: "Procedimento diagnóstico em revisão de lâminas ou cortes histológicos seriados",
                        mod: "Procedimento diagnóstico em revisão de lâminas ou cortes histológicos seriados",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601161",
                        original: "Procedimento diagnóstico em citologia hormonal isolada",
                        mod: "Procedimento diagnóstico em citologia hormonal isolada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601170",
                        original: "Procedimento diagnóstico em painel de imunoistoquímica (duas a cinco reações)",
                        mod: "Procedimento diagnóstico em painel de imunoistoquímica (duas a cinco reações)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601188",
                        original: "Procedimento diagnóstico em reação imunoistoquímica isolada",
                        mod: "Procedimento diagnóstico em reação imunoistoquímica isolada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601196",
                        original: "Procedimento diagnóstico em fragmentos múltiplos de biópsias de mesmo órgão ou topografia, acondicionados em um mesmo frasco",
                        mod: "Procedimento diagnóstico em fragmentos múltiplos de biópsias de mesmo órgão ou topografia, acondicionados em um mesmo frasco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601200",
                        original: "Procedimento diagnóstico em peça anatômica ou cirúrgica simples",
                        mod: "Procedimento diagnóstico em peça anatômica ou cirúrgica simples",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601218",
                        original: "Procedimento diagnóstico em peça cirúrgica ou anatômica complexa",
                        mod: "Procedimento diagnóstico em peça cirúrgica ou anatômica complexa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601226",
                        original: "Procedimento diagnóstico em grupos de linfonodos, estruturas vizinhas e margens de peças anatômicas simples ou complexas (por margem) - máximo de três margens",
                        mod: "Procedimento diagnóstico em grupos de linfonodos, estruturas vizinhas e margens de peças anatômicas simples ou complexas (por margem) - máximo de três margens",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601234",
                        original: "Procedimento diagnóstico em amputação de membros - sem causa oncológica",
                        mod: "Procedimento diagnóstico em amputação de membros - sem causa oncológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601242",
                        original: "Procedimento diagnóstico em amputação de membros - causa oncológica",
                        mod: "Procedimento diagnóstico em amputação de membros - causa oncológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601250",
                        original: "Procedimento diagnóstico em lâminas de PAAF até 5",
                        mod: "Procedimento diagnóstico em lâminas de PAAF até 5",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601269",
                        original: "Coloração especial por coloração",
                        mod: "Coloração especial por coloração",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601277",
                        original: "Procedimento diagnóstico em imunofluorescência",
                        mod: "Procedimento diagnóstico em imunofluorescência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601285",
                        original: "Procedimento diagnóstico em painel de hibridização 'in situ'",
                        mod: "Procedimento diagnóstico em painel de hibridização 'in situ'",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601293",
                        original: "Procedimento diagnóstico por captura híbrida",
                        mod: "Procedimento diagnóstico por captura híbrida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601307",
                        original: "Procedimento diagnóstico em citometria de fluxo (por monoclonal pesquisado)",
                        mod: "Procedimento diagnóstico em citometria de fluxo (por monoclonal pesquisado)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601315",
                        original: "Procedimento diagnóstico em citometria de imagens",
                        mod: "Procedimento diagnóstico em citometria de imagens",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601323",
                        original: "Procedimento diagnóstico citopatológico em meio líquido",
                        mod: "Procedimento diagnóstico citopatológico em meio líquido",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601331",
                        original: "Citológico anatomia patológica, qualquer material",
                        mod: "Citológico anatomia patológica, qualquer material",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601340",
                        original: "Citológico em líquido ascítico",
                        mod: "Citológico em líquido ascítico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601358",
                        original: "Citológico em líquido pericárdio",
                        mod: "Citológico em líquido pericárdio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601366",
                        original: "Citológico em líquido sinovial",
                        mod: "Citológico em líquido sinovial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601374",
                        original: "Citológico em outros materiais",
                        mod: "Citológico em outros materiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601382",
                        original: "DNA citometria fluxo parafina - outros materiais",
                        mod: "DNA citometria fluxo parafina - outros materiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601390",
                        original: "Imprint de gânglio",
                        mod: "Imprint de gânglio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601404",
                        original: "Imprint de medula óssea",
                        mod: "Imprint de medula óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601412",
                        original: "AP bióspia múltiplas (até 6 áreas)",
                        mod: "AP bióspia múltiplas (até 6 áreas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601420",
                        original: "AP biópsia mútliplas (de 7 a 10 áreas)",
                        mod: "AP biópsia mútliplas (de 7 a 10 áreas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40601439",
                        original: "Instabilidade de microssatélites (MSI), detecção por PCR, bloco de parafina",
                        mod: "Instabilidade de microssatélites (MSI), detecção por PCR, bloco de parafina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701018",
                        original: "Angiografia radioisotópica",
                        mod: "Angiografia radioisotópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701026",
                        original: "Cintilografia com hemácias marcadas",
                        mod: "Cintilografia com hemácias marcadas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701034",
                        original: "Cintilografia do miocárdio com duplo isótopo (perfusão + viabilidade)",
                        mod: "Cintilografia do miocárdio com duplo isótopo (perfusão + viabilidade)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701042",
                        original: "Cintilografia do miocárdio com FDG-18 F, em câmara híbrida",
                        mod: "Cintilografia do miocárdio com FDG-18 F, em câmara híbrida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701050",
                        original: "Cintilografia do miocárdio necrose (infarto agudo)",
                        mod: "Cintilografia do miocárdio necrose (infarto agudo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701069",
                        original: "Cintilografia do miocárdio perfusão - repouso",
                        mod: "Cintilografia do miocárdio perfusão - repouso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701077",
                        original: "Cintilografia sincronizada das câmaras cardíacas - esforço",
                        mod: "Cintilografia sincronizada das câmaras cardíacas - esforço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701085",
                        original: "Cintilografia sincronizada das câmaras cardíacas - repouso",
                        mod: "Cintilografia sincronizada das câmaras cardíacas - repouso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701093",
                        original: "Fluxo sanguíneo das extremidades",
                        mod: "Fluxo sanguíneo das extremidades",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701107",
                        original: "Quantificação de 'shunt' da direita para a esquerda",
                        mod: "Quantificação de 'shunt' da direita para a esquerda",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701115",
                        original: "Quantificação de 'shunt' periférico",
                        mod: "Quantificação de 'shunt' periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701123",
                        original: "Venografia radioisotópica",
                        mod: "Venografia radioisotópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701131",
                        original: "Cintilografia do miocárdio perfusão - estresse farmacológico",
                        mod: "Cintilografia do miocárdio perfusão - estresse farmacológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701140",
                        original: "Cintilografia do miocárdio perfusão - estresse físico",
                        mod: "Cintilografia do miocárdio perfusão - estresse físico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40701158",
                        original: "Cintilografia de perfusão do miocárido, associada à Dobutamina",
                        mod: "Cintilografia de perfusão do miocárido, associada à Dobutamina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702014",
                        original: "Cintilografia das glândulas salivares com ou sem estímulo",
                        mod: "Cintilografia das glândulas salivares com ou sem estímulo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702022",
                        original: "Cintilografia do fígado e do baço",
                        mod: "Cintilografia do fígado e do baço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702030",
                        original: "Cintilografia do fígado e vias biliares",
                        mod: "Cintilografia do fígado e vias biliares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702049",
                        original: "Cintilografia para detecção de hemorragia digestória ativa",
                        mod: "Cintilografia para detecção de hemorragia digestória ativa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702057",
                        original: "Cintilografia para detecção de hemorragia digestória não ativa",
                        mod: "Cintilografia para detecção de hemorragia digestória não ativa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702065",
                        original: "Cintilografia para determinação do tempo de esvaziamento gástrico",
                        mod: "Cintilografia para determinação do tempo de esvaziamento gástrico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702073",
                        original: "Cintilografia para estudo de trânsito esofágico (líquidos)",
                        mod: "Cintilografia para estudo de trânsito esofágico (líquidos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702081",
                        original: "Cintilografia para estudo de trânsito esofágico (semi-sólidos)",
                        mod: "Cintilografia para estudo de trânsito esofágico (semi-sólidos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702090",
                        original: "Cintilografia para pesquisa de divertículo de Meckel",
                        mod: "Cintilografia para pesquisa de divertículo de Meckel",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702103",
                        original: "Cintilografia para pesquisa de refluxo gastro-esofágico",
                        mod: "Cintilografia para pesquisa de refluxo gastro-esofágico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702111",
                        original: "Fluxo sanguíneo hepático (qualitativo e quantitativo)",
                        mod: "Fluxo sanguíneo hepático (qualitativo e quantitativo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702120",
                        original: "Absorção de gorduras",
                        mod: "Absorção de gorduras",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702138",
                        original: "Perdas proteicas",
                        mod: "Perdas proteicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40702146",
                        original: "Cintilografia, receptores da Somatostatina com lutécio - 177",
                        mod: "Cintilografia, receptores da Somatostatina com lutécio - 177",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40703010",
                        original: "Cintilografia da tireóide e/ou captação (iodo - 123)",
                        mod: "Cintilografia da tireóide e/ou captação (iodo - 123)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40703029",
                        original: "Cintilografia da tireóide e/ou captação (iodo - 131)",
                        mod: "Cintilografia da tireóide e/ou captação (iodo - 131)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40703037",
                        original: "Cintilografia da tireóide e/ou captação (tecnécio - 99m TC)",
                        mod: "Cintilografia da tireóide e/ou captação (tecnécio - 99m TC)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40703045",
                        original: "Cintilografia das paratireóides",
                        mod: "Cintilografia das paratireóides",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40703053",
                        original: "Cintilografia de corpo inteiro para pesquisa de metástases (PCI)",
                        mod: "Cintilografia de corpo inteiro para pesquisa de metástases (PCI)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40703061",
                        original: "Teste de estímulo com TSH recombinante",
                        mod: "Teste de estímulo com TSH recombinante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40703070",
                        original: "Teste de supressão da tireóide com T3",
                        mod: "Teste de supressão da tireóide com T3",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40703088",
                        original: "Teste do perclorato",
                        mod: "Teste do perclorato",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40703096",
                        original: "Cintilografia de corpo inteiro com metaiodobenzilguandina - iodo-123",
                        mod: "Cintilografia de corpo inteiro com metaiodobenzilguandina - iodo-123",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40703100",
                        original: "Cintilografia de corpo inteiro com MIBI marcada com tecnécio - 99m",
                        mod: "Cintilografia de corpo inteiro com MIBI marcada com tecnécio - 99m",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40704017",
                        original: "Cintilografia renal dinâmica",
                        mod: "Cintilografia renal dinâmica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40704025",
                        original: "Cintilografia renal dinâmica com diurético",
                        mod: "Cintilografia renal dinâmica com diurético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40704033",
                        original: "Cintilografia renal estática (quantitativa ou qualitativa)",
                        mod: "Cintilografia renal estática (quantitativa ou qualitativa)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40704041",
                        original: "Cintilografia testicular (escrotal)",
                        mod: "Cintilografia testicular (escrotal)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40704050",
                        original: "Cistocintilografia direta",
                        mod: "Cistocintilografia direta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40704068",
                        original: "Cistocintilografia indireta",
                        mod: "Cistocintilografia indireta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40704076",
                        original: "Determinação da filtração glomerular",
                        mod: "Determinação da filtração glomerular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40704084",
                        original: "Determinação do fluxo plasmático renal",
                        mod: "Determinação do fluxo plasmático renal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40704092",
                        original: "Renograma",
                        mod: "Renograma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40705013",
                        original: "Cintilografia do sistema retículo-endotelial (medula óssea)",
                        mod: "Cintilografia do sistema retículo-endotelial (medula óssea)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40705021",
                        original: "Demonstração do sequestro de hemácias pelo baço",
                        mod: "Demonstração do sequestro de hemácias pelo baço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40705030",
                        original: "Determinação da sobrevida de hemácias",
                        mod: "Determinação da sobrevida de hemácias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40705048",
                        original: "Determinação do volume eritrocitário",
                        mod: "Determinação do volume eritrocitário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40705056",
                        original: "Determinação do volume plasmático",
                        mod: "Determinação do volume plasmático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40705064",
                        original: "Teste de absorção de vitamina B12 com cobalto - 57 (teste de Schilling)",
                        mod: "Teste de absorção de vitamina B12 com cobalto - 57 (teste de Schilling)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40706010",
                        original: "Cintilografia óssea (corpo total)",
                        mod: "Cintilografia óssea (corpo total)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40706028",
                        original: "Fluxo sanguíneo ósseo",
                        mod: "Fluxo sanguíneo ósseo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40707016",
                        original: "Cintilografia cerebral",
                        mod: "Cintilografia cerebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40707024",
                        original: "Cintilografia cerebral com FDG-18 F, em câmara hibrída",
                        mod: "Cintilografia cerebral com FDG-18 F, em câmara hibrída",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40707032",
                        original: "Cintilografia de perfusão cerebral",
                        mod: "Cintilografia de perfusão cerebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40707040",
                        original: "Cisternocintilografia",
                        mod: "Cisternocintilografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40707040",
                        original: "Cisternocintilografia (inclui estudo do trânsito liquórico)",
                        mod: "Cisternocintilografia (inclui estudo do trânsito liquórico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40707059",
                        original: "Cisternocintilografia para pesquisa de fístula liquórica",
                        mod: "Cisternocintilografia para pesquisa de fístula liquórica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40707067",
                        original: "Fluxo sanguíneo cerebral",
                        mod: "Fluxo sanguíneo cerebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40707075",
                        original: "Mielocintilografia",
                        mod: "Mielocintilografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40707083",
                        original: "Ventrículo-cintilografia",
                        mod: "Ventrículo-cintilografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40707091",
                        original: "Cintilografia perfusão cerebral para avaliação de transportadores de dopamina",
                        mod: "Cintilografia perfusão cerebral para avaliação de transportadores de dopamina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40708012",
                        original: "Cintilografia com análogo de somatostatina",
                        mod: "Cintilografia com análogo de somatostatina",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40708020",
                        original: "Cintilografia com gálio-67",
                        mod: "Cintilografia com gálio-67",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40708039",
                        original: "Cintilografia com leucócitos marcados",
                        mod: "Cintilografia com leucócitos marcados",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40708047",
                        original: "Cintilografia com MIBG (metaiodobenzilguanidina)",
                        mod: "Cintilografia com MIBG (metaiodobenzilguanidina)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40708055",
                        original: "Cintilografia de corpo total com FDG-18 F, em câmara híbrida",
                        mod: "Cintilografia de corpo total com FDG-18 F, em câmara híbrida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40708063",
                        original: "Cintilografia de mama (bilateral)",
                        mod: "Cintilografia de mama (bilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40708071",
                        original: "Demarcação radioisotópica de lesões tumorais",
                        mod: "Demarcação radioisotópica de lesões tumorais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40708080",
                        original: "Detecção intraoperatória radioguiada de lesões tumorais",
                        mod: "Detecção intraoperatória radioguiada de lesões tumorais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40708098",
                        original: "Detecção intraoperatória radioguiada de linfonodo sentinela",
                        mod: "Detecção intraoperatória radioguiada de linfonodo sentinela",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40708101",
                        original: "Linfocintilografia",
                        mod: "Linfocintilografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40708110",
                        original: "Quantificação da captação pulmonar com gálio-67",
                        mod: "Quantificação da captação pulmonar com gálio-67",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40708128",
                        original: "PET dedicado oncológico",
                        mod: "PET dedicado oncológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40709019",
                        original: "Cintilografia para detecção de aspiração pulmonar",
                        mod: "Cintilografia para detecção de aspiração pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40709027",
                        original: "Cintilografia pulmonar (inalação)",
                        mod: "Cintilografia pulmonar (inalação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40709035",
                        original: "Cintilografia pulmonar (perfusão)",
                        mod: "Cintilografia pulmonar (perfusão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40710017",
                        original: "Sessão médica para planejamento técnico de radioisotopoterapia",
                        mod: "Sessão médica para planejamento técnico de radioisotopoterapia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40710025",
                        original: "Tratamento com metaiodobenzilguanidina (MIBG)",
                        mod: "Tratamento com metaiodobenzilguanidina (MIBG)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40710033",
                        original: "Tratamento da policitemia vera",
                        mod: "Tratamento da policitemia vera",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40710041",
                        original: "Tratamento de câncer da tireóide",
                        mod: "Tratamento de câncer da tireóide",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40710050",
                        original: "Tratamento de hipertireoidismo-bócio nodular tóxico (Graves)",
                        mod: "Tratamento de hipertireoidismo-bócio nodular tóxico (Graves)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40710068",
                        original: "Tratamento de hipertireoidismo-bócio nodular tóxico (Plummer)",
                        mod: "Tratamento de hipertireoidismo-bócio nodular tóxico (Plummer)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40710076",
                        original: "Tratamento de metástases ósseas (estrôncio-90)",
                        mod: "Tratamento de metástases ósseas (estrôncio-90)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40710084",
                        original: "Tratamento de metástases ósseas (samário-153)",
                        mod: "Tratamento de metástases ósseas (samário-153)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40710092",
                        original: "Tratamento de tumores neuroendócrinos",
                        mod: "Tratamento de tumores neuroendócrinos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40710106",
                        original: "Controle após terapia com lutécio",
                        mod: "Controle após terapia com lutécio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40711013",
                        original: "Dacriocintilografia",
                        mod: "Dacriocintilografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40711021",
                        original: "Imunocintilografia (anticorpos monoclonais)",
                        mod: "Imunocintilografia (anticorpos monoclonais)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801012",
                        original: "RX - Crânio - 2 incidências",
                        mod: "RX - Crânio - 2 incidências",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801020",
                        original: "RX - Crânio - 3 incidências",
                        mod: "RX - Crânio - 3 incidências",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801039",
                        original: "RX - Crânio - 4 incidências",
                        mod: "RX - Crânio - 4 incidências",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801047",
                        original: "RX - Orelha, mastóides ou rochedos - bilateral",
                        mod: "RX - Orelha, mastóides ou rochedos - bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801055",
                        original: "RX - Órbitas - bilateral",
                        mod: "RX - Órbitas - bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801063",
                        original: "RX - Seios da face",
                        mod: "RX - Seios da face",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801071",
                        original: "RX - Sela túrcica",
                        mod: "RX - Sela túrcica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801080",
                        original: "RX - Maxilar inferior",
                        mod: "RX - Maxilar inferior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801098",
                        original: "RX - Ossos da face",
                        mod: "RX - Ossos da face",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801101",
                        original: "RX - Arcos zigomáticos ou malar ou apófises estilóides",
                        mod: "RX - Arcos zigomáticos ou malar ou apófises estilóides",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801110",
                        original: "RX - Articulação temporomandibular - bilateral",
                        mod: "RX - Articulação temporomandibular - bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801128",
                        original: "RX - Adenóides ou cavum",
                        mod: "RX - Adenóides ou cavum",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801136",
                        original: "RX - Panorâmica de mandíbula (ortopantomografia)",
                        mod: "RX - Panorâmica de mandíbula (ortopantomografia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801144",
                        original: "RX - Teleperfil em cefalostato - sem traçado",
                        mod: "RX - Teleperfil em cefalostato - sem traçado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801152",
                        original: "RX - Teleperfil em cefalostato - com traçado",
                        mod: "RX - Teleperfil em cefalostato - com traçado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801160",
                        original: "RX - Arcada dentária (por arcada)",
                        mod: "RX - Arcada dentária (por arcada)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801179",
                        original: "RX - Radiografia peri-apical",
                        mod: "RX - Radiografia peri-apical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801187",
                        original: "RX - Radiografia oclusal",
                        mod: "RX - Radiografia oclusal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801195",
                        original: "RX - Planigrafia linear de crânio ou sela túrcica ou face ou mastóide",
                        mod: "RX - Planigrafia linear de crânio ou sela túrcica ou face ou mastóide",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40801209",
                        original: "RX - Incidência adicional de crânio ou face",
                        mod: "RX - Incidência adicional de crânio ou face",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40802019",
                        original: "RX - Coluna cervical - 3 incidências",
                        mod: "RX - Coluna cervical - 3 incidências",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40802027",
                        original: "RX - Coluna cervical - 5 incidências",
                        mod: "RX - Coluna cervical - 5 incidências",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40802035",
                        original: "RX - Coluna dorsal - 2 incidências",
                        mod: "RX - Coluna dorsal - 2 incidências",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40802043",
                        original: "RX - Coluna dorsal - 4 incidências",
                        mod: "RX - Coluna dorsal - 4 incidências",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40802051",
                        original: "RX - Coluna lombo-sacra - 3 incidências",
                        mod: "RX - Coluna lombo-sacra - 3 incidências",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40802060",
                        original: "RX - Coluna lombo-sacra - 5 incidências",
                        mod: "RX - Coluna lombo-sacra - 5 incidências",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40802078",
                        original: "RX - Sacro-coccix",
                        mod: "RX - Sacro-coccix",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40802086",
                        original: "RX - Coluna dorso-lombar para escoliose",
                        mod: "RX - Coluna dorso-lombar para escoliose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40802094",
                        original: "RX - Coluna total para escoliose (telespondilografia)",
                        mod: "RX - Coluna total para escoliose (telespondilografia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40802108",
                        original: "RX - Planigrafia de coluna vertebral (dois planos)",
                        mod: "RX - Planigrafia de coluna vertebral (dois planos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40802116",
                        original: "RX - Incidência adicional de coluna",
                        mod: "RX - Incidência adicional de coluna",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803015",
                        original: "RX - Esterno",
                        mod: "RX - Esterno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803023",
                        original: "RX - Articulação esternoclavicular",
                        mod: "RX - Articulação esternoclavicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803031",
                        original: "RX - Costelas - por hemitórax",
                        mod: "RX - Costelas - por hemitórax",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803040",
                        original: "RX - Clavícula",
                        mod: "RX - Clavícula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803058",
                        original: "RX - Omoplata ou escápula",
                        mod: "RX - Omoplata ou escápula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803066",
                        original: "RX - Articulação acromioclavicular",
                        mod: "RX - Articulação acromioclavicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803074",
                        original: "RX - Articulação escapuloumeral (ombro)",
                        mod: "RX - Articulação escapuloumeral (ombro)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803082",
                        original: "RX - Braço",
                        mod: "RX - Braço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803090",
                        original: "RX - Cotovelo",
                        mod: "RX - Cotovelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803104",
                        original: "RX - Antebraço",
                        mod: "RX - Antebraço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803112",
                        original: "RX - Punho",
                        mod: "RX - Punho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803120",
                        original: "RX - Mão ou quirodáctilo",
                        mod: "RX - Mão ou quirodáctilo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803139",
                        original: "RX - Mãos e punhos para idade óssea",
                        mod: "RX - Mãos e punhos para idade óssea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803147",
                        original: "RX - Incidência adicional de membro superior",
                        mod: "RX - Incidência adicional de membro superior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40803155",
                        original: "RX - Escafóide",
                        mod: "RX - Escafóide",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804011",
                        original: "RX - Bacia",
                        mod: "RX - Bacia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804020",
                        original: "RX - Articulações sacroilíacas",
                        mod: "RX - Articulações sacroilíacas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804038",
                        original: "RX - Articulação coxofemoral (quadril)",
                        mod: "RX - Articulação coxofemoral (quadril)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804046",
                        original: "RX - Coxa",
                        mod: "RX - Coxa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804054",
                        original: "RX - Joelho",
                        mod: "RX - Joelho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804062",
                        original: "RX - Patela",
                        mod: "RX - Patela",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804070",
                        original: "RX - Perna",
                        mod: "RX - Perna",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804089",
                        original: "RX - Articulação tibiotársica (tornozelo)",
                        mod: "RX - Articulação tibiotársica (tornozelo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804097",
                        original: "RX - Pé ou pododáctilo",
                        mod: "RX - Pé ou pododáctilo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804100",
                        original: "RX - Calcâneo",
                        mod: "RX - Calcâneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804119",
                        original: "RX - Escanometria",
                        mod: "RX - Escanometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804127",
                        original: "RX - Panorâmica dos membros inferiores",
                        mod: "RX - Panorâmica dos membros inferiores",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40804135",
                        original: "RX - Incidência adicional de membro inferior",
                        mod: "RX - Incidência adicional de membro inferior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40805018",
                        original: "RX - Tórax - 1 incidência",
                        mod: "RX - Tórax - 1 incidência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40805026",
                        original: "RX - Tórax - 2 incidências",
                        mod: "Radiografia de tórax - 2 incidências (PA e Perfil); ",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40805034",
                        original: "RX - Tórax - 3 incidências",
                        mod: "RX - Tórax - 3 incidências",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40805042",
                        original: "RX - Tórax - 4 incidências",
                        mod: "RX - Tórax - 4 incidências",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40805050",
                        original: "RX - Coração e vasos da base",
                        mod: "RX - Coração e vasos da base",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40805069",
                        original: "RX - Planigrafia de tórax, mediastino ou laringe",
                        mod: "RX - Planigrafia de tórax, mediastino ou laringe",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40805077",
                        original: "RX - Laringe ou hipofaringe ou pescoço (partes moles)",
                        mod: "RX - Laringe ou hipofaringe ou pescoço (partes moles)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40805085",
                        original: "RX - Abreugrafia 100 mm",
                        mod: "RX - Abreugrafia 100 mm",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40805093",
                        original: "RX - Abreugrafia 35 ou 70 mm",
                        mod: "RX - Abreugrafia 35 ou 70 mm",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806014",
                        original: "RX - Deglutograma",
                        mod: "RX - Deglutograma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806022",
                        original: "RX - Videodeglutograma",
                        mod: "RX - Videodeglutograma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806030",
                        original: "RX - Esôfago",
                        mod: "RX - Esôfago",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806049",
                        original: "RX - Estômago e duodeno",
                        mod: "RX - Estômago e duodeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806057",
                        original: "RX - Esôfago - hiato - estômago e duodeno",
                        mod: "RX - Esôfago - hiato - estômago e duodeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806065",
                        original: "RX - Trânsito e morfologia do delgado",
                        mod: "RX - Trânsito e morfologia do delgado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806073",
                        original: "RX - Estudo do delgado com duplo contraste",
                        mod: "RX - Estudo do delgado com duplo contraste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806081",
                        original: "RX - Clister ou enema opaco (duplo contraste)",
                        mod: "RX - Clister ou enema opaco (duplo contraste)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806090",
                        original: "RX - Defecograma",
                        mod: "RX - Defecograma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806103",
                        original: "RX - Colangiografia intra-operatória",
                        mod: "RX - Colangiografia intra-operatória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806111",
                        original: "RX - Colangiografia pós-operatória (pelo dreno)",
                        mod: "RX - Colangiografia pós-operatória (pelo dreno)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806120",
                        original: "RX - Colangiografia pré-operatória",
                        mod: "RX - Colangiografia pré-operatória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806138",
                        original: "RX - Colangiografia venosa",
                        mod: "RX - Colangiografia venosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806146",
                        original: "RX - Colangiografia venosa com tomografias",
                        mod: "RX - Colangiografia venosa com tomografias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806154",
                        original: "RX - Colecistograma oral",
                        mod: "RX - Colecistograma oral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806162",
                        original: "RX - Colecistograma oral com prova motora",
                        mod: "RX - Colecistograma oral com prova motora",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806170",
                        original: "RX - Duodenografia hipotônica",
                        mod: "RX - Duodenografia hipotônica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806189",
                        original: "RX - Trânsito colônico",
                        mod: "RX - Trânsito colônico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806197",
                        original: "RX - Colangiografia endoscópica",
                        mod: "RX - Colangiografia endoscópica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806200",
                        original: "RX - Videodefecograma",
                        mod: "RX - Videodefecograma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40806219",
                        original: "RX - Videodefecograma com manometria anorretal",
                        mod: "RX - Videodefecograma com manometria anorretal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40807010",
                        original: "RX - Urografia venosa com bexiga pré e pós-miccional",
                        mod: "RX - Urografia venosa com bexiga pré e pós-miccional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40807029",
                        original: "RX - Pielografia ascendente",
                        mod: "RX - Pielografia ascendente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40807037",
                        original: "RX - Urografia venosa minutada 1-2-3",
                        mod: "RX - Urografia venosa minutada 1-2-3",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40807045",
                        original: "RX - Urografia venosa com nefrotomografia",
                        mod: "RX - Urografia venosa com nefrotomografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40807053",
                        original: "RX - Uretrocistografia de adulto",
                        mod: "RX - Uretrocistografia de adulto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40807061",
                        original: "RX - Uretrocistografia de criança (até 12 anos)",
                        mod: "RX - Uretrocistografia de criança (até 12 anos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40807070",
                        original: "RX - Tomografia renal sem contraste",
                        mod: "RX - Tomografia renal sem contraste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40807088",
                        original: "RX - Pênis",
                        mod: "RX - Pênis",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40807096",
                        original: "RX - Uretrocistografia retrógada",
                        mod: "RX - Uretrocistografia retrógada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40807100",
                        original: "RX - Nefrograma, basal e após captopril",
                        mod: "RX - Nefrograma, basal e após captopril",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808017",
                        original: "RX - Abdome simples",
                        mod: "RX - Abdome simples",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808025",
                        original: "RX - Abdome agudo",
                        mod: "RX - Abdome agudo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808033",
                        original: "Mamografia convencional bilateral",
                        mod: "Mamografia convencional bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808041",
                        original: "Mamografia digital bilateral",
                        mod: "Mamografia digital bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808050",
                        original: "RX - Ampliação ou magnificação de lesão mamária",
                        mod: "RX - Ampliação ou magnificação de lesão mamária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808068",
                        original: "Marcação pré-cirúrgica por estereotaxia, orientada por imagem - por mama (já inclui exame de base)",
                        mod: "Marcação pré-cirúrgica por estereotaxia, orientada por imagem - por mama (já inclui exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808084",
                        original: "Punção ou biópsia mamária percutânea por agulha fina orientada por imagem (já inclui o exame de base)",
                        mod: "Punção ou biópsia mamária percutânea por agulha fina orientada por imagem (já inclui o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808092",
                        original: "Biópsia percutânea de fragmento mamário (core biopsy) orientada por US ou RX - agulha grossa",
                        mod: "Biópsia percutânea de fragmento mamário (core biopsy) orientada por US ou RX - agulha grossa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808106",
                        original: "Mamotomia por estereotaxia ou US",
                        mod: "Mamotomia por estereotaxia ou US",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808114",
                        original: "RX - Esqueleto (incidências básicas de: crânio, coluna, bacia e membros)",
                        mod: "RX - Esqueleto (incidências básicas de: crânio, coluna, bacia e membros)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808122",
                        original: "Densitometria óssea (um segmento)",
                        mod: "Densitometria óssea (um segmento)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808130",
                        original: "Densitometria óssea - rotina: coluna e fêmur (ou dois segmentos)",
                        mod: "Densitometria óssea - rotina: coluna e fêmur (ou dois segmentos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808149",
                        original: "Densitometria óssea - corpo inteiro (avaliação de massa óssea ou de composição corporal)",
                        mod: "Densitometria óssea - corpo inteiro (avaliação de massa óssea ou de composição corporal)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808157",
                        original: "RX - Avaliação de fraturas vertebrais por DXA",
                        mod: "RX - Avaliação de fraturas vertebrais por DXA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808165",
                        original: "Planigrafia de osso",
                        mod: "Planigrafia de osso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808173",
                        original: "Xeromamografia",
                        mod: "Xeromamografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808181",
                        original: "Biópsia de mama, dirigida por RM",
                        mod: "Biópsia de mama, dirigida por RM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808190",
                        original: "Marcação pré-cirúrgica por nódulo - máximo de 3 nódulos por mama, por estereotaxia (não inclui exame de imagem)",
                        mod: "Marcação pré-cirúrgica por nódulo - máximo de 3 nódulos por mama, por estereotaxia (não inclui exame de imagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808200",
                        original: "MARCAÇÃO PRÉ-CIRÚRGICA POR NÓDULO - MÁXIMO DE 3 NÓDULOS POR MAMA, POR US (NÃO INCLUI EXAME DE IMAGEM)",
                        mod: "MARCAÇÃO PRÉ-CIRÚRGICA POR NÓDULO - MÁXIMO DE 3 NÓDULOS POR MAMA, POR US (NÃO INCLUI EXAME DE IMAGEM)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808203",
                        original: "Marcação pré-cirúrgica por nódulo - máximo de 3 nódulos por mama, por US (não inclui exame de imagem)",
                        mod: "Marcação pré-cirúrgica por nódulo - máximo de 3 nódulos por mama, por US (não inclui exame de imagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808211",
                        original: "Marcação pré-cirúrgica por nódulo - máximo de 3 nódulos por mama, por RM (não inclui exame de imagem)",
                        mod: "Marcação pré-cirúrgica por nódulo - máximo de 3 nódulos por mama, por Ressonância magnética (não inclui exame de imagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808220",
                        original: "Punção ou biópsia mamária percutânea por agulha fina orientada por estereotaxia (não inclui o exame de base)",
                        mod: "Punção ou biópsia mamária percutânea por agulha fina orientada por estereotaxia (não inclui o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808238",
                        original: "Punção ou biópsia mamária percutânea por agulha fina orientada por US (não inclui o exame de base)",
                        mod: "Punção ou biópsia mamária percutânea por agulha fina orientada por US (não inclui o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808246",
                        original: "Punção ou biópsia mamária percutânea por agulha fina orientada por TC (não inclui o exame de base)",
                        mod: "Punção ou biópsia mamária percutânea por agulha fina orientada por TC (não inclui o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808254",
                        original: "Biópsia percutânea de fragmento mamário por agulha grossa (core biopsy) orientada por estereotaxia (não inclui o exame de imagem)",
                        mod: "Biópsia percutânea de fragmento mamário por agulha grossa (core biopsy) orientada por estereotaxia (não inclui o exame de imagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808262",
                        original: "Biópsia percutânea de fragmento mamário por agulha grossa (core biopsy) orientada por US (não inclui o exame de imagem)",
                        mod: "Biópsia percutânea de fragmento mamário por agulha grossa (core biopsy) orientada por US (não inclui o exame de imagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808270",
                        original: "Biópsia percutânea de fragmento mamário por agulha grossa (core biopsy) orientada por RM (não inclui o exame de imagem)",
                        mod: "Biópsia percutânea de fragmento mamário por agulha grossa (core biopsy) orientada por Ressonância magnética (não inclui o exame de imagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808289",
                        original: "Mamotomia por estereotaxia (não inclui o exame de imagem)",
                        mod: "Mamotomia por estereotaxia (não inclui o exame de imagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808297",
                        original: "Mamotomia por US (não inclui o exame de imagem)",
                        mod: "Mamotomia por US (não inclui o exame de imagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40808300",
                        original: "Mamotomia por RM (não inclui o exame de imagem)",
                        mod: "Mamotomia por Ressonância magnética (não inclui o exame de imagem)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809013",
                        original: "Ductografia (por mama)",
                        mod: "Ductografia (por mama)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809021",
                        original: "Sialografia (por glândula)",
                        mod: "Sialografia (por glândula)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809030",
                        original: "Histerossalpingografia",
                        mod: "Histerossalpingografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809048",
                        original: "Artrografia ou pneumoartrografia",
                        mod: "Artrografia ou pneumoartrografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809056",
                        original: "Fistulografia",
                        mod: "Fistulografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809064",
                        original: "Colangiografia transcutânea",
                        mod: "Colangiografia transcutânea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809072",
                        original: "Colangiopancreatografia retrógrada",
                        mod: "Colangiopancreatografia retrógrada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809080",
                        original: "Dacriocistografia",
                        mod: "Dacriocistografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809099",
                        original: "Punção biópsia/aspirativa de órgão ou estrutura orientada por RX, US ou CT (acrescentar o exame base)",
                        mod: "Punção biópsia/aspirativa de órgão ou estrutura orientada por RX, US ou CT (acrescentar o exame base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809102",
                        original: "Drenagem percutânea orientada por RX (acrescentar o exame de base)",
                        mod: "Drenagem percutânea orientada por RX (acrescentar o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809110",
                        original: "Broncografia (por punção ou entubação traqueal)",
                        mod: "Broncografia (por punção ou entubação traqueal)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809129",
                        original: "Broncografia unilateral",
                        mod: "Broncografia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809137",
                        original: "Pneumoperitônio (RX)",
                        mod: "Pneumoperitônio (RX)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809145",
                        original: "Colocação de marcador de local da intervenção",
                        mod: "Colocação de marcador de local da intervenção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809153",
                        original: "Punção biópsia/aspirativa de órgão ou estrutura orientada por RX (não inclui o exame de base)",
                        mod: "Punção biópsia/aspirativa de órgão ou estrutura orientada por RX (não inclui o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809161",
                        original: "Punção biópsia/aspirativa de órgão ou estrutura orientada por US (não inclui o exame de base)",
                        mod: "Punção biópsia/aspirativa de órgão ou estrutura orientada por US (não inclui o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809170",
                        original: "Punção biópsia/aspirativa de órgão ou estrutura orientada por TC (não inclui o exame de base)",
                        mod: "Punção biópsia/aspirativa de órgão ou estrutura orientada por TC (não inclui o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40809188",
                        original: "Punção biópsia/aspirativa de órgão ou estrutura orientada por RM (não inclui o exame de base)",
                        mod: "Punção biópsia/aspirativa de órgão ou estrutura orientada por Ressonância magnética (não inclui o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40810011",
                        original: "Mielografia segmentar (por segmento)",
                        mod: "Mielografia segmentar (por segmento)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40810020",
                        original: "Teste de oclusão de artéria carótida ou vertebral",
                        mod: "Teste de oclusão de artéria carótida ou vertebral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40810038",
                        original: "Colheita seletiva de sangue para dosagem hormonal",
                        mod: "Colheita seletiva de sangue para dosagem hormonal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40810046",
                        original: "Avaliação hemodinâmica por cateterismo (aferimento de pressão ou fluxo arterial ou venoso)",
                        mod: "Avaliação hemodinâmica por cateterismo (aferimento de pressão ou fluxo arterial ou venoso)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40811018",
                        original: "Radioscopia diagnóstica",
                        mod: "Radioscopia diagnóstica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40811026",
                        original: "Radioscopia para acompanhamento de procedimento cirúrgico (por hora ou fração)",
                        mod: "Radioscopia para acompanhamento de procedimento cirúrgico (por hora ou fração)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812014",
                        original: "Aortografia abdominal por punção translombar",
                        mod: "Aortografia abdominal por punção translombar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812022",
                        original: "Angiografia por punção",
                        mod: "Angiografia por punção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812030",
                        original: "Angiografia por cateterismo não seletivo de grande vaso",
                        mod: "Angiografia por cateterismo não seletivo de grande vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812049",
                        original: "Angiografia por cateterismo seletivo de ramo primário - por vaso",
                        mod: "Angiografia por cateterismo seletivo de ramo primário - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812057",
                        original: "Angiografia por cateterismo superseletivo de ramo secundário ou distal - por vaso",
                        mod: "Angiografia por cateterismo superseletivo de ramo secundário ou distal - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812065",
                        original: "Angiografia transoperatória de posicionamento",
                        mod: "Angiografia transoperatória de posicionamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812073",
                        original: "Angiografia pós-operatória de controle",
                        mod: "Angiografia pós-operatória de controle",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812081",
                        original: "Flebografia por punção venosa unilateral",
                        mod: "Flebografia por punção venosa unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812090",
                        original: "Flebografia retrógrada por cateterismo - unilateral",
                        mod: "Flebografia retrógrada por cateterismo - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812103",
                        original: "Portografia trans-hepática",
                        mod: "Portografia trans-hepática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812111",
                        original: "Esplenoportografia percutânea",
                        mod: "Esplenoportografia percutânea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812120",
                        original: "Linfangioadenografia unilateral",
                        mod: "Linfangioadenografia unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812138",
                        original: "Cavernosografia",
                        mod: "Cavernosografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812146",
                        original: "Fármaco-cavernosografia (dinâmica)",
                        mod: "Fármaco-cavernosografia (dinâmica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812154",
                        original: "Angiografia Medular",
                        mod: "Angiografia Medular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40812155",
                        original: "Angiografia Medular",
                        mod: "Angiografia Medular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813010",
                        original: "Ablação percutânea de tumor torácico (qualquer método)",
                        mod: "Ablação percutânea de tumor torácico (qualquer método)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813029",
                        original: "Ablação percutânea de tumor hepático (qualquer método) - metodo intervencionista/terapêutico por imagem",
                        mod: "Ablação percutânea de tumor hepático (qualquer método) - metodo intervencionista/terapêutico por imagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813037",
                        original: "Ablação percutânea de tumor ósseo (qualquer método)",
                        mod: "Ablação percutânea de tumor ósseo (qualquer método)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813045",
                        original: "Ablação percutânea de tumor (qualquer método)",
                        mod: "Ablação percutânea de tumor (qualquer método)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813053",
                        original: "Alcoolização percutânea de angioma",
                        mod: "Alcoolização percutânea de angioma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813061",
                        original: "Angioplastia de ramo intracraniano",
                        mod: "Angioplastia de ramo intracraniano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813070",
                        original: "Angioplastia de tronco supra-aórtico",
                        mod: "Angioplastia de tronco supra-aórtico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813088",
                        original: "Angioplastia de aorta para tratamento de coarctação",
                        mod: "Angioplastia de aorta para tratamento de coarctação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813100",
                        original: "Angioplastia de artéria visceral - por vaso",
                        mod: "Angioplastia de artéria visceral - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813118",
                        original: "Angioplastia arterial ou venosa de anastomose vascular de fígado transplantado",
                        mod: "Angioplastia arterial ou venosa de anastomose vascular de fígado transplantado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813126",
                        original: "Angioplastia renal para tratamento de hipertensão renovascular ou outra condição",
                        mod: "Angioplastia renal para tratamento de hipertensão renovascular ou outra condição",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813134",
                        original: "Angioplastia arterial ou venosa de anastomose vascular de rim transplantado",
                        mod: "Angioplastia arterial ou venosa de anastomose vascular de rim transplantado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813142",
                        original: "Angioplastia de ramos hipogástricos para tratamento de impotência",
                        mod: "Angioplastia de ramos hipogástricos para tratamento de impotência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813150",
                        original: "Angioplastia de tronco venoso",
                        mod: "Angioplastia de tronco venoso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813169",
                        original: "Angioplastia venosa para tratamento de síndrome de BUDD-CHIARI",
                        mod: "Angioplastia venosa para tratamento de síndrome de BUDD-CHIARI",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813177",
                        original: "Angioplastia transluminal percutânea",
                        mod: "Angioplastia transluminal percutânea",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813185",
                        original: "Angioplastia transluminal percutânea para tratamento de obstrução arterial",
                        mod: "Angioplastia transluminal percutânea para tratamento de obstrução arterial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813193",
                        original: "Colocação de stent em ramo intracraniano",
                        mod: "Colocação de stent em ramo intracraniano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813207",
                        original: "Colocação de stent em tronco supra-aórtico",
                        mod: "Colocação de stent em tronco supra-aórtico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813215",
                        original: "Colocação de stent aórtico",
                        mod: "Colocação de stent aórtico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813223",
                        original: "Colocação de stent para tratamento de síndrome de VCI",
                        mod: "Colocação de stent para tratamento de síndrome de VCI",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813231",
                        original: "Colocação de cateter venoso central ou portocath",
                        mod: "Colocação de cateter venoso central ou portocath",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813240",
                        original: "Colocação de filtro de VCI para prevenção de TEP",
                        mod: "Colocação de filtro de VCI para prevenção de TEP",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813258",
                        original: "Colocação de stent em artéria visceral - por vaso",
                        mod: "Colocação de stent em artéria visceral - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813266",
                        original: "Colocação de stent para tratamento de obstrução arterial ou venosa",
                        mod: "Colocação de stent para tratamento de obstrução arterial ou venosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813274",
                        original: "Colocação de stent revestido (stent-graft) para tratamento de aneurisma periférico",
                        mod: "Colocação de stent revestido (stent-graft) para tratamento de aneurisma periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813282",
                        original: "Colocação de stent revestido (stent-graft) para tratamento de fístula arteriovenosa",
                        mod: "Colocação de stent revestido (stent-graft) para tratamento de fístula arteriovenosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813290",
                        original: "Colocação de stent em estenose vascular de enxerto transplantado",
                        mod: "Colocação de stent em estenose vascular de enxerto transplantado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813304",
                        original: "Colocação de stent em traquéia ou brônquio",
                        mod: "Colocação de stent em traquéia ou brônquio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813312",
                        original: "Colocação de stent esofagiano, duodenal ou colônico",
                        mod: "Colocação de stent esofagiano, duodenal ou colônico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813320",
                        original: "Colocação de stent biliar",
                        mod: "Colocação de stent biliar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813339",
                        original: "Colocação de stent renal",
                        mod: "Colocação de stent renal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813347",
                        original: "Colocação percutânea de cateter pielovesical",
                        mod: "Colocação percutânea de cateter pielovesical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813355",
                        original: "Colocação percutânea de stent vascular",
                        mod: "Colocação percutânea de stent vascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813363",
                        original: "Coluna vertebral: infiltração foraminal ou facetária ou articular",
                        mod: "Coluna vertebral: infiltração foraminal ou facetária ou articular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813371",
                        original: "Dilatação percutânea de estenose biliar cicatricial",
                        mod: "Dilatação percutânea de estenose biliar cicatricial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813380",
                        original: "Dilatação percutânea de estenose de conduto urinário",
                        mod: "Dilatação percutânea de estenose de conduto urinário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813398",
                        original: "Dilatação percutânea de estenose de ducto pancreático",
                        mod: "Dilatação percutânea de estenose de ducto pancreático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813401",
                        original: "Aterectomia percutânea orientada por RX",
                        mod: "Aterectomia percutânea orientada por RX",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813410",
                        original: "Drenagem percutânea de coleção pleural",
                        mod: "Drenagem percutânea de coleção pleural",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813428",
                        original: "Drenagem percutânea de pneumotórax",
                        mod: "Drenagem percutânea de pneumotórax",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813436",
                        original: "Drenagem de abscesso pulmonar ou mediastinal",
                        mod: "Drenagem de abscesso pulmonar ou mediastinal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813444",
                        original: "Drenagem mediastinal orientada por RX ou TC",
                        mod: "Drenagem mediastinal orientada por RX ou TC",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813452",
                        original: "Drenagem percutânea de coleção infectada abdominal",
                        mod: "Drenagem percutânea de coleção infectada abdominal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813460",
                        original: "Drenagem percutânea de abscesso hepático ou pancreático",
                        mod: "Drenagem percutânea de abscesso hepático ou pancreático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813479",
                        original: "Drenagem percutânea de cisto hepático ou pancreático",
                        mod: "Drenagem percutânea de cisto hepático ou pancreático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813487",
                        original: "Drenagem percutânea de via biliar",
                        mod: "Drenagem percutânea de via biliar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813495",
                        original: "Drenagem percutânea de cisto renal",
                        mod: "Drenagem percutânea de cisto renal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813509",
                        original: "Drenagem percutânea de abscesso renal",
                        mod: "Drenagem percutânea de abscesso renal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813517",
                        original: "Drenagem percutânea de coleção infectada profunda",
                        mod: "Drenagem percutânea de coleção infectada profunda",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813525",
                        original: "Drenagem percutânea de abscesso retroperitoneal ou pélvico",
                        mod: "Drenagem percutânea de abscesso retroperitoneal ou pélvico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813533",
                        original: "Drenagem percutânea não especificada",
                        mod: "Drenagem percutânea não especificada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813541",
                        original: "Embolização de aneurisma cerebral por oclusão sacular - por vaso",
                        mod: "Embolização de aneurisma cerebral por oclusão sacular - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813550",
                        original: "Embolização de aneurisma cerebral por oclusão vascular - por vaso",
                        mod: "Embolização de aneurisma cerebral por oclusão vascular - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813568",
                        original: "Embolização de malformação arteriovenosa cerebral ou medular - por vaso",
                        mod: "Embolização de malformação arteriovenosa cerebral ou medular - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813576",
                        original: "Embolização de fístula arteriovenosa em cabeça, pescoço ou coluna - por vaso",
                        mod: "Embolização de fístula arteriovenosa em cabeça, pescoço ou coluna - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813584",
                        original: "Embolização para tratamento de epistaxe",
                        mod: "Embolização para tratamento de epistaxe",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813592",
                        original: "Embolização de aneurisma ou pseudoaneurisma visceral",
                        mod: "Embolização de aneurisma ou pseudoaneurisma visceral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813606",
                        original: "Embolização brônquica para tratamento de hemoptise",
                        mod: "Embolização brônquica para tratamento de hemoptise",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813614",
                        original: "Embolização pulmonar para tratamento de fístula arteriovenosa ou outra situação",
                        mod: "Embolização pulmonar para tratamento de fístula arteriovenosa ou outra situação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813622",
                        original: "Embolização de varizes esofagianas ou gástricas",
                        mod: "Embolização de varizes esofagianas ou gástricas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813630",
                        original: "Embolização de hemorragia digestiva",
                        mod: "Embolização de hemorragia digestiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813649",
                        original: "Embolização de ramo portal",
                        mod: "Embolização de ramo portal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813657",
                        original: "Embolização esplênica para tratamento de hiperesplenismo ou outra situação",
                        mod: "Embolização esplênica para tratamento de hiperesplenismo ou outra situação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813665",
                        original: "Embolização arterial para tratamento de priapismo",
                        mod: "Embolização arterial para tratamento de priapismo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813673",
                        original: "Embolização para tratamento de impotência",
                        mod: "Embolização para tratamento de impotência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813681",
                        original: "Embolização de ramos hipogástricos para tratamento de sangramento ginecológico",
                        mod: "Embolização de ramos hipogástricos para tratamento de sangramento ginecológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813690",
                        original: "Embolização seletiva de fístula ou aneurisma renal para tratamento de hematúria",
                        mod: "Embolização seletiva de fístula ou aneurisma renal para tratamento de hematúria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813703",
                        original: "Embolização de artéria renal para nefrectomia",
                        mod: "Embolização de artéria renal para nefrectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813711",
                        original: "Embolização de fístula arteriovenosa não especificada acima - por vaso",
                        mod: "Embolização de fístula arteriovenosa não especificada acima - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813720",
                        original: "Embolização de malformação vascular - por vaso",
                        mod: "Embolização de malformação vascular - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813738",
                        original: "Embolização de pseudoaneurisma - por vaso",
                        mod: "Embolização de pseudoaneurisma - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813746",
                        original: "Embolização de artéria uterina para tratamento de mioma ou outras situações",
                        mod: "Embolização de artéria uterina para tratamento de mioma ou outras situações",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813754",
                        original: "Embolização de veia espermática para tratamento de varicocele",
                        mod: "Embolização de veia espermática para tratamento de varicocele",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813762",
                        original: "Embolização de veias ovarianas para tratamento de varicocele",
                        mod: "Embolização de veias ovarianas para tratamento de varicocele",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813762",
                        original: "Embolização de veias ovarianas para tratamento de varizes pélvicas",
                        mod: "Embolização de veias ovarianas para tratamento de varizes pélvicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813770",
                        original: "Embolização definitiva não especificada acima - por vaso",
                        mod: "Embolização definitiva não especificada acima - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813789",
                        original: "Embolização de tumor de cabeça e pescoço",
                        mod: "Embolização de tumor de cabeça e pescoço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813797",
                        original: "Embolização de tumor do aparelho digestivo",
                        mod: "Embolização de tumor do aparelho digestivo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813800",
                        original: "Embolização de tumor ósseo ou de partes moles",
                        mod: "Embolização de tumor ósseo ou de partes moles",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813819",
                        original: "Embolização de tumor não especificado",
                        mod: "Embolização de tumor não especificado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813827",
                        original: "Traqueotomia percutânea orientada por RX ou TC",
                        mod: "Traqueotomia percutânea orientada por RX ou TC",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813835",
                        original: "Gastrostomia percutânea orientada por RX ou TC",
                        mod: "Gastrostomia percutânea orientada por RX ou TC",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813843",
                        original: "Colecistostomia percutânea orientada por RX, US ou TC",
                        mod: "Colecistostomia percutânea orientada por RX, US ou TC",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813851",
                        original: "Esclerose percutânea de cisto pancreático",
                        mod: "Esclerose percutânea de cisto pancreático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813860",
                        original: "Celostomia percutânea orientada por RX ou TC",
                        mod: "Celostomia percutânea orientada por RX ou TC",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813878",
                        original: "Nefrostomia percutânea orientada por RX, US, TC ou RM",
                        mod: "Nefrostomia percutânea orientada por RX, US, TC ou RM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813886",
                        original: "Pielografia percutânea orientada por RX, US, TC ou RM",
                        mod: "Pielografia percutânea orientada por RX, US, TC ou RM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813894",
                        original: "Exérese percutânea de tumor benigno orientada por RX, US, TC ou RM",
                        mod: "Exérese percutânea de tumor benigno orientada por RX, US, TC ou RM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813908",
                        original: "Quimioterapia por cateter de tumor de cabeça e pescoço",
                        mod: "Quimioterapia por cateter de tumor de cabeça e pescoço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813916",
                        original: "Quimioembolização para tratamento de tumor hepático",
                        mod: "Quimioembolização para tratamento de tumor hepático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813924",
                        original: "Quimioterapia por cateter intra-arterial",
                        mod: "Quimioterapia por cateter intra-arterial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813932",
                        original: "TIPS - anastomose porto-cava percutânea para tratamento de hipertensão portal",
                        mod: "TIPS - anastomose porto-cava percutânea para tratamento de hipertensão portal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813940",
                        original: "Implante de endoprótese em aneurisma de aorta abdominal ou torácica com stent revestido (stent-graft)",
                        mod: "Implante de endoprótese em aneurisma de aorta abdominal ou torácica com stent revestido (stent-graft)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813959",
                        original: "Implante de endoprótese em dissecção de aorta abdominal ou torácica com stent revestido (stent-graft)",
                        mod: "Implante de endoprótese em dissecção de aorta abdominal ou torácica com stent revestido (stent-graft)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813967",
                        original: "Tratamento de pseudoaneurisma por compressão com US-Doppler",
                        mod: "Tratamento de pseudoaneurisma por compressão com US-Doppler",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813975",
                        original: "Tratamento do vasoespasmo pós-trauma",
                        mod: "Tratamento do vasoespasmo pós-trauma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813983",
                        original: "Trombectomia mecânica para tratamento de TEP",
                        mod: "Trombectomia mecânica para tratamento de TEP",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40813991",
                        original: "Trombectomia mecânica venosa",
                        mod: "Trombectomia mecânica venosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814017",
                        original: "Trombectomia medicamentosa para tratamento de TEP",
                        mod: "Trombectomia medicamentosa para tratamento de TEP",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814025",
                        original: "Trombólise medicamentosa arterial ou venosa - por vaso",
                        mod: "Trombólise medicamentosa arterial ou venosa - por vaso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814033",
                        original: "Trombólise medicamentosa arterial ou venosa para tratamento de isquemia mesentérica",
                        mod: "Trombólise medicamentosa arterial ou venosa para tratamento de isquemia mesentérica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814041",
                        original: "Trombólise medicamentosa em troncos supra-aórticos e intracranianos",
                        mod: "Trombólise medicamentosa em troncos supra-aórticos e intracranianos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814050",
                        original: "Repermeabilização tubária para tratamento de infertilidade",
                        mod: "Repermeabilização tubária para tratamento de infertilidade",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814068",
                        original: "Retirada percutânea de cálculos biliares orientada por RX, US ou TC",
                        mod: "Retirada percutânea de cálculos biliares orientada por RX, US ou TC",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814076",
                        original: "Retirada percutânea de cálculos renais orientada por RX, US ou TC",
                        mod: "Retirada percutânea de cálculos renais orientada por RX, US ou TC",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814084",
                        original: "Retirada percutânea de corpo estranho intravascular",
                        mod: "Retirada percutânea de corpo estranho intravascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814092",
                        original: "Osteoplastia ou discectomia percutânea (vertebroplastia e outras)",
                        mod: "Osteoplastia ou discectomia percutânea (vertebroplastia e outras)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814106",
                        original: "Discografia",
                        mod: "Discografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814114",
                        original: "Litotripsia mecânica de cálculos renais orientada por RX ou US",
                        mod: "Litotripsia mecânica de cálculos renais orientada por RX ou US",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814122",
                        original: "Trituração de calcificação tendínea orientada por RX ou US",
                        mod: "Trituração de calcificação tendínea orientada por RX ou US",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814130",
                        original: "Sinusografia (abscessografia)",
                        mod: "Sinusografia (abscessografia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814149",
                        original: "Paracentese orientada por RX ou US",
                        mod: "Paracentese orientada por RX ou US",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814157",
                        original: "Manipulação de drenos pós-drenagem (orientada por RX, TC, US ou RM)",
                        mod: "Manipulação de drenos pós-drenagem (orientada por RX, TC, US ou RM)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814165",
                        original: "Esclerose percutânea de nódulos benignos dirigida por RX, US, TC ou RM",
                        mod: "Esclerose percutânea de nódulos benignos dirigida por RX, US, TC ou RM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40814173",
                        original: "Escleroterapia de cistos (renais, hepáticos, ovarianos), orientada por imagem",
                        mod: "Escleroterapia de cistos (renais, hepáticos, ovarianos), orientada por imagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901017",
                        original: "US - Globo ocular - bilateral",
                        mod: "US - Globo ocular - bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901025",
                        original: "US - Globo ocular com Doppler colorido - bilateral",
                        mod: "US - Globo ocular com Doppler colorido - bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901033",
                        original: "US - Glândulas salivares (todas)",
                        mod: "US - Glândulas salivares (todas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901041",
                        original: "US - Torácico extracardíaco",
                        mod: "US - Torácico extracardíaco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901050",
                        original: "Ecodopplercardiograma com contraste intracavitário",
                        mod: "Ecodopplercardiograma com contraste intracavitário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901068",
                        original: "Ecodopplercardiograma com contraste para perfusão miocárdica - em repouso",
                        mod: "Ecodopplercardiograma com contraste para perfusão miocárdica - em repouso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901076",
                        original: "Ecodopplercardiograma com estresse farmacológico",
                        mod: "Ecodopplercardiograma com estresse farmacológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901084",
                        original: "Ecodopplercardiograma fetal com mapeamento de fluxo em cores - por feto",
                        mod: "Ecodopplercardiograma fetal com mapeamento de fluxo em cores - por feto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901092",
                        original: "Ecodopplercardiograma transesofágico (inclui transtorácico)",
                        mod: "Ecodopplercardiograma transesofágico (inclui transtorácico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901106",
                        original: "Ecodopplercardiograma transtorácico",
                        mod: "Ecodopplercardiograma transtorácico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901114",
                        original: "US - Mamas",
                        mod: "US - Mamas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901122",
                        original: "US - Abdome total (abdome superior, rins, bexiga, aorta, veia cava inferior e adrenais)",
                        mod: "US - Abdome total (abdome superior, rins, bexiga, aorta, veia cava inferior e adrenais)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901130",
                        original: "US - Abdome superior (fígado, vias biliares, vesícula, pâncreas e baço)",
                        mod: "US - Abdome superior (fígado, vias biliares, vesícula, pâncreas e baço)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901149",
                        original: "US - Retroperitônio (grandes vasos ou adrenais)",
                        mod: "US - Retroperitônio (grandes vasos ou adrenais)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901157",
                        original: "Aparelho urinário feminino (rins, ureteres e bexiga)",
                        mod: "Aparelho urinário feminino (rins, ureteres e bexiga)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901165",
                        original: "Aparelho urinário masculino (rins, ureteres, bexiga e próstata)",
                        mod: "Aparelho urinário masculino (rins, ureteres, bexiga e próstata)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901173",
                        original: "US - Abdome inferior masculino (bexiga, próstata e vesículas seminais)",
                        mod: "US - Abdome inferior masculino (bexiga, próstata e vesículas seminais)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901181",
                        original: "US - Abdome inferior feminino (bexiga, útero, ovário e anexos)",
                        mod: "US - Abdome inferior feminino (bexiga, útero, ovário e anexos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901190",
                        original: "US - Dermatológico - pele e subcutâneo",
                        mod: "US - Dermatológico - pele e subcutâneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901203",
                        original: "US - Órgãos superficiais (tireóide ou escroto ou pênis ou crânio)",
                        mod: "US - Órgãos superficiais (tireóide ou escroto ou pênis ou crânio)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901211",
                        original: "US - Estruturas superficiais (cervical ou axilas ou músculo ou tendão)",
                        mod: "US - Estruturas superficiais (cervical ou axilas ou músculo ou tendão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901220",
                        original: "US - Articular (por articulação)",
                        mod: "US - Articular (por articulação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901238",
                        original: "US - Obstétrica",
                        mod: "US - Obstétrica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901246",
                        original: "US - Obstétrica com Doppler colorido",
                        mod: "US - Obstétrica com Doppler colorido",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901254",
                        original: "US - Obstétrica com translucência nucal",
                        mod: "US - Obstétrica com translucência nucal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901262",
                        original: "US - Obstétrica morfológica",
                        mod: "US - Obstétrica morfológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901270",
                        original: "US - Obstétrica gestação múltipla: cada feto",
                        mod: "US - Obstétrica gestação múltipla: cada feto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901289",
                        original: "US - Obstétrica gestação múltipla com Doppler colorido: cada feto",
                        mod: "US - Obstétrica gestação múltipla com Doppler colorido: cada feto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901297",
                        original: "US - Obstétrica 1º trimestre (endovaginal)",
                        mod: "US - Obstétrica 1º trimestre (endovaginal)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901300",
                        original: "US - Transvaginal (útero, ovário, anexos e vagina)",
                        mod: "US - Transvaginal (útero, ovário, anexos e vagina)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901319",
                        original: "US - Transvaginal para controle de ovulação (3 ou mais exames)",
                        mod: "US - Transvaginal para controle de ovulação (3 ou mais exames)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901327",
                        original: "US - Histerossonografia",
                        mod: "US - Histerossonografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901335",
                        original: "US - Próstata transretal (não inclui abdome inferior masculino)",
                        mod: "US - Próstata transretal (não inclui abdome inferior masculino)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901351",
                        original: "Doppler colorido transfontanela",
                        mod: "Doppler colorido transfontanela",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901360",
                        original: "Doppler colorido de vasos cervicais arteriais bilateral (carótidas e vertebrais)",
                        mod: "Doppler colorido de vasos cervicais arteriais bilateral (carótidas e vertebrais)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901378",
                        original: "Doppler colorido de vasos cervicais venosos bilateral (subclávias e jugulares)",
                        mod: "Doppler colorido de vasos cervicais venosos bilateral (subclávias e jugulares)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901386",
                        original: "Doppler colorido de órgão ou estrutura isolada",
                        mod: "Doppler colorido de órgão ou estrutura isolada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901394",
                        original: "Doppler colorido de aorta e artérias renais",
                        mod: "Doppler colorido de aorta e artérias renais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901408",
                        original: "Doppler colorido de aorta e ilíacas",
                        mod: "Doppler colorido de aorta e ilíacas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901416",
                        original: "Doppler colorido de artérias viscerais (mesentéricas superior e inferior e tronco celíaco)",
                        mod: "Doppler colorido de artérias viscerais (mesentéricas superior e inferior e tronco celíaco)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901424",
                        original: "Doppler colorido de hemangioma",
                        mod: "Doppler colorido de hemangioma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901432",
                        original: "Doppler colorido de veia cava superior ou inferior",
                        mod: "Doppler colorido de veia cava superior ou inferior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901440",
                        original: "Doppler colorido peniano com fármaco-indução",
                        mod: "Doppler colorido peniano com fármaco-indução",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901459",
                        original: "Doppler colorido arterial de membro superior - unilateral",
                        mod: "Doppler colorido arterial de membro superior - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901467",
                        original: "Doppler colorido venoso de membro superior - unilateral",
                        mod: "Doppler colorido venoso de membro superior - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901475",
                        original: "Doppler colorido arterial de membro inferior - unilateral",
                        mod: "Doppler colorido arterial de membro inferior - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901483",
                        original: "Doppler colorido venoso de membro inferior - unilateral",
                        mod: "Doppler colorido venoso de membro inferior - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901491",
                        original: "US - Tridimensional - acrescentar ao exame de base",
                        mod: "US - Tridimensional - acrescentar ao exame de base",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901505",
                        original: "US - Obstétrica: perfil biofísico fetal",
                        mod: "US - Obstétrica: perfil biofísico fetal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901513",
                        original: "Doppler colorido de artérias penianas (sem fármaco indução)",
                        mod: "Doppler colorido de artérias penianas (sem fármaco indução)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901521",
                        original: "Ultrassonografia biomicroscópica - monocular",
                        mod: "Ultrassonografia biomicroscópica - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901530",
                        original: "Ultrassonografia diagnóstica - monocular",
                        mod: "Ultrassonografia diagnóstica - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901548",
                        original: "Doppler convencional órgão/estrutura isolada",
                        mod: "Doppler convencional órgão/estrutura isolada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901556",
                        original: "Ecocardiografia fetal gestação múltipla",
                        mod: "Ecocardiografia fetal gestação múltipla",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901564",
                        original: "Ecoencefalograma",
                        mod: "Ecoencefalograma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901572",
                        original: "Ultrassom com doppler abdôme total e pelve feminino",
                        mod: "Ultrassom com doppler abdôme total e pelve feminino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901580",
                        original: "US - Estudo sonográfico dinâmico das vias urinárias (masculino)",
                        mod: "US - Estudo sonográfico dinâmico das vias urinárias (masculino)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901599",
                        original: "US - Estudo sonográfico dinâmico das vias urinárias (feminino)",
                        mod: "US - Estudo sonográfico dinâmico das vias urinárias (feminino)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901602",
                        original: "Doppler transcraniano",
                        mod: "Doppler transcraniano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901610",
                        original: "US - Crânio para criança",
                        mod: "US - Crânio para criança",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901629",
                        original: "US - Ecodopplercardiograma com análise do sincronismo cardíaco",
                        mod: "US - Ecodopplercardiograma com análise do sincronismo cardíaco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901637",
                        original: "US - Ecocardiograma com Doppler convencional - artérias",
                        mod: "US - Ecocardiograma com Doppler convencional - artérias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901645",
                        original: "US - Ecocardiograma com Doppler convencional - carótidas",
                        mod: "US - Ecocardiograma com Doppler convencional - carótidas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901653",
                        original: "US - Ecocardiograma com Doppler tecidual para ressincronização",
                        mod: "US - Ecocardiograma com Doppler tecidual para ressincronização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901661",
                        original: "US - Ecodoppler de carótidas",
                        mod: "US - Ecodoppler de carótidas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901670",
                        original: "US - Prova de Boyden",
                        mod: "US - Prova de Boyden",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901688",
                        original: "US - Adicional de reconstrução 3D - ECO",
                        mod: "US - Adicional de reconstrução 3D - ECO",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901696",
                        original: "US - Ecodopplercardiograma com estresse físico",
                        mod: "US - Ecodopplercardiograma com estresse físico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901700",
                        original: "Ecodopplercardiograma sob estresse físico ou farmacológico com contraste",
                        mod: "Ecodopplercardiograma sob estresse físico ou farmacológico com contraste",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901718",
                        original: "Ecodopplercardiograma para ajuste de marca-passo",
                        mod: "Ecodopplercardiograma para ajuste de marca-passo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901726",
                        original: "US - Neurossonografia fetal",
                        mod: "US - Neurossonografia fetal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901734",
                        original: "US - Peça cirúgica",
                        mod: "US - Peça cirúgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901742",
                        original: "US - Transretal Radial",
                        mod: "US - Transretal Radial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901750",
                        original: "US - Próstata (via abdominal)",
                        mod: "US - Próstata (via abdominal)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901769",
                        original: "US - Aparelho urinário (rins, ureteres e bexiga)",
                        mod: "US - Aparelho urinário (rins, ureteres e bexiga)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40901793",
                        original: "Elastografia hepática ultrassônica",
                        mod: "Elastografia hepática ultrassônica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40902013",
                        original: "US - Obstétrica: com amniocentese",
                        mod: "US - Obstétrica: com amniocentese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40902021",
                        original: "US - Obstétrica 1º trimestre com punção: biópsia ou aspirativa",
                        mod: "US - Obstétrica 1º trimestre com punção: biópsia ou aspirativa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40902030",
                        original: "US - Próstata transretal com biópsia - até 8 fragmentos",
                        mod: "US - Próstata transretal com biópsia - até 8 fragmentos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40902048",
                        original: "US - Próstata transretal com biópsia - mais de 8 fragmentos",
                        mod: "US - Próstata transretal com biópsia - mais de 8 fragmentos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40902056",
                        original: "US - Intra-operatório",
                        mod: "US - Intra-operatório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40902064",
                        original: "Doppler colorido intra-operatório",
                        mod: "Doppler colorido intra-operatório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40902072",
                        original: "Ecodopplercardiograma transoperatório (transesofágico ou epicárdico) (1ª hora)",
                        mod: "Ecodopplercardiograma transoperatório (transesofágico ou epicárdico) (1ª hora)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40902080",
                        original: "Ecodopplercardiograma transoperatório (transesofágico ou epicárdico) - por hora suplementar",
                        mod: "Ecodopplercardiograma transoperatório (transesofágico ou epicárdico) - por hora suplementar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40902110",
                        original: "Drenagem percutânea orientada por US (acrescentar o exame de base)",
                        mod: "Drenagem percutânea orientada por US (acrescentar o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40902129",
                        original: "Redução de invaginação intestinal por enema, orientada por US (acrescentar o exame de base)",
                        mod: "Redução de invaginação intestinal por enema, orientada por US (acrescentar o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40902137",
                        original: "Monitorização por Doppler transcraniano",
                        mod: "Monitorização por Doppler transcraniano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "40902145",
                        original: "Ecodopplercardiograma intracardíaco",
                        mod: "Ecodopplercardiograma intracardíaco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001010",
                        original: "TC - Crânio ou sela túrcica ou órbitas",
                        mod: "Tomografia computadorizada de Crânio ou sela túrcica ou órbitas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001028",
                        original: "TC - Mastóides ou orelhas",
                        mod: "Tomografia computadorizada de Mastóides ou orelhas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001036",
                        original: "TC - Face ou seios da face",
                        mod: "Tomografia computadorizada de Face ou seios da face",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001044",
                        original: "TC - Articulações temporomandibulares",
                        mod: "Tomografia computadorizada de Articulações temporomandibulares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001052",
                        original: "TC - Dental (dentascan)",
                        mod: "Tomografia computadorizada de Dental (dentascan)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001060",
                        original: "TC - Pescoço (partes moles, laringe, tireóide, faringe e glândulas salivares)",
                        mod: "Tomografia computadorizada de Pescoço (partes moles, laringe, tireóide, faringe e glândulas salivares)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001079",
                        original: "TC - Tórax",
                        mod: "Tomografia computadorizada de Tórax",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001087",
                        original: "TC - Coração - para avaliação do escore de cálcio coronariano",
                        mod: "Tomografia computadorizada de Coração - para avaliação do escore de cálcio coronariano",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001095",
                        original: "TC - Abdome total (abdome superior, pelve e retroperitônio)",
                        mod: "Tomografia computadorizada de Abdome total (abdome superior, pelve e retroperitônio)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001109",
                        original: "TC - Abdome superior",
                        mod: "Tomografia computadorizada de Abdome superior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001117",
                        original: "TC - Pelve ou bacia",
                        mod: "Tomografia computadorizada de Pelve ou bacia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001125",
                        original: "TC - Coluna cervical ou dorsal ou lombo-sacra (até 3 segmentos)",
                        mod: "Tomografia computadorizada de Coluna cervical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001125",
                        original: "TC - Coluna cervical ou dorsal ou lombo-sacra (até 3 segmentos)",
                        mod: "Tomografia computadorizada de Coluna dorsal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001125",
                        original: "TC - Coluna cervical ou dorsal ou lombo-sacra (até 3 segmentos)",
                        mod: "Tomografia computadorizada de Coluna lombo-sacra",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001133",
                        original: "TC - Coluna - segmento adicional",
                        mod: "Tomografia computadorizada de Coluna - segmento adicional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001141",
                        original: "TC - Articulação (esternoclavicular ou ombro ou cotovelo ou punho ou sacroilíacas ou coxofemoral ou joelho ou tornozelo) - unilateral",
                        mod: "Tomografia computadorizada de Articulação (esternoclavicular ou ombro ou cotovelo ou punho ou sacroilíacas ou coxofemoral ou joelho ou tornozelo) - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001150",
                        original: "TC - Segmento apendicular (braço ou antebraço ou mão ou coxa ou perna ou pé) - unilateral",
                        mod: "Tomografia computadorizada de Segmento apendicular (braço ou antebraço ou mão ou coxa ou perna ou pé) - unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001168",
                        original: "Angiotomografia (crânio ou pescoço ou tórax ou abdome superior ou pelve) - arterial ou venosa",
                        mod: "Angiotomografia (crânio ou pescoço ou tórax ou abdome superior ou pelve) - arterial ou venosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001176",
                        original: "Angiotomografia de aorta torácica",
                        mod: "Angiotomografia de aorta torácica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001184",
                        original: "Angiotomografia de aorta abdominal",
                        mod: "Angiotomografia de aorta abdominal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001192",
                        original: "TC - Escanometria digital",
                        mod: "Tomografia computadorizada de Escanometria digital",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001206",
                        original: "TC - Reconstrução tridimensional de qualquer órgão ou estrutura - acrescentar ao exame de base",
                        mod: "Tomografia computadorizada de Reconstrução tridimensional de qualquer órgão ou estrutura - acrescentar ao exame de base",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001214",
                        original: "Endoscopia virtual de qualquer órgão ou estrutura por TC - acrescentar ao exame de base",
                        mod: "Endoscopia virtual de qualquer órgão ou estrutura por TC - acrescentar ao exame de base",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001222",
                        original: "TC para PET dedicado oncológico",
                        mod: "TC para PET dedicado oncológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001230",
                        original: "TC - Angiotomografia coronariana",
                        mod: "Tomografia computadorizada de Angiotomografia coronariana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001249",
                        original: "TC - Artérias de membro superior",
                        mod: "Tomografia computadorizada de Artérias de membro superior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001257",
                        original: "TC - Cisternografia por tomografia",
                        mod: "Tomografia computadorizada de Cisternografia por tomografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001265",
                        original: "TC - Colonoscopia virtual",
                        mod: "Tomografia computadorizada de Colonoscopia virtual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001273",
                        original: "TC - Mandíbula",
                        mod: "Tomografia computadorizada de Mandíbula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001281",
                        original: "TC - Maxilar",
                        mod: "Tomografia computadorizada de Maxilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001290",
                        original: "PET-CT cardiológico",
                        mod: "PET-CT cardiológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001303",
                        original: "PET-CT infecção",
                        mod: "PET-CT infecção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001311",
                        original: "PET-CT neurológico",
                        mod: "PET-CT neurológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001320",
                        original: "TC - Tomossíntese digital mamária",
                        mod: "Tomografia computadorizada de Tomossíntese digital mamária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001338",
                        original: "TC - Radiocirurgia esterotáxica",
                        mod: "Tomografia computadorizada de Radiocirurgia esterotáxica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001346",
                        original: "TC - Multifase (dinâmica)",
                        mod: "Tomografia computadorizada de Multifase (dinâmica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001354",
                        original: "TC - Articular dinâmica",
                        mod: "Tomografia computadorizada de Articular dinâmica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001362",
                        original: "TC - Vias urinárias (Urotomografia)",
                        mod: "Tomografia computadorizada de Vias urinárias (Urotomografia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001370",
                        original: "Angiotomografia arterial de crânio",
                        mod: "Angiotomografia arterial de crânio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001389",
                        original: "Angiotomografia venosa de crânio",
                        mod: "Angiotomografia venosa de crânio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001397",
                        original: "Angiotomografia arterial de pescoço",
                        mod: "Angiotomografia arterial de pescoço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001400",
                        original: "Angiotomografia venosa de pescoço",
                        mod: "Angiotomografia venosa de pescoço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001419",
                        original: "Angiotomografia arterial de tórax",
                        mod: "Angiotomografia arterial de tórax",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001427",
                        original: "Angiotomografia venosa de tórax",
                        mod: "Angiotomografia venosa de tórax",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001435",
                        original: "Angiotomografia arterial de abdome superior",
                        mod: "Angiotomografia arterial de abdome superior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001443",
                        original: "Angiotomografia venosa de abdome superior",
                        mod: "Angiotomografia venosa de abdome superior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001451",
                        original: "Angiotomografia arterial de pelve",
                        mod: "Angiotomografia arterial de pelve",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001460",
                        original: "Angiotomografia venosa de pelve",
                        mod: "Angiotomografia venosa de pelve",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001478",
                        original: "Angiotomografia arterial de membro inferior",
                        mod: "Angiotomografia arterial de membro inferior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001486",
                        original: "Angiotomografia venosa de membro inferior",
                        mod: "Angiotomografia venosa de membro inferior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001494",
                        original: "Angiotomografia arterial de membro superior",
                        mod: "Angiotomografia arterial de membro superior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001508",
                        original: "Angiotomografia venosa de membro superior",
                        mod: "Angiotomografia venosa de membro superior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001516",
                        original: "Angiotomografia arterial pulmonar",
                        mod: "Angiotomografia arterial pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001524",
                        original: "Angiotomografia venosa pulmonar",
                        mod: "Angiotomografia venosa pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41001532",
                        original: "TC para planejamento oncológico",
                        mod: "TC para planejamento oncológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41002016",
                        original: "Tomomielografia (até 3 segmentos) - acrescentar a TC da coluna e incluir a punção",
                        mod: "Tomomielografia (até 3 segmentos) - acrescentar a TC da coluna e incluir a punção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41002024",
                        original: "Punção aspirativa orientada por TC (acrescentar o exame de base)",
                        mod: "Punção aspirativa orientada por TC (acrescentar o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41002032",
                        original: "Drenagem percutânea orientada por TC (acrescentar o exame de base)",
                        mod: "Drenagem percutânea orientada por TC (acrescentar o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41002040",
                        original: "TC - Punção para introdução de contraste (acrescentar o exame de base)",
                        mod: "Tomografia computadorizada de Punção para introdução de contraste (acrescentar o exame de base)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41002059",
                        original: "Artro-TC",
                        mod: "Artro-TC",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101014",
                        original: "RM - Crânio (encéfalo)",
                        mod: "Ressonância magnética de Crânio (encéfalo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101022",
                        original: "RM - Sela túrcica (hipófise)",
                        mod: "Ressonância magnética de Sela túrcica (hipófise)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101030",
                        original: "RM - Base do crânio",
                        mod: "Ressonância magnética de Base do crânio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101049",
                        original: "Estudo funcional (mapeamento cortical por RM)",
                        mod: "Estudo funcional (mapeamento cortical por RM)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101057",
                        original: "Perfusão cerebral por RM",
                        mod: "Perfusão cerebral por RM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101065",
                        original: "Espectroscopia por RM",
                        mod: "Espectroscopia por RM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101073",
                        original: "RM - Órbita bilateral",
                        mod: "Ressonância magnética de Órbita bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101081",
                        original: "RM - Ossos temporais bilateral",
                        mod: "Ressonância magnética de Ossos temporais bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101090",
                        original: "RM - Face (inclui seios da face)",
                        mod: "Ressonância magnética de Face (inclui seios da face)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101103",
                        original: "RM - Articulação temporomandibular (bilateral)",
                        mod: "Ressonância magnética de Articulação temporomandibular (bilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101111",
                        original: "RM - Pescoço (nasofaringe, orofaringe, laringe, traquéia, tireóide, paratireóide)",
                        mod: "Ressonância magnética de Pescoço (nasofaringe, orofaringe, laringe, traquéia, tireóide, paratireóide)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101120",
                        original: "RM - Tórax (mediastino, pulmão, parede torácica)",
                        mod: "Ressonância magnética de Tórax (mediastino, pulmão, parede torácica)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101138",
                        original: "RM - Coração - morfológico e funcional",
                        mod: "Ressonância magnética de Coração - morfológico e funcional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101146",
                        original: "RM - Coração - morfológico e funcional + perfusão + estresse",
                        mod: "Ressonância magnética de Coração - morfológico e funcional + perfusão + estresse",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101154",
                        original: "RM - Coração - morfológico e funcional + perfusão + viabilidade miocárdica",
                        mod: "Ressonância magnética de Coração - morfológico e funcional + perfusão + viabilidade miocárdica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101162",
                        original: "Mama (unilateral)",
                        mod: "Mama (unilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101170",
                        original: "RM - Abdome superior (fígado, pâncreas, baço, rins, supra-renais, retroperitônio)",
                        mod: "Ressonância magnética de Abdome superior (fígado, pâncreas, baço, rins, supra-renais, retroperitônio)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101189",
                        original: "RM - Pelve (não inclui articulações coxofemorais)",
                        mod: "Ressonância magnética de Pelve (não inclui articulações coxofemorais)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101197",
                        original: "RM - Fetal",
                        mod: "Ressonância magnética Fetal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101200",
                        original: "RM - Pênis",
                        mod: "Ressonância magnética de Pênis",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101219",
                        original: "RM - Bolsa escrotal",
                        mod: "Ressonância magnética de Bolsa escrotal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101227",
                        original: "RM - Coluna cervical ou dorsal ou lombar",
                        mod: "Ressonância magnética de Coluna cervical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101227",
                        original: "RM - Coluna cervical ou dorsal ou lombar",
                        mod: "Ressonância magnética de Coluna dorsal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101227",
                        original: "RM - Coluna cervical ou dorsal ou lombar",
                        mod: "Ressonância magnética de Coluna lombar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101235",
                        original: "RM - Fluxo liquórico (como complementar)",
                        mod: "Ressonância magnética de Fluxo liquórico (como complementar)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101243",
                        original: "RM - Plexo braquial (desfiladeiro torácico) ou lombossacral (não inclui coluna cervical ou lombar)",
                        mod: "Ressonância magnética de Plexo braquial (desfiladeiro torácico) ou lombossacral (não inclui coluna cervical ou lombar)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101251",
                        original: "RM - Membro superior unilateral (não inclui mão e articulações)",
                        mod: "Ressonância magnética de Membro superior unilateral (não inclui mão e articulações)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101260",
                        original: "RM - Mão (não inclui punho)",
                        mod: "Ressonância magnética de Mão (não inclui punho)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101278",
                        original: "RM - Bacia (articulações sacroilíacas)",
                        mod: "Ressonância magnética de Bacia (articulações sacroilíacas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101286",
                        original: "RM - Coxa (unilateral)",
                        mod: "Ressonância magnética de Coxa (unilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101294",
                        original: "RM - Perna (unilateral)",
                        mod: "Ressonância magnética de Perna (unilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101308",
                        original: "RM - Pé (antepé) - não inclui tornozelo",
                        mod: "Ressonância magnética de Pé (antepé) - não inclui tornozelo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101316",
                        original: "RM - Articular (por articulação)",
                        mod: "Ressonância magnética Articular (por articulação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101324",
                        original: "Angio-RM (crânio ou pescoço ou tórax ou abdome superior ou pelve) - arterial ou venosa",
                        mod: "Angio-RM (crânio ou pescoço ou tórax ou abdome superior ou pelve) - arterial ou venosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101332",
                        original: "Angio-RM de aorta torácica",
                        mod: "Angio-Ressonância magnética de aorta torácica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101340",
                        original: "Angio-RM de aorta abdominal",
                        mod: "Angio-Ressonância magnética de aorta abdominal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101359",
                        original: "Hidro-RM (colângio-RM ou uro-RM ou mielo-RM ou sialo-RM ou cistografia por RM)",
                        mod: "Hidro-Ressonância magnética (colângio-RM ou uro-RM ou mielo-RM ou sialo-RM ou cistografia por RM)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101367",
                        original: "RM - Endocavitária (endorretal ou endovaginal)",
                        mod: "Ressonância magnética de Endocavitária (endorretal ou endovaginal)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101375",
                        original: "Endoscopia virtual por RM - acrescentar ao exame de base",
                        mod: "Endoscopia virtual por Ressonância magnética - acrescentar ao exame de base",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101383",
                        original: "RM - Reconstrução tridimensional - acrescentar ao exame de base",
                        mod: "Ressonância magnética de Reconstrução tridimensional - acrescentar ao exame de base",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101391",
                        original: "RM artérias membros inferiores",
                        mod: "Ressonância magnética artérias membros inferiores",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101430",
                        original: "RM - Avaliação de ferro (coração ou fígado)",
                        mod: "Ressonância magnética de Avaliação de ferro (coração ou fígado)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101448",
                        original: "RM - Tractografia",
                        mod: "Ressonância magnética de Tractografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101456",
                        original: "RM - Difusão por RM",
                        mod: "Ressonância magnética de Difusão por RM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101464",
                        original: "RM - Multiparamétrica transretal da próstata",
                        mod: "Ressonância magnética de Multiparamétrica transretal da próstata",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101472",
                        original: "RM - Planejamento",
                        mod: "Ressonância magnética de Planejamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101480",
                        original: "RM - Mama (bilateral)",
                        mod: "Ressonância magnética de Mama (bilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101499",
                        original: "Angio-RM arterial pulmonar",
                        mod: "Angio-Ressonância magnética arterial pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101502",
                        original: "Angio-RM venosa pulmonar",
                        mod: "Angio-Ressonância magnética venosa pulmonar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101510",
                        original: "Angio-RM arterial de abdome superior",
                        mod: "Angio-Ressonância magnética arterial de abdome superior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101529",
                        original: "Angio-RM venosa de abdome superior",
                        mod: "Angio-Ressonância magnética venosa de abdome superior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101537",
                        original: "Angio-RM arterial de crânio",
                        mod: "Angio-Ressonância magnética arterial de crânio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101545",
                        original: "Angio-RM venosa de crânio",
                        mod: "Angio-Ressonância magnética venosa de crânio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101553",
                        original: "Angio-RM arterial de membro inferior (unilateral)",
                        mod: "Angio-Ressonância magnética arterial de membro inferior (unilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101561",
                        original: "Angio-RM venosa de membro inferior (unilateral)",
                        mod: "Angio-Ressonância magnética venosa de membro inferior (unilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101570",
                        original: "Angio-RM arterial de membro superior (unilateral)",
                        mod: "Angio-Ressonância magnética arterial de membro superior (unilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101588",
                        original: "Angio-RM venosa de membro superior (unilateral)",
                        mod: "Angio-Ressonância magnética venosa de membro superior (unilateral)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101596",
                        original: "Angio-RM arterial de pelve",
                        mod: "Angio-Ressonância magnética arterial de pelve",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101600",
                        original: "Angio-RM venosa de pelve",
                        mod: "Angio-Ressonância magnética venosa de pelve",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101618",
                        original: "Angio-RM arterial de pescoço",
                        mod: "Angio-Ressonância magnética arterial de pescoço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101626",
                        original: "Angio-RM venosa de pescoço",
                        mod: "Angio-Ressonância magnética venosa de pescoço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101634",
                        original: "RM - Endorretal",
                        mod: "Ressonância magnética Endorretal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101642",
                        original: "RM - Endovaginal",
                        mod: "Ressonância magnética Endovaginal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101650",
                        original: "RM - Neuronavegador",
                        mod: "Ressonância magnética Neuronavegador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41101669",
                        original: "RM para planejamento oncológico",
                        mod: "Ressonância magnética para planejamento oncológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41102010",
                        original: "Artro-RM (incluir a punção articular) - por articulação",
                        mod: "Artro-Ressonância magnética (incluir a punção articular) - por articulação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203011",
                        original: "Betaterapia (placa de estrôncio) - por campo",
                        mod: "Betaterapia (placa de estrôncio) - por campo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203020",
                        original: "Radiocirurgia (RTC) - nível 1, lesão única e/ou um isocentro - por tratamento",
                        mod: "Radiocirurgia (RTC) - nível 1, lesão única e/ou um isocentro - por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203038",
                        original: "Radiocirurgia (RTC) - nível 2, duas lesões e/ou dois a quatro isocentros - por tratamento",
                        mod: "Radiocirurgia (RTC) - nível 2, duas lesões e/ou dois a quatro isocentros - por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203046",
                        original: "Radiocirurgia (RTC) - nível 3, três lesões e/ou de mais de quatro isocentros - por tratamento",
                        mod: "Radiocirurgia (RTC) - nível 3, três lesões e/ou de mais de quatro isocentros - por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203054",
                        original: "Radioterapia  com Modulação da Intensidade do Feixe (IMRT) - por tratamento",
                        mod: "Radioterapia  com Modulação da Intensidade do Feixe (IMRT) - por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203062",
                        original: "Radioterapia Conformada Tridimensional (RCT-3D)  com Acelerador Linear - por tratamento",
                        mod: "Radioterapia Conformada Tridimensional (RCT-3D)  com Acelerador Linear - por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203070",
                        original: "Radioterapia Convencional de Megavoltagem com Acelerador Linear com Fótons e Elétrons - por campo",
                        mod: "Radioterapia Convencional de Megavoltagem com Acelerador Linear com Fótons e Elétrons - por campo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203089",
                        original: "Radioterapia Convencional de Megavoltagem com Acelerador Linear só com Fótons - por campo",
                        mod: "Radioterapia Convencional de Megavoltagem com Acelerador Linear só com Fótons - por campo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203097",
                        original: "Radioterapia Convencional de Megavoltagem com Unidade de Telecobalto - por campo",
                        mod: "Radioterapia Convencional de Megavoltagem com Unidade de Telecobalto - por campo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203100",
                        original: "Radioterapia de Corpo Inteiro - por tratamento",
                        mod: "Radioterapia de Corpo Inteiro - por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203119",
                        original: "Radioterapia de Meio Corpo (HBI) - por dia de tratamento",
                        mod: "Radioterapia de Meio Corpo (HBI) - por dia de tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203127",
                        original: "Radioterapia de Pele Total (TSI) - por tratamento",
                        mod: "Radioterapia de Pele Total (TSI) - por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203135",
                        original: "Radioterapia Estereotática - 1º dia de tratamento",
                        mod: "Radioterapia Estereotática - 1º dia de tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203143",
                        original: "Radioterapia Estereotática - por dia subsequente",
                        mod: "Radioterapia Estereotática - por dia subsequente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203151",
                        original: "Radioterapia Externa de Ortovoltagem (Roentgenterapia) - por campo",
                        mod: "Radioterapia Externa de Ortovoltagem (Roentgenterapia) - por campo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203160",
                        original: "Radioterapia Intra-operatória (IORT) - por tratamento",
                        mod: "Radioterapia Intra-operatória (IORT) - por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203178",
                        original: "Radioterapia Rotatória com acelerador linear com fótons e elétrons - por volume tratado e por dia",
                        mod: "Radioterapia Rotatória com acelerador linear com fótons e elétrons - por volume tratado e por dia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203186",
                        original: "Radioterapia Rotatória com acelerador linear só com fótons - por volume tratado e por dia",
                        mod: "Radioterapia Rotatória com acelerador linear só com fótons - por volume tratado e por dia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203194",
                        original: "Radioterapia Rotatória com unidade de cobalto - por volume tratado e por dia",
                        mod: "Radioterapia Rotatória com unidade de cobalto - por volume tratado e por dia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41203208",
                        original: "Sangues e derivados (por unidade)",
                        mod: "Sangues e derivados (por unidade)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41204018",
                        original: "Colimação individual - 1 por incidência planejada",
                        mod: "Colimação individual - 1 por incidência planejada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41204026",
                        original: "Filme de verificação (cheque-filme) - 1 por incidência planejada/semana - filme a parte",
                        mod: "Filme de verificação (cheque-filme) - 1 por incidência planejada/semana - filme a parte",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41204034",
                        original: "Planejamento de tratamento computadorizado - 1 por volume tratado",
                        mod: "Planejamento de tratamento computadorizado - 1 por volume tratado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41204042",
                        original: "Planejamento de tratamento computadorizado tridimensional - 1 por volume tratado",
                        mod: "Planejamento de tratamento computadorizado tridimensional - 1 por volume tratado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41204050",
                        original: "Planejamento de tratamento simples (não computadorizado) - 1 por volume tratado",
                        mod: "Planejamento de tratamento simples (não computadorizado) - 1 por volume tratado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41204069",
                        original: "Simulação de tratamento complexa (com tomografia e com contraste) - 1 por volume tratado",
                        mod: "Simulação de tratamento complexa (com tomografia e com contraste) - 1 por volume tratado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41204077",
                        original: "Simulação de tratamento intermediária (com tomografia) - 1 por volume tratado",
                        mod: "Simulação de tratamento intermediária (com tomografia) - 1 por volume tratado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41204085",
                        original: "Simulação de tratamento simples (sem tomografia computadorizada) - 1 por volume tratado",
                        mod: "Simulação de tratamento simples (sem tomografia computadorizada) - 1 por volume tratado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41204093",
                        original: "Sistemas de imobilização - cabeça (máscaras) ou membros - 1 por tratamento",
                        mod: "Sistemas de imobilização - cabeça (máscaras) ou membros - 1 por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41204107",
                        original: "Sistemas de imobilização - tórax, abdome ou pélvis - 1 por tratamento",
                        mod: "Sistemas de imobilização - tórax, abdome ou pélvis - 1 por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41205014",
                        original: "Braquiterapia endoluminal de alta taxa de dose (BATD) - por inserção",
                        mod: "Braquiterapia endoluminal de alta taxa de dose (BATD) - por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41205022",
                        original: "Braquiterapia endoluminal de baixa taxa de dose (BBTD) - por inserção",
                        mod: "Braquiterapia endoluminal de baixa taxa de dose (BBTD) - por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41205030",
                        original: "Braquiterapia intersticial de alta taxa de dose (BATD) - por inserção",
                        mod: "Braquiterapia intersticial de alta taxa de dose (BATD) - por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41205049",
                        original: "Braquiterapia intersticial de baixa taxa de dose (BBTD) - com Césio - por inserção",
                        mod: "Braquiterapia intersticial de baixa taxa de dose (BBTD) - com Césio - por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41205057",
                        original: "Braquiterapia intersticial de baixa taxa de dose (BBTD) permanente de próstata - por tratamento",
                        mod: "Braquiterapia intersticial de baixa taxa de dose (BBTD) permanente de próstata - por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41205065",
                        original: "Braquiterapia intersticial de baixa taxa de dose (BBTD) com ouro, irídio ou iodo - por tratamento",
                        mod: "Braquiterapia intersticial de baixa taxa de dose (BBTD) com ouro, irídio ou iodo - por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41205073",
                        original: "Braquiterapia intracavitária de alta taxa de dose (BATD) - por inserção",
                        mod: "Braquiterapia intracavitária de alta taxa de dose (BATD) - por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41205081",
                        original: "Braquiterapia intracavitária de baixa taxa de dose (BBTD) com Césio - por inserção",
                        mod: "Braquiterapia intracavitária de baixa taxa de dose (BBTD) com Césio - por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41205090",
                        original: "Braquiterapia oftálmica de baixa taxa de dose (BBTD) - por inserção",
                        mod: "Braquiterapia oftálmica de baixa taxa de dose (BBTD) - por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41205103",
                        original: "Braquiterapia por moldagem ou contato de baixa taxa de dose (BBTD) com Césio - por inserção",
                        mod: "Braquiterapia por moldagem ou contato de baixa taxa de dose (BBTD) com Césio - por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41205111",
                        original: "Braquiterapia por moldagem ou contato de baixa taxa de dose (BBTD) com ouro, irídio ou iodo - por tratamento",
                        mod: "Braquiterapia por moldagem ou contato de baixa taxa de dose (BBTD) com ouro, irídio ou iodo - por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41205120",
                        original: "Braquiterapia por moldagem ou contato, de alta taxa de dose (BATD) - por inserção",
                        mod: "Braquiterapia por moldagem ou contato, de alta taxa de dose (BATD) - por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41206010",
                        original: "Filme de verificação (cheque-filme) de braquiterapia - 2 por inserção - filme à parte",
                        mod: "Filme de verificação (cheque-filme) de braquiterapia - 2 por inserção - filme à parte",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41206029",
                        original: "Colocação ou retirada da placa oftálmica - 1 colocação e 1 retirada por tratamento",
                        mod: "Colocação ou retirada da placa oftálmica - 1 colocação e 1 retirada por tratamento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41206037",
                        original: "Colocação ou retirada dos cateteres - 1 colocação e 1 retirada por inserção",
                        mod: "Colocação ou retirada dos cateteres - 1 colocação e 1 retirada por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41206045",
                        original: "Planejamento computadorizado de braquiterapia - 1 por inserção",
                        mod: "Planejamento computadorizado de braquiterapia - 1 por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41206053",
                        original: "Planejamento computadorizado tridimensional de braquiterapia - 1 por inserção",
                        mod: "Planejamento computadorizado tridimensional de braquiterapia - 1 por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41206061",
                        original: "Planejamento não-computadorizado de braquiterapia - 1 por inserção",
                        mod: "Planejamento não-computadorizado de braquiterapia - 1 por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41206070",
                        original: "Simulação de braquiterapia - 1 por inserção",
                        mod: "Simulação de braquiterapia - 1 por inserção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301013",
                        original: "Angiofluoresceinografia - monocular",
                        mod: "Angiofluoresceinografia - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301021",
                        original: "Angiografia com indocianina verde - monocular",
                        mod: "Angiografia com indocianina verde - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301030",
                        original: "Avaliação órbito-palpebral-exoftalmometria - binocular",
                        mod: "Avaliação órbito-palpebral-exoftalmometria - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301048",
                        original: "Bioimpedanciometria (ambulatorial) exame",
                        mod: "Bioimpedanciometria (ambulatorial) exame",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301056",
                        original: "Biópsia do vilo corial",
                        mod: "Biópsia do vilo corial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301064",
                        original: "Calorimetria indireta (ambulatorial) exame",
                        mod: "Calorimetria indireta (ambulatorial) exame",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301072",
                        original: "Campimetria manual - monocular",
                        mod: "Campimetria manual - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301080",
                        original: "Ceratoscopia computadorizada - monocular",
                        mod: "Ceratoscopia computadorizada - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301099",
                        original: "Coleta de material cérvico-vaginal",
                        mod: "Coleta de material cérvico-vaginal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301102",
                        original: "Colposcopia (cérvice uterina e vagina)",
                        mod: "Colposcopia (cérvice uterina e vagina)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301110",
                        original: "Cordocentese",
                        mod: "Cordocentese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301129",
                        original: "Curva tensional diária - binocular",
                        mod: "Curva tensional diária - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301137",
                        original: "Dermatoscopia (por lesão)",
                        mod: "Dermatoscopia (por lesão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301145",
                        original: "Ereção fármaco-induzida",
                        mod: "Ereção fármaco-induzida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301153",
                        original: "Estéreo-foto de papila - monocular",
                        mod: "Estéreo-foto de papila - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301161",
                        original: "Estesiometria (por membro)",
                        mod: "Estesiometria (por membro)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301170",
                        original: "Avaliação de vias lacrimais (Teste de Schirmer) - monocular",
                        mod: "Avaliação de vias lacrimais (Teste de Schirmer) - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301188",
                        original: "Exame a fresco do conteúdo vaginal e cervical",
                        mod: "Exame a fresco do conteúdo vaginal e cervical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301200",
                        original: "Exame de motilidade ocular (teste ortóptico) - binocular",
                        mod: "Exame de motilidade ocular (teste ortóptico) - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301218",
                        original: "Exame micológico - cultura e identificação de colônia",
                        mod: "Exame micológico - cultura e identificação de colônia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301226",
                        original: "Exame micológico direto (por local)",
                        mod: "Exame micológico direto (por local)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301234",
                        original: "Fotodermatoscopia (por lesão)",
                        mod: "Fotodermatoscopia (por lesão)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301242",
                        original: "Gonioscopia - binocular",
                        mod: "Gonioscopia - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301250",
                        original: "Mapeamento de retina (oftalmoscopia indireta) - monocular",
                        mod: "Mapeamento de retina (oftalmoscopia indireta) - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301269",
                        original: "Microscopia especular de córnea - monocular",
                        mod: "Microscopia especular de córnea - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301277",
                        original: "Oftalmodinamometria - monocular",
                        mod: "Oftalmodinamometria - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301285",
                        original: "Peniscopia (inclui bolsa escrotal)",
                        mod: "Peniscopia (inclui bolsa escrotal)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301307",
                        original: "Potencial de acuidade visual - monocular",
                        mod: "Potencial de acuidade visual - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301315",
                        original: "Retinografia (só honorário) monocular",
                        mod: "Retinografia (só honorário) monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301323",
                        original: "Tonometria - binocular",
                        mod: "Tonometria - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301331",
                        original: "Tricograma",
                        mod: "Tricograma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301340",
                        original: "Urodinâmica completa",
                        mod: "Urodinâmica completa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301358",
                        original: "Urofluxometria",
                        mod: "Urofluxometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301366",
                        original: "Visão subnormal - monocular",
                        mod: "Visão subnormal - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301374",
                        original: "Vulvoscopia (vulva e períneo)",
                        mod: "Vulvoscopia (vulva e períneo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301382",
                        original: "Capilaroscopia periungueal",
                        mod: "Capilaroscopia periungueal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301390",
                        original: "Coleta de raspado dérmico em lesões e sítios específicos para baciloscopia (por sítio)",
                        mod: "Coleta de raspado dérmico em lesões e sítios específicos para baciloscopia (por sítio)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301404",
                        original: "Avaliação da função muscular por movimento manual (por membro)",
                        mod: "Avaliação da função muscular por movimento manual (por membro)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301412",
                        original: "Calorimetria direta",
                        mod: "Calorimetria direta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301420",
                        original: "Biomicroscopia de fundo",
                        mod: "Biomicroscopia de fundo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301439",
                        original: "Fundoscopia sob medríases - binocular",
                        mod: "Fundoscopia sob medríases - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301447",
                        original: "Glare (BAT) - binocular",
                        mod: "Glare (BAT) - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301455",
                        original: "Olfatometria",
                        mod: "Olfatometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301463",
                        original: "Triagem auditiva neonatal/infantil",
                        mod: "Triagem auditiva neonatal/infantil",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301471",
                        original: "Teste do reflexo vermelho em recém nato (teste do olhinho)",
                        mod: "Teste do reflexo vermelho em recém nato (teste do olhinho)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301480",
                        original: "Ceratoscopia computadorizada da córnea por orbscan, olho",
                        mod: "Ceratoscopia computadorizada da córnea por orbscan, olho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301498",
                        original: "Exame neuro oftalmlógico",
                        mod: "Exame neuro oftalmlógico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301510",
                        original: "Topografia do disco óptico - HRT - binocular",
                        mod: "Topografia do disco óptico - HRT - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301528",
                        original: "Cauterização de alta frequência em sistema genital e reprodutor feminino",
                        mod: "Cauterização de alta frequência em sistema genital e reprodutor feminino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301536",
                        original: "Colposcopia anal",
                        mod: "Colposcopia anal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301544",
                        original: "Colposcopia por vídeo",
                        mod: "Colposcopia por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301549",
                        original: "Campimetria blue-yellow (perimetria azul amarelo)",
                        mod: "Campimetria blue-yellow (perimetria azul amarelo)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41301552",
                        original: "Vulvoscopia por vídeo",
                        mod: "Vulvoscopia por vídeo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401018",
                        original: "Avaliação da função muscular (por movimento) com equipamento informatizado (isocinético)",
                        mod: "Avaliação da função muscular (por movimento) com equipamento informatizado (isocinético)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401026",
                        original: "Avaliação da função muscular (por movimento) com equipamento mecânico (dinamometria/módulos de cargas)",
                        mod: "Avaliação da função muscular (por movimento) com equipamento mecânico (dinamometria/módulos de cargas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401042",
                        original: "Prova de auto-rotação cefálica",
                        mod: "Prova de auto-rotação cefálica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401050",
                        original: "Prova de Lombard",
                        mod: "Prova de Lombard",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401069",
                        original: "Provas imuno-alérgicas para bactérias (por antígeno)",
                        mod: "Provas imuno-alérgicas para bactérias (por antígeno)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401077",
                        original: "Provas imuno-alérgicas para fungos (por antígeno)",
                        mod: "Provas imuno-alérgicas para fungos (por antígeno)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401085",
                        original: "Teste da histamina (duas áreas testadas)",
                        mod: "Teste da histamina (duas áreas testadas)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401093",
                        original: "Teste de adaptação patológica (tone decay test)",
                        mod: "Teste de adaptação patológica (tone decay test)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401107",
                        original: "Teste de broncoprovocação",
                        mod: "Teste de broncoprovocação",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401115",
                        original: "Teste de caminhada de 6 minutos",
                        mod: "Teste de caminhada de 6 minutos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401123",
                        original: "Teste de desempenho anaeróbico em laboratório (T. de Wingate)",
                        mod: "Teste de desempenho anaeróbico em laboratório (T. de Wingate)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401131",
                        original: "Teste de equilíbrio peritoneal (PET)",
                        mod: "Teste de equilíbrio peritoneal (PET)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401140",
                        original: "Teste de exercício dos 4 segundos",
                        mod: "Teste de exercício dos 4 segundos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401158",
                        original: "Teste de exercício em ergômetro   com  determinação  do lactato sanguíneo",
                        mod: "Teste de exercício em ergômetro   com  determinação  do lactato sanguíneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401166",
                        original: "Teste de exercício em ergômetro com   realização  de gasometria arterial",
                        mod: "Teste de exercício em ergômetro com   realização  de gasometria arterial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401174",
                        original: "Teste de exercício em ergômetro com  monitorização  da frequência cardíaca",
                        mod: "Teste de exercício em ergômetro com  monitorização  da frequência cardíaca",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401182",
                        original: "Teste de exercício em ergômetro com  monitorização  do eletrocardiograma",
                        mod: "Teste de exercício em ergômetro com  monitorização  do eletrocardiograma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401190",
                        original: "Teste de exercício em ergômetro com medida de gases expirados (teste cardiopulmonar de exercício) com qualquer ergômetro",
                        mod: "Teste de exercício em ergômetro com medida de gases expirados (teste cardiopulmonar de exercício) com qualquer ergômetro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401204",
                        original: "Teste de exercício em ergômetro com medida de gases expirados e eletrocardiograma",
                        mod: "Teste de exercício em ergômetro com medida de gases expirados e eletrocardiograma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401212",
                        original: "Teste de glicerol (com audiometria tonal limiar pré e pós)",
                        mod: "Teste de glicerol (com audiometria tonal limiar pré e pós)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401220",
                        original: "Teste de glicerol (com eletrococleografia pré e pós)",
                        mod: "Teste de glicerol (com eletrococleografia pré e pós)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401239",
                        original: "Teste de Hilger para paralisia facial",
                        mod: "Teste de Hilger para paralisia facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401247",
                        original: "Teste de Huhner",
                        mod: "Teste de Huhner",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401255",
                        original: "Teste de Mitsuda",
                        mod: "Teste de Mitsuda",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401263",
                        original: "Teste de prótese auditiva",
                        mod: "Teste de prótese auditiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401271",
                        original: "Teste de sensibilidade de contraste ou de cores - monocular",
                        mod: "Teste de sensibilidade de contraste ou de cores - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401280",
                        original: "Teste de SISI",
                        mod: "Teste de SISI",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401298",
                        original: "Teste para broncoespasmo de exercício",
                        mod: "Teste para broncoespasmo de exercício",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401301",
                        original: "Teste provocativo para glaucoma - binocular",
                        mod: "Teste provocativo para glaucoma - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401310",
                        original: "Testes aeróbicos em campo com determinação do lactato sanguíneo",
                        mod: "Testes aeróbicos em campo com determinação do lactato sanguíneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401328",
                        original: "Testes aeróbicos em campo com medida de gases expirados",
                        mod: "Testes aeróbicos em campo com medida de gases expirados",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401336",
                        original: "Testes aeróbicos em campo com telemetria da frequência cardíaca",
                        mod: "Testes aeróbicos em campo com telemetria da frequência cardíaca",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401344",
                        original: "Testes anaeróbicos em campo com determinação do lactato sanguíneo",
                        mod: "Testes anaeróbicos em campo com determinação do lactato sanguíneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401352",
                        original: "Testes anaeróbicos em campo sem determinação do lactato sanguíneo",
                        mod: "Testes anaeróbicos em campo sem determinação do lactato sanguíneo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401360",
                        original: "Testes cutâneo-alérgicos para alérgenos da poeira",
                        mod: "Testes cutâneo-alérgicos para alérgenos da poeira",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401379",
                        original: "Testes cutâneo-alérgicos para alimentos",
                        mod: "Testes cutâneo-alérgicos para alimentos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401387",
                        original: "Testes cutâneo-alérgicos para fungos",
                        mod: "Testes cutâneo-alérgicos para fungos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401395",
                        original: "Testes cutâneo-alérgicos para insetos hematófagos",
                        mod: "Testes cutâneo-alérgicos para insetos hematófagos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401409",
                        original: "Testes cutâneo-alérgicos para pólens",
                        mod: "Testes cutâneo-alérgicos para pólens",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401417",
                        original: "Testes de aptidão em laboratório (agilidade, equilíbrio, tempo de reação e coordenação)",
                        mod: "Testes de aptidão em laboratório (agilidade, equilíbrio, tempo de reação e coordenação)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401425",
                        original: "Testes de contato - até 30 substâncias",
                        mod: "Testes de contato - até 30 substâncias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401433",
                        original: "Testes de contato - por substância, acima de 30",
                        mod: "Testes de contato - por substância, acima de 30",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401441",
                        original: "Testes de contato por fotossensibilização - até 30 substâncias",
                        mod: "Testes de contato por fotossensibilização - até 30 substâncias",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401450",
                        original: "Testes de contato por fotossensibilização - por substância, acima de 30",
                        mod: "Testes de contato por fotossensibilização - por substância, acima de 30",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401468",
                        original: "Testes do desenvolvimento (escala de Denver e outras)",
                        mod: "Testes do desenvolvimento (escala de Denver e outras)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401476",
                        original: "Testes vestibulares, com prova calórica, com eletronistagmografia",
                        mod: "Testes vestibulares, com prova calórica, com eletronistagmografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401484",
                        original: "Testes vestibulares, com prova calórica, sem eletronistagmografia",
                        mod: "Testes vestibulares, com prova calórica, sem eletronistagmografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401492",
                        original: "Testes vestibulares, com vecto-eletronistagmografia",
                        mod: "Testes vestibulares, com vecto-eletronistagmografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401514",
                        original: "Oximetria não invasiva",
                        mod: "Oximetria não invasiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401522",
                        original: "Teste cutâneo-alérgicos para látex",
                        mod: "Teste cutâneo-alérgicos para látex",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401530",
                        original: "Teste cutâneo-alérgicos Epitelis de Animais",
                        mod: "Teste cutâneo-alérgicos Epitelis de Animais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401549",
                        original: "Teste de monitorização contínua da glicose (TMCG)",
                        mod: "Teste de monitorização contínua da glicose (TMCG)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401557",
                        original: "Repertorização",
                        mod: "Repertorização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401565",
                        original: "Teste de avaliação geriátrica global",
                        mod: "Teste de avaliação geriátrica global",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401573",
                        original: "Estudo cito-alergológico (ECA)",
                        mod: "Estudo cito-alergológico (ECA)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401581",
                        original: "Teste de Heald",
                        mod: "Teste de Heald",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401590",
                        original: "Teste de imagens",
                        mod: "Teste de imagens",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401603",
                        original: "Teste de Teller - binocular",
                        mod: "Teste de Teller - binocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401611",
                        original: "Teste de Wepmann",
                        mod: "Teste de Wepmann",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401620",
                        original: "Teste sensibilidade ao sal",
                        mod: "Teste sensibilidade ao sal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401638",
                        original: "Teste suplementar de sensibilidade",
                        mod: "Teste suplementar de sensibilidade",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401646",
                        original: "Testes cutâneos de contato (patch tests)",
                        mod: "Testes cutâneos de contato (patch tests)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401654",
                        original: "Teste de fluxo salivar",
                        mod: "Teste de fluxo salivar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401662",
                        original: "Teste de estimulação muscúlo-esquelética 'in vitro' (mínimo seis)",
                        mod: "Teste de estimulação muscúlo-esquelética 'in vitro' (mínimo seis)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401670",
                        original: "Teste de fibronectina fetal - indicador bioquímico para parto prematuro",
                        mod: "Teste de fibronectina fetal - indicador bioquímico para parto prematuro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401689",
                        original: "Metabolismo de repouso",
                        mod: "Metabolismo de repouso",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401697",
                        original: "Estudo da halitose",
                        mod: "Estudo da halitose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401700",
                        original: "Teste de Wada",
                        mod: "Teste de Wada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401719",
                        original: "Teste rápido para detecção da PAMG-1 para diagnóstico de ruptura de membranas fetais",
                        mod: "Teste rápido para detecção da PAMG-1 para diagnóstico de ruptura de membranas fetais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401751",
                        original: "Teste de contato bateria cosméticos",
                        mod: "Teste de contato bateria cosméticos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401760",
                        original: "Teste de contato bateria regional",
                        mod: "Teste de contato bateria regional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401778",
                        original: "Teste de contato bateria capilar",
                        mod: "Teste de contato bateria capilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401786",
                        original: "Teste de contato bateria unhas",
                        mod: "Teste de contato bateria unhas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401794",
                        original: "Teste de contato bateria medicamentos/corticoides",
                        mod: "Teste de contato bateria medicamentos/corticoides",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41401808",
                        original: "Teste de contato bateria agentes ocupacionais",
                        mod: "Teste de contato bateria agentes ocupacionais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501012",
                        original: "Biometria ultrassônica - monocular",
                        mod: "Biometria ultrassônica - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501020",
                        original: "Cavernosometria",
                        mod: "Cavernosometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501047",
                        original: "Dopplermetria dos cordões espermáticos",
                        mod: "Dopplermetria dos cordões espermáticos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501063",
                        original: "Investigação ultrassônica com registro gráfico (qualquer área)",
                        mod: "Investigação ultrassônica com registro gráfico (qualquer área)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501071",
                        original: "Investigação ultrassônica com teste de stress e com registro gráfico",
                        mod: "Investigação ultrassônica com teste de stress e com registro gráfico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501080",
                        original: "Investigação ultrassônica com teste de stress e sem registro gráfico",
                        mod: "Investigação ultrassônica com teste de stress e sem registro gráfico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501098",
                        original: "Investigação ultrassônica com teste de stress em esteira e com registro gráfico",
                        mod: "Investigação ultrassônica com teste de stress em esteira e com registro gráfico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501101",
                        original: "Investigação ultrassônica sem registro gráfico (qualquer área)",
                        mod: "Investigação ultrassônica sem registro gráfico (qualquer área)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501110",
                        original: "Medida de índice de artelhos com registro gráfico",
                        mod: "Medida de índice de artelhos com registro gráfico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501128",
                        original: "Paquimetria ultrassônica - monocular",
                        mod: "Paquimetria ultrassônica - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501136",
                        original: "Termometria cutânea (por lateralidade: pescoço, membros, bolsa escrotal, por território peniano)",
                        mod: "Termometria cutânea (por lateralidade: pescoço, membros, bolsa escrotal, por território peniano)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501144",
                        original: "Tomografia de coerência óptica - monocular",
                        mod: "Tomografia de coerência óptica - monocular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501179",
                        original: "Fotopletismografia (venosa ou arterial) por lateralidade ou segmento",
                        mod: "Fotopletismografia (venosa ou arterial) por lateralidade ou segmento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501187",
                        original: "Medida de pressão segmentar (nos quatro segmentos)",
                        mod: "Medida de pressão segmentar (nos quatro segmentos)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501195",
                        original: "Pletismografia (qualquer tipo) por lateralidade ou território",
                        mod: "Pletismografia (qualquer tipo) por lateralidade ou território",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501209",
                        original: "Medida de pressão hepática",
                        mod: "Medida de pressão hepática",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501217",
                        original: "Injeção intracavernosa",
                        mod: "Injeção intracavernosa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501225",
                        original: "Oximetria arterial, perfil",
                        mod: "Oximetria arterial, perfil",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501233",
                        original: "Oximetria venosa, perfil",
                        mod: "Oximetria venosa, perfil",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501241",
                        original: "Perfil de pressão uretral",
                        mod: "Perfil de pressão uretral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501250",
                        original: "Pressão intra abdominal urológica",
                        mod: "Pressão intra abdominal urológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "41501268",
                        original: "Pressão arterial peniana",
                        mod: "Pressão arterial peniana",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000012",
                        original: "Sessão de psicomotricidade individual",
                        mod: "Sessão de psicomotricidade individual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000020",
                        original: "Sessão de psicomotricidade em grupo",
                        mod: "Sessão de psicomotricidade em grupo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000039",
                        original: "Sessão de ludoterapia individual",
                        mod: "Sessão de ludoterapia individual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000047",
                        original: "Sessão de ludoterapia em grupo",
                        mod: "Sessão de ludoterapia em grupo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000055",
                        original: "Consulta individual ambulatorial, em terapia ocupacional",
                        mod: "Consulta individual ambulatorial, em terapia ocupacional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000063",
                        original: "Consulta individual domiciliar, em terapia ocupacional",
                        mod: "Consulta individual domiciliar, em terapia ocupacional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000071",
                        original: "Consulta individual hospitalar, em terapia ocupacional",
                        mod: "Consulta individual hospitalar, em terapia ocupacional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000080",
                        original: "Sessão individual ambulatorial, em terapia ocupacional",
                        mod: "Sessão individual ambulatorial, em terapia ocupacional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000098",
                        original: "Sessão individual domiciliar, em terapia ocupacional",
                        mod: "Sessão individual domiciliar, em terapia ocupacional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000101",
                        original: "Sessão individual hospitalar, em terapia ocupacional",
                        mod: "Sessão individual hospitalar, em terapia ocupacional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000110",
                        original: "Sessão de terapia ocupacional familiar",
                        mod: "Sessão de terapia ocupacional familiar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000128",
                        original: "Sessão de terapia ocupacional em grupo",
                        mod: "Sessão de terapia ocupacional em grupo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000136",
                        original: "Sessão de terapia ocupacional para treinamento órteses, próteses e adaptações",
                        mod: "Sessão de terapia ocupacional para treinamento órteses, próteses e adaptações",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000144",
                        original: "Consulta ambulatorial em fisioterapia",
                        mod: "Consulta ambulatorial em fisioterapia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000152",
                        original: "Sessão para assistência fisioterapêutica ambulatorial ao paciente com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        mod: "Sessão para assistência fisioterapêutica ambulatorial ao paciente com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000160",
                        original: "Sessão para assistência fisioterapêutica ambulatorial ao paciente com disfunção decorrente de alterações do sistema músculo-esquelético",
                        mod: "Fisioterapia motora",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000179",
                        original: "Sessão para assistência fisioterapêutica ambulatorial ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        mod: "Fisioterapia respiratória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000187",
                        original: "Sessão para assistência fisioterapêutica ambulatorial ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        mod: "Sessão para assistência fisioterapêutica ambulatorial ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000195",
                        original: "Sessão para assistência fisioterapêutica ambulatorial ao paciente com disfunção decorrente de queimaduras",
                        mod: "Sessão para assistência fisioterapêutica ambulatorial ao paciente com disfunção decorrente de queimaduras",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000195",
                        original: "Atendimento fisioterapêutico ambulatorial ao paciente com disfunção decorrente de queimaduras",
                        mod: "Atendimento fisioterapêutico ambulatorial ao paciente com disfunção decorrente de queimaduras",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000209",
                        original: "Sessão para assistência fisioterapêutica ambulatorial ao paciente com disfunção decorrente de alterações do sistema linfático e/ou vascular periférico",
                        mod: "Sessão para assistência fisioterapêutica ambulatorial ao paciente com disfunção decorrente de alterações do sistema linfático e/ou vascular periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000209",
                        original: "Atendimento fisioterapêutico ambulatorial ao paciente com disfunção decorrente de alterações do sistema linfático e/ou vascular periférico",
                        mod: "Atendimento fisioterapêutico ambulatorial ao paciente com disfunção decorrente de alterações do sistema linfático e/ou vascular periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000217",
                        original: "Sessão para assistência fisioterapêutica ambulatorial no pré e pós cirúrgico e em recuperação de tecidos",
                        mod: "Sessão para assistência fisioterapêutica ambulatorial no pré e pós cirúrgico e em recuperação de tecidos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000217",
                        original: "Atendimento fisioterapêutico ambulatorial no pré e pós cirúrgico e em recuperação de tecidos",
                        mod: "Atendimento fisioterapêutico ambulatorial no pré e pós cirúrgico e em recuperação de tecidos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000225",
                        original: "Sessão para assistência fisioterapêutica ambulatorial por alterações endocrino-metabólicas",
                        mod: "Sessão para assistência fisioterapêutica ambulatorial por alterações endocrino-metabólicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000233",
                        original: "Sessão para assistência fisioterapêutica ambulatorial para alterações inflamatórias e ou degenerativas do aparelho genito-urinário e reprodutor",
                        mod: "Sessão para assistência fisioterapêutica ambulatorial para alterações inflamatórias e ou degenerativas do aparelho genito-urinário e reprodutor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000233",
                        original: "Atendimento fisioterapêutico ambulatorial para alterações inflamatórias e ou degenerativas do aparelho genito-urinário e reprodutor, e/ou proctológico",
                        mod: "Atendimento fisioterapêutico ambulatorial para alterações inflamatórias e ou degenerativas do aparelho genito-urinário e reprodutor, e/ou proctológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000241",
                        original: "Consulta domiciliar em fisioterapia",
                        mod: "Consulta domiciliar em fisioterapia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000250",
                        original: "Sessão para assistência fisioterapêutica domiciliar ao paciente com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        mod: "Sessão para assistência fisioterapêutica domiciliar ao paciente com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000250",
                        original: "Atendimento fisioterapêutico domiciliar ao paciente com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        mod: "Atendimento fisioterapêutico domiciliar ao paciente com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000268",
                        original: "Sessão para assistência fisioterapêutica domiciliar ao paciente com disfunção decorrente de alterações do sistema músculo-esquelético",
                        mod: "Sessão para assistência fisioterapêutica domiciliar ao paciente com disfunção decorrente de alterações do sistema músculo-esquelético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000268",
                        original: "Atendimento fisioterapêutico domiciliar ao paciente com disfunção decorrente de alterações do sistema músculo-esquelético",
                        mod: "Atendimento fisioterapêutico domiciliar ao paciente com disfunção decorrente de alterações do sistema músculo-esquelético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000276",
                        original: "Sessão para assistência fisioterapêutica domiciliar ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        mod: "Sessão para assistência fisioterapêutica domiciliar ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000276",
                        original: "Atendimento fisioterapêutico domiciliar ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        mod: "Atendimento fisioterapêutico domiciliar ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000284",
                        original: "Sessão para assistência fisioterapêutica domiciliar ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        mod: "Sessão para assistência fisioterapêutica domiciliar ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000284",
                        original: "Atendimento fisioterapêutico domiciliar ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        mod: "Atendimento fisioterapêutico domiciliar ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000292",
                        original: "Sessão para assistência fisioterapêutica domiciliar ao paciente com disfunção decorrente de queimaduras",
                        mod: "Sessão para assistência fisioterapêutica domiciliar ao paciente com disfunção decorrente de queimaduras",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000292",
                        original: "Atendimento fisioterapêutico domiciliar ao paciente com disfunção decorrente de queimaduras",
                        mod: "Atendimento fisioterapêutico domiciliar ao paciente com disfunção decorrente de queimaduras",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000306",
                        original: "Sessão para assistência fisioterapêutica domiciliar ao paciente com disfunção decorrente de alterações do sistema linfático e/ou vascular periférico",
                        mod: "Sessão para assistência fisioterapêutica domiciliar ao paciente com disfunção decorrente de alterações do sistema linfático e/ou vascular periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000306",
                        original: "Atendimento fisioterapêutico domiciliar ao paciente com disfunção decorrente de alterações do sistema linfático e/ou vascular periférico",
                        mod: "Atendimento fisioterapêutico domiciliar ao paciente com disfunção decorrente de alterações do sistema linfático e/ou vascular periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000314",
                        original: "Sessão para assistência fisioterapêutica domiciliar no pré e pós cirúrgico e em recuperação de tecidos",
                        mod: "Sessão para assistência fisioterapêutica domiciliar no pré e pós cirúrgico e em recuperação de tecidos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000314",
                        original: "Atendimento fisioterapêutico domiciliar no pré e pós cirúrgico e em recuperação de tecidos",
                        mod: "Atendimento fisioterapêutico domiciliar no pré e pós cirúrgico e em recuperação de tecidos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000322",
                        original: "Sessão para assistência fisioterapêutica domiciliar por alterações endocrino-metabólicas",
                        mod: "Sessão para assistência fisioterapêutica domiciliar por alterações endocrino-metabólicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000322",
                        original: "Atendimento fisioterapêutico domiciliar por alterações endocrino-metabólicas",
                        mod: "Atendimento fisioterapêutico domiciliar por alterações endocrino-metabólicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000330",
                        original: "Sessão para assistência fisioterapêutica domiciliar para alterações inflamatórias e ou degenerativas do aparelho genito-urinário e reprodutor",
                        mod: "Sessão para assistência fisioterapêutica domiciliar para alterações inflamatórias e ou degenerativas do aparelho genito-urinário e reprodutor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000330",
                        original: "Atendimento fisioterapêutico domiciliar para alterações inflamatórias e ou degenerativas do aparelho genito-urinário, reprodutor e/ou proctológico",
                        mod: "Atendimento fisioterapêutico domiciliar para alterações inflamatórias e ou degenerativas do aparelho genito-urinário, reprodutor e/ou proctológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000349",
                        original: "Consulta hospitalar em fisioterapia",
                        mod: "Consulta hospitalar em fisioterapia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000357",
                        original: "Sessão para assistência fisioterapêutica hospitalar ao paciente com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        mod: "Sessão para assistência fisioterapêutica hospitalar ao paciente com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000365",
                        original: "Sessão para assistência fisioterapêutica hospitalar ao paciente com disfunção decorrente de alterações do sistema músculo-esquelético",
                        mod: "Sessão para assistência fisioterapêutica hospitalar ao paciente com disfunção decorrente de alterações do sistema músculo-esquelético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000365",
                        original: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações do sistema músculo-esquelético",
                        mod: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações do sistema músculo-esquelético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000373",
                        original: "Sessão para assistência fisioterapêutica hospitalar ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        mod: "Sessão para assistência fisioterapêutica hospitalar ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000381",
                        original: "Sessão para assistência fisioterapêutica hospitalar ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        mod: "Sessão para assistência fisioterapêutica hospitalar ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000381",
                        original: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        mod: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000390",
                        original: "Sessão para assistência fisioterapêutica hospitalar ao paciente com disfunção decorrente de queimaduras",
                        mod: "Sessão para assistência fisioterapêutica hospitalar ao paciente com disfunção decorrente de queimaduras",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000390",
                        original: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de queimaduras",
                        mod: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de queimaduras",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000403",
                        original: "Sessão para assistência fisioterapêutica hospitalar ao paciente com disfunção decorrente de alterações do sistema linfático e/ou vascular periférico",
                        mod: "Sessão para assistência fisioterapêutica hospitalar ao paciente com disfunção decorrente de alterações do sistema linfático e/ou vascular periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000403",
                        original: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações do sistema linfático e/ou vascular periférico",
                        mod: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações do sistema linfático e/ou vascular periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000411",
                        original: "Sessão para assistência fisioterapêutica hospitalar no pré e pós cirúrgico e em recuperação de tecidos",
                        mod: "Sessão para assistência fisioterapêutica hospitalar no pré e pós cirúrgico e em recuperação de tecidos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000411",
                        original: "Atendimento fisioterapêutico hospitalar no pré e pós cirúrgico e em recuperação de tecidos",
                        mod: "Atendimento fisioterapêutico hospitalar no pré e pós cirúrgico e em recuperação de tecidos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000420",
                        original: "Sessão para assistência fisioterapêutica hospitalar por alterações endocrino-metabólicas",
                        mod: "Sessão para assistência fisioterapêutica hospitalar por alterações endocrino-metabólicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000420",
                        original: "Atendimento fisioterapêutico hospitalar por alterações endocrino-metabólicas",
                        mod: "Atendimento fisioterapêutico hospitalar por alterações endocrino-metabólicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000438",
                        original: "Hidroterapia",
                        mod: "Hidroterapia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000446",
                        original: "RPG",
                        mod: "RPG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000446",
                        original: "Reeducação Postural Global",
                        mod: "Reeducação Postural Global",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000454",
                        original: "Sessão para assistência fisioterapêutica hospitalar para alterações inflamatórias e ou degenerativas do aparelho genito-urinário e reprodutor",
                        mod: "Sessão para assistência fisioterapêutica hospitalar para alterações inflamatórias e ou degenerativas do aparelho genito-urinário e reprodutor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000454",
                        original: "Atendimento fisioterapêutico hospitalar para alterações inflamatórias e ou degenerativas do aparelho genito-urinário, reprodutor e/ou proctológico",
                        mod: "Atendimento fisioterapêutico hospitalar para alterações inflamatórias e ou degenerativas do aparelho genito-urinário, reprodutor e/ou proctológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000462",
                        original: "Consulta em psicologia",
                        mod: "Consulta em psicologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000470",
                        original: "Sessão de psicoterapia individual por psicólogo",
                        mod: "Sessão de psicoterapia individual por psicólogo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000489",
                        original: "Sessão de psicoterapia em grupo por psicólogo",
                        mod: "Sessão de psicoterapia em grupo por psicólogo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000497",
                        original: "Sessão de psicoterapia em casal por psicólogo",
                        mod: "Sessão de psicoterapia em casal por psicólogo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000500",
                        original: "Sessão de psicoterapia familiar por psicólogo",
                        mod: "Sessão de psicoterapia familiar por psicólogo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000519",
                        original: "Acompanhamento e reabilitação profissional por psicólogo",
                        mod: "Acompanhamento e reabilitação profissional por psicólogo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000527",
                        original: "Consulta hospitalar de enfermagem",
                        mod: "Consulta hospitalar de enfermagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000527",
                        original: "Enfermagem hospitalar",
                        mod: "Enfermagem hospitalar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000535",
                        original: "Consulta domiciliar de enfermagem",
                        mod: "Consulta domiciliar de enfermagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000535",
                        original: "Enfermagem domiciliar",
                        mod: "Enfermagem domiciliar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000543",
                        original: "Acompanhamento de cuidador domiciliar",
                        mod: "Acompanhamento de cuidador domiciliar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000551",
                        original: "Acompanhamento de cuidador para paciente psiquiátrico",
                        mod: "Acompanhamento de cuidador para paciente psiquiátrico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000560",
                        original: "Consulta ambulatorial por nutricionista",
                        mod: "Consulta ambulatorial por nutricionista",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000578",
                        original: "Consulta domiciliar por nutricionista",
                        mod: "Consulta domiciliar por nutricionista",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000586",
                        original: "Consulta individual ambulatorial de fonoaudiologia",
                        mod: "Consulta individual ambulatorial de fonoaudiologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000594",
                        original: "Consulta individual domiciliar de fonoaudiologia",
                        mod: "Consulta individual domiciliar de fonoaudiologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000608",
                        original: "Consulta individual hospitalar de fonoaudiologia",
                        mod: "Consulta individual hospitalar de fonoaudiologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000616",
                        original: "Sessão individual ambulatorial de fonoaudiologia",
                        mod: "Sessão individual ambulatorial de fonoaudiologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000624",
                        original: "Sessão individual domiciliar de fonoaudiologia",
                        mod: "Sessão individual domiciliar de fonoaudiologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000632",
                        original: "Sessão individual hospitalar de fonoaudiologia",
                        mod: "Sessão individual hospitalar de fonoaudiologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000640",
                        original: "Sessão de fonoaudiologia em grupo",
                        mod: "Sessão de fonoaudiologia em grupo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000659",
                        original: "Orientação fonoaudiológica aos pais/escolar/cuidador",
                        mod: "Orientação fonoaudiológica aos pais/escolar/cuidador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000667",
                        original: "Análise acústica da voz por fonoaudiólogo",
                        mod: "Análise acústica da voz por fonoaudiólogo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000675",
                        original: "Avaliação do processamento auditivo central por fonoaudiólogo",
                        mod: "Avaliação do processamento auditivo central por fonoaudiólogo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000683",
                        original: "Visita de assistente social",
                        mod: "Visita de assistente social",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000691",
                        original: "Consulta hospitalar por nutricionista",
                        mod: "Consulta hospitalar por nutricionista",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000705",
                        original: "Consulta ambulatorial de enfermagem",
                        mod: "Consulta ambulatorial de enfermagem",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000713",
                        original: "Atendimento fisioterapêutico ambulatorial ao paciente independente ou com dependência parcial, com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        mod: "Atendimento fisioterapêutico ambulatorial ao paciente independente ou com dependência parcial, com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000721",
                        original: "Atendimento fisioterapêutico ambulatorial ao paciente dependente com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        mod: "Atendimento fisioterapêutico ambulatorial ao paciente dependente com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000730",
                        original: "Atendimento fisioterapêutico ambulatorial individual ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        mod: "Atendimento fisioterapêutico ambulatorial individual ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000748",
                        original: "Atendimento fisioterapêutico ambulatorial em grupo aos pacientes com disfunção decorrente de alterações no sistema respiratório",
                        mod: "Atendimento fisioterapêutico ambulatorial em grupo aos pacientes com disfunção decorrente de alterações no sistema respiratório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000756",
                        original: "Atendimento fisioterapêutico ambulatorial individual ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        mod: "Atendimento fisioterapêutico ambulatorial individual ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000764",
                        original: "Atendimento fisioterapêutico ambulatorial em grupo aos pacientes com disfunção decorrente de alterações do sistema cardiovascular",
                        mod: "Atendimento fisioterapêutico ambulatorial em grupo aos pacientes com disfunção decorrente de alterações do sistema cardiovascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000772",
                        original: "Atendimento fisioterapêutico ambulatorial individual por alterações endocrino-metabólicas",
                        mod: "Atendimento fisioterapêutico ambulatorial individual por alterações endocrino-metabólicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000780",
                        original: "Atendimento fisioterapêutico ambulatorial em grupo por alterações endocrino-metabólicas",
                        mod: "Atendimento fisioterapêutico ambulatorial em grupo por alterações endocrino-metabólicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000799",
                        original: "Atendimento fisioterapêutico hospitalar ao paciente independente ou com dependência parcial, com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        mod: "Atendimento fisioterapêutico hospitalar ao paciente independente ou com dependência parcial, com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000802",
                        original: "Atendimento fisioterapêutico hospitalar ao paciente dependente com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        mod: "Atendimento fisioterapêutico hospitalar ao paciente dependente com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000810",
                        original: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações no sistema respiratório com assistência ventilatória.",
                        mod: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações no sistema respiratório com assistência ventilatória.",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000829",
                        original: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações no sistema respiratório sem assistência ventilatória",
                        mod: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações no sistema respiratório sem assistência ventilatória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000837",
                        original: "Fisioterapia aquatica individual",
                        mod: "Fisioterapia aquatica individual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000837",
                        original: "Fisioterapia aquatica individual (Hidroterapia)",
                        mod: "Fisioterapia aquatica individual (Hidroterapia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000845",
                        original: "Fisioterapia aquatica em grupo",
                        mod: "Fisioterapia aquatica em grupo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000845",
                        original: "Fisioterapia aquatica em grupo (Hidroterapia)",
                        mod: "Fisioterapia aquatica em grupo (Hidroterapia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000853",
                        original: "Atendimento Fisioterapêutico individual em Pilates",
                        mod: "Atendimento Fisioterapêutico individual em Pilates",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000861",
                        original: "Atendimento Fisioterapêutico em grupo em Pilates",
                        mod: "Atendimento Fisioterapêutico em grupo em Pilates",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000870",
                        original: "Atendimento Fisioterapêutico em Quiropraxia",
                        mod: "Atendimento Fisioterapêutico em Quiropraxia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000888",
                        original: "Baropodometria",
                        mod: "Baropodometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000896",
                        original: "Avaliação ergonômica",
                        mod: "Avaliação ergonômica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000900",
                        original: "EMG não  invasivo",
                        mod: "EMG não  invasivo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000918",
                        original: "Estabilometria",
                        mod: "Estabilometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000926",
                        original: "Ventilometria (incluindo Capacidade Vital, Capacidade Inspiratoria, volume minuto, volume corrente e todos índices derivados destas avaliações)",
                        mod: "Ventilometria (incluindo Capacidade Vital, Capacidade Inspiratoria, volume minuto, volume corrente e todos índices derivados destas avaliações)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000934",
                        original: "Análise Eletroterapêutica não invasiva, identificação de ponto motor, cronaximetria, reobase, acomodação e curva I/T - por segmento ou membro",
                        mod: "Análise Eletroterapêutica não invasiva, identificação de ponto motor, cronaximetria, reobase, acomodação e curva I/T - por segmento ou membro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000942",
                        original: "Atendimento fisioterapêutico ambulatorial individual ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        mod: "Atendimento fisioterapêutico ambulatorial individual ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000950",
                        original: "Atendimento fisioterapêutico ambulatorial em grupo ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        mod: "Atendimento fisioterapêutico ambulatorial em grupo ao paciente com disfunção decorrente de alterações do sistema cardiovascular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000969",
                        original: "Hidroterapia individual",
                        mod: "Hidroterapia individual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000977",
                        original: "Hidroterapia em grupo",
                        mod: "Hidroterapia em grupo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000985",
                        original: "Atendimento fisioterapêutico ambulatorial individual ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        mod: "Atendimento fisioterapêutico ambulatorial individual ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50000993",
                        original: "Atendimento fisioterapêutico ambulatorial em grupo ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        mod: "Atendimento fisioterapêutico ambulatorial em grupo ao paciente com disfunção decorrente de alterações no sistema respiratório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001000",
                        original: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações no sistema respiratório sem Assistência Ventilatória Mecânica.",
                        mod: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações no sistema respiratório sem Assistência Ventilatória Mecânica.",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001019",
                        original: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações no sistema respiratório com Assistência Ventilatória Mecânica.",
                        mod: "Atendimento fisioterapêutico hospitalar ao paciente com disfunção decorrente de alterações no sistema respiratório com Assistência Ventilatória Mecânica.",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001027",
                        original: "Atendimento fisioterapêutico ambulatorial individual por alterações endocrino-metabólicas",
                        mod: "Atendimento fisioterapêutico ambulatorial individual por alterações endocrino-metabólicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001035",
                        original: "Atendimento fisioterapêutico ambulatorial em grupo por alterações endocrino-metabólicas",
                        mod: "Atendimento fisioterapêutico ambulatorial em grupo por alterações endocrino-metabólicas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001043",
                        original: "Atendimento fisioterapêutico hospitalar ao paciente com dependência parcial com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        mod: "Atendimento fisioterapêutico hospitalar ao paciente com dependência parcial com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001051",
                        original: "Atendimento fisioterapêutico hospitalar ao paciente com dependência total com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        mod: "Atendimento fisioterapêutico hospitalar ao paciente com dependência total com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001060",
                        original: "Atendimento fisioterapêutico ambulatorial ao paciente com dependência parcial com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        mod: "Atendimento fisioterapêutico ambulatorial ao paciente com dependência parcial com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001078",
                        original: "Atendimento fisioterapêutico ambulatorial ao paciente com dependência total com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        mod: "Atendimento fisioterapêutico ambulatorial ao paciente com dependência total com disfunção decorrente de lesão do sistema nervoso central e/ou periférico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001086",
                        original: "Atendimento Fisioterapêutico individual em Pilates",
                        mod: "Atendimento Fisioterapêutico individual em Pilates",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001094",
                        original: "Atendimento Fisioterapêutico em grupo em Pilates",
                        mod: "Atendimento Fisioterapêutico em grupo em Pilates",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001108",
                        original: "Atendimento fisioterapêutico em Quiropraxia",
                        mod: "Atendimento fisioterapêutico em Quiropraxia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001116",
                        original: "Análise Eletroterapêutica não invasiva, identificação de ponto motor, cronaximetria, reobase, acomodação e curva I/T - por segmento ou membro",
                        mod: "Análise Eletroterapêutica não invasiva, identificação de ponto motor, cronaximetria, reobase, acomodação e curva I/T - por segmento ou membro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001124",
                        original: "Baropodometria",
                        mod: "Baropodometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001132",
                        original: "Avaliação ergonômica",
                        mod: "Avaliação ergonômica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001140",
                        original: "EMG não invasivo",
                        mod: "EMG não invasivo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001159",
                        original: "Estabilometria",
                        mod: "Estabilometria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001167",
                        original: "Confecção de recursos de tecnologia assistiva",
                        mod: "Confecção de recursos de tecnologia assistiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "50001175",
                        original: "Ventilometria (incluindo Capacidade Vital, Capacidade Inspiratória, volume minuto, volume corrente e todos índices derivados destas avaliações)",
                        mod: "Ventilometria (incluindo Capacidade Vital, Capacidade Inspiratória, volume minuto, volume corrente e todos índices derivados destas avaliações)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000014",
                        original: "Condicionamento em Odontologia",
                        mod: "Condicionamento em Odontologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000030",
                        original: "Consulta odontológica",
                        mod: "Consulta odontológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000049",
                        original: "Consulta odontológica de Urgência",
                        mod: "Consulta odontológica de Urgência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000057",
                        original: "Consulta odontológica de Urgência 24 hs",
                        mod: "Consulta odontológica de Urgência 24 hs",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000065",
                        original: "Consulta odontológica inicial",
                        mod: "Consulta odontológica inicial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000073",
                        original: "Consulta odontológica para avaliação técnica de auditoria",
                        mod: "Consulta odontológica para avaliação técnica de auditoria",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000090",
                        original: "Consulta para Técnica de Clareamento Dentário Caseiro",
                        mod: "Consulta para Técnica de Clareamento Dentário Caseiro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000111",
                        original: "Diagnóstico anatomopatológico em citologia esfoliativa na região buco-maxilo-facial",
                        mod: "Diagnóstico anatomopatológico em citologia esfoliativa na região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000138",
                        original: "Diagnóstico anatomopatológico em material de biópsia na região buco-maxilo-facial",
                        mod: "Diagnóstico anatomopatológico em material de biópsia na região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000154",
                        original: "Diagnóstico anatomopatológico em peça cirúrgica na região buco-maxilo-facial",
                        mod: "Diagnóstico anatomopatológico em peça cirúrgica na região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000170",
                        original: "Diagnóstico anatomopatológico em punção na região buco-maxilo-facial",
                        mod: "Diagnóstico anatomopatológico em punção na região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000189",
                        original: "Diagnóstico e planejamento para tratamento odontológico",
                        mod: "Diagnóstico e planejamento para tratamento odontológico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000197",
                        original: "Diagnóstico e tratamento de estomatite herpética",
                        mod: "Diagnóstico e tratamento de estomatite herpética",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000200",
                        original: "Diagnóstico e tratamento de estomatite por candidose",
                        mod: "Diagnóstico e tratamento de estomatite por candidose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000219",
                        original: "Diagnóstico e tratamento de halitose",
                        mod: "Diagnóstico e tratamento de halitose",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000235",
                        original: "Diagnóstico e tratamento de xerostomia",
                        mod: "Diagnóstico e tratamento de xerostomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000243",
                        original: "Diagnóstico por meio de enceramento",
                        mod: "Diagnóstico por meio de enceramento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000260",
                        original: "Diagnóstico por meio de procedimentos laboratoriais",
                        mod: "Diagnóstico por meio de procedimentos laboratoriais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000278",
                        original: "Fotografia",
                        mod: "Fotografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000294",
                        original: "Levantamento Radiográfico (Exame Radiodôntico)",
                        mod: "Levantamento Radiográfico (Exame Radiodôntico)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000308",
                        original: "Modelos ortodônticos",
                        mod: "Modelos ortodônticos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000324",
                        original: "Radiografia antero-posterior",
                        mod: "Radiografia antero-posterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000340",
                        original: "Radiografia da ATM",
                        mod: "Radiografia da ATM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000367",
                        original: "Radiografia da mão e punho - carpal",
                        mod: "Radiografia da mão e punho - carpal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000375",
                        original: "Radiografia interproximal - bite-wing",
                        mod: "Radiografia interproximal - bite-wing",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000383",
                        original: "Radiografia oclusal",
                        mod: "Radiografia oclusal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000405",
                        original: "Radiografia panorâmica de mandíbula/maxila (ortopantomografia)",
                        mod: "Radiografia panorâmica de mandíbula/maxila (ortopantomografia)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000413",
                        original: "Radiografia panorâmica de mandíbula/maxila (ortopantomografia) com traçado para implantes",
                        mod: "Radiografia panorâmica de mandíbula/maxila (ortopantomografia) com traçado para implantes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000413",
                        original: "Radiografia panorâmica de mandíbula/maxila (ortopantomografia) com traçado cefalométrico",
                        mod: "Radiografia panorâmica de mandíbula/maxila (ortopantomografia) com traçado cefalométrico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000421",
                        original: "Radiografia periapical",
                        mod: "Radiografia periapical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000430",
                        original: "Radiografia póstero-anterior",
                        mod: "Radiografia póstero-anterior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000456",
                        original: "Slide",
                        mod: "Slide",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000472",
                        original: "Telerradiografia",
                        mod: "Telerradiografia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000480",
                        original: "Telerradiografia com traçado cefalométrico",
                        mod: "Telerradiografia com traçado cefalométrico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000510",
                        original: "Tomografia computadorizada por feixe cônico – cone beam",
                        mod: "Tomografia computadorizada por feixe cônico – cone beam",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000529",
                        original: "Tomografia convencional – linear ou multi-direcional",
                        mod: "Tomografia convencional – linear ou multi-direcional",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000537",
                        original: "Traçado Cefalométrico",
                        mod: "Traçado Cefalométrico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000545",
                        original: "Diagnóstico e tratamento de trismo",
                        mod: "Diagnóstico e tratamento de trismo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000553",
                        original: "Documentação odontológica em mídia digital",
                        mod: "Documentação odontológica em mídia digital",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000561",
                        original: "Radiografia lateral corpo da mandíbula",
                        mod: "Radiografia lateral corpo da mandíbula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "81000570",
                        original: "Técnica de localização radiográfica",
                        mod: "Técnica de localização radiográfica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000026",
                        original: "Acompanhamento de tratamento/procedimento cirúrgico em odontologia",
                        mod: "Acompanhamento de tratamento/procedimento cirúrgico em odontologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000034",
                        original: "Alveoloplastia",
                        mod: "Alveoloplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000050",
                        original: "Amputação radicular com obturação retrógrada",
                        mod: "Amputação radicular com obturação retrógrada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000069",
                        original: "Amputação radicular sem obturação retrógrada",
                        mod: "Amputação radicular sem obturação retrógrada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000077",
                        original: "Apicetomia birradiculares com obturação retrógrada",
                        mod: "Apicetomia birradiculares com obturação retrógrada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000085",
                        original: "Apicetomia birradiculares sem obturação retrógrada",
                        mod: "Apicetomia birradiculares sem obturação retrógrada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000158",
                        original: "Apicetomia multirradiculares com obturação retrógrada",
                        mod: "Apicetomia multirradiculares com obturação retrógrada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000166",
                        original: "Apicetomia multirradiculares sem obturação retrógrada",
                        mod: "Apicetomia multirradiculares sem obturação retrógrada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000174",
                        original: "Apicetomia unirradiculares com obturação retrógrada",
                        mod: "Apicetomia unirradiculares com obturação retrógrada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000182",
                        original: "Apicetomia unirradiculares sem obturação retrógrada",
                        mod: "Apicetomia unirradiculares sem obturação retrógrada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000190",
                        original: "Aprofundamento/aumento de vestíbulo",
                        mod: "Aprofundamento/aumento de vestíbulo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000212",
                        original: "Aumento de coroa clínica",
                        mod: "Aumento de coroa clínica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000239",
                        original: "Biópsia de boca",
                        mod: "Biópsia de boca",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000247",
                        original: "Biópsia de glândula salivar",
                        mod: "Biópsia de glândula salivar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000255",
                        original: "Biópsia de lábio",
                        mod: "Biópsia de lábio",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000263",
                        original: "Biópsia de língua",
                        mod: "Biópsia de língua",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000271",
                        original: "Biópsia de mandíbula",
                        mod: "Biópsia de mandíbula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000280",
                        original: "Biópsia de maxila",
                        mod: "Biópsia de maxila",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000298",
                        original: "Bridectomia",
                        mod: "Bridectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000301",
                        original: "Bridotomia",
                        mod: "Bridotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000336",
                        original: "Cirurgia odontológica a retalho",
                        mod: "Cirurgia odontológica a retalho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000344",
                        original: "Cirurgia odontológica com aplicação de aloenxertos",
                        mod: "Cirurgia odontológica com aplicação de aloenxertos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000352",
                        original: "Cirurgia para exostose maxilar",
                        mod: "Cirurgia para exostose maxilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000360",
                        original: "Cirurgia para torus mandibular – bilateral",
                        mod: "Cirurgia para torus mandibular – bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000387",
                        original: "Cirurgia para torus mandibular – unilateral",
                        mod: "Cirurgia para torus mandibular – unilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000395",
                        original: "Cirurgia para torus palatino",
                        mod: "Cirurgia para torus palatino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000417",
                        original: "Cirurgia periodontal a retalho",
                        mod: "Cirurgia periodontal a retalho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000441",
                        original: "Coleta de raspado em lesões ou sítios específicos da região buco-maxilo-facial",
                        mod: "Coleta de raspado em lesões ou sítios específicos da região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000468",
                        original: "Controle de hemorragia com aplicação de agente hemostático em região buco-maxilo-facial",
                        mod: "Controle de hemorragia com aplicação de agente hemostático em região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000484",
                        original: "Controle de hemorragia sem aplicação de agente hemostático em região buco-maxilo-facial",
                        mod: "Controle de hemorragia sem aplicação de agente hemostático em região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000506",
                        original: "Controle pós-operatório em odontologia",
                        mod: "Controle pós-operatório em odontologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000522",
                        original: "Criocirurgia de neoplasias da região buco-maxilo-facial",
                        mod: "Criocirurgia de neoplasias da região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000549",
                        original: "Crioterapia ou termoterapia em odontologia",
                        mod: "Crioterapia ou termoterapia em odontologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000557",
                        original: "Cunha proximal",
                        mod: "Cunha proximal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000581",
                        original: "Enxerto com osso autógeno da linha oblíqua",
                        mod: "Enxerto com osso autógeno da linha oblíqua",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000603",
                        original: "Enxerto com osso autógeno do mento",
                        mod: "Enxerto com osso autógeno do mento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000620",
                        original: "Enxerto com osso liofilizado",
                        mod: "Enxerto com osso liofilizado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000646",
                        original: "Enxerto conjuntivo subepitelial",
                        mod: "Enxerto conjuntivo subepitelial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000662",
                        original: "Enxerto gengival livre",
                        mod: "Enxerto gengival livre",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000689",
                        original: "Enxerto pediculado",
                        mod: "Enxerto pediculado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000700",
                        original: "Estabilização de paciente por meio de contenção física e/ou mecânica",
                        mod: "Estabilização de paciente por meio de contenção física e/ou mecânica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000743",
                        original: "Exérese de lipoma na região buco-maxilo-facial",
                        mod: "Exérese de lipoma na região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000778",
                        original: "Exérese ou excisão de cálculo salivar",
                        mod: "Exérese ou excisão de cálculo salivar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000786",
                        original: "Exérese ou excisão de cistos odontológicos",
                        mod: "Exérese ou excisão de cistos odontológicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000794",
                        original: "Exérese ou excisão de mucocele",
                        mod: "Exérese ou excisão de mucocele",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000808",
                        original: "Exérese ou excisão de rânula",
                        mod: "Exérese ou excisão de rânula",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000816",
                        original: "Exodontia a retalho",
                        mod: "Exodontia a retalho",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000832",
                        original: "Exodontia de permanente por indicação ortodôntica/protética",
                        mod: "Exodontia de permanente por indicação ortodôntica/protética",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000859",
                        original: "Exodontia de raiz residual",
                        mod: "Exodontia de raiz residual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000875",
                        original: "Exodontia simples de permanente",
                        mod: "Exodontia simples de permanente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000883",
                        original: "Frenulectomia labial",
                        mod: "Frenulectomia labial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000891",
                        original: "Frenulectomia lingual",
                        mod: "Frenulectomia lingual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000905",
                        original: "Frenulotomia labial",
                        mod: "Frenulotomia labial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000913",
                        original: "Frenulotomia lingual",
                        mod: "Frenulotomia lingual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000921",
                        original: "Gengivectomia",
                        mod: "Gengivectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000948",
                        original: "Gengivoplastia",
                        mod: "Gengivoplastia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000964",
                        original: "Implante ortodôntico",
                        mod: "Implante ortodôntico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82000980",
                        original: "Implante ósseo integrado",
                        mod: "Implante ósseo integrado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001006",
                        original: "Implante Zigomático",
                        mod: "Implante Zigomático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001022",
                        original: "Incisão e Drenagem extra-oral de abscesso, hematoma e/ou flegmão da região buco-maxilo-facial",
                        mod: "Incisão e Drenagem extra-oral de abscesso, hematoma e/ou flegmão da região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001030",
                        original: "Incisão e Drenagem intra-oral de abscesso, hematoma e/ou flegmão da região buco-maxilo-facial",
                        mod: "Incisão e Drenagem intra-oral de abscesso, hematoma e/ou flegmão da região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001049",
                        original: "Levantamento do seio maxilar com osso autógeno",
                        mod: "Levantamento do seio maxilar com osso autógeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001057",
                        original: "Levantamento do seio maxilar com osso homólogo",
                        mod: "Levantamento do seio maxilar com osso homólogo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001065",
                        original: "Levantamento do seio maxilar com osso liofilizado",
                        mod: "Levantamento do seio maxilar com osso liofilizado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001073",
                        original: "Odonto-secção",
                        mod: "Odonto-secção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001103",
                        original: "Punção aspirativa na região buco-maxilo-facial",
                        mod: "Punção aspirativa na região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001120",
                        original: "Punção aspirativa orientada por imagem na região buco-maxilo-facial",
                        mod: "Punção aspirativa orientada por imagem na região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001138",
                        original: "Reabertura - colocação de cicatrizador",
                        mod: "Reabertura - colocação de cicatrizador",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001154",
                        original: "Reconstrução de sulco gengivo-labial",
                        mod: "Reconstrução de sulco gengivo-labial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001170",
                        original: "Redução cruenta de fratura alvéolo dentária",
                        mod: "Redução cruenta de fratura alvéolo dentária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001189",
                        original: "Redução incruenta de fratura alvéolo dentária",
                        mod: "Redução incruenta de fratura alvéolo dentária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001197",
                        original: "Redução simples de luxação de Articulação Têmporo-mandibular (ATM)",
                        mod: "Redução simples de luxação de Articulação Têmporo-mandibular (ATM)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001219",
                        original: "Reeducação e/ou reabilitação de distúrbio buco-maxilo-facial",
                        mod: "Reeducação e/ou reabilitação de distúrbio buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001235",
                        original: "Reeducação e/ou reabilitação de sequela em traumatismo buco-maxilo-facial",
                        mod: "Reeducação e/ou reabilitação de sequela em traumatismo buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001243",
                        original: "Regeneração tecidual guiada – RTG",
                        mod: "Regeneração tecidual guiada – RTG",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001251",
                        original: "Reimplante dentário com contenção",
                        mod: "Reimplante dentário com contenção",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001286",
                        original: "Remoção de dentes inclusos / impactados",
                        mod: "Remoção de dentes inclusos / impactados",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001294",
                        original: "Remoção de dentes semi-inclusos / impactados",
                        mod: "Remoção de dentes semi-inclusos / impactados",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001308",
                        original: "Remoção de dreno extra-oral",
                        mod: "Remoção de dreno extra-oral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001316",
                        original: "Remoção de dreno intra-oral",
                        mod: "Remoção de dreno intra-oral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001324",
                        original: "Remoção de implante dentário não ósseo integrado",
                        mod: "Remoção de implante dentário não ósseo integrado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001332",
                        original: "Remoção de implante dentário ósseo integrado no seio maxilar",
                        mod: "Remoção de implante dentário ósseo integrado no seio maxilar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001367",
                        original: "Remoção de odontoma",
                        mod: "Remoção de odontoma",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001375",
                        original: "Remoção de tamponamento nasal",
                        mod: "Remoção de tamponamento nasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001391",
                        original: "Retirada de corpo estranho oroantral ou oronasal da região buco-maxilo-facial",
                        mod: "Retirada de corpo estranho oroantral ou oronasal da região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001413",
                        original: "Retirada de corpo estranho subcutâneo ou submucoso da região buco-maxilo-facial",
                        mod: "Retirada de corpo estranho subcutâneo ou submucoso da região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001430",
                        original: "Retirada dos meios de fixação da região buco-maxilo-facial",
                        mod: "Retirada dos meios de fixação da região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001448",
                        original: "Sedação consciente com óxido nitroso e oxigênio em odontologia",
                        mod: "Sedação consciente com óxido nitroso e oxigênio em odontologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001456",
                        original: "Sedação medicamentosa ambulatorial em odontologia",
                        mod: "Sedação medicamentosa ambulatorial em odontologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001464",
                        original: "Sepultamento radicular",
                        mod: "Sepultamento radicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001499",
                        original: "Sutura de ferida em região buco-maxilo-facial",
                        mod: "Sutura de ferida em região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001502",
                        original: "Tracionamento cirúrgico com finalidade ortodôntica",
                        mod: "Tracionamento cirúrgico com finalidade ortodôntica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001510",
                        original: "Tratamento cirúrgico das fístulas buco nasal",
                        mod: "Tratamento cirúrgico das fístulas buco nasal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001529",
                        original: "Tratamento cirúrgico das fístulas buco sinusal",
                        mod: "Tratamento cirúrgico das fístulas buco sinusal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001545",
                        original: "Tratamento cirúrgico de bridas constritivas da região buco-maxilo-facial",
                        mod: "Tratamento cirúrgico de bridas constritivas da região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001553",
                        original: "Tratamento cirúrgico de hiperplasias de tecidos moles na região buco-maxilo-facial",
                        mod: "Tratamento cirúrgico de hiperplasias de tecidos moles na região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001588",
                        original: "Tratamento cirúrgico de hiperplasias de tecidos ósseos/cartilaginosos na região buco-maxilo-facial",
                        mod: "Tratamento cirúrgico de hiperplasias de tecidos ósseos/cartilaginosos na região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001596",
                        original: "Tratamento cirúrgico de tumores benignos de tecidos ósseos/cartilaginosos na região buco-maxilo-facial",
                        mod: "Tratamento cirúrgico de tumores benignos de tecidos ósseos/cartilaginosos na região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001618",
                        original: "Tratamento cirúrgico dos tumores benignos de tecidos moles na região buco-maxilo-facial",
                        mod: "Tratamento cirúrgico dos tumores benignos de tecidos moles na região buco-maxilo-facial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001634",
                        original: "Tratamento Cirúrgico para tumores odontogênicos benignos – sem reconstrução",
                        mod: "Tratamento Cirúrgico para tumores odontogênicos benignos – sem reconstrução",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001642",
                        original: "Tratamento conservador de luxação da articulação têmporo-mandibular - ATM",
                        mod: "Tratamento conservador de luxação da articulação têmporo-mandibular - ATM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001650",
                        original: "Tratamento de alveolite",
                        mod: "Tratamento de alveolite",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001669",
                        original: "Tratamento odontológico regenerativo com enxerto de osso autógeno",
                        mod: "Tratamento odontológico regenerativo com enxerto de osso autógeno",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001685",
                        original: "Tunelização",
                        mod: "Tunelização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001707",
                        original: "Ulectomia",
                        mod: "Ulectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001715",
                        original: "Ulotomia",
                        mod: "Ulotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001723",
                        original: "Aplicação de laser pós cirúrgico",
                        mod: "Aplicação de laser pós cirúrgico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001731",
                        original: "Exodontia de semi-incluso/impactado supra numerário",
                        mod: "Exodontia de semi-incluso/impactado supra numerário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001740",
                        original: "Exodontia de incluso/impactado supra numerário",
                        mod: "Exodontia de incluso/impactado supra numerário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001758",
                        original: "Marsupialização de cistos odontológicos",
                        mod: "Marsupialização de cistos odontológicos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "82001766",
                        original: "Placa de contenção cirúrgica",
                        mod: "Placa de contenção cirúrgica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "83000020",
                        original: "Coroa de acetato em dente decíduo",
                        mod: "Coroa de acetato em dente decíduo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "83000046",
                        original: "Coroa de aço em dente decíduo",
                        mod: "Coroa de aço em dente decíduo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "83000062",
                        original: "Coroa de policarbonato em dente decíduo",
                        mod: "Coroa de policarbonato em dente decíduo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "83000089",
                        original: "Exodontia simples de decíduo",
                        mod: "Exodontia simples de decíduo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "83000097",
                        original: "Mantenedor de espaço fixo",
                        mod: "Mantenedor de espaço fixo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "83000100",
                        original: "Mantenedor de espaço removível",
                        mod: "Mantenedor de espaço removível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "83000127",
                        original: "Pulpotomia em dente decíduo",
                        mod: "Pulpotomia em dente decíduo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "83000135",
                        original: "Restauração atraumática em dente decíduo",
                        mod: "Restauração atraumática em dente decíduo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "83000151",
                        original: "Tratamento endodôntico em dente decíduo",
                        mod: "Tratamento endodôntico em dente decíduo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000015",
                        original: "Aparelho protetor bucal",
                        mod: "Aparelho protetor bucal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000031",
                        original: "Aplicação de cariostático",
                        mod: "Aplicação de cariostático",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000058",
                        original: "Aplicação de selante - técnica invasiva",
                        mod: "Aplicação de selante - técnica invasiva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000074",
                        original: "Aplicação de selante de fóssulas e fissuras",
                        mod: "Aplicação de selante de fóssulas e fissuras",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000090",
                        original: "Aplicação tópica de flúor",
                        mod: "Aplicação tópica de flúor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000112",
                        original: "Aplicação tópica de verniz fluoretado",
                        mod: "Aplicação tópica de verniz fluoretado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000139",
                        original: "Atividade educativa em saúde bucal",
                        mod: "Atividade educativa em saúde bucal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000163",
                        original: "Controle de biofilme (placa bacteriana)",
                        mod: "Controle de biofilme (placa bacteriana)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000171",
                        original: "Controle de cárie incipiente",
                        mod: "Controle de cárie incipiente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000198",
                        original: "Profilaxia: polimento coronário",
                        mod: "Profilaxia: polimento coronário",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000201",
                        original: "Remineralização",
                        mod: "Remineralização",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000228",
                        original: "Teste de capacidade tampão da saliva",
                        mod: "Teste de capacidade tampão da saliva",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000236",
                        original: "Teste de contagem microbiológica",
                        mod: "Teste de contagem microbiológica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000244",
                        original: "Teste de fluxo salivar",
                        mod: "Teste de fluxo salivar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "84000252",
                        original: "Teste de PH salivar",
                        mod: "Teste de PH salivar",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85000787",
                        original: "Imobilização dentária em dentes decíduos",
                        mod: "Imobilização dentária em dentes decíduos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100013",
                        original: "Capeamento pulpar direto",
                        mod: "Capeamento pulpar direto",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100021",
                        original: "Clareamento dentário caseiro",
                        mod: "Clareamento dentário caseiro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100030",
                        original: "Clareamento dentário de consultório",
                        mod: "Clareamento dentário de consultório",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100048",
                        original: "Colagem de fragmentos dentários",
                        mod: "Colagem de fragmentos dentários",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100056",
                        original: "Curativo de demora em endodontia",
                        mod: "Curativo de demora em endodontia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100064",
                        original: "Faceta direta em resina fotopolimerizável",
                        mod: "Faceta direta em resina fotopolimerizável",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100072",
                        original: "Placa de Acetato para Clareamento Caseiro",
                        mod: "Placa de Acetato para Clareamento Caseiro",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100080",
                        original: "Restauração atraumática em dente permanente",
                        mod: "Restauração atraumática em dente permanente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100099",
                        original: "Restauração de amálgama  - 1 face",
                        mod: "Restauração de amálgama  - 1 face",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100102",
                        original: "Restauração de amálgama - 2 faces",
                        mod: "Restauração de amálgama - 2 faces",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100110",
                        original: "Restauração de amálgama - 3 faces",
                        mod: "Restauração de amálgama - 3 faces",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100129",
                        original: "Restauração de amálgama - 4 faces",
                        mod: "Restauração de amálgama - 4 faces",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100137",
                        original: "Restauração em ionômero de vidro - 1 face",
                        mod: "Restauração em ionômero de vidro - 1 face",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100145",
                        original: "Restauração em ionômero de vidro - 2 faces",
                        mod: "Restauração em ionômero de vidro - 2 faces",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100153",
                        original: "Restauração em ionômero de vidro - 3 faces",
                        mod: "Restauração em ionômero de vidro - 3 faces",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100161",
                        original: "Restauração em ionômero de vidro - 4 faces",
                        mod: "Restauração em ionômero de vidro - 4 faces",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100170",
                        original: "Restauração em resina (indireta) - Inlay",
                        mod: "Restauração em resina (indireta) - Inlay",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100188",
                        original: "Restauração em resina (indireta) - Onlay",
                        mod: "Restauração em resina (indireta) - Onlay",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100196",
                        original: "Restauração em resina fotopolimerizável  1 face",
                        mod: "Restauração em resina fotopolimerizável  1 face",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100200",
                        original: "Restauração em resina fotopolimerizável  2 faces",
                        mod: "Restauração em resina fotopolimerizável  2 faces",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100218",
                        original: "Restauração em resina fotopolimerizável  3 faces",
                        mod: "Restauração em resina fotopolimerizável  3 faces",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100226",
                        original: "Restauração em resina fotopolimerizável  4 faces",
                        mod: "Restauração em resina fotopolimerizável  4 faces",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100234",
                        original: "Tratamento de fluorose - microabrasão",
                        mod: "Tratamento de fluorose - microabrasão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100242",
                        original: "Adequação do meio bucal",
                        mod: "Adequação do meio bucal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100250",
                        original: "Aplicação de laser terapêutico",
                        mod: "Aplicação de laser terapêutico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85100269",
                        original: "Dessensibilização dentinária",
                        mod: "Dessensibilização dentinária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200018",
                        original: "Clareamento de dente desvitalizado",
                        mod: "Clareamento de dente desvitalizado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200026",
                        original: "Preparo para núcleo intrarradicular",
                        mod: "Preparo para núcleo intrarradicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200034",
                        original: "Pulpectomia",
                        mod: "Pulpectomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200042",
                        original: "Pulpotomia",
                        mod: "Pulpotomia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200050",
                        original: "Remoção de corpo estranho intracanal",
                        mod: "Remoção de corpo estranho intracanal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200069",
                        original: "Remoção de material obturador intracanal para retratamento endodôntico",
                        mod: "Remoção de material obturador intracanal para retratamento endodôntico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200077",
                        original: "Remoção de núcleo intrarradicular",
                        mod: "Remoção de núcleo intrarradicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200085",
                        original: "Restauração temporária / tratamento expectante",
                        mod: "Restauração temporária / tratamento expectante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200093",
                        original: "Retratamento endodôntico birradicular",
                        mod: "Retratamento endodôntico birradicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200107",
                        original: "Retratamento endodôntico multirradicular",
                        mod: "Retratamento endodôntico multirradicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200115",
                        original: "Retratamento endodôntico unirradicular",
                        mod: "Retratamento endodôntico unirradicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200123",
                        original: "Tratamento de perfuração endodôntica",
                        mod: "Tratamento de perfuração endodôntica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200131",
                        original: "Tratamento endodôndico de dente com rizogênese incompleta",
                        mod: "Tratamento endodôndico de dente com rizogênese incompleta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200140",
                        original: "Tratamento endodôntico birradicular",
                        mod: "Tratamento endodôntico birradicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200158",
                        original: "Tratamento endodôntico multirradicular",
                        mod: "Tratamento endodôntico multirradicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200166",
                        original: "Tratamento endodôntico unirradicular",
                        mod: "Tratamento endodôntico unirradicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200174",
                        original: "Curativo endodôntico em situação de urgência",
                        mod: "Curativo endodôntico em situação de urgência",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85200182",
                        original: "Curetagem apical",
                        mod: "Curetagem apical",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85300012",
                        original: "Dessensibilização dentária",
                        mod: "Dessensibilização dentária",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85300020",
                        original: "Imobilização dentária em dentes permanentes",
                        mod: "Imobilização dentária em dentes permanentes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85300039",
                        original: "Raspagem sub-gengival/alisamento radicular",
                        mod: "Raspagem sub-gengival/alisamento radicular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85300047",
                        original: "Raspagem supra-gengival",
                        mod: "Raspagem supra-gengival",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85300055",
                        original: "Remoção dos fatores de retenção do Biofilme Dental (Placa Bacteriana)",
                        mod: "Remoção dos fatores de retenção do Biofilme Dental (Placa Bacteriana)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85300063",
                        original: "Tratamento de abscesso periodontal agudo",
                        mod: "Tratamento de abscesso periodontal agudo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85300071",
                        original: "Tratamento de gengivite necrosante aguda - GNA",
                        mod: "Tratamento de gengivite necrosante aguda - GNA",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85300080",
                        original: "Tratamento de pericoronarite",
                        mod: "Tratamento de pericoronarite",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85300098",
                        original: "Manutenção periodontal",
                        mod: "Manutenção periodontal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85300101",
                        original: "Tracionamento de raiz residual",
                        mod: "Tracionamento de raiz residual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400017",
                        original: "Ajuste Oclusal por acréscimo",
                        mod: "Ajuste Oclusal por acréscimo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400025",
                        original: "Ajuste Oclusal por desgaste seletivo",
                        mod: "Ajuste Oclusal por desgaste seletivo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400033",
                        original: "Conserto em prótese parcial removível (em consultório e em laboratório)",
                        mod: "Conserto em prótese parcial removível (em consultório e em laboratório)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400041",
                        original: "Conserto em prótese parcial removível (exclusivamente em consultório)",
                        mod: "Conserto em prótese parcial removível (exclusivamente em consultório)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400050",
                        original: "Conserto em prótese total (em consultório e em laboratório)",
                        mod: "Conserto em prótese total (em consultório e em laboratório)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400068",
                        original: "Conserto em prótese total (exclusivamente em consultório)",
                        mod: "Conserto em prótese total (exclusivamente em consultório)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400076",
                        original: "Coroa provisória com pino",
                        mod: "Coroa provisória com pino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400084",
                        original: "Coroa provisória sem pino",
                        mod: "Coroa provisória sem pino",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400092",
                        original: "Coroa total acrílica prensada",
                        mod: "Coroa total acrílica prensada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400106",
                        original: "Coroa total em cerâmica pura",
                        mod: "Coroa total em cerâmica pura",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400114",
                        original: "Coroa total em cerômero",
                        mod: "Coroa total em cerômero",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400122",
                        original: "Coroa total livre de metal (metalfree) sobre implante - cerâmica",
                        mod: "Coroa total livre de metal (metalfree) sobre implante - cerâmica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400130",
                        original: "Coroa total livre de metal (metalfree) sobre implante - cerômero",
                        mod: "Coroa total livre de metal (metalfree) sobre implante - cerômero",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400149",
                        original: "Coroa total metálica",
                        mod: "Coroa total metálica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400157",
                        original: "Coroa total metalo cerâmica",
                        mod: "Coroa total metalo cerâmica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400165",
                        original: "Coroa total metalo plástica – cerômero",
                        mod: "Coroa total metalo plástica – cerômero",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400173",
                        original: "Coroa total metalo plástica – resina acrílica",
                        mod: "Coroa total metalo plástica – resina acrílica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400181",
                        original: "Faceta em cerâmica pura",
                        mod: "Faceta em cerâmica pura",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400190",
                        original: "Faceta em cerômero",
                        mod: "Faceta em cerômero",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400203",
                        original: "Guia cirúrgico para prótese total imediata",
                        mod: "Guia cirúrgico para prótese total imediata",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400211",
                        original: "Núcleo de preenchimento",
                        mod: "Núcleo de preenchimento",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400220",
                        original: "Núcleo metálico fundido",
                        mod: "Núcleo metálico fundido",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400238",
                        original: "Onlay de Resina Indireta",
                        mod: "Onlay de Resina Indireta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400246",
                        original: "Órtese miorrelaxante (placa oclusal estabilizadora)",
                        mod: "Órtese miorrelaxante (placa oclusal estabilizadora)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400254",
                        original: "Órtese reposicionadora (placa oclusal reposicionadora)",
                        mod: "Órtese reposicionadora (placa oclusal reposicionadora)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400262",
                        original: "Pino pré fabricado",
                        mod: "Pino pré fabricado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400270",
                        original: "Placa oclusal resiliente",
                        mod: "Placa oclusal resiliente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400289",
                        original: "Prótese fixa adesiva direta (provisória)",
                        mod: "Prótese fixa adesiva direta (provisória)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400297",
                        original: "Prótese fixa adesiva em cerômero livre de metal (metal free)",
                        mod: "Prótese fixa adesiva em cerômero livre de metal (metal free)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400300",
                        original: "Prótese fixa adesiva indireta em metalo cerâmica",
                        mod: "Prótese fixa adesiva indireta em metalo cerâmica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400319",
                        original: "Prótese fixa adesiva indireta em metalo plástica",
                        mod: "Prótese fixa adesiva indireta em metalo plástica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400327",
                        original: "Prótese parcial fixa em cerômero livre de metal (metal free)",
                        mod: "Prótese parcial fixa em cerômero livre de metal (metal free)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400335",
                        original: "Prótese parcial fixa em metalo cerâmica",
                        mod: "Prótese parcial fixa em metalo cerâmica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400343",
                        original: "Prótese parcial fixa em metalo plástica",
                        mod: "Prótese parcial fixa em metalo plástica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400351",
                        original: "Prótese parcial fixa In Ceran livre de metal (metal free)",
                        mod: "Prótese parcial fixa In Ceran livre de metal (metal free)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400360",
                        original: "Prótese parcial fixa provisória",
                        mod: "Prótese parcial fixa provisória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400378",
                        original: "Prótese parcial removível com encaixes de precisão ou de semi precisão",
                        mod: "Prótese parcial removível com encaixes de precisão ou de semi precisão",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400386",
                        original: "Prótese parcial removível com grampos bilateral",
                        mod: "Prótese parcial removível com grampos bilateral",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400394",
                        original: "Prótese parcial removível provisória em acrílico com ou sem grampos",
                        mod: "Prótese parcial removível provisória em acrílico com ou sem grampos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400408",
                        original: "Prótese total",
                        mod: "Prótese total",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400416",
                        original: "Prótese total imediata",
                        mod: "Prótese total imediata",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400424",
                        original: "Prótese total incolor",
                        mod: "Prótese total incolor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400432",
                        original: "Provisório para Faceta",
                        mod: "Provisório para Faceta",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400440",
                        original: "Provisório para Inlay/Onlay",
                        mod: "Provisório para Inlay/Onlay",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400459",
                        original: "Provisório para Restauração metálica fundida",
                        mod: "Provisório para Restauração metálica fundida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400467",
                        original: "Recimentação de trabalhos protéticos",
                        mod: "Recimentação de trabalhos protéticos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400475",
                        original: "Reembasamento de coroa provisória",
                        mod: "Reembasamento de coroa provisória",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400483",
                        original: "Reembasamento de prótese total ou parcial - imediato (em consultório)",
                        mod: "Reembasamento de prótese total ou parcial - imediato (em consultório)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400491",
                        original: "Reembasamento de prótese total ou parcial - mediato (em laboratório)",
                        mod: "Reembasamento de prótese total ou parcial - mediato (em laboratório)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400505",
                        original: "Remoção de trabalho protético",
                        mod: "Remoção de trabalho protético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400513",
                        original: "Restauração em cerâmica pura - inlay",
                        mod: "Restauração em cerâmica pura - inlay",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400521",
                        original: "Restauração em cerâmica pura - onlay",
                        mod: "Restauração em cerâmica pura - onlay",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400530",
                        original: "Restauração em cerômero - onlay",
                        mod: "Restauração em cerômero - onlay",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400548",
                        original: "Restauração em cerômero - inlay",
                        mod: "Restauração em cerômero - inlay",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400556",
                        original: "Restauração metálica fundida",
                        mod: "Restauração metálica fundida",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400564",
                        original: "Prótese total imediata sobre implantes",
                        mod: "Prótese total imediata sobre implantes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400572",
                        original: "Coroa 3/4 ou 4/5",
                        mod: "Coroa 3/4 ou 4/5",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400580",
                        original: "JIG ou Front plato - órtese reposicionadora",
                        mod: "JIG ou Front plato - órtese reposicionadora",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400599",
                        original: "Planejamento em prótese",
                        mod: "Planejamento em prótese",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400602",
                        original: "Ponto de solda",
                        mod: "Ponto de solda",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85400610",
                        original: "Prótese total caracterizada",
                        mod: "Prótese total caracterizada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500011",
                        original: "Coroa provisória sobre implante",
                        mod: "Coroa provisória sobre implante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500020",
                        original: "Coroa provisória sobre implante com carga imediata",
                        mod: "Coroa provisória sobre implante com carga imediata",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500038",
                        original: "Coroa total metalo cerâmica sobre implante",
                        mod: "Coroa total metalo cerâmica sobre implante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500046",
                        original: "Coroa total metalo plástica sobre implante – cerômero",
                        mod: "Coroa total metalo plástica sobre implante – cerômero",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500054",
                        original: "Coroa total metalo plástica sobre implante – resina acrílica",
                        mod: "Coroa total metalo plástica sobre implante – resina acrílica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500062",
                        original: "Guia cirúrgico para implante",
                        mod: "Guia cirúrgico para implante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500070",
                        original: "Intermediário protético (para implantes)",
                        mod: "Intermediário protético (para implantes)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500089",
                        original: "Manutenção de prótese sobre implantes",
                        mod: "Manutenção de prótese sobre implantes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500097",
                        original: "Overdenture barra clipe ou o'ring sobre dois implantes",
                        mod: "Overdenture barra clipe ou o'ring sobre dois implantes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500100",
                        original: "Overdenture barra clipe ou o'ring sobre quatro ou mais implantes",
                        mod: "Overdenture barra clipe ou o'ring sobre quatro ou mais implantes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500119",
                        original: "Overdenture barra clipe ou o'ring sobre três implantes",
                        mod: "Overdenture barra clipe ou o'ring sobre três implantes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500127",
                        original: "Prótese parcial fixa implanto suportada",
                        mod: "Prótese parcial fixa implanto suportada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500135",
                        original: "Prótese parcial fixa provisória em carga imediata",
                        mod: "Prótese parcial fixa provisória em carga imediata",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500143",
                        original: "Protocolo Branemark em carga imediata para 4 implantes - parte protética",
                        mod: "Protocolo Branemark em carga imediata para 4 implantes - parte protética",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500151",
                        original: "Protocolo Branemark em carga imediata para 5 implantes - parte protética",
                        mod: "Protocolo Branemark em carga imediata para 5 implantes - parte protética",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500160",
                        original: "Protocolo Branemark para 4 implantes",
                        mod: "Protocolo Branemark para 4 implantes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500178",
                        original: "Protocolo Branemark para 5 implantes",
                        mod: "Protocolo Branemark para 5 implantes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500186",
                        original: "Protocolo Branemark provisório para 4 implantes",
                        mod: "Protocolo Branemark provisório para 4 implantes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500194",
                        original: "Protocolo Branemark provisório para 5 implantes",
                        mod: "Protocolo Branemark provisório para 5 implantes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500208",
                        original: "Barra clipe",
                        mod: "Barra clipe",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500216",
                        original: "Plasma rico em plaquetas (PRP)",
                        mod: "Plasma rico em plaquetas (PRP)",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "85500224",
                        original: "Tratamento de perimplantite por implante",
                        mod: "Tratamento de perimplantite por implante",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000012",
                        original: "Aletas Gomes",
                        mod: "Aletas Gomes",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000020",
                        original: "Aparelho de Klammt",
                        mod: "Aparelho de Klammt",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000039",
                        original: "Aparelho de protração mandibular -  APM",
                        mod: "Aparelho de protração mandibular -  APM",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000047",
                        original: "Aparelho de Thurow",
                        mod: "Aparelho de Thurow",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000055",
                        original: "Aparelho extra-bucal",
                        mod: "Aparelho extra-bucal",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000063",
                        original: "Aparelho ortodôntico fixo estético",
                        mod: "Aparelho ortodôntico fixo estético",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000080",
                        original: "Aparelho ortodôntico fixo estético parcial",
                        mod: "Aparelho ortodôntico fixo estético parcial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000098",
                        original: "Aparelho ortodôntico fixo metálico",
                        mod: "Aparelho ortodôntico fixo metálico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000110",
                        original: "Aparelho ortodôntico fixo metálico parcial",
                        mod: "Aparelho ortodôntico fixo metálico parcial",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000128",
                        original: "Aparelho removível com alças bionator invertida ou de Escheler",
                        mod: "Aparelho removível com alças bionator invertida ou de Escheler",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000136",
                        original: "APM - Aparelho de Protração Mandibular",
                        mod: "APM - Aparelho de Protração Mandibular",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000144",
                        original: "Arco lingual",
                        mod: "Arco lingual",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000152",
                        original: "Barra transpalatina fixa",
                        mod: "Barra transpalatina fixa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000160",
                        original: "Barra transpalatina removível",
                        mod: "Barra transpalatina removível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000179",
                        original: "Bionator de Balters",
                        mod: "Bionator de Balters",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000187",
                        original: "Blocos geminados de Clark – twinblock",
                        mod: "Blocos geminados de Clark – twinblock",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000195",
                        original: "Botão de Nance",
                        mod: "Botão de Nance",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000209",
                        original: "Contenção fixa - por arcada",
                        mod: "Contenção fixa - por arcada",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000209",
                        original: "Contenção fixa por arcada, em ortodontia",
                        mod: "Contenção fixa por arcada, em ortodontia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000225",
                        original: "Disjuntor palatino - Hirax",
                        mod: "Disjuntor palatino - Hirax",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000233",
                        original: "Disjuntor palatino - Macnamara",
                        mod: "Disjuntor palatino - Macnamara",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000241",
                        original: "Distalizador com mola nitinol",
                        mod: "Distalizador com mola nitinol",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000250",
                        original: "Distalizador de Hilgers",
                        mod: "Distalizador de Hilgers",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000268",
                        original: "Distalizador Distal Jet",
                        mod: "Distalizador Distal Jet",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000276",
                        original: "Distalizador Pendulo/Pendex",
                        mod: "Distalizador Pendulo/Pendex",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000284",
                        original: "Distalizador tipo Jones Jig",
                        mod: "Distalizador tipo Jones Jig",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000292",
                        original: "Documentação eletromiográfica",
                        mod: "Documentação eletromiográfica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000306",
                        original: "Gianelly",
                        mod: "Gianelly",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000314",
                        original: "Grade palatina fixa",
                        mod: "Grade palatina fixa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000322",
                        original: "Grade palatina removível",
                        mod: "Grade palatina removível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000330",
                        original: "Herbst encapsulado",
                        mod: "Herbst encapsulado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000357",
                        original: "Manutenção de aparelho ortodôntico - aparelho fixo",
                        mod: "Manutenção de aparelho ortodôntico - aparelho fixo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000365",
                        original: "Manutenção de aparelho ortodôntico - aparelho ortopédico",
                        mod: "Manutenção de aparelho ortodôntico - aparelho ortopédico",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000373",
                        original: "Manutenção de aparelho ortodôntico - aparelho removível",
                        mod: "Manutenção de aparelho ortodôntico - aparelho removível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000381",
                        original: "Máscara facial – Delaire e Tração Reversa",
                        mod: "Máscara facial – Delaire e Tração Reversa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000390",
                        original: "Mentoneira",
                        mod: "Mentoneira",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000403",
                        original: "Modelador elástico de Bimler",
                        mod: "Modelador elástico de Bimler",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000411",
                        original: "Monobloco",
                        mod: "Monobloco",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000420",
                        original: "Obtenção de modelos gnatostáticos de Planas",
                        mod: "Obtenção de modelos gnatostáticos de Planas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000438",
                        original: "Pistas diretas de Planas - superior e inferior",
                        mod: "Pistas diretas de Planas - superior e inferior",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000446",
                        original: "Pistas indiretas de Planas",
                        mod: "Pistas indiretas de Planas",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000454",
                        original: "Placa de distalização de molares",
                        mod: "Placa de distalização de molares",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000462",
                        original: "Placa de Hawley",
                        mod: "Placa de Hawley",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000470",
                        original: "Placa de Hawley - com torno expansor",
                        mod: "Placa de Hawley - com torno expansor",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000489",
                        original: "Placa de mordida ortodôntica",
                        mod: "Placa de mordida ortodôntica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000497",
                        original: "Placa de Schwarz",
                        mod: "Placa de Schwarz",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000500",
                        original: "Placa de verticalização de caninos",
                        mod: "Placa de verticalização de caninos",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000519",
                        original: "Placa dupla de Sanders",
                        mod: "Placa dupla de Sanders",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000527",
                        original: "Placa encapsulada de Maurício",
                        mod: "Placa encapsulada de Maurício",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000535",
                        original: "Placa lábio-ativa",
                        mod: "Placa lábio-ativa",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000543",
                        original: "Plano anterior fixo",
                        mod: "Plano anterior fixo",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000551",
                        original: "Plano inclinado",
                        mod: "Plano inclinado",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000560",
                        original: "Quadrihélice",
                        mod: "Quadrihélice",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000578",
                        original: "Regulador de função de Frankel",
                        mod: "Regulador de função de Frankel",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000586",
                        original: "Simões Network",
                        mod: "Simões Network",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000594",
                        original: "Splinter",
                        mod: "Splinter",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000608",
                        original: "Placa de contenção ortodôntica",
                        mod: "Placa de contenção ortodôntica",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "86000616",
                        original: "Recolocação de mantenedor de espaço",
                        mod: "Recolocação de mantenedor de espaço",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "87000016",
                        original: "Atividade educativa em odontologia para pais e/ou cuidadores de pacientes com necessidades especiais",
                        mod: "Atividade educativa em odontologia para pais e/ou cuidadores de pacientes com necessidades especiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "87000016",
                        original: "Colocação de aparelho ortodôntico removível",
                        mod: "Colocação de aparelho ortodôntico removível",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "87000024",
                        original: "Atividade educativa para pais e/ou cuidadores",
                        mod: "Atividade educativa para pais e/ou cuidadores",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "87000032",
                        original: "Condicionamento em odontologia para pacientes com necessidades especiais",
                        mod: "Condicionamento em odontologia para pacientes com necessidades especiais",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "87000040",
                        original: "Coroa de acetato em dente permanente",
                        mod: "Coroa de acetato em dente permanente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "87000059",
                        original: "Coroa de aço em dente permanente",
                        mod: "Coroa de aço em dente permanente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "87000067",
                        original: "Coroa de policarbonato em dente permanente",
                        mod: "Coroa de policarbonato em dente permanente",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "87000148",
                        original: "Estabilização por meio de contenção física e/ou mecânica em pacientes com necessidades especiais em odontologia",
                        mod: "Estabilização por meio de contenção física e/ou mecânica em pacientes com necessidades especiais em odontologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "87000164",
                        original: "Sedação consciente com óxido nitroso e oxigênio em pacientes com necessidades especiais em odontologia",
                        mod: "Sedação consciente com óxido nitroso e oxigênio em pacientes com necessidades especiais em odontologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "87000180",
                        original: "Sedação medicamentosa ambulatorial em pacientes com necessidades especiais em odontologia",
                        mod: "Sedação medicamentosa ambulatorial em pacientes com necessidades especiais em odontologia",
                        favorito: false,
                        aspecto: "",
                },
                {
                        codigo: "87000199",
                        original: "Colocação de aparelho ortodôntico removível",
                        mod: "Colocação de aparelho ortodôntico removível",
                        favorito: false,
                        aspecto: "",
                },

        ]

        return procedimentos
    
    }
    
    export default Tuss